import React, { useState, useEffect } from 'react'



import logoWenet from '../../../_assets/media/images/logos/logo_wenet_color_home.png';

import icnSearch from '../../../_assets/media/images/icons/icn_search.png';
import icnFilter from '../../../_assets/media/images/icons/icn_filter.png';
import icnFilterActive from '../../../_assets/media/images/icons/icn_filter_active.png';
import icnCancel from '../../../_assets/media/images/icons/icn_cancel.png';
import icnNewChat from '../../../_assets/media/images/icons/icn_new_chat.png';
import icnNotification from '../../../_assets/media/images/icons/icn_notification.png';
import icnLetter from '../../../_assets/media/images/icons/icn_size_typo.png'

import './Searcher.scss';
import { profileService } from '../../../_resources/services';



const Searcher = (props) => {
    const [filtersSelected, setFiltersSelected] = useState(null);
    const [showInput, setShowInput] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [bellNotRead, setBellNotRead] = useState(0);


    const onFilterBtnTapped = () => {
        if (props.onOpenFilters) {
            props.onOpenFilters();
        }
    }

    const onNotificationsBtnTapped = () => {
        if (props.onNotificationsTapped) {
            props.onNotificationsTapped();
        }
    }

    const onLetterBtnTapped = () => {
        if (props.onLetterTapped) {
            props.onLetterTapped();
        }
    }

    const toggleInput = (value) => {
        setShowInput(value);
        if (!value) {
            setSearchTerm('');
        }
    }

    const onClearSeach = () => {
        setSearchTerm('')

    }

    const onSearchTerm = () => {
        if (props.onSearchTerm) {
            props.onSearchTerm(searchTerm);
        }
    }

    const onNewChatBtnTapped = () => {
        if (props.onNewChatBtnTapped) {
            props.onNewChatBtnTapped();
        }
    }

    useEffect(() => {
        onSearchTerm();
    }, [searchTerm]);

    useEffect(() => {
        profileService.getGeneric('version').then((response) => {
            if (response && response.data) {
                setBellNotRead(response.NotRead);
            }
        });
    }, [])
    


    return (
        <div className="Searcher-container">
            <div className="row m-0">
                {
                    showInput ? (
                        <div className="col Searcher-input-box">
                            <label htmlFor="searcher">
                                <img src={icnSearch} alt="search icon"/>
                            </label>
                            <input type="text" id='searcher' name='searcher' value={searchTerm} onChange={e => setSearchTerm(e.target.value)}/>
                            {
                                searchTerm && (
                                    <button className='btn-cleared' onClick={e => onClearSeach()}>
                                        <img src={icnCancel} alt="search icon"/>
                                    </button>
                                )
                            }
                        </div>
                    ):(
                        <div className="col p-0 Searcher-logo-box d-flex align-items-center">
                            {
                                props.showLogo && (
                                    <img src={logoWenet} alt="logo wenet"/>
                                )
                            }
                        </div>
                    )
                }



                <div className={`col Searcher-toggle-box d-flex align-items-center ${showInput ? 'toggled':''}`}>
                    {
                        !showInput ? (
                            <button className='btn-cleared' onClick={() => {toggleInput(true)}}>
                                <img src={icnSearch} alt="search icon"/>
                            </button>
                        ):(
                            <button className='btn-cleared' onClick={() => {toggleInput(false)}}>
                                Cancelar
                            </button>
                        )
                    }
                </div>
                <div className="col px-0 Searcher-filter-box">
                    <button className='btn-cleared' onClick={() => {onLetterBtnTapped()}}>
                        <img src={icnLetter} alt="filter icon"/>
                    </button>
                </div>
                <div className="col px-0 Searcher-filter-box">
                    <button className='btn-cleared' onClick={() => {onFilterBtnTapped()}}>
                        <img src={props.isActiveFilters ? icnFilterActive:icnFilter} alt="filter icon"/>
                    </button>
                </div>
                <div className="col px-0 Searcher-filter-box">
                    <button className='btn-cleared' onClick={() => {onNotificationsBtnTapped()}}>
                        {
                            bellNotRead && bellNotRead > 0? 
                            (
                                <span className='dotSpan'></span>
                            ):
                            (null)
                        }
                        <img src={icnNotification} alt="filter icon"/>
                    </button>
                </div>
                {
                    props.showNewChatBtn &&
                    <div className="col px-0 Searcher-filter-box">
                        <button className='btn-cleared' onClick={() => {onNewChatBtnTapped()}}>
                            <img src={icnNewChat} alt="filter icon"/>
                        </button>
                    </div>
                }
            </div>
        </div>
    );
}
export { Searcher };
