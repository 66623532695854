import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { profileService, swalAlertsService } from '../../../../../_resources/services';
import back from '../../.././../../_assets/media/images/icons/icn_back_arrow.png'
import visa from '../../../../../_assets/media/images/icons/img_visa.png'
import amex from '../../../../../_assets/media/images/icons/img_amex.png'
import master from '../../../../../_assets/media/images/icons/img_mastercard.png'

import './MembershipProfile.scss'
import Swal from 'sweetalert2';

const MembershipProfile = (props) => {

    const history = useHistory()
    const location = useLocation()



    const [card, setCard] = useState([])
    const [membership, setMembership] = useState({})

    useEffect(() => {
        let isSuscribed = true;

        if (isSuscribed) {
            if (props.onToggleTab) {
                props.onToggleTab(true);
            }

            profileService.getCard().then(
                (response) => {
                    if(response.data.cards) {
                        if (isSuscribed) {
                            setCard(response.data.cards[0])
                        }

                    }

                }
            )

            profileService.getUser().then(
                (response) => {
                    if (isSuscribed) {
                        setMembership(response.data.Membresia)
                    }
                }
            )
        }

        return () => isSuscribed = false;
    }, [])


    const getData = () => {
        profileService.getCard().then(
            (response) => {
                if(response.data.cards) {
                    setCard(response.data.cards[0])
                }

            }
        )

        profileService.getUser().then(
            (response) => {
                setMembership(response.data.Membresia)
            }
        )
    }

    const deleteMembership = () => {
        if(membership.clv_metodopago === 2) {
            swalAlertsService.showToast("error", "Ocurrio un error y no se pudo cancelar la membresia")
        }else {
            swalAlertsService.answer("", "¿Estas seguro de querer eliminar tu membresia?", "Si, cancelar").then((result) => {
                if (result.isConfirmed) {
                    let loading = swalAlertsService.createLoading("Espere un momento...")
                    loading.fire()
                    profileService.deleteMembership().then(
                        (response) => {
                            if(response.status === 200 || response.status === 202) {
                                response.text().then(text => {

                                    console.log(JSON.parse(text) );

                                    setTimeout(() => {
                                        loading.close()
                                        swalAlertsService.answer("","La membresia se cancelo exitosamente", "Okay").then((result) => {
                                            if (result.isConfirmed) {
                                                history.replace('/')
                                                // localStorage.clear();
                                                window.location.reload(true)
                                            }else {
                                                history.replace('/')
                                                window.location.reload(true)
                                                // localStorage.clear();
                                            }
                                        })

                                    }, 3000);
                                })
                            }

                            if(response.status === 500) {
                                loading.close()
                                Swal.fire(
                                    '',
                                    "Membresia eliminada exitosamente",
                                    'error'
                                )
                            }

                        }
                    )
                }
            })
        }
    }

    return (
        <React.Fragment>
            <div className="container_membership">
                <div className="container_header_membership">
                    <img src={back} width="30px" height="30px" onClick={() => history.goBack()}/>
                    <h3>Membresía</h3>
                </div>


                {


                    membership.Precio ?
                        <div>
                            <h5>{`Membresía ${membership.Membresia}`}</h5>
                            <div className="container_data_option_detail_member">
                                <div className="container_data_option_detail_member_description">
                                    <h5>{`${membership.Moneda} $${membership.Precio} ${membership.Intervalo}`}</h5>
                                    <p>{`Primeros ${membership.DiasPrueba} días gratis`}</p>
                                </div>
                            </div>
                        </div>
                    :null
                }

                {
                    !membership.Precio &&
                    <div className="container_data_option_detail_member">
                        <div className="container_data_option_detail_member_description">
                            <h5>Membresía gratuita</h5>
                        </div>
                    </div>
                }

                {
                    (card && card.length === undefined)  && (membership.clv_metodopago === 1) &&
                    <div>
                        <h5>Metodo de pago</h5>

                        <div className={`container_data_option_detail_member flex`}>
                            <img src={card.brand === "visa" ? visa : card.brand === "mastercard" ? master : card.brand === "amex" ? amex : ""} width="50px" height="40px"/>
                            <div className="container_data_option_detail_member_description">
                                <p>{card.name}</p>
                                <h5>{`**** ${card.last4}`}</h5>
                                {
                                    card.exp_month && card.exp_year ? (
                                        <h6 className='font-color-blue'>{`${card.exp_month}/${card.exp_year}`}</h6>
                                    ):(null)
                                }
                            </div>
                        </div>
                    </div>
                }

                {
                    (card && card.length === undefined)  && (membership.clv_metodopago === 1) &&
                    <button className="buttom_membership_profile" onClick={() => deleteMembership()}>Cancelar membresía</button>
                }
            </div>
        </React.Fragment>
    );
}

export {MembershipProfile};
