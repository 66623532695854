import { useState, useEffect } from 'react';

import './Talent.scss';

import { BottomSheetShared, ModalDefault } from '../';
import { infoService, profileService, swalAlertsService } from '../../../_resources/services/';
import { useWindowDimensions } from '../../../_resources/helpers';

import icnsNetworks from '../../../_assets/media/images/icons/icns_networks.png';
import icnDeleteFilter from '../../../_assets/media/images/icons/icn_delete_filter.png';
import icnClose from '../../../_assets/media/images/icons/icn_close.png';

const Talent = (props) => {
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [inputValueBase, setInputValueBase] = useState('');
    const {width, height} = useWindowDimensions();

    const onBottomSheetDimiss = () => {
        if (props.onBottomSheetDimiss) {
            props.onBottomSheetDimiss();
        }
    }

    const setupInput = () => {
        setTimeout(() => {
            if (props.talent && props.talent.length) {
                let element = document.getElementById('talentBox');

                if (element) {
                    element.value = props.talent;
                }
            }
        },200);
    }

    useEffect(() => {
        let isSuscribed = true;
        setupInput();
        if (props.talent) {
            if (isSuscribed) {
                setInputValue(props.talent);
                setInputValueBase(JSON.parse(JSON.stringify(props.talent)));
            }
        }

        return () => isSuscribed = false;
    }, []);

    const viewHasChanges = () => {
        let inputValueCP = JSON.stringify(inputValue);
        let inputValueBaseCP = JSON.stringify(inputValueBase);

        if (inputValueCP !== inputValueBaseCP) {
            return true;
        }
        return false;
    }

    const footerBtn = () => {
        return <button className={`btn-cleared ${ viewHasChanges() ? 'active':''}`} onClick={onSubmitBtnTapped}>
            Guardar
        </button>
    }

    const onSubmitBtnTapped = () => {
        if (viewHasChanges()) {
            let loader = swalAlertsService.createLoading("Espere un momento...");

            if (props.isRecomendation && props.user && props.user.clv_usuario) {
                let obj = { recomendacion: inputValue, clv_usuario: props.user.clv_usuario };
                loader.fire();
                profileService.postRecomendation(obj).then(
                    (response) => {
                        loader.close();

                        if (response) {
                            if (response.data) {
                                swalAlertsService.showToast("success", "Recomendación enviada correctamente");
                                if (props.dataUpdated) {
                                    props.dataUpdated();
                                }
                            } else {
                                swalAlertsService.showToast("error", "Ocurrio un error inténtalo de nuevo más tarde");
                            }
                        }
                    }
                )
            } else {
                let obj = { talento: inputValue };
                loader.fire();
                profileService.postTalent(obj).then(
                    (response) => {
                        loader.close();

                        if (response) {
                            if (response.data) {
                                swalAlertsService.showToast("success", "Talento actualizado correctamente");
                                if (props.dataUpdated) {
                                    props.dataUpdated();
                                }
                            } else {
                                swalAlertsService.showToast("error", "Ocurrio un error inténtalo de nuevo más tarde");
                            }
                        }
                    }
                )
            }

        }

    }

    const onInputChanged = (e) => {
        let inputValuesCP = JSON.parse(JSON.stringify(inputValue));
        inputValuesCP = e.target.value;
        setInputValue(inputValuesCP);
    }

    const renderView = () => {
        return (
            <div className="row m-0 Talent-container">

                <div className={`col-12 Talent-title-container ${width && width >= 1450 ? 'desktop':''}`}>
                    { props.isRecomendation ? 'Recomendar':'Mi mayor'} talento

                    {
                        width && width >= 1450 ? (
                            <button className="btn-cleared" onClick={onBottomSheetDimiss}>
                                <img src={icnClose} alt="icnClose" width='30' height='30'/>
                            </button>
                        ):(null)
                    }
                </div>

                {
                    props.isRecomendation &&
                    <div className="col-12 Talent-subtitle-container">
                        Describe brevemente cómo fue mi aportación, conocimientos o desempeño durante nuestra colaboración.
                    </div>
                }

                <div className="col-12 Talent-form-container">

                    <div className="row m-0">
                        <div className="col-12 px-0 pos-relative Talent-form-group">
                            <textarea name="talentBox"
                                id="talentBox"
                                placeholder={props.isRecomendation ? 'Escribir...':'Escribe brevemente lo más sobresaliente de tus habilidades y conocimientos para que otro usuarios validen esta información de acuerdo a su experiencia contigo y te recomienden.'}
                                onChange={onInputChanged}
                                maxLength={props.isRecomendation ? 300:500}
                                className={props.isRecomendation && 'recomendation'}/>
                            <small>{inputValue.length}/{props.isRecomendation ? 300:500}</small>
                        </div>
                    </div>

                </div>

                {
                    width && width >= 1450 ? (
                        <div className="col-12 Talent-footer-container">
                            {footerBtn()}
                        </div>
                    ):(null)
                }
            </div>
        );
    }

    const desktopRender = () => {
        return (<ModalDefault show={true} onHide={onBottomSheetDimiss}>
            {renderView()}
        </ModalDefault>);
    }

    const mobileRender = () => {
        return (
            <BottomSheetShared showHeader={true} showCancelBtn={true}
                blocking={true}
                isOpen={isBottomSheetOpen}
                onDismiss={onBottomSheetDimiss}
                title={props.title ? props.title:'Talento'}
                footerBtn={footerBtn()}
                shouldClose={true}>
                {renderView()}
            </BottomSheetShared>
        );
    }

    return (
        <>
            {
                width && width >= 1450 ? (desktopRender()):(mobileRender())
            }
        </>
    )
}

export { Talent };
