import React, { useState, useEffect } from 'react';
import './NotificationDetails.scss';
import logoWenet from '../../../_assets/media/images/logos/logo_wenet_color_home.png';
import icnForwardArrow from '../../../_assets/media/images/icons/icn_forward_arrow.png';
import placeholderuser from '../../../_assets/media/images/icons/icn_user_placeholder.png';

import { profileService, authenticationService } from '../../../_resources/services';
import { toolsHelper } from '../../../_resources/helpers/';

import { ModalDefault } from '../../shared/';

import { useHistory } from 'react-router-dom';

const NotificationDetails = (props) => {
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [notificationsTitle, setNotificationsTitle] = useState('Notificaciones');

    const [showSubTitle, setShowSubTitle] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);
    const [showHeader, setshowHeader] = useState(false);

    const [notificationsList, setNotificationsList] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const getNotifications = (param) => {
        profileService.getGeneric(`user/news?Fecha=${param}`).then((response) => {
            if (response && response.data) {
                setNotificationsList(response.data);
            }
        });
    }

    useEffect(() => {
        let isSuscribed = true;

        authenticationService.currentUser.subscribe((user) => {
            if (user && user.SUID && user.Usuario && isSuscribed) {
                setCurrentUser(user);
            }
        });

        if (props.match && props.match.params && props.match.params.param) {
            getNotifications(props.match.params.param);
        }

        return () => isSuscribed = false
    }, []);

    const onUserTapped = (user) => {

        if (currentUser && user) {
            if (currentUser.Usuario.clv_usuario === user.clv_usuario) {
                history.push('/profile', {from: `notification/${props.match.params.param}`});
            } else {
                history.push(`/profile-user/${user.clv_usuario}`, {from: `notification/${props.match.params.param}`});
            }
        }
    }

    const renderNotificationsList = () => {

        if (notificationsList && notificationsList.length) {
            return notificationsList.map((item, index) => {
                return (
                    <div className="row mx-0 NotificationDetails-user-item" key={index}>
                        <div className="col-12 px-0">

                            <div className="row m-0 line-height-20px">
                                <div className="col avatar-image-box-60">
                                    <img src={item.ThumbnailUrl || placeholderuser} alt=""
                                        onError={(e)=>{
                                            e.target.onerror = null;
                                            e.target.src=placeholderuser
                                        }}/>
                                </div>
                                <div className="col pe-0">
                                    <div className="row m-0">
                                        <div className="col-12 p-0 user-details-title text-block-truncate-1">{item.NombreCompleto}</div>
                                        {
                                            item.Cargo || item.EmpresaNombreCorto ? (
                                                <div className="col-12 p-0 user-details-subtitle soft text-block-truncate-1">{item.Cargo ? item.Cargo:''}{item.Cargo && item.EmpresaNombreCorto ? ' | ':''}{item.EmpresaNombreCorto ? item.EmpresaNombreCorto:''}</div>
                                            ):(null)
                                        }

                                        {
                                            item.EstadoResidencia || item.EmpresaNombreCorto ? (
                                                <div className="col-12 p-0 user-details-subtitle soft text-block-truncate-1">{item.EstadoResidencia ? item.EstadoResidencia:''}{item.EstadoResidencia && item.EmpresaNombreCorto ? ', ':''}{item.PaisResidencia ? item.PaisResidencia:''}</div>
                                            ):(null)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            item.Descripcion &&
                            <div className="col-12 px-0 text-block-truncate-3 description-box">{item.Descripcion}</div>
                        }
                        <div className="col-12 px-0 btn-container">
                            <button className="btn-cleared" onClick={e => onUserTapped(item)}>
                                Ver perfil <img src={icnForwardArrow} alt="icnForwardArrow" width='24' height='24'/>
                            </button>
                        </div>
                    </div>
                )
            })
        }

        return null;
    }

    const renderView = () => {
        return (
            <React.Fragment>
                <div className={`NotificationDetails-container`}>
                    <div className={`row m-0`}>
                        <div className="col-12 NotificationDetails-logo-container">
                            <img src={logoWenet} alt="logo wenet"/>
                        </div>

                        <div className="col-12 p-0">
                            <div className="row m-0">
                                <div className="col-12 NotificationDetails-title-container">
                                    ¡Cada día somos más!
                                </div>
                                <div className="col-12 NotificationDetails-subtitle-container">
                                    Conoce y envía una bienvenida a nuevos integrantes de la comunidad Wenet.
                                </div>
                            </div>

                            <div className="row m-0 NotificationDetails-users-container">

                                <div className="col-12">
                                    {renderNotificationsList()}
                                </div>
                            </div>

                            <div className="row m-0 NotificationDetails-footer-container">
                                <div className="col-12">
                                    <button className='btn-cleared' onClick={e => history.goBack()}>Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <ModalDefault show={true} className='full-view'>
            {renderView()}
        </ModalDefault>
    );

}
export { NotificationDetails };
