import React, { useState, useEffect } from 'react';

import './AddPostHeader.scss';

import iconClose from '../../../../../_assets/media/images/icons/icn_close.png';
import imgUserPlaceholder from '../../../../../_assets/media/images/icons/icn_user_placeholder.png';
import start from '../../../../../_assets/media/images/icons/icn_batch_star.svg';

const AddPostHeader = (props) => {

    const [currentUser, setCurrentUser] = useState(false);

    const goToPreviousPath = () => {
        if (props.goToPreviousPath) {
            props.goToPreviousPath();
        }
    }

    const onPostBtnTapped = () => {
        if (props.onPostBtnTapped) {
            props.onPostBtnTapped();
        }
    }

    useEffect(() => {
        if (props.currentUser && props.currentUser.Usuario) {
            setCurrentUser(props.currentUser.Usuario);
        }
    });

    return (
        <div className="AddPostHeader-container">
            <div className="row m-0 AddPostHeader-nav-container">
                <div className="col-12 p-0 pos-relative">
                    <button className='btn-cleared AddPostHeader-close-btn' onClick={goToPreviousPath}>
                        <img src={iconClose} alt="close icon"/>
                    </button>
                    <p className='titleAddPost'>{ props.edit ? 'Editar publicación':'Crear publicación'}</p>
                    <button className={`btn-cleared AddPostHeader-post-btn ${props.canPost ? 'active':''}`} onClick={onPostBtnTapped}>
                        Publicar
                    </button>
                </div>
            </div>

            <div className="row m-0 AddPostHeader-user-container">
                <div className="col p-0 AddPostHeader-img-container">
                    <img src={currentUser.ThumbnailUrl || imgUserPlaceholder} alt="user image"
                        onError={(e)=>{
                            e.target.onerror = null;
                            e.target.src=imgUserPlaceholder
                        }}
                    />
                    {
                        currentUser.clv_estatusbm === 2 &&
                        <img src={start} width="10px" height="10px" alt="miembro" className="miembro"/>
                    }
                </div>
                <div className="col AddPostHeader-info-container">
                    <div className="row m-0">
                        <div className="col-12 p-0 name text-block-truncate-1">{currentUser.NombreCompleto}</div>
                        <div className="col-12 p-0 subtext text-block-truncate-1">{currentUser.Cargo} | {currentUser.EmpresaNombreCorto}</div>
                        <div className="col-12 p-0 subtext text-block-truncate-1">{currentUser.EstadoResidencia}, {currentUser.PaisResidencia}</div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export { AddPostHeader }
