import React, { useState, useEffect } from 'react';

import { toolsHelper, useDidMountEffect } from '../../../../../_resources/helpers';
import { Dropdown } from 'react-bootstrap';

import './MessagesDesktopList.scss';

import icnsMessages from '../../../../../_assets/media/images/icons/icns_messages.png';
import icnCancel from '../../../../../_assets/media/images/icons/icn_cancel.png';
import imgUserPlaceholder from '../../../../../_assets/media/images/icons/icn_user_placeholder.png';
import imgPlaceholderGroup from '../../../../../_assets/media/images/icons/icn_placeholder_group.png';
import icnMore from '../../../../../_assets/media/images/icons/icn_more_options.png';

const MessagesDesktopList = (props) => {
    const [selected, setSelected] = useState("");

    const onChatSelected = (chat, index) => {
        setSelected(chat.clv_conversacion)
        if (props.onChatSelected) {
            props.onChatSelected(chat);
        }
    }

    const onChatDelete = (chat) => {
        if (props.onChatDelete) {
            props.onChatDelete(chat);
        }
    }

    const onGroupDelete = (chat) => {
        if (props.onGroupDelete) {
            props.onGroupDelete(chat);
        }
    }

    const getChatTitle = (chat) => {
        if (chat) {
            if (chat.Mensaje) {
                return chat.Mensaje;
            } else {
                if (chat.clv_tipoconversacion === 1) {
                    return 'Conversación creada';
                } else {
                    return 'Grupo creado';
                }
            }
        }
        return null;
    }

    const onNewChatBtnTapped = () => {
        if (props.onNewChatBtnTapped) {
            props.onNewChatBtnTapped();
        }
    }

    useEffect(() => {
        if (props.chats) {
            if (props.chats.length > 0 && selected === "") {
                setSelected(props.chats[0].clv_conversacion);
            }
        }
    }, [props.chats])

    const renderChats = () => {
        if (props.chats && props.chats.length) {
            return props.chats.map((chat, index) => {
                const placeholder = chat.clv_tipoconversacion === 1 ? imgUserPlaceholder : imgPlaceholderGroup
                return (
                    <div className={`col-12 MessagesDesktopList-user-container ${chat.clv_conversacion === selected && 'active'}`} key={index} onClick={e => onChatSelected(chat, index)}>
                        <div className="row m-0 mouse-pointer" onClick={e => onChatSelected(chat)}>
                            <div className="col avatar-image-box-60">
                                <img src={chat.ImageUrl ? chat.ImageUrl : placeholder} alt="placeholder"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = placeholder
                                    }} />
                            </div>
                            <div className="col pe-0 MessagesDesktopList-user-col">
                                <div className="row mx-0 MessagesDesktopList-user-row">
                                    <div className="col-12 px-0">
                                        <div className="row m-0">
                                            <div className="col p-0 nameCol text-truncate noselect">{chat.Titulo}</div>
                                            <div className="col p-0 timeSinceCol noselect">{toolsHelper.timeSince(chat.Fecha)}</div>

                                            {
                                                chat.clv_tipoconversacion === 1 ? (
                                                    <div className="col p-0 MessagesDesktopList-result-deleter noselect">
                                                        <Dropdown>
                                                            <Dropdown.Toggle>
                                                                <img src={icnMore} alt="options icon" width='30' height='30' />
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item key='0' className='text-danger' onClick={e => onChatDelete(chat, index)}>Eliminar</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                ) : (null)
                                            }

                                            {
                                                chat.clv_tipoconversacion === 2 && chat.Inactivo ? (
                                                    <div className="col p-0 MessagesDesktopList-result-deleter noselect">
                                                        <Dropdown>
                                                            <Dropdown.Toggle>
                                                                <img src={icnMore} alt="options icon" width='30' height='30' />
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item key='0' className='text-danger' onClick={e => onGroupDelete(chat, index)}>Eliminar</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                ) : (null)
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 px-0 text-truncate subtitleCol">
                                        {`${chat.Cargo ? chat.Cargo : ''}${chat.Cargo && chat.EmpresaNombreCorto ? ' | ' : ''}${chat.EmpresaNombreCorto ? chat.EmpresaNombreCorto : ''}`}
                                    </div>
                                    <div className="col-10 px-0 text-block-truncate-2 contentCol d-flex">
                                        {getChatTitle(chat)}
                                        
                                    </div>
                                    {
                                        chat.NoLeidos ? (
                                            <div className="col p-0 MessagesDesktopList-result-unread-container noselect">
                                                {chat.NoLeidos > 99 ? '99+' : chat.NoLeidos}
                                            </div>
                                        ) : (null)

                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                );
            });
        }
        return null;
    }

    const onContactSelected = (contact) => {
        if (props.onContactSelected) {
            props.onContactSelected(contact);
        }
    }

    const renderContacts = () => {
        
        if (props.contacts && props.contacts.length) {
            const items = props.contacts.map((contact, index) => {
                const placeholder = imgUserPlaceholder;
                return (
                    <div className={`col-12 MessagesDesktopList-user-container`} key={index} onClick={e => onContactSelected(contact, index)}>
                        <div className="row m-0 mouse-pointer" onClick={e => onContactSelected(contact)}>
                            <div className="col avatar-image-box-60">
                                <img src={contact.ThumbnailUrl ? contact.ThumbnailUrl : placeholder} alt="placeholder"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = placeholder
                                    }} />
                            </div>
                            <div className="col pe-0 MessagesDesktopList-user-col">
                                <div className="row mx-0 MessagesDesktopList-user-row">
                                    <div className="col-12 px-0">
                                        <div className="row m-0">
                                            <div className="col p-0 nameCol text-truncate noselect">{contact.NombreCompleto}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 px-0 text-truncate subtitleCol">
                                        {`${contact.Cargo ? contact.Cargo : ''}${contact.Cargo && contact.EmpresaNombreCorto ? ' | ' : ''}${contact.EmpresaNombreCorto ? contact.EmpresaNombreCorto : ''}`}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                );
            });

            return <>
                <div className="col-12 MessagesDesktopList-contacts-header">
                    Contactos sin conversación activa
                </div>

                {items}
            </>
        }
        return null;
    } 

    return (
        <div className="MessagesDesktopList-container row m-0 h-100">

            <div className="col-12 p-0">
                <div className="row m-0">

                    <div className="col-12 MessagesDesktopList-header-container">
                        <div className="row m-0 w-100 pos-relative">
                            <div className="col MessagesDesktopList-header-title noselect mouse-pointer">Mensajes</div>
                            <div className="col px-0 MessagesDesktopList-header-btn first">
                                <button className="btn-cleared" onClick={onNewChatBtnTapped}>
                                    <img src={icnsMessages} alt="icnsMessages" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className={`col-12 px-0 MessagesDesktopList-main-container`}>

                        <div className="row m-0 MessagesDesktopList-users-list">
                            {renderChats()}
                            {renderContacts()}
                            
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export { MessagesDesktopList }
