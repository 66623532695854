import React, { useState, useEffect } from 'react';
import './Directory.scss';

import { useHistory } from 'react-router-dom';
import { algoliaService, authenticationService } from '../../../_resources/services';
import { toolsHelper, useDidMountEffect, useWindowDimensions } from '../../../_resources/helpers';

import {
    DirectoryHeader,
    DirectoryList,
    DirectoryDesktopHeader,
    DirectoryDesktopList
} from './directory-components';

import {
    Filters,
    ContactList,
    Header,
    MessagesBubble,
    NotificationsSheet,
    FiltersSheet
} from '../../shared';
import { getMessagin } from '../../../firebaseInit';



const Directory = (props) => {
    const history = useHistory();
    const [showFilters, setShowFilters] = useState(false);
    const [isNewChatOpen, setIsNewChatOpen] = useState(false);
    const [directoryState, setDirectoryState] = useState({
        results: [],
        searchTerm: ''
    });

    const [currentUser, setCurrentUser] = useState(null);

    const [activeFilters, setActiveFilters] = useState({
        filtersSelected: null,
        postedBy: null,
        isUsingFilters: false
    });

    const [isFetchingData, setIsFetchingData] = useState(false);
    const [algoliaData, setAlgoliaData] = useState({
        nbHits: 0,
        nbPages: 0,
        page: 0
    });

    const [firstUsersCant, setFirstUsersCant] = useState(0);

    const [searchTerm, setSearchTerm] = useState('');
    const {width, height} = useWindowDimensions();
    const [activeTab, setActiveTab] = useState('users');
    const [updateDot, setUpdateDot] = useState(false);

    let messaging = getMessagin();

    if(messaging){
        messaging.onMessage((payload) => {
            setUpdateDot(true);
            if (payload && payload.data && payload.data.clv_tipoaccion === "12") {
                setTimeout(() => {
                    loadDirectory(true);
                }, 2000);
            }
        });
    }
    
    const endMessage = ()=>{
        setUpdateDot(false);
    }

    const prepareFilters = (page) => {
        let parameters = {
            facets:  ["Aficion", "Cargo", "Clasificacion", "Estado", "Giro", "Pais", "Asociacion"],
            hitsPerPage: 999,
            facetFilters: []
        };

        if (page) {
            parameters['page'] = page;
        }

        if (currentUser && currentUser.Usuario && currentUser.Usuario.clv_usuario) {
            let userID = currentUser.Usuario.clv_usuario;
            parameters['numericFilters'] = [`BloqueadoPor.clv_usuario!=${currentUser.Usuario.clv_usuario}`];

            const {filtersSelected} = activeFilters;

            if (filtersSelected) {
                let facetsRows = 
                ["Aficiones", "Cargos", "Clasificaciones", "Estados", "Giros", "Paises", "Asociacion"];
                facetsRows.forEach((facet, index) => {
                    if (filtersSelected[facet] && filtersSelected[facet].length) {
                        var elements = []
                        if(facet === "Asociacion" || facet === "Asociación" || facet === "Usuarios.Asociacion" || facet === "Usuarios.Asociación") {
                            elements = filtersSelected[facet].map(element => `${activeTab === 'companies' ? 'Usuarios.': ''}EstatusBM: ${element.EstatusBM}`);
                        } else {
                            elements = filtersSelected[facet].map(element => `${activeTab === 'companies' ? 'Usuarios.': ''}${parameters.facets[index]}: ${element.name}`);
                        }
                        parameters.facetFilters.push(elements);
                    }
                });
            }
        }

        if(activeTab === 'mentors'){
            parameters.facetFilters.push('Mentor:true');
        }

        if(activeTab === 'companies'){
            parameters.facets = ["Usuarios.Aficion", "Usuarios.Cargo", "Usuarios.Clasificacion", "Usuarios.Estado", "Usuarios.Giro", "Usuarios.Pais", "Usuarios.Asociacion"];
        }
        return parameters;
    }

    const getStoredDirectory = () => {
        let storedDirectory = localStorage.getItem('storedDirectory');
        let storedAlgolia = localStorage.getItem('storedAlgolia');


        // setFirstUsersCant(storedAlgolia.ngHits);
        if (storedDirectory) {
            try {
                storedDirectory = JSON.parse(storedDirectory);
                if (storedDirectory.length) {
                    let directoryStateCP = JSON.parse(JSON.stringify(directoryState));

                    directoryStateCP.results = storedDirectory;
                    setDirectoryState(directoryStateCP);
                }
            } catch (e) {
            }
        }

        if (storedAlgolia) {
            try {
                storedAlgolia = JSON.parse(storedAlgolia);
                if (storedAlgolia.length) {
                    let algoliaDataCP = JSON.parse(JSON.stringify(algoliaData));

                    setAlgoliaData(storedAlgolia);
                }
            } catch (e) {
            }
        }
    }

    const storeDirectory = (items) => {
        if (items && items.length) {
            localStorage.setItem('storedDirectory', JSON.stringify(items));
        }
    }

    const storeAlgolia = (algolia) => {
        if (algolia) {
            localStorage.setItem('storedAlgolia', JSON.stringify(algolia));
        }
    }

    async function loadData (searchTerm = '', page = 0) {
        let parameters = prepareFilters(page);

        if (!parameters.numericFilters && currentUser) {
            parameters.numericFilters.p = [`clv_usuario!=${currentUser.Usuario.clv_usuario}`];
        }
        const response = await algoliaService.getResults(searchTerm, parameters, activeTab === 'users' ||  activeTab === 'mentors' ? 'Directorio' : 'Empresasv2', true, true);
        return response;
    }

    const onFilterDismiss = () => {
        setShowFilters(false);
    }

    const onSubmit = (data) => {
        if (data.activeFilters && (data.activeFilters.filtersSelected || data.activeFilters.postedBy)) {
            setActiveFilters({
                filtersSelected: data.activeFilters.filtersSelected,
                postedBy: data.activeFilters.postedBy,
                isUsingFilters: true
            });

        } else {
            setActiveFilters({
                filtersSelected: null,
                postedBy: null,
                isUsingFilters: false
            });
        }

        setShowFilters(false);
    }

    const handleResponse = (response, shouldConcat = false) => {
        let directoryStateCP = JSON.parse(JSON.stringify(directoryState));

        if (!shouldConcat) {
            directoryStateCP.results = [];
            storeDirectory([]);
        }

        let algoliaDataCP = JSON.parse(JSON.stringify(algoliaData));

        const {nbHits, nbPages, page} = response;
        algoliaDataCP = {
            nbHits: nbHits || 0,
            nbPages: nbPages || 0,
            page: page || 0
        }

        setAlgoliaData(algoliaDataCP);
        if (response && response.hits && response.hits.length) {

            if (shouldConcat) {
                directoryStateCP.results = directoryStateCP.results.concat(response.hits);
            } else {
                directoryStateCP.results = response.hits;
            }
        }
        storeDirectory(directoryStateCP.results);
        storeAlgolia(algoliaDataCP);
        setDirectoryState(directoryStateCP);
    }

    useEffect(() => {
        let isSuscribed = true;
        getStoredDirectory();
        if (props.onToggleTab) {
            props.onToggleTab(false);
        }
        const { location } = props;
        if (location && location.state && location.state.shouldReload) {
            if (history.location.state && history.location.state.shouldReload) {
                let state = { ...history.location.state };
                delete state.shouldReload;
                history.replace({ ...history.location, state });
            }
            window.location.reload();

        } else {
            loadDirectory(isSuscribed);
        }
        authenticationService.currentUser.subscribe((user) => {
            if (user && user.SUID && user.Usuario && isSuscribed) {
                setCurrentUser(user);
            }
        });

        return () => isSuscribed =  false
    }, []);

    useEffect(() => {
        // react please run me if 'key' changes, but not on initial render
        let isSuscribed = true;
        const data = loadData(directoryState.searchTerm,0);
        setIsFetchingData(true);
        data.then((response) => {
            if (isSuscribed) {
                setIsFetchingData(false);
                handleResponse(response);
            }
        });
        return () => isSuscribed =  false
    }, [directoryState.searchTerm]);

    useEffect(() => {
        let isSuscribed = true;
        setIsFetchingData(true);
        const data = loadData(directoryState.searchTerm,0);
        data.then((response) => {
            if (isSuscribed) {
                setIsFetchingData(false);
                handleResponse(response);
            }
        });
        return () => isSuscribed =  false
    }, [activeFilters]);

    useDidMountEffect(() => {
        let isSuscribed = true;
        setIsFetchingData(true);
        const data = loadData(directoryState.searchTerm,0);
        data.then((response) => {
            if (isSuscribed) {
                setIsFetchingData(false);
                handleResponse(response);
            }
        });
        return () => isSuscribed =  false
    }, [activeTab]);

    const loadDirectory = (isSuscribed)=>{
        const data = loadData('',0);
        setIsFetchingData(true);
        data.then((response) => {
            if (response && response.nbHits) {
                setFirstUsersCant(response.nbHits);
            }
            if (isSuscribed) {
                setIsFetchingData(false);
                handleResponse(response);
            }
        });
    }

    const onOpenFilters = () => {
        setShowFilters(true);
    }

    const onNewChatBtnTapped = () => {
        setIsNewChatOpen(true);
    }

    const onSearchTerm = (data) => {
        let directoryStateCP = JSON.parse(JSON.stringify(directoryState));
        directoryStateCP.searchTerm = data || '';
        setDirectoryState(directoryStateCP);
    }

    const checkActiveFilters = () => {
        if (activeFilters && (activeFilters.filtersSelected || (activeFilters.postedBy && activeFilters.postedBy.value !== 1))) {
            return true;
        }
        return false;
    }

    const onUserSelected = (item) => {
        let chat = toolsHelper.chatObjBuilder(item);
        if (chat) {
            setIsNewChatOpen(false);
            history.push(`/messages`, {chat});
        }
    }

    const onBottomSheetDimiss = () => {
        setIsNewChatOpen(false);
    }

    const onMassiveSendBtnTapped = (massiveChat) => {
        if (massiveChat && massiveChat.length) {
            setIsNewChatOpen(false);
            history.push(`/messages`, {massiveChat});
        }
    }

    const onGroupSendBtnTapped = (group) => {
        if (group && group.clv_conversacion) {
            setIsNewChatOpen(false);
            history.push(`/messages`, {groupDetails: group});
        }
    }

    const loadMore = () => {
        let algoliaDataCP = JSON.parse(JSON.stringify(algoliaData));

        if (!isFetchingData && directoryState.results.length < algoliaDataCP.nbHits) {
            setIsFetchingData(true);
            const data = loadData(directoryState.searchTerm || '',algoliaDataCP.page + 1);

            data.then((response) => {
                setIsFetchingData(false);
                handleResponse(response, true);
            });
        }
    }

    const onTabToggled = (data) => {
        setActiveTab(data);
    }

    const filtersDidChanged = (data) => {
        if (data) {
            setActiveFilters(data);
        }
    }

    const onFiltersDeleted = () => {
        setActiveFilters({
            filtersSelected: null,
            postedBy: null,
            isUsingFilters: false
        });
    }

    const onTabSelected = (data) => {
        if (data && data !== activeTab) {
            setActiveTab(data);
        }
    }

    const desktopRender = () => {
        return (
            <div className="Directory-container h-100">
                <Header title='directorio' activeLink='directory' onSearchTerm={onSearchTerm} updateShowDot={updateDot} 
                    endMessage={endMessage}/>

                    <div className="row m-0 d-flex justify-content-center Directory-box-container">
                        <div className="col Directory-lateral-content left">
                            <div className="row m-0 pos-sticky isTop-24">
                                <div className="col-12 px-0 py-3">
                                    <FiltersSheet activeFilters={activeFilters} filtersDidChanged={filtersDidChanged}/>
                                </div>
                            </div>
                        </div>

                        <div className="col Directory-post-box px-0">
                            <DirectoryDesktopHeader firstUsersCant={firstUsersCant} directoryState={directoryState} algoliaData={algoliaData} onTabToggled={onTabToggled} onFiltersDeleted={onFiltersDeleted} isActiveFilters={checkActiveFilters()}/>
                            <DirectoryDesktopList directoryState={directoryState} algoliaData={algoliaData} mode={activeTab} currentUser={currentUser} loadMore={loadMore}/>
                        </div>
                        <div className="col Directory-lateral-content left">
                            <div className="row m-0 pos-sticky isTop-10">
                                <div className="col-12 px-0 pb-3">
                                    <NotificationsSheet/>
                                </div>
                            </div>
                        </div>

                    </div>

                <MessagesBubble isHome={true}/>
            </div>
        );
    }

    const mobileRender = () => {
        return (
            <div className="Directory-container h-100">
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <DirectoryHeader onOpenFilters={onOpenFilters}
                            onNewChatBtnTapped={onNewChatBtnTapped}
                            onSearchTerm={onSearchTerm}
                            isActiveFilters={checkActiveFilters()}/>
                    </div>
                </div>

                <div className="row m-0">
                    <div className="col-12 p-0">
                        <DirectoryList directoryState={directoryState}
                            algoliaData={algoliaData}
                            currentUser={currentUser}
                            loadMore={loadMore}
                            isFetchingData={isFetchingData}
                            onTabSelected={onTabSelected}
                            firstUsersCant={firstUsersCant}
                        />
                    </div>
                </div>


            </div>
        );
    }

    return (
        <React.Fragment>
            {
                width && width >= 1450 ? (desktopRender()):(mobileRender())
            }

            {
                showFilters &&
                <Filters onDismiss={onFilterDismiss}
                    onSubmit={onSubmit}
                    activeFilters={activeFilters}
                    showAllTags={true}
                    hidePostedBy={true}
                    showSubmit={true}
                />
            }

            {
                isNewChatOpen &&
                <ContactList onUserSelected={onUserSelected}
                    currentUser={currentUser}
                    onBottomSheetDimiss={onBottomSheetDimiss}
                    onMassiveSendBtnTapped={onMassiveSendBtnTapped}
                    activeFilters={activeFilters}
                    onGroupSendBtnTapped={onGroupSendBtnTapped}
                />
            }
        </React.Fragment>
    );
}

export { Directory };
