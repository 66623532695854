import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { authenticationService } from '../../../../_resources/services';
import { LazyImage } from "react-lazy-images";

import icnUserPlaceholder from '../../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnOptionsPhotos from '../../../../_assets/media/images/icons/icn_options_photos.png';
import icnOptionsImages from '../../../../_assets/media/images/icons/icn_options_images.png';
import icnOptionsVideo from '../../../../_assets/media/images/icons/icn_options_video.png';
import icnOptionsDoc from '../../../../_assets/media/images/icons/icn_options_doc.png';

import { AddPost } from '../../';


import './HomeNewPost.scss';

const HomeNewPost = (props) => {
    const history = useHistory();
    const [openModal, setOpenModal] = useState(false);
    const [isShowSupport, setIsShowSupport] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);


    const toggleOpenModal = (value) => {
        setOpenModal(value);
    }

    const goToSupport = () => {
        setOpenModal(true);
        setIsShowSupport(true);
    }

    const showProfile = () => {
        history.push('/profile');
    }

    useEffect(() => {
        let isSuscribed = true;

        authenticationService.currentUser.subscribe((user) => {
            if (user && user.SUID && user.Usuario && isSuscribed) {
                setCurrentUser(user);
            }
        });

        if (isShowSupport) {
            if (isSuscribed) {
                setIsShowSupport(false);
            }

            history.push('/setting/support');
        }
        return () => isSuscribed = false
    }, [isShowSupport]);


    return (
        <>
            <div className="HomeNewPost-container row m-0">
                <div className="col avatar-image-box-60 mouse-pointer" onClick={showProfile}>
                    <LazyImage src={props.currentUser && props.currentUser.Usuario && props.currentUser.Usuario.ThumbnailUrl ? props.currentUser.Usuario.ThumbnailUrl:icnUserPlaceholder}
                        alt="icnUserPlaceholder"
                        placeholder={ ({imageProps, ref}) =>
                            <img ref={ref} src={icnUserPlaceholder} alt={imageProps.alt}/>
                        }
                        actual={({imageProps}) =>
                            <img {...imageProps}/>
                        }
                        error={() => (<img src={icnUserPlaceholder} alt="icnUserPlaceholder"/>)}
                    />
                </div>
                <div className="col p-0">
                    <div className="row m-0">
                        <div className="col-12 pe-0">
                            <button className="btn-cleared inputBtn" onClick={e => toggleOpenModal(true)}>
                                Escribir...
                            </button>
                        </div>
                        <div className="col-12 HomeNewPost-options-box">
                            {
                                // <button className="btn-cleared" onClick={e => toggleOpenModal(true)}>
                                //     <img src={icnOptionsPhotos} alt="icnOptionsPhotos"/>
                                //     <span>Foto</span>
                                // </button>
                            }
                            <button className="btn-cleared" onClick={e => toggleOpenModal(true)}>
                                <img src={icnOptionsImages} alt="icnOptionsImages"/>
                                <span>Imágenes</span>
                            </button>
                            <button className="btn-cleared" onClick={e => toggleOpenModal(true)}>
                                <img src={icnOptionsVideo} alt="icnOptionsImages"/>
                                <span>Video</span>
                            </button>

                            <button className="btn-cleared" onClick={e => toggleOpenModal(true)}>
                                <img src={icnOptionsDoc} alt="icnOptionsDoc"/>
                                <span>Archivo</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {
                openModal && <AddPost 
                    currentUser={props.currentUser} 
                    show={openModal} 
                    onHide={e => toggleOpenModal(false)} 
                    goToSupport={goToSupport}
                />
            }

        </>
    )
}

export { HomeNewPost };
