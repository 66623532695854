import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './Tab.scss';

import { NavLink } from 'react-router-dom';

import { AddPost } from '../../pages';

import { authenticationService, swalAlertsService, profileService } from '../../../_resources/services'

import icnTabHome from '../../../_assets/media/images/icons/icn_tab_home.png';
import icnTabMessages from '../../../_assets/media/images/icons/icn_tab_messages.png';
import icnTabAddPost from '../../../_assets/media/images/icons/icn_tab_add_post.png';
import icnTabDirectory from '../../../_assets/media/images/icons/icn_tab_directory.png';
import icnTabProfile from '../../../_assets/media/images/icons/icn_tab_profile.png';
import logoWenet from '../../../_assets/media/images/logos/logo_wenet_color2.png';
import { getMessagin } from '../../../firebaseInit';

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const Tab = (props) => {
    const history = useHistory();
    let location = useLocation()
    const [width] = useWindowSize();
    const [openModal, setOpenModal] = useState(false);
    const [isShowSupport, setIsShowSupport] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [currentLink, setCurrentLink] = useState('');
    const [bellNotRead, setBellNotRead] = useState(0);

    let messaging = getMessagin();

    if (width < 1450) {

        if (messaging) {
            messaging.onMessage((payload) => {
                console.log(payload);
                getNotification();
            });
        }
    }


    const toggleOpenModal = (value) => {
        setOpenModal(value);
    }

    const getMaxWidth = () => {
        if (width >= 1600) {
            return 450;
        } else if (width >= 1450 && width < 1600) {
            return 336;
        } else {
            return 700;
        }
    }

    const goToSupport = () => {
        setOpenModal(true);
        setIsShowSupport(true);
    }

    const goHome = () => {
        history.push('/');
    }

    const logOut = () => {
        swalAlertsService.answer("", "¿Estas seguro de que quieres cerrar sesión?", "Si, cerrar sesión").then((result) => {
            if (result.isConfirmed) {
                authenticationService.logout().then((response) => {
                    console.log(response);
                    history.replace('/welcome');
                    window.location.reload();
                });
            }
        })
    }

    const goTo = (link) => {
        const currentUser = localStorage.getItem('currentUser');
        if (currentUser) {
            authenticationService.getUser();
        } else {
            authenticationService.logout().then((response) => {
                history.replace('/welcome');
            });
        }

        // if (notifications && notifications.length) {
        //     if (showDot(link)) {
        //         markAsReaded(link);
        //     }
        // }
    }

    async function markAsReaded(link) {
        let obj = [];

        if (notifications && notifications.length) {
            if (link === 'home') {
                let notificationsFiltered = notifications.filter(el => el.clv_tiponotificacion === 2 && el.NoLeido > 0);
                let notificationsFiltered2 = notifications.filter(el => el.clv_tiponotificacion === 3 && el.NoLeido > 0);

                if (notificationsFiltered.length) {
                    obj.push({ clv_tiponotificacion: notificationsFiltered[0].clv_tiponotificacion });
                }
                if (notificationsFiltered2.length) {
                    obj.push({ clv_tiponotificacion: notificationsFiltered2[0].clv_tiponotificacion });
                }
            } else if (link === 'messages') {
                let notificationsFiltered = notifications.filter(el => el.clv_tiponotificacion === 1 && el.NoLeido > 0);

                if (notificationsFiltered.length) {
                    obj.push({ clv_tiponotificacion: notificationsFiltered[0].clv_tiponotificacion });
                }
            } else if (link === 'profile') {
                let notificationsFiltered = notifications.filter(el => el.clv_tiponotificacion === 1 && el.NoLeido > 0);

                if (notificationsFiltered.length) {
                    obj.push({ clv_tiponotificacion: notificationsFiltered[0].clv_tiponotificacion });
                }
            } else if (link === 'notifications') {
                let notificationsFiltered = notifications.filter(el => el.clv_tiponotificacion === 6 && el.NoLeido > 0);
                let notificationsFiltered2 = notifications.filter(el => el.clv_tiponotificacion === 7 && el.NoLeido > 0);

                if (notificationsFiltered.length) {
                    obj.push({ clv_tiponotificacion: notificationsFiltered[0].clv_tiponotificacion });
                }
                if (notificationsFiltered2.length) {
                    obj.push({ clv_tiponotificacion: notificationsFiltered2[0].clv_tiponotificacion });
                }
            }

            if (obj.length) {
                await Promise.all(
                    obj.map((el) => {
                        return profileService.postGeneric('notification/read', 'POST', el);
                    })
                );
            }
        }
    }

    const showDot = (option) => {
        let activeLink = window.location.pathname;

        if (activeLink.length && activeLink.charAt(0) === '/') {
            activeLink = activeLink.substring(1);
        }
        if ((option === 'home' && activeLink === '') || (option === activeLink)) {
            return null;
        }

        if (notifications && notifications.length) {
            let value = false;

            if (option === 'home') {
                let notificationsFiltered = notifications.filter(el => el.clv_tiponotificacion === 2 && el.NoLeido > 0);
                let notificationsFiltered2 = notifications.filter(el => el.clv_tiponotificacion === 3 && el.NoLeido > 0);
                value = notificationsFiltered.length || notificationsFiltered2.length ? true : false;
            } else if (option === 'messages') {
                let notificationsFiltered = notifications.filter(el => el.clv_tiponotificacion === 1 && el.NoLeido > 0);
                value = notificationsFiltered.length ? true : false;
            } else if (option === 'profile') {
                let notificationsFiltered = notifications.filter(el => el.clv_tiponotificacion === 5 && el.NoLeido > 0);
                value = notificationsFiltered.length ? true : false;
            } else if (option === 'notifications') {
                if (bellNotRead && bellNotRead > 0) {
                    value = true;
                } else {
                    value = false;
                }
            }

            if (value) {
                return <span className='dotSpan'></span>;
            }
        }


        return null;
    }

    useEffect(() => {
        let isSuscribed = true;

        let cUser = localStorage.getItem('currentUser');
        if (cUser) {
            getNotification(isSuscribed);
        }

        // setInterval(() => {
        //     if (isSuscribed) {
        //         let cUser2 = localStorage.getItem('currentUser');

        //         if (cUser2) {
        //             profileService.getGeneric('notification/unread').then((response) => {
        //                 if (response && response.data && isSuscribed) {
        //                     setNotifications(response.data);
        //                 }
        //             });
        //         }

        //     }
        // }, 1000 * 30)
        return () => isSuscribed = false
    }, []);

    useEffect(() => {
        let isSuscribed = true;

        authenticationService.currentUser.subscribe((user) => {
            if (user && user.SUID && user.Usuario && isSuscribed) {
                setCurrentUser(user);
            }
        });

        if (isShowSupport) {
            if (isSuscribed) {
                setIsShowSupport(false);
            }

            history.push('/setting/support');
        }
        return () => isSuscribed = false
    }, [isShowSupport]);

    useEffect(() => {

        let pathTxt = location.pathname.charAt(0) === '/' ? location.pathname.substring(1) : location.pathname;
        pathTxt = pathTxt === '' ? 'home' : pathTxt;

        if (pathTxt) {
            setCurrentLink(pathTxt);
            markAsReaded(pathTxt);
        }
    }, [location]);


    const getNotification = (isSuscribed) => {
        profileService.getGeneric('notification/unread').then((response) => {
            if (response && response.data && isSuscribed) {
                setNotifications(response.data);
            }
        });

        profileService.getGeneric('version').then((response) => {
            if (response && response.data && isSuscribed) {
                setBellNotRead(response.NotRead);
            }
        });

    }

    return (
        <div className={`Tab-container ${props.hideTab ? 'hideTab' : ''}`} style={{ maxWidth: getMaxWidth() }}>
            <div className="logo-box">
                <img src={logoWenet} alt="logo Wenet" draggable='false' className='noselect' onClick={goHome} />
            </div>
            <ul>
                <li>
                    <NavLink exact to='/' activeClassName='active' onClick={() => goTo('')}>
                        <div className='text-center img-box'>
                            {showDot('home')}
                            <img src={icnTabHome} alt="" />
                        </div>
                        <div className='text-center title-box'>Inicio</div>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to='/messages' activeClassName='active' onClick={() => goTo('messages')}>
                        <div className='text-center img-box'>
                            {showDot('messages')}
                            <img src={icnTabMessages} alt="" />
                        </div>
                        <div className='text-center title-box'>Mensajes</div>
                    </NavLink>
                </li>
                <li>
                    <button className='btn-cleared Tab-btn' onClick={e => toggleOpenModal(true)}>
                        <div className='text-center img-box'>
                            <img src={icnTabAddPost} alt="" />
                        </div>
                        <div className='text-center title-box'>Publicar</div>
                    </button>
                </li>
                <li>
                    <NavLink exact to='/directory' activeClassName='active' onClick={() => goTo('directory')}>
                        <div className='text-center img-box'>
                            {showDot('directory')}
                            <img src={icnTabDirectory} alt="" />
                        </div>
                        <div className='text-center title-box'>Directorio</div>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to='/profile' activeClassName='active' onClick={() => goTo('profile')}>
                        <div className='text-center img-box'>
                            {showDot('profile')}
                            <img src={icnTabProfile} alt="" />
                        </div>
                        <div className='text-center title-box'>Perfil</div>
                    </NavLink>
                </li>
            </ul>
            {
                getMaxWidth() !== 700 ? (
                    <div className="footer">
                        WeNet &copy;{new Date().getFullYear()}
                        <br /><br />
                        <button className='btn-cleared Tab-btn' onClick={() => history.push("/setting/support")}>Soporte</button>
                        <br />
                        <button className='btn-cleared Tab-btn' onClick={() => logOut()}>Cerrar sesión</button>
                    </div>
                ) : (null)
            }

            {
                openModal && <AddPost currentUser={props.currentUser} show={openModal} onHide={e => toggleOpenModal(false)} goToSupport={goToSupport} />
            }

        </div>
    );
}

export { Tab };
