import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './RegisterWizard.scss';

import { toolsHelper, useDidMountEffect } from '../../../_resources/helpers';
import { profileService, swalAlertsService, authenticationService, infoService } from '../../../_resources/services';

import { UniversalPicker, ContactList, ImageCrop, AppsDisclaimer } from '../../shared';

import imgLogo from '../../../_assets/media/images/logos/logo_wenet_color_home.png';
import icnsTogglers from '../../../_assets/media/images/icons/icns_togglers.png';
import icnsReg from '../../../_assets/media/images/icons/icns_reg.png';
import icnBackArrow from '../../../_assets/media/images/icons/icn_back_arrow_green.png';
import icnArrowDown from '../../../_assets/media/images/icons/icn_arrow_down.png';
import imgUserPlaceholder from '../../../_assets/media/images/icons/img_user_placeholder.png';
import imgCompanyPlaceholder from '../../../_assets/media/images/icons/img_company_placeholder.png';
import icnUpload from '../../../_assets/media/images/icons/icn_upload.png';

import icnCheckbox from '../../../_assets/media/images/icons/icn_checkbox.png';
import icnCheckboxSelected from '../../../_assets/media/images/icons/icn_checkbox_blue_selected.png';

const RegisterWizard = (props) => {
    const history = useHistory();
    const location = useLocation();

    const [step, setStep] = useState(null);
    const [isAcceptedProfile, setIsAcceptedProfile] = useState(false);
    const [openUniversalPicker, setOpenUniversalPicker] = useState(false);
    const [isContactAdding, setIsContactAdding] = useState(false);
    const [openCamera, setOpenCamera] = useState(false);
    const [visibleCrop, setVisibleCrop] = useState(false);

    const [imageData, setImageData] = useState({
        base64: null,
        splitted: null
    });

    const [type, setType] = useState('');

    const inputProfileImage = useRef(null);
    const inputCompanyImage = useRef(null);

    const [mentoryTypes, setMentoryTypes] = useState([]);

    const [userData, setUserData] = useState({
        Nombre: '',
        ApellidoPaterno: '',
        ApellidoMaterno: '',
        Cargo: '',
        clv_cargo: null,
        PaisResidencia: '',
        clv_paisresidencia: null,
        EstadoResidencia: '',
        clv_estadoresidencia: null,
        Empresa: '',
        EmpresaNombreCorto: '',
        Descripcion: '',
        Giro: '',
        clv_giro: null,
        Clasificacion: '',
        clv_clasificacion: null,
        URL: '',
        Tag1: '',
        Tag2: '',
        InvitadoPor: '',
        clv_usuarioInvitadoPor: null,
        clv_estatusbm: null,
        clv_estatusipade: null,
        isBM: false,
        isIpade: false,
        PhotoUrl: null,
        FotoUser: false,
        LogoUrl: null,
        FechaIpade: null,
        OfreceMentoria: false,
        clv_tipomentoria: null,
        Mentoria: '',
        OfreceDescuento: false,
        PorcentajeDescuento: '',
        ComentariosDescuento: '',
        CodigoInvitacion: ''
    });

    useEffect(() => {
        let isSuscribed = true;

        if (props.onToggleTab) {
            props.onToggleTab(true);
        }

        if (isSuscribed && props.match && props.match.params && props.match.params.step) {
            let value = props.match.params.step;

            let preForm = localStorage.getItem('preForm');

            if (preForm) {
                try {
                    preForm = JSON.parse(preForm);
                    setUserData(preForm);
                } catch (e) { }
            }

            if (value === 'init') {
                localStorage.setItem('step', value);
                setStep(value);
            } else if (value === 'steps') {
                let localStep = localStorage.getItem('step');

                if (localStep !== 'init' && localStep !== 'congrats') {
                    setStep(localStep);
                } else {
                    localStorage.setItem('step', 'init');
                    history.push("/register-wizard/init");
                }
            } else if (value === 'congrats') {
                let localStep = localStorage.getItem('step');

                if (localStep && localStep === value) {
                    setStep(localStep);
                } else {
                    localStorage.setItem('step', 'init');
                    history.push("/register-wizard/init");
                }
            } else {
                localStorage.setItem('step', 'init');
                history.push("/register-wizard/init");
            }

            infoService.catalogs.subscribe((catalogs) => {
                if (catalogs && catalogs.TiposMentoria && catalogs.TiposMentoria.length) {
                    setMentoryTypes(catalogs.TiposMentoria);
                }
            });
        }

        return () => isSuscribed = false
    }, []);

    useDidMountEffect(() => {
        let isSuscribed = true;

        if (isSuscribed && props.match && props.match.params && props.match.params.step) {

            let preForm = localStorage.getItem('preForm');

            if (preForm) {
                try {
                    preForm = JSON.parse(preForm);
                    setUserData(preForm);
                } catch (e) { }
            }
            let value = props.match.params.step;

            if (value === 'init') {
                localStorage.setItem('step', value);
                setStep(value);
            } else if (value === 'steps') {
                let localStep = localStorage.getItem('step');

                if (localStep !== 'init' && localStep !== 'congrats') {
                    setStep(localStep);
                } else {
                    localStorage.setItem('step', 'init');
                    history.push("/register-wizard/init");
                }
            } else if (value === 'congrats') {
                let localStep = localStorage.getItem('step');

                if (localStep && localStep === value) {
                    setStep(localStep);
                } else {
                    localStorage.setItem('step', 'init');
                    history.push("/register-wizard/init");
                }
            } else {
                localStorage.setItem('step', 'init');
                history.push("/register-wizard/init");
            }
        }

        return () => isSuscribed = false
    }, [props]);

    const onBackBtnTapped = () => {
        if (step !== 'init' && step !== 'congrats' && !isNaN(step)) {
            let value = parseInt(step);

            if (value === 1) {
                setIsAcceptedProfile(false);
                localStorage.setItem('step', `init`);
                history.push("/register-wizard/init");
            } else {
                value -= 1;
                localStorage.setItem('step', `${value}`);
                history.push(`/register-wizard/steps`);
            }
        }
    }

    const stepFormChecker = () => {
        if (!isNaN(step)) {
            const stepVal = parseInt(step);
            const userDataCP = JSON.parse(JSON.stringify(userData));
            if (stepVal === 1) {
                if (userData.InvitadoPor && userData.clv_usuarioInvitadoPor) {
                    return true;
                } else {
                    const msgError = `Debes seleccionar un usuario`;
                    swalAlertsService.showToast("error", msgError);
                }
            } else if (stepVal === 2) {
                if (userDataCP.Nombre && userDataCP.ApellidoPaterno) {
                    return true;
                } else {
                    const msgError = `Debes llenar los campos obligatorios: ${!userDataCP.Nombre ? 'Nombre' : ''}${!userDataCP.ApellidoPaterno ? `${!userDataCP.Nombre ? ', ' : ''} Apellido paterno` : ''}`;
                    swalAlertsService.showToast("error", msgError);
                }
            } else if (stepVal === 3) {
                if (userDataCP.clv_cargo && userDataCP.clv_paisresidencia && userDataCP.clv_estadoresidencia) {
                    return true;
                } else {
                    const msgError = `Debes llenar los campos obligatorios: ${!userDataCP.Cargo ? 'Puesto' : ''}${!userDataCP.PaisResidencia ? `${!userDataCP.Cargo ? ', ' : ''} País` : ''}${!userDataCP.EstadoResidencia ? `${!userDataCP.PaisResidencia || !userDataCP.Cargo ? ', ' : ''} Estado` : ''}`;
                    swalAlertsService.showToast("error", msgError);
                }
            } else if (stepVal === 4) {
                if (userDataCP.Empresa && userDataCP.EmpresaNombreCorto && userDataCP.Descripcion) {
                    return true;
                } else {
                    const msgError = `Debes llenar los campos obligatorios: ${!userDataCP.Empresa ? 'Empresa' : ''}${!userDataCP.EmpresaNombreCorto ? `${!userDataCP.Empresa ? ', ' : ''} Empresa nombre corto` : ''}${!userDataCP.Descripcion ? `${!userDataCP.Empresa || !userDataCP.EmpresaNombreCorto ? ', ' : ''} Descripción` : ''}`;
                    swalAlertsService.showToast("error", msgError);
                }
            } else if (stepVal === 5) {
                if (userDataCP.clv_giro && userDataCP.clv_clasificacion) {
                    return true;
                } else {
                    const msgError = `Debes llenar los campos obligatorios: ${!userDataCP.clv_giro ? 'Giro' : ''}${!userDataCP.clv_clasificacion ? `${!userDataCP.clv_giro ? ', ' : ''} Categoría` : ''}`;
                    swalAlertsService.showToast("error", msgError);
                }
                //} //else if (stepVal === 6 || stepVal === 10) {
                //return true;
            } else if (stepVal === 10) {
                return true;
            } else if (stepVal === 6) {
                if (userDataCP.Tag1) {
                    return true;
                } else {
                    const msgError = `Debes llenar los campos obligatorios: Palabra clave Nro 1`;
                    swalAlertsService.showToast("error", msgError);
                }
            } else if (stepVal === 7) {
                if (userDataCP.isBM) {
                    if (userDataCP.clv_estatusbm === null) {
                        const msgError = `Debes elegir si fuiste miembro o ex miembro de BoardMedia Digital`;
                        swalAlertsService.showToast("error", msgError);
                        return false;
                    }
                }

                if (userDataCP.isIpade) {
                    if (!userDataCP.clv_estatusipade || !userDataCP.FechaIpade) {
                        const msgError = `Debes elegir: ${!userDataCP.clv_estatusipade ? 'Catálogo de programa' : ''}${!userDataCP.FechaIpade ? `${!userDataCP.clv_estatusipade ? ', ' : ''}Fecha` : ''}`;
                        swalAlertsService.showToast("error", msgError);
                        return false;
                    }
                }

                return true;
            } else if (stepVal === 8) {
                if (userDataCP.OfreceMentoria) {
                    if (userDataCP.clv_tipomentoria === null) {
                        const msgError = `Debe seleccionar una opción de mentoría`;
                        swalAlertsService.showToast("error", msgError);
                        return false;
                    } else if (userDataCP.clv_tipomentoria === 4 && !userDataCP.Mentoria) {
                        const msgError = `Debe definir el nombre de la mentoría que ofrece`;
                        swalAlertsService.showToast("error", msgError);
                        return false;
                    }
                }
                return true;
            } else if (stepVal === 9) {

                if (userDataCP.OfreceDescuento && (userData.PorcentajeDescuento === '' || userData.PorcentajeDescuento === null || userData.PorcentajeDescuento === undefined || !parseInt(userData.PorcentajeDescuento))) {
                    const msgError = `Debes ingregar un porcentaje de descuento`;
                    swalAlertsService.showToast("error", msgError);
                    return false;
                }

                return true;
            }
        }

        return false;
    }

    const onNextBtnTapped = () => {
        if (!isNaN(step)) {
            if (parseInt(step) < 11 && stepFormChecker()) {
                const value = parseInt(step);
                if (value == 1) {
                    if (userData.InvitadoPor && userData.clv_usuarioInvitadoPor) {

                        let objSend = {
                            clv_usuario: userData.clv_usuarioInvitadoPor
                        }

                        if (userData.CodigoInvitacion && userData.CodigoInvitacion.trim().length) {
                            objSend['Codigo'] = userData.CodigoInvitacion;
                        }
                        profileService.postGeneric('user/referal', 'post', objSend).then(
                            (response) => {
                                if (response && response.message && response.message == "Se ha registrado el usuario que refirió correctamente") {
                                    // swalAlertsService.showToast("success", response.message);
                                    localStorage.setItem('step', `${value + 1}`);
                                    history.push(`/register-wizard/steps`);
                                } else {
                                    swalAlertsService.showToast("error", response);
                                }
                            }
                        )
                    } else {
                        swalAlertsService.showToast("error", "Tienes que seleccionar un usuario para continuar");
                    }
                } else if (value == 5) {
                    let regex = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/)
                    if (userData && userData.URL && userData.URL.trim().length) {
                        if (regex.test(userData.URL)) {
                            localStorage.setItem('step', `${value + 1}`);
                            history.push(`/register-wizard/steps`);
                        } else {
                            swalAlertsService.showToast("error", "El url es invalido");
                        }
                    } else {
                        localStorage.setItem('step', `${value + 1}`);
                        history.push(`/register-wizard/steps`);
                    }
                } else {
                    localStorage.setItem('step', `${value + 1}`);
                    history.push(`/register-wizard/steps`);
                }


            } else if (parseInt(step) === 11) {
                sendFormToApi();

            }
        }
    }

    const sendFormToApi = () => {

        let objSend = JSON.parse(JSON.stringify(userData));
        objSend.Imagen = null;
        objSend.Logo = null;

        if (objSend.PhotoUrl && objSend.PhotoUrl.indexOf('base64,') >= 0) {
            const dataSplitted = objSend.PhotoUrl.split(objSend.PhotoUrl.substring(0, objSend.PhotoUrl.indexOf('base64,') + 'base64,'.length));
            if (dataSplitted.length > 1) {

                objSend.Imagen = {
                    clv_tipoadjunto: 3,
                    Extension: 'jpg',
                    Base64: dataSplitted[1]
                }
            }

        }

        if (objSend.LogoUrl && objSend.LogoUrl.indexOf('base64,') >= 0) {
            const dataSplitted = objSend.LogoUrl.split(objSend.LogoUrl.substring(0, objSend.LogoUrl.indexOf('base64,') + 'base64,'.length));
            if (dataSplitted.length > 1) {

                objSend.Logo = {
                    clv_tipoadjunto: 3,
                    Extension: 'jpg',
                    Base64: dataSplitted[1]
                }
            }
        }

        delete objSend.PhotoUrl;
        delete objSend.LogoUrl;
        delete objSend.isBM;
        delete objSend.isIpade;
        delete objSend.FotoUser;

        let loader = swalAlertsService.createLoading("Espere un momento...");
        loader.fire();
        profileService.postGeneric('user/info', 'POST', objSend).then(
            (response) => {
                loader.close();
                if (response) {
                    if (response.data) {
                        swalAlertsService.showToast('success', 'Información de usuario enviada correctamente');


                        authenticationService.getApiUser().then(() => {
                            if (objSend.CodigoInvitacion && objSend.CodigoInvitacion.trim().length) {
                                profileService.postGeneric('user/membership/code', 'POST', { Codigo: objSend.CodigoInvitacion }).then(
                                    (response) => {
                                        if (response && response.data && response.message) {
                                            localStorage.setItem('step', `congrats`);
                                            localStorage.removeItem('preForm');
                                            history.push(`/`);
                                        } else {
                                            localStorage.setItem('step', `congrats`);
                                            localStorage.removeItem('preForm');
                                            history.push(`/register-wizard/congrats`);
                                        }
                                    })
                            } else {
                                localStorage.setItem('step', `congrats`);
                                localStorage.removeItem('preForm');
                                history.push(`/register-wizard/congrats`);
                            }
                        });


                    } else {
                        swalAlertsService.showToast('error', 'No pudimos enviar la información de usuario');
                    }
                }
            },
            (error) => {
                loader.close();
                swalAlertsService.showToast('error', 'No pudimos enviar la información de usuario');
            }
        )
    }

    const initialView = () => {
        return (
            <div className="RegisterWizard-preaccept row">
                <div className="col-12 RegisterWizard-preaccept-main-container">
                    <div className="row m-0">
                        <div className="col-12 px-0 RegisterWizard-preaccept-logo-container">
                            <img src={imgLogo} alt="wenet logo" />
                        </div>

                        <div className="col-12 px-0 RegisterWizard-preaccept-title-container">
                            WeNet es una aplicación para:
                        </div>
                    </div>

                    <div className="row m-0 RegisterWizard-preaccept-items-container">
                        <div className="col-12 px-0">
                            <div className="row mx-0 RegisterWizard-preaccept-item-row">
                                <div className="col RegisterWizard-preaccept-item-icon">
                                    <img src={icnsTogglers} alt="check icon" />
                                </div>
                                <div className="col noselect">Dueño</div>
                            </div>
                        </div>

                        <div className="col-12 px-0">
                            <div className="row mx-0 RegisterWizard-preaccept-item-row">
                                <div className="col RegisterWizard-preaccept-item-icon">
                                    <img src={icnsTogglers} alt="check icon" />
                                </div>
                                <div className="col noselect">Socio</div>
                            </div>
                        </div>

                        <div className="col-12 px-0">
                            <div className="row mx-0 RegisterWizard-preaccept-item-row">
                                <div className="col RegisterWizard-preaccept-item-icon">
                                    <img src={icnsTogglers} alt="check icon" />
                                </div>
                                <div className="col noselect">Miembro de consejo</div>
                            </div>
                        </div>

                        <div className="col-12 px-0">
                            <div className="row mx-0 RegisterWizard-preaccept-item-row">
                                <div className="col RegisterWizard-preaccept-item-icon">
                                    <img src={icnsTogglers} alt="check icon" />
                                </div>
                                <div className="col noselect">Director General</div>
                            </div>
                        </div>

                        <div className="col-12 px-0">
                            <div className="row mx-0 RegisterWizard-preaccept-item-row">
                                <div className="col RegisterWizard-preaccept-item-icon">
                                    <img src={icnsTogglers} alt="check icon" />
                                </div>
                                <div className="col noselect">Profesional independiente</div>
                            </div>
                        </div>

                        <div className="col-12 px-0">
                            <div className="row mx-0 RegisterWizard-preaccept-item-row">
                                <div className="col RegisterWizard-preaccept-item-icon">
                                    <img src={icnsTogglers} alt="check icon" />
                                </div>
                                <div className="col noselect">Emprendedor</div>
                            </div>
                        </div>
                    </div>

                    <div className="row m-0">
                        <div className="col-12 px-0 RegisterWizard-preaccept-title-container">
                            En caso de no cumplir con alguno de estos perfiles, sugerimos no avanzar.
                        </div>
                    </div>
                </div>
                <div className="col-12 RegisterWizard-preaccept-submit-container">
                    <button className="btn-cleared" onClick={(e) => {
                        localStorage.setItem('step', '1');
                        history.push("/register-wizard/steps")
                    }}>
                        Continuar
                    </button>
                </div>
            </div>
        );
    }

    const finalView = () => {
        return (
            <div className="RegisterWizard-preaccept row">
                <div className="col-12 RegisterWizard-accept-main-container">
                    <div className="row m-0">
                        <div className="col-12 px-0 RegisterWizard-accept-logo-container">
                            <img src={imgLogo} alt="wenet logo" />
                        </div>

                        <div className="col-12 px-0 RegisterWizard-accept-title-container">
                            <span className='font-weight-bold'>¡Gracias!</span><br /><br />
                            Una vez concluido todos los pasos necesarios para tu integración, te recomendamos ir a tu perfil y completar algunos otros datos que te ayudarán a potenciar tu networking con los miembros de WeNet.
                        </div>
                    </div>
                </div>
                <div className="col-12 RegisterWizard-preaccept-submit-container">
                    <button className="btn-cleared isGreen" onClick={e => {
                        localStorage.removeItem('step');
                        history.push("/register-steps?continue=true");
                    }}>
                        De acuerdo
                    </button>
                </div>
            </div>
        );
    }

    const stepHeader = () => {
        return (
            <div className="col-12 RegisterWizard-steps-header-container">
                <img src={imgLogo} alt="wenet logo" /> <span>{step}/11</span>
            </div>
        )
    }

    const stepFooter = () => {
        return (
            <div className="col-12 RegisterWizard-steps-footer-container">
                <button className="btn-cleared RegisterWizard-back-btn" onClick={onBackBtnTapped}>
                    <img src={icnBackArrow} alt="back arrow" width='30' height='30' />
                </button>
                <button className={`btn-cleared RegisterWizard-submit-btn ${step === '11' ? 'last-step' : ''}`} onClick={onNextBtnTapped}>
                    {step === '11' ? 'Finalizar' : 'Siguiente'}
                </button>
            </div>
        )
    }

    const setInputForm = (key, e) => {
        if (key && e) {
            let userDataCP = JSON.parse(JSON.stringify(userData));
            console.log(userDataCP);
            if (key in userDataCP) {
                if (key === 'CodigoInvitacion') {
                    userDataCP[key] = e.target.value.replace(/[^\w\s]/gi, "").substr(0, 9);
                    localStorage.setItem('preForm', JSON.stringify(userDataCP));
                    setUserData(userDataCP);
                } else {
                    userDataCP[key] = e.target.value;
                    localStorage.setItem('preForm', JSON.stringify(userDataCP));
                    setUserData(userDataCP);
                }

            }
        }
    }

    const defineUniversalPickerType = (type) => {
        if (type === "state") {
            if (userData.clv_paisresidencia) {
                setType(type);
                setOpenUniversalPicker(true);
            } else {
                swalAlertsService.showToast("error", "Debes elegir un pais");
            }
        } else {
            setType(type);
            setOpenUniversalPicker(true);
        }
    }

    const onUniverlPickerHide = (data) => {
        setOpenUniversalPicker(data);
    }

    const onBottomSheetDimiss = () => {
        setIsContactAdding(false);
    }

    const onContantSendBtnTapped = (element) => {
        let userDataCP = JSON.parse(JSON.stringify(userData));
        userDataCP = { ...userDataCP, clv_usuarioInvitadoPor: element.clv_usuario, InvitadoPor: element.NombreCompleto };
        localStorage.setItem('preForm', JSON.stringify(userDataCP));
        setUserData(userDataCP);
        onBottomSheetDimiss();
    }

    const onElementSelected = (element) => {
        let userDataCP = JSON.parse(JSON.stringify(userData));
        switch (type) {
            case 'giro':
                userDataCP = { ...userDataCP, clv_giro: element.clv_giro, Giro: element.Giro };
                localStorage.setItem('preForm', JSON.stringify(userDataCP));
                setUserData(userDataCP);
                break;
            case 'category':
                userDataCP = { ...userDataCP, clv_clasificacion: element.clv_clasificacion, Clasificacion: element.Clasificacion };
                localStorage.setItem('preForm', JSON.stringify(userDataCP));
                setUserData(userDataCP);
                break;

            case 'hobby':
                userDataCP = { ...userDataCP, clv_aficion: element.clv_aficion, Aficion: element.Aficion };
                localStorage.setItem('preForm', JSON.stringify(userDataCP));
                setUserData(userDataCP);
                break;
            case 'study':
                userDataCP = { ...userDataCP, clv_nivelestudio: element.clv_nivelestudio, NivelEstudio: element.NivelEstudio };
                localStorage.setItem('preForm', JSON.stringify(userDataCP));
                setUserData(userDataCP);
                break;
            case 'post':
                userDataCP = { ...userDataCP, clv_cargo: element.clv_cargo, Cargo: element.Cargo };
                localStorage.setItem('preForm', JSON.stringify(userDataCP));
                setUserData(userDataCP);
                break;
            case 'country':
                userDataCP = { ...userDataCP, clv_paisresidencia: element.clv_pais, PaisResidencia: element.Pais };
                localStorage.setItem('preForm', JSON.stringify(userDataCP));
                setUserData(userDataCP);
                break;
            case 'state':
                userDataCP = { ...userDataCP, clv_estadoresidencia: element.clv_estado, EstadoResidencia: element.Estado };
                localStorage.setItem('preForm', JSON.stringify(userDataCP));
                setUserData(userDataCP);
                break;
            default:
                break;
        }
    }

    const onBtnOptionsPick = (type) => {
        let userDataCP = JSON.parse(JSON.stringify(userData));

        if (type === 'bm') {
            userDataCP.isBM = !userDataCP.isBM;
            // userDataCP.isIpade = false;
            // userDataCP.clv_estatusipade = null;
            // userDataCP.FechaIpade = null;
        } else if (type === 'ipade') {
            // userDataCP.isBM = false;
            userDataCP.isIpade = !userDataCP.isIpade;
            // userDataCP.clv_estatusbm = null;
        } else if (type === 'no') {
            userDataCP.isBM = false;
            userDataCP.isIpade = false;
            userDataCP.clv_estatusbm = null;
            userDataCP.clv_estatusipade = null;
            userDataCP.FechaIpade = null;
        }
        localStorage.setItem('preForm', JSON.stringify(userDataCP));
        setUserData(userDataCP);
    }

    const giveMentor = (value) => {
        let userDataCP = JSON.parse(JSON.stringify(userData));

        if (userDataCP.OfreceMentoria !== value) {
            userDataCP.OfreceMentoria = value;
            userDataCP.clv_tipomentoria = null;
            userDataCP.Mentoria = '';
            localStorage.setItem('preForm', JSON.stringify(userDataCP));
            setUserData(userDataCP);
        }
    }

    const giveDiscount = (value) => {
        let userDataCP = JSON.parse(JSON.stringify(userData));

        if (userDataCP.OfreceDescuento !== value) {
            userDataCP.OfreceDescuento = value;
            userDataCP.PorcentajeDescuento = '';
            userDataCP.ComentariosDescuento = '';
            localStorage.setItem('preForm', JSON.stringify(userDataCP));
            setUserData(userDataCP);
        }
    }

    const bmTypePicker = (type) => {

        if (type) {
            let userDataCP = JSON.parse(JSON.stringify(userData));

            userDataCP.clv_estatusbm = type === 4 ? 4 : 3;
            localStorage.setItem('preForm', JSON.stringify(userDataCP));
            setUserData(userDataCP);
        }
    }

    async function getFile(event, type) {
        if (event.target && event.target.files && event.target.files.length) {
            const result = await toolsHelper.toBase64(event.target.files[0]).catch(e => console.log(e));

            if (result && type) {
                if (type === 'image') {
                    handleTakePhoto(result);
                } else {
                    handleFile(result, event.target.files[0]);
                }
            }
        }
    }

    const handleTakePhoto = (dataUri) => {

        if (openCamera) {
            setOpenCamera(false);
        }
        if (dataUri && dataUri.indexOf('base64,') >= 0) {
            const dataSplitted = dataUri.split(dataUri.substring(0, dataUri.indexOf('base64,') + 'base64,'.length));
            if (dataSplitted.length > 1) {
                defineImageUser(dataUri)
            }
        }
    }

    const handleFile = (dataUri, extraData) => {

        if (dataUri && dataUri.indexOf('base64,') >= 0) {
            const dataSplitted = dataUri.split(dataUri.substring(0, dataUri.indexOf('base64,') + 'base64,'.length));

            if (dataSplitted.length > 1) {
                setImageData({
                    base64: dataUri,
                    splitted: dataSplitted[1]
                });
                defineImageUser();
            }
        }
    }

    const defineImageUser = async (data) => {
        let imageDataCP = JSON.parse(JSON.stringify(imageData));
        imageDataCP.base64 = data;
        setImageData(imageDataCP);
        setVisibleCrop(true)
    }

    const onCropImage = async (image) => {
        var uri = URL.createObjectURL(image.blob);
        // setUserData({...userData, PhotoUrl: uri});
        setVisibleCrop(false);
        let loader = swalAlertsService.createLoading("Espere un momento...");
        loader.fire();
        var reader = new FileReader();
        reader.readAsDataURL(image.blob);
        reader.onloadend = async function () {
            var data = reader.result;
            let userDataCP = JSON.parse(JSON.stringify(userData));

            if (step && !isNaN(step)) {
                let value = parseInt(step);

                if (value === 10) {
                    userDataCP = { ...userData, PhotoUrl: data };
                } else {
                    userDataCP = { ...userData, LogoUrl: data };
                }

                setUserData(userDataCP);
                localStorage.setItem('preForm', JSON.stringify(userDataCP));
            }

            loader.close();
            // var data = reader.result;
            // let userDataCP = JSON.parse(JSON.stringify(userData));
            // userDataCP = {...userData, PhotoUrl: data, FotoUser: true};
            // setUserData(userDataCP);
            // localStorage.setItem('preForm', JSON.stringify(userDataCP));
            // loader.close();
            // let loader = swalAlertsService.createLoading("Espere un momento...")

            // loader.fire()
            // const resp = await profileService.updateImage(data.split(",")[1],3, "jpg");
            // resp.text().then(text => {
            //     let userDataCP = JSON.parse(JSON.stringify(userData));
            //     userDataCP = {...userData, PhotoUrl: JSON.parse(text).message, FotoUser: true};
            //     setUserData(userDataCP);
            //     localStorage.setItem('preForm', userDataCP);
            //     loader.close();
            // })


        }
    }

    const onCancelCrop = () => {
        setVisibleCrop(false)
    }

    const onImageDelete = (key) => {

        if (key === 'LogoUrl' || key === 'PhotoUrl') {
            let userDataCP = JSON.parse(JSON.stringify(userData));
            userDataCP[key] = null;
            localStorage.setItem('preForm', JSON.stringify(userDataCP));
            setUserData(userDataCP);

            setImageData({
                base64: null,
                splitted: null
            });
        }

    }

    const onIpadeOptionPicked = (value) => {
        if (value) {
            let userDataCP = JSON.parse(JSON.stringify(userData));
            userDataCP.clv_estatusipade = value;
            localStorage.setItem('preForm', JSON.stringify(userDataCP));
            setUserData(userDataCP);
        }
    }

    const ipadeDateBuilder = () => {
        let items = [];
        let now = new Date();
        const actualYear = now.getFullYear();
        const fundationDate = 1967;

        for (let i = fundationDate; i <= actualYear; i++) {
            items.push(<option value={i}>{i}</option>);
        }

        return items;
    }

    const onIpadeDateSelected = (e) => {
        let userDataCP = JSON.parse(JSON.stringify(userData));
        userDataCP.FechaIpade = e.target.value;
        localStorage.setItem('preForm', JSON.stringify(userDataCP));
        setUserData(userDataCP);
    }

    const getMentoryOptions = () => {
        if (mentoryTypes && mentoryTypes.length) {
            return mentoryTypes.map((mentory, index) => {
                return (
                    <div className="col-12 px-0 RegisterWizard-form-disclaimer-form-option-container" key={index}>
                        <div className="row m-0">
                            <div className="col-12 px-0">
                                <button className={`mainBtn ${userData.clv_tipomentoria && userData.clv_tipomentoria === mentory.clv_tipomentoria ? 'active' : ''}`} onClick={
                                    (e) => {
                                        let userDataCP = JSON.parse(JSON.stringify(userData));
                                        userDataCP.clv_tipomentoria = mentory.clv_tipomentoria;
                                        if (mentory.clv_tipomentoria !== 4) {
                                            userDataCP.Mentoria = mentory.Mentoria;
                                        } else {
                                            userDataCP.Mentoria = '';
                                        }

                                        localStorage.setItem('preForm', JSON.stringify(userDataCP));
                                        setUserData(userDataCP);
                                    }}>
                                    <span className='text-truncate'>{mentory.Mentoria}</span> <img src={icnsTogglers} alt="toggler icon" className='ss isBullet' width='30' height='30' />
                                </button>
                            </div>
                        </div>
                    </div>
                );
            });
        }
        return null;
    }

    const formItems = (position) => {
        switch (position) {
            case '11':
                return (
                    <>
                        <div className="col-12 px-0 RegisterWizard-form-disclaimer">
                            <div className="row m-0">
                                <div className="col-12 px-0 RegisterWizard-form-disclaimer-title">
                                    Agrega el logo de tu empresa
                                </div>
                                <div className="col-12 px-0 RegisterWizard-form-disclaimer-subtitle">
                                    Por favor elige una imagen con buena calidad.
                                </div>
                            </div>
                        </div>

                        <div className="col-12 p-0 RegisterWizard-form-media">
                            <img src={userData.LogoUrl != null ? userData.LogoUrl : imgCompanyPlaceholder} alt="imgCompanyPlaceholder" className='imgbg' />
                            {
                                userData.LogoUrl ? (
                                    <button className="eraserBtn btn-cleared" onClick={e => onImageDelete('LogoUrl')}>
                                        Eliminar Logo
                                    </button>
                                ) : (
                                    <>
                                        <input id="image"
                                            type="file"
                                            ref={inputCompanyImage}
                                            onChange={event => getFile(event, 'image')}
                                            onError={error => console.log(error)}
                                            accept="image/png, image/gif, image/jpeg"
                                            multiple={false}
                                            name="image"
                                        />

                                        <button onClick={e => {
                                            if (inputCompanyImage && inputCompanyImage.current) {
                                                inputCompanyImage.current.click();
                                            }
                                        }} className='uploaderBtn'>
                                            <img src={icnUpload} alt="icnUpload" width='70' height='70' />
                                            <span>Subir foto</span>
                                            Peso máximo: 10MB
                                        </button>
                                    </>
                                )
                            }
                        </div>
                    </>
                );
                break;
            case '10':
                return (
                    <>
                        <div className="col-12 px-0 RegisterWizard-form-disclaimer">
                            <div className="row m-0">
                                <div className="col-12 px-0 RegisterWizard-form-disclaimer-title">
                                    Agrega una foto de perfil
                                </div>
                                <div className="col-12 px-0 RegisterWizard-form-disclaimer-subtitle">
                                    Por favor elige una imagen con buena calidad de tu rostro para que puedan identificarte.
                                </div>
                            </div>
                        </div>

                        <div className="col-12 p-0 RegisterWizard-form-media">
                            <img src={userData.PhotoUrl != null ? userData.PhotoUrl : imgUserPlaceholder} alt="imgUserPlaceholder" className='imgbg' />
                            {
                                userData.PhotoUrl ? (
                                    <button className="eraserBtn btn-cleared" onClick={e => onImageDelete('PhotoUrl')}>
                                        Eliminar Foto
                                    </button>
                                ) : (
                                    <>
                                        <input id="image"
                                            type="file"
                                            ref={inputProfileImage}
                                            onChange={event => getFile(event, 'image')}
                                            onError={error => console.log(error)}
                                            accept="image/png, image/gif, image/jpeg"
                                            multiple={false}
                                            name="image"
                                        />

                                        <button onClick={e => {
                                            if (inputProfileImage && inputProfileImage.current) {
                                                inputProfileImage.current.click();
                                            }
                                        }} className='uploaderBtn'>
                                            <img src={icnUpload} alt="icnUpload" width='70' height='70' />
                                            <span>Subir foto</span>
                                            Peso máximo: 10MB
                                        </button>
                                    </>
                                )
                            }
                        </div>
                    </>
                );
                break;
            case '9':
                return (
                    <>
                        <div className="col-12 px-0 RegisterWizard-form-disclaimer">
                            <div className="row m-0">
                                <div className="col-12 px-0 RegisterWizard-form-disclaimer-subtitle">
                                    ¿Podrías ofrecer algún descuento a miembros WeNet?
                                    <br /><br />

                                    <div className="row m-0 pickerRow">
                                        <div className="col px-0">
                                            <button className={`btn-cleared ${!userData.OfreceDescuento ? 'active' : ''}`} onClick={e => giveDiscount(false)}>No</button>
                                        </div>
                                        <div className="col px-0">
                                            <button className={`btn-cleared ${userData.OfreceDescuento ? 'active' : ''}`} onClick={e => giveDiscount(true)}>Si</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                userData.OfreceDescuento ? (
                                    <>
                                        <div className="row m-0 RegisterWizard-form-disclaimer-form-container">
                                            <div className="container_edit_company_input px-0">
                                                <p>¿De qué porcentaje?</p>
                                                <div className="container_edit_user_gender_container blue px-0">
                                                    <div className="container_edit_user_gender_container_option">
                                                        <div className="container_edit_company_gender_container_option_field m" >
                                                            <input type="text" className='percent' placeholder='0%' value={userData.PorcentajeDescuento} onChange={(e) => {
                                                                let userDataCP = JSON.parse(JSON.stringify(userData));

                                                                if (e.target.value) {
                                                                    let value = e.target.value.replace(/\D/g, '');
                                                                    value = value > 100 ? 100 : value;

                                                                    value = value < 0 ? 0 : value;
                                                                    userDataCP.PorcentajeDescuento = value;
                                                                } else {
                                                                    userDataCP.PorcentajeDescuento = '';
                                                                }

                                                                localStorage.setItem('preForm', JSON.stringify(userDataCP));
                                                                setUserData(userDataCP);

                                                            }} />
                                                            {
                                                                userData.PorcentajeDescuento !== '' && userData.PorcentajeDescuento !== null && userData.PorcentajeDescuento !== undefined ? (
                                                                    <span className="percentSymbol noselect">%</span>
                                                                ) : (null)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="container_edit_company_input px-0">
                                                <p>Comentarios</p>
                                                <div className="container_edit_company_input_field" >
                                                    <textarea type="text" rows="5" cols="42" width="100%" value={userData.ComentariosDescuento} onChange={(e) => {
                                                        let userDataCP = JSON.parse(JSON.stringify(userData));

                                                        if (e.target.value) {
                                                            userDataCP.ComentariosDescuento = e.target.value;
                                                        } else {
                                                            userDataCP.ComentariosDescuento = '';
                                                        }

                                                        localStorage.setItem('preForm', JSON.stringify(userDataCP));
                                                        setUserData(userDataCP);
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (null)
                            }
                        </div>
                    </>
                );
                break;
            case '8':
                return (
                    <>
                        <div className="col-12 px-0 RegisterWizard-form-disclaimer">
                            <div className="row m-0">
                                <div className="col-12 px-0 RegisterWizard-form-disclaimer-subtitle">
                                    ¿Podrías ofrecer una sesión de Mentoria algún miembro WeNet?
                                    <br /><br />

                                    <div className="row m-0 pickerRow">
                                        <div className="col px-0">
                                            <button className={`btn-cleared ${!userData.OfreceMentoria ? 'active' : ''}`} onClick={e => giveMentor(false)}>No</button>
                                        </div>
                                        <div className="col px-0">
                                            <button className={`btn-cleared ${userData.OfreceMentoria ? 'active' : ''}`} onClick={e => giveMentor(true)}>Si</button>
                                        </div>
                                    </div>

                                    <br /><br />


                                    {
                                        userData.OfreceMentoria ? ('¿Sobre que tema?') : (null)
                                    }
                                </div>
                            </div>

                            {
                                userData.OfreceMentoria ? (
                                    <>
                                        <div className="row m-0 RegisterWizard-form-disclaimer-form-container">
                                            {getMentoryOptions()}
                                        </div>

                                        {
                                            userData.clv_tipomentoria === 4 ? (
                                                <div className="row m-0 RegisterWizard-form-disclaimer-other-row">
                                                    <p className='subtitleP px-0'>¿Qué tema?</p>

                                                    <div className="container_edit_user_input_field fullCol px-0" >
                                                        <input type="text" value={userData.Mentoria} onChange={(e) => {
                                                            let userDataCP = JSON.parse(JSON.stringify(userData));

                                                            if (e.target.value) {
                                                                userDataCP.Mentoria = e.target.value;
                                                            } else {
                                                                userDataCP.Mentoria = '';
                                                            }

                                                            localStorage.setItem('preForm', JSON.stringify(userDataCP));
                                                            setUserData(userDataCP);

                                                        }} className='noIcon' placeholder='Escribir tema' />
                                                    </div>
                                                </div>
                                            ) : (null)
                                        }
                                    </>
                                ) : (null)
                            }
                        </div>
                    </>
                );
                break;
            case '7':
                return (
                    <>
                        <div className="col-12 px-0 RegisterWizard-form-disclaimer">
                            <div className="row m-0">
                                <div className="col-12 px-0 RegisterWizard-form-disclaimer-subtitle">
                                    Somos una Comunidad de Comunidades, si tú perteneces alguna de ellas por favor indícalo
                                    <br /><br />
                                    ¿Perteneces o perteneciste a alguna de las siguientes asociaciones? *
                                </div>
                            </div>
                        </div>

                        <div className="col-12 px-0 RegisterWizard-form-option-container">
                            <div className="row m-0">
                                <div className="col-12 px-0">
                                    <button className={`mainBtn ${!userData.isBM && !userData.isIpade ? 'active' : ''}`} onClick={e => onBtnOptionsPick('no')}>
                                        <span className='text-truncate'>Ninguna</span> <img src={icnsTogglers} alt="toggler icon" className='ss' width='30' height='30' />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 px-0 RegisterWizard-form-option-container">
                            <div className="row m-0">
                                <div className="col-12 px-0">
                                    <button className={`mainBtn ${userData.isBM ? 'active' : ''}`} onClick={e => onBtnOptionsPick('bm')}>
                                        <span className='text-truncate'>BoardMedia Digital</span> <img src={icnsTogglers} alt="toggler icon" className='ss' width='30' height='30' />
                                    </button>
                                </div>

                                {
                                    userData.isBM ? (
                                        <div className="col-12 px-0 RegisterWizard-form-suboptions-container pb-0">
                                            <div className="row m-0">
                                                <div className="col-12 px-0 subOptionsTitle">
                                                    Elige una opción:
                                                </div>
                                                <div className="col-12 px-0">
                                                    <div className="row m-0 d-flex justify-content-center btns-Options">
                                                        <button className={`col left ${userData.clv_estatusbm === 4 ? 'active' : ''}`} onClick={e => bmTypePicker(4)}>Miembro<br />activo</button>
                                                        <button className={`col right ${userData.clv_estatusbm === 3 ? 'active' : ''}`} onClick={e => bmTypePicker(3)}>Ex miembro</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (null)
                                }

                                <div className="col-12 px-0 RegisterWizard-form-suboptions-container py-1">
                                    <div className="row m-0">
                                        <div className="col-12 px-0 linkBox pt-0">
                                            <a href="https://www.bmdigital.mx" target='_blank'>www.bmdigital.mx</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 px-0 RegisterWizard-form-option-container">
                            <div className="row m-0">
                                <div className="col-12 px-0">
                                    <button className={`mainBtn ${userData.isIpade ? 'active' : ''}`} onClick={e => onBtnOptionsPick('ipade')}>
                                        <span className='text-truncate'>IPADE</span> <img src={icnsTogglers} alt="toggler icon" className='ss' width='30' height='30' />
                                    </button>
                                </div>

                                {
                                    userData.isIpade ? (
                                        <div className="col-12 px-0 RegisterWizard-form-suboptions-container pb-0">
                                            <div className="row m-0">
                                                <div className="col-12 px-0 subOptionsTitle">
                                                    Catálogo de programa:
                                                </div>

                                                <div className="col-12 px-0">
                                                    <div className="row m-0 d-flex justify-content-center list-Options">
                                                        <div className={`col-12 px-0 listOption isFirst ${userData.clv_estatusipade === 1 ? 'isActive' : ''}`} onClick={e => onIpadeOptionPicked(1)}>
                                                            <span>Alta Dirección</span>
                                                            <img src={icnsTogglers} alt="toggler icon" width='30' height='30' />
                                                        </div>

                                                        <div className={`col-12 px-0 listOption ${userData.clv_estatusipade === 2 ? 'isActive' : ''}`} onClick={e => onIpadeOptionPicked(2)}>
                                                            <span>Maestría</span>
                                                            <img src={icnsTogglers} alt="toggler icon" width='30' height='30' />
                                                        </div>

                                                        <div className={`col-12 px-0 listOption ${userData.clv_estatusipade === 3 ? 'isActive' : ''}`} onClick={e => onIpadeOptionPicked(3)}>
                                                            <span>Programa Temático</span>
                                                            <img src={icnsTogglers} alt="toggler icon" width='30' height='30' />
                                                        </div>

                                                        <div className={`col-12 px-0 listOption ${userData.clv_estatusipade === 4 ? 'isActive' : ''}`} onClick={e => onIpadeOptionPicked(4)}>
                                                            <span>Profesor</span>
                                                            <img src={icnsTogglers} alt="toggler icon" width='30' height='30' />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 px-0">
                                                    <div className="row m-0">
                                                        <div className="col-12 px-0 selectOptions-container">
                                                            <label htmlFor="ipadeDate">Fecha:</label>
                                                            <select name="ipadeDate" id="ipadeDate" value={userData.FechaIpade} onChange={onIpadeDateSelected}>
                                                                <option value="" selected disabled={true}>Seleccionar año</option>
                                                                {ipadeDateBuilder()}
                                                            </select>
                                                            <img src={icnArrowDown} alt="arrow down" width='30' height='30' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (null)
                                }

                                <div className="col-12 px-0 RegisterWizard-form-suboptions-container py-1">
                                    <div className="row m-0">
                                        <div className="col-12 px-0 linkBox pt-0">
                                            <a href="https://www.ipade.mx/" target='_blank'>www.ipade.mx</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;
            case '6':
                return (
                    <>
                        <div className="col-12 px-0 RegisterWizard-form-disclaimer">
                            <div className="row m-0">
                                <div className="col-12 px-0 RegisterWizard-form-disclaimer-title">
                                    Etiquetas de búsqueda
                                </div>
                                <div className="col-12 px-0 RegisterWizard-form-disclaimer-subtitle">
                                    Escribe dos palabras clave para que otros miembros de WeNet te puedan localizar.
                                    <br /><br />
                                    Por ejemplo: al escribir en el buscador del directorio “Contadores” aparecerán todos los miembros con esa palabra clave.
                                </div>
                            </div>
                        </div>
                        <div className="col-12 RegisterWizard-form-group hasNumber">
                            <label htmlFor="tag1" className="numberLabel">
                                1.
                            </label>
                            <input id='tag1' name='tag1' type="text" placeholder='Ej. Alimentos*' value={userData.Tag1} onChange={e => setInputForm("Tag1", e)} />
                        </div>

                        <div className="col-12 RegisterWizard-form-group hasNumber">
                            <label htmlFor="tag2" className="numberLabel">
                                2.
                            </label>
                            <input id='tag2' name='tag2' type="text" placeholder='Ej. eCommerce' value={userData.Tag2} onChange={e => setInputForm("Tag2", e)} />
                        </div>
                    </>
                );
                break;
            case '5':
                return (
                    <>
                        <div className="col-12 RegisterWizard-form-group hasIcon">
                            <label htmlFor="url" className='textLbl'>Sitio web de la empresa</label>
                            <label htmlFor="url" className="icnLabel">
                                <img src={icnsReg} alt="input icon" className='formicon-7' />
                            </label>
                            <input id='url' name='url' type="text" placeholder='https://www.ejemplo.com' value={userData.URL} onChange={e => setInputForm("URL", e)} />
                        </div>

                        <div className="col-12 RegisterWizard-form-group">
                            <label htmlFor="giro" className='textLbl'>¿Cuál es el giro?*</label>
                            <button id='giro' name='giro' onClick={() => defineUniversalPickerType("giro")} className={userData.Giro ? 'isActive' : ''}>
                                <span className='text-truncate'>{userData.Giro ? userData.Giro : 'Selecciona una opción'}</span> <img src={icnArrowDown} alt="arrow down" width='30' height='30' />
                            </button>
                        </div>

                        <div className="col-12 RegisterWizard-form-group">
                            <label htmlFor="categoria" className='textLbl'>Elige una categoría*</label>
                            <button id='categoria' name='categoria' onClick={() => defineUniversalPickerType("category")} className={userData.Clasificacion ? 'isActive' : ''}>
                                <span className='text-truncate'>{userData.Clasificacion ? userData.Clasificacion : 'Selecciona una opción'}</span> <img src={icnArrowDown} alt="arrow down" width='30' height='30' />
                            </button>
                        </div>
                    </>
                );
                break;
            case '4':
                return (
                    <>
                        <div className="col-12 px-0 RegisterWizard-form-disclaimer">
                            <div className="row m-0">
                                <div className="col-12 px-0 RegisterWizard-form-disclaimer-title">
                                    Acerca de tu empresa
                                </div>
                                <div className="col-12 px-0 RegisterWizard-form-disclaimer-subtitle">
                                    Tus datos te servirán para potencializar tu networking.
                                </div>
                            </div>
                        </div>
                        <div className="col-12 RegisterWizard-form-group hasIcon">
                            <label htmlFor="empresa" className='textLbl'>Nombre completo de la empresa*</label>
                            <label htmlFor="empresa" className="icnLabel">
                                <img src={icnsReg} alt="input icon" className='formicon-4' />
                            </label>
                            <input id='empresa' name='empresa' type="text" placeholder='Escribe aquí tu respuesta' value={userData.Empresa} onChange={e => setInputForm("Empresa", e)} />
                        </div>

                        <div className="col-12 RegisterWizard-form-group hasIcon">
                            <label htmlFor="empresaCorto" className='textLbl'>Nombre corto de la empresa*</label>
                            <label htmlFor="empresaCorto" className="icnLabel">
                                <img src={icnsReg} alt="input icon" className='formicon-4' />
                            </label>
                            <input id='empresaCorto' name='empresaCorto' type="text" placeholder='Escribe aquí tu respuesta' value={userData.EmpresaNombreCorto} onChange={e => setInputForm("EmpresaNombreCorto", e)} />
                        </div>

                        <div className="col-12 RegisterWizard-form-group">
                            <label htmlFor="description" className='textLbl'>Descripción de la empresa*</label>
                            <textarea name="description" maxLength='400' placeholder='Describe brevemente la información más relevante de la empresa…' value={userData.Descripcion} onChange={e => setInputForm("Descripcion", e)}></textarea>
                            <span className="RegisterWizard-textarea-counter">{userData.Descripcion.length} / 400</span>
                        </div>
                    </>
                );
                break;
            case '3':
                return (
                    <>
                        <div className="col-12 RegisterWizard-form-group hasIcon">
                            <label htmlFor="cargo" className='textLbl'>Puesto actual que desempeñas*</label>
                            <label htmlFor="cargo" className="icnLabel">
                                <img src={icnsReg} alt="input icon" className='formicon-6' />
                            </label>
                            <button id='cargo' name='cargo' onClick={() => defineUniversalPickerType("post")} className={userData.Cargo ? 'isActive' : ''}>
                                <span className='text-truncate'>{userData.Cargo ? userData.Cargo : 'Selecciona una opción'}</span> <img src={icnArrowDown} alt="arrow down" width='30' height='30' />
                            </button>
                        </div>

                        <div className="col-12 RegisterWizard-form-group">
                            <label htmlFor="paisResidencia" className='textLbl'>País de residencia*</label>
                            <button id='paisResidencia' name='paisResidencia' onClick={() => defineUniversalPickerType("country")} className={userData.PaisResidencia ? 'isActive' : ''}>
                                <span className='text-truncate'>{userData.PaisResidencia ? userData.PaisResidencia : 'Selecciona una opción'}</span> <img src={icnArrowDown} alt="arrow down" width='30' height='30' />
                            </button>
                        </div>

                        <div className="col-12 RegisterWizard-form-group">
                            <label htmlFor="estado" className='textLbl'>Estado*</label>
                            <button id='estado' name='estado' onClick={() => defineUniversalPickerType("state")} className={userData.EstadoResidencia ? 'isActive' : ''}>
                                <span className='text-truncate'>{userData.EstadoResidencia ? userData.EstadoResidencia : 'Selecciona una opción'}</span> <img src={icnArrowDown} alt="arrow down" width='30' height='30' />
                            </button>
                        </div>
                    </>
                );
                break;
            case '2':
                return (
                    <>
                        <div className="col-12 RegisterWizard-form-group hasIcon">
                            <label htmlFor="firstName" className='textLbl'>Nombre (s)*</label>
                            <label htmlFor="firstName" className="icnLabel">
                                <img src={icnsReg} alt="input icon" className='formicon-5' />
                            </label>
                            <input id='firstName' name='firstName' type="text" placeholder='Escribe aquí tu respuesta' value={userData.Nombre} onChange={e => setInputForm("Nombre", e)} />
                        </div>

                        <div className="col-12 RegisterWizard-form-group hasIcon">
                            <label htmlFor="secondName" className='textLbl'>Apellido paterno*</label>
                            <label htmlFor="secondName" className="icnLabel">
                                <img src={icnsReg} alt="input icon" className='formicon-5' />
                            </label>
                            <input id='secondName' name='secondName' type="text" placeholder='Escribe aquí tu respuesta' value={userData.ApellidoPaterno} onChange={e => setInputForm("ApellidoPaterno", e)} />
                        </div>

                        <div className="col-12 RegisterWizard-form-group hasIcon">
                            <label htmlFor="thirdName" className='textLbl'>Apellido materno</label>
                            <label htmlFor="thirdName" className="icnLabel">
                                <img src={icnsReg} alt="input icon" className='formicon-5' />
                            </label>
                            <input id='thirdName' name='thirdName' type="text" placeholder='Escribe aquí tu respuesta' value={userData.ApellidoMaterno} onChange={e => setInputForm("ApellidoMaterno", e)} />
                        </div>
                    </>
                );
                break;
            case '1':
                return (
                    <>
                        <div className="col-12 px-0 RegisterWizard-form-disclaimer">
                            <div className="row m-0">
                                <div className="col-12 px-0 RegisterWizard-form-disclaimer-subtitle">
                                    ¿Quién te invitó a ser parte de WeNet?
                                    <br /><br />
                                    Si tu integración con nosotros fue por invitación de un miembro activo en WeNet, escribe su nombre, esto te ayudará en el networking de WeNet.
                                </div>
                            </div>
                        </div>

                        <div className="col-12 RegisterWizard-form-group hasIcon">
                            <label htmlFor="invitadoPor" className="icnLabel">
                                <img src={icnsReg} alt="input icon" className='formicon-5' />
                            </label>
                            <button id='invitadoPor' name='invitadoPor' className={userData.InvitadoPor ? 'isActive' : ''} onClick={() => setIsContactAdding(true)}>
                                <span className='text-truncate'> {userData.InvitadoPor ? userData.InvitadoPor : "Buscar por nombre"}</span>
                            </button>
                        </div>

                        <div className="col-12 RegisterWizard-form-group hasIcon">
                            <label htmlFor="CodigoInvitacion" className='textLbl'>
                                Código de invitación
                                <span style={{ color: '#CDCED4', fontSize: '16px', position: 'absolute', right: '0px' }}>(Opcional)</span>
                            </label>
                            <label htmlFor="CodigoInvitacion" className="icnLabel">
                                <img src={icnsReg} alt="input icon" className='formicon-5' />
                            </label>
                            <input id='CodigoInvitacion' maxLength={9} name='CodigoInvitacion' type="text" placeholder='XXXXXXXXX' value={userData.CodigoInvitacion} onChange={e => setInputForm("CodigoInvitacion", e)} />
                        </div>
                    </>
                );
                break;
            default:
                return null;
        }
    }

    const stepForm = (position) => {
        return (
            <div className="col-12 px-0">
                <div className="RegisterWizard-steps-form-container row m-0">

                    {formItems(position)}
                </div>
            </div>
        );
    }

    const stepView = (position) => {
        if (position) {
            return (
                <div className="RegisterWizard-steps-container row">
                    <div className="col-12 p-0">
                        <div className="row m-0">
                            {stepHeader()}
                            {stepForm(position)}
                        </div>
                    </div>
                    {stepFooter()}
                </div>
            );
        }
        return null;
    }

    const mainRender = () => {

        if (step) {

            switch (step) {
                case 'congrats':
                    return finalView();
                    break;
                case 'init':
                    return initialView();
                    break;
                default:
                    return stepView(step);
                    break;
            }
        }
        return null;
    }



    return (
        <div className="RegisterWizard-container container-fluid p-0">
            {!visibleCrop && mainRender()}

            {
                openUniversalPicker ? (
                    <UniversalPicker mode={type} country={userData.PaisResidencia} onDismiss={onUniverlPickerHide} onElementSelected={onElementSelected} />
                ) : (null)
            }

            {
                isContactAdding &&
                <ContactList currentUser={props.currentUser}
                    onBottomSheetDimiss={onBottomSheetDimiss}
                    onInvitationAdded={onContantSendBtnTapped}
                    isGroupAdding={false}
                    groupPreviusUser={[]}
                    isInvitation={true}
                    isRegister={true}
                />
            }

            {
                visibleCrop &&
                <ImageCrop src={imageData.base64} cutCrop={onCropImage} cancelCrop={onCancelCrop} text={""} aspectRatio={1 / 1} />
            }
        </div>
    );
};

export { RegisterWizard };
