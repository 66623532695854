import React from 'react';

import './AudioPlayerSimple.scss';

const AudioPlayerSimple = (props) => {
    const handleOnlyOneAudio = () => {
        let audios = document.getElementsByTagName('audio');
        let videos = document.getElementsByTagName('video');
        if (audios && audios.length) {
            for (let i = 0; i < audios.length; i++) {
                if (audios[i].id !== `${props.id}`) {
                    audios[i].pause();
                }
            }
        }

        if (videos && videos.length) {
            for (let i = 0; i < videos.length; i++) {
                videos[i].pause();
            }
        }
    }

    const mainRender = () => {
        if (props.url) {
            return (
                <div className="AudioPlayerSimple-container">
                    <audio src={props.url} onPlay={handleOnlyOneAudio} id={props.id} controls controlsList='nodownload nofullscreen noremoteplayback noplaybackrate'/>
                </div>
            )
        }
        return null;
    }

    return mainRender();
}

export { AudioPlayerSimple };
