import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';

import './Login.scss';
import { toolsHelper } from '../../../_resources/helpers';
import { authenticationService, infoService, swalAlertsService } from '../../../_resources/services';
import icnClose from '../../../_assets/media/images/icons/icn_close.png';
import icnBackArrow from '../../../_assets/media/images/icons/icn_back_arrow.png';
import icnMail from '../../../_assets/media/images/icons/icn_mail.png';
import icnLock from '../../../_assets/media/images/icons/icn_lock.png';
import icnPasswordShow from '../../../_assets/media/images/icons/icn_password_show.png';
import icnPasswordHide from '../../../_assets/media/images/icons/icn_password_hide.png';

import { UniversalPicker } from '../../shared';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: authenticationService.currentUserValue,
            openUniversalPicker: false,
            phoneLada: null,
            phone: '',
            email: '',
            activeDefaultTab: 'email',
            step: 0,
            passwordInputType: 'password',
            password: '',
            submitted: false,
            submitted2: false,
            errorResponse: '',
            goToRecover: false
        };
        this.onUniverlPickerHide = this.onUniverlPickerHide.bind(this);
        this.prepareLadaPreLoad = this.prepareLadaPreLoad.bind(this);
        this.onElementSelected = this.onElementSelected.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.onNextBtnTapped = this.onNextBtnTapped.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
    }

    componentDidMount() {
        if (this.props.onToggleTab) {
            this.props.onToggleTab(true);
        }
        this.prepareLadaPreLoad();
    }

    onUniverlPickerHide(data) {
        this.setState({openUniversalPicker: data});
    }

    onPasswordChange(event) {
        this.setState({submitted2: false, password: event.target.value});
    }

    onNextBtnTapped() {
        const submitted = true;
        let step = this.state.step;

        switch (this.state.activeDefaultTab) {
            case 'phone':
                if (this.state.phone && this.state.phone.length === 10) {
                    step = 1;
                }
                break;
            default:
                if (this.state.email && toolsHelper.validateEmail(this.state.email)) {
                    step = 1;
                }
                break;
        }
        this.setState({submitted, step});
    }
    handlePhoneChange(event) {
        const phone = (event.target.validity.valid) ? event.target.value : this.state.financialGoal;
        this.setState({phone, submitted: false});
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value, submitted: false});
    }

    prepareLadaPreLoad() {
        infoService.catalogs.subscribe((catalogs) => {
            if (catalogs && catalogs.Paises) {
                let mxFiltered = catalogs.Paises.filter(element => element.Pais === 'México');

                if (mxFiltered.length) {
                    this.setState({phoneLada: mxFiltered[0]});
                }
            }

        });
    }

    onElementSelected(element) {
        if (element && element.Lada) {
            this.setState({phoneLada: element});

        }
    }

    ladaStringFormatter(value) {
        let lada = '';
        if (value && value.Lada) {
            lada = value.Lada;
            const valueSplitted = value.Lada.split(' ');
            if (valueSplitted.length && valueSplitted.length > 1) {
                lada = `${valueSplitted[0]} (${valueSplitted[1]})`
            }
        }
        return lada;
    }

    onSubmitBtnTapped() {
        this.setState({submitted2: true, errorResponse: ''}, () => {
            if (this.state.password) {
                let value = this.state.activeDefaultTab === 'email' ? this.state.email:this.state.phone;
                let key = this.state.activeDefaultTab === 'email' ? 'Email':'Celular';

                let loading = swalAlertsService.createLoading('Iniciando sesión', '');
                loading.fire();

                authenticationService.login(value, this.state.password, key)
                .then((response) => {
                    console.log('then', response);
                    if (response.data && response.data.SUID) {
                        window.location.reload(true);
                    } else if (typeof response == 'string') {
                        loading.close();
                        this.setState({errorResponse: response});
                        swalAlertsService.showToast('error', response);
                    }
                }, (error) => {
                    console.log('error', error);
                    loading.close();
                    if (typeof error == 'string') {
                        this.setState({errorResponse: error});
                        swalAlertsService.showToast('error', error);
                    }
                });
            }
        });
    }

    onRecoverBtnTapped() {
        this.setState({goToRecover: true});
    }


    renderEmail() {
        return (
            <div className="row m-0">
                <div className={`col-12 p-0 Login-input-box ${this.state.activeDefaultTab === 'email' && this.state.submitted && (!this.state.email || !toolsHelper.validateEmail(this.state.email)) ? 'input-alert':''}`}>
                    <label htmlFor="email" className='icon'>
                        <img src={icnMail} alt="mail icon"/>
                    </label>
                    <input type="email" id='email' name='email' className='iconned' placeholder='Correo electrónico' onKeyUp={(e) => {this.handleEmailChange(e)}}/>
                </div>

                {
                    this.state.activeDefaultTab === 'email' && this.state.submitted && (!this.state.email || !toolsHelper.validateEmail(this.state.email)) ? (
                        <div className="col-12 p-0">
                            <small className='text-danger'>
                                {
                                    !this.state.email ? ('Campo requerido'):(null)
                                }

                                {
                                    this.state.email && !toolsHelper.validateEmail(this.state.email) ? ('Ingresa un correo electrónico válido'):(null)
                                }
                            </small>
                        </div>
                    ):(null)
                }
            </div>
        );
    }

    renderPhone() {
        return (
            <div className="row m-0">
                <div className={`col-12 Login-input-box-lada ${this.state.activeDefaultTab === 'phone' && this.state.submitted && (!this.state.phone || this.state.phone.length < 10) ? 'input-alert':''}`}>
                    <div className="row m-0 d-flex align-items-center">
                        <div className="col p-0 lada-btn-box">
                            <button onClick={() => {this.setState({openUniversalPicker:true})}}>
                                +{this.ladaStringFormatter(this.state.phoneLada)}
                            </button>
                        </div>
                        <div className="col p-0 input-box">
                            <input type="text"
                                className='ladded'
                                id='phone'
                                name='phone'
                                maxLength='10'
                                value={this.state.phone}
                                onChange={event => this.setState({phone: event.target.value.replace(/\D/g,'')})}  />
                        </div>
                    </div>
                </div>
                {
                    this.state.activeDefaultTab === 'phone' && this.state.submitted && (!this.state.phone || this.state.phone.length < 10) ? (
                        <div className="col-12 p-0">
                            <small className='text-danger'>Campo requerido</small>
                        </div>
                    ):(null)
                }
            </div>
        );
    }

    renderStep1() {
        return (
            <div className="container-fluid Login-container">

                <div className="row m-0">
                    <div className="col-12 p-0">
                        <Link to='/welcome'>
                            <button className='Login-close-btn'>
                                <img src={icnClose} alt="close icn"/>
                            </button>
                        </Link>
                    </div>
                </div>

                <div className="row m-0 Login-titles-box">
                    <div className="col-12 p-0 title">
                        Iniciar sesión
                    </div>
                    <div className="col-12 p-0 subtitle">
                        Elige una opción e ingresa tus datos
                    </div>
                </div>

                <div className="row m-0 Login-tabs-box">
                    <div className="col-12 p-0">

                        <Tabs defaultActiveKey="email" id="uncontrolled-tab-example" className='hola1'  onSelect={(activeDefaultTab) => {this.setState({activeDefaultTab})}}>
                            <Tab eventKey="email" title="Email" >
                                {this.renderEmail()}
                            </Tab>
                            <Tab eventKey="phone" title="Teléfono">
                                {this.renderPhone()}
                            </Tab>
                        </Tabs>

                    </div>

                    <div className="col-12 px-0 Login-step-btn-box">
                        <button onClick={() => {this.onNextBtnTapped()}}>
                            Siguiente
                        </button>
                    </div>
                </div>

            </div>
        );
    }

    renderStep2() {
        return (
            <div className="container-fluid Login-container">

                <div className="row m-0">
                    <div className="col-12 p-0">
                        <button className='Login-close-btn' onClick={() => {this.setState({step: 0})}}>
                            <img src={icnBackArrow} alt="close icn"/>
                        </button>
                    </div>
                </div>

                <div className="row m-0 Login-titles-box">
                    <div className="col-12 p-0 title">
                        Iniciar sesión
                    </div>
                    <div className="col-12 p-0 subtitle">
                        Elige una opción e ingresa tus datos
                    </div>
                </div>

                <div className="row m-0 Login-password-container">
                    <div className={`col-12 p-0 Login-password-box ${this.state.submitted2 && !this.state.password ? 'input-alert':''}`}>

                        <label htmlFor="password">
                            <img src={icnLock} alt="lock icn"/>
                        </label>
                        <input type={this.state.passwordInputType} id='password' name='password' onKeyUp={(e) => {this.onPasswordChange(e)}} placeholder='Contraseña'/>
                        <button onClick={() => {this.setState({passwordInputType: this.state.passwordInputType === 'text' ? 'password':'text'})}}>
                            <img src={this.state.passwordInputType === 'text' ? icnPasswordHide:icnPasswordShow} alt="icon password"/>
                        </button>
                    </div>
                    {
                        this.state.submitted2 && !this.state.password ? (
                            <div className="col-12 p-0">
                                <small className='text-danger'>
                                    Campo requerido
                                </small>
                            </div>
                        ):(null)
                    }

                    {
                        this.state.submitted2 && this.state.errorResponse ? (
                            <div className="col-12 p-0 Login-error-box">
                                {this.state.errorResponse}
                            </div>
                        ):(null)
                    }

                    <div className="col-12 px-0 Login-step-btn-box">
                        <button onClick={() => {this.onSubmitBtnTapped()}}>
                            Entrar
                        </button>
                    </div>

                    <div className="col-12 px-0 Login-recover">
                        <div onClick={() => {this.onRecoverBtnTapped()}}>
                            Recuperar contraseña
                        </div>
                    </div>

                </div>

            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.step === 0 ? (
                        this.renderStep1()
                    ):(
                        this.renderStep2()
                    )
                }
                {
                    this.state.openUniversalPicker ? (
                        <UniversalPicker mode='phone' onDismiss={this.onUniverlPickerHide} onElementSelected={this.onElementSelected}/>
                    ):(null)
                }

                {
                    this.state.goToRecover ? (
                        <Redirect to={{ pathname: '/recover' }} />
                    ) : (null)
                }
            </React.Fragment>

        );
    }
}

export { Login };
