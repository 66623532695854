import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import icnBack from '../../../../_assets/media/images/icons/icn_back_arrow.png';
import imgUserPlaceholder from '../../../../_assets/media/images/icons/icn_user_placeholder.png';
import { authenticationService, swalAlertsService } from '../../../../_resources/services';
import { settingService } from '../../../../_resources/services/settings.service';
import { Settings } from '../configuration/settings';
import "./bloquedusers.scss"

const BloquedUsers = (props) => {


    const [dataBloqued, setDataBloqued] = useState([])
    const history = useHistory()

    useEffect(() => {
        if (props.onToggleTab) {
            props.onToggleTab(true);
        }
       getData()
    }, [])


    const getData = () => {
        settingService.getBloquedUser().then(
            (response) => {
                console.log(response.data)
                 setDataBloqued(response.data)
            }
        )
        .catch(
            (error) => {

            }
        )
    }

    const desblockUser = (value) => {
        swalAlertsService.answer("", "¿Estas seguro de querer desbloquear a este usuario?", "Si, desbloquear")
            .then((result) => {
                if (result.isConfirmed) {
                    let loading = swalAlertsService.createLoading("Desbloqueando")
                    loading.fire()
                    settingService.deleteBloquedUser(value).then(
                        (response) => {
                            getData()
                            loading.close()
                            Swal.fire(
                                '',
                                'Usuario desbloqueado',
                                'success'
                            )
                            setTimeout(() => {
                                window.location.reload(true)
                            }, 1000);
                        }
                    )
                    .catch(
                        (error) => {
                            loading.close()
                            console.log(error)
                        }
                    )
                }
            })

    }


    const containerbloqued = (data) => {
        return (
            <div className="container_bloqued" key={data.clv_bloqueadoxusuario}>
                {
                    data && data.ThumbnailUrl ?
                    (
                        <img src={data.ThumbnailUrl} width="60px" height="60px"/>
                    ):(
                        <img src={imgUserPlaceholder} width="60px" height="60px"/>
                    )
                }
                <div className="container_bloqued_data">
                    <p className="container_bloqued_data_user">{data.NombreCompleto}</p>
                    <p>{data.Cargo + " | " + data.EmpresaNombreCorto}</p>
                    <p>{data.Giro + ", " + data.Clasificacion}</p>
                    <p>{data.Estado + ", " + data.Pais}</p>
                </div>
                <button className="container_bloqued_button" onClick={ () => desblockUser(data.clv_bloqueadoxusuario)}>Desbloquear</button>
            </div>
        )
    }

    return (
        <div className={`container-BloquedUsers container-blocked-users ${props.isDesktop ? 'isDesktop':''}`}>
            <div className="container_header">
                {
                    !props.isDesktop ? (
                        <img src={icnBack} width="30px" height="30px" onClick={() => history.goBack()}/>
                    ):(null)
                }
                <h3>Bloqueo permanente de usuarios</h3>
            </div>
            <p className="container_header_bloqued">
                Si no deseas tener contacto con una persona (que no te envíe mensajes ni te vea en directorio) puedes bloquear al usuario.
                <br/> <br/>
                <strong className='text-muted-config'>¡Precaución! al bloquear algún usuario, no tendrá acceso a sus publicaciones, mensajes, o a visualizarlo en el directorio, a menos que lo desbloquee.</strong>
            </p>
            {

                dataBloqued.length == 0
                ?
                    <p className="container_header_bloqued text-center">No has bloquedo a nadie</p>
                :
                    dataBloqued.map( (data) =>
                        containerbloqued(data)
                    )
            }
            <br/><br/>
        </div>
    );
}

export {BloquedUsers};
