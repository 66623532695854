import { authHeader, handleResponse } from '../helpers';

const apiPath = process.env.REACT_APP_API_PATH;

export const publicationService = {
    getPublicationById,
    postPublication,
    putPublication,
    deletePublication,
    reportPublication,
    commentPublication,
    editPublicationComment,
    deletePublicationComment
};

// function getPublications() {
//     const requestOptions = { method: 'GET', headers: authHeader() };
//     return fetch(`/users`, requestOptions).then(handleResponse);
// }

function getPublicationById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiPath}publication/${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function postPublication(body) {
    const requestOptions = { method: 'POST', headers: authHeader(), body: JSON.stringify(body)};
    return fetch(`${apiPath}publication`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function putPublication(id,body) {
    const requestOptions = { method: 'PUT', headers: authHeader(), body: JSON.stringify(body)};
    return fetch(`${apiPath}publication/${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function deletePublication(id) {
    const requestOptions = { method: 'DELETE', headers: authHeader()};
    return fetch(`${apiPath}publication/${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function reportPublication(id, body) {
    const requestOptions = { method: 'POST', headers: authHeader(), body: JSON.stringify(body)};
    return fetch(`${apiPath}publication/${id}/report`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function commentPublication(id, body) {
    const requestOptions = { method: 'POST', headers: authHeader(), body: JSON.stringify(body)};
    return fetch(`${apiPath}publication/${id}/message`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function editPublicationComment(id, body) {
    const requestOptions = { method: 'PUT', headers: authHeader(), body: JSON.stringify(body)};
    return fetch(`${apiPath}publication/${id}/message`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}


function deletePublicationComment(pubID, commentID) {
    const requestOptions = { method: 'DELETE', headers: authHeader()};
    return fetch(`${apiPath}publication/${pubID}/message/${commentID}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}
