import React, { useState, useEffect } from 'react';

import './RecomendationTalent.scss';

import placeholderuser from '../../../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnCommentEdit from '../../../../../_assets/media/images/icons/icn_comment_edit.png';
import icnStart from '../../../../../_assets/media/images/icons/icn_batch_star.svg';

const RecomendationTalent = (props) => {

    const getUserData = () => {
        if (props.currentUser) {

            if (props.user && props.userID != props.currentUser.Usuario.clv_usuario) {
                return props.user;
            } else {
                return props.currentUser.Usuario;
            }
        }

        return null;
    }

    const getBtnTxt = () => {
        const user = getUserData();

        if (user && props.currentUser && user.clv_usuario === props.currentUser.Usuario.clv_usuario) {
            return 'Pedir recomendación';
        }

        return 'Recomendar';
    }

    const onBtnTapped = () => {

        if (props.onBtnTapped) {
            props.onBtnTapped(getBtnTxt() === 'Recomendar' ? 'reco':'ask');
        }
    }

    const renderRecomendationBtn = () => {
        let btnTxt = getBtnTxt();
        const user = getUserData();

        if (btnTxt === 'Recomendar') {

            if (user && user.Recomendaciones && props.currentUser && props.currentUser.Usuario) {

                if (user.Recomendaciones.length) {
                    const filtered = user.Recomendaciones.filter(ele => ele.clv_usuariorecomienda === props.currentUser.Usuario.clv_usuario);

                    if (!filtered.length) {
                        return (
                            <div className="col-12 RecomendationTalent-btn-container">
                                <button className='btn-cleared' onClick={onBtnTapped}>
                                    <img src={icnCommentEdit} alt="comment edit"/> {btnTxt}
                                </button>
                            </div>
                        );
                    }
                } else {
                    return (
                        <div className="col-12 RecomendationTalent-btn-container">
                            <button className='btn-cleared' onClick={onBtnTapped}>
                                <img src={icnCommentEdit} alt="comment edit"/> {btnTxt}
                            </button>
                        </div>
                    )
                }
            }



        } else {
            return (
                <div className="col-12 RecomendationTalent-btn-container">
                    <button className='btn-cleared' onClick={onBtnTapped}>
                        <img src={icnCommentEdit} alt="comment edit"/> {btnTxt}
                    </button>
                </div>
            );
        }
        return null;
    }

    const renderView = () => {
        const user = getUserData();

        if (user) {
            return (
                <>
                    <div className="col-12 RecomendationTalent-user-container">
                        <div className="row m-0">
                            <div className="col avatar-image-box-60 pos-relative">
                                <img src={user.ThumbnailUrl ? user.ThumbnailUrl : placeholderuser} alt="recomendation photo"
                                    onError={(e)=>{
                                        e.target.onerror = null;
                                        e.target.src=placeholderuser
                                    }}
                                />
                                {
                                    user.clv_estatusbm === 2 &&
                                    <img src={icnStart} alt=" start icon" className='startIcn'/>
                                }
                            </div>
                            <div className="col pe-0">
                                <div className="row m-0 recomendation-details-box">
                                    <div className="col-12 px-0 user-details-title text-block-truncate-1">{user.NombreCompleto}</div>
                                    <div className="col-12 px-0 user-details-subtitle soft text-block-truncate-1 small">
                                        {`${user.Cargo ? user.Cargo:''}${user.Cargo && user.EmpresaNombreCorto ? ' | ':''}${user.EmpresaNombreCorto ? user.EmpresaNombreCorto:''}`}
                                    </div>
                                    <div className="col-12 px-0 user-details-subtitle soft text-block-truncate-1 small">
                                        {`${user.EstadoResidencia ? user.EstadoResidencia:''}${user.EstadoResidencia && user.PaisResidencia ? ', ':''}${user.PaisResidencia ? user.PaisResidencia:''}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 RecomendationTalent-text-container">

                        <div className="row m-0">
                            <div className="col-12 px-0 title-box">
                                Mi mayor talento
                            </div>
                        </div>

                        <div className="row m-0">
                            <div className="col-12 px-0 text-box">
                                {user.Talento}
                            </div>
                        </div>
                    </div>


                    {renderRecomendationBtn()}
                </>

            );
        }

        return null;
    }
    return (
        <div className="RecomendationTalent-container row m-0">
            {renderView()}
        </div>
    );
}

export { RecomendationTalent }
