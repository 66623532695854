import React, { useState, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import './DirectoryListTable.scss';

import { useDidMountEffect, useElementOnScreen } from '../../../../../_resources/helpers';
import { DirectoryEnterpricesModal, NoResults } from '../../../../shared';
import icnUserPlaceholder from '../../../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnCompanyPlaceholder from '../../../../../_assets/media/images/icons/icn_company_placeholder.png';
import start from '../../../../../_assets/media/images/icons/icn_batch_star.svg';

const DirectoryListTable = (props) => {
    const history = useHistory();

    const [isOpenDirectoryEnterpricesModal, setIsOpenDirectoryEnterpricesModal] = useState(false);
    const [enterpriceToShow, setEnterpriceToShow] = useState(null);

    const [lastItemRef, isVisible] = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 1.0
    })

    useDidMountEffect(() => {
        // react please run me if 'key' changes, but not on initial render
        let isSuscribed = true;
        if (isVisible && props.loadMore) {
            props.loadMore();
        }
        return () => isSuscribed = false
    }, [isVisible]);

    const getImage = (item) => {
        if (item) {
            let value = item[props.mode === 'users' || props.mode === 'mentors' ? 'ThumbnailUrl' : 'LogoThumbnailUrl'];
            let icon = props.mode === 'users' || props.mode === 'mentors' ? icnUserPlaceholder : icnCompanyPlaceholder;
            return value || icon;
        }
        return null;
    }

    const goToUser = (item) => {
        const { currentUser } = props;
        if (item.clv_usuario && currentUser) {
            if (item.clv_usuario === currentUser.Usuario.clv_usuario) {
                history.replace('/profile');
            } else {
                history.push(`/profile-user/${item.clv_usuario}`, { from: '/directory' })
            }

        } else if (item.Usuarios && item.Usuarios.length) {
            console.log("aqui4", item);
            setEnterpriceToShow(item);
            setIsOpenDirectoryEnterpricesModal(true);
        }
    }

    const getResultsTitle = () => {
        if (props.items) {
            return `${props.items.length} ${props.mode === 'users' ? 'Miembro' : props.mode === 'mentors' ? 'Mentor' : 'Empresa'}${props.items.length === 1 && props.mode === 'mentors' ? 's' : props.items.length > 1 && props.mode === 'mentors' ? 'es' : props.items.length === 1 && props.mode != 'mentors' ? '' : 's' }`;
        }
    }

    const renderItems = () => {

        if (props && props.items && props.items.length && props.mode) {
            const items = props.items.map((item, index) => {

                return <div className="DirectoryListTable-item-col col-12 p-0 mouse-pointer" key={index} onClick={e => goToUser(item)}>
                    <div className="row m-0">
                        <div className="col avatar-image-box-60">
                            <img src={getImage(item)}
                                alt=""
                                className="avatar"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    const icon = props.mode === 'users' || props.mode === 'mentors' ? icnUserPlaceholder : icnCompanyPlaceholder;
                                    e.target.src = icon
                                }}
                            />
                            {
                                props.mode === 'users' && item.clv_estatusbm === 2 &&
                                <img src={start} width="10px" height="10px" alt="miembro" className="miembro" />
                            }
                        </div>
                        <div className="col">
                            <div className="row line-height-18px DirectoryListTable-user-col">
                                <div className="col-12 p-0 user-details-title">
                                    {props.mode === 'users' || props.mode === 'mentors' ? item.NombreCompleto : item.EmpresaNombreCorto}
                                </div>
                                <div className="col-12 p-0 user-details-subtitle">
                                    {
                                        props.mode === 'users' || props.mode === 'mentors' ?
                                            `${item.Cargo}${item.Cargo && item.EmpresaNombreCorto ? ' | ' : ''}${item.EmpresaNombreCorto}` :
                                            `${item.Empresa ? `${item.Empresa}`:''}`
                                    }
                                </div>
                                <div className={`col-12 p-0 user-details-subtitle soft ${props.mode === 'users' || props.mode === 'mentors' ? '' : 'order-last'}`}>
                                    {
                                        props.mode === 'users' || props.mode === 'mentors' ? (
                                            `${item.Giro || ''}${item.Giro && item.Clasificacion ? ', ' : ''}${item.Clasificacion || ''}`
                                        ) : (`${item.Miembros} Miembro${item.Miembros > 1 ? 's': ''}`)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            });

            return <div className="DirectoryListTable-items-container row m-0">
                {items}
                <div ref={lastItemRef} className='pt-0'></div>
            </div>
        }
        return <div className="DirectoryListTable-noresults-container row m-0"><NoResults /></div>
    }

    return (
        <div className="DirectoryListTable-container row m-0">
            <div className="col-12 p-0">
                <div className='mb-3 text-muted'>
                    {getResultsTitle()}
                </div>
                {renderItems()}
            </div>

            {
                isOpenDirectoryEnterpricesModal && 
                enterpriceToShow && 
                <DirectoryEnterpricesModal 
                    users={enterpriceToShow && enterpriceToShow.Usuarios ? enterpriceToShow.Usuarios:null} 
                    title={enterpriceToShow && enterpriceToShow.EmpresaNombreCorto ? enterpriceToShow.EmpresaNombreCorto:null} 
                    onBottomSheetDimiss={() => {setIsOpenDirectoryEnterpricesModal(false); setEnterpriceToShow(null)}}
                />
            }
        </div>
    );
}

export { DirectoryListTable }
