import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Linkify from "react-linkify";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getMessagin, getToken } from "../../../firebaseInit";

import "./Home.scss";
import {
    userService,
    authenticationService,
    algoliaService,
    infoService,
    swalAlertsService,
    publicationService,
    profileService,
} from "../../../_resources/services";
import { HomeHeader } from "./home-header/HomeHeader";
import {
    Searcher,
    Post,
    NoResults,
    Filters,
    Notifications,
    Header,
    ProfileSheet,
    CompanySheet,
    NotificationsSheet,
    MessagesBubble,
    ModalDefault,
    ContentPost,
    CreatorsContentsModal,
    ContentDetailModal
} from "../../shared";
import { useHistory } from "react-router-dom";
import {
    useDidMountEffect,
    useElementOnScreen,
    useWindowDimensions,
} from "../../../_resources/helpers";

import { HomeNewPost } from "./home-new-post/HomeNewPost";
import { HomeFilterToggler } from "./home-filter-toggler/HomeFilterToggler";
import { HomePostType } from "./home-post-type/HomePostType";
import { HomeFilterMent } from "./home-filter-ment/HomeFilterMent";
import { LogoutSheet } from "../../shared/sheets/logout-sheet/LogoutSheet";
import { set } from "date-fns/esm";

import icnUserPlaceholder from '../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnStar from '../../../_assets/media/images/icons/icn_batch_star.svg';

const Home = (props) => {
    const history = useHistory();
    const [currentUser, setCurrentUser] = useState(null);
    const [version, setVersion] = useState(infoService.versionValue);
    const [catalogs, setCatalogs] = useState(null);
    const [posts, setPosts] = useState([]);
    const [creators, setCreators] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [hideName, setHideName] = useState(false);
    const [facets, setFacets] = useState({numericFilters: [`clv_tipopublicacion!=4`, `clv_tipopublicacion!=5`], facetFilters: []});
    const [showFilters, setShowFilters] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showLetter, setShowLetter] = useState(false);
    const [showMFilters, setShowMFilters] = useState(false);
    const [showContents, setShowContents] = useState(false);
    const [letterSize, setletterSize] = useState(18);
    const [tempLetterSize, setTempletterSize] = useState(18);
    const [lineSize, setLineSize] = useState(22);
    const [updateProfile, setupdateProfile] = useState(false);
    const [postTypeClv, setPostTypeClv] = useState(null);
    const [contentClv, setContentClv] = useState(null);
    const [reactions, setReactions] = useState([]);
    const [isCreators, setIsCreators] = useState(false);
    const [showCreatorsModal, setShowCreatorsModal] = useState(false);
    const [creatorSelected, setCreatorSelected] = useState(null);
    const [contentSelected, setContentSelected] = useState(null);
    const [isComment, setIsComment] = useState(false);

    const { width, height } = useWindowDimensions();

    const [activeFilters, setActiveFilters] = useState({
        filtersSelected: null,
        postedBy: null,
        isUsingFilters: false,
    });

    const [lastItemRef, isVisible] = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
    });

    const [isFetchingData, setIsFetchingData] = useState(false);
    const [algoliaData, setAlgoliaData] = useState({
        nbHits: 0,
        nbPages: 0,
        page: 0,
    });

    const [updateDot, setUpdateDot] = useState(false);

    let messaging = getMessagin();

    if(messaging){
        messaging.onMessage((payload) => {
            setUpdateDot(true);
            if (payload && payload.data && payload.data.clv_tipoaccion === "12") {
                setupdateProfile(true);
                setTimeout(() => {
                    getPosts(true);
                }, 2000);
            }
        });
    }
    
    const endMessage = ()=>{
        setUpdateDot(false);
    }

    const prepareFilters = (page = 0) => {
        let parameters = {
            numericFilters: [],
            facets: ["TipoPublicacion"],
            page: page || 0
        };

        if (isCreators) {
            parameters.facets = ['*'];

            return parameters;
        }

        if (facets) {
            let facetsCp = JSON.parse(JSON.stringify(facets));
            let {numericFilters, facetFilters} =  facetsCp;
            parameters = {...parameters, facetFilters, numericFilters};
        }

        if (currentUser && currentUser.Usuario && currentUser.Usuario.clv_usuario) {
            let userID = currentUser.Usuario.clv_usuario;

            let roles = [];

            if (
                activeFilters.filtersSelected &&
                activeFilters.filtersSelected.Cargos &&
                activeFilters.filtersSelected.Cargos.length
            ) {
                roles = activeFilters.filtersSelected.Cargos;
            } else if (currentUser.Usuario.Cargos && currentUser.Usuario.Cargos.length) {
                let activess = currentUser.Usuario.Cargos.filter((role) => role.Activo);

                if (activess.length) {
                    roles = activess;
                }

            }

            if (roles.length) {
                roles.forEach((role) => {
                    if (parameters.facetFilters && parameters.facetFilters.length) {
                        if (!parameters.facetFilters.includes(`Cargo: ${role.Cargo}`)) {
                            parameters.facetFilters.push(`Cargo: ${role.Cargo}`);
                        }
                    } else {
                        parameters.facetFilters = [`Cargo: ${role.Cargo}`];
                    }
                });
            }

            parameters.facetFilters = parameters.facetFilters;

            if(parameters.numericFilters && parameters.numericFilters.length){
                if(!parameters.numericFilters.includes(`RestringidoPor.clv_usuario!=${userID}`)){
                    parameters.numericFilters.push(`RestringidoPor.clv_usuario!=${userID}`);
                }
            }else{
                parameters.numericFilters = [`RestringidoPor.clv_usuario!=${userID}`];
            }

            if (version && version.PublicacionesTimeStamp) {

                if(parameters.filters){
                    if(parameters.filters.indexOf(`FechaTimeStamp >= ${version.PublicacionesTimeStamp}`) < 0){
                        parameters.filters = `${parameters.filters ? `${parameters.filters} AND ` : ""}FechaTimeStamp >= ${version.PublicacionesTimeStamp}`;
                    }
                }else{
                    parameters.filters = `FechaTimeStamp >= ${version.PublicacionesTimeStamp}`;
                }


            }

            if (activeFilters.postedBy) {
                if (activeFilters.postedBy.value === 2) {
                    if (parameters.numericFilters) {
                        parameters.numericFilters.push(`clv_usuario=${userID}`);
                    } else {
                        parameters.numericFilters = [`clv_usuario=${userID}`];
                    }
                }
            }
        }
        return parameters;
    };

    async function loadPosts(page = 0, shouldScroll = true) {
        if (shouldScroll) {
            goScrollTop();
        }
        let parameters = prepareFilters(page);
        let index = isCreators ? 'Creadores':'Publicaciones'
        const response = await algoliaService.getResults(
            searchTerm,
            parameters,
            index,
            true
        );
        return response;
    }

    const onSearchTerm = (value) => {
        setSearchTerm(value);
    };

    const checkScroll = (e) => {
        if (posts.length >= 3) {
            if (
                e.target.children.length &&
                e.target.children[0].scrollHeight < e.target.scrollTop
            ) {
                if (!hideName) {
                    setHideName(true);
                }
            } else {
                if (hideName) {
                    setHideName(false);
                }
            }
        }
    };

    const goScrollTop = (callBack) => {
        let element = document.getElementById("Home-post-container");

        if (element) {
            element.scrollTo({ top: 0, behavior: "smooth" });
        }
        if (callBack) {
            callBack();
        }
    };

    const onPostTypeSelected = (item) => {
        setShowContents(false);
        setFacets(null);
        setPostTypeClv(null);
        setContentClv(null);
        setIsCreators(false);

        setTimeout(() => {
            if (item && item.clv_tipopublicacion) {
                setPostTypeClv(item.clv_tipopublicacion);
                setShowMFilters(item.clv_tipopublicacion === 4 ? true:false);
                setShowContents(item.clv_tipopublicacion === 5 ? true:false);
                

                if (item.clv_tipopublicacion == 4) {
                    let obj = {
                        facets: ["TipoPublicacion"],
                        facetFilters: [`TipoPublicacion: Mentoría`],
                        numericFilters: [`clv_tipopublicacion=4`]
                    };

                    setFacets(obj);
                } else if (item.clv_tipopublicacion == 5) {
                    infoService.catalogs.subscribe((data) => {
                        
                        if (data && data.TiposContenido && data.TiposContenido.length) {
                            let obj = {
                                facets: ["CategoriaPublicacion"],
                                facetFilters: [`TipoContenido: ${data.TiposContenido[0].TipoContenido}`],
                                numericFilters: [`clv_tipopublicacion=5`]
                            }
                            setContentClv(data.TiposContenido[0].TipoContenido);
                            setFacets(obj);
                        }
                    });
                }
                
                
            } else {
                let obj = {
                    numericFilters: [`clv_tipopublicacion!=4`,'clv_tipopublicacion!=5'],
                    facetFilters: []
                }
                setPostTypeClv(0);
                setFacets(obj);
                setShowMFilters(false);
                setShowContents(false);
            }
        }, 1);
    };

    const onSubPostTypeSelected = (element) => {
        if (element && 'id' in element) {
            let obj = {
                facets: ["TipoPublicacion"],
                numericFilters: [`clv_tipopublicacion!=4`,'clv_tipopublicacion!=5']
            };
            
            if (element.id != 0) {
                obj['facetFilters']= [`TipoPublicacion: ${element.name}`];
            }

            setFacets(obj);
        }
    }

    const onMentorSelected = (element) => {
        console.log("onMentorSelected",element);
        if (element) {
            let obj = {
                facets: ["TipoPublicacion"],
                numericFilters: [`clv_tipopublicacion=4`],
            };

            if (element.id) {
                obj.facets = ["CategoriaPublicacion"];
                obj['facetFilters']=[`CategoriaPublicacion: ${element.name}`]
            } else {
                obj['facetFilters']= [`TipoPublicacion: Mentoría`];
            }
            setFacets(obj);
        }
    }

    const onContentSelected = (element) => {
        console.log('onContentSelected',element);
        setShowContents(element.id != 4);
        setIsCreators(element.id == 4);

        if (element.id == 4) {
            if (facets && facets.facetFilters && facets.facetFilters.length) {
                let filterd = facets.facetFilters.filter(el => el.length && el[0].indexOf('CategoriaPublicacion:') < 0);

                if (filterd.length) {
                    let facetsCp = JSON.parse(JSON.stringify(facets));

                    facetsCp.facetFilters = filterd;
                    setFacets(facetsCp);
                }
            }
        }
        if (element) {
            let facetFilters = [["TipoPublicacion: Contenidos"]];
            let obj = {
                facets: ["TipoPublicacion"],
                numericFilters: [`clv_tipopublicacion=5`],
            };
            
            let catPub = '';

            if (facets && facets.facetFilters && facets.facetFilters.length) {
                let filterContent = facets.facetFilters.filter(el => el.length && el[0].indexOf('CategoriaPublicacion:') >= 0);

                if (filterContent.length) {
                    catPub = filterContent[0];
                }
            }

            if (element.id) {
                facetFilters.push([`TipoContenido: ${element.name}`]);
                if (catPub.length) {
                    facetFilters.push(catPub);
                }

                obj['facetFilters'] = facetFilters;
                setContentClv(element.name);
                setFacets(obj);
            } else {
                infoService.catalogs.subscribe((data) => {
                    if (data && data.TiposContenido && data.TiposContenido.length) {
                        facetFilters.push([`TipoContenido: ${data.TiposContenido[0].TipoContenido}`]);
                        setContentClv(data.TiposContenido[0].TipoContenido);
                        if (catPub.length) {
                            facetFilters.push(catPub);
                        }
                        obj['facetFilters'] = facetFilters;
                        setFacets(obj);
                    }
                });
            }
            
        }
    }

    const onContentTypePostSelected = (element) => {
        
        if (element && contentClv) {
            let facetFilters = [["TipoPublicacion: Contenidos"]];
            let obj = {
                facets: ["TipoPublicacion"],
                numericFilters: [`clv_tipopublicacion=5`]
            };
            
            let contentType = '';

            if (facets && facets.facetFilters && facets.facetFilters.length) {
                let filterContent = facets.facetFilters.filter(el => el.indexOf('TipoContenido:') >= 0);

                if (filterContent.length) {
                    contentType = filterContent[0];
                }
            }
            if (element.id) {
                facetFilters.push([`TipoContenido:${contentClv}`]);
                facetFilters.push([`CategoriaPublicacion:${element.name}`]);
                obj['facetFilters'] = facetFilters;
                
                setFacets(obj);
            } else {
                infoService.catalogs.subscribe((data) => {
                    if (data) {
                        if (contentType.length) {
                            facetFilters.push([contentType]);
                        } else if (data.TiposContenido && data.TiposContenido.length) {
                            facetFilters.push([`TipoContenido: ${data.TiposContenido[0].TipoContenido}`]);
                        }
                    }

                    obj['facetFilters'] = facetFilters
                    setFacets(obj);
                });
            }
            
        }
    }

    const showTab = () => {
        if (props.onToggleTab) {
            props.onToggleTab(false);
        }
    };

    const getStoredPosts = () => {
        let storedPosts = localStorage.getItem("storedPosts");

        if (storedPosts) {
            try {
                storedPosts = JSON.parse(storedPosts);

                if (storedPosts.length) {
                    setPosts(storedPosts);
                }
            } catch (e) { }
        }
    };

    const storePosts = (items) => {
        if (items && items.length) {
            localStorage.setItem("storedPosts", JSON.stringify(items));
        }
    };

    useEffect(() => {
        showTab();

        let isSuscribed = true;

        authenticationService.currentUser.subscribe((user) => {
            if (user && user.SUID && user.Usuario && isSuscribed) {
                setCurrentUser(user);
            }
        });

        profileService.getGeneric("user/reactions").then((response) => {
            if (response && response.data && isSuscribed) {
                setReactions(response.data);
            }
        });

        if (!localStorage.getItem("letterSize")) {
            setSize(18);
        } else {
            setSize(parseInt(localStorage.getItem("letterSize")));
        }

        return () => (isSuscribed = false);
    }, []);

    const tokenFunc = async () => {
        let data = await getToken(currentUser.SUID);
        if (data) {
            profileService
                .postGeneric("user/device", "post", { Device: data.currentToken })
                .then((response) => { 
                });
        }
        return data;
    };

    useDidMountEffect(() => {
        let isSuscribed = true;
        if (currentUser) {
            getStoredPosts();
            setIsFetchingData(true);
            const postsCP = loadPosts(0, true);

            postsCP.then((response) => {
                if (isSuscribed) {
                    if (response) {
                        let algoliaDataCP = JSON.parse(JSON.stringify(algoliaData));

                        const { nbHits, nbPages, page } = response;
                        algoliaDataCP = {
                            nbHits: nbHits || 0,
                            nbPages: nbPages || 0,
                            page: page || 0,
                        };
                        setAlgoliaData(algoliaDataCP);

                        if (response.hits && response.hits.length) {
                            
                            if (!isCreators) {
                                setPosts(response.hits);
                                storePosts(response.hits);
                            } else {
                                setCreators(response.hits);;
                            }
                        } else {
                            if (!isCreators) {
                                setPosts([]);
                                storePosts([]);
                            } else {
                                setCreators([]);
                            }
                        }
                    } else {
                        if (!isCreators) {
                            setPosts([]);
                            storePosts([]);
                        } else {
                            setCreators([]);
                        }
                    }
                    setIsFetchingData(false);
                }
            });

            tokenFunc();
        }
        return () => (isSuscribed = false);
    }, [currentUser]);

    useDidMountEffect(() => {
        // react please run me if 'key' changes, but not on initial render
        let isSuscribed = true;
        if (facets) {
            getPosts(isSuscribed);
        }
        return () => (isSuscribed = false);
    }, [searchTerm, facets, activeFilters]);

    useDidMountEffect(() => {
        // react please run me if 'key' changes, but not on initial render
        let isSuscribed = true;

        let algoliaDataCP = JSON.parse(JSON.stringify(algoliaData));

        if (isVisible && !isFetchingData && posts.length < algoliaDataCP.nbHits) {
            setIsFetchingData(true);
            const postsCP = loadPosts(algoliaDataCP.page + 1, false);


            postsCP.then((response) => {
                setTimeout(() => {
                    if (isSuscribed) {
                        if (response) {
                            
                            const { nbHits, nbPages, page } = response;
                            algoliaDataCP = {
                                nbHits: nbHits || 0,
                                nbPages: nbPages || 0,
                                page: page || 0,
                            };
                            setAlgoliaData(algoliaDataCP);
                            if (response.hits && response.hits.length) {
                                if (isCreators) {
                                    let creatorsCopy = JSON.parse(JSON.stringify(creators));
                                    setCreators(creatorsCopy.concat(response.hits));
                                } else {
                                    let postsCopy = JSON.parse(JSON.stringify(posts));
                                    setPosts(postsCopy.concat(response.hits));
                                }
                                
                            }
                        }
                        setIsFetchingData(false);
                    }
                }, 500);
            });
        }
        return () => (isSuscribed = false);
    }, [isVisible]);

    const getPosts = (isSuscribed) =>{
        
        if (isCreators) {
            setCreators([]);
        } else {
            setPosts([]);
        }
        const postsCP = loadPosts(0, true);
        setIsFetchingData(true);
        postsCP.then((response) => {
            if (isSuscribed) {
                let algoliaDataCP = JSON.parse(JSON.stringify(algoliaData));
                const { nbHits, nbPages, page } = response;
                algoliaDataCP = {
                    nbHits: nbHits || 0,
                    nbPages: nbPages || 0,
                    page: page || 0,
                };
                setAlgoliaData(algoliaDataCP);
                if (response && response.hits && response.hits.length) {
                    if (isCreators) {
                        setCreators(response.hits);
                    } else {
                        setPosts(response.hits);
                    }
                } else {
                    if (isCreators) {
                        setCreators([]);
                    } else {
                        setPosts([]);
                    }
                }

                setIsFetchingData(false);
            }
        });
    }

    const onSuccessEdit = () => {
        setPosts([]);

        const postsCP = loadPosts();

        postsCP.then((response) => {
            if (response && response.hits && response.hits.length) {
                setPosts(response.hits);
            } else {
                setPosts([]);
            }
        });
    };

    const onPostDelete = (item) => {
        if (item && item.clv_publicacion) {
            let loading = swalAlertsService.createLoading(
                "Borrando publicación",
                "En breve estará lista"
            );
            loading.fire();
            publicationService
                .deletePublication(item.clv_publicacion)
                .then((response) => {
                    loading.close();
                    if (response && response.message) {
                        swalAlertsService.showToast("success", response.message);

                        let postsCP = JSON.parse(JSON.stringify(posts));
                        let filtered = postsCP.filter(
                            (post) => post.clv_publicacion === item.clv_publicacion
                        );
                        if (filtered.length) {
                            let index = postsCP.indexOf(filtered[0]);
                            if (index >= 0) {
                                postsCP.splice(index, 1);
                                setPosts(postsCP);
                                storePosts(postsCP);
                            }
                        }
                    } else {
                        swalAlertsService.showToast(
                            "error",
                            "Ocurrió algo al intentar borrar la publicación"
                        );
                    }
                });
        }
    };

    const onPostLiked = (item) => {
        if (posts && posts.length && item && item.clv_publicacion) {
            let loading = swalAlertsService.createLoading("Enviando reacción", "");
            loading.fire();
            profileService
                .postGeneric(`publication/${item.clv_publicacion}/reaction`, "POST", {
                    clv_tiporeaccion: 1,
                })
                .then((response) => {
                    if (response && response.data) {
                        loading.close();
                        if (response.publication) {
                            let postsCP = JSON.parse(JSON.stringify(posts));
                            const filtered = postsCP.filter(
                                (ele) =>
                                    ele.clv_publicacion === response.publication.clv_publicacion
                            );

                            if (filtered.length) {
                                let index = postsCP.indexOf(filtered[0]);

                                if (index >= 0) {
                                    postsCP[index] = response.publication;
                                    setPosts(postsCP);
                                }
                            }
                        }
                        profileService
                            .getGeneric("user/reactions")
                            .then((responseReactions) => {
                                if (responseReactions && responseReactions.data) {
                                    setReactions(responseReactions.data);
                                }
                            });
                    }
                });
        }
    };

    const normalPosts = () => {
        return posts.length && posts.map((item, index) => {
            return (
                <Post
                    key={`ssPost-${index}`}
                    item={item}
                    onSuccessEdit={onSuccessEdit}
                    onPostDelete={onPostDelete}
                    reactions={reactions}
                    postLiked={onPostLiked}
                    fontSize={letterSize}
                    lineHeight={lineSize}
                />
            );
        });
    }

    const contentPosts = () => {
        return posts.length && posts.map((item, index) => {
            return (
                <ContentPost
                    key={`contentPost-${index}`}
                    item={item}
                    onSuccessEdit={onSuccessEdit}
                    onPostDelete={onPostDelete}
                    reactions={reactions}
                    postLiked={onPostLiked}
                    fontSize={letterSize}
                    lineHeight={lineSize}
                    showDetails={onShowContentDetails}
                />
            );
        });
    }

    const onCreatorSelected = (item) => {
        if (item) {
            setCreatorSelected(item);
            setShowCreatorsModal(true);
        }
    }

    const creatorsList = () => {
        const items = creators.length && creators.map((item, index) => {
            return (
                <div className="col-12 creator-item mouse-pointer" onClick={e => onCreatorSelected(item)}>
                    <div className="row mx-0">
                        <div className="col user-image-box p-0">
                            <img loading="lazy" 
                                src={item.ThumbnailUrl ? item.ThumbnailUrl:icnUserPlaceholder} 
                                alt="User photo" 
                                className="avatar noselect"
                                onError={(e)=>{
                                    e.target.onerror = null;
                                    e.target.src=icnUserPlaceholder
                                }}
                                draggable="false"
                            />

{
                            item.clv_estatusbm === 2 ? (
                                    <img src={icnStar} alt="icnStar" className='bmIcon noselect' draggable="false"/>
                                ) : (null)
                            }
                        </div>
                        <div className="col creator-details">
                            <div className="row mx-0">
                                <div className="col-12 px-0 creator-name noselect">{item.NombreCompleto ? item.NombreCompleto:''}</div>
                                <div className="col-12 px-0 creator-contenttype noselect">{item.TiposContenido ? item.TiposContenido:''}</div>
                                <div className="col-12 px-0 creator-contents noselect">{item.Publicaciones ? `${item.Publicaciones} ${item.Publicaciones == 1 ? 'contenido':'contenidos'}`:'Sin contenido'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });

        return (
            <div className="Home-creators-content row m-0">
                {items}
            </div>
        );
    }

    const postsList = () => {
        const items = isCreators ? creatorsList():!contentClv ? normalPosts():contentPosts()
        
        return (
            <div
                id="Home-post-container"
                className={`Home-post-container ${width && width >= 1450 ? "isDesktop" : ""} ${(!posts.length && !isCreators) && !isFetchingData ? "noresults" : ""} ${!hideName ? "showName" : ""
                    }`}
                onScroll={(e) => {
                    checkScroll(e);
                }}
            >
                {isCreators && creators.length ? <React.Fragment>{items}</React.Fragment> : null}
                {!isCreators && posts.length ? <React.Fragment>{items}</React.Fragment> : null}

                {isCreators && !creators.length && !isFetchingData ? <NoResults  isMent={showMFilters}/> : null}
                {!isCreators && !posts.length && !isFetchingData ? <NoResults  isMent={showMFilters}/> : null}

                <div ref={lastItemRef} className="pt-0"></div>

                {isFetchingData ? (
                    <div className="row mx-0 Home-loaderBox">
                        <div className="col-12 px-0 pb-3">
                            <h1>
                                <Skeleton />
                            </h1>
                        </div>

                        <div className="col-12 px-0">
                            <Skeleton count={3} />
                        </div>
                    </div>
                ) : null}
            </div>
        );
    };

    const onFilterDismiss = () => {
        setShowFilters(false);
    };

    const onOpenFilters = () => {
        setShowFilters(true);
    };

    const onNotificationsTapped = () => {
        setShowNotifications(true);
    };

    const onLetterTapped = () => {
        setShowLetter(true);
    };

    const onLetterDissmiss = () => {
        setShowLetter(false);
    };

    const onNotificationsDismiss = () => {
        setShowNotifications(false);
    };

    const onShowCreatorsModalDismiss = () => {
        setShowCreatorsModal(false);
        setCreatorSelected(null);
    };

    const onSubmit = (data) => {
        if (
            data.activeFilters &&
            (data.activeFilters.filtersSelected || data.activeFilters.postedBy)
        ) {
            setActiveFilters({
                filtersSelected: data.activeFilters.filtersSelected,
                postedBy: data.activeFilters.postedBy,
                isUsingFilters: true,
            });
        } else {
            setActiveFilters({
                filtersSelected: null,
                postedBy: null,
                isUsingFilters: false,
            });
        }

        setShowFilters(false);
    };

    const checkActiveFilters = () => {
        if (
            activeFilters &&
            (activeFilters.filtersSelected ||
                (activeFilters.postedBy && activeFilters.postedBy.value !== 1))
        ) {
            return true;
        }
        return false;
    };

    const setSize = (size) => {
        localStorage.setItem("letterSize", size);
        setletterSize(size);
        setTempletterSize(size);
        setShowLetter(false);

        if(size === 22){
            setLineSize(24);
        localStorage.setItem("lineSize",24 );

        }else if(size === 28){
            setLineSize(32)
        localStorage.setItem("lineSize", 32);

        }else{
            setLineSize(22)
        localStorage.setItem("lineSize", 22);

        }
    };

    const mobileRender = () => {
        return (
            <div className={`Home-container ${showFilters ? "d-none" : ""}`}>
                <Searcher
                    isActiveFilters={checkActiveFilters()}
                    showLogo={hideName}
                    onSearchTerm={onSearchTerm}
                    onOpenFilters={onOpenFilters}
                    onNotificationsTapped={onNotificationsTapped}
                    onLetterTapped={onLetterTapped}
                />
                <HomeHeader
                    hideName={hideName}
                    onPostTypeSelected={onPostTypeSelected}
                />
                {
                    facets &&
                    <HomeFilterMent postTypeClv={postTypeClv}
                        onSubPostTypeSelected={onSubPostTypeSelected}
                        onMentorSelected={onMentorSelected}
                        onContentSelected={onContentSelected}
                    />
                }
                
                {postsList()}
            </div>
        );
    };

    const desktopRender = () => {
        return (
            <div className="Home-container">
                <Header
                    title="publicaciones"
                    activeLink="home"
                    onSearchTerm={onSearchTerm}
                    updateShowDot={updateDot} 
                    endMessage={endMessage}
                />

                <div className="row m-0 d-flex justify-content-center Home-box-container">
                    <div className="col Home-lateral-content left">
                        <div className="row m-0">
                            <div className="col-12 px-0">
                                <ProfileSheet updateProfile={updateProfile}/>
                            </div>
                        </div>
                        <div className="row m-0 pos-sticky isTop-24">
                            <div className="col-12 px-0 py-3">
                                <CompanySheet />
                            </div>
                        </div>
                        <div className="row m-0 pos-sticky isTop-525">
                            <div className="col-12 px-0 py-3">
                                <LogoutSheet />
                            </div>
                        </div>
                    </div>
                    <div className="col Home-post-box">
                        <HomeNewPost currentUser={currentUser}/>
                        <HomeFilterToggler
                            isActiveFilters={checkActiveFilters()}
                            onOpenFilters={onOpenFilters}
                            onLetterTapped={onLetterTapped}
                        />
                        <HomePostType onPostTypeSelected={onPostTypeSelected} />
                        {
                            facets &&
                            <HomeFilterMent postTypeClv={postTypeClv}
                                onSubPostTypeSelected={onSubPostTypeSelected}
                                onMentorSelected={onMentorSelected}
                                onContentSelected={onContentSelected}
                            />
                        }
                        {postsList()}
                    </div>
                    <div className="col Home-lateral-content left">
                        <div className="row m-0 pos-sticky isTop-10">
                            <div className="col-12 px-0 pb-3">
                                <NotificationsSheet />
                            </div>
                        </div>
                    </div>
                </div>
                <MessagesBubble isHome={true} />
            </div>
        );
    };

    const onShowContentDetails = (data) => {
        if (data && data.item) {
            setIsComment(data.isComment ? true:false);
            setContentSelected(data.item);
            setShowCreatorsModal(false);
        }
        
    }

    const onContentDetailsDismiss = () => {
        setIsComment(false);
        setContentSelected(null);
        
        if (creatorSelected) {
            setShowCreatorsModal(true);
        }
    }

    return (
        <React.Fragment>
            {width && width >= 1450 ? desktopRender() : mobileRender()}

            {showFilters && (
                <Filters
                    onDismiss={onFilterDismiss}
                    onSubmit={onSubmit}
                    activeFilters={activeFilters}
                    showSubmit={true}
                />
            )}
            {showNotifications && (
                <Notifications onDismiss={onNotificationsDismiss} />
            )}
            {showLetter && (
                <ModalDefault
                    show={showLetter}
                    onHide={onLetterDissmiss}
                    className="letter-container"
                >
                    <>
                        <h3 className="text-center title-letter">Tamaño de letra</h3>
                        <h4 className="subtitle-letter">
                            Elige el tamaño de letra que deseas en las publicaciones
                        </h4>
                        <div className="col-9 col-md-5 mx-auto mt-3">
                            <div className=" row text-center mb-4">
                                <div className="col-4 p-0">
                                    <button
                                        className={`btn-letter ${tempLetterSize === 18 ? 'btn-letter-active' : ''}`}
                                        style={{ fontSize: 18 }}
                                        onClick={() => setTempletterSize(18)}
                                    >
                                        Aa
                                    </button>
                                </div>
                                <div className="col-4 p-0">
                                    <button
                                        className={`btn-letter ${tempLetterSize === 22 ? 'btn-letter-active' : ''}`}
                                        style={{ fontSize: 22 }}
                                        onClick={() => setTempletterSize(22)}
                                    >
                                        Aa
                                    </button>
                                </div>
                                <div className="col-4 p-0">
                                    <button
                                        className={`btn-letter ${tempLetterSize === 28 ? 'btn-letter-active' : ''}`}
                                        style={{ fontSize: 28 }}
                                        onClick={() => setTempletterSize(28)}
                                    >
                                        Aa
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="text-test">
                            <textarea
                                name=""
                                id=""
                                cols="10"
                                rows="5"
                                className="test-input"
                                style={{ fontSize: tempLetterSize, lineHeight: `${tempLetterSize === 18 ? '22px':
                                                                                    tempLetterSize === 22 ? '24px':
                                                                                    tempLetterSize === 28 ? '32px': ''}` }}
                                value=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores
                                aspernatur illum mollitia ipsam veniam vitae. Tenetur quis
                                nesciunt at, facilis natus placeat rem quos soluta veritatis,
                                nisi repellat saepe. Dignissimos."
                            >
                            </textarea>
                        </div>
                        <div className="save-letter-container">
                            <button className="save-letter" onClick={()=>setSize(tempLetterSize)}>Guardar</button>
                        </div>
                        {/* <div className='col-6 mx-auto mt-3'>
                        <div className=' row text-center mb-4'>
                            <div className='col'>
                                <button onClick={()=>setSize(18)}>Chica</button>
                            </div>
                            <div className='col'>
                                <button onClick={()=>setSize(22)}>Mediana</button>
                            </div>
                            <div className='col'>
                                <button onClick={()=>setSize(28)}>Grande</button>
                            </div>
                        </div>
                    </div> */}
                    </>
                </ModalDefault>
            )}

            {
                showCreatorsModal &&
                <CreatorsContentsModal onBottomSheetDimiss={onShowCreatorsModalDismiss} 
                    creator={creatorSelected} 
                    letterSize={letterSize}
                    lineSize={lineSize}
                    onShowContentDetails={onShowContentDetails}
                />
            }

            {
                contentSelected && !showCreatorsModal &&
                <ContentDetailModal details={contentSelected} isComment={isComment} onDismiss={onContentDetailsDismiss}/>
            }
        </React.Fragment>
    );
};

export { Home };
