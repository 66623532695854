import React, { useState, useEffect } from 'react';

import { BottomSheetShared, ModalDefault } from '../../../';

import icnCheckBox from '../../../../../_assets/media/images/icons/icn_checkbox.png';
import icnCheckBoxSelected from '../../../../../_assets/media/images/icons/icn_checkbox_selected.png';
import icnSearch from '../../../../../_assets/media/images/icons/icn_search.png';
import icnCancel from '../../../../../_assets/media/images/icons/icn_cancel.png';

import './FilterOptionPicker.scss';

const FilterOptionPicker = (props) => {
    const [open, setOpen] = useState(true);
    const [activeBtn, setActiveBtn] = useState(true);
    const [options, setOptions] = useState([]);
    const [optionsFiltered, setOptionsFiltered] = useState([]);
    const [optionsSelected, setOptionsSelected] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const onDismiss = () => {
        setOpen(false);
        if (props.onDismiss) {
            props.onDismiss(false);
        }
    }

    const configureFiltersSelected = () => {
        if (props.filtersSelected && props.filtersSelected[props.mode]) {
            setOptionsSelected(props.filtersSelected[props.mode]);
        }
    }

    const prepareCatalogs = () => {
        if(props.mode === "Asociacion") {
                var options = [{clv_estatusbm: 2, EstatusBM: "Soy miembro activo", id: 2, name: "BoardMedia"}]
                setOptions(options)
                setOptionsFiltered(JSON.parse(JSON.stringify(options)))
                configureFiltersSelected();
        }else {
            if (props.mode && props.catalogs && props.catalogs[props.mode] && props.catalogs[props.mode].length) {
                setOptions(props.catalogs[props.mode]);
                setOptionsFiltered(JSON.parse(JSON.stringify(props.catalogs[props.mode])));
                configureFiltersSelected();
            } else {
                onDismiss();
            }
        }

    }



    const pickOption = (option) => {
        if (option) {
            let optCopy = JSON.parse(JSON.stringify(optionsSelected));
            if (optCopy.length) {
                const optionFiltered = optCopy.filter(element => element.id === option.id);

                if (optionFiltered.length) {
                    const index = optCopy.indexOf(optionFiltered[0]);

                    if (index >= 0) {

                        optCopy.splice(index, 1);
                    }
                } else {
                    optCopy.push(option);
                }
            } else {
                optCopy.push(option);
            }
            setOptionsSelected(optCopy);
        }
    }

    const onAddBtnTapped = () => {
        if (props.onAddBtnTapped && props.mode) {
            props.onAddBtnTapped({optionsSelected, mode: props.mode});
        }
    }

    const footerBtn = () => {
        return (
            <button className={`btn-cleared ${activeBtn ? 'active':''}`} onClick={() => { onAddBtnTapped()}}>
                Agregar
            </button>
        );
    }

    const onSearchChanged = (e) => {
        setSearchTerm(e.target.value);
    }

    const optionsBuilder = () => {
        const items = optionsFiltered.map((option, index) => {
            let isSelected = false;
            if (optionsSelected.length) {
                let filtered = optionsSelected.filter(element => element.id === option.id);
                if (filtered.length) {
                    isSelected = true;
                }
            }

            return (
                <div className="row m-0 FilterOptionPicker-item-box d-flex align-items-center noselect mouse-pointer" key={index} onClick={() => {pickOption(option)}}>
                    <div className="col p-0">
                        {props.title === "Aficiones" ? option.Aficion : option.name}
                    </div>
                    <div className="col p-0 FilterOptionPicker-selector">
                        <button className='btn-cleared'>
                            <img src={isSelected ? icnCheckBoxSelected:icnCheckBox} alt="checkbox icon"/>
                        </button>
                    </div>
                </div>

            );
        });

        return (
            <React.Fragment>
                <div className="row m-0">
                    <div className="col-12 FilterOptionPicker-header-box">
                        <button className='btn-cleared' onClick={onDismiss}>Cancelar</button>
                        <p className='m-0'>{props.title || 'Seleccionar opción'}</p>
                    </div>
                </div>
                <div className="row m-0 FilterOptionPicker-searcher-container d-flex align-items-center">
                    <div className="col-12 p-0 FilterOptionPicker-searcher-box">
                        <label htmlFor="FilterOptionPicker-searcher">
                            <img src={icnSearch} alt="search icon"/>
                        </label>
                        <input type="text" id='FilterOptionPicker-searcher' name='FilterOptionPicker-searcher' value={searchTerm} onChange={(e) => {onSearchChanged(e)}}/>
                        {
                            searchTerm ? (
                                <button className='btn-cleared' onClick={(e) => {setSearchTerm('')}}>
                                    <img src={icnCancel} alt="cancel icon"/>
                                </button>
                            ):(null)
                        }
                    </div>
                </div>

                <div className="row m-0 FilterOptionPicker-items-container">
                    <div className="col-12 p-0">
                        {items}
                    </div>
                </div>

                <div className="FilterOptionPicker-footer-container">
                    {footerBtn()}
                </div>
            </React.Fragment>
        );
    }

    useEffect(() => {
        setSearchTerm('');
        prepareCatalogs();
    }, []);

    useEffect(() => {
        if (options && options.length) {
            let filtered = options;
            if (searchTerm) {
                filtered = options.filter(option => option.name.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1);
            }
            setOptionsFiltered(filtered);
        }
    }, [searchTerm]);

    useEffect(() => {
        setActiveBtn(optionsSelected.length > 0);
    }, [optionsSelected]);

    // <BottomSheetShared showHeader={true}
    //     footerBtn={footerBtn} isOpen={open}
    //     onDismiss={onDismiss}
    //     title={props.title || 'Seleccionar opción'}>
    //
    //     <div className="FilterOptionPicker-container">
    //         {optionsBuilder()}
    //     </div>
    // </BottomSheetShared>

    return (
        <ModalDefault show={true} className='full-view'>
            <div className="FilterOptionPicker-container">
                {optionsBuilder()}
            </div>
        </ModalDefault>
    );
}

export { FilterOptionPicker }
