
import React, { useEffect, useState } from 'react';
import Switch from 'react-bootstrap/esm/Switch';
import { useHistory, useLocation } from 'react-router-dom';
import fondo from '../../../_assets/media/images/icons/fondoregister.svg';
import select from '../../../_assets/media/images/icons/select.svg'
import { authenticationService, profileService, swalAlertsService } from '../../../_resources/services';
import { ModalDefault, DiscountCode, AppsDisclaimer } from '../../shared';
import close from '../../../_assets/media/images/icons/icn_close.png'

import icnTogglers from '../../../_assets/media/images/icons/icns_togglers.png';
import icnDiscout from '../../../_assets/media/images/icons/icn_discount_modal.png';
import icnGift from '../../../_assets/media/images/icons/icn_gift.png';

import { getMessagin, getToken } from "../../../firebaseInit";

import './RegisterSteps.scss';

const RegisterSteps = (props) => {
    const history = useHistory()
    const location = useLocation()
    let value = 1


    const [view, setView] = useState({
        welcome: true,
        steps: false,
        personal: false,
        company: false
    })

    const [disabledButton, setDisabledButton] = useState(true)
    const [user, setUser] = useState({})
    const [stepsValue, setStepsValue] = useState(0)
    const [modal, setModal] = useState(true)
    const [currentUser, setCurrentUser] = useState(null);
    const [showDiscountRequest, setShowDiscountRequest] = useState(false);
    const [discountCode, setDiscountCode] = useState(null);
    const [isDiscountInvalid, setIsDiscountInvalid] = useState(false);
    const [showDiscountBottomSheet, setShowDiscountBottomSheet] = useState(false);
    const [isOpenDisclaimer, setIsOpenDisclaimer] = useState(false);

    const [loadUser, setLoadUser] = useState(false)

    useEffect(() => {
        let isSuscribed = true;

        if (props.onToggleTab) {
            props.onToggleTab(true);
        }

        authenticationService.getUser().then((response) => {
            if (response && response.data && isSuscribed) {
                setUser(response.data);
                setLoadUser(true);
                tokenFunc(response.data.UUID);
                if(location.search.indexOf('continue=true') > -1) {
                    toggleView();
                }
            }
        });


        return () => isSuscribed = false
    }, [loadUser])

    const toggleView = () => {
        setContador();
        setView({ welcome: false, steps: true, personal: false, company: false })
    }

    const tokenFunc = async (UUID) => {
        let data = await getToken(UUID);
        if (data) {
            profileService
                .postGeneric("user/device", "post", { Device: data.currentToken })
                .then((response) => { });
        }
        return data;
    };

    const setContador = () => {
        if (user) {
            let item = 0;
            if (user.FillRate) {
                item++;
                if (user.FillRate.TieneDatos) {
                    item++
                }
                if (user.FillRate.TieneMembresia) {
                    item++
                }
                setStepsValue(item)
                if (item === 2) {
                    setDisabledButton(false)
                } else {
                    setDisabledButton(true)
                }
            }
        }

    }
    //
    // const getMembership = () => {
    //     return (
    //         <ModalDefault show={modal} className="modal-register">
    //             <img src={close} width="30px" height="30px" onClick={() => setModal(false)}/>
    //             <h1>Pago exitoso</h1>
    //             <p>¡Gracias por suscribirte!</p>
    //         </ModalDefault>
    //     )
    // }

    const checkIfCanContinue = () => {

        if (user && user.FillRate && user.FillRate.TieneDatos && user.FillRate.TieneMembresia) {
            return true;
        }
        return false;
    }

    const hasDiscount = () => {
        return (
            <ModalDefault show={modal} className="RegisterSteps-modal-discount">
                <div className="row m-0">
                    <div className="col-12 px-0 RegisterSteps-modal-img-box">
                        <img src={icnDiscout} alt="icnDiscout" width='106' height='61' />
                    </div>

                    <div className="col-12 px-0 RegisterSteps-modal-txt-box">
                        ¿Tienes un código de descuento para tu suscripción?
                    </div>

                    <div className="col-12 px-0 RegisterSteps-modal-btns-box">
                        <div className="row m-0">
                            <div className="col left ps-0 pe-2">
                                <button className='btn-cleared' onClick={() => { user.FillRate.TieneMembresia === false && history.push("/membership/0", { id: 0 }) }}>
                                    No
                                </button>
                            </div>
                            <div className="col right pe-0 ps-2">
                                <button className='btn-cleared isSuccess' onClick={openCodeModal}>
                                    Si
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalDefault>
        );
    }

    const goToMain = () => {
        setIsOpenDisclaimer(true);
        // authenticationService.getUser().then((response) => {
        //     history.replace("/");
        // });

    }

    const onDisclaimerDismiss = () => {
        authenticationService.getUser().then((response) => {
            setIsOpenDisclaimer(false);
            history.replace("/");
        });
    }

    const openCodeModal = () => {
        setShowDiscountRequest(false);
        setTimeout(() => {
            setShowDiscountBottomSheet(true);
        }, 200);
    }

    const onDiscountBottomSheetDismiss = () => {
        setShowDiscountBottomSheet(false);
    }

    const onCodeSuccess = () => {

        authenticationService.getUser().then((response) => {
            onDiscountBottomSheetDismiss();
            if (response && response.data) {
                setUser(response.data);
            }

        })
    }

    const renderWelcome = () => {
        return (
            <div className="row m-0 RegisterSteps-welcome-container" style={{ backgroundImage: `url(${fondo})` }}>
                <div className="col-12 RegisterSteps-welcome-text-container">
                    <div className="row m-0">
                        <div className="col-12 px-0 title text-center">
                            ¡Hola!
                        </div>
                        <div className="col-12 px-0 subtitle text-center">
                            Y bienvenido a <strong>Wenet.</strong> Vamos a continuar tu registro.
                        </div>

                        <div className="col-12 px-0 subtitle-min text-center">
                            Te tomará 5 minutos.
                        </div>
                    </div>
                </div>
                <div className="col-12 RegisterSteps-welcome-submit-container px-3 px-md-0">
                    <button className="btn-cleared" onClick={() => toggleView()}>
                        ¡Comenzar ahora!
                    </button>
                </div>
            </div>
        );
    }

    const onMembershipOptionTapped = () => {

        if (user.FillRate.TieneDatos) {

            if (user.FillRate.Confirmado) {
                if (user.FillRate.TieneMembresia === false) {
                    history.push("/membership/0", { id: 0 })
                }
            } else {
                swalAlertsService.showToastCenter('warning', user.FillRate.MensajeConfirmado);
            }
        } else {
            swalAlertsService.showToastCenter('error', 'Debes completar antes el paso 2 Perfil de cuenta.');
        }
    }

    const renderSteps = () => {
        return (
            <div className="row m-0 RegisterSteps-steps-container">
                <div className="col-12 px-0 RegisterSteps-steps-main-container">
                    <div className="row m-0">
                        <div className="col-12 title">
                            Registro
                        </div>
                        <div className="col-12 subtitle">
                            {stepsValue} de 3 pasos completados
                        </div>
                    </div>

                    <div className="row m-0 RegisterSteps-steps-items-container">

                        <div className="col-12 RegisterSteps-steps-item">
                            <div className={`row m-0 RegisterSteps-steps-item-row ${user.FillRate ? 'success' : ''}`}>
                                <div className="col icon-box noselect">
                                    <img src={icnTogglers} alt="success" />
                                </div>
                                <div className="col row m-0">
                                    <div className="col-12 ps-0 item-title noselect">
                                        Crear cuenta
                                    </div>
                                    {
                                        user.FillRate && !user.FillRate.TieneDatos &&
                                        <div className="col-12 ps-0 item-subtitle noselect">
                                            Dar click en el siguiente paso para continuar tu registro.
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="col-12 RegisterSteps-steps-item">
                            <div className={`row m-0 RegisterSteps-steps-item-row ${user.FillRate && user.FillRate.TieneDatos ? 'success' : ''}`}
                                onClick={() => {
                                    user.FillRate && user.FillRate.TieneDatos === false && history.push("/register-wizard/init")
                                }}>
                                <div className="col icon-box noselect">
                                    {
                                        user.FillRate && user.FillRate.TieneDatos ? (
                                            <img src={icnTogglers} alt="success" />
                                        ) : (
                                            <span>2.</span>
                                        )
                                    }
                                </div>
                                <div className="col ps-0">
                                    <div className="row m-0">
                                        <div className="col-12 px-0 item-title noselect">
                                            Perfil de cuenta
                                        </div>
                                        {
                                            user.FillRate && !user.FillRate.TieneDatos &&
                                            <div className="col-12 px-0 item-subtitle noselect">
                                                Queremos conocer tus datos para identificar tu networking en WeNet.
                                            </div>
                                        }

                                        {
                                            user.FillRate && user.FillRate.TieneDatos && !user.FillRate.Confirmado &&
                                            <div className="col-12 px-0 item-subtitle noselect">
                                                Dar click en el siguiente paso para continuar tu registro.
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 RegisterSteps-steps-item">
                            <div className={`row m-0 RegisterSteps-steps-item-row ${user.FillRate && user.FillRate.TieneMembresia ? 'success' : ''}  ${!user || !user.FillRate || !user.FillRate.TieneDatos ? 'disbled-mem' : ''}`}
                                onClick={onMembershipOptionTapped}>
                                <div className="col icon-box noselect">
                                    {
                                        user.FillRate && user.FillRate.TieneMembresia ? (
                                            <img src={icnTogglers} alt="success" />
                                        ) : (
                                            <span>3.</span>
                                        )
                                    }
                                </div>
                                <div className="col ps-0">
                                    <div className="row m-0">
                                        <div className="col-12 px-0 item-title noselect">
                                            Define tipo de membresía
                                        </div>
                                        {
                                            user && user.FillRate && !user.FillRate.Confirmado &&
                                            <div className="col-12 px-0 item-subtitle noselect">
                                                En espera de ser aceptado.
                                            </div>
                                        }
                                        {
                                            user && user.FillRate && user.FillRate.Confirmado && !user.FillRate.TieneMembresia &&
                                            <div className="col-12 px-0 item-subtitle noselect">
                                                Selecciona la membresía de tu preferencia.
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            user && user.FillRate && !user.FillRate.Confirmado ?
                                (
                                    <div className="col-12 RegisterSteps-steps-item">
                                        <div className="col-12 px-0 item-title noselect">
                                            {user.FillRate.MensajeConfirmado}
                                        </div>
                                    </div>
                                ) :
                                (null)
                        }

                    </div>
                </div>

                {
                    checkIfCanContinue() ?
                        <div className="col-12 RegisterSteps-steps-submit-container px-3 px-md-0">
                            <button disabled={!checkIfCanContinue()}
                                onClick={() => goToMain()}
                                className={`btn-cleared ${checkIfCanContinue() === true ? 'active' : ''}`}>
                                Entrar
                            </button>
                        </div> :
                        (null)
                }


            </div>
        );
    }

    const mainRender = () => {
        if (view.welcome) {
            return renderWelcome();
        } else if (view.steps) {
            return renderSteps();
        }

        return null;
    }

    return (
        <div className="RegisterSteps-container container-fluid p-0">
            {mainRender()}

            {/* {
                showDiscountRequest && hasDiscount()
            }

            {
                showDiscountBottomSheet &&
                <DiscountCode
                    onDismiss={onDiscountBottomSheetDismiss}
                    onCodeSuccess={onCodeSuccess}
                />
            } */}

            {
                isOpenDisclaimer &&
                <AppsDisclaimer onDismiss={onDisclaimerDismiss} />
            }
        </div>
    );
}

export { RegisterSteps };
