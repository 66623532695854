import React, { useState, useEffect, Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Redirect,
    Switch,
    useHistory
} from 'react-router-dom';

import { Helmet } from "react-helmet";

import './App.scss';

import CacheBuster from 'react-cache-buster';
import { version } from '../../../package.json';

import { history, Role } from '../../_resources/helpers';
import { authenticationService, infoService } from '../../_resources/services';
import { PrivateRoute, PublicRoute } from '../../_resources/components';
import {
    Home,
    NotFound,
    Profile,
    AddPost,
    PostDetails,
    Messages,
    Directory,
    Invitation,
    RecomendationDetails,
    NotificationDetails,
    NotificationBirthday,
    NotificationsList
} from '../pages';
import {
    // Welcome,
    Login,
    Register,
    Recover,
    RegisterSteps,
    RegisterWizard,
    LandingIndex,
    LandingOffer,
    LandingTutorial
} from '../auth';
import { Loader, Tab } from '../shared';
import { UserProfile } from '../pages/users-profile/UserProfile';
import { EditUser } from '../pages/configuration/edit-user/EditUser';
import { EditCompany } from '../pages/configuration/edit-company/EditCompany';
import { Settings } from '../pages/configuration/configuration/settings';
import { BloquedUsers } from '../pages/configuration/bloqued-users/bloquedusers';
import { RestrictedUsers } from '../pages/configuration/restricted-users/RestrictedUsers';
import { NewPassword } from '../pages/configuration/new-password/NewPassword';
import { Notifications } from '../pages/configuration/notifications/Notifications';
import { Support } from '../pages/configuration/support/Support';
import { Folleto } from '../pages/configuration/folleto/Folleto';
import { Membership } from '../pages/membership/Membership';
import { MembershipProfile } from '../pages/configuration/membership/membership-profile/MembershipProfile';
import { MethodPay } from '../pages/configuration/membership/method-pay/MethodPay';
import { Preferences } from '../pages/configuration/preferences/Preferences';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


const App = (props) => {
    const isProduction = process.env.NODE_ENV === 'production';
    const historyRouter = useHistory();
    const [loading, setLoading] = useState(false);
    const [hideTab, setHideTab] = useState(false);
    const [showAnimation, setShowAnimation] = useState(true);
    const [animationShow, setAnimationShow] = useState(true);
    const [isAdmin, setIsAmin] = useState(false);

    const getCatalogs = () => {
        infoService.getCatalogs();
    }

    const getVersion = () => {
        infoService.getVersion();
    }

    const onToggleTab = (value) => {
        setHideTab(value);
    }

    const reload = (reload) => {
        // window.location.reload(reload)
    }

    useEffect(() => {
        let isSuscribed = true;
        getCatalogs();
        getVersion();

        if (isSuscribed) {
            let user = localStorage.getItem('currentUser');

            if (user) {
                authenticationService.getUser()
            }
        }



        return () => isSuscribed = false
    }, []);

    return (
        <React.Fragment>
        {/* /<GoogleReCaptchaProvider useRecaptchaNet reCaptchaKey="6LfXwokhAAAAANaTzdPQBm9VabRfC0KhzHdIFtRs"> */}

        {/* </GoogleReCaptchaProvider> */}
            {
                // <CacheBuster
                //     currentVersion={version}
                //     isEnabled={isProduction}
                //     isVerboseMode={false}
                //     loadingComponent={<Loader showAnimation={showAnimation} animationShow={animationShow}/>}>
            }

            <Helmet>
                <script type="text/javascript" data-conekta-public-key={process.env.REACT_APP_CONEKTA_KEY} src="https://cdn.conekta.io/js/v1.0.1/conekta.js"></script>
            </Helmet>
            {
                loading === false ? (
                    <Router history={history}>
                        <div className={`h-100 p-relative ${!hideTab ? 'tabbed' : ''}`}>
                            <Switch>
                                {
                                    // <PrivateRoute path="/admin" roles={[Role.Admin]} component={AdminPage} />
                                }
                                <PrivateRoute exact path='/' component={(props) => <Home onToggleTab={onToggleTab} {...props} />} />

                                <PrivateRoute exact path="/profile" component={(props) => <Profile onToggleTab={onToggleTab} reload={reload} {...props} />} />
                                <PrivateRoute exact path="/profile-user/:user_id" component={(props) => <UserProfile onToggleTab={onToggleTab} {...props} />} />
                                <PrivateRoute exact path="/profile/edit/:user_id" component={(props) => <EditUser onToggleTab={onToggleTab} {...props} />} />
                                <PrivateRoute exact path="/profile/edit-company/:user_id" component={(props) => <EditCompany onToggleTab={onToggleTab} {...props} />} />
                                <PrivateRoute exact path="/profile/membership" component={(props) => <MembershipProfile onToggleTab={onToggleTab} {...props} />} />
                                <PrivateRoute exact path="/profile/method-pay" component={(props) => <MethodPay onToggleTab={onToggleTab} {...props} />} />
                                <PrivateRoute exact path="/profile/folleto" component={(props) => <Folleto onToggleTab={onToggleTab} {...props} />} />

                                <PrivateRoute exact path="/setting" component={(props) => <Settings onToggleTab={onToggleTab} reload={reload} {...props} />} />
                                <PrivateRoute exact path="/setting/bloqued_users" component={(props) => <BloquedUsers onToggleTab={onToggleTab} {...props} />} />
                                <PrivateRoute exact path="/setting/silence_users" component={(props) => <RestrictedUsers onToggleTab={onToggleTab} {...props} />} />
                                <PrivateRoute exact path="/setting/new_password" component={(props) => <NewPassword onToggleTab={onToggleTab} {...props} />} />
                                <PrivateRoute exact path="/setting/notifications" component={(props) => <Notifications onToggleTab={onToggleTab} {...props} />} />
                                <PrivateRoute exact path="/setting/support" component={(props) => <Support onToggleTab={onToggleTab} {...props} />} />
                                <PrivateRoute exact path="/setting/preferences" component={(props) => <Preferences onToggleTab={onToggleTab} {...props} />} />


                                <PrivateRoute exact path="/membership/:type" component={(props) => <Membership onToggleTab={onToggleTab} {...props} />} />

                                <PrivateRoute exact path="/post/:post_id" component={(props) => <PostDetails onToggleTab={onToggleTab} {...props} />} />
                                <PrivateRoute exact path="/talent/:talent_id" component={(props) => <RecomendationDetails onToggleTab={onToggleTab} {...props} />} />
                                <PrivateRoute exact path="/notification/:param" component={(props) => <NotificationDetails onToggleTab={onToggleTab} {...props} />} />
                                <PrivateRoute exact path="/notification-birthday/:param" component={(props) => <NotificationBirthday onToggleTab={onToggleTab} {...props} />} />
                                <PrivateRoute exact path="/notifications" component={(props) => <NotificationsList onToggleTab={onToggleTab} {...props} />} />

                                <PrivateRoute exact path='/messages' component={(props) => <Messages reload={reload} onToggleTab={onToggleTab} {...props} />} />
                                <PrivateRoute exact path='/directory' component={(props) => <Directory onToggleTab={onToggleTab} {...props} />} />
                                    <PrivateRoute exact path='/register-steps' component={(props) => <RegisterSteps onToggleTab={onToggleTab} {...props} />} />
                                    <PrivateRoute exact path='/register-wizard/:step' component={(props) => <RegisterWizard onToggleTab={onToggleTab} {...props} />} />
                                    {
                                        // <PublicRoute exact path="/welcome" component={(props) => <Welcome onToggleTab={onToggleTab} {...props} /> } />
                                    }
                                    <PublicRoute exact path="/login" component={(props) => <Login onToggleTab={onToggleTab} {...props} />} />
                                    <PublicRoute exact path="/register" component={(props) => <Register onToggleTab={onToggleTab} {...props} />} />
                                    <PublicRoute exact path="/recover" component={(props) => <Recover onToggleTab={onToggleTab} {...props} />} />

                                <PublicRoute exact path="/welcome" component={(props) => <LandingIndex onToggleTab={onToggleTab} {...props} />} />
                                <PublicRoute exact path="/welcome/oferta" component={(props) => <LandingOffer onToggleTab={onToggleTab} {...props} />} />
                                <PublicRoute exact path="/welcome/tutorial" component={(props) => <LandingTutorial onToggleTab={onToggleTab} {...props} />} />


                                <Route exact path="/invitation/:iuid" component={(props) => <Invitation onToggleTab={onToggleTab} {...props} />} />

                                <Route path="*" component={NotFound} />
                            </Switch>
                            <Tab hideTab={hideTab} />
                        </div>


                    </Router>
                ) : (
                    <Loader showAnimation={showAnimation} animationShow={animationShow} />
                )}

            {
                // </CacheBuster>
            }



        </React.Fragment>
    );
}

export { App };
