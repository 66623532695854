import React, { useState, useEffect, useRef } from 'react';
import { ImageGroup, Image } from 'react-fullscreen-image'

import { LazyImage } from "react-lazy-images";

import Linkify from 'react-linkify';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Redirect, useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

import { Document, Page, pdfjs } from 'react-pdf';
import { toolsHelper } from '../../../_resources/helpers';
import './ContentPost.scss';
import { authenticationService, swalAlertsService, infoService } from '../../../_resources/services';

import { Dropdown } from 'react-bootstrap';

import { ReportPost, VideoPlayer, LikesModal, ContentsModal, ContentVideo } from '../';

import icnUserPlaceholder from '../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnMore from '../../../_assets/media/images/icons/icn_more_options.png';
import icnBrochure from '../../../_assets/media/images/icons/icn_doc.png';
import icnComment from '../../../_assets/media/images/icons/icn_comment.png';
import icnDownload from '../../../_assets/media/images/icons/icn_download.png';
import start from '../../../_assets/media/images/icons/icn_batch_star.svg';
import close from '../../../_assets/media/images/icons/icn_close.png';
import icnPlay from '../../../_assets/media/images/icons/icn_play_video.png';
import icnsReactions from '../../../_assets/media/images/icons/icns_reactions.png';
import icnContents from '../../../_assets/media/images/icons/icn_contents.png';
import icnArrowForwardBlack from '../../../_assets/media/images/icons/icn_arrow_forward_black.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ContentPost = (props) => {
    let galleryRef = useRef(null);
    let galleryFullRef = useRef(null);
    const [currentUser, setCurrentUser] = useState(authenticationService.currentUserValue);
    const [isEllipsed, setIsEllipsed] = useState(false);
    const [showMore, setShowMore] = useState(false);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [imageFullScreen, setImageFullScreen] = useState(false);

    const [galleryPosition, setGalleryPosition] = useState(0);
    const [catalogs, setCatalogs] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isOpenLikesModal, setIsOpenLikesModal] = useState(false);
    const [isOpenContentsModal, setIsOpenContentsModal] = useState(false);

    const [videoToShow, setVideoToShow] = useState(null);
    const [showVideoContent, setShowVideoContent] = useState(false);

    const history = useHistory()
    var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

    const isEllipsisActive = (e) => {
        return (e.offsetHeight < e.scrollHeight);
    }

    const checkIfElementEllipsed = () => {
        let element = document.getElementById(props.item.clv_publicacion);
            if (element) {
                setIsEllipsed(isEllipsisActive(element));
                if(props && props.isDetails){
                    setShowMore(true);
                }
            }
    }

    const detectLinksInContent = (content) => {

        if (content && content.Contenido && content.Contenido.length) {
            let urlRegex = /(https?:\/\/[^\s]+)/g;
            /*return content.replace(urlRegex, function(url) {
                return `<a href="' + url + '">' + url + '</a>`;
            })*/
            // or alternatively
            return content.Contenido.replace(urlRegex, `<a href="$1" target="_blank">$1</a>`)
        }

        return '';

    }

    function onDocumentLoadSuccess({ numPages }) {
        if (numPages > 0) {
            setNumPages(1);
        }

    }

    function setUser() {
        if(currentUser.Usuario.clv_usuario === props.item.clv_usuario ) {
            history.replace('/profile')
        }else {
            history.push('/profile-user/' + props.item.clv_usuario, {from: '/'} )
        }
    }

    const onCommentBtnTapped = () => {

        if (props.isDetails) {
            if (props.onCommentBtnTapped) {
                props.onCommentBtnTapped();
            }
        } else {
            goContentPostDetails(true);
        }
    }

    useEffect(() => {
        let isSuscribed = true;

        infoService.catalogs.subscribe((response) => {
            if (isSuscribed && response && response.TiposAdjunto && response.TiposAdjunto.length) {
                setCatalogs(response.TiposAdjunto);
            }
        });
        
        checkIfElementEllipsed();

        return () => isSuscribed = false
    }, []);

    const goContentPostDetails = (isComment = false) => {
        if (props.showDetails) {
            props.showDetails({isComment, item: props.item});
        }
    }

    const goImageFullScreen = () => {
        setImageFullScreen(true);
    }

    const closeFullScreen = () => {
        setImageFullScreen(false);
    }

    const showGalleryImages = (goFull) => {
        if (props.item.Galeria && props.item.Galeria.length) {
            return props.item.Galeria.map((image, index) => {
                return showItemByType(goFull, image, index);
            });

        }
        return null;
    }

    const showItemByType = (goFull, image, index) => {
        if (catalogs && catalogs.length) {
            const typeFiltered = catalogs.filter(el => el.clv_tipoadjunto === image.clv_tipoimagen);

            if (typeFiltered.length && (typeFiltered[0].clv_tipoadjunto || typeFiltered[0].clv_tipoimagen)) {
                let type = typeFiltered[0].clv_tipoadjunto || typeFiltered[0].clv_tipoimagen;
                switch (type) {
                    case 6://video mp4
                        return (
                            <div className='item isVideo pos-relative' key={index}>
                                <VideoPlayer url={image.ImageUrl} id={`VideoPost-${image.clv_imagenxpublicacion}`} thumbnail={image.ImageThumbnailUrl}/>
                                {
                                    goFull ? (
                                        <a href={image.ImageUrl} target='_blank'>
                                            <img src={icnDownload} alt="donwload icon" className='image-icon'/>
                                        </a>
                                    ):(null)
                                }
                            </div>
                        );
                    case 3://jpg
                    case 4://png
                        return (
                            <div className='item' key={index}>
                                <LazyImage src={image.ImageUrl}
                                    alt="ContentPost img"
                                    placeholder={ ({imageProps, ref}) =>

                                        <img ref={ref} src={image.ImageThumbnailUrl} alt={imageProps.alt} className={'LazyImage-Placeholder'}/>
                                    }
                                    actual={({imageProps}) =>
                                        <img {...imageProps}
                                            onClick={(e) => {
                                                if (goFull) {
                                                    goImageFullScreen();
                                                }
                                            }}
                                        />
                                    }
                                />
                                {
                                    goFull ? (
                                        <a href={image.ImageUrl} target='_blank'>
                                            <img src={icnDownload} alt="donwload icon" className='image-icon'/>
                                        </a>
                                    ):(null)
                                }
                            </div>
                        );
                        break;
                    default:

                }
            }

        }

        return null;
    }

    const didIReacted = () => {
        if (props.item && props.item.clv_publicacion && props.reactions && props.reactions.length) {
            const reacted = props.reactions.filter(element => element.clv_publicacion === props.item.clv_publicacion && element.clv_tiporeaccion === 1);

            if (reacted.length) {
                return true;
            }
        }
        return false;
    }

    const onLikeBtnTapped = () => {
        if (props.postLiked) {
            props.postLiked(props.item);
        }
    }

    const showLikesModal = () => {
        setIsOpenLikesModal(true);
    }

    const getReactions = () => {
        if (props.item && props.item.TiposReaccion && props.item.TiposReaccion.length) {
            const likes = props.item.TiposReaccion.filter(el => el.clv_tiporeaccion === 1);

            if (likes.length) {
                return likes[0];
            }
        }
        return null;
    }

    const getContents = () => {
        if (props.item && props.item.Contenidos && props.item.Contenidos.length) {
            return props.item.Contenidos;
        }
        return null;
    }

    const goToMentionLink = (clv_usuario) => {
        if (clv_usuario) {
            
            if (
                currentUser &&
                currentUser.Usuario &&
                currentUser.Usuario.clv_usuario &&
                currentUser.Usuario.clv_usuario === clv_usuario
            ) {
                history.replace('/profile');
            } else {
                history.push('/profile-user/' + clv_usuario, {from: '/'} );
            }
        }
    }

    const createMentionTxt = (message) => {
        let value = message.Contenido;
        if (message.Menciones && message.Menciones.length) {
            let finalValue = [];
            value = value.trim();
            let tokenString = value.split(' ');
            let keyValue = 0;
            if (tokenString.length) {
                tokenString.forEach((item, i) => {
                    if (item.length) {

                        if (item.indexOf('@@') >= 0) {
                            const brSpacing = item.indexOf('\n') >= 0;
                            const fixedTxt = item.replaceAll('\n', '');
                            const mentioned = message.Menciones.filter(el => el.Tag === fixedTxt);

                            if (mentioned.length) {
                                keyValue += 1;
                                const obj = [0].map((el, i) => {
                                    if (brSpacing) {
                                        return (
                                            <span key={keyValue}>
                                                <br/>
                                                <span className='bubbleMention' title={mentioned[0].NombreCompleto} onClick={e => goToMentionLink(mentioned[0].clv_usuario)}>{`${mentioned[0].Nombre}${tokenString.length - 1 > i ? ' ':''}`}</span>
                                            </span>
                                        );
                                    }
                                    return (<span key={keyValue} className='bubbleMention' title={mentioned[0].NombreCompleto} onClick={e => goToMentionLink(mentioned[0].clv_usuario)}>{`${mentioned[0].Nombre}${tokenString.length - 1 > i ? ' ':''}`}</span>);
                                });
                                finalValue = finalValue.concat(obj);
                            }
                        } else {
                            keyValue += 1;
                            const obj = [0].map((el, i) => {
                                return (<span key={keyValue}>{`${item}${tokenString.length - 1 > i ? ' ':''}`}</span>);
                            });
                            finalValue = finalValue.concat(obj);
                        }
                    }
                });

            }
            return finalValue;
        }
        return message.Mensaje || null;
    }

    const renderMessageText = (message) => {
        if (message && message.Contenido) {
            const isDeleted = message.Eliminado;
            if (!isDeleted && message.Menciones && message.Menciones.length) {
                return createMentionTxt(message);
            }
            return message.Contenido.split('\n').map((str, indexv) => {
                if(!str.length){
                    return <div key={`valval-${indexv}`}><br /></div>
                }
                return <div>{str}</div>
            });
        }
        return null;
    }

    const showContentImg = () => {
        if (props.item.Galeria && props.item.Galeria.length && props.item.Galeria[0].ImageThumbnailUrl) {
            return props.item.Galeria[0].ImageThumbnailUrl;
        }
        
        return icnUserPlaceholder;
    }

    const openContents = () => {
        if (props.item && props.item.Contenidos && props.item.Contenidos.length) {
            setIsOpenContentsModal(true);
        }
    }

    const renderContents = () => {
        if (props && props.item && props.item.Contenidos && props.item.Contenidos.length) {
            return (
                <div className="row mx-0 ContentPost-contents-box mouse-pointer noselect" onClick={openContents}>
                    <div className="col contents-icon noselect">
                        <img src={icnContents} alt="contents icon"/>
                    </div>
                    <div className="col contents-title noselect">Ver contenido</div>
                    <div className="col contents-arrow noselect">
                        <img src={icnArrowForwardBlack} alt="arrow forward icon" />
                    </div>
                </div>
            );
        }
        return null;
    }

    const onVideoShow = (content) => {
        if (!content || !content.URL || content.clv_tipoadjunto != 6) {
            return;
        }
        
        setIsOpenContentsModal(false);
        setVideoToShow(content);
        setShowVideoContent(true);
    }

    const onCloseVideoTapped = () => {
        setVideoToShow(null);
        setShowVideoContent(false);
        setIsOpenContentsModal(true);
    }
    
    const shouldShowContents = () => {
        if (isOpenContentsModal && props && props.item && props.item.Contenidos && props.item.Contenidos.length) {
            return true;
        }
        return false;
    }

    if (props.item) {
        return (
           <React.Fragment>
                <div className={`ContentPost-container cursor-default ${props.isDetails ? 'is-details':''}`}>
                    <div className="row m-0">
                        <div className="col-12 ContentPost-header">

                            <div className="row m-0">
                                <div className="col p-0 ContentPost-user-avatar-box">
                                    <img loading="lazy" src={showContentImg()} 
                                        alt="User photo" 
                                        className="avatar"
                                        onError={(e)=>{
                                            e.target.onerror = null;
                                            e.target.src=icnUserPlaceholder
                                        }}
                                    />
                                </div>
                                <div className="col ContentPost-user-details">
                                    <div className="row m-0">
                                        <div className="col p-0">
                                            <div className="row m-0">
                                                <div className="col-12 p-0 ContentPost-user-title text-block-truncate-1" title={props.item.Titulo}>
                                                    {props.item.Titulo}
                                                </div>

                                                {
                                                    props.item.NombreCompleto ? (
                                                        <div className="col-12 p-0 ContentPost-user-name">
                                                            {props.item.NombreCompleto}
                                                        </div>
                                                    ):(null)
                                                }

                                                {
                                                    props.item.Fecha || props.item.TipoContenido || props.item.CategoriaPublicacion ? (
                                                        <div className="col-12 p-0 ContentPost-user-info">
                                                            {toolsHelper.timeSince(props.item.Fecha)}
                                                            {props.item.Fecha && (props.item.TipoContenido || props.item.CategoriaPublicacion) ? ' | ':' '}
                                                            {props.item.TipoContenido ? props.item.TipoContenido:''}
                                                            {props.item.TipoContenido && props.item.CategoriaPublicacion ? ' | ':' '}
                                                            {props.item.CategoriaPublicacion ? props.item.CategoriaPublicacion:''}
                                                        </div>
                                                    ):(null)
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 ContentPost-content">
                            <div className="row m-0" >
                                {
                                    props.item.Contenido ? (
                                        <React.Fragment>
                                            <div className={`col-12 px-0 ContentPost-content-text ${showMore ? '':'line-clamp-w line-clamp-3-w'}`}
                                                id={props.item.clv_publicacion}
                                                style={ {fontSize: props.fontSize, lineHeight: `${props.lineHeight}px`}}
                                                onClick={e => goContentPostDetails(false)}>
                                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                                    <a target="blank" href={decoratedHref} key={key} style={{color: '#0fb855', textDecoration: 'underline'}}>
                                                        {decoratedText}
                                                    </a>
                                                )}>
                                                    {renderMessageText(props.item)}
                                                </Linkify>
                                            </div>
                                            {
                                                isEllipsed ? (
                                                    <div className='col-12 px-0 show-more-btn'>
                                                        <button className='btn-cleared' onClick={() => {setShowMore(!showMore)}}>{showMore ? 'Ver menos':'Ver más'}</button>
                                                    </div>
                                                ):(null)
                                            }
                                        </React.Fragment>
                                    ):(null)

                                }
                            </div>

                            { renderContents() }
                        </div>
                        

                        {
                            props.item.Reacciones || (props.item.Comments !== null && props.item.Comments.length >= 0) ? (
                                <div className="col-12 p-0">
                                    <div className="row m-0">
                                        <div className="col-6 ContentPost-comments-count isLike mouse-pointer" onClick={showLikesModal}>
                                            {
                                                props.item.Reacciones !== null && props.item.Reacciones > 0 ? (
                                                    <>
                                                        <img src={icnsReactions} alt="icnsReactions"/>
                                                        {`${props.item.Reacciones}`}
                                                    </>
                                                ):(null)
                                            }
                                        </div>

                                        <div className="col-6 ContentPost-comments-count mouse-pointer" onClick={e => goContentPostDetails(false)}>
                                            {
                                                props.item.Comments !== null && props.item.Comments.length >= 0 ? (
                                                    <>
                                                        {`${props.item.Comments.length} ${props.item.Comments.length === 1 ? 'Comentario':'Comentarios'}`}
                                                    </>
                                                ):(null)
                                            }
                                        </div>

                                    </div>
                                </div>
                            ):(null)
                        }

                        <div className="col-12">

                            <div className="row m-0">
                                <div className="col-12 px-0 ContentPost-footer">
                                    <button className={`btn-cleared likeBtn ${didIReacted() ? 'active':''}`} onClick={onLikeBtnTapped}>
                                        <img src={icnsReactions} alt="icnsReactions"/>
                                        <span>Me gusta</span>
                                    </button>
                                    <button className='btn-cleared' onClick={onCommentBtnTapped}>
                                        <img src={icnComment} alt="comment icon"/>
                                        <span>Comentar</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                

                {
                    isOpenLikesModal &&
                    getReactions() && 
                    <LikesModal reactions={getReactions()} 
                        onBottomSheetDimiss={el => setIsOpenLikesModal(false)}
                    />
                }

                {
                    shouldShowContents() && 
                    <ContentsModal reactions={getReactions()} 
                        contents={getContents()}
                        onBottomSheetDimiss={el => setIsOpenContentsModal(false)}
                        title={props.item && props.item.CategoriaPublicacion ? props.item.CategoriaPublicacion:'Contenidos'}
                        onVideoShow={onVideoShow}
                    />
                }

                {
                    videoToShow && showVideoContent ? <ContentVideo onCloseTapped={onCloseVideoTapped} content={videoToShow}/>:null
                }

            </React.Fragment>
        );
    }
    return null;
}

export { ContentPost };
