import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import back from '../../../../_assets/media/images/icons/icn_back_arrow.png'
import { authenticationService, infoService, profileService, swalAlertsService } from '../../../../_resources/services';
import { Filters } from '../../../shared';
import CryptoJS from 'crypto-js';

import './Preferences.scss'

const Preferences = (props) => {

    let value


    const history = useHistory()
    const [preferences, setPreferences] = useState([])
    const [activeFiltersSelect, setActiveFiltersSelect] = useState([])
    const [activeFilters, setActiveFilters] = useState({
        filtersSelected: null,
        postedBy: null,
        isUsingFilters: false
    });
    const [showFilters, setShowFilters] = useState(false);
    const phrase = process.env.REACT_APP_PHRASE;

    useEffect(() => {
        if (props.onToggleTab) {
            props.onToggleTab(true);
        }
       value = infoService.catalogsValue
       let valor
       let data = []
       if(value.Cargos) {
            if(authenticationService.currentUserValue.Usuario.Cargos) {
                authenticationService.currentUserValue.Usuario.Cargos.map( (cargoUsuario) => {
                    const id = cargoUsuario.clv_cargo
                    valor = value.Cargos.filter(cargo => cargo.clv_cargo === id)
                    if(valor) {
                        data.push(valor[0])
                    }
                })
                setPreferences(data);
                selectFilters(JSON.parse(JSON.stringify(data)));
            }
       }
    },[value])

    const onFilterDismiss = () => {
        setShowFilters(false);
    }

    const selectFilters = (data) => {
        var cargos = [];
        data.map( (cargo) =>
            cargos.push(JSON.parse(`{"clv_cargo": ${cargo.clv_cargo}, "Activo": true}`))
        )
        setActiveFiltersSelect(cargos)
    }

    const setSavePreferences = () => {
        let currentUser = authenticationService.currentUserValue;
        if (currentUser && currentUser.Usuario) {
            let data = currentUser.Usuario.Cargos.map((role) => {
                return {clv_cargo: role.clv_cargo, Activo: false};
            });
            let activeFiltersSelectCP = JSON.parse(JSON.stringify(activeFiltersSelect));
            data.forEach((val) => {
                let filtered = activeFiltersSelectCP.filter(active => active.clv_cargo === val.clv_cargo);

                if (filtered.length) {
                    val.Activo = true;
                    let index = activeFiltersSelectCP.indexOf(filtered[0]);
                    if (index >= 0) {
                        activeFiltersSelectCP[index].added = true;
                    }
                }
            });

            let newFiltered = activeFiltersSelectCP.filter(ele => !ele.added);
            if (newFiltered.length) {
                data = data.concat(newFiltered);
            }

            let loader = swalAlertsService.createLoading("Espere un momento...")
            loader.fire()
            profileService.updatePreferences(data).then(
                (user) => {
                    loader.close();
                    if (user && user.data && user.message) {
                        authenticationService.getUser();
                        swalAlertsService.showToast('success', user.message);
                    } else if (typeof user === 'string') {
                        swalAlertsService.showToast('error', user);
                    }

                }
            )
        }
    }

    return (
        <React.Fragment>
            <div className={`container_preferences ${props.isDesktop ? 'isDesktop':''}`}>
                <div className="container_header">
                    {
                        !props.isDesktop ? (
                            <img src={back} width="30px" height="30px" onClick={() => history.goBack()}/>
                        ):(null)
                    }
                    <h3 >Filtro permanente de publicaciones</h3>
                </div>

                <p>Personaliza el contenido de tus publicaciones  y activa solo las opciones de tu interés.
                    <br/><br/>
                    <strong className='text-muted-config'>¡Precaución! al seleccionar alguno de estos filtros, eliminara permanentemente la posibilidad de visualizar las publicaciones del perfil seleccionado.</strong>
                </p>

                <h5>Ver publicaciones de</h5>
                <div className="filters">
                    <Filters className="puesto"
                        onDismiss={onFilterDismiss}
                        onSubmit={setSavePreferences}
                        activeFilters={activeFilters}
                        hidePostedBy={true}
                        showHeader={true}
                        selectFilters={selectFilters}
                        hideModal={true}
                        preferences={preferences}
                        isDesktop={props.isDesktop}
                    />
                </div>

                {
                    // <button className="button-preferences" onClick={() => setSavePreferences()}>Guardar</button>
                }
            </div>
        </React.Fragment>
    );
}

export {Preferences};
