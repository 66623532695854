import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faQuoteLeft, faQuoteRight, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import './css/magnific-popup.css';

import './LandingIndex.scss';

import logoWenet from '../../../_assets/media/images/new-landing/logo-WeNet.svg';

import bgMain from '../../../_assets/media/images/new-landing/bgProducts.png';
import bgProduct from '../../../_assets/media/images/new-landing/revolution-slider/product/bg_product.jpg';
import macbookPro from '../../../_assets/media/images/new-landing/revolution-slider/product/macbookpro.png';
import macbookContent from '../../../_assets/media/images/new-landing/revolution-slider/product/macbook_content.jpg';
import ipadDark from '../../../_assets/media/images/new-landing/revolution-slider/product/ipad_dark.png';
import ipadContent from '../../../_assets/media/images/new-landing/revolution-slider/product/ipad_content.jpg';
import ihponeDark from '../../../_assets/media/images/new-landing/revolution-slider/product/ihpone_dark.png';
import iphoneContent from '../../../_assets/media/images/new-landing/revolution-slider/product/iphone_content.jpg';
import ceoBiomedica from '../../../_assets/media/images/new-landing/ceo-biomedica.jpg';
import ceoGarabatos from '../../../_assets/media/images/new-landing/ceo-garabatos.jpg';
import ceoArion from '../../../_assets/media/images/new-landing/ceo-arion.jpg';
import presidenteColectivoModa from '../../../_assets/media/images/new-landing/presidente-colectivo-moda.jpg';
import ceoRappi from '../../../_assets/media/images/new-landing/ceo-rappi.jpg';
import bgWenet from '../../../_assets/media/images/new-landing/bg-WeNet.jpg';


import svgArrowUp from '../../../_assets/media/images/svg/svg_arrow_up_solid.svg';

const LandingIndex = (props) => {
    let mainRef = useRef(null);
    const [toggleBtn, setToggleBtn] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (props.onToggleTab) {
            props.onToggleTab(true);
        }

        removePreloader();
        goTop();

        window.addEventListener("scroll", (e) => handleNavigation(e));

        return () => { // return a cleanup function to unregister our function since its gonna run multiple times
            window.removeEventListener("scroll", (e) => handleNavigation(e));
        };
    }, []);

    const handleNavigation = (e) => {
        toggleHeaderFixed(window.scrollY > 0 ? true:false);
    }

    const toggleHeaderFixed = (value) => {
        let element = document.getElementById('header');

        if (element && element.className) {
            if (value && element.className.indexOf('header-fixed') < 0) {
                element.className = element.className.trim();
                element.className += ' header-fixed';
            }

            if (!value) {
                element.className = element.className.replace(/header-fixed/g, '');
                element.className = element.className.trim();
            }
        }
    }

    const goToLogin = () => {
        history.push("/login");
    }

    const goToRegister = () => {
        history.push("/register");
    }

    const goTop = () => {
        window.scrollTo(0, 0);
    }

    const removePreloader = () => {
        setTimeout(() => {
            let preloader = document.getElementById('preloader');
            if (preloader && preloader.className) {
                if (preloader.className.indexOf('loading') >= 0) {
                    preloader.className = preloader.className.replace(/loading/g, 'ended');
                }

                if (preloader.className.indexOf('ended') < 0) {
                    preloader.className = preloader.className.trim();
                    preloader.className += ' ended';
                }

            }
        }, 500);
    }

    const toggleHeader = () => {
        setToggleBtn(!toggleBtn);
    }

    return (

        <div className="LandingIndex-container dark" ref={mainRef} id='LandingIndex-container'>

            <div className="preloader loading" id="preloader">
                <div className="logopreloader">
                    <img src={logoWenet} alt="logo-black"/>
                </div>
                <div className="loader" id="loader"></div>
            </div>

            <div className="wrapper" >
                <header className="header" id='header'>
                    <div className="header-inner">
                        <nav className="navbar">


                            <div className="logo">
                                <Link to="/welcome" className="navbar-brand" data-target=".navbar-collapse.show" className="navbar-brand">
                                    <img id="logo-light" className="logo-light" src={logoWenet} alt="logo-light" />
                                    <img id="logo-dark" className="logo-dark" src={logoWenet} alt="logo-dark" />
                                </Link>
                            </div>

                            <button className="navbar-toggle navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleHeader}>
        						<span id="icon-toggler" className={toggleBtn ? 'open':''}>
        						  <span></span>
        						  <span></span>
        						  <span></span>
        						  <span></span>
        						</span>
        					</button>

                            <div id="navbarSupportedContent" className={`collapse navbar-collapse navbar-responsive-collapse show ${toggleBtn ? 'in':''}`} style={{height: toggleBtn ? 'auto':'0px'}}>

                                <ul className="nav navbar-nav" id="main-navigation">
                                    <li className="active"><Link to='/welcome'>Inicio</Link></li>
        							<li><Link to='/welcome/oferta'>Oferta</Link></li>
        							<li><Link to='/welcome/tutorial'>Tutorial</Link></li>
        							<li><Link to='/login'>Iniciar sesión</Link></li>
        							<li><Link to='/register' className='lastLink'>Registro</Link></li>
        						</ul>

        					</div>

                        </nav>
                    </div>
                </header>

                {
                    // <section className="mainsliderss" id="mainsliders">
                    //     <div id="rev_slider_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-alias="product" style={{margin: '0px auto', backgroundColor: 'transparent', padding: '0px', marginTop: '0px', marginBottom: '0px'}}>
                    //         <div id="rev_slider_product" className="rev_slider fullwidthabanner" data-version="5.0.7">
                    //             <ul>
                    //                 <li data-index="rs-235" data-transition="fade" data-slotamount="default" data-easein="default" data-easeout="default" data-masterspeed="1500" data-rotate="0" data-saveperformance="off" data-title="Intro" data-description="">
                    //                     <img src={bgProduct} alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="5" className="rev-slidebg" data-no-retina/>
                    //
                    //
                    //                     <div className="tp-caption tp-resizeme rs-parallaxlevel-1" id="slide-235-layer-1" data-x="['right','right','center','center']" data-hoffset="['-254','-453','70','60']" data-y="['middle','middle','middle','bottom']" data-voffset="['50','50','211','25']" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;" data-transform_in="x:right;s:1500;e:Power3.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="2500" data-responsive_offset="on" style={{zIndex: 5}}><img src={macbookPro} alt="" width="1000" height="600" data-ww="['1000px','1000px','500px','350px']" data-hh="['600px','600px','300px','210px']" data-no-retina/>
                    //                     </div>
                    //
                    //                     <div className="tp-caption   tp-resizeme rs-parallaxlevel-1" id="slide-235-layer-2" data-x="['left','left','center','center']" data-hoffset="['828','865','70','60']" data-y="['top','top','top','bottom']" data-voffset="['217','216','580','63']" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;" data-transform_in="z:0;rX:0deg;rY:0;rZ:0;sX:1.5;sY:1.5;skX:0;skY:0;opacity:0;s:1500;e:Power3.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-mask_in="x:0px;y:0px;s:inherit;e:inherit;" data-start="3350" data-responsive_offset="on" style={{zIndex: 6}}><img src={macbookContent} alt="" width="653" height="408" data-ww="['653px','653px','330px','230px']" data-hh="['408px','408px','206px','144px']" data-no-retina/>
                    //                     </div>
                    //
                    //                     <div className="tp-caption   tp-resizeme rs-parallaxlevel-2" id="slide-235-layer-3" data-x="['left','left','center','center']" data-hoffset="['593','633','-110','-60']" data-y="['top','top','top','bottom']" data-voffset="['203','203','590','20']" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;" data-transform_in="x:right;s:1500;e:Power3.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="2750" data-responsive_offset="on" style={{zIndex: 7}}><img src={ipadDark} alt="" width="430" height="540" data-ww="['430px','430px','200px','170px']" data-hh="['540px','540px','251px','213px']" data-no-retina/>
                    //                     </div>
                    //
                    //
                    //                     <div className="tp-caption   tp-resizeme rs-parallaxlevel-2" id="slide-235-layer-4" data-x="['left','left','left','center']" data-hoffset="['663','703','212','-60']" data-y="['top','top','top','bottom']" data-voffset="['291','291','632','50']" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;" data-transform_in="z:0;rX:0deg;rY:0;rZ:0;sX:1.5;sY:1.5;skX:0;skY:0;opacity:0;s:1500;e:Power3.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-mask_in="x:0px;y:0px;s:inherit;e:inherit;" data-start="3700" data-responsive_offset="on" style={{zIndex: 8}}><img src={ipadContent} alt="" width="290" height="374" data-ww="['290px','290px','135px','115px']" data-hh="['374px','374px','174px','148px']" data-no-retina/>
                    //                     </div>
                    //
                    //
                    //                     <div className="tp-caption   tp-resizeme rs-parallaxlevel-3" id="slide-235-layer-5" data-x="['left','left','left','left']" data-hoffset="['530','553','127','58']" data-y="['top','top','top','top']" data-voffset="['297','297','622','529']" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;" data-transform_in="x:right;s:1500;e:Power3.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="3000" data-responsive_offset="on" style={{zIndex: 9}}><img src={ihponeDark} alt="" width="260" height="450" data-ww="['260px','260px','130px','100px']" data-hh="['450px','450px','225px','173px']" data-no-retina/>
                    //                     </div>
                    //
                    //
                    //                     <div className="tp-caption   tp-resizeme rs-parallaxlevel-3" id="slide-235-layer-6" data-x="['left','left','left','left']" data-hoffset="['575','598','150','75']" data-y="['top','top','top','top']" data-voffset="['380','379','663','560']" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;" data-transform_in="z:0;rX:0deg;rY:0;rZ:0;sX:1.5;sY:1.5;skX:0;skY:0;opacity:0;s:1500;e:Power3.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-mask_in="x:0px;y:0px;s:inherit;e:inherit;" data-start="3950" data-responsive_offset="on" style={{zIndex: 10}}><img src={iphoneContent} alt="" width="170" height="286" data-ww="['170px','170px','85px','66px']" data-hh="['286px','286px','143px','111px']" data-no-retina/>
                    //                     </div>
                    //
                    //
                    //                     <div className="tp-caption NotGeneric-Title   tp-resizeme rs-parallaxlevel-0" id="slide-235-layer-7" data-x="['left','left','left','left']" data-hoffset="['30','30','200','80']" data-y="['middle','middle','top','top']" data-voffset="['-110','-110','137','130']" data-fontsize="['90','90','75','75']" data-lineheight="['90','90','75','70']" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:1000;e:Power2.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="1000" data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{zIndex: 11, whiteSpace: 'nowrap'}}><img id="logo-light" className="logo-light" src={logoWenet} alt="logo-light" />
                    //                     </div>
                    //
                    //
                    //                     <div className="tp-caption NotGeneric-SubTitle   tp-resizeme rs-parallaxlevel-0" id="slide-235-layer-10" data-x="['left','left','left','left']" data-hoffset="['30','30','200','80']" data-y="['middle','middle','top','top']" data-voffset="['14','44','294','277']" data-fontsize="['19','19','19','13']" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:1000;e:Power2.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="1250" data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{zIndex: 12, whiteSpace: 'nowrap', letterSpacing: '2px', fontWeight: 500}}>INTÉGRATE A UNA COMUNIDAD PARA GENERAR <br/>NUEVAS OPORTUNIDADES DE NEGOCIO.
                    //                     </div>
                    //
                    //
                    //                     <div className="tp-caption WebProduct-Content-Light   tp-resizeme rs-parallaxlevel-0 hidden-xs" id="slide-235-layer-9" data-x="['left','left','left','left']" data-hoffset="['30','30','200','80']" data-y="['middle','middle','top','top']" data-voffset="['99','127','345','316']" data-fontsize="['18','18','18','14']" data-lineheight="['24','24','24','22']" data-width="['448','356','334','277']" data-height="['none','none','76','68']" data-whitespace="normal" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:1000;e:Power2.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="1500" data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{zIndex: 13, minWidth: '448px', maxWidth: '448px', whiteSpace: 'normal'}}>Somos una exclusiva comunidad de líderes interesados en intercambiar conocimientos, experiencias y relaciones a través de networking y eventos digitales.
                    //                     </div>
                    //
                    //
                    //                     <div className="tp-caption" data-x="['left','left','left','left']" data-hoffset="['30','30','200','80']" data-y="['middle','middle','top','top']" data-voffset="['238','238','456','350']" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:1000;e:Power2.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="1750" data-splitin="none" data-splitout="none" data-responsive_offset="on" data-responsive="off" style={{zIndex: 14, whiteSpace: 'nowrap', letterSpacing: '1px'}}><Link to='/register' className="custom-button slider-button scroll-to-target">Registrate</Link>
                    //                     </div>
                    //                 </li>
                    //             </ul>
                    //             <div className="tp-static-layers">
                    //             </div>
                    //             <div className="tp-bannertimer tp-bottom" style={{visibility: 'hidden !important'}}></div>
                    //         </div>
                    //     </div>
                    //
                    // </section>
                }

                    <section className='homeBox d-flex justify-content-center' style={{backgroundImage: `url('${bgProduct}')`}}>
                        <div className="row m-0 container">
                            <div className="col-md">
                                <div className="row m-0">
                                    <div className="col-12 logoBox">
                                        <img src={logoWenet} alt="logoWenet"/>
                                    </div>
                                    <div className="col-12 textBox">
                                        <div>INTÉGRATE A UNA COMUNIDAD PARA GENERAR  <br/>
                                        NUEVAS OPORTUNIDADES DE NEGOCIO.</div>
                                    </div>

                                    <div className="col-12 subText">
                                        <div>
                                            Somos una exclusiva comunidad de líderes interesados en intercambiar conocimientos, experiencias y relaciones a través de networking y eventos digitales.
                                        </div>
                                    </div>
                                    <div className="col-12 btnBox">
                                        <Link to="/register" className="custom-button slider-button scroll-to-target"><span data-hover="Registrate">Registrate</span></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md imgsBox">
                                <img src={bgMain} alt="bgMain"/>
                            </div>
                        </div>
                    </section>

                <section className="services">

                    <div className="container">
                        <div className="text-center top-text">
                            <h1><span>Qué</span> Hacemos</h1>

                        </div>

                        <div className="divider text-center">
                            <span className="outer-line"></span>
                            <span className='px-3'><FontAwesomeIcon icon={faCogs} /></span>
                            <span className="outer-line"></span>
                        </div>

                        <div className="row services-box">
                            <div className="col-lg-4 col-md-6 col-sm-12 services-box-item">
                                <span className="services-box-item-cover fa fa-pencil" icon={faCogs} data-headline="NUESTRO PROPÓSITO"></span>

                                <div className="services-box-item-content fa fa-pencil" icon={faCogs}>
                                	<h2>NUESTRO PROPÓSITO</h2>
                                    <p>Formar relaciones fraternales  a través de medios digitales, donde si uno gana, todos ganamos.</p>
                                </div>

                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 services-box-item">
                                <span className="services-box-item-cover fa fa-eye" data-headline="NUESTRA PROPUESTA"></span>

                                <div className="services-box-item-content fa fa-eye">
                                    <h2>NUESTRA PROPUESTA</h2>
                                    <p>Integrarte a una “comunidad colaborativa” y ser parte de algo más grande y significativo para la sociedad.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 services-box-item">
                                <span className="services-box-item-cover fa fa-lightbulb-o" data-headline="NUESTRO CREENCIA"></span>
                                <div className="services-box-item-content fa fa-lightbulb-o">
                                    <h2>NUESTRO CREENCIA</h2>
                                    <p>Con tu colaboración, seguramente podemos mejorar el destino de nuestra empresa y apoyarte a transformar el de la tuya también.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 services-box-item">
                                <span className="services-box-item-cover fa fa-group" data-headline="PERFIL DE COMUNIDAD"></span>

                                <div className="services-box-item-content fa fa-group">
                                    <h2>PERFIL DE COMUNIDAD</h2>
                                    <p>Exclusivamente líderes de negocios: Dueño, Socio, Miembro de Consejo, Director General, Director Corporativo, Profesionista Independiente o Emprendedor.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 services-box-item">
                                <span className="services-box-item-cover fa fa-ticket" data-headline="MEMBRESÍA"></span>
                                <div className="services-box-item-content fa fa-ticket">
                                    <h2>MEMBRESÍA</h2>
                                    <p>Por solo $25 usd al mes, relacionate con otros líderes, comparte información, accede a capacitación directiva y aporta al mundo emprendedor.</p>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>

                <section className="testimonials">
                    <div className="section-overlay">

                        <div className="container">

                            <div className="text-center top-text">
                                <h1><span>Testimonios</span> </h1>
                            </div>

                            <div id="quote-carousel" className="carousel slide" data-ride="carousel">

                                {
                                    // <ol className="carousel-indicators">
                                    //     <li data-target="#quote-carousel" data-slide-to="0" className="active"></li>
                                    //     <li data-target="#quote-carousel" data-slide-to="1"></li>
                                    //     <li data-target="#quote-carousel" data-slide-to="2"></li>
                                    //     <li data-target="#quote-carousel" data-slide-to="3"></li>
                                    // </ol>
                                }

                                <div className="carousel-inner row d-flex justify-content-center">

                                    <div className="item active col-12 col-md-4 mb-4">
                                        <blockquote>
                                            <img width="150px" className="img-circle img-responsive img-ceos" src={ceoBiomedica} alt="client"/>
                                            <p><FontAwesomeIcon icon={faQuoteLeft} className='mx-2'/>Esta es una extraordinaria comunidad de capital intelectual que permite tener apoyo para resolver mis retos estratégicos<FontAwesomeIcon icon={faQuoteRight} className='mx-2'/></p>
                                            <h5 className='mb-4'>CEO Biomédica, CDMX</h5>
                                        </blockquote>
                                    </div>

                                    <div className="item col-12 col-md-4 mb-4">
                                        <blockquote>
                                            <img width="150px" className="img-circle img-responsive img-ceos" src={ceoGarabatos} alt="client"/>
                                            <p>El ser parte de una fraternal comunidad de empresarios que buscan ayudarse mutuamente en todo momento es muy valioso<FontAwesomeIcon icon={faQuoteRight} className='mx-2'/></p>
                                            <h5 className='mb-4'>CEO Garabatos, CDMX</h5>
                                        </blockquote>
                                    </div>

                                    <div className="item col-12 col-md-4 mb-4">
                                        <blockquote>
                                            <img width="150px" className="img-circle img-responsive img-ceos" src={ceoArion} alt="client"/>
                                            <p>He encontrado a un grupo de empresarios que viven retos similares a los míos y entre todos vemos cómo solucionarlos<FontAwesomeIcon icon={faQuoteRight} className='mx-2'/></p>
                                            <h5 className='mb-4'>CEO Grupo Arión, CDMX</h5>
                                        </blockquote>
                                    </div>
                                    <div className="item col-12 col-md-4 mb-4">
                                        <blockquote>
                                            <img width="150px" className="img-circle img-responsive img-ceos" src={ceoRappi} alt="client"/>
                                            <p><FontAwesomeIcon icon={faQuoteLeft} className='mx-2'/>El mundo ya cambió y todo gira alrededor de relaciones digitales de alto impacto<FontAwesomeIcon icon={faQuoteRight} className='mx-2'/></p>
                                            <h5 className='mb-4'>CEO Rappi, Mex.</h5>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




                <footer className="footer top-logos">
                    <div className="container top-footer">
                        <div className="row">

                            <div className="col-6 col-lg-2">
                                <h4>Wenet</h4>
                                <div className="menu">
                                    <ul>
                                        <li><a href='/welcome'>Inicio</a></li>
                                        <li><Link to='/welcome/oferta'>Oferta</Link></li>
                                        <li><Link to='/welcome/tutorial'>Tutorial</Link></li>

                                    </ul>
                                </div>
                            </div>

                            <div className="col-6 col-lg-2">
                                <h4>Soporte</h4>
                                <div className="menu">
                                    <ul>
        								<li><a href="https://api.wenet.mx/html/terms.html" target='_blank'>Términos y condiciones </a></li>
                                        <li><a href="https://api.wenet.mx/html/privacy.html">Aviso de privacidad</a></li>
                                        <li><Link to="/register">Registro</Link></li>
                                        <li><Link to="/login">Iniciar sesión</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-6 col-lg-2">
                                <h4>Contactanos</h4>
                                <div className="menu">
                                    <ul>
                                        <li><span><i className="fa fa-envelope-open"></i> <a href="mailto:contacto@wenet.mx">contacto@wenet.mx</a> </span></li>
                                        <li><span><i className="fa fa-phone"></i> <a href="https://api.whatsapp.com/send?phone=525535008661&text=Escribo%20para%20solicitar%20información%20de%20la%20app%20WeNet" target='_blank'>Whats app</a></span></li>
                                        <li><span><i className="fa fa-map-marker"></i> Woodlands, USA, 77389</span></li>

                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>

                </footer>
                <div id="back-top-wrapper">
                    <p id="back-top">
                        <a href="#top"><FontAwesomeIcon icon={faArrowUp}/></a>
                    </p>
                </div>
            </div>

        </div>

    );
}

export { LandingIndex };
