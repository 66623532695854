import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import './RecomendationsList.scss';

import { Dropdown } from 'react-bootstrap';
import ShowMore from 'react-show-more';

// import { toolsHelper } from '../../../../../_resources/helpers';

import placeholderuser from '../../../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnLike from '../../../../../_assets/media/images/icons/icn_like.png';
import icnMore from '../../../../../_assets/media/images/icons/icn_more_options.png';

import { swalAlertsService, profileService } from '../../../../../_resources/services';


import { Talent } from '../../../../shared';

const RecomendationsList = (props) => {
    const history = useHistory();
    const [isTalentOpen, setIsTalentOpen] = useState(false);
    const [recomendationEdit, setRecomendationEdit] = useState(null);

    const getUserData = () => {
        if (props.currentUser) {

            if (props.user && props.userID != props.currentUser.Usuario.clv_usuario) {
                return props.user;
            } else {
                return props.currentUser.Usuario;
            }
        }

        return null;
    }

    useEffect(() => {
        if (props.shouldOpenRecoView) {
            let text = '';
            const user = getUserData();
            if (user && user.Recomendaciones && user.Recomendaciones.length && props.currentUser && props.currentUser.Usuario.clv_usuario) {
                let filtered = user.Recomendaciones.filter(ele => ele.clv_usuariorecomienda === props.currentUser.Usuario.clv_usuario);

                if (filtered.length) {
                    text = filtered[0].Recomendacion || '';
                }
            }
            setRecomendationEdit({text});
            setTimeout(() => {
                setIsTalentOpen(true);
            }, 200);
        }
    }, [props.shouldOpenRecoView]);

    const onReportRecomendation = (recomendation) => {
        if (recomendation && recomendation.clv_recomendacionxusuario) {
            let loader = swalAlertsService.createLoading("Espere un momento...")
            loader.fire();
            const objSend = {
                clv_recomendacionxusuario: recomendation.clv_recomendacionxusuario,
                clv_tiporeporte: 2,
                Comentarios: "Texto default"
            }
            profileService.postGeneric('user/recommendation/report', 'POST', objSend).then(
                (response) => {
                    loader.close();
                    if (response) {
                        if (response.data) {
                            dataUpdated();
                            swalAlertsService.showToast('success', 'Recomendación reportada correctamente');
                        } else {
                            swalAlertsService.showToast('error', 'No pudimos reportar la recomendación');
                        }
                    }
                },
                (error) => {
                    console.log(error);
                    loader.close();
                    swalAlertsService.showToast('error', 'No pudimos reportar la recomendación');
                }
            )
        }
    }

    const onEditRecomendation = (recomendation) => {
        if (recomendation) {
            setRecomendationEdit({id: recomendation.clv_usuariorecomienda, text: recomendation.Recomendacion});

            setTimeout(() => {
                setIsTalentOpen(true);
            }, 200);
        }

    }

    const onDeleteRecomendation = (recomendation, isOwner) => {
        if (recomendation && recomendation.clv_recomendacionxusuario) {
            swalAlertsService.answer("", "¿Estas seguro de querer elimiar esta recomendación?", "Si").then((result) => {
                if (result.isConfirmed) {
                    let loader = swalAlertsService.createLoading("Espere un momento...")
                    loader.fire();
                    profileService.postGeneric('user/recommendation', 'DELETE', {clv_recomendacionxusuario: recomendation.clv_recomendacionxusuario}).then(
                        (response) => {
                            loader.close();
                            if (response) {
                                if (response.data) {
                                    dataUpdated();
                                    swalAlertsService.showToast('success', 'Recomendación borrada correctamente');
                                } else {
                                    swalAlertsService.showToast('error', 'No pudimos borrar tu recomendación');
                                }
                            }
                        },
                        (error) => {
                            console.log(error);
                            loader.close();
                            swalAlertsService.showToast('error', 'No pudimos borrar tu recomendación');
                        }
                    )
                }
            })
        }
    }

    const onOptionTapped = (type, recomendation) => {

        switch (type) {
            case 'deleteOwner':
                onDeleteRecomendation(recomendation, true);
                break;
            case 'report':
                onReportRecomendation(recomendation);
                break;
            case 'edit':
                onEditRecomendation(recomendation);
                break;
            case 'delete':
                onDeleteRecomendation(recomendation, false);
                break;
            default:
                break;
        }
    }

    const showDownDownOptions = (isUser, recomendation) => {
        let options = [];

        if (isUser) {
            // options.push(<Dropdown.Item key='0' className='text-danger' onClick={e => onOptionTapped('report',recomendation)}>Reportar recomendación</Dropdown.Item>);
            options.push(<Dropdown.Item key='0' className='text-danger' onClick={e => onOptionTapped('deleteOwner',recomendation)}>Eliminar recomendación</Dropdown.Item>);
        } else {
            options.push(<Dropdown.Item key='1' className='text-primary' onClick={e => onOptionTapped('edit',recomendation)}>Editar recomendación</Dropdown.Item>)
            options.push(<Dropdown.Item key='2' className='text-danger' onClick={e => onOptionTapped('delete', recomendation)}>Eliminar recomendación</Dropdown.Item>)
        }
        return (
            <>{options}</>
        );
    }

    const dataUpdated = () => {
        if (props.dataUpdated) {
            props.dataUpdated();
            onCloseTalentEditor();
        }

    }

    const onOpenTalentEditor = (recomendation) => {
        if (recomendation) {
            setRecomendationEdit(recomendation);
            setTimeout(() => {
                setIsTalentOpen(true);
            },200);
        }

    }

    const onCloseTalentEditor = () => {
        setRecomendationEdit(null);
        setIsTalentOpen(false);
    }

    const renderOptions = (user, recomendation) => {

        if (user) {
            if (props.userID) {
                const currentID = props.currentUser && props.currentUser.Usuario && props.currentUser.Usuario.clv_usuario ? props.currentUser.Usuario.clv_usuario:null;
                if (currentID === parseInt(props.userID)) {
                    return (
                        <div className={`col p-0 recomendation-options ${props.isDetails ? 'isDetails':''}`}>
                            <Dropdown>
                                <Dropdown.Toggle>
                                    <img src={icnMore} alt="options icon" width='30' height='30'/>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {showDownDownOptions(true, recomendation)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )
                } else if (currentID === recomendation.clv_usuariorecomienda) {
                    return (
                        <div className={`col p-0 recomendation-options ${props.isDetails ? 'isDetails':''}`}>
                            <Dropdown>
                                <Dropdown.Toggle>
                                    <img src={icnMore} alt="options icon" width='30' height='30'/>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {showDownDownOptions(false, recomendation)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )
                } else if (parseInt(props.userID) === recomendation.clv_usuariorecomienda) {
                    return (
                        <div className={`col p-0 recomendation-options ${props.isDetails ? 'isDetails':''}`}>
                            <Dropdown>
                                <Dropdown.Toggle>
                                    <img src={icnMore} alt="options icon" width='30' height='30'/>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {showDownDownOptions(false, recomendation)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )
                }
            } else {
                return (
                    <div className={`col p-0 recomendation-options ${props.isDetails ? 'isDetails':''}`}>
                        <Dropdown>
                            <Dropdown.Toggle>
                                <img src={icnMore} alt="options icon" width='30' height='30'/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {showDownDownOptions(true, recomendation)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )
            }
        }

        return null;
    }

    const onRecomendationUserTapped = (recomendation) => {
        if (recomendation && props.currentUser && props.currentUser.Usuario && recomendation.clv_usuariorecomienda) {
            if (recomendation.clv_usuariorecomienda !== props.currentUser.Usuario.clv_usuario) {
                history.push(`/profile-user/${recomendation.clv_usuariorecomienda}`, {from: '/talent'});
            } else {
                history.push(`/profile`, {from: '/talent'});
            }
        }

    }

    const renderView = () => {

        const user = getUserData();

        if (user && user.Recomendaciones && user.Recomendaciones.length) {
            const items = user.Recomendaciones.map((recomendation, index) => {

                return (
                    <div className="col-12 recomendation-item" key={index}>

                        <div className="row m-0 recomendation-item-row">
                            <div className="col-12 p-0">
                                <div className="row m-0 pos-relative">
                                    <div className="col-12 p-0">
                                        <div className="row m-0 mouse-pointer" onClick={() => onRecomendationUserTapped(recomendation)}>
                                            <div className="col avatar-image-box-60 pos-relative">
                                                <img src={recomendation.ThumbnailUrl ? recomendation.ThumbnailUrl : placeholderuser} alt="recomendation photo"
                                                    onError={(e)=>{
                                                        e.target.onerror = null;
                                                        e.target.src=placeholderuser
                                                    }}
                                                />
                                                <img src={icnLike} alt="like icon" width='35' height='35' className='likeIcn'/>
                                            </div>
                                            <div className="col pe-0">
                                                <div className="row m-0 recomendation-details-box">
                                                    <div className="col-12 px-0 user-details-title text-block-truncate-1">{recomendation.NombreCompleto}</div>
                                                    <div className="col-12 px-0 user-details-subtitle soft text-block-truncate-1 small">
                                                        {`${recomendation.Cargo ? recomendation.Cargo:''}${recomendation.Cargo && recomendation.EmpresaNombreCorto ? ' | ':''}${recomendation.EmpresaNombreCorto ? recomendation.EmpresaNombreCorto:''}`}
                                                    </div>
                                                    <div className="col-12 px-0 user-details-subtitle soft text-block-truncate-1 small">
                                                        {`${recomendation.EstadoResidencia ? recomendation.EstadoResidencia:''}${recomendation.EstadoResidencia && recomendation.PaisResidencia ? ', ':''}${recomendation.PaisResidencia ? recomendation.PaisResidencia:''}`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {renderOptions(user, recomendation)}
                                </div>
                                <div className="row m-0">
                                    <div className="col-12 px-0 recomendation-text-box text-block-truncate-4">
                                        {recomendation.Recomendacion}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })

            return (
                <div className="col-12 p-0">

                    <div className="row RecomendationsList-header-container m-0">
                        <div className="col-12">
                            {user.Recomendaciones.length} {user.Recomendaciones.length == 1 ? 'Recomendación':'Recomendaciones'}
                        </div>
                    </div>

                    <div className="row m-0 RecomendationsList-items-container">
                        {items}
                    </div>
                </div>
            )
        }
        return null;
    }

    const getTalentTitle = () => {
        const user = getUserData();
        if (user) {
            return user.Talento ? 'Editar recomendación': 'Agregar talento';
        } else {

        }
    }

    const renderTalentBox = () => {
        const user = getUserData();
        if (isTalentOpen && recomendationEdit && user) {
            return (
                <Talent
                    onBottomSheetDimiss={onCloseTalentEditor}
                    title='Recomendación'
                    dataUpdated={dataUpdated}
                    talent={recomendationEdit ? recomendationEdit.text:''}
                    isRecomendation={true}
                    currentUser={props.currentUser}
                    user={user}
                />
            )
        }

        return null;
    }

    return (
        <div className="RecomendationsList-container row m-0">
            {renderView()}

            { renderTalentBox() }
        </div>
    );
}

export { RecomendationsList }
