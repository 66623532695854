import React, { useState, useEffect } from 'react';

import './NotificationsList.scss';

import { profileService, authenticationService, swalAlertsService } from '../../../_resources/services';


import icnUserPlaceholder from '../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnBMStar from '../../../_assets/media/images/icons/icn_bm_star.png';
import icnsNotification from '../../../_assets/media/images/icons/icns_notification.png';
import icnArrow from '../../../_assets/media/images/icons/icn_back_arrow.png';
import icnNotificationNewUsers from '../../../_assets/media/images/icons/icn_notification_new_users.png';
import icnBanner from '../../../_assets/media/images/icons/icn_tutorial_banner.png';
import icnPlaceHolder from "../../../_assets/media/images/icons/icn_user_placeholder.png";
import icnClose from "../../../_assets/media/images/icons/icn_close.png";


import { toolsHelper } from '../../../_resources/helpers/';
import { Link, useHistory } from 'react-router-dom';
import { getMessagin } from '../../../firebaseInit';

import {
    Header,
    MessagesBubble,
    ModalDefault
} from '../../shared';

const NotificationsList = (props) => {
    const history = useHistory();

    const [notificationsList, setNotificationsList] = useState([]);
    const [modalActive, setModalActive] = useState(false);
    const [userRefeal, setUserRefeal] = useState(null);
    const [selectedTypeNotification, setSelectedTypeNotification] = useState('MiActividad');
    const [updateDot, setUpdateDot] = useState(false);

    let messaging = getMessagin();

    if(messaging){
        messaging.onMessage((payload) => {
            setUpdateDot(true);
        });
    }
    
    const endMessage = ()=>{
        setUpdateDot(false);
    }

    const getNotifications = () => {
        profileService.getGeneric('notification').then((response) => {
            if (response && response.notificationcenter) {
                setNotificationsList(response.notificationcenter);
            }
        });
    }

    const onNotificationTapped = (item) => {

        if (item && !item.Leido) {
            profileService.postGeneric(`notification/${item.clv_notificacionxusuario}/read`, 'put', null).then(response => {
                if (response && response.notificationcenter && response.message && response.message === "Notificaciones obtenidas correctamente") {
                    let notificationListCP = JSON.parse(JSON.stringify(notificationsList));
                    notificationListCP[selectedTypeNotification] = response.notificationcenter[selectedTypeNotification];
                    setNotificationsList(notificationListCP);
                }
            })
        }
        if (item && item.clv_notificacionxusuario && item.Parametro) {
            profileService.postGeneric(`notification/${item.clv_notificacionxusuario}/read`, 'PUT', {}).then((response) => {
                console.log(item);

                if (item.clv_tiponotificacion === 6) {
                    history.push(`/notification/${item.Parametro}`, { from: '/home' })
                } else if (item.clv_tiponotificacion === 7) {
                    history.push(`/notification-birthday/${item.Parametro}`, { from: '/home' })
                } else if (item.clv_tipoaccion === 8) {
                    prepareModal(item);
                } else if (item.clv_tipoaccion === 2 || item.clv_tipoaccion === 9) {
                    history.push(`/post/${item.Parametro}`);
                }else if (item.clv_tipoaccion === 4) {
                    history.push(`/talent/${item.Parametro}`);
                }
            });
        }
    }

    const renderNotificationsList = () => {

        if (notificationsList && notificationsList[selectedTypeNotification] && notificationsList[selectedTypeNotification].notifications && notificationsList[selectedTypeNotification].notifications.length) {
            return notificationsList[selectedTypeNotification].notifications.map((item, index) => {
                return (
                    <div className={`row m-0 NotificationsList-item-container mouse-pointer ${index === 0 ? 'isFirst' : ''} ${item.Leido ? 'readed' : ''} ${notificationsList.length - 1 === index ? 'isLast' : ''}`} key={index} onClick={e => onNotificationTapped(item)}>
                        <div className="col NotificationsList-icon-container noselect">
                            <img src={icnNotificationNewUsers} alt="icnNotificationNewUsers" />
                        </div>
                        <div className="col NotificationsList-details-container pos-relative">
                            <div className="hour-badge noselect">{toolsHelper.timeSince(item.Fecha, true)}</div>
                            <div className="row m-0 w-100 line-height-22px">
                                <div className="col-12 px-0 title text-block-truncate-1 noselect">{item.Titulo}</div>
                                <div className="col-12 px-0 subtitle noselect text-block-truncate-2">{item.Body}</div>
                            </div>
                        </div>
                    </div>
                )
            })
        }

        return (
            <div className="row m-0">
                <div className="NotificationsList-no-results-container col-12">
                    <img src={icnsNotification} alt="icnsNotification" />
                    <span>No tienes notificaciones recientes.</span>
                </div>
            </div>

        );
    }

    const prepareModal = (item) => {
        profileService.getUserProfile(item.Parametro).then(
            (response) => {
                console.log(response);
                if (response && response.data) {
                    setUserRefeal(response.data);
                    setModalActive(true);

                }
            }
        )
    }

    const actionButton = (status) => {
        const objSend = {
            clv_usuario: userRefeal.clv_usuario,
            clv_estatusreferido: status
        }

        profileService.postGeneric('user/referal', 'put', objSend).then((response) => {
            if (response) {
                swalAlertsService.showToast('success', response.message);
                setModalActive(false);
            }
        })
    }


    useEffect(() => {
        getNotifications();
    }, []);

    const handleNotification = (item) => {
        setSelectedTypeNotification(item);
    }

    const readAll = () => {
        swalAlertsService.answer('Confirmación', '¿Estás seguro de querer marcar todas las notificaciones como leídas?', 'Aceptar').then(result => {
            if (result && result.isConfirmed && result.value) {
                profileService.postGeneric('notification/readall', 'POST', null).then((response) => {
                    console.log(response);
                    swalAlertsService.showToast('success', 'Se marcaron correctamente');
                    setNotificationsList(response.notificationcenter);
                })
            }
        });
    }

    return (
        <div className="NotificationsList-container">
            <Header title='notificaciones' activeLink='notifications' hideSearcher={true} updateShowDot={updateDot} 
                    endMessage={endMessage} />

            <div className="row m-0 d-flex justify-content-center NotificationsList-box-container">
                <div className="col NotificationsList-lateral-content left">

                </div>
                <div className="col NotificationsList-post-box">
                    <div className="row m-0 NotificationsList-content">
                        <div className="col-12 container-tutorial">
                            <span className='title-tutorial'>¿Sabes cómo utilizar WeNet?</span>
                            <div className='container-body-tutorial col-12 col-md-9 mx-auto'>
                                <a href="https://imagora.mx/WeNet30/tutorial-wenet.html" target='_blank' className='row m-0 p-0'>
                                    <div className='container-icn-tutorial'>
                                        <img src={icnBanner} alt="" className='icn_tutorial' />
                                    </div>
                                    <div className='col-9 col-md-7 container-text-tutorial'>
                                        <span className='main-text-tutorial'>Conoce todas las funciones disponibles</span>
                                        <br />
                                        Ver tutoriales <img src={icnArrow} alt="" className='icn_arrow' />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <hr className='hr-tutorial mx-auto' />
                        <h5 onClick={readAll} style={{ cursor: 'pointer', textAlign: 'end', fontSize: '15px', fontWeight: 400, marginRight: '5px' }}>Leer todo</h5>
                        <div className='row m-0'>
                            
                            <div className={`col-6 sample-notification ${selectedTypeNotification === 'MiActividad' ? 'selected-notification' : ''}`} onClick={() => handleNotification('MiActividad')}>
                                <h5 className='text-center'>Mi actividad  {
                                    notificationsList && notificationsList.MiActividad && notificationsList.MiActividad.NotRead && notificationsList.MiActividad.NotRead != 0 ?
                                        (<span className='amount-not-read'> {notificationsList.MiActividad.NotRead} </span>)
                                        : (null)
                                }</h5>
                            </div>
                            <div className={`col-6 sample-notification  ${selectedTypeNotification === 'Informativa' ? 'selected-notification' : ''}  `} onClick={() => handleNotification('Informativa')}>
                                <h5 className='text-center'>Informativa   {
                                    notificationsList && notificationsList.Informativa && notificationsList.Informativa.NotRead && notificationsList.Informativa.NotRead != 0 ?
                                        (<span className='amount-not-read'> {notificationsList.Informativa.NotRead} </span>)
                                        : (null)
                                }</h5>
                            </div>

                        </div>
                        <div className="col-12 p-0">
                            {renderNotificationsList()}
                        </div>
                    </div>
                </div>
                <div className="col NotificationsList-lateral-content left">

                </div>

            </div>
            <MessagesBubble isHome={true} />
            <ModalDefault show={modalActive} onHide={() => setModalActive(false)} className={'modalRefereal'}>
                {userRefeal ? (
                    <div className='container-refereal'>
                        <div className='text-end close-refereal'>
                            <img className='icn-close-refereal' src={icnClose} alt="" onClick={() => setModalActive(false)} />
                        </div>
                        <h3 className='title-refeal text-center'>
                            {
                                userRefeal.clv_estatusreferido === 2 ?
                                    `Has confirmado a ${userRefeal.Nombre} como tu invitado` :
                                    userRefeal.clv_estatusreferido === 3 ?
                                        `Has rechazado a ${userRefeal.Nombre} como tu invitado` :
                                        `¿Confirmas a ${userRefeal.Nombre} como tu invitado a WeNet?`
                            }
                        </h3>
                        <div className='detail-refeal'>
                            <div className='container-user-info-refereal'>
                                <div className='row'>
                                    <div className='col-2'>
                                        <img src={userRefeal.ThumbnailUrl ? userRefeal.ThumbnailUrl : icnPlaceHolder} alt="user image" className='icn_refeal' />
                                    </div>
                                    <div className='col-10'>
                                        <h3 className='name-refereal'>{userRefeal.NombreCompleto}</h3>
                                        <h3 className='position-business-refereal'> {userRefeal.Cargo} | {userRefeal.EmpresaNombreCorto}</h3>
                                        <h3 className='location-refereal'> {userRefeal.EstadoResidencia && userRefeal.PaisResidencia ? userRefeal.EstadoResidencia + '|' + userRefeal.PaisResidencia : ''}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='container-user-description-refereal'>
                                <h3> {userRefeal.Descripcion}</h3>
                            </div>
                            <div className='container-view-profile-refereal text-end'>
                                <Link to={`profile-user/${userRefeal.clv_usuario}`} className='view-profile-refereal'>Ver perfil</Link>
                            </div>
                        </div>
                        <div className='container-buttons-refeal row m-0'>
                            {
                                userRefeal.clv_estatusreferido == 1 ?
                                    (<>
                                        <div className='col-6 text-center'>
                                            <button className='btn btn-cancel-refereal' onClick={() => actionButton(3)}>No</button>
                                        </div>
                                        <div className='col-6 text-center'>
                                            <button className='btn btn-ok-refereal' onClick={() => actionButton(2)} >Si</button>
                                        </div>
                                    </>) :
                                    (null)

                            }

                        </div>
                    </div>
                ) : ''}
            </ModalDefault>
        </div>

    )
}

export { NotificationsList }
