import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './TalentSheet.scss';

import icnStar from '../../../../_assets/media/images/icons/icn_batch_star.svg';
import placeholderuser from '../../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnsSheets from '../../../../_assets/media/images/icons/icns_sheets.png';
import icnLike from '../../../../_assets/media/images/icons/icn_like.png';
import icnEditGreen from '../../../../_assets/media/images/icons/icn_edit_green.png';

const TalentSheet = (props) => {
    const history = useHistory();

    const onRecomendationUserTapped = (recomendation) => {
        if (recomendation && props.onRecomendationUserTapped) {
            props.onRecomendationUserTapped(recomendation);
        }
    }

    const onEditBtnTapped = () => {
        if (props.onEditBtnTapped) {
            props.onEditBtnTapped();
        }
    }

    const renderRecomendations = () => {

        if (props.user && props.user.Recomendaciones && props.user.Recomendaciones.length) {
            const slicedArray = props.user.Recomendaciones.slice(0, 3);
            const items = slicedArray.map((recomendation, index) => {

                return (
                    <div className="col recomendation-item mouse-pointer" key={index} onClick={() => {
                        if (props.user && props.user.clv_usuario) {
                            history.push(`/talent/${props.user.clv_usuario}`, {from: '/profile'} )
                        }
                    }}>

                        <div className="row m-0 recomendation-item-row">
                            <div className="col-12 p-0">
                                <div className="row m-0">
                                    <div className="col-12 p-0">
                                        {/* <div className="row m-0 mouse-pointer" onClick={() => onRecomendationUserTapped(recomendation)}> */}
                                        <div className="row m-0 " >
                                            <div className="col avatar-image-box-60 pos-relative">
                                                <img src={recomendation.ThumbnailUrl ? recomendation.ThumbnailUrl : placeholderuser} alt="recomendation photo"
                                                    onError={(e)=>{
                                                        e.target.onerror = null;
                                                        e.target.src=placeholderuser
                                                    }}
                                                />
                                                <img src={icnLike} alt="like icon" width='35' height='35' className='likeIcn'/>
                                            </div>
                                            <div className="col pe-0">
                                                <div className="row m-0 recomendation-details-box">
                                                    <div className="col-12 px-0 user-details-title text-block-truncate-1">{recomendation.NombreCompleto}</div>
                                                    <div className="col-12 px-0 user-details-subtitle soft text-block-truncate-1 small">
                                                        {`${recomendation.Cargo ? recomendation.Cargo:''}${recomendation.Cargo && recomendation.EmpresaNombreCorto ? ' | ':''}${recomendation.EmpresaNombreCorto ? recomendation.EmpresaNombreCorto:''}`}
                                                    </div>
                                                    <div className="col-12 px-0 user-details-subtitle soft text-block-truncate-1 small">
                                                        {`${recomendation.EstadoResidencia ? recomendation.EstadoResidencia:''}${recomendation.EstadoResidencia && recomendation.PaisResidencia ? ', ':''}${recomendation.PaisResidencia ? recomendation.PaisResidencia:''}`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className="col-12 px-0 recomendation-text-box text-block-truncate-4">
                                        {recomendation.Recomendacion}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })
            return (
                <div className="col-12 TalentSheet-recomendation-container px-0">
                    <div className="row m-0 d-flex align-items-center recomendation-header">
                        <div className="col p-0 recomendation-cant-box">
                            <img src={icnLike} alt="like icon" width='35' height='35'/> {props.user && props.user.Recomendaciones.length} {props.user.Recomendaciones.length == 1 ? 'Recomendación':'Recomendaciones'}
                        </div>
                        <div className="col recomendation-showmore-box">
                            <button className="btn-cleared" onClick={() => {
                                if (props.user && props.user.clv_usuario) {
                                    history.push(`/talent/${props.user.clv_usuario}`, {from: '/profile'} )
                                }
                            }}>Ver todas</button>
                        </div>
                    </div>

                    <div className="row m-0 recomendation-items-container flex-nowrap ">
                        {items}
                    </div>
                </div>
            )
        }
        return null;
    }

    return (
        <div className="row TalentSheet-container">
            <div className="col-12 p-0">
                <div className="row m-0">

                    <div className="col-12 px-0 TalentSheet-header">
                        <span className='titleSpan'>Mi mayor talento</span>
                        {
                            props.isMine ? (
                                <button className='btn-cleared' onClick={onEditBtnTapped}>
                                    <span>{
                                        props.user &&
                                        props.user.RedesSociales &&
                                        props.user.RedesSociales.length ?
                                        ('Editar'):('Agrega')
                                    }</span>
                                    <img src={icnEditGreen} alt="icnEditGreen" width='40' height='40'/>
                                </button>
                            ):(null)
                        }
                    </div>
                </div>
            </div>

            {
                props.user ? (
                    <div className="col-12 TalentSheets-box text-content px-0 pb-3">
                        {props.user.Talento || 'Sin detalles'}
                    </div>
                ):(null)
            }

            {renderRecomendations()}
        </div>
    );
}

export { TalentSheet }
