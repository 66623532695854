import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    useHistory
} from "react-router-dom";
import './Register.scss';
import { toolsHelper } from '../../../_resources/helpers';
import { authenticationService, infoService, swalAlertsService } from '../../../_resources/services';
import { RegisterCreate } from './register-components/register-create/RegisterCreate'
import { RegisterData } from './register-components/register-data/RegisterData';
import { RegisterCode } from './register-components/register-code/RegisterCode';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import md5 from 'md5';
import { async } from 'rxjs';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const WSwal = withReactContent(Swal)

export function useIsMounted() {
    const isMountedRef = useRef(true);
    const isMounted = useCallback(() => isMountedRef.current, []);

    useEffect(() => {
        return () => void (isMountedRef.current = false);
    }, []);

    return isMounted;
}

const Register = (props) => {
    const history = useHistory();
    const [timerRunning, setTimerRunning] = useState(false);
    const isMounted = useIsMounted();

    const [currentState, setCurrentState] = useState({
        status: 'initial'
    });

    const [postState, setPostState] = useState({
        email: "",
        password: "",
        phone: "",
        countryId: 0,
        lada: "",
        paisResidencia: ""
    });

    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
        props.onToggleTab(true);
    }, []);

    const goToCode = () => {
        let actualState = JSON.parse(JSON.stringify(currentState))
        actualState.status = 'code'
        setCurrentState(actualState)
    }

    const goToTerms = () => {
        let actualState = JSON.parse(JSON.stringify(currentState))
        actualState.status = 'terms';
        if (isMounted) {
            setCurrentState(actualState)
        }

    }

    const onRowTapped = (type) => {

        if (type === 'rol') {
            setShowFilters(true);
        }

    }

    const onNextBtnTapped = async (postStateVal) => {
        console.log(postStateVal);
        if (postStateVal.email && toolsHelper.validateEmail(postStateVal.email) && postStateVal.password && postStateVal.countryId && postStateVal.phone && postStateVal.googleToken) {


            const r = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&_])[A-Za-z\\d@$!%*?&_]{8,}\$", "i")

            if (postStateVal.password.length < 8) {
                let text = '';
                text = "Contraseña no valida \n Tu Contraseña debe tener una longitud mínima de 8";
                swalAlertsService.showToast('error', text);
            } else if (postStateVal.password !== postStateVal.password2) {
                let text = '';
                text = "Las contraseñas no coinciden";
                swalAlertsService.showToast('error', text);
            } else {

                authenticationService.getClientIp().then(objIp => {
                    let parameters = {
                        Email: postStateVal.email,
                        clv_pais: postStateVal.countryId,
                        Celular: postStateVal.phone,
                        Password: md5(postStateVal.password),
                        token: postStateVal.googleToken,
                        IP: objIp.ip
                    }
                    setPostState(postStateVal)
                    let loading = swalAlertsService.createLoading('Cargando');
                    loading.fire();
                    authenticationService.register(parameters).then((response) => {
                        loading.close();
                        if (response && response.data) {
                            // goToCode()
                            goToTerms();
                        } else if (response) {
                            swalAlertsService.showToast('error', response);
                        }
                    });
                });
            }


        } else {
            let text = '';

            if (!postStateVal.email || !toolsHelper.validateEmail(postStateVal.email)) {
                text = 'Introduce un correo válido';
            }

            if (!postStateVal.lada) {
                text = 'Introduce una lada';
            }

            if (!postStateVal.phone || postStateVal.phone.length != 10) {
                text = 'Introduce un teléfono (10 dígitos)';
            }

            if (!postStateVal.password) {
                text = 'Introduce una contraseña';
            }

            if (!postStateVal.googleToken) {
                text = 'Catpcha no valido';
            }

            swalAlertsService.showToast('error', text);
        }
    }

    const onCodeBtnTapped = (codePostState, preUser) => {


        if (codePostState.code1 && codePostState.code2 && codePostState.code3 && codePostState.code4 && codePostState.code5) {

            let code = codePostState.code1 + codePostState.code2 + codePostState.code3 + codePostState.code4 + codePostState.code5

            let parameters = {
                UUID: preUser.UUID,
                Codigo: code
            }

            const IUID = localStorage.getItem('iuid');
            if (IUID) {
                parameters['IUID'] = IUID;
            }
            let loading = swalAlertsService.createLoading('Cargando');
            loading.fire();
            authenticationService.createAccount(parameters).then((response) => {
                loading.close();
                if (response) {
                    if (response.data) {
                        localStorage.removeItem('iuid');
                        goToTerms()
                    } else if (typeof response === 'string') {
                        swalAlertsService.showToast('error', response);
                    }
                }
            });
        } else {

            let text = 'Introduce el código que recibiste';

            swalAlertsService.showToast('error', text);
        }
    }

    const onResendCodeBtnTapped = () => {

        let parameters = {
            Email: postState.email,
            clv_pais: postState.countryId,
            Celular: postState.phone,
            Password: md5(postState.password)
        }

        let loading = swalAlertsService.createLoading('Cargando');
        loading.fire();
        authenticationService.register(parameters).then((response) => {
            loading.close();
            if (response && response.data) {
                // avisar a la vista que debe reiniciar el timer
                setTimerRunning(true)
            } else if (typeof response === 'string') {
                swalAlertsService.showToast('error', response);
            }
        });

    }

    const onTermsBtnTapped = () => {

        let parameters = {
            AceptaTerminos: true
        }

        let loading = swalAlertsService.createLoading('Cargando');
        loading.fire();
        authenticationService.acceptTerms(parameters).then((response) => {
            loading.close();
            if (response && response.message) {
                history.replace("/register-steps", { id: 2 })
            } else if (typeof response === 'string') {
                swalAlertsService.showToast('error', response);
            }
        });

    }

    return (
        <GoogleReCaptchaProvider useRecaptchaNet={true} reCaptchaKey="6LfXwokhAAAAANaTzdPQBm9VabRfC0KhzHdIFtRs" scriptProps={{
            async: true, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: 'head', // optional, default to "head", can be "head" or "body",
            nonce: undefined // optional, default undefined
        }}>
            <div className="container-fluid Register-container">
                {(currentState.status === 'initial' &&
                    <RegisterData onNextBtnTapped={onNextBtnTapped}
                        {...props} />)
                }
                {(currentState.status === 'code' &&
                    <RegisterCode
                        onCodeBtnTapped={onCodeBtnTapped}
                        onResendCodeBtnTapped={onResendCodeBtnTapped}
                        timerRunning={timerRunning}
                        setTimerRunning={setTimerRunning}
                        {...props} />)
                }
                {(currentState.status === 'terms' &&
                    <RegisterCreate onTermsBtnTapped={onTermsBtnTapped}
                        {...props} />)
                }
            </div>
        </GoogleReCaptchaProvider>

    );

}

export { Register };
