import React, { useState, useEffect, useRef } from 'react';

import './AppsDisclaimer.scss';

import { algoliaService, swalAlertsService, messagesService, profileService } from '../../../_resources/services';
import { toolsHelper } from '../../../_resources/helpers';

import { NoResults, BottomSheetShared, Filters, ModalDefault } from '../';

import icnBullet from '../../../_assets/media/images/icons/icn_bullet.png';
import icnBulletSelected from '../../../_assets/media/images/icons/icn_bullet_actived.png';
import imgUserPlaceholder from '../../../_assets/media/images/icons/icn_user_placeholder.png';

import icnSearch from '../../../_assets/media/images/icons/icn_search.png';
import icnCancel from '../../../_assets/media/images/icons/icn_close.png';
import icnBroadcast from '../../../_assets/media/images/icons/icn_broadcast.png';
import icnGroup from '../../../_assets/media/images/icons/icn_group.png';
import icnCamera from '../../../_assets/media/images/icons/icn_camera.png';
import icnCheckBox from '../../../_assets/media/images/icons/icn_checkbox.png';
import icnCheckBoxSelected from '../../../_assets/media/images/icons/icn_checkbox_selected.png';
import icnDeletContact from '../../../_assets/media/images/icons/icn_delet_contact.png';
import icnCopy from '../../../_assets/media/images/icons/icn_copy.png';
import start from '../../../_assets/media/images/icons/icn_batch_star.svg';

import imgWenetShadow from '../../../_assets/media/images/img_wenet_shadow.png';
import icnApple from '../../../_assets/media/images/icons/icn_app_apple.png';
import icnGoogle from '../../../_assets/media/images/icons/icn_app_google.png';

const AppsDisclaimer = (props) => {

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const mainRender = () => {
        return (
            <React.Fragment>
                <div className='AppsDisclaimer-container'>
                    <div className='row m-0'>
                        <div className='col-12 AppsDisclaimer-header-box' style={{visibility: "visible"}}>
                            <button className='btn-cleared AppsDisclaimer-btn-close mouse-pointer' onClick={onDismiss}>
                                <img src={icnCancel} alt="close icon"/>
                            </button>
                        </div>
                    </div>

                    <div className="row m-0">
                        <div className="col-12 p-0 d-flex justify-content-center align-items-center AppsDisclaimer-logo-box">
                            <img src={imgWenetShadow} alt="" width='210' height='210'/>
                        </div>

                        <div className="col-12 AppsDisclaimer-text-main">
                            <p className='firstLine'>
                                Para vivir la mejor experiencia de Networking desde la palma de tu mano
                            </p>
                            <p className='secondLine'>
                                Descarga la app en tu celular
                            </p>
                        </div>

                        <div className="col-12 px-0 AppsDisclaimer-text-subtitle">
                            WeNet está disponible para iOS y Android
                        </div>

                        <div className="col-12 p-0">
                            <div className="row m-0">
                                <div className="col text-end ps-0 pe-2">
                                    <a href="https://apps.apple.com/uy/app/wenetceo/id1576540331" target='_blank'>
                                        <img src={icnApple} alt="icnApple" height='42'/>
                                    </a>
                                </div>
                                <div className="col text-start pe-0 ps-2">
                                    <a href="https://play.google.com/store/apps/details?id=com.bmdigitalsociety.wenetCEO&hl=es_MX" target='_blank'>
                                        <img src={icnGoogle} alt="icnGoogle" height='42'/>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <ModalDefault show={true} className='full-view AppsDisclaimer'>
            {mainRender()}
        </ModalDefault>

    );
}

export { AppsDisclaimer };
