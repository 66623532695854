import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import back from '../../../../_assets/media/images/icons/icn_back_arrow.png';
import imgUserPlaceholder from '../../../../_assets/media/images/icons/icn_user_placeholder.png';
import { authenticationService, swalAlertsService } from '../../../../_resources/services';
import { settingService } from '../../../../_resources/services/settings.service';
import { Settings } from '../configuration/settings';
import "./RestrictedUsers.scss"

const RestrictedUsers = (props) => {


    const [dataRestrict, setDataRestrict] = useState([])
    const history = useHistory()

    useEffect(() => {
        if (props.onToggleTab) {
            props.onToggleTab(true);
        }
        getData()
    }, [])

    const getData = () => {
        settingService.getRestrictedUser().then(
            (response) => {
                console.log(response.data)
                 setDataRestrict(response.data)
            }
        )
        .catch(
            (error) => {
             console.log(error)
            }
        )
    }

    const desrestrictedUser = (value) => {
        swalAlertsService.answer("", "¿Estas seguro de no silenciar a este usuario?", "Si, no slienciar")
        .then((result) => {
            if (result.isConfirmed) {
                let loading = swalAlertsService.createLoading("Eliminando")
                loading.fire()
                settingService.deleteRestrictedUser(value).then(
                    (response) => {
                        getData()
                        loading.close()
                        Swal.fire(
                            '',
                            'Usuario no silenciado',
                            'success'
                        )
                        setTimeout(() => {
                            window.location.reload(true)
                        }, 1000);
                    }
                )
                .catch(
                    (error) => {
                        console.log(error)
                    }
                )
            }
        })
    }


    const containerestrict = (data) => {
        return (
            <div className="container_bloqued" key={data.clv_restringidoxusuario}>
                {
                    data && data.ThumbnailUrl ?
                    (
                        <img src={data.ThumbnailUrl} width="60px" height="60px"/>
                    ):(
                        <img src={imgUserPlaceholder} width="60px" height="60px"/>
                    )
                }

                <div className="container_bloqued_data">
                    <p className="container_bloqued_data_user">{data.NombreCompleto}</p>
                    <p>{data.Cargo + " | " + data.EmpresaNombreCorto}</p>
                    <p>{data.Giro + ", " + data.Clasificacion}</p>
                    <p>{data.Estado + ", " + data.Pais}</p>
                </div>
                <button className="container_bloqued_button" onClick={ () => desrestrictedUser(data.clv_restringidoxusuario)}>No silenciar</button>
            </div>
        )
    }

    return (
        <div className={`container-RestrictedUsers container-restricted-users ${props.isDesktop ? 'isDesktop':''}`}>
            <div className="container_header">
                {
                    !props.isDesktop ? (
                        <img src={back} width="30px" height="30px" onClick={() => history.goBack()}/>
                    ):(null)
                }
                <h3>Silencio permantente de usuarios</h3>
            </div>

            {

                dataRestrict.length == 0
                ?
                    <p className="container_header_bloqued">
                        Si no deseas ver las publicaciones de algunas personas, puedes silenciarlas sin necesidad de bloquear la comunicación
                        por medio de mensajes
                        <br/> <br/>
                        <strong className='text-muted-config'> ¡Precaución! al silenciar algún usuario, no tendrá acceso a sus publicaciones, a menos que lo desbloquee.</strong>
                        <br/>
                    </p>

                :
                    <>
                        <p className="container_header_bloqued">
                            Se han ocultado las publicaciones de estas personas. Aún puedes
                            interactuar con ellos por medio de la sección de Mensajes
                            <br/> <br/>
                            <strong> ¡Precaución! al silenciar algún usuario, no tendrá acceso a sus publicaciones, a menos que lo desbloquee.</strong>                        </p>
                        {
                            dataRestrict.map( (data) =>
                                containerestrict(data)
                            )
                        }
                        <br/>
                    </>

            }
        </div>
    );
}

export {RestrictedUsers};
