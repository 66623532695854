import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from "react-router-dom";

import './RecomendationDetails.scss';

import { publicationService, swalAlertsService, profileService, authenticationService } from '../../../_resources/services';
import { toolsHelper } from '../../../_resources/helpers';

import { ModalDefault, ContactList } from '../../shared';
import { RecomendationsList, RecomendationTalent } from './recomendation-details-components';

import icnBack from '../../../_assets/media/images/icons/icn_back_arrow.png';
import { ImageCrop } from '../../shared/image-crop/ImageCrop';

const RecomendationDetails = (props) => {
    const history = useHistory();
    const [userID, setUserID] = useState(null);
    const [user, setUser] = useState(null);
    const [shouldOpenRecoView, setShouldOpenRecoView] = useState(false);
    const [isContactAdding, setIsContactAdding] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    const showTab = () => {
        if (props.onToggleTab) {
            props.onToggleTab(false);
        }
    }

    const goToPreviousPath = () => {
        setTimeout(() => {
            showTab();
        },200);
        history.goBack();
        // history.push("/profile", {shouldReload: true});
    }

    const getPassedID = () => {
        if (props) {
            if (props.match && props.match.params && props.match.params.talent_id) {
                let id = props.match.params.talent_id;

                if (isNaN(id)) {
                    const matches = id.match(/(\d+)/);
                    if (matches) {
                        id = matches[0];
                    } else {
                        return null;
                    }
                }
                return parseInt(id);
            }
        }
        return null;
    }

    const getUser = (id) => {
        profileService.getUserProfile(id).then(
            (response) => {
                setUser(response.data);
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const dataUpdated = () => {
        let id = userID;

        if (!id) {
            id = currentUser.Usuario.clv_usuario;
        }
        getUser(id);
        setShouldOpenRecoView(false);
    }


    useEffect(() => {
        let isSuscribed = true;
        authenticationService.currentUser.subscribe((user) => {
            if (user && user.SUID && user.Usuario && isSuscribed) {
                setCurrentUser(user);
            }
        });

        if (props.onToggleTab) {
            props.onToggleTab(true);
        }

        const id = getPassedID();
        if (id && isSuscribed) {
            setUserID(id);
            getUser(id);
        }

        return () => isSuscribed = false
    }, []);

    const onBtnTapped = (data) => {
        if (data) {
            if (data === 'reco') {
                console.log("recomendar");
                setShouldOpenRecoView(true);
            } else {
                console.log("pedir recomendacion");
                setIsContactAdding(true);
            }
        }
    }

    const onCloseContactAdding = () => {
        setIsContactAdding(false);
    }

    const onContantSendBtnTapped = (data) => {
        if (data && data.length) {
            let loader = swalAlertsService.createLoading("Espere un momento...")
            loader.fire();
            const objSend = {
                users: data.map((el) => { return {clv_usuario: el.clv_usuario}})
            }
            profileService.postGeneric('user/recommendations', 'POST', objSend).then(
                (response) => {
                    loader.close();
                    if (response) {
                        if (response.message && response.message.indexOf('correctamente') >= 0) {
                            dataUpdated();
                            swalAlertsService.showToast('success', 'Petición de recomendación enviada correctamente');
                        } else {
                            swalAlertsService.showToast('error', 'No pudimos enviar la petición de recomendación');
                        }
                    }
                },
                (error) => {
                    console.log(error);
                    loader.close();
                    swalAlertsService.showToast('error', 'No pudimos enviar la petición de recomendación');
                }
            )
        }
        // setUser({...user, InvitadoPor: data.NombreCompleto, clv_usuarioInvitadoPor: data.clv_usuario});
        onCloseContactAdding();
        // if (data && data.length) {
        //     let obj = {
        //         Usuarios: data.map(el => { return {clv_usuario: el.clv_destinatario}})
        //     }
        //
        //     let loading = swalAlertsService.createLoading("Espere un momento...")
        //     loading.fire();
        //     messagesService.postAddMembersToGroup(groupState.clv_conversacion, obj).then((response) => {
        //         loading.close();
        //         if (response && response.data && response.data && response.message) {
        //             swalAlertsService.showToast('success', response.message);
        //             setGroupState(response.data);
        //             onBottomSheetDimissContactList();
        //             onChatUpdate();
        //         } else {
        //             swalAlertsService.showToast('error', 'Ocurrió un problema al intentar actualizar el grupo');
        //         }
        //     });
        // }
    }

    return (
        <>
            <div className="RecomendationDetails-container" id='RecomendationDetails-container'>
                <div className="row m-0 RecomendationDetails-back-container">
                    <div className="col p-0 pos-relative RecomendationDetails-back-col">
                        <button className="btn-cleared" onClick={goToPreviousPath}>
                            <img src={icnBack} alt="back icon" width='30' height='30'/>
                        </button>

                        Recomendaciones
                    </div>
                </div>
                <RecomendationTalent currentUser={currentUser} userID={getPassedID()} user={user} onBtnTapped={onBtnTapped}/>
                {
                    user && user.Recomendaciones && user.Recomendaciones.length ? (
                        <RecomendationsList currentUser={currentUser} userID={getPassedID()} user={user} dataUpdated={dataUpdated} shouldOpenRecoView={shouldOpenRecoView}/>
                    ):(null)
                }

            </div>

            {
                isContactAdding &&
                <ContactList currentUser={currentUser}
                    onBottomSheetDimiss={onCloseContactAdding}
                    onInvitationAdded={onContantSendBtnTapped}
                    isGroupAdding={false}
                    groupPreviusUser={[]}
                    isInvitation={true}
                    isMultiple={true}
                />
            }
        </>
    );
}

export { RecomendationDetails }
