import React, { useState, useEffect } from 'react';
import './Filters.scss';
import icnClose from '../../../_assets/media/images/icons/icn_close.png';

import { infoService } from '../../../_resources/services';

import { FilterTable, FilterOptionPicker } from './filter-components';

import { ModalDefault } from '../';

const Filters = (props) => {
    const [open, setOpen] = useState(false);
    const [filtersTitle, setFiltersTitle] = useState('Filtros');

    const [showSubTitle, setShowSubTitle] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false)
    const [showHeader, setshowHeader] = useState(false)

    const [showClearBtn, setShowClearBtn] = useState(false);
    const [showPostedBy, setShowPostedBy] = useState(true);
    const [showOptionPicker, setShowOptionPicker] = useState(false);
    const [optionPickerMode, setOptionPickerMode] = useState(null);
    const [catalogs, setCatalogs] = useState(null);
    const [activeSubmitBtn, setActiveSubmitBtn] = useState(false);

    const [activeFilters, setActiveFilters] = useState({
        filtersSelected: null,
        postedBy: null,
        isUsingFilters: false
    });

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const onDismissPicker = () => {
        setShowOptionPicker(false);
        setOptionPickerMode(null);
    }

    const prepareCatalogs = () => {
        infoService.catalogs.subscribe((catalogs) =>{
            if (catalogs) {
                setCatalogs(catalogs);
            }
        });
    }

    useEffect(() => {
        toggleCleanFiltersBtn();
    }, [activeFilters]);

    useEffect(() => {
        toggleCleanFiltersBtn();
    }, [activeFilters.filtersSelected, activeFilters.postedBy]);


    useEffect(() => {
        if (!showPostedBy) {
            setShowPostedBy(true);
        }
    }, [showPostedBy]);

    useEffect(() => {
        setShowOptionPicker(optionPickerMode ? true:false);
    }, [optionPickerMode]);

    useEffect(() => {
        prepareCatalogs();
        if (props.activeFilters) {
            setActiveFilters(props.activeFilters);
        }

        if (props.showSubTitle) {
            setFiltersTitle('Dirigir publicación a:')
            setShowSubTitle(true);
        }

        if(props.showSubmit) {
            setShowSubmit(props.showSubmit)
        }

        if(props.showHeader) {
            setshowHeader(props.showHeader)
        }


    }, []);

    useEffect(() => {
        if(props.preferences) {
            let data = {
                optionsSelected: [],
                mode: null,
            };
            data.optionsSelected = props.preferences;
            data.mode = "Cargos"
            let activeFiltersCP3 = {
                filtersSelected: {},
                postedBy: null,
                isUsingFilters: false
            };
            activeFiltersCP3.filtersSelected [data.mode] = props.preferences;
            setActiveFilters(activeFiltersCP3)
        }


    }, [props.preferences])

    const onClearFilters = () => {
        setActiveFilters({
            filtersSelected: null,
            postedBy: null,
            isUsingFilters: false
        });
        setActiveSubmitBtn(true);
    }

    const onFilterPickerBtnTapped = (data) => {
        console.log(data);
        setOptionPickerMode(data);
    }

    const onAddBtnTapped = (data) => {
        if(showHeader) {
            props.selectFilters(data.optionsSelected)
        }
        if (data.optionsSelected && data.mode) {
            let activeFiltersCP = activeFilters;
            if (activeFiltersCP && activeFiltersCP.filtersSelected) {
                activeFiltersCP.filtersSelected[data.mode] = data.optionsSelected;
            } else {
                activeFiltersCP = {
                    filtersSelected: {},
                    postedBy: null,
                    isUsingFilters: false
                };
                activeFiltersCP.filtersSelected [data.mode] = data.optionsSelected;
            }
            setActiveFilters(activeFiltersCP);
            setOptionPickerMode(null);
        }
    }

    const onTagDelete = (data) => {
        let activeFiltersCP = JSON.parse(JSON.stringify(activeFilters));

        if (activeFiltersCP.filtersSelected && data.tag && data.key && activeFiltersCP.filtersSelected[data.key]) {
            let filtered = activeFiltersCP.filtersSelected[data.key].filter(element => element.id === data.tag.id);

            if (filtered.length) {
                let index = activeFiltersCP.filtersSelected[data.key].indexOf(filtered[0]);
                if (index >= 0) {
                    activeFiltersCP.filtersSelected[data.key].splice(index, 1);
                    if (activeFiltersCP.filtersSelected[data.key].length === 0) {
                        delete activeFiltersCP.filtersSelected[data.key]
                    }

                    const keyys = Object.keys(activeFiltersCP.filtersSelected);

                    if (keyys.length === 0) {
                        activeFiltersCP.filtersSelected = null;
                    }
                    if(JSON.stringify(activeFiltersCP.filtersSelected) !== "null") {
                        if(props.selectFilters) {
                            props.selectFilters(activeFiltersCP.filtersSelected[data.key])
                        }
                        setActiveFilters(activeFiltersCP);
                    }else {
                        if(props.selectFilters) {
                            props.selectFilters([])
                        }
                        setActiveFilters([]);
                    }

                }
            }
        }
    }


    const toggleCleanFiltersBtn = () => {
        if (activeFilters.filtersSelected || activeFilters.postedBy) {
            setShowClearBtn(true);
            setActiveSubmitBtn(true);
            if(props.window) {
                if(props.window === "register") {
                    setShowSubmit(false)
                }
            }else {
                setShowSubmit(true)
            }
        } else {
            setShowClearBtn(false);
        }
    }

    const onPostedBySelected = (data) => {
        if (data) {
            let activeFiltersCopy = JSON.parse(JSON.stringify(activeFilters));
            activeFiltersCopy.postedBy = data;
            setActiveFilters(activeFiltersCopy);
        }
    }


    const onSubmitBtnTapped = () => {
        if (props.onSubmit) {
            props.onSubmit({activeFilters});
        }
    }

    const renderView = () => {
        return (
            <React.Fragment>
                <div className={`Filters-container ${showSubTitle ? 'hasSubtitle':''}`}>
                    <div className={`row m-0 ${showSubTitle ? 'hasSubtitle':''}`}>
                        <div className={`col-12 Filters-header-box`} style={showHeader ? {display: "none"} : {visibility: "visible"}}>
                            <button className='btn-cleared Filters-btn-close mouse-pointer' onClick={onDismiss}>
                                <img src={icnClose} alt="close icon"/>
                            </button>

                            <p className={`${showClearBtn ? 'large-title':''}`}>{filtersTitle}</p>

                            {
                                showSubTitle &&
                                <div className="col-12 Filters-subtitle-box">
                                    Personaliza tu publicación con los filtros y dirígela a un público específico.
                                </div>
                            }
                            {
                                showClearBtn && <button className='btn-cleared Filters-btn-clear mouse-pointer' onClick={onClearFilters}>Restablecer</button>
                            }
                        </div>

                        <div className="row m-0">
                            <div className="col p-0 Filters-content">
                                {
                                    catalogs ? (
                                        <FilterTable catalogs={catalogs}
                                            onFilterPickerBtnTapped={onFilterPickerBtnTapped}
                                            activeFilters={activeFilters}
                                            hideTags={props.hideTags}
                                            hidePostedBy={props.hidePostedBy}
                                            onPostedBySelected={onPostedBySelected}
                                            onTagDelete={onTagDelete}
                                            showAllTags={props.showAllTags}
                                            />
                                    ):(null)
                                }


                            </div>
                        </div>

                        {
                            showOptionPicker ? (
                                <FilterOptionPicker mode={optionPickerMode}
                                    title={optionPickerMode == 'Asociacion' ? 'Asociación':optionPickerMode}
                                    open={showOptionPicker}
                                    catalogs={catalogs}
                                    onAddBtnTapped={onAddBtnTapped}
                                    onDismiss={onDismissPicker}
                                    filtersSelected={activeFilters.filtersSelected}
                                />
                            ):(null)
                        }



                    </div>

                    {
                        showSubmit ?
                        (
                            <div className={`Filters-footer-container ${props.isDesktop ? 'isDesktop':''}`}>
                                <button className={`btn-cleared ${activeSubmitBtn ? 'active':''}`} onClick={() => {onSubmitBtnTapped()}}>
                                    Aplicar filtros
                                </button>
                            </div>
                        ):(null)
                    }
                </div>
            </React.Fragment>
        );
    }

    if (props.hideModal) {
        return renderView();
    } else {
        return (
            <ModalDefault show={true} className='full-view'>
                {renderView()}
            </ModalDefault>
        );
    }

}
export { Filters };
