import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import back from '../../../../../_assets/media/images/icons/icn_back_arrow.png'
import './EditCompanyHeader.scss'

const EditCompanyHeader = (props) => {

    const history = useHistory()

    return (
        <div className="container_header">
            <img className='mouse-pointer cursor-pointer' src={back} width="30px" height="30px" onClick={() => props.back()}/>
            <h3>Editar empresa</h3>
            <p onClick={() => props.save(true)} className='mouse-pointer cursor-pointer'>Guardar</p>
        </div>
    )
}

export {EditCompanyHeader}
