import React, { useState, useEffect } from 'react';

import { infoService } from '../../../../_resources/services';

import icnEditGreen from '../../../../_assets/media/images/icons/icn_edit_green.png';

import './AsociationsSheet.scss';

const AsociationsSheet = (props) => {
    const [catalogs, setCatalogs] = useState(null);

    const getIpadeDetails = () => {
        if (catalogs && catalogs.EstatusIpade && catalogs.EstatusIpade.length && props.user && props.user.clv_estatusIpade) {
            const filtered = catalogs.EstatusIpade.filter(el => el.clv_estatusIpade === props.user.clv_estatusIpade);

            if (filtered.length) {
                return `${filtered[0].EstatusIpade}${props.user.FechaIpade ? ` - ${props.user.FechaIpade}` : ''}`;
            }
        }

        return null;
    }

    useEffect(() => {
        let isSuscribed = true;

        infoService.catalogs.subscribe((response) => {
            if (typeof response === 'object' && response !== null && isSuscribed) {
                setCatalogs(response);
            }
        });

        return () => isSuscribed = false
    }, [])

    return (
        <div className="AsociationsSheet-container row">

            <div className="col-12 px-0">

                <div className="row m-0 AsociationsSheet-asociations-container p-0">
                    <div className="col-12 px-0 colTitle">
                        Asociaciones a las que pertenece
                    </div>

                    {
                        props.user && props.user.clv_estatusbm &&  props.user.clv_estatusbm != 4 && props.user.clv_estatusbm != 1 ? (
                            <div className="col-12 asociationBox">
                                <div className="row m-0">
                                    <div className="col px-0 asocTitle">BoardMedia Digital</div>
                                    <div className="col-4 px-0 asocDetails text-end">{props.user.clv_estatusbm === 2 ? 'Miembro activo' :
                                        props.user.clv_estatusbm === 3 ? 'Ex Miembro' : ''}</div>
                                </div>
                            </div>
                        ) : (null)
                    }

                    {
                        props.user && props.user.clv_estatusIpade ? (
                            <div className="col-12 asociationBox">
                                <div className="row m-0">
                                    <div className="col px-0 asocTitle">IPADE</div>
                                    <div className="col-4 px-0 asocDetails text-end">{getIpadeDetails()}</div>
                                </div>
                            </div>
                        ) : (null)
                    }

                </div>

            </div>
        </div>
    );
}

export { AsociationsSheet };
