import React, { useState, useEffect } from 'react';
import { BottomSheet, SheetContent } from 'react-spring-bottom-sheet';
import './BottomSheetShared.scss';

import icnFilter from '../../../_assets/media/images/icons/icn_filter.png';
import icnFilterSelected from '../../../_assets/media/images/icons/icn_filter_active.png';
import icnClose from '../../../_assets/media/images/icons/icn_close.png';

const BottomSheetShared = (props) => {
    const [open, setOpen] = useState(true);
    const [blocking, setBlocking] = useState(true);
    const [title, setTitle] = useState('Seleccionar opción');
    const [showHeader, setShowHeader] = useState(false);
    const [showHeaderSubmit, setShowHeaderSubmit] = useState(false);

    const [showCancelBtn, setShowCancelBtn] = useState(true);
    const [showFilterBtn, setShowFilterBtn] = useState(false);
    const [footerBtn, setFooterBtn] = useState(null);
    const [viewMaxHeight, setViewMaxHeight] = useState(false);
    const [viewMinHeight, setViewMinHeight] = useState(false);
    const [searchTerm, setSearchTerm] = useState('hola');

    const prepareBottomElements = () => {
        if (props.showHeader) {
            setShowHeader(props.showHeader);
        }

        if (props.showFilterBtn) {
            setShowFilterBtn(props.showFilterBtn);
        }

        if (props.footerBtn) {
            setFooterBtn(props.footerBtn);
        }

        if (props.viewMaxHeight) {
            setViewMaxHeight(props.viewMaxHeight);
        }

        if (props.viewMinHeight) {
            setViewMinHeight(props.viewMinHeight);
        }

        if (props.title) {
            setTitle(props.title);
        }

        if ('blocking' in props) {
            setBlocking(props.blocking);
        }

        if ('showCancelBtn' in props) {
            setShowCancelBtn(props.showCancelBtn);
        }

        if (props.showHeaderSubmit) {
            setShowHeaderSubmit(props.showHeaderSubmit);
        }

        setOpen(props.isOpen ? true : false);
    }

    const onDismiss = () => {
        if ('shouldClose' in props) {
            if (props.shouldClose) {
                setOpen(false);

                setTimeout(() => {
                    if (props.onDismiss) {
                        props.onDismiss(false);
                    }
                }, 800);
            } else {
                if (props.onDismiss) {
                    props.onDismiss(false);
                }
            }
        } else {
            setOpen(false);

            setTimeout(() => {
                if (props.onDismiss) {
                    props.onDismiss(false);
                }
            }, 800);
        }


    }

    const onFilterBtnTapped = () => {
        if (props.onFilterBtnTapped) {
            props.onFilterBtnTapped();
        }
    }

    const cancelBtn = () => {
        if (props.isCloseSymbol) {
            return (
                <button className='left' onClick={() => {onDismiss()}}>
                    <img src={icnClose} alt="icnClose" width='30' height='30'/>
                </button>
            );
        }
        return (
            <button className='left' onClick={() => {onDismiss()}}>Cancelar</button>
        );
    }

    const filterBtn = () => {
        return (
            <button className='right btn-cleared' onClick={() => {onFilterBtnTapped()}}>
                <img src={props.isActiveFilters ? icnFilterSelected:icnFilter} alt="filter icon" width='30' height='30'/>
            </button>
        );
    }

    const onHeaderSubmitBtnTapped = () => {
        if (props.onHeaderSubmit) {
            props.onHeaderSubmit();
        }
    }
    const submitBtn = () => {
        return (
            <button className='right btn-cleared font-color-blue' onClick={onHeaderSubmitBtnTapped}>
                Guardar
            </button>
        );
    }

    const headerElement = () => {
        return (
            <div className="row m-0">
                <div className="col-12 p-0 BottomSheetShared-header-box">
                    {showCancelBtn && cancelBtn()}
                    <p>
                        {title}
                    </p>

                    {showFilterBtn && filterBtn()}

                    {showHeaderSubmit && submitBtn()}
                    {
                        props.likesCounter && props.likesCounter
                    }
                </div>
            </div>
        );
    }

    const footerElement = () => {
        return (
            <div className="row m-0">
                <div className="col-12 p-0 BottomSheetShared-footer-box">
                    {footerBtn}
                </div>
            </div>
        );
    }

    const defineMinHeight = (maxHeight) => {
        if (props.viewMinHeight) {
            return props.viewMinHeight;
        }
        return maxHeight - maxHeight / 9;
    }

    const defineMaxHeight = (maxHeight) => {
        if (props.bottomSheetMaxHeightDefiner) {
            const value = maxHeight - props.bottomSheetMaxHeightDefiner;
            // return value < 500 ? 500:value;
            return maxHeight - props.bottomSheetMaxHeightDefiner;
        }

        return props.viewMaxHeight ? props.viewMaxHeight: maxHeight;
    }

    useEffect(() => {
        prepareBottomElements();
    }, []);

    useEffect(() => {
        if (props.footerBtn) {
            setFooterBtn(props.footerBtn);
        } else if (footerBtn) {
            setFooterBtn(null);
        }
    }, [props.footerBtn]);

    useEffect(() => {
        if (props.title) {
            setTitle(props.title);
        }
    }, [props.title]);

    useEffect(() => {
        if ('showFilterBtn' in props) {
            setShowFilterBtn(props.showFilterBtn);
        }
    }, [props.showFilterBtn]);

    return (
        <React.Fragment>
            <BottomSheet open={open}
                onDismiss={onDismiss}
                defaultSnap={({ snapPoints, lastSnap }) => {
                    if (props.firstOpen) {
                        return Math.max(...snapPoints)
                    }
                    return lastSnap ?? Math.min(...snapPoints)
                }}
                snapPoints={({ maxHeight }) => [
                    defineMinHeight(maxHeight),
                    defineMaxHeight(maxHeight),
                ]}
                header={showHeader ? headerElement() : null}
                footer={footerBtn ? footerElement() : null}
                blocking={blocking}
                className='BottomSheetMainBox'
            >

                {props.children}
            </BottomSheet>
        </React.Fragment>
    );
}

export { BottomSheetShared };
