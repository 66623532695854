import { authenticationService } from '../services';

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    const tempUser = authenticationService.tempCurrentUserValue;
    let headers = {
        'Content-Type': 'application/json',
        'Token': process.env.REACT_APP_API_TOKEN
    }
    if (currentUser && currentUser.SUID) {
        headers['SUID'] = currentUser.SUID;
    } else  if (tempUser && tempUser.SUID) {
        headers['SUID'] = tempUser.SUID;
    }
    return headers;
}
