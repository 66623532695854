import React, { useState, useEffect, useRef, useMemo } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { EditUserHeader } from './edit-user-header/EditUserHeader';
import placeholderuser from '../../../../_assets/media/images/icons/icn_user_placeholder.png';
import email from './../../../../_assets/media/images/icons/icn_mail_black.png'
import phone from './../../../../_assets/media/images/icons/icn_phone.png';
import birthday from './../../../../_assets/media/images/icons/icn_birthday.png';
import users from './../../../../_assets/media/images/icons/icn_user_name.png';
import degree from './../../../../_assets/media/images/icons/icn_degree.png';
import study from './../../../../_assets/media/images/icons/icn_study.png'
import position from './../../../../_assets/media/images/icons/icn_position.png'
import more from './../../../../_assets/media/images/icons/icn_more.png';

import icnsTogglers from './../../../../_assets/media/images/icons/icns_togglers.png';
import icnArrowDown from './../../../../_assets/media/images/icons/icn_arrow_down.png';


import { UniversalPicker, ContactList } from '../../../shared';
import DatePicker, { CalendarContainer, registerLocale, setDefaultLocale } from "react-datepicker";
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import es from 'date-fns/locale/es';


import './EditUser.scss'
import { toolsHelper } from '../../../../_resources/helpers';
import { profileService, swalAlertsService, authenticationService, infoService } from '../../../../_resources/services';
import { getDefaultWatermarks } from 'istanbul-lib-report';
import { useHistory, useParams } from 'react-router-dom';
import { load } from 'dotenv';
import { ImageCrop } from '../../../shared/image-crop/ImageCrop';
import { Button } from 'bootstrap';

const EditUser = (props) => {

    registerLocale('es', es);
    const history = useHistory()
    const inputLinkedin = useRef()
    const [user, setUser] = useState({})
    const [userCopy, setUserCopy] = useState({})
    const [openCamera, setOpenCamera] = useState(false);
    const [openUniversalPicker, setOpenUniversalPicker] = useState(false)
    const [type, setType] = useState("")
    const [fechaFormat, setFechaFormat] = useState(true)
    const [checkedPhone, setCheckedPhone] = useState(false)
    const [checkedBirth, setCheckedBirth] = useState(false);
    const [checkedEmail, setCheckedEmail] = useState(false)
    const [save, setsave] = useState(false)
    const [visibleCrop, setVisibleCrop] = useState(false)
    const [isContactAdding, setIsContactAdding] = useState(false);
    const [imageData, setImageData] = useState({
        base64: null,
        splitted: null
    });

    const [mentoryTypes, setMentoryTypes] = useState([]);

    const [inputRedesSociales, setInputRedesSociales] = useState([
        {
            clv_redsocial: 1,
            Perfil: "",
            Activo: false
        },
        {
            clv_redsocial: 2,
            Perfil: "",
            Activo: false
        },
        {
            clv_redsocial: 3,
            Perfil: "",
            Activo: false
        },
        {
            clv_redsocial: 4,
            Perfil: "",
            Activo: false
        },
        {
            clv_redsocial: 5,
            Perfil: "",
            Activo: false
        },
        {
            clv_redsocial: 6,
            Perfil: "",
            Activo: false
        }
    ])
    const useStyles = makeStyles((theme) => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
    }));
    const classes = useStyles();

    const isMounted = toolsHelper.useIsMounted();
    const [formDateUser, setFormDateUser] = useState({
        "EmailPrivado": null,
        "CelularPrivado": null,
        "Nombre": "",
        "ApellidoPaterno": null,
        "ApellidoMaterno": null,
        "clv_genero": null,
        "FechaNacimiento": null,
        "clv_nivelestudio": null,
        "clv_aficion": null,
        "clv_nacionalidad": null,
        "clv_estadoresidencia": null,
        "clv_paistelefono": null,
        "Telefono": null,
        "clv_cargo": null,
        "clv_estatusbm": null,
        "FechaNacimientoPrivado": null
    })


    const [userData, setUserData] = useState({
        clv_estatusbm: null,
        clv_estatusIpade: null,
        isBM: false,
        isIpade: false,
        FechaIpade: null
    });

    const [date, setDate] = useState(null)

    const params = useParams()

    const inputFile = useRef(null);
    const inputImage = useRef(null);


    useEffect(() => {
        if (props.onToggleTab) {
            props.onToggleTab(true);
        }
        getUser()
        setDate(new Date().getFullYear())

        infoService.catalogs.subscribe((catalogs) => {
            console.log(catalogs);

            if (catalogs && catalogs.TiposMentoria && catalogs.TiposMentoria.length) {
                setMentoryTypes(catalogs.TiposMentoria);
            }
            // if (catalogs && catalogs.Paises) {
            //     let mxFiltered = catalogs.Paises.filter(element => element.Pais === 'México');
            //
            //     if (mxFiltered.length) {
            //         this.setState({phoneLada: mxFiltered[0]});
            //     }
            // }

        });
    }, [])

    const getMinAgeDate = () => {
        let date = new Date();

        date.setFullYear(date.getFullYear() - 18);

        return date;
    }

    const saveUser = async (value) => {
        let loader = swalAlertsService.createLoading("Espere un momento...")
        loader.fire()
        if (params.user_id !== "0") {
            console.log(user);
            if (user.Nombre && user.ApellidoPaterno && user.clv_cargo && user.clv_paisresidencia && user.clv_estadoresidencia) {

                if (userData.isIpade) {
                    if (!userData.clv_estatusIpade) {
                        swalAlertsService.showToast("error", "Debe catálogo de programa de IPADE");
                        return;
                    }

                    if (!userData.FechaIpade) {
                        swalAlertsService.showToast("error", "Debe seleccionar una fecha de IPADE");
                        return;
                    }
                }

                if (userData.isBM && !userData.clv_estatusbm) {
                    swalAlertsService.showToast("error", "Debe seleccionar una opción de BoardMedia Digital");
                    return;
                }

                if (user.OfreceMentoria) {
                    if (!user.clv_tipomentoria) {
                        swalAlertsService.showToast("error", "Debe seleccionar una opción de mentoría");
                        return;
                    } else if (user.clv_tipomentoria === 4 && !user.Mentoria) {
                        swalAlertsService.showToast("error", "Debe definir el nombre de la mentoría que ofrece");
                        return;
                    }
                }
                // if(inputRedesSociales[0].Activo) {
                //     if(!toolsHelper.validateURL(inputRedesSociales[0].Perfil)) {
                //         loader.close()
                //         swalAlertsService.showToast("error", "Debe escribir una dirección web válida para Linkedin que ingreso sea una URL valida")
                //         return
                //     }
                // }
                //
                // if(inputRedesSociales[1].Activo) {
                //     if(!toolsHelper.validateURL(inputRedesSociales[1].Perfil)) {
                //         loader.close()
                //         swalAlertsService.showToast("error", "Debe escribir una dirección web válida para Facebook que ingreso sea una URL valida")
                //         return
                //     }
                // }
                //
                // if(inputRedesSociales[2].Activo) {
                //     if(!toolsHelper.validateURL(inputRedesSociales[2].Perfil)) {
                //         loader.close()
                //         swalAlertsService.showToast("error", "Debe escribir una dirección web válida para Twitter que ingreso sea una URL valida")
                //         return
                //     }
                // }
                //
                // if(inputRedesSociales[3].Activo) {
                //     if(!toolsHelper.validateURL(inputRedesSociales[3].Perfil)) {
                //         loader.close()
                //         swalAlertsService.showToast("error", "Debe escribir una dirección web válida para Youtube que ingreso sea una URL valida")
                //         return
                //     }
                // }
                //
                // if(inputRedesSociales[4].Activo) {
                //     if(!toolsHelper.validateURL(inputRedesSociales[4].Perfil)) {
                //         loader.close()
                //         swalAlertsService.showToast("error", "Debe escribir una dirección web válida para Instagram que ingreso sea una URL valida")
                //         return
                //     }
                // }
                //
                // if(inputRedesSociales[5].Activo) {
                //     if(!toolsHelper.validateURL(inputRedesSociales[5].Perfil)) {
                //         loader.close()
                //         swalAlertsService.showToast("error", "Debe escribir una dirección web válida para Spotify que ingreso sea una URL valida")
                //         return
                //     }
                // }

                user.RedesSociales[0] = inputRedesSociales[0]
                user.RedesSociales[1] = inputRedesSociales[1]
                user.RedesSociales[2] = inputRedesSociales[2]
                user.RedesSociales[3] = inputRedesSociales[3]
                user.RedesSociales[4] = inputRedesSociales[4]
                user.RedesSociales[5] = inputRedesSociales[5]

                let userSend = JSON.parse(JSON.stringify(user));

                if (userData.clv_estatusbm || (userData.clv_estatusIpade && userData.FechaIpade)) {
                    userSend['clv_estatusbm'] = userData.clv_estatusbm || null;
                    userSend['clv_estatusIpade'] = userData.clv_estatusIpade || null;
                    userSend['FechaIpade'] = userData.FechaIpade || null;
                }

                profileService.updateUser(userSend).then(
                    (response) => {
                        if (response && response.data) {
                            authenticationService.getUser().then(() => {
                                loader.close();
                                swalAlertsService.showToast("success", "Perfil actualizado exitosamente");
                                history.goBack();

                            })
                        } else {
                            loader.close();
                        }
                    }
                )
                    .catch(
                        (error) => {
                            loader.close()
                        }
                    )
            } else {
                loader.close()
                let text = "Falta por llenar los siguientes datos: \n"
                if (!user.Nombre) {
                    text = text + "-Nombre\n"
                }
                if (!user.ApellidoPaterno) {
                    text = text + "-ApellidoPaterno\n"
                }
                if (!user.clv_cargo) {
                    text = text + "-Puesto\n"
                }

                if (!user.clv_paistelefono) {
                    text = text + "-País residencia\n"
                }
                if (!user.clv_estadoresidencia) {
                    text = text + "-Estado residencia\n"
                }

                swalAlertsService.showToast("error", text)
            }
        } else {
            if (
                user.Nombre &&
                user.EmailPrivado !== undefined &&
                user.EmailPrivado !== null &&
                user.CelularPrivado !== undefined &&
                user.CelularPrivado !== null &&
                user.FechaNacimientoPrivado !== undefined &&
                user.FechaNacimientoPrivado !== null &&
                user.ApellidoPaterno &&
                user.clv_cargo
            ) {

                user.RedesSociales = []
                profileService.updateUser(user).then(
                    (response) => {
                        loader.close()
                        history.replace("/register-steps", { id: 0 })
                    },
                    (error) => {
                        loader.close()
                        swalAlertsService.showToast("error", "Ocurrio un error intentelo de nuevo más tarde")
                    }
                )
                    .catch(
                        (error) => {
                            loader.close()
                        }
                    )

            } else {
                let text = "Falta por llenar los siguientes datos: \n"
                if (!user.Nombre) {
                    text = text + "-Nombre\n"
                }
                if (!user.ApellidoPaterno) {
                    text = text + "-ApellidoPaterno\n"
                }
                if (!user.clv_cargo) {
                    text = text + "-Puesto\n"
                }
                swalAlertsService.showToast("error", text)
            }
        }



    }

    const onUniverlPickerHide = (data) => {
        setOpenUniversalPicker(data)
    }

    const onElementSelected = (element) => {
        switch (type) {
            case 'hobby':
                setUser({ ...user, "clv_aficion": element.clv_aficion, "Aficion": element.Aficion })
                break;
            case 'study':
                setUser({ ...user, "clv_nivelestudio": element.clv_nivelestudio, "NivelEstudio": element.NivelEstudio })
                break;
            case 'post':
                console.log(element.clv_cargo)
                setUser({ ...user, "Cargo": element.Cargo, "clv_cargo": element.clv_cargo })
                break;
            case 'phone':
                console.log(element)
                setUser({ ...user, "clv_paistelefono": element.clv_pais, "LadaTelefono": element.Lada })
                break;
            case 'nationality':
                console.log(element)
                setUser({ ...user, "clv_nacionalidad": element.clv_pais, "Nacionalidad": element.Nacionalidad })
                break;
            case 'country':
                setUser({ ...user, "clv_paistelefono": element.clv_pais, "PaisResidencia": element.Pais })
                break;
            case 'state':
                setUser({ ...user, "clv_estadoresidencia": element.clv_estado, "EstadoResidencia": element.Estado })
                break;
            default:
                break;
        }
    }

    const getUser = () => {
        profileService.getUser().then(
            (response) => {
                if (params.user_id !== "0") {
                    let obj = {
                        clv_estatusbm: response.data.clv_estatusbm === 1 ? null : response.data.clv_estatusbm,
                        clv_estatusIpade: response.data.clv_estatusIpade,
                        isBM: false,
                        isIpade: false,
                        FechaIpade: response.data.FechaIpade
                    }

                    if (obj.clv_estatusbm) {
                        obj.isBM = true;
                    }

                    if (obj.clv_estatusIpade) {
                        obj.isIpade = true;
                    }
                    setUserData(obj);
                    let data = response.data;

                    if (data.OfreceMentoria && data.clv_tipomentoria === 4 && data.MentoriaPersonalizado) {
                        data.Mentoria = data.MentoriaPersonalizado;
                    }
                    setUser(data);
                    setUserCopy(data);
                    data.RedesSociales.map((item, index) => {
                        switch (item.clv_redsocial) {
                            case 1:
                                setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[0].Perfil = item.Perfil, inputRedesSociales[0].Activo = true)
                                break;
                            case 2:
                                setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[1].Perfil = item.Perfil, inputRedesSociales[1].Activo = true)
                                break;
                            case 3:
                                setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[2].Perfil = item.Perfil, inputRedesSociales[2].Activo = true)
                                break;
                            case 4:
                                setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[3].Perfil = item.Perfil, inputRedesSociales[3].Activo = true)
                                break;
                            case 5:
                                setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[4].Perfil = item.Perfil, inputRedesSociales[4].Activo = true)
                                break;
                            case 6:
                                setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[5].Perfil = item.Perfil, inputRedesSociales[5].Activo = true)
                                break;

                            default:
                                break;
                        }
                    })
                } else {
                    if (isMounted) {
                        console.log('aqui');
                        setUser({ ...user, "Email": response.data.Email, "Celular": response.data.Celular, "CelularPrivado": false, "EmailPrivado": false, "LadaTelefono": "52", "clv_paistelefono": response.data.clv_paistelefono || 134, "FechaNacimiento": getMinAgeDate() })
                    }


                }

            },
            (error) => {
                console.log(error)
            }
        )
    }


    const setImageUser = async (data) => {
        imageData.base64 = data
        setVisibleCrop(true)
    }

    const cut = async (image) => {
        var uri = URL.createObjectURL(image.blob);
        setUser({ ...user, "PhotoUrl": uri })
        setVisibleCrop(false)
        var reader = new FileReader();
        reader.readAsDataURL(image.blob);
        reader.onloadend = async function () {
            let loader = swalAlertsService.createLoading("Espere un momento...")
            var data = reader.result;
            loader.fire()
            const resp = await profileService.updateImage(data.split(",")[1], 3, "jpg")
            resp.text().then(text => {
                setUser({ ...user, PhotoUrl: JSON.parse(text).message, FotoUser: true })
                loader.close()
            })
        }
    }

    const cancel = () => {
        setVisibleCrop(false)
    }

    const handleTakePhoto = (dataUri) => {

        if (openCamera) {
            setOpenCamera(false);
        }
        if (dataUri && dataUri.indexOf('base64,') >= 0) {
            const dataSplitted = dataUri.split(dataUri.substring(0, dataUri.indexOf('base64,') + 'base64,'.length));
            if (dataSplitted.length > 1) {
                setImageUser(dataUri)
            }
        }
    }

    const handleFile = (dataUri, extraData) => {

        if (dataUri && dataUri.indexOf('base64,') >= 0) {
            const dataSplitted = dataUri.split(dataUri.substring(0, dataUri.indexOf('base64,') + 'base64,'.length));

            if (dataSplitted.length > 1) {
                setImageData({
                    base64: dataUri,
                    splitted: dataSplitted[1]
                });
                console.log("entre")
                setImageUser()
            }
        }
    }

    async function getFile(event, type) {
        if (event.target && event.target.files && event.target.files.length) {
            const result = await toolsHelper.toBase64(event.target.files[0]).catch(e => console.log(e));

            if (result && type) {
                if (type === 'image') {
                    handleTakePhoto(result);
                } else {
                    handleFile(result, event.target.files[0]);
                }
            }
        }
    }

    const handleCameraError = (e) => {
        console.log(e);
        swalAlertsService.showToast('error', 'Error al intentar usar la camara');

        setTimeout(() => {
            setOpenCamera(false);
        }, 500);
    }

    const setBottom = (type) => {
        if (type === "state") {
            if (params.user_id !== "0") {
                if (user.clv_nacionalidad) {
                    setType(type)
                    setOpenUniversalPicker(true)
                } else {
                    swalAlertsService.showToast("error", "Debes elegir una nacionalidad")
                }
            } else {
                if (user.clv_paisresidencia) {
                    setType(type)
                    setOpenUniversalPicker(true)
                } else {
                    swalAlertsService.showToast("error", "Debes elegir un pais")
                }
            }
        } else {
            setType(type)
            setOpenUniversalPicker(true)
        }
    }

    const setInputForm = (type, e) => {
        setFechaFormat(false);
        let value = e.target.value;
        if (type === 'Celular' || type === 'Telefono') {
            value = e.target.value.replace(/\D/g, '');
        }
        setUser({ ...user, [type]: value })
    }

    const setInputFormRedesSociales = (type, e) => {
        switch (type) {
            case 1:
                if (e.target.value) {
                    setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[0].Perfil = e.target.value, inputRedesSociales[0].Activo = true)
                } else {
                    setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[0].Perfil = e.target.value, inputRedesSociales[0].Activo = false)
                }
                break;
            case 2:
                if (e.target.value) {
                    setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[1].Perfil = e.target.value, inputRedesSociales[1].Activo = true)
                } else {
                    setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[1].Perfil = e.target.value, inputRedesSociales[1].Activo = false)
                }
                break;
            case 3:
                if (e.target.value) {
                    setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[2].Perfil = e.target.value, inputRedesSociales[2].Activo = true)
                } else {
                    setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[2].Perfil = e.target.value, inputRedesSociales[2].Activo = false)
                }
                break;
            case 4:
                if (e.target.value) {
                    setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[3].Perfil = e.target.value, inputRedesSociales[3].Activo = true)
                } else {
                    setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[3].Perfil = e.target.value, inputRedesSociales[3].Activo = false)
                }
                break;
            case 5:
                if (e.target.value) {
                    setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[4].Perfil = e.target.value, inputRedesSociales[4].Activo = true)
                } else {
                    setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[4].Perfil = e.target.value, inputRedesSociales[4].Activo = false)
                }
                break;
            case 6:
                if (e.target.value) {
                    setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[5].Perfil = e.target.value, inputRedesSociales[5].Activo = true)
                } else {
                    setInputRedesSociales({ ...inputRedesSociales }, inputRedesSociales[5].Perfil = e.target.value, inputRedesSociales[5].Activo = false)
                }
                break;

            default:
                break;
        }
    }

    const setInputSwitch = (type, value) => {
        if (type === "Phone") {
            setCheckedPhone(!checkedPhone)
            setUser({ ...user, "CelularPrivado": value })
            return;
        }
        if (type === "Email") {
            setCheckedEmail(!checkedEmail)
            setUser({ ...user, "EmailPrivado": value })
            return;
        }

        if (type === "Birth") {
            setCheckedBirth(!checkedBirth)
            setUser({ ...user, "FechaNacimientoPrivado": value })
            return;
        }
    }

    const back = () => {

        if (user === userCopy) {
            history.goBack()
        } else {
            swalAlertsService.answer("¿Seguro que quieres salir?", "Si sales se perderan tus cambios", "Si, regresar").then((result) => {
                if (result.isConfirmed) {
                    history.goBack()
                }
            })
        }
    }

    const getStringWidth = (index) => {
        const fontSize = '16';
        let span = document.getElementById(index);
        if (span) {
            span['style']['fontSize'] = fontSize;
            return (span['clientWidth'] + 15) + "px";
        }
        return '0px';
    }

    const MyContainer = ({ className, children }) => {
        return (
            <div style={{ padding: "16px", background: "#216ba5", color: "#fff" }}>
                <CalendarContainer className={className}>
                    <div style={{ background: "#f0f0f0" }}>
                        What is your favorite day?
                    </div>
                    <div style={{ position: "relative" }}>{children}</div>
                </CalendarContainer>
            </div>
        );
    };

    const onBottomSheetDimiss = () => {
        setIsContactAdding(false);
    }

    const onContantSendBtnTapped = (data) => {
        console.log(data);
        setUser({ ...user, InvitadoPor: data.NombreCompleto, clv_usuarioInvitadoPor: data.clv_usuario });
        onBottomSheetDimiss();
        // if (data && data.length) {
        //     let obj = {
        //         Usuarios: data.map(el => { return {clv_usuario: el.clv_destinatario}})
        //     }
        //
        //     let loading = swalAlertsService.createLoading("Espere un momento...")
        //     loading.fire();
        //     messagesService.postAddMembersToGroup(groupState.clv_conversacion, obj).then((response) => {
        //         loading.close();
        //         if (response && response.data && response.data && response.message) {
        //             swalAlertsService.showToast('success', response.message);
        //             setGroupState(response.data);
        //             onBottomSheetDimissContactList();
        //             onChatUpdate();
        //         } else {
        //             swalAlertsService.showToast('error', 'Ocurrió un problema al intentar actualizar el grupo');
        //         }
        //     });
        // }
    }

    const getMentoryOptions = () => {
        if (mentoryTypes && mentoryTypes.length) {
            return mentoryTypes.map((mentory, index) => {
                return (
                    <div className="col-12 px-0 EditUser-form-option-container" key={index}>
                        <div className="row m-0">
                            <div className="col-12 px-0">
                                <button className={`mainBtn ${user.clv_tipomentoria && user.clv_tipomentoria === mentory.clv_tipomentoria ? 'active' : ''}`} onClick={
                                    (e) => {
                                        if (mentory.clv_tipomentoria !== 4) {
                                            setUser({ ...user, clv_tipomentoria: mentory.clv_tipomentoria, Mentoria: mentory.Mentoria });
                                        } else {
                                            setUser({ ...user, clv_tipomentoria: mentory.clv_tipomentoria, Mentoria: null });
                                        }
                                    }}>
                                    <span className='text-truncate'>{mentory.Mentoria}</span> <img src={icnsTogglers} alt="toggler icon" className='ss isBullet' width='30' height='30' />
                                </button>
                            </div>
                        </div>
                    </div>
                );
            });
        }
        return null;
    }

    const onBtnOptionsPick = (type) => {

        console.log(user.clv_estatusbm);
        let userDataCP = JSON.parse(JSON.stringify(userData));
        if (type === 'bm') {
            userDataCP.isBM = !userDataCP.isBM;
            userDataCP.clv_estatusbm = 1;
        } else if (type === 'no') {
            userDataCP.isBM = false;
            userDataCP.isIpade = false;
            userDataCP.clv_estatusbm = 1;
            userDataCP.clv_estatusIpade = null;
            userDataCP.FechaIpade = null;
        }

        if (type === 'ipade') {
            userDataCP.isIpade = !userDataCP.isIpade;
        }

        setUserData(userDataCP);


    }

    const bmTypePicker = (type) => {
        if (user.clv_estatusbm != 4 && user.clv_estatusbm != 2) {
            if (type) {
                // if(userData.clv_estatusbm === 4){
                //     swalAlertsService.showToast("success", "Debe catálogo de programa de IPADE");
                // }
                let userDataCP = JSON.parse(JSON.stringify(userData));
                userDataCP.clv_estatusbm = type === 4 ? 4 : 3;
                setUserData(userDataCP);
            }
        }

    }

    const onIpadeOptionPicked = (value) => {
        if (value) {
            let userDataCP = JSON.parse(JSON.stringify(userData));
            userDataCP.clv_estatusIpade = value;
            setUserData(userDataCP);
        }
    }

    const onIpadeDateSelected = (e) => {
        let userDataCP = JSON.parse(JSON.stringify(userData));
        userDataCP.FechaIpade = e.target.value;
        setUserData(userDataCP);
    }

    const ipadeDateBuilder = () => {
        let items = [];
        let now = new Date();
        const actualYear = now.getFullYear();
        const fundationDate = 1967;

        for (let i = fundationDate; i <= actualYear; i++) {
            items.push(<option value={i}>{i}</option>);
        }

        return items;
    }

    return (
        <React.Fragment>
            {
                !visibleCrop ?
                    (
                        <div className="container_edit_user">
                            <EditUserHeader save={saveUser} back={back} value={params.user_id} />
                            <div className="container_edit_user_photo">
                                <img src={user.PhotoUrl != null ? user.PhotoUrl : placeholderuser} 
                                    className="imageUser" 
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = placeholderuser
                                    }}
                                />
                                <input
                                    id="image"
                                    type="file"
                                    ref={inputImage}
                                    onChange={event => getFile(event, 'image')}
                                    onError={error => console.log(error)}
                                    accept="image/png, image/gif, image/jpeg"
                                    multiple={false}
                                    name="image"
                                />
                                <label htmlFor="image">Cambiar foto de perfil</label>
                            </div>

                            <div className="container_edit_user_input">
                                <p>Correo electrónico</p>
                                <div className="container_edit_user_input_field" >
                                    <img src={email} />
                                    <input type="text" disabled={true} value={user.Email} onChange={e => setInputForm("Email", e)} />
                                </div>
                                <div className="container_edit_user_input_switch">
                                    <p>{!user.EmailPrivado ? "Es visible en tu perfil" : "Está oculto en tu perfil"}</p>
                                    <label className="switch">
                                        <input type="checkbox" checked={user.EmailPrivado} onChange={() => setInputSwitch("Email", !checkedEmail)} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="container_edit_user_input">
                                <p>Teléfono</p>
                                <div className="container_edit_user_input_field" >
                                    <img src={phone} />
                                    <input type="text" disabled={true} value={user.Celular} onChange={e => setInputForm("Celular", e)} />
                                </div>
                                <div className="container_edit_user_input_switch">
                                    <p>{!user.CelularPrivado ? "Es visible en tu perfil" : "Está oculto en tu perfil"}</p>
                                    <label className="switch">
                                        <input type="checkbox" checked={user.CelularPrivado} onChange={() => setInputSwitch("Phone", !checkedPhone)} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="container_edit_user_input">
                                <p>Nombre</p>
                                <div className="container_edit_user_input_field" >
                                    <img src={users} />
                                    <input type="text" value={user.Nombre} onChange={e => setInputForm("Nombre", e)} />
                                </div>
                            </div>

                            <div className="container_edit_user_input">
                                <p>Apellido Paterno</p>
                                <div className="container_edit_user_input_field" >
                                    <img src={users} />
                                    <input type="text" value={user.ApellidoPaterno} onChange={e => setInputForm("ApellidoPaterno", e)} />
                                </div>
                            </div>

                            <div className="container_edit_user_input">
                                <p>Apellido Materno</p>
                                <div className="container_edit_user_input_field" >
                                    <img src={users} />
                                    <input type="text" value={user.ApellidoMaterno} onChange={e => setInputForm("ApellidoMaterno", e)} />
                                </div>
                            </div>

                            <div className="container_edit_user_gender">
                                <p>Elige tu género</p>
                                <div className="container_edit_user_gender_container">
                                    <div className="container_edit_user_gender_container_option">
                                        <div className={`container_edit_user_gender_container_option_field h ${user.clv_genero === 2 ? 'active' : ''}`}>
                                            <button className="field-btn" onClick={() => { setUser({ ...user, clv_genero: 2 }) }}>Hombre</button>
                                        </div>
                                    </div>
                                    <div className="container_edit_user_gender_container_option">
                                        <div className={`container_edit_user_gender_container_option_field m ${user.clv_genero === 1 ? 'active' : ''}`}>
                                            <button className="field-btn" onClick={() => { setUser({ ...user, clv_genero: 1 }) }}>Mujer</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container_edit_user_input">
                                <p>Cuando es tu cumpleaños</p>
                                <div className="container_edit_user_input_field" >
                                    <img src={birthday} className="left datepicker-icn" />
                                    <DatePicker
                                        selected={user.FechaNacimiento ? new Date(user.FechaNacimiento) : getMinAgeDate()}
                                        onChange={(date) => setUser({ ...user, "FechaNacimiento": date })}
                                        dateFormat="dd-MM-yyy"
                                        maxDate={getMinAgeDate()}
                                        showYearDropdown={true}
                                        showMonthDropdown={true}
                                        disabledKeyboardNavigation={true}
                                        locale="es"
                                        dropdownMode='select' />

                                </div>
                                <div className="container_edit_user_input_switch">
                                    <p>{!user.FechaNacimientoPrivado ? "Es visible en tu perfil" : "Está oculto en tu perfil"}</p>
                                    <label className="switch">
                                        <input type="checkbox" checked={user.FechaNacimientoPrivado} onChange={() => setInputSwitch("Birth", !checkedBirth)} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="container_edit_user_input">
                                <p>¿Tienes alguna afición?</p>
                                <div className="container_edit_user_input_field" >
                                    <img src={degree} className="left aficion" />
                                    <button className={`field-btn ${user.Aficion != "" ? 'black' : ''}`} onClick={() => setBottom("hobby")}>{user.Aficion != null ? user.Aficion : "Selecciona una opción"}</button>
                                    <img src={more} className="right" />
                                </div>
                            </div>

                            <div className="container_edit_user_input">
                                <p>Último grado de estudios</p>
                                <div className="container_edit_user_input_field" >
                                    <img src={study} className="left" />
                                    <button className={`field-btn ${user.NivelEstudio != "" ? 'black' : ''}`} onClick={() => setBottom("study")}>{user.NivelEstudio != null ? user.NivelEstudio : "Selecciona una opción"}</button>
                                    <img src={more} className="right" />
                                </div>
                            </div>
                            {
                                // <div className="container_edit_user_gender">
                                //      <p>¿Eres miembro de BM Digital?</p>
                                //      <div className="container_edit_user_gender_container">
                                //          <div className="container_edit_user_gender_container_option">
                                //              <div className={`container_edit_user_gender_container_option_field member ${user.clv_estatusbm === 2 ? 'active' : ''}`}>
                                //                  <button onClick={() => {setUser({...user, "clv_estatusbm": 2})}}>Si</button>
                                //              </div>
                                //          </div>
                                //          <div className="container_edit_user_gender_container_option">
                                //              <div className={`container_edit_user_gender_container_option_field  member ${user.clv_estatusbm === 1 ? 'active' : ''}`}>
                                //                  <button onClick={() => {setUser({...user, "clv_estatusbm": 1})}}>No</button>
                                //              </div>
                                //          </div>
                                //          <div className="container_edit_user_gender_container_option">
                                //              <div className={`container_edit_user_gender_container_option_field member ${user.clv_estatusbm === 3 ? 'active' : ''}`}>
                                //                  <button onClick={() => {setUser({...user, "clv_estatusbm": 3})}}>Ex Miembro</button>
                                //              </div>
                                //          </div>
                                //      </div>
                                //  </div>
                            }

                            <div className="row m-0 EditUser-form-container">
                                <div className="col-12 px-0 EditUser-form-disclaimer">
                                    <div className="row m-0">
                                        <div className="col-12 px-0 EditUser-form-disclaimer-subtitle">
                                            ¿Perteneces o perteneciste por algún tiempo a alguna asociación? *
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 px-0 EditUser-form-option-container">
                                    <div className="row m-0">
                                        <div className="col-12 px-0">
                                            <button className={`mainBtn ${!userData.isBM && !userData.isIpade ? 'active' : ''}`} onClick={() => onBtnOptionsPick('no')}>
                                                <span className='text-truncate'>Ninguna</span> <img src={icnsTogglers} alt="toggler icon" className='ss' width='30' height='30' />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 px-0 EditUser-form-option-container">
                                    <div className="row m-0">
                                        <div className="col-12 px-0">
                                            <button className={`mainBtn ${userData.isBM ? 'active' : ''}`} onClick={e => onBtnOptionsPick('bm')}>
                                                <span className='text-truncate'>BoardMedia Digital</span> <img src={icnsTogglers} alt="toggler icon" className='ss' width='30' height='30' />
                                            </button>
                                        </div>

                                        {
                                            userData.isBM ? (
                                                <div className="col-12 px-0 EditUser-form-suboptions-container pb-0">
                                                    <div className="row m-0">
                                                        <div className="col-12 px-0 subOptionsTitle">
                                                            Elige una opción:
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="row m-0 d-flex justify-content-center btns-Options">
                                                                {
                                                                    userData.clv_estatusbm != 4 ?
                                                                        <>
                                                                            <button className={`col left ${userData.clv_estatusbm === 2 ? 'active' : ''}`} onClick={e => bmTypePicker(4)}>Miembro<br />activo</button>
                                                                            <button className={`col right ${userData.clv_estatusbm === 3 ? 'active' : ''}`} onClick={e => bmTypePicker(3)}>Ex miembro</button>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <button className={`col ${userData.clv_estatusbm === 4 ? 'active' : ''}`} style={{maxWidth: '100%'}} onClick={e => bmTypePicker(4)}>Pendiente</button>
                                                                        </>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (null)
                                        }

                                        <div className="col-12 px-0 EditUser-form-suboptions-container py-1">
                                            <div className="row m-0">
                                                <div className="col-12 px-0 linkBox pt-0">
                                                    <a href="https://www.bmdigital.mx" target='_blank'>www.bmdigital.mx</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 px-0 EditUser-form-option-container">
                                    <div className="row m-0">
                                        <div className="col-12 px-0">
                                            <button className={`mainBtn ${userData.isIpade ? 'active' : ''}`} onClick={e => onBtnOptionsPick('ipade')}>
                                                <span className='text-truncate'>IPADE</span> <img src={icnsTogglers} alt="toggler icon" className='ss' width='30' height='30' />
                                            </button>
                                        </div>

                                        {
                                            userData.isIpade ? (
                                                <div className="col-12 px-0 EditUser-form-suboptions-container pb-0">
                                                    <div className="row m-0">
                                                        <div className="col-12 px-0 subOptionsTitle">
                                                            Catálogo de programa:
                                                        </div>

                                                        <div className="col-12 px-0">
                                                            <div className="row m-0 d-flex justify-content-center list-Options">
                                                                <div className={`col-12 px-0 listOption isFirst ${userData.clv_estatusIpade === 1 ? 'isActive' : ''}`} onClick={e => onIpadeOptionPicked(1)}>
                                                                    <span>Alta Dirección</span>
                                                                    <img src={icnsTogglers} alt="toggler icon" width='30' height='30' />
                                                                </div>

                                                                <div className={`col-12 px-0 listOption ${userData.clv_estatusIpade === 2 ? 'isActive' : ''}`} onClick={e => onIpadeOptionPicked(2)}>
                                                                    <span>Maestría</span>
                                                                    <img src={icnsTogglers} alt="toggler icon" width='30' height='30' />
                                                                </div>

                                                                <div className={`col-12 px-0 listOption ${userData.clv_estatusIpade === 3 ? 'isActive' : ''}`} onClick={e => onIpadeOptionPicked(3)}>
                                                                    <span>Programa Temático</span>
                                                                    <img src={icnsTogglers} alt="toggler icon" width='30' height='30' />
                                                                </div>

                                                                <div className={`col-12 px-0 listOption ${userData.clv_estatusIpade === 4 ? 'isActive' : ''}`} onClick={e => onIpadeOptionPicked(4)}>
                                                                    <span>Profesor</span>
                                                                    <img src={icnsTogglers} alt="toggler icon" width='30' height='30' />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 px-0">
                                                            <div className="row m-0">
                                                                <div className="col-12 px-0 selectOptions-container">
                                                                    <label htmlFor="ipadeDate">Fecha:</label>
                                                                    <select name="ipadeDate" id="ipadeDate" value={userData.FechaIpade} onChange={onIpadeDateSelected}>
                                                                        <option value="" selected disabled={true}>Seleccionar año</option>
                                                                        {ipadeDateBuilder()}
                                                                    </select>
                                                                    <img src={icnArrowDown} alt="arrow down" width='30' height='30' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (null)
                                        }

                                        <div className="col-12 px-0 EditUser-form-suboptions-container py-1">
                                            <div className="row m-0">
                                                <div className="col-12 px-0 linkBox pt-0">
                                                    <a href="https://www.ipade.mx/" target='_blank'>www.ipade.mx</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="container_edit_user_input">
                                <p>Puesto actual que desempeñas</p>
                                <div className="container_edit_user_input_field" >
                                    <img src={position} className="left" />
                                    <button className={`field-btn ${user.Cargo != "" ? 'black' : ''}`} onClick={() => setBottom("post")}>{user.Cargo != null ? user.Cargo : "Selecciona una opción"}</button>
                                    <img src={more} className="right" />
                                </div>
                            </div>

                            <div className="row m-0 container-lada">
                                <p>Teléfono de oficina</p>
                                <div className={`col-12 Login-input-box-lada`}>
                                    <div className="row m-0 d-flex align-items-center">
                                        <div className="col p-0 lada-btn-box">
                                            <button className={user.LadaTelefono != "" ? 'black' : ''} onClick={() => setBottom("phone")}>{user.LadaTelefono != null ? "+" + user.LadaTelefono : "+"}</button>
                                        </div>
                                        <div className="col p-0 input-box">
                                            <input type="text"
                                                className='ladded'
                                                id='phone'
                                                name='phone'
                                                maxLength='10'
                                                value={user.Telefono}
                                                onChange={e => setInputForm("Telefono", e)} />
                                        </div>
                                    </div>
                                </div>
                                {
                                }
                            </div>

                            <div className="container_edit_user_input">
                                <p>Nacionalidad</p>
                                <div className="container_edit_user_input_field" >
                                    <button className={`field-btn ${user.Nacionalidad != "" ? 'black' : ''} button`} onClick={() => setBottom("nationality")}>{user.Nacionalidad != null ? user.Nacionalidad : "Selecciona una opción"}</button>
                                    <img src={more} className="right" />
                                </div>
                            </div>

                            <div className="container_edit_user_input">
                                <p>País de residencia</p>
                                <div className="container_edit_user_input_field" >
                                    <button className={`field-btn ${user.Nacionalidad != "" ? 'black' : ''} button`} onClick={() => setBottom("country")}>{user.PaisResidencia != null ? user.PaisResidencia : "Selecciona una opción"}</button>
                                    <img src={more} className="right" />
                                </div>
                            </div>

                            <div className="container_edit_user_input">
                                <p>Estado</p>
                                <div className="container_edit_user_input_field" >
                                    <button className={`field-btn ${user.Nacionalidad != "" ? 'black' : ''} button`} onClick={() => setBottom("state")}>{user.EstadoResidencia != null ? user.EstadoResidencia : "Selecciona una opción"}</button>
                                    <img src={more} className="right" />
                                </div>
                            </div>

                            {/* <div className="container_edit_user_input">
                        <p>Invitado por:</p>
                        {
                            // <div className="container_edit_user_input_field" >
                            //     <input type="text"  className="p-left" value={user.InvitadoPor} onChange={e => setInputForm("InvitadoPor",e)} placeholder="Nombre de la persona"/>
                            // </div>
                        }
                        <div className="container_edit_user_input_field" >
                           <button className={`field-btn ${user.InvitadoPor != "" ? 'black' : ''} button`} onClick={() => setIsContactAdding(true)}>{user.InvitadoPor != null ? user.InvitadoPor : "Elige un usuario"}</button>
                           <img src={more} className="right"/>
                        </div>
                    </div> */}

                            <div className="container_edit_user_input fullRow">
                                <p className='titleP'>Mentoría</p>

                                <p className='subtitleP'>¿Podrías ofrecer una sesión de Mentoria algún miembro WeNet ?</p>

                                <div className="container_edit_user_gender_container blue">
                                    <div className="container_edit_user_gender_container_option">
                                        <div className={`container_edit_user_gender_container_option_field h ${!user.OfreceMentoria ? 'active' : ''}`}>
                                            <button className="field-btn blue" onClick={() => { setUser({ ...user, OfreceMentoria: false }) }}>No</button>
                                        </div>
                                    </div>
                                    <div className="container_edit_user_gender_container_option">
                                        <div className={`container_edit_user_gender_container_option_field m  ${user.OfreceMentoria ? 'active' : ''}`}>
                                            <button className="field-btn blue" onClick={() => { setUser({ ...user, OfreceMentoria: true }) }}>Si</button>
                                        </div>
                                    </div>
                                </div>

                                {
                                    user.OfreceMentoria ? (
                                        <>
                                            <p className='subtitleP'>¿Sobre que tema?</p>


                                            <div className="row m-0 EditUser-form-container pt-0">
                                                {getMentoryOptions()}
                                            </div>

                                            {
                                                user.clv_tipomentoria === 4 ? (
                                                    <>
                                                        <p className='subtitleP'>¿Qué tema?</p>

                                                        <div className="container_edit_user_input_field fullCol" >
                                                            <input type="text" value={user.Mentoria} onChange={e => setInputForm("Mentoria", e)} className='noIcon' placeholder='Escribir tema' />
                                                        </div>
                                                    </>
                                                ) : (null)
                                            }
                                        </>
                                    ) : (null)
                                }
                            </div>

                            <div>
                                <button className="btn-save" onClick={() => saveUser(true)}>Guardar</button>
                            </div>


                        </div>
                    )
                    : (null)
            }

            {
                openUniversalPicker ? (
                    <UniversalPicker mode={type} country={user.PaisResidencia} onDismiss={onUniverlPickerHide} onElementSelected={onElementSelected} />
                ) : (null)
            }
            {
                visibleCrop &&
                <ImageCrop src={imageData.base64} cutCrop={cut} cancelCrop={cancel} text={""} />
            }

            {
                isContactAdding &&
                <ContactList currentUser={props.currentUser}
                    onBottomSheetDimiss={onBottomSheetDimiss}
                    onInvitationAdded={onContantSendBtnTapped}
                    isGroupAdding={false}
                    groupPreviusUser={[]}
                    isInvitation={true}
                />
            }
        </React.Fragment>
    )
}

export { EditUser }
