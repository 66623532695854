import React, { useState, useEffect } from 'react';

import { toolsHelper } from '../../../../../_resources/helpers';

import './ProfileNetworkInfo.scss';

import icnEditGreen from '../../../../../_assets/media/images/icons/icn_edit_green.png';
import icnsNetworks from '../../../../../_assets/media/images/icons/icns_networks.png';

const ProfileNetworkInfo = (props) => {

    const onEditBtnTapped = () => {
        if (props.onEditBtnTapped) {
            props.onEditBtnTapped();
        }
    }

    const getFixedUrl = (item) => {
        if (item && item.RedSocial && item.Perfil) {

            if(!toolsHelper.validateURL(item.Perfil)) {
                switch (item.RedSocial) {
                    case 'Facebook':
                        return `https://facebook.com/${item.Perfil}`;
                    break;
                    case 'Twitter':
                        return `https://twitter.com/${item.Perfil}`;
                    break;
                    case 'LinkedIn':
                        return `https://linkedin.com/in/${item.Perfil}`;
                    break;
                    case 'YouTube':
                        return `https://www.youtube.com/results?search_query=${item.Perfil}`;
                    break;
                    case 'Instagram':
                        return `https://instagram.com/${item.Perfil}`;
                    break;
                    case 'Spotify':
                        return `https://spotify.com/${item.Perfil}`;
                    break;
                }
            }
            return item.Perfil;
        }
        return null;
    }

    return (
        <div className="ProfileNetworkInfo-container row h-100">
            <div className="col-12 p-0">
                <div className="row m-0">
                    <div className="col-12 px-0 ProfileNetworkInfo-header">
                        <span className='titleSpan'>Sígueme en:</span>
                        <button className='btn-cleared' onClick={onEditBtnTapped}>
                            <span>{
                                props.user &&
                                props.user.RedesSociales &&
                                props.user.RedesSociales.length ?
                                ('Editar'):('Agrega')
                            }</span>
                            <img src={icnEditGreen} alt="icnEditGreen" width='40' height='40'/>
                        </button>
                    </div>
                </div>

                {
                    props.user &&
                    props.user.RedesSociales &&
                    props.user.RedesSociales.length ?
                    props.user.RedesSociales.map((item, index) => {

                        if (item.RedSocial !== 'Spotify') {
                            return (
                                <a href={getFixedUrl(item)} target="_blank" rel="noreferrer" key={index}>
                                    <div className="row m-0">
                                        <div className="col-12 mouse-pointer ProfileNetworkInfo-item">
                                            <img src={icnsNetworks} alt="" className={item.RedSocial}/>
                                            <div className='text-block-truncate-1 redName'>
                                                { item.RedSocial === 'Facebook' || item.RedSocial === 'LinkedIn' ? '/':'@'}{item.Perfil}
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            )
                        } else {
                            return (
                                <div className="row m-0" key={index}>
                                    <div className="col-12 mouse-pointer ProfileNetworkInfo-item">
                                        <img src={icnsNetworks} alt="" className={item.RedSocial}/>
                                        <div className='text-block-truncate-1 redName'>
                                            {item.Perfil}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    }):(
                        <div className="row m-0">
                            <div className="col-12 placeholderTxt py-4 font-size-18px">
                                Sin redes sociales
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export { ProfileNetworkInfo }
