import React, { useState, useEffect } from 'react';
import Linkify from 'react-linkify';

import { profileService } from '../../../../_resources/services';

import './CompanySheet.scss';

import icnCompanyPlaceholder from '../../../../_assets/media/images/icons/icn_company_placeholder.png';
import icnBMStar from '../../../../_assets/media/images/icons/icn_bm_star.png';
import icnsSheets from '../../../../_assets/media/images/icons/icns_sheets.png';

const CompanySheet = (props) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        let isSuscribed = true;

        profileService.getUser().then(
            (response) => {
                if (isSuscribed && response && response.data) {
                    setUser(response.data);
                }
            },
            (error) => {
                console.log(error)
            }
        );

        return () => isSuscribed = false
    }, []);

    return (
        <div className="CompanySheet-container row m-0 d-flex justify-content-end">
            <div className="col-12 Company-header-container p-0">
                <div className="row m-0">
                    <div className="col Company-logo-container">
                        <img src={user && user.LogoThumbnailUrl ? user.LogoThumbnailUrl:icnCompanyPlaceholder} 
                            alt="icnCompanyPlaceholder"
                                onError={(e)=>{
                                    e.target.onerror = null;
                                    e.target.src=icnCompanyPlaceholder
                                }}
                            />
                    </div>
                    <div className="col Company-main-details pe-0">
                        <div className="row m-0">
                            <div className="col-12 px-0 title text-truncate mouse-default">{user && user.EmpresaNombreCorto ? user.EmpresaNombreCorto:''}</div>
                            <div className="col-12 px-0 subtitle text-truncate mouse-default">{user && user.Giro ? user.Giro:''}</div>
                            <div className="col-12 px-0 subtitle text-truncate mouse-default">{user && user.Clasificacion ? user.Clasificacion:''}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 px-0 CompanySheet-description-container">
                <div className="row m-0">
                    <div className="col-12 px-0 CompanySheet-description-title mouse-default">{user && user.Empresa ? user.Empresa:''}</div>
                    <div className="col-12 CompanySheet-description-content px-0 mouse-default">{user && user.Descripcion ? user.Descripcion:''}</div>
                </div>

            </div>

            <div className="col-12 px-0 pt-3 CompanySheet-details-container">
                <div className="row m-0 d-flex align-items-center">
                    <div className="col p-0 icnBox">
                        <img src={icnsSheets} alt="icnsSheets" className='pos-6'/>
                    </div>
                    <div className="txtBox col pe-0 text-truncate mouse-pointer">
                        {user && user.URL ? (
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a className='' target="blank" href={decoratedHref} key={key} style={{color: '#0fb855', textDecoration: 'none'}}>
                                    {decoratedText}
                                </a>
                            )}>
                                {  user.URL  }
                            </Linkify>)
                        :(<span className='placeholderTxt'>Sin página web</span>)}
                    </div>
                </div>

                <div className="row m-0 d-flex align-items-center pt-1">
                    <div className="col p-0 icnBox">
                        <img src={icnsSheets} alt="icnsSheets" className='pos-2'/>
                    </div>
                    <div className="txtBox col pe-0 text-truncate font-color-green mouse-pointer">
                        {
                            user && user.Telefono ? (
                                <a href={`tel://${user.Telefono}`} className='font-color-green'>
                                    {user.Telefono}
                                </a>
                            ):(<span className='placeholderTxt'>Sin Teléfono</span>)
                        }
                    </div>
                </div>

                <div className="row m-0 d-flex align-items-center pt-1">
                    <div className="col p-0 icnBox">
                        <img src={icnsSheets} alt="icnsSheets" className='pos-4'/>
                    </div>
                    {
                        user ? (
                            <>
                                {
                                    !user.Estado && !user.Pais ? (
                                        <div className="txtBox col pe-0 text-truncate mouse-default placeholderTxt">Sin ubicación</div>
                                    ):(
                                        <div className="txtBox col pe-0 text-truncate mouse-default">{user ? `${user.Estado ? user.Estado:''}${user.Estado && user.Pais ? ', ':''}${user.Pais ? user.Pais:''}`:(<span className='placeholderTxt'>Sin ubicación</span>)}</div>
                                    )

                                }
                            </>
                        ):(<span className='placeholderTxt'>Sin ubicación</span>)
                    }

                </div>

                <div className="row m-0 d-flex align-items-center pt-1">
                    <div className="col p-0 icnBox">
                        <img src={icnsSheets} alt="icnsSheets" className='pos-7'/>
                    </div>
                    <div className="txtBox col pe-0 text-truncate mouse-default">Fundada en {user && user.Fundacion ? `${new Date(user.Fundacion).getFullYear().toString()}`:''}</div>
                </div>



                <div className="row m-0 d-flex align-items-center pt-1">
                    <div className="col p-0 icnBox">
                        <img src={icnsSheets} alt="icnsSheets" className='pos-8'/>
                    </div>
                    <div className="txtBox col pe-0 text-truncate mouse-default">{user && user.RangoEmpleados ? `${user.RangoEmpleados} empleados`:(<span className='placeholderTxt'>Sin Rango de empleados</span>)}</div>
                </div>

            </div>

            {
                user && (user.Tag1 || user.Tag2) &&
                <div className="col-12 px-0 CompanySheet-specialities-container">
                    <div className="row m-0">
                        <div className="col-12 px-0 CompanySheet-specialities-title">Especialidades</div>
                        <div className="col-12 px-0 CompanySheet-specialities-options">
                            {
                                user && user.Tag1 &&
                                <button className="btn-cleared me-2">{user.Tag1}</button>
                            }
                            {
                                user && user.Tag2 &&
                                <button className="btn-cleared">{user.Tag2}</button>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export { CompanySheet };
