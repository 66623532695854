import React, { useState, useEffect } from 'react';
import "./Notifications.scss"
import pass from '../../../../_assets/media/images/icons/icn_pass.png'
import back from '../../../../_assets/media/images/icons/icn_back_arrow.png'
import { authenticationService, swalAlertsService, profileService } from '../../../../_resources/services';
import { settingService } from '../../../../_resources/services/settings.service';
import { useHistory } from 'react-router-dom';

const Notifications = (props) => {

    const history = useHistory()
    const [user, setUser] = useState(null); 

    // const [data, setData] = useState({
    //     "OldPassword": "",
    //     "NewPassword": "",
    //     "ConfirmPassword": ""
    // })

    // const setInputForm = (type, e) => {
    //     setData({...data, [type]: e.target.value})
    // }

    useEffect(() => {
        if (props.onToggleTab) {
            props.onToggleTab(true);
        }
        getUser();
    }, []);

    const getUser = () => {
        authenticationService.getUser().then(
            (response) => {
                if (response && response.data) {
                    setUser(response.data);
                }
                
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const onSwitchToggle = (index) => {
        if (!user || !user.TiposNotificacion || !user.TiposNotificacion.length || !user.TiposNotificacion[index]) {
            return;
        }

        let userCP = JSON.parse(JSON.stringify(user));

        userCP.TiposNotificacion[index].Activo = !userCP.TiposNotificacion[index].Activo;
        
        const objSend = { TiposNotificacion: [userCP.TiposNotificacion[index]]};
        
        let loader = swalAlertsService.createLoading("Espere un momento...");
        loader.fire();
        profileService.postGeneric('user/notificationtypes', 'POST', objSend).then(
            (response) => {
                loader.close();
                if (response && response.data) {
                    setUser(response.data);
                }
            },
            (error) => {
                loader.close();
                swalAlertsService.showToast('error', 'No pudimos enviar la información de usuario');
            }
        )
    }

    const renderNotificationsItems = () => {

        if (user && user.TiposNotificacion && user.TiposNotificacion.length) {
            return user.TiposNotificacion.map((el, index) => {
                return (
                    <div className="col-12 notification-item" key={index}>
                        <div className="row m-0">
                            <div className="col ps-0">

                                <div className="row m-0">
                                    <div className="col-12 px-0 item-title">
                                        {el.Titulo}
                                    </div>
                                    <div className="col-12 px-0 item-description">
                                        {el.Descripcion}
                                    </div>
                                </div>
                            </div>
                            <div className="col pe-0 switch-box d-flex align-items-center">
                                <label className="switch">
                                <input type="checkbox" checked={el.Activo} onChange={() => onSwitchToggle(index)}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                )
            });
        }

        return null;
    }

    return (
        <div className={`container-fluid px-0 container-notifications ${props.isDesktop ? 'isDesktop':''}`}>
            <div className="container-header row m-0">
                <div className="col-12 p-0 d-flex align-items-center justify-content-center pos-relative title-col">
                    {
                        !props.isDesktop ? (
                            <img src={back} width="30px" height="30px" className="back-img" onClick={() => history.goBack()}/>
                        ):(null)
                    }
                    <h3 className='m-0'>Administrar notificaciones</h3> 
                </div>
            </div>

            <div className="row m-0 container-subheader">
                <div className="col-12 sub-text">
                    Aquí puedes prender o apagar que notificaciones deseas recibir en tu aplicación de Wenet
                </div>
            </div>
            <div className="row m-0 notifications-container">
                {renderNotificationsItems()}
            </div>

        </div>
    );
}

export { Notifications };
