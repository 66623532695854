import React, { useState, useEffect } from 'react';
import back from '../../../../../_assets/media/images/icons/icn_back_arrow.png'
import visa from '../../../../../_assets/media/images/icons/img_visa.png'
import master from '../../../../../_assets/media/images/icons/img_mastercard.png'
import amx from '../../../../../_assets/media/images/icons/img_amex.png'

import icnDiscout from '../../../../../_assets/media/images/icons/icn_discount_modal.png';

import './Method.scss'
import { useHistory } from 'react-router-dom';

import { DiscountCode } from '../../../../shared/';


const Method = (props) => {

    const history = useHistory();
    const [showDiscountBottomSheet, setShowDiscountBottomSheet] = useState(false);

    const onDiscountBottomSheetDismiss = () => {
        setShowDiscountBottomSheet(false);
    }

    const onCodeSuccess = () => {
        history.replace("/register-steps");
    }

    const showDiscountModal = () => {
        setShowDiscountBottomSheet(true);
    }

    return (
        <React.Fragment>
             <div className="container-method bg-white">
                <div className="container_header">
                    <img src={back} width="30px" height="30px" onClick={() => {props.setView({"type": true,"method": false, "card": false, "detail": false})}}/>
                    <h3>Método de pago</h3>
                </div>

                <p className="container_mensaje">Elige un metodo de pago para realizar tu suscripción</p>

                <div className="padding">
                    <div className="container_card mouse-pointer" onClick={() => {props.setView({"method": false, "card": true, "detail": false})}}>
                        <div className="container_card_cards">
                            <img src={visa} width="50px" height="35px"/>
                            <img src={master} width="50px" height="35px"/>
                            <img src={amx} width="50px" height="35px"/>
                        </div>
                        <p className="container_card_footer">Agrega una Tarjeta de crédito / débito</p>
                    </div>
                </div>


                <div className="row m-0 pt-1">
                    <div className="col-12 px-0 Method-item-container pt-4">

                        <div className="padding">
                            <div className="container_card mouse-pointer" onClick={showDiscountModal}>
                                <div className="container_card_cards">
                                    <img src={icnDiscout} alt="icnDiscout" width='106' height='61'/>
                                </div>
                                <p className="container_card_footer">¿Tienes un código?</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {
                showDiscountBottomSheet &&
                <DiscountCode
                    onDismiss={onDiscountBottomSheetDismiss}
                    onCodeSuccess={onCodeSuccess}
                />
            }
        </React.Fragment>
    );
}

export {Method};
