import React, { useState, useEffect } from 'react';

import './DirectoryDesktopHeader.scss';

import icnDeleteFilter from '../../../../../_assets/media/images/icons/icn_delete_filter_red.png';

const DirectoryDesktopHeader = (props) => {
    const [activeTab, setActiveTab] = useState('users');
    const [userLabel, setUserLabel] = useState('');
    const [companiesLabel, setCompaniesLabel] = useState('');
    const [mentorsLabel, setMentorsLabel] = useState('');



    useEffect(() => {
        setUserLabel(getTabsTitle('users'));
        setCompaniesLabel(getTabsTitle('companies'));
        setMentorsLabel(getTabsTitle('mentors'))
    }, [])


    useEffect(() => {
        setUserLabel(getTabsTitle('users'));
        setCompaniesLabel(getTabsTitle('companies'));
        setMentorsLabel(getTabsTitle('mentors'))
    }, [props])

    

    const unique = (users) => {
        var uniquePeople = [];
        if (users && users.length) {
            users.forEach((user) => {
                if (
                    user &&
                    user.EmpresaNombreCorto
                ) {
                    let filtered = uniquePeople.filter(el => el.EmpresaNombreCorto.toLowerCase() === user.EmpresaNombreCorto.toLowerCase());
                    if (!filtered.length) {
                        uniquePeople.push(user)
                    }
                }
            });
        }
        return uniquePeople;
    }

    const getTabsTitle = (type) => {
        let title = type === 'users' ? 'Miembros': type === 'mentors' ? 'Mentores' :  'Empresas';
        return title;
    }

    const getResultsTitle = () => {
        if (props.algoliaData && props.algoliaData.nbHits) {
            return `${props.algoliaData.nbHits} ${activeTab === 'users' ? 'Miembro' : activeTab === 'mentors' ? 'Mentor' : 'Empresa'}${props.algoliaData.nbHits === 1 && activeTab === 'mentors' ? 's' : props.algoliaData.nbHits > 1 && activeTab === 'mentors' ? 'es' : props.algoliaData.nbHits === 1 && activeTab != 'mentors' ? '' : 's' }`;
        }
    }

    const onTabToggle = (tab) => {
        setActiveTab(tab);
        if (props.onTabToggled) {
            props.onTabToggled(tab);
        }
    }

    const clearFilters = () => {
        if (props.onFiltersDeleted) {
            props.onFiltersDeleted();
        }
        if (props.onFilterClear) {
            props.onFilterClear();
        }
    }

    useEffect(() => {
        if (props.activeTab && ['users', 'companies'].includes(props.activeTab)) {
            setActiveTab(props.activeTab);
        }
    },[]);

    return (
        <div className="row m-0 DirectoryDesktopHeader-container">
            <div className="col-12 p-0 DirectoryDesktopHeader-tab-container">
                <button className={`btn-cleared ${activeTab === 'users' ? 'active':''}`} onClick={e => onTabToggle('users')}>{userLabel}</button>
                <button className={`btn-cleared ${activeTab === 'companies' ? 'active':''}`} onClick={e => onTabToggle('companies')}>{companiesLabel}</button>
                <button className={`btn-cleared ${activeTab === 'mentors' ? 'active':''}`} onClick={e => onTabToggle('mentors')}>{mentorsLabel}</button>
            </div>
            <div className="col-12 px-0 py-4">
                <div className="row m-0 d-flex align-items-center">
                    {
                        <div className="col p-0 DirectoryDesktopHeader-results-label">{getResultsTitle()}</div>
                    }
                    {
                        props.isActiveFilters ? (
                            <div className="col p-0 text-end DirectoryDesktopHeader-filter-btn-container">
                                <button className="btn-cleared" onClick={clearFilters}>
                                    Borrar filtros
                                    <img src={icnDeleteFilter} alt="icnDeleteFilter" width='28' height='28'/>
                                </button>
                            </div>
                        ):(null)
                    }
                </div>
            </div>
        </div>
    )
}

export { DirectoryDesktopHeader }
