import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Linkify from 'react-linkify';

import './CompanyDetailsSheet.scss';

import icnEditGreen from '../../../../_assets/media/images/icons/icn_edit_green.png';
import placeholdercompany from '../../../../_assets/media/images/icons/placeholder_company.svg';
import icnsSheets from '../../../../_assets/media/images/icons/icns_sheets.png';

const CompanyDetailsSheet = (props) => {
    const history = useHistory();

    const getDiscountData = () => {
        if (props.user && props.user.OfreceDescuento && props.user.PorcentajeDescuento) {
            return (
                <div className="row m-0 CompanyDetailsSheet-discount-container">
                    <div className="col-12 CompanyDetailsSheet-discount-col">
                        <div className="row m-0 w-100">
                            <div className="col discount-number px-0">{props.user.PorcentajeDescuento}%</div>
                            <div className="col discount-text">
                                De descuento para miembros WeNet
                            </div>
                        </div>
                    </div>

                    {props.user.ComentariosDescuento ? (
                        <div className="col-12 CompanyDetailsSheet-discount-comments px-0 py-3">
                            <strong>Comentarios:</strong> {props.user.ComentariosDescuento}
                        </div>
                    ):(null)}
                </div>
            );
        }
        return null;
    }

    return (
        <div className="CompanyDetailsSheet-container row">
            <div className="col-12 p-0">
                <div className="row m-0">
                    <div className="col-12 px-0 CompanyDetailsSheet-header">
                        <div className="row m-0 w-100">
                            <div className="col ps-0 companyDetailsCol">
                                <div className="row m-0 d-flex align-items-center">
                                    <div className="col p-0 imgCol">
                                        <img src={props.user && props.user.LogoThumbnailUrl ? props.user.LogoThumbnailUrl:placeholdercompany} alt="placeholdercompany"
                                            onError={(e)=>{
                                                e.target.onerror = null;
                                                e.target.src=placeholdercompany
                                            }}/>
                                    </div>
                                    <div className="col">
                                        <div className="row m-0">
                                            <div className="col-12 px-0 titleCol">{props.user && props.user.EmpresaNombreCorto ? props.user.EmpresaNombreCorto:''}</div>
                                            <div className="col-12 px-0 pt-1">
                                                <div className="row m-0 subTitleRow">
                                                    <div className="col-12 px-0 subTitleCol">{props.user && props.user.Giro ? props.user.Giro:''}</div>
                                                    <div className="col-12 px-0 subTitleCol">{props.user && props.user.Clasificacion ? props.user.Clasificacion:''}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                props.isMine ? (
                                    <div className="col btnCol px-0">
                                        <button className='btn-cleared' onClick={() => {
                                            if (props.user) {
                                                history.push("/profile/edit-company/" + props.user.clv_usuario, {})
                                            }
                                        }}>
                                            <span>{
                                                props.user &&
                                                props.user.RedesSociales &&
                                                props.user.RedesSociales.length ?
                                                ('Editar'):('Agrega')
                                            }</span>
                                            <img src={icnEditGreen} alt="icnEditGreen" width='40' height='40'/>
                                        </button>
                                    </div>
                                ):(null)
                            }

                        </div>
                    </div>
                </div>

                <div className="row m-0 CompanyDetailsSheet-main-box">
                    <div className="col ps-0 leftBox">
                        <div className="row m-0">
                            <div className="col-12 px-0">
                                <p>{props.user && props.user.Empresa ? props.user.Empresa:''}</p>
                                {
                                    props.user && props.user.Descripcion ? (
                                        <span className='mouse-default'>{props.user.Descripcion}</span>
                                    ):(
                                        <span className='font-color-gray mouse-default'>Sin descripción</span>
                                    )
                                }
                            </div>
                            {
                                props.user && (props.user.Tag1 || props.user.Tag2) &&
                                <div className="col-12 px-0 CompanyDetailsSheet-specialities-container">
                                    <div className="row m-0">
                                        <div className="col-12 px-0 CompanyDetailsSheet-specialities-title">Especialidades</div>
                                        <div className="col-12 px-0 CompanyDetailsSheet-specialities-options">
                                            {
                                                props.user && props.user && props.user.Tag1 &&
                                                <button className="btn-cleared me-2">{props.user.Tag1}</button>
                                            }
                                            {
                                                props.user && props.user.Tag2 &&
                                                <button className="btn-cleared">{props.user.Tag2}</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col ps-5 pe-0 rightBox">
                        <div className="row m-0">
                            <div className="col-12 px-0 CompanyDetailsSheet-details-container">
                                <div className="row m-0 d-flex align-items-center">
                                    <div className="col p-0 icnBox">
                                        <img src={icnsSheets} alt="icnsSheets" className='pos-6'/>
                                    </div>
                                    {
                                        props.user && props.user.URL ? (

                                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                                <a className='link-w-none' target="blank" href={decoratedHref} key={key} style={{color: '#0fb855', textDecoration: 'none'}}>
                                                    {decoratedText}
                                                </a>
                                            )}>
                                                { props.user.URL  }
                                            </Linkify>

                                        ):(
                                            <div className="txtBox col pe-0 text-truncate font-color-gray">Sin página web</div>
                                        )
                                    }
                                </div>

                                <div className="row m-0 d-flex align-items-center pt-1">
                                    <div className="col p-0 icnBox">
                                        <img src={icnsSheets} alt="icnsSheets" className='pos-2'/>
                                    </div>
                                    {
                                        props.user && props.user.Telefono ? (
                                            <div className="txtBox col pe-0 text-truncate font-color-green mouse-pointer">
                                                <a href={`tel://${props.user.Telefono}`} className='p-0 font-color-green mouse-pointer'>
                                                    {props.user.Telefono}
                                                </a>
                                            </div>

                                        ):(
                                            <div className="txtBox col pe-0 text-truncate font-color-gray">Sin teléfono</div>
                                        )
                                    }
                                </div>

                                <div className="row m-0 d-flex align-items-center pt-1">
                                    <div className="col p-0 icnBox">
                                        <img src={icnsSheets} alt="icnsSheets" className='pos-4'/>
                                    </div>
                                    {
                                        props.user && (props.user.Estado || props.user.Pais) ? (
                                            <div className="txtBox col pe-0 text-truncate">{`${props.user.Estado ? props.user.Estado:''}${props.user.Estado && props.user.Pais ? ', ':''}${props.user.Pais ? props.user.Pais:''}`}</div>
                                        ):(
                                            <div className="txtBox col pe-0 text-truncate font-color-gray">Sin ubicación</div>
                                        )
                                    }


                                </div>

                                <div className="row m-0 d-flex align-items-center pt-1">
                                    <div className="col p-0 icnBox">
                                        <img src={icnsSheets} alt="icnsSheets" className='pos-7'/>
                                    </div>
                                    {
                                        props.user && props.user.Fundacion ? (
                                            <div className="txtBox col pe-0 text-truncate">Fundada en {props.user && props.user.Fundacion ? `${new Date(props.user.Fundacion).getFullYear().toString()}`:''}</div>
                                        ):(
                                            <div className="txtBox col pe-0 text-truncate font-color-gray">Fecha no definida</div>
                                        )
                                    }
                                </div>



                                <div className="row m-0 d-flex align-items-center pt-1">
                                    <div className="col p-0 icnBox">
                                        <img src={icnsSheets} alt="icnsSheets" className='pos-8'/>
                                    </div>
                                    {
                                        props.user && props.user.RangoEmpleados ? (
                                            <div className="txtBox col pe-0 text-truncate ">{`${props.user.RangoEmpleados} empleados`}</div>
                                        ):(
                                            <div className="txtBox col pe-0 text-truncate font-color-gray">Sin empleados</div>
                                        )
                                    }
                                </div>

                            </div>


                        </div>
                    </div>

                    <div className="col-12 p-0">
                        <div className="row m-0">
                            <div className="col-6 px-0">
                                {getDiscountData()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { CompanyDetailsSheet }
