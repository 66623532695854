import React, { useState, useEffect } from 'react';

import { profileService } from '../../../../_resources/services';
import { LazyImage } from "react-lazy-images";

import './ProfileSheet.scss';

import icnUserPlaceholder from '../../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnBMStar from '../../../../_assets/media/images/icons/icn_bm_star.png';
import icnsSheets from '../../../../_assets/media/images/icons/icns_sheets.png';
import { getMessagin } from '../../../../firebaseInit';

const ProfileSheet = (props) => {
    const [user, setUser] = useState(null);

    const getUser = (isSuscribed)=>{
        profileService.getUser().then(
            (response) => {
                if (isSuscribed && response && response.data) {
                    setUser(response.data);
                }
            },
            (error) => {
                console.log(error)
            }
        );
    }

    useEffect(() => {
        let isSuscribed = true;

        getUser(isSuscribed);
        return () => isSuscribed = false
    }, [props]);

    return (
        <div className="ProfileSheet-container row m-0 d-flex justify-content-end">
            <div className="col-12 ProfileSheet-photo-container">
                {
                    user ? (
                        <>
                            <LazyImage src={user.PhotoUrl ? user.PhotoUrl:icnUserPlaceholder}
                                alt="icnUserPlaceholder"
                                placeholder={ ({imageProps, ref}) =>
                                    <img ref={ref} src={icnUserPlaceholder} alt={imageProps.alt}/>
                                }
                                actual={({imageProps}) =>
                                    <img {...imageProps}/>
                                }
                                error={() => (<img src={icnUserPlaceholder} alt="icnUserPlaceholder"/>)}
                            />
                        </>
                    ):(
                        <img src={icnUserPlaceholder} alt="icnUserPlaceholder"/>
                    )
                }
            </div>

            <div className="col-12 ProfileSheet-info-container">
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <div className="row m-0 d-flex align-items-center">
                            <div className="col ps-0 text-truncate ProfileSheet-name-container mouse-default">
                                {user && user.NombreCompleto ? user.NombreCompleto:(<span className='placeholderTxt'>Sin Nombre</span>)}
                            </div>
                            {
                                user && user.clv_estatusbm && user.clv_estatusbm === 2 &&
                                <div className="col ProfileSheet-bm-icon">
                                    <img src={icnBMStar} alt="icnBMStar"/>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="col-12 px-0 pt-3 ProfileSheet-details-container">
                        <div className="row m-0 d-flex align-items-center">
                            <div className="col p-0 icnBox">
                                <img src={icnsSheets} alt="icnsSheets" className='pos-1'/>
                            </div>
                            {
                                user ? (
                                    <>
                                        {
                                            !user.Cargo && !user.EmpresaNombreCorto ? (
                                                <div className="txtBox col pe-0 text-truncate mouse-default placeholderTxt">
                                                    Sin empresa
                                                </div>
                                            ):(
                                                <div className="txtBox col pe-0 text-truncate mouse-default">
                                                    {`${user.Cargo ? user.Cargo:''}${user.Cargo && user.EmpresaNombreCorto ? ' | ':''}${user.EmpresaNombreCorto ? user.EmpresaNombreCorto:''}`}
                                                </div>
                                            )
                                        }
                                    </>
                                ):(null)
                            }

                        </div>

                        <div className="row m-0 d-flex align-items-center pt-1">
                            <div className="col p-0 icnBox">
                                <img src={icnsSheets} alt="icnsSheets" className='pos-2'/>
                            </div>
                            <div className="txtBox col pe-0 text-truncate font-color-green mouse-pointer">{user && user.Celular ? user.Celular:(<span className='placeholderTxt'>Sin celular</span>)}</div>
                        </div>

                        <div className="row m-0 d-flex align-items-center pt-1">
                            <div className="col p-0 icnBox">
                                <img src={icnsSheets} alt="icnsSheets" className='pos-4'/>
                            </div>
                            {
                                user ? (
                                    <>
                                        {
                                            !user.EstadoResidencia && !user.PaisResidencia ? (
                                                <div className="txtBox col pe-0 text-truncate mouse-default placeholderTxt">
                                                    Sin ubicación
                                                </div>
                                            ):(
                                                <div className="txtBox col pe-0 text-truncate mouse-default">
                                                    {`${user.EstadoResidencia ? user.EstadoResidencia:''}${user.EstadoResidencia && user.PaisResidencia ? ', ':''}${user.PaisResidencia ? user.PaisResidencia:''}`}
                                                </div>
                                            )
                                        }
                                    </>
                                ):(null)
                            }
                        </div>

                        <div className="row m-0 d-flex align-items-center pt-1">
                            <div className="col p-0 icnBox">
                                <img src={icnsSheets} alt="icnsSheets" className='pos-3'/>
                            </div>
                            <div className="txtBox col pe-0 text-truncate font-color-green mouse-pointer">
                                {
                                    user && user.Email ? (
                                        <a href={`mailto:${user.Email}`} className='font-color-green'>
                                            {user.Email}
                                        </a>
                                    ):(<span className='placeholderTxt'>Sin celular</span>)
                                }
                            </div>
                        </div>



                        <div className="row m-0 d-flex align-items-center pt-1">
                            <div className="col p-0 icnBox">
                                <img src={icnsSheets} alt="icnsSheets" className='pos-5'/>
                            </div>
                            <div className="txtBox col pe-0 text-truncate mouse-default">{user && user.Aficion ? user.Aficion:(<span className='placeholderTxt'>Sin afición</span>)}</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export { ProfileSheet };
