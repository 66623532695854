import React, { useState, useEffect } from 'react';
import './Notifications.scss';
import icnArrow from '../../../_assets/media/images/icons/icn_back_arrow.png';
import icnNotificationNewUsers from '../../../_assets/media/images/icons/icn_notification_new_users.png';
import icnBanner from '../../../_assets/media/images/icons/icn_tutorial_banner.png';
import icnPlaceHolder from "../../../_assets/media/images/icons/icn_user_placeholder.png";
import icnClose from "../../../_assets/media/images/icons/icn_close.png";

import { profileService, swalAlertsService } from '../../../_resources/services';
import { toolsHelper } from '../../../_resources/helpers/';
import { useHistory, Link } from 'react-router-dom';

import { ModalDefault } from '../';

const Notifications = (props) => {
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [notificationsTitle, setNotificationsTitle] = useState('Notificaciones');

    const [showSubTitle, setShowSubTitle] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);
    const [showHeader, setshowHeader] = useState(false);

    const [notificationsList, setNotificationsList] = useState([]);
    const [modalActive, setModalActive] = useState(false);
    const [userRefeal, setUserRefeal] = useState(null);
    const [selectedTypeNotification, setSelectedTypeNotification] = useState('MiActividad');

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const getNotifications = () => {
        profileService.getGeneric('notification').then((response) => {
            if (response && response.notificationcenter) {
                setNotificationsList(response.notificationcenter);
            }
        });
    }

    useEffect(() => {
        getNotifications();
    }, []);

    const onNotificationTapped = (item) => {
        if (item && !item.Leido) {
            profileService.postGeneric(`notification/${item.clv_notificacionxusuario}/read`, 'put', null).then(response => {
                if (response && response.notificationcenter && response.message && response.message === "Notificaciones obtenidas correctamente") {
                    let notificationListCP = JSON.parse(JSON.stringify(notificationsList));
                    notificationListCP[selectedTypeNotification] = response.notificationcenter[selectedTypeNotification];
                    setNotificationsList(notificationListCP);
                }
            })
        }

        if (item && item.Parametro) {
            if (item.clv_tiponotificacion === 6) {
                history.push(`/notification/${item.Parametro}`, { from: '/home' })
            } else if (item.clv_tiponotificacion === 7) {
                history.push(`/notification-birthday/${item.Parametro}`, { from: '/home' })
            } else if (item.clv_tipoaccion === 8) {
                prepareModal(item);
            } else if (item.clv_tipoaccion === 2 || item.clv_tipoaccion === 9) {
                history.push(`/post/${item.Parametro}`);
            } else if (item.clv_tipoaccion === 4) {
                history.push(`/talent/${item.Parametro}`);
            }
        }
    }

    const renderNotificationsList = () => {

        if (notificationsList && notificationsList[selectedTypeNotification] && notificationsList[selectedTypeNotification].notifications && notificationsList[selectedTypeNotification].notifications.length) {
            return notificationsList[selectedTypeNotification].notifications.map((item, index) => {
                return (
                    <div className={`row px-0 m-0 Notification-item-container mouse-pointer ${item.Leido ? 'readed' : ''}`} key={index} onClick={e => onNotificationTapped(item)}>
                        <div className="col Notification-icon-container noselect">
                            <img src={icnNotificationNewUsers} alt="icnNotificationNewUsers" />
                        </div>
                        <div className="col Notification-details-container pos-relative">
                            <div className="hour-badge noselect">{toolsHelper.timeSince(item.Fecha, true)}</div>
                            <div className="row m-0 w-100 line-height-22px">
                                <div className="col-12 px-0 title text-block-truncate-1 noselect">{item.Titulo}</div>
                                <div className="col-12 px-0 subtitle noselect">{item.Body}</div>
                            </div>
                        </div>
                    </div>
                )
            })
        }

        return null;
    }
    const prepareModal = (item) => {
        profileService.getUserProfile(item.Parametro).then(
            (response) => {
                if (response && response.data) {
                    setUserRefeal(response.data);
                    setModalActive(true);
                }
            }
        )
    }

    const actionButton = (status) => {
        const objSend = {
            clv_usuario: userRefeal.clv_usuario,
            clv_estatusreferido: status
        }

        profileService.postGeneric('user/referal', 'put', objSend).then((response) => {
            if (response) {
                swalAlertsService.showToast('success', response.message);
                setModalActive(false);
            }
        })
    }

    const handleNotification = (item) => {
        setSelectedTypeNotification(item);
    }

    const readAll = () => {
        swalAlertsService.answer('Confirmación', '¿Estás seguro de querer marcar todas las notificaciones como leídas?', 'Aceptar').then(result => {
            if (result && result.isConfirmed && result.value) {
                profileService.postGeneric('notification/readall', 'POST', null).then((response) => {
                    console.log(response);
                    swalAlertsService.showToast('success', 'Se marcaron correctamente');
                    setNotificationsList(response.notificationcenter);
                })
            }
        });
    }

    const renderView = () => {
        return (
            <React.Fragment>
                <div className={`Notifications-container`}>
                    <div className={`row m-0`}>
                        <div className={`col-12 Notifications-header-box`} style={{ visibility: "visible" }}>
                            <button className='btn-cleared Notifications-btn-close mouse-pointer' onClick={onDismiss}>
                                <img src={icnArrow} alt="close icon" />
                            </button>


                            <p className={`large-title`}>Notificaciones </p>
                            <h5 onClick={readAll} className="readAllNot">Leer todo</h5>
                        </div>

                        <div className="col-12 container-tutorial">
                            <span className='title-tutorial'>¿Sabes cómo utilizar WeNet?</span>
                            <div className='container-body-tutorial col-12 col-md-8 mx-auto'>
                                <a href="https://imagora.mx/WeNet30/tutorial-wenet.html" target='_blank' className='row m-0 p-0 '>
                                    <div className='container-icn-tutorial'>
                                        <img src={icnBanner} alt="" className='icn_tutorial' />
                                    </div>
                                    <div className='col-7 col-md-7 container-text-tutorial'>
                                        <span className='main-text-tutorial'>Conoce todas las funciones disponibles</span>
                                        <br />
                                        Ver tutoriales <img src={icnArrow} alt="" className='icn_arrow' />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <hr className='hr-tutorial mx-auto' />
                        <div className='row m-0'>
                            <div className={`col-6 sample-notification ${selectedTypeNotification === 'MiActividad' ? 'selected-notification' : ''}`} onClick={() => handleNotification('MiActividad')}>
                                <h5>Mi actividad  {
                                    notificationsList && notificationsList.MiActividad && notificationsList.MiActividad.NotRead && notificationsList.MiActividad.NotRead != 0 ?
                                        (<span className='amount-not-read'> {notificationsList.MiActividad.NotRead} </span>)
                                        : (null)
                                }</h5>
                            </div>
                            <div className={`col-6 sample-notification  ${selectedTypeNotification === 'Informativa' ? 'selected-notification' : ''}  `} onClick={() => handleNotification('Informativa')}>
                                <h5 className='text-center'>Informativa   {
                                    notificationsList && notificationsList.Informativa && notificationsList.Informativa.NotRead && notificationsList.Informativa.NotRead != 0 ?
                                        (<span className='amount-not-read'> {notificationsList.Informativa.NotRead} </span>)
                                        : (null)
                                }</h5>
                            </div>


                        </div>
                        <div className='row m-0'>
                            <div className="col p-0 Notifications-content">
                                {renderNotificationsList()}
                            </div>
                        </div>
                    </div>
                    <ModalDefault show={modalActive} onHide={() => setModalActive(false)} className={'modalRefereal-mobile'}>
                        {userRefeal ? (
                            <div className='container-refereal'>
                                <div className='text-end close-refereal'>
                                    <img className='icn-close-refereal' src={icnClose} alt="" onClick={() => setModalActive(false)} />
                                </div>
                                <h3 className='title-refeal text-center'>
                                    {
                                        userRefeal.clv_estatusreferido === 2 ?
                                            `Has confirmado a ${userRefeal.Nombre} como tu invitado` :
                                            userRefeal.clv_estatusreferido === 3 ?
                                                `Has rechazado a ${userRefeal.Nombre} como tu invitado` :
                                                `¿Confirmas a ${userRefeal.Nombre} como tu invitado a WeNet?`
                                    }
                                </h3>
                                <div className='detail-refeal'>
                                    <div className='container-user-info-refereal'>
                                        <div className='row'>
                                            <div className='col-2'>
                                                <img src={userRefeal.ThumbnailUrl ? userRefeal.ThumbnailUrl : icnPlaceHolder} alt="user image" className='icn_refeal' />
                                            </div>
                                            <div className='col-10'>
                                                <h3 className='name-refereal'>{userRefeal.NombreCompleto}</h3>
                                                <h3 className='position-business-refereal'> {userRefeal.Cargo} | {userRefeal.EmpresaNombreCorto}</h3>
                                                <h3 className='location-refereal'> {userRefeal.EstadoResidencia && userRefeal.PaisResidencia ? userRefeal.EstadoResidencia + '|' + userRefeal.PaisResidencia : ''}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container-user-description-refereal'>
                                        <h3> {userRefeal.Descripcion}</h3>
                                    </div>
                                    <div className='container-view-profile-refereal text-end'>
                                        <Link to={`profile-user/${userRefeal.clv_usuario}`} className='view-profile-refereal'>Ver perfil</Link>
                                    </div>
                                </div>
                                <div className='container-buttons-refeal row m-0'>
                                    {
                                        userRefeal.clv_estatusreferido == 1 ?
                                            (<>
                                                <div className='col-6 text-center'>
                                                    <button className='btn btn-cancel-refereal' onClick={() => actionButton(3)}>No</button>
                                                </div>
                                                <div className='col-6 text-center'>
                                                    <button className='btn btn-ok-refereal' onClick={() => actionButton(2)} >Si</button>
                                                </div>
                                            </>) :
                                            (null)

                                    }

                                </div>
                            </div>
                        ) : ''}
                    </ModalDefault>
                </div>
            </React.Fragment>
        );
    }

    return (
        <ModalDefault show={true} className='full-view'>
            {renderView()}
        </ModalDefault>
    );

}
export { Notifications };
