import React, { useState, useEffect } from 'react';
import './DirectoryHeader.scss';

import { Searcher } from '../../../../shared';

import icnNewChat from '../../../../../_assets/media/images/icons/icn_new_chat.png';

const DirectoryHeader = (props) => {

    const onOpenFilters = () => {
        if (props.onOpenFilters) {
            props.onOpenFilters();
        }
    }

    const onNewChatBtnTapped = () => {
        if (props.onNewChatBtnTapped) {
            props.onNewChatBtnTapped();
        }
    }

    const onSearchTerm = (data) => {
        if (props.onSearchTerm) {
            props.onSearchTerm(data);
        }
    }

    return (
        <div className="DirectoryHeader-container row m-0">

            <div className="col-12 p-0">
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <Searcher isActiveFilters={props.isActiveFilters}
                            showLogo={false}
                            onSearchTerm={onSearchTerm}
                            onOpenFilters={onOpenFilters}
                            onNewChatBtnTapped={onNewChatBtnTapped}
                            showNewChatBtn={true}
                        />
                    </div>
                </div>
            </div>

            <div className="col-12 title-box">
                Directorio
            </div>
        </div>
    );
}

export { DirectoryHeader }
