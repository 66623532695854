import { BehaviorSubject } from 'rxjs';
import { authHeader, handleResponse } from '../helpers';
import CryptoJS from 'crypto-js';
const phrase = process.env.REACT_APP_PHRASE;
const apiPath = process.env.REACT_APP_API_PATH;

const catalogsSubject = new BehaviorSubject(() => {
    let value = null;
    value = localStorage.getItem('catalogs');
    if (value) {
        try {
            value = JSON.parse( CryptoJS.AES.decrypt(value,`${phrase}catalogs`))
            console.log(value);
        } catch (e) {

        }
    }
    return value;
});

const versionSubject = new BehaviorSubject(() => {
    let value = null;
    value = localStorage.getItem('infoVersion');
    if (value) {
        try {
            // value = JSON.parse( CryptoJS.AES.decrypt(value,`${phrase}infoVersion`))
            value = JSON.parse(value);
        } catch (e) {

        }
    }
    return value;
});

const getVersion = () => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiPath}/version`, requestOptions)
        .then(handleResponse)
        .then(version => {

            version = JSON.parse(JSON.stringify(version));
            if (version.data) {

                const stringed = JSON.stringify(version.data);
                // localStorage.setItem('infoVersion', CryptoJS.AES.encrypt(stringed, `${phrase}infoVersion`) );
                localStorage.setItem('infoVersion', stringed );
                versionSubject.next(version.data);
            }

            return version;
        });

}

const getCatalogs = () => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiPath}/catalogs`, requestOptions)
        .then(handleResponse)
        .then(catalogs => {
            catalogs = JSON.parse(JSON.stringify(catalogs));
            if (catalogs.data) {
                let data = catalogs.data;
                for (const key in data) {
                    if (data[key].length) {
                        data[key].forEach((item) => {
                            const fistLetter = key.charAt(0);

                            for (const objKey in item) {
                                if (objKey.charAt(0) === fistLetter && objKey !== 'Activo') {
                                    item.name = item[objKey];
                                } else if (objKey.substring(0, 4) === 'clv_') {
                                    item.id = item[objKey];
                                }
                            }


                        });
                    }
                }

                catalogs.data = data;

                const stringed = JSON.stringify(data);
                localStorage.setItem('catalogs', CryptoJS.AES.encrypt(stringed, `${phrase}catalogs`) );
                catalogsSubject.next(data);
            }

            return catalogs;
        });

}

export const infoService = {
    getVersion,
    getCatalogs,
    catalogs: catalogsSubject.asObservable(),
    get catalogsValue () { return catalogsSubject.value },
    version: versionSubject.asObservable(),
    get versionValue () { return versionSubject.value },

};
