import React, { useState, useEffect } from 'react';

import './ConfigSheet.scss';

import icnCog from '../../../../_assets/media/images/icons/icn_cog.png';
import icnMore from '../../../../_assets/media/images/icons/icn_more.png';
import icnShare from '../../../../_assets/media/images/icons/icn_share.png';

const ConfigSheet = (props) => {

    const onOptionTapped = (option) => {
        if (option && props.onOptionTapped) {
            props.onOptionTapped(option);
        }
    }

    return (
        <div className="ConfigSheet-container row m-0 pos-sticky isTop-10">
            <div className="col-12 px-0">

                <div className="row m-0">
                    <div className="col-12 ConfigSheet-header-container noselect" onClick={e => onOptionTapped('config')}>
                        <span>
                            <img src={icnCog} alt="icnCog" width='30' height='30'/>
                            <span className='titleSpan'>Configuración</span>
                        </span>

                        <span>
                            <img src={icnMore} alt="icnMore" width='40' height='40'/>
                        </span>
                    </div>
                </div>

                <div className="row m-0">
                    <div className="col-12 px-0 ConfigSheet-content-container">
                        <div className="row m-0">
                            <div className="col-12 ConfigSheet-content-header">
                                Mi cuenta
                            </div>

                            <div className="col-12 ConfigSheet-content-option noselect" onClick={e => onOptionTapped('membership')}>
                                <span>Membresía</span> <img src={icnMore} alt="icnMore" width='40' height='40'/>
                            </div>

                            <div className="col-12 ConfigSheet-content-option noselect" onClick={e => onOptionTapped('payment-method')}>
                                <span>Método de pago</span> <img src={icnMore} alt="icnMore" width='40' height='40'/>
                            </div>

                            <div className="col-12 ConfigSheet-content-option noselect" onClick={e => onOptionTapped('invitation')}>
                                <span className='font-color-green'>Invitar amigos a WeNet</span> <img src={icnShare} alt="icnShare" width='40' height='40'/>
                            </div>

                            <div className="col-12 ConfigSheet-content-option noselect" onClick={e => onOptionTapped('recomendation')}>
                                <span className='font-color-green'>Pedir recomendación</span> <img src={icnShare} alt="icnShare" width='40' height='40'/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export { ConfigSheet };
