import React, { useState, useEffect } from 'react';

import iconMiniBullet from '../../../../../../../_assets/media/images/icons/icn_mini_bullet.png';
import iconMiniBulletSelected from '../../../../../../../_assets/media/images/icons/icn_mini_bullet_selected.png';

import './FilterRadioCell.scss';

const FilterRadioCell = (props) => {

    const onElementSelected = () => {
        if (props.onElementSelected) {
            props.onElementSelected(props.item);
        }
    }

    if (props.item) {
        return (
            <div className="FilterRadioCell-container col-12" onClick={onElementSelected}>
                <div className="row m-0 h-100 d-flex align-items-center">
                    <div className="col p-0 FRC-title-label noselect">{props.item.title}</div>
                    <div className="col p-0 FRC-buttlet-img noselect">
                        <img src={props.selected ? iconMiniBulletSelected:iconMiniBullet} alt="bullet icon"/>
                    </div>
                </div>
            </div>
        );
    }
    return null;

}
export { FilterRadioCell }
