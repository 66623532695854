import React, {useState, useEffect, useRef} from 'react'
import { userService, authenticationService, swalAlertsService } from '../../../../_resources/services';
import folleto from '../../../../_assets/media/images/icons/folleto.png'
import back from '../../../../_assets/media/images/icons/icn_back_arrow.png'
import close from '../../../../_assets/media/images/icons/icn_close.png'
import { profileService } from '../../../../_resources/services/profile.service';
import { useHistory } from 'react-router';
import './Folleto.scss';
import { toolsHelper } from '../../../../_resources/helpers';

const Folleto = (props) => {
    props.onToggleTab(true)
    const history = useHistory()
    const [user, setUser] = useState({})
    const [fileData, setFileData] = useState({
        base64: null,
        splitted: null,
        name: null,
        size: null,
        fromEdit: false,
        deleted: false
    });
    const [data, setData] = useState({})

    const inputFile = useRef(null);

    useEffect(() => {
        getUser()

    }, [])

    const getUser = () => {
        profileService.getUser().then(
            (response) => {
                setUser(response.data)
              },
            (error) => {
                console.log(error)
            }
        )
    }


    const setUrl = (key, value) => {
        switch (key) {
            case "web":
                if(value.includes("https://") || value.includes("http://")) {
                    window.location.replace(value)
                }else {
                    window.location.replace(`https://${value}`)
                }
                break;

            default:
                break;
        }
    }

    const nameDocument = (document) => {
        if(document) {
            let name = document.split("/")[6]
            return name.split("?")[0]
        }
    }

    const deleteDocument = () => {
        profileService.deleteDocument().then(
            (response) => {
                if(response.status === 200 || response.status === 202) {
                    getUser()
                }
                if(response.status === 500 || response.status === 400 || response.status === 404) {
                    swalAlertsService.showToast("error", "Algo salio mal al intertar borrar el folleto, intentalo de nuev más tarde")
                }
            }
        )
    }

    const setDocument = (base64) => {
        console.log(base64)
        let loading = swalAlertsService.createLoading("Espere un momento...")
        loading.fire()
        let data = {
            "clv_tipoadjunto": 1,
            "Extension": "pdf",
            "Base64": base64,
        }
       profileService.setDocument(data).then(
            (response) => {
                if(response.status === 200 || response.status === 202) {
                    getUser()
                    loading.close()
                }
                if(response.status === 500 || response.status === 400 || response.status === 404) {
                    loading.close()
                    swalAlertsService.showToast("error", "Algo salio mal al intertar subir el folleto, intentalo de nuev más tarde")
                }
            }
        )
    }

    async function getFile (event, type) {
        if (event.target && event.target.files && event.target.files.length) {
            const result = await toolsHelper.toBase64(event.target.files[0]).catch(e => console.log(e));

            if (result && type) {
                handleFile(result, event.target.files[0]);
            }
        }
    }

    const handleFile = (dataUri, extraData) => {

        if (dataUri && dataUri.indexOf('base64,') >= 0) {
            const dataSplitted = dataUri.split(dataUri.substring(0, dataUri.indexOf('base64,') + 'base64,'.length));

            if (dataSplitted.length > 1) {
                let base64 = dataUri.split(",")[1]
                setDocument(base64)
            }
        }
    }


    return (
        <React.Fragment>
            <div className="container_folleto">
                <div className="container_folleto_header">
                    <img src={back} width="30px" height="30px" onClick={() => history.goBack()}/>
                    <h3>Subir folleto</h3>
                </div>

                <div className="container_body">
                    <p className="text_folleto">
                        Sube un folleto en formato PDF que describa los servicios y
                        actividades de tu empresa.
                    </p>

                    {
                        user ?
                        (
                            user.BrochureUrl !== null
                            ?
                            (
                                <div>
                                    <div className="container_document_select">
                                        <img src={folleto} width="90px" height="70px"/>
                                        <p>{nameDocument(user.BrochureUrl)}</p>
                                        <img src={close} width="30px" height="30px" className="img_close" onClick={() => deleteDocument()}/>
                                    </div>

                                   <a href={user.BrochureUrl} target="_blank" rel="noreferrer"> <button className="btn_folleto">Ver folleto </button></a>
                                </div>
                            )
                            :
                            (
                                <div className="container_document_no_select">
                                    <label htmlFor="image" >Selecciona un archivo</label>
                                    <input id="image"
                                        type="file"
                                        ref={inputFile}
                                        onChange={(event) => {
                                            if (event.target && event.target.files && event.target.files.length && event.target.files[0].type === "application/pdf") {
                                                getFile(event,'pdf');
                                            }
                                        }}
                                        onError={error => console.log(error)}
                                        accept="application/pdf"
                                        style={{display: 'none'}}
                                        multiple={false}
                                    />
                                </div>
                        )
                        )
                        : ""
                    }
                </div>
           </div>
        </React.Fragment>
    )
}

export { Folleto };
