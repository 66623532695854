import React, { useState, useEffect } from 'react';
import './AddPostBottom.scss';

import icnMore from '../../../../../_assets/media/images/icons/icn_more.png';
import icnPhoto from '../../../../../_assets/media/images/icons/icn_options_photos.png';
import icnImages from '../../../../../_assets/media/images/icons/icn_options_images.png';
import icnVideo from '../../../../../_assets/media/images/icons/icn_options_video.png';
import icnDoc from '../../../../../_assets/media/images/icons/icn_options_doc.png';
import icnRules from '../../../../../_assets/media/images/icons/icn_options_rules.png';

const AddPostBottom = (props) => {

    let postSelected = props.postTypeSelected ? props.postTypeSelected : {clv_tipopublicacion : 0};

    const onRowTapped = (type) => {
        if (props.onRowTapped) {
            props.onRowTapped(type);
        }
    }

    return (
        <div className="AddPostBottom-container">
            {
                postSelected.clv_tipopublicacion !=4 ? 
                (
                    <div className="row m-0 AddPostBottom-row AddPostBottom-rol-filter-container" onClick={() => {onRowTapped('rol')}}>
                    <div className="col-12 p-0 pos-relative line-height-45px">
                        <span className='first-label'>Selecionar puesto</span>
                        <button className='btn-cleared'>
                            <img src={icnMore} alt="" width='40' height='40'/>
                        </button>
                        <span className='second-label'>{props.roleString ? props.roleString:'Todos'}</span>
    
                    </div>
                </div>
                ): (null)
            }
           

            {
                // <div className="row m-0 AddPostBottom-row AddPostBottom-option-box" onClick={() => {onRowTapped('photo')}}>
                //     <div className="col-12 p-0 pos-relative line-height-45pxss">
                //         <img src={icnPhoto} alt="icon" width='30' height='30'/>
                //         <span>Tomar foto</span>
                //     </div>
                // </div>
            }

            <div className="row m-0 AddPostBottom-row AddPostBottom-option-box" onClick={() => {onRowTapped('image')}}>
                <div className="col-12 p-0 pos-relative line-height-45pxss noselect">
                    <img src={icnImages} alt="icon" width='30' height='30'/>
                    <span>Imágenes</span>
                </div>
            </div>

            <div className="row m-0 AddPostBottom-row AddPostBottom-option-box" onClick={() => {onRowTapped('video')}}>
                <div className="col-12 p-0 pos-relative line-height-45pxss noselect">
                    <img src={icnVideo} alt="icon" width='30' height='30'/>
                    <span>Video</span>
                </div>
            </div>

            <div className="row m-0 AddPostBottom-row AddPostBottom-option-box" onClick={() => {onRowTapped('doc')}}>
                <div className="col-12 p-0 pos-relative line-height-45pxss noselect">
                    <img src={icnDoc} alt="icon" width='30' height='30'/>
                    <span>Archivo</span>
                </div>
            </div>

            <div className="row m-0 AddPostBottom-row AddPostBottom-option-box" onClick={() => {onRowTapped('rules')}}>
                <div className="col-12 p-0 pos-relative line-height-45pxss noselect">
                    <img src={icnRules} alt="icon" width='30' height='30'/>
                    <span>Ver reglas de uso</span>
                </div>
            </div>
        </div>
    );
}

export { AddPostBottom }
