import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { swalAlertsService, authenticationService } from '../../../_resources/services';

import './Invitation.scss';

const Invitation = (props) => {
    const history = useHistory();
    useEffect(() => {
        if (props && props.match && props.match.params && props.match.params.iuid) {
            localStorage.setItem('iuid', props.match.params.iuid);

            swalAlertsService.confirm('success', "", "Para usar tú código, debes Iniciar sesión o crear una nueva cuenta", () => {
                if (localStorage.getItem('iuid')) {
                    authenticationService.logout().then((response) => {
                        localStorage.removeItem('currentUser');
                        history.replace('/register');
                        window.location.reload();
                    });

                }
            });
        }
        if (props.onToggleTab) {
            props.onToggleTab(true);
        }
    }, []);

    return (<div className="Invitation-container"></div>);
};

export { Invitation };
