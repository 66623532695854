import React, { useState, useEffect, useCallback } from 'react';
import {
    useHistory,
    Redirect
} from "react-router-dom";
import './RecoverPhone.scss';
import { toolsHelper } from '../../../../../_resources/helpers';
import iconClose from '../../../../../_assets/media/images/icons/icn_close.png';
import { UniversalPicker } from '../../../../shared';
import { infoService } from '../../../../../_resources/services';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useRef } from 'react';
import { async } from 'rxjs';

const RecoverPhone = (props) => {
    const history = useHistory();
    const [openUniversalPicker, setOpenUniversalPicker] = useState(false)
    const [goToMain, setGoToMain] = useState(false);
    const [postState, setPostState] = useState({
        phone: "",
        countryId: 0,
        lada: "",
        paisResidencia: ""
    });

    const { executeRecaptcha } = useGoogleReCaptcha();

    const phone = useRef(null);

    let tokenXd = null;

    const goToWelcome = () => {
        if (history.action !== 'POP') {
            history.goBack();
        } else {
            setGoToMain(true);
        }
    }

    const onPhoneChanged = (e) => {
        let postStateCP = JSON.parse(JSON.stringify(postState))
        postStateCP.phone = e.target.value
        setPostState(postStateCP)
    }

    const onNextBtnTapped = async() => {
        if (props.onNextBtnTapped) {
            let token  = await handleReCaptchaVerify();
            let cpPostState = JSON.parse(JSON.stringify(postState));
            cpPostState.phone = phone.current.value;
            cpPostState.googleToken = token;
            props.onNextBtnTapped(cpPostState);
            setPostState(cpPostState);
        }
    }

    // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    let error = false;
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      error = true;
    }
        const token = await executeRecaptcha('login');
        return token;
    // Do whatever you want with the token
    }, [executeRecaptcha]);

    const onUniverlPickerHide = (data) => {
        setOpenUniversalPicker(data)
    }

    const onElementSelected = (element) => {
        let postStateCP = JSON.parse(JSON.stringify(postState))
        postStateCP.lada = element.Lada
        postStateCP.countryId = element.clv_pais
        postStateCP.paisResidencia = element.PaisResidencia
        setPostState(postStateCP)
    }

    const showPicker = () => {
        setOpenUniversalPicker(true)
    }

    useEffect(() => {
        let suscribed = true
        infoService.catalogs.subscribe((catalogs) => {
            if (suscribed && catalogs.Paises) {
                let defaultCountry = catalogs.Paises.filter(result => result.Lada === "52");

                if (defaultCountry[0]) {
                    let postStateCP = JSON.parse(JSON.stringify(postState))
                    postStateCP.lada = defaultCountry[0].Lada
                    postStateCP.countryId = defaultCountry[0].clv_pais
                    postStateCP.paisResidencia = defaultCountry[0].PaisResidencia
                    setPostState(postStateCP)
                }
            }
        });
        return () => suscribed = false
    }, []);


    return (

        <React.Fragment>
            {

                <div className="RegisterData-container">

                    <div className="row m-0">
                        <div className="col-12 p-0">
                            <button className='btn-cleared RegisterData-close-btn' onClick={goToWelcome}>
                                <img src={iconClose} alt="close icon" />
                            </button>
                        </div>
                    </div>

                    <div className="row m-0 RegisterData-titles-box">
                        <div className="col-12 p-0 title">
                            Recuperar contraseña
                        </div>
                        <div className="col-12 p-0 subtitle">
                            Por favor ingresa el celular con el que te registraste.
                        </div>
                    </div>

                    <div className="col-12 p-0">

                        <div className="row m-0">
                            <div className={`col-12 RegisterData-input-box-lada`}>
                                <div className="row m-0 d-flex align-items-center">
                                    <div className="col p-0 lada-btn-box">
                                        <button onClick={() => showPicker("phone")}>
                                            {postState.lada != null ? "+" + postState.lada : "NA"}
                                        </button>
                                    </div>
                                    <div className="col p-0 input-box">
                                        <input type="text"
                                            className='ladded'
                                            id='phone'
                                            name='phone'
                                            maxLength='10'
                                            ref={phone}
                                        // onChange={(e) => { onPhoneChanged(e) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row m-0 RegisterData-titles-box">
                        <div className="col-12 p-0 subtitle">
                            Enviaremos un código por SMS para validar tu cuenta al número que proporciones.
                        </div>
                    </div>

                    <div className="col-12 px-0 RegisterData-step-btn-box">
                        <button onClick={() => { onNextBtnTapped() }}>
                            Siguiente
                        </button>
                    </div>



                </div>

            }

            {
                openUniversalPicker ? (
                    <UniversalPicker mode='phone' country={postState.paisResidencia} onDismiss={onUniverlPickerHide} onElementSelected={onElementSelected} />
                ) : (null)
            }

            {
                goToMain ? (
                    <Redirect to={{ pathname: '/' }} />
                ) : (null)
            }
        </React.Fragment>

    )
}

export { RecoverPhone }
