import React, { useState, useEffect, useRef } from 'react';

import './ContactList.scss';

import { algoliaService, swalAlertsService, messagesService } from '../../../_resources/services';
import { toolsHelper, useWindowDimensions } from '../../../_resources/helpers';

import { NoResults, BottomSheetShared, Filters, ModalDefault } from '../';

import icnBullet from '../../../_assets/media/images/icons/icn_bullet.png';
import icnBulletSelected from '../../../_assets/media/images/icons/icn_bullet_actived.png';
import imgUserPlaceholder from '../../../_assets/media/images/icons/icn_user_placeholder.png';

import icnSearch from '../../../_assets/media/images/icons/icn_search.png';
import icnCancel from '../../../_assets/media/images/icons/icn_cancel.png';
import icnBroadcast from '../../../_assets/media/images/icons/icn_broadcast.png';
import icnGroup from '../../../_assets/media/images/icons/icn_group.png';
import icnCamera from '../../../_assets/media/images/icons/icn_camera.png';
import icnCheckBox from '../../../_assets/media/images/icons/icn_checkbox.png';
import icnCheckBoxSelected from '../../../_assets/media/images/icons/icn_checkbox_selected.png';
import icnDeletContact from '../../../_assets/media/images/icons/icn_delet_contact.png';
import start from '../../../_assets/media/images/icons/icn_batch_star.svg';
import icnClose from '../../../_assets/media/images/icons/icn_close.png';
import icnFilter from '../../../_assets/media/images/icons/icn_filter.png';
import icnFilterSelected from '../../../_assets/media/images/icons/icn_filter_active.png';

const ContactList = (props) => {
    console.log(props);
    const [showFilters, setShowFilters] = useState(false);
    const [isGroupSubmit, setIsGroupSubmit] = useState(false);
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(true);
    const [isHiddingGroup, setIsHiddingGroup] = useState(false);
    const [contactListState, setContactListState] = useState({
        results: [],
        massiveSelected: [],
        searchTerm: ``,
        isMassive: false,
        massiveCheckAll: false,
        isGroup: false,
        groupCheckAll: false,
        groupSelected: [],
        groupDetails: {
            image: null,
            name: ''
        },
        invitationSelected: {
            clv_usuario: null,
            NombreCompleto: null
        },
        invitationsSelected: []
    });

    const inputFile = useRef(null);

    const [activeFilters, setActiveFilters] = useState({
        filtersSelected: null,
        postedBy: null,
        isUsingFilters: false
    });

    const {width, height} = useWindowDimensions();

    const prepareFilters = () => {
        const {currentUser} = props;
        let parameters = {
            facets: ["Aficion", "Cargo", "Clasificacion", "Estado", "Giro", "Pais"],
            hitsPerPage: 999,
            facetFilters: []
        };

        if (currentUser && currentUser.Usuario && currentUser.Usuario.clv_usuario) {
            let userID = currentUser.Usuario.clv_usuario;

            parameters['numericFilters'] = [`BloqueadoPor.clv_usuario!=${currentUser.Usuario.clv_usuario}`, `clv_usuario!=${currentUser.Usuario.clv_usuario}`];

            const {filtersSelected} = activeFilters;

            if (filtersSelected) {
                let facetsRows = ["Aficiones", "Cargos", "Clasificaciones", "Estados", "Giros", "Paises"]
                facetsRows.forEach((facet, index) => {
                    if (filtersSelected[facet] && filtersSelected[facet].length) {
                        const elements = filtersSelected[facet].map(element => `${parameters.facets[index]}: ${element.name}`);
                        parameters.facetFilters.push(elements);
                    }
                });
            }
        }

        return parameters;
    }

    async function loadData (searchTerm = '') {
        let parameters = prepareFilters();
        if (!parameters.numericFilters && props.currentUser && props.currentUser.Usuario && 'clv_usuario' in props.currentUser.Usuario) {
            parameters.numericFilters = [`clv_usuario!=${props.currentUser.Usuario.clv_usuario}`];
        }


        if (props && props.isRegister) {
            parameters.numericFilters = ['clv_usuario!=1'];
        }

        const response = await algoliaService.getResults(searchTerm, parameters, 'Directorio', false, false);

        // fix invite only to real user
        // if (!props.isMultiple && !response.length && props.isInvitation) {
        //     return fakeUserGet();
        // }
        return response;
    }

    // fix invite only to real user
    // const fakeUserGet = () => {
    //     return [{
    //         clv_usuario: null,
    //         NombreCompleto: contactListState.searchTerm
    //     }]

    // }

    const handleResponse = (response) => {
        let contactListStateCP = JSON.parse(JSON.stringify(contactListState));
        contactListStateCP.results = [];

        if (response && response.length) {
            contactListStateCP.results = response;
            contactListStateCP.massiveCheckAll = checkIfAllSelected(response, contactListStateCP.massiveSelected);
            contactListStateCP.groupCheckAll = checkIfAllSelected(response, contactListStateCP.groupSelected);
        }

        if (props.isGroupAdding) {
            contactListStateCP.isGroup = true;
        }
        setContactListState(contactListStateCP);
    }

    useEffect(() => {
        let isSuscribed = true;

        const data = loadData();

        data.then((response) => {
            if (isSuscribed) {
                handleResponse(response);
            }
        });

        if (props.isGroupAdding) {
            let contactListStateCP = JSON.parse(JSON.stringify(contactListState));
            contactListStateCP.isGroup = true;
            contactListStateCP.isMassive = false;
            contactListStateCP.massiveSelected = [];

            if (isSuscribed) {
                setContactListState(contactListStateCP);
            }
        }

        return () => isSuscribed =  false
    }, []);

    useEffect(() => {
        let isSuscribed = true;
        const data = loadData(contactListState.searchTerm);
        data.then((response) => {
            if (isSuscribed) {
                handleResponse(response);
            }
        });

        if (props.isGroupAdding) {
            let contactListStateCP = JSON.parse(JSON.stringify(contactListState));
            contactListStateCP.isGroup = true;
            contactListStateCP.isMassive = false;
            contactListStateCP.massiveSelected = [];

            if (isSuscribed) {
                setContactListState(contactListStateCP);
            }
        }

        return () => isSuscribed =  false
    }, [activeFilters, contactListState.searchTerm]);

    useEffect(() => {
        let isSuscribed = true;

        if (isSuscribed && props.activeFilters) {
            setActiveFilters(props.activeFilters);
        }
        return () => isSuscribed =  false
    }, [props.activeFilters]);

    const onUserSelected =  (item) => {
        if (contactListState.isMassive || contactListState.isGroup) {

            let contactListStateCP = JSON.parse(JSON.stringify(contactListState));
            let filtered = contactListStateCP.results.filter(result => result.clv_usuario === item.clv_usuario);

            if (filtered.length) {
                const pushingElement = {clv_destinatario: filtered[0].clv_usuario, name: filtered[0].NombreCompleto, image: filtered[0].ThumbnailUrl, clv_estatusbm: filtered[0].clv_estatusbm};

                if (contactListState.isMassive) {
                    console.log(props);
                        let remainingMessages = props.currentMessagesCount.Limite - props.currentMessagesCount.Mensajes;


                    if (contactListStateCP.massiveSelected.length) {
                        const massiveFiltered = contactListStateCP.massiveSelected.filter(element => element.clv_destinatario === filtered[0].clv_usuario);
                        if (massiveFiltered.length) {
                            const index = contactListStateCP.massiveSelected.indexOf(massiveFiltered[0]);

                            if (index >= 0) {
                                contactListStateCP.massiveSelected.splice(index,1);
                            }
                        } else {

                            if(contactListStateCP.massiveSelected.length > (remainingMessages - 1)){
                                swalAlertsService.showToast('error',`No puedes seleccionar más contactos, alcanzaste tu límite mensual.`);
                                return null;
                            }
                            contactListStateCP.massiveSelected.push(pushingElement);
                        }

                    } else {
                        if(contactListStateCP.massiveSelected.length > (remainingMessages - 1)){
                            swalAlertsService.showToast('error',`No puedes seleccionar más contactos, alcanzaste tu límite mensual.`);
                            return null;
                        }
                        contactListStateCP.massiveSelected.push(pushingElement);
                    }

                    contactListStateCP.massiveCheckAll = checkIfAllSelected(contactListStateCP.results, contactListStateCP.massiveSelected);
                } else {
                    if (props.isGroupAdding) {
                        if (!checkIfGroupAddingSelected(item)) {
                            if (contactListStateCP.groupSelected.length) {
                                const groupFiltered = contactListStateCP.groupSelected.filter(element => element.clv_destinatario === filtered[0].clv_usuario);
                                if (groupFiltered.length) {
                                    const index = contactListStateCP.groupSelected.indexOf(groupFiltered[0]);

                                    if (index >= 0) {
                                        contactListStateCP.groupSelected.splice(index,1);
                                    }
                                } else {
                                    contactListStateCP.groupSelected.push(pushingElement);
                                }

                            } else {
                                contactListStateCP.groupSelected.push(pushingElement);
                            }

                            contactListStateCP.groupCheckAll = checkIfAllSelected(contactListStateCP.results, contactListStateCP.groupSelected);
                        }
                    } else {
                        if (contactListStateCP.groupSelected.length) {
                            const groupFiltered = contactListStateCP.groupSelected.filter(element => element.clv_destinatario === filtered[0].clv_usuario);
                            if (groupFiltered.length) {
                                const index = contactListStateCP.groupSelected.indexOf(groupFiltered[0]);

                                if (index >= 0) {
                                    contactListStateCP.groupSelected.splice(index,1);
                                }
                            } else {
                                contactListStateCP.groupSelected.push(pushingElement);
                            }

                        } else {
                            contactListStateCP.groupSelected.push(pushingElement);
                        }

                        contactListStateCP.groupCheckAll = checkIfAllSelected(contactListStateCP.results, contactListStateCP.groupSelected);
                    }
                }
                setContactListState(contactListStateCP);

            }
        } else if (props.isInvitation) {

            if (props.isMultiple) {

                let contactListStateCP = JSON.parse(JSON.stringify(contactListState));
                if (contactListStateCP.invitationsSelected.length) {
                    let filtered = contactListStateCP.invitationsSelected.filter(value => value.clv_usuario === item.clv_usuario);

                    if (filtered.length) {
                        const index = contactListStateCP.invitationsSelected.indexOf(filtered[0]);

                        if (index >= 0 ) {
                            contactListStateCP.invitationsSelected.splice(index, 1);
                        }
                    } else {
                        contactListStateCP.invitationsSelected.push({
                            clv_usuario: item.clv_usuario,
                            NombreCompleto: item.NombreCompleto
                        });
                    }
                } else {
                    contactListStateCP.invitationsSelected.push({
                        clv_usuario: item.clv_usuario,
                        NombreCompleto: item.NombreCompleto
                    });
                }
                setContactListState(contactListStateCP);
            } else {
                let contactListStateCP = JSON.parse(JSON.stringify(contactListState));
                if (contactListStateCP.invitationSelected) {

                    if (contactListStateCP.invitationSelected.clv_usuario) {
                        if (contactListStateCP.invitationSelected.clv_usuario === item.clv_usuario) {
                            contactListStateCP.invitationSelected = {
                                clv_usuario: null,
                                NombreCompleto: null
                            }
                        } else {
                            contactListStateCP.invitationSelected = {
                                clv_usuario: item.clv_usuario,
                                NombreCompleto: item.NombreCompleto
                            }
                        }
                    } else if (contactListStateCP.invitationSelected.NombreCompleto) {
                        if (contactListStateCP.invitationSelected.NombreCompleto === item.NombreCompleto) {
                            contactListStateCP.invitationSelected = {
                                clv_usuario: null,
                                NombreCompleto: null
                            }
                        } else {
                            contactListStateCP.invitationSelected = {
                                clv_usuario: item.clv_usuario,
                                NombreCompleto: item.NombreCompleto
                            }
                        }
                    } else {
                        contactListStateCP.invitationSelected = {
                            clv_usuario: item.clv_usuario,
                            NombreCompleto: item.NombreCompleto
                        }
                    }
                } else {
                    contactListStateCP.invitationSelected = {
                        clv_usuario: item.clv_usuario,
                        NombreCompleto: item.NombreCompleto
                    }
                }
                setContactListState(contactListStateCP);
            }


        } else {
            if (props.onUserSelected) {
                props.onUserSelected(item);
            }
        }
    }

    const checkIfAllSelected = (results, selected) => {

        if (results && results.length && selected && selected.length) {
            let count = 0;

            results.forEach(result => {
                let filtered = selected.filter(element => element.clv_destinatario === result.clv_usuario);

                if (props.isGroupAdding && props.groupPreviusUser && props.groupPreviusUser.length && checkIfGroupAddingSelected(result)) {
                    count += 1;
                }
                count += filtered.length ? 1:0
            });
            return results.length === count;
        }
        return false;
    }

    const onContactInputChanged = (e) => {
        let contactListStateCP = JSON.parse(JSON.stringify(contactListState));
        contactListStateCP.searchTerm = e.target.value;
        setContactListState(contactListStateCP);

    }

    const onContactInputCleared = () => {
        let element = document.getElementById('contact-searcher-input');

        if (element) {
            element.value = '';
        }
        let contactListStateCP = JSON.parse(JSON.stringify(contactListState));
        contactListStateCP.searchTerm = '';
        setContactListState(contactListStateCP);
    }

    const onMassiveSwitcher = (value) => {
        console.log('switch');
        let contactListStateCP = JSON.parse(JSON.stringify(contactListState));
        contactListStateCP.isMassive = value;
        contactListStateCP.isGroup = false;
        contactListStateCP.groupSelected = [];
        contactListStateCP.massiveSelected =[];
        console.log(contactListStateCP);
        setContactListState(contactListStateCP);
    }

    const onGroupSwitcher = (value) => {
        let contactListStateCP = JSON.parse(JSON.stringify(contactListState));
        contactListStateCP.isGroup = value;
        contactListStateCP.isMassive = false;
        contactListStateCP.massiveSelected = [];
        setContactListState(contactListStateCP);
    }

    const onMassiveChecker = (value) => {
        let contactListStateCP = JSON.parse(JSON.stringify(contactListState));
        contactListStateCP.groupSelected = [];
        contactListStateCP.isGroup = false;

        contactListStateCP.massiveCheckAll = value;
        if (contactListStateCP.results.length && value) {
            contactListStateCP.massiveSelected = contactListStateCP.results.map(result => {
                return {clv_destinatario: result.clv_usuario, name: result.NombreCompleto}
            });
        } else {
            contactListStateCP.massiveSelected = [];
        }
        setContactListState(contactListStateCP);
    }

    const onGroupChecker = (value) => {
        let contactListStateCP = JSON.parse(JSON.stringify(contactListState));
        contactListStateCP.massiveSelected = [];
        contactListStateCP.isMassive = false;

        contactListStateCP.groupCheckAll = value;
        if (contactListStateCP.results.length && value) {
            if (props.isGroupAdding && props.groupPreviusUser && props.groupPreviusUser.length) {
                let selected = [];

                contactListStateCP.results.forEach((result) => {
                    if (!checkIfGroupAddingSelected(result)) {
                        selected.push({clv_destinatario: result.clv_usuario, name: result.NombreCompleto, image: result.ThumbnailUrl});
                    }
                });

                contactListStateCP.groupSelected = selected;
            } else {
                contactListStateCP.groupSelected = contactListStateCP.results.map(result => {
                    return {clv_destinatario: result.clv_usuario, name: result.NombreCompleto, image: result.ThumbnailUrl}
                });
            }
        } else {
            contactListStateCP.groupSelected = [];
        }
        setContactListState(contactListStateCP);
    }

    const onMassiveSendBtnTapped = () => {
        if (props.onMassiveSendBtnTapped) {
            console.log(props.Broadcast);

            if (contactListState.massiveSelected.length >= 2 ) {
                props.onMassiveSendBtnTapped(contactListState.massiveSelected);
            }else{
                swalAlertsService.showToast('error','Debes seleccionar al menos 2 usuarios');
            }
        }
    }

    async function createGroup(obj) {
        return await messagesService.createGroup(obj);
    }

    const onInvitationSendBtnTapped = () => {
        if (props.onInvitationAdded) {

            if (props.isMultiple) {
                if (contactListState.invitationsSelected.length) {
                    props.onInvitationAdded(contactListState.invitationsSelected);
                }//else{
                   // swalAlertsService.showToast('error','Debes seleccionar al menos 2 usuarios');
                //}
            } else {
                if(contactListState && contactListState.invitationSelected && contactListState.invitationSelected.NombreCompleto && contactListState.invitationSelected.clv_usuario){
                    props.onInvitationAdded(contactListState.invitationSelected);
                }else{
                    swalAlertsService.showToast('error','Debes seleccionar un usuario');
                }

            }

        }
    }

    const onGroupSendBtnTapped = () => {
        if (checkIfCanContinue('group')) {

            if (props.isGroupAdding) {
                if (props.onGroupSendBtnTappedContactList) {
                    props.onGroupSendBtnTappedContactList(contactListState.groupSelected);
                }
            } else {
                if (isGroupSubmit) {
                    if (contactListState.groupDetails.name.length) {
                        let groupObj = {
                            Titulo: contactListState.groupDetails.name,
                            Usuarios: contactListState.groupSelected.map(el => { return {clv_usuario: el.clv_destinatario}}),
                            Descripcion: ''
                        }

                        if (contactListState.groupDetails.image && contactListState.groupDetails.image.base64) {
                            groupObj.Imagen = {
                                clv_tipoadjunto: 3,
                                Nombre: contactListState.groupDetails.image.name || null,
                                Extension: 'jpg',
                                Base64: contactListState.groupDetails.image.splitted
                            }
                        }

                        const group = createGroup(groupObj);

                        group.then((response) => {
                            if (response && response.data && response.data.clv_conversacion) {
                                if (props.onGroupSendBtnTapped) {
                                    props.onGroupSendBtnTapped({clv_conversacion: response.data.clv_conversacion});
                                }
                            } else {
                                swalAlertsService.showToast('error','Error al intentar crear grupo');
                            }
                        });
                    } else {
                        swalAlertsService.showToast('error','Debes asignarle un nombre al grupo');
                    }
                } else {
                    setIsGroupSubmit(true);
                }
            }
        } else {
            swalAlertsService.showToast('error',`Debes seleccionar al menos a ${props.isGroupAdding ? '1':'2'} ${props.isGroupAdding ? 'usuario':'usuarios'}`);
        }
    }

    const getFooterBtnTitle = () => {
        if (props.isGroupAdding) {
            return 'Añadir participantes';
        }

        if (isGroupSubmit) {
            return 'Iniciar conversación';
        }

        return 'Crear grupo';
    }

    const checkIfCanContinue = (type) => {
        if (type === 'massive') {
            if (contactListState.massiveSelected.length >= 2) {
                return true;
            }
        } else if (type === 'group') {

            if (contactListState.groupSelected.length) {
                if (props.isGroupAdding) {
                    if (contactListState.groupSelected.length >= 1) {
                        return true;
                    }
                } else if (contactListState.groupSelected.length >= 2) {
                    return true;
                }
            }
        }  else if (type === 'invitation') {
            if (props.isMultiple) {
                return contactListState.invitationsSelected.length
            } else if (
                contactListState.invitationSelected &&
                (
                    contactListState.invitationSelected.clv_usuario ||
                    contactListState.invitationSelected.NombreCompleto
                )
            ) {
                return true;
            }
        }




        return false;
    }
    const contactSearcherFooterBtn = () => {
        if (props.isInvitation) {
            return <button className={`btn-cleared ${checkIfCanContinue('invitation') ? 'active':''}`} onClick={onInvitationSendBtnTapped}>
                Seleccionar
            </button>
        }

        if (contactListState.isMassive) {
            return <button className={`btn-cleared ${checkIfCanContinue('massive') ? 'active':''}`} onClick={onMassiveSendBtnTapped}>Enviar mensaje</button>
        } else if (contactListState.isGroup) {

            return <button className={`btn-cleared ${checkIfCanContinue('group') ? 'active':''}`} onClick={onGroupSendBtnTapped}>
                {getFooterBtnTitle()}
            </button>
        }
        return null;
    }

    const onBottomSheetDimiss = () => {
        if (isGroupSubmit) {
            setIsGroupSubmit(false);
            setIsBottomSheetOpen(true);
        } else {
            if (props.onBottomSheetDimiss) {
                props.onBottomSheetDimiss();
            }
        }
    }

    const onGroupPreSendCancel = () => {
        setIsGroupSubmit(false);
    }

    const renderHeader = () => {

        if (props.isInvitation) {
            return null;
        }
        if (contactListState.isMassive) {
            let contactListStateCP = JSON.parse(JSON.stringify(contactListState));
            return (
                <div className="Contact-searcher-allpicker-container row m-0 d-flex justify-content-end">
                    {/* <div className=" d-none col p-0 chekbox-col">
                        <button className="btn-cleared" onClick={e => onMassiveChecker(!contactListState.massiveCheckAll)}>
                            <img src={contactListState.massiveCheckAll ? icnCheckBoxSelected:icnCheckBox} alt="checkbox icon" width='30' height='30'/>
                        </button>
                    </div> */}
                    <div className="col text-col noselect">
                        <span className='Contact-searcher-massive-switcher p-0'> {contactListStateCP.massiveSelected.length} de {(props.currentMessagesCount.Limite - props.currentMessagesCount.Mensajes)} </span> <span className='text-muted'> contactos disponibles </span>
                    </div>
                    <div className="col p-0 cancel-btn-col">
                        <button className="btn-cleared text-danger" onClick={e => onMassiveSwitcher(false)}>
                            Cancelar
                        </button>
                    </div>
                </div>
            );
        } else if (contactListState.isGroup) {
            return (
                <div className="Contact-searcher-allpicker-container row m-0 d-flex align-items-center">
                    <div className="col p-0 chekbox-col">
                        <button className="btn-cleared" onClick={e => onGroupChecker(!contactListState.groupCheckAll)}>
                            <img src={contactListState.groupCheckAll ? icnCheckBoxSelected:icnCheckBox} alt="checkbox icon" width='30' height='30'/>
                        </button>
                    </div>
                    <div className="col text-col mouse-pointer noselect" onClick={e => onGroupChecker(!contactListState.groupCheckAll)}>
                        Seleccionar todos
                    </div>
                    <div className="col p-0 cancel-btn-col">
                        {
                            !props.isGroupAdding &&
                            <button className="btn-cleared text-danger" onClick={e => onGroupSwitcher(false)}>
                                Cancelar
                            </button>
                        }
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    {
                        !isHiddingGroup &&
                        <>
                            <div className="Contact-searcher-massive-switcher noselect mouse-pointer" onClick={e => onGroupSwitcher(true)}>
                                <img src={icnGroup} alt="broadcast icon" className="noselect"/>
                                Crear grupo
                            </div>
                            <hr className='ContactList-separator-line'/>
                        </>
                    }
                    <div className={`Contact-searcher-massive-switcher noselect mouse-pointer ${!isHiddingGroup ? 'second':''} d-flex w-100`} onClick={e => onMassiveSwitcher(true)}>
                        <img src={icnBroadcast} alt="broadcast icon" className="noselect col-2"/>
                        <div className='col-10 align-self-center'>
                            <span className=''> Enviar mensaje masivo </span>
                            <br />
                            <span className='text-muted'> Máx. a 20 contactos por mes </span>
                        </div>
                    </div>
                </>
            )
        }
    }
    const contactSearcherElement = () => {
        return (
            <>
                <div className="Contact-searcher-container">
                    <label htmlFor="">
                        <img src={icnSearch} alt="searcjer icon"/>
                    </label>
                    <input type="text" id='contact-searcher-input' name='contact-searcher-input' onKeyUp={onContactInputChanged}/>
                    {
                        contactListState.searchTerm &&
                        <button className="btn-cleared" onClick={onContactInputCleared}>
                            <img src={icnCancel} alt="cancel icon"/>
                        </button>
                    }
                </div>
                {renderHeader()}
            </>
        );
    }

    const isChecked = (item, type) => {

        let filtered = [];
        if (type === 'massive') {
            filtered = contactListState.massiveSelected.filter(value => value.clv_destinatario === item.clv_usuario);
        } else if (type === 'invitation') {

            if (props.isMultiple) {
                filtered = contactListState.invitationsSelected.filter(value => value.clv_usuario === item.clv_usuario);
            } else {
                if (contactListState.invitationSelected) {
                    if (contactListState.invitationSelected.clv_usuario) {
                        if (contactListState.invitationSelected.clv_usuario === item.clv_usuario) {
                            filtered = [1];
                        }
                    } else if (contactListState.invitationSelected.NombreCompleto) {
                        if (contactListState.invitationSelected.NombreCompleto === item.NombreCompleto) {
                            filtered = [1];
                        }
                    }

                }
            }

        } else {
            filtered = contactListState.groupSelected.filter(value => value.clv_destinatario === item.clv_usuario);
            if (checkIfGroupAddingSelected(item)) {
                return true;
            }
        }

        return filtered.length;
    }

    const checkIfGroupAddingSelected = (item) => {
        if (props.isGroupAdding && props.groupPreviusUser && props.groupPreviusUser.length && item) {
            const filtered = props.groupPreviusUser.filter(el => el.clv_usuario === item.clv_usuario);
            return filtered.length ? true:false;
        }
        return false;
    }

    const resultsRender = () => {
        const {results} = contactListState;

        if (results && results.length) {
            const items = results.map((item, index) => {
                return (
                    <div className="col-12 p-0 mouse-pointer" key={index} onClick={e => onUserSelected(item)}>
                        <div className="row m-0">
                            {
                                contactListState.isMassive &&
                                <div className="col ContactList-result-checker-container">
                                    <img src={isChecked(item, 'massive') ? icnBulletSelected:icnBullet} alt="bullet icon"/>
                                </div>
                            }

                            {
                                props.isInvitation &&
                                <div className="col ContactList-result-checker-container">
                                    <img src={isChecked(item, 'invitation') ? icnBulletSelected:icnBullet} alt="bullet icon"/>
                                </div>
                            }

                            {
                                contactListState.isGroup &&
                                <div className={`col ContactList-result-checker-container ${checkIfGroupAddingSelected(item) ? 'active-blocked':''}`}>
                                    <img src={isChecked(item, 'group') ? icnBulletSelected:icnBullet} alt="bullet icon"/>
                                </div>
                            }
                            <div className="col ContactList-result-img-container">
                                <img src={item.ThumbnailUrl || imgUserPlaceholder} alt="user image" onError={(e)=>{e.target.onerror = null; e.target.src=imgUserPlaceholder}} className="avatar"/>
                                {
                                    item.clv_estatusbm === 2 &&
                                    <img src={start} width="5px" height="5px" alt="miembro" className="miembro"/>
                                }
                            </div>
                            <div className="col ContactList-result-name-container">
                                <div className="row m-0 h-100 d-flex align-items-center">
                                    <div className="col-12 p-0 title text-block-truncate-1">{item.NombreCompleto}</div>

                                    {
                                        item.EmpresaNombreCorto || item.Cargo ? (
                                            <div className="col-12 p-0 subtitle text-block-truncate-1">{`${item.EmpresaNombreCorto}${item.Cargo ? ' | ':''}${item.Cargo}`}</div>
                                        ):(null)
                                    }

                                    {
                                        item.EstadoResidencia || item.PaisResidencia ? (
                                            <div className="col-12 p-0 subtitle text-block-truncate-1">{`${item.EstadoResidencia ? item.EstadoResidencia:''}${item.EstadoResidencia && item.PaisResidencia ? ', ':''}${item.PaisResidencia ? item.PaisResidencia:''}`}</div>
                                        ):(null)
                                    }
                                    {
                                        props.isGroupAdding && checkIfGroupAddingSelected(item) &&
                                        <div className="col-12 p-0 subtitle text-block-truncate-1">Ya participa en este grupo</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });

            return <div className="row m-0 ContactList-results-container">{items}</div>;
        }
        return <div className="row m-0 ContactList-noresults-container">
            <NoResults/>
        </div>
    }

    const destopHeaderTitle = (isNewGroup) => {
        if (props.destopHeaderTitle) {
            return props.destopHeaderTitle;
        }
        return isNewGroup ? 'Crear grupo' :  props && props.isRegister ? 'Seleccionar usuario' :'Nueva conversación';
    }

    const headerView = (isNewGroup) => {
        return (
            <div className="ContantList-headerView-container">
                {
                    shouldShowFilterBtn() ? (
                        <button className='btn-cleared filters' onClick={onFilterBtnTapped}>
                            <img src={checkActiveFilters() ? icnFilterSelected:icnFilter} alt="filter icon" width='30' height='30'/>
                        </button>
                    ):(null)
                }

                <span>{destopHeaderTitle(isNewGroup)}</span>
                <button className="btn-cleared" onClick={onBottomSheetDimiss}>
                    <img src={icnClose} alt="icnClose"/>
                </button>

            </div>
        )
    }

    const mainRender = () => {
        return (
            <>
                {
                    width && width >= 1450 ? (headerView()):(null)
                }
                {contactSearcherElement()}
                <div className={`ContactList-container ${props.isDesktop ? 'isDesktop':''} ${props.isInvitation ? 'invitation':`${contactListState.isMassive || contactListState.isGroup ? 'massive':'switcher'}`} ${isHiddingGroup ? 'hide-group':''}`}>
                    {resultsRender()}
                </div>

                {
                    width && width >= 1450 && (props.isInvitation || contactListState.isMassive || contactListState.isGroup) ? (
                        <div className={`ContactList-footer-box ${props.isDesktop ? 'isDesktop':''}`}>
                            {contactSearcherFooterBtn()}
                        </div>
                    ):(null)
                }
            </>
        )
    }

    const onFilterBtnTapped = () => {
        setShowFilters(true);
    }

    const onFilterDismiss = () => {
        setShowFilters(false);
    }

    const onSubmit = (data) => {
        if (data.activeFilters && (data.activeFilters.filtersSelected || data.activeFilters.postedBy)) {
            setActiveFilters({
                filtersSelected: data.activeFilters.filtersSelected,
                postedBy: data.activeFilters.postedBy,
                isUsingFilters: true
            });

        } else {
            setActiveFilters({
                filtersSelected: null,
                postedBy: null,
                isUsingFilters: false
            });
        }

        setShowFilters(false);
    }

    const checkActiveFilters = () => {
        if (activeFilters && (activeFilters.filtersSelected || (activeFilters.postedBy && activeFilters.postedBy.value !== 1))) {
            return true;
        }
        return false;
    }

    const resetFile = () => {
        setTimeout(() => {
            if (inputFile && inputFile.current) {
                inputFile.current.value = ''
            }
        }, 200);
    }


    const onGroupImageBtnTapped = () => {
        inputFile.current.click();
    }

    const onGroupNameChanged = (e) => {

        let contactListStateCP = JSON.parse(JSON.stringify(contactListState));
        contactListStateCP.groupDetails.name = e.target.value;
        setContactListState(contactListStateCP);

    }

    async function processFile (event) {
        const result = await toolsHelper.getFile(event);
        let contactListStateCP = JSON.parse(JSON.stringify(contactListState));
        contactListStateCP.groupDetails.image = result;
        setContactListState(contactListStateCP);
        resetFile();
    }

    const onMemberDelete = (member) => {
        let contactListStateCP = JSON.parse(JSON.stringify(contactListState));

        if (contactListStateCP.groupSelected.length > 2) {
            const filtered = contactListStateCP.groupSelected.filter(group => group.clv_destinatario === member.clv_destinatario);

            if (filtered.length) {
                const index = contactListStateCP.groupSelected.indexOf(filtered[0]);
                contactListStateCP.groupSelected.splice(index,1);
                setContactListState(contactListStateCP);
            }
        } else {
            swalAlertsService.showToast('error','Debes seleccionar al menos a 2 usuarios');
        }
    }

    const newGroupMembers = () => {
        let members = [];

        if (contactListState.groupSelected.length) {
            members = contactListState.groupSelected.map((member, index) => {
                return (
                    <div className="col ContactList-group-member pos-relative" key={index}>
                        <div className="row m-0">
                            <div className="col-12 p-0 mouse-pointer">
                                <div className="row m-0">
                                    <div className="col-12 p-0">
                                        <img src={member.image ? member.image:imgUserPlaceholder}
                                            alt="user icon"
                                            className='img-user'
                                            draggable='false'
                                            onError={(e)=>{e.target.onerror = null; e.target.src=imgUserPlaceholder}}
                                        />
                                        <button className="btn-cleared deleter-btn" onClick={e => onMemberDelete(member)}>
                                            <img src={icnDeletContact} alt="Delete contact icon" draggable='false'/>
                                        </button>
                                        {
                                            member.clv_estatusbm === 2 &&
                                            <img src={start} width="10px" height="10px" alt="miembro" className="miembro"/>
                                        }
                                    </div>
                                    <div className="col-12 px-0 pt-1 text-truncate member-name mouse-pointer">
                                        {member.name.split(' ')[0]}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                );
            });

        }

        return (
            <div className="ContactList-group-members-container row m-0">
                {members}
            </div>
        );
    }
    const newGroupBox = () => {

        return (
            <div className="ContactList-group-container h-100">
                {newGroupHeaderRow()}
                {newGroupMembersRow()}
                {
                    width && width >= 1450 && contactListState.isGroup ? (
                        <div className={`ContactList-footer-box ${props.isDesktop ? 'isDesktop':''}`}>
                            {contactSearcherFooterBtn()}
                        </div>
                    ):(null)
                }
            </div>
        );
    }

    const newGroupHeaderRow = () => {

        return (
            <div className="ContactList-group-header-row row m-0">

                {
                    width && width >= 1450 ? (headerView(true)):(null)
                }
                <div className="col p-0 image-col mouse-pointer" onClick={onGroupImageBtnTapped}>
                    <div className="row m-0">
                        {
                            contactListState.groupDetails.image && contactListState.groupDetails.image.base64 ? (
                                <>
                                    <div className="col-12 p-0 camera-col">
                                        <img src={contactListState.groupDetails.image.base64} alt="group Icon" draggable='false'/>
                                    </div>


                                </>
                            ):(
                                <div className="col-12 p-0 camera-col">
                                    <img src={icnCamera} alt="Camera Icon" draggable='false'/>
                                </div>
                            )
                        }

                        <div className={`col-12 px-0 edit-col ${contactListState.groupDetails.image && contactListState.groupDetails.image.base64 ? 'showing':''}`}>
                            <button className="btn-cleared">
                                Editar
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col input-col">
                    <input id='groupNameInput'
                        type="text"
                        placeholder='Nombre del grupo'
                        maxLength='80'
                        onKeyUp={onGroupNameChanged}
                    />
                </div>

                <input id="image"
                    type="file"
                    ref={inputFile}
                    onChange={(event) => {
                        processFile(event);
                    }}
                    onError={error => console.log(error)}
                    accept="image/*"
                    style={{display: 'none'}}
                    multiple={false}
                />
            </div>
        );
    }

    const newGroupMembersRow = () => {

        return (
            <div className="ContactList-group-members-row row m-0">

                <div className="col-12 title-header">
                    Participantes ({contactListState.groupSelected.length})
                </div>

                <div className="col-12 p-0">
                    {newGroupMembers()}
                </div>
            </div>
        );
    }

    const shouldShowFilterBtn = () => {

        if (props.isGroupAdding || props.isInvitation) {
            return false;
        }
        return isGroupSubmit ? false:true
    }

    const defineTitle = () => {
        if (props.isInvitation) {
            return 'Seleccionar';
        }

        return `${!isGroupSubmit ? 'Nueva conversación':'Crear grupo'}`;
    }

    const renderBox = () => {
        if (showFilters) {
            return (
                <Filters onDismiss={onFilterDismiss}
                    onSubmit={onSubmit}
                    activeFilters={activeFilters}
                    showAllTags={true}
                    hidePostedBy={true}
                    showSubmit={true}
                />
            )
        } else {
            return (
                <BottomSheetShared showHeader={true} showCancelBtn={true}
                    blocking={true}
                    isOpen={isBottomSheetOpen}
                    onDismiss={onBottomSheetDimiss}
                    title={defineTitle()}
                    footerBtn={contactSearcherFooterBtn()}
                    onFilterBtnTapped={onFilterBtnTapped}
                    showFilterBtn={shouldShowFilterBtn()}
                    isActiveFilters={checkActiveFilters()}
                    shouldClose={isGroupSubmit ? false:true}
                >
                    {
                        !isGroupSubmit ? (
                            mainRender()
                        ):(
                            newGroupBox()
                        )
                    }
                </BottomSheetShared>
            )
        }
    }

    const desktopRender =  () => {
        if (showFilters) {
            return (
                <Filters onDismiss={onFilterDismiss}
                    onSubmit={onSubmit}
                    activeFilters={activeFilters}
                    showAllTags={true}
                    hidePostedBy={true}
                    showSubmit={true}
                />
            )
        } else {
            return (
                <ModalDefault show={true} className='full-view'>
                    {
                        !isGroupSubmit ? (
                            mainRender()
                        ):(
                            newGroupBox()
                        )
                    }
                </ModalDefault>

            )

            // <BottomSheetShared showHeader={true} showCancelBtn={true}
            //     blocking={true}
            //     isOpen={isBottomSheetOpen}
            //     onDismiss={onBottomSheetDimiss}
            //     title={defineTitle()}
            //     footerBtn={contactSearcherFooterBtn()}
            //     onFilterBtnTapped={onFilterBtnTapped}
            //     showFilterBtn={shouldShowFilterBtn()}
            //     isActiveFilters={checkActiveFilters()}
            //     shouldClose={isGroupSubmit ? false:true}
            // >
            //     {
            //         !isGroupSubmit ? (
            //             mainRender()
            //         ):(
            //             newGroupBox()
            //         )
            //     }
            // </BottomSheetShared>
        }
    }
    return (
        <>
            {
                width && width >= 1450 ? (desktopRender()):(renderBox())
            }
        </>
    );
}

export { ContactList };
