import React, { useState, useEffect } from 'react';
import './DirectoryList.scss';
import { Tabs, Tab } from 'react-bootstrap';

import { DirectoryListTable } from '../';

const DirectoryList = (props) => {

    const unique = (users) => {
        var uniquePeople = [];

        if (users && users.length) {
            users.forEach((user) => {
                if (
                    user &&
                    user.EmpresaNombreCorto
                ) {
                    let filtered = uniquePeople.filter(el => el.EmpresaNombreCorto.toLowerCase() === user.EmpresaNombreCorto.toLowerCase());
                    if (!filtered.length) {
                        uniquePeople.push(user)
                    }
                }
            });
        }
        return uniquePeople;
    }

    const getTabsTitle = (type) => {
        let title = type === 'users' ? 'Miembros': type === 'mentors' ? 'Mentores' :  'Empresas';
        return title;
    }

    const loadMore = () => {
        if (props.loadMore) {
            props.loadMore();
        }
    }

    return (
        <div className="DirectoryList-container row m-0">

            <div className="col-12 p-0">

                <div className="row m-0 DirectoryList-tabs-box">
                    <div className="col-12 p-0">

                        <Tabs defaultActiveKey="users" onSelect={(activeDefaultTab) => {
                                if (props.onTabSelected) {
                                    props.onTabSelected(activeDefaultTab);
                                }
                            }}>
                            <Tab eventKey="users" title={getTabsTitle('users')}>
                                <DirectoryListTable mode='users' items={props.directoryState.results} currentUser={props.currentUser} loadMore={loadMore} isFetchingData={props.isFetchingData}/>
                            </Tab>
                            <Tab eventKey="companies" title={getTabsTitle('companies')}>
                                <DirectoryListTable mode='companies' items={props.directoryState.results} currentUser={props.currentUser} loadMore={loadMore} isFetchingData={props.isFetchingData}/>
                            </Tab>
                            <Tab eventKey="mentors" title={getTabsTitle('mentors')}>
                                <DirectoryListTable mode='mentors' items={props.directoryState.results} currentUser={props.currentUser} loadMore={loadMore} isFetchingData={props.isFetchingData}/>
                            </Tab>
                        </Tabs>

                    </div>
                </div>

            </div>
        </div>
    );
}

export { DirectoryList }
