import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import './DirectoryEnterpricesModal.scss';

import { BottomSheetShared, ModalDefault } from '..';
import { infoService, profileService, swalAlertsService, authenticationService } from '../../../_resources/services';
import { useWindowDimensions } from '../../../_resources/helpers';

import icnsNetworks from '../../../_assets/media/images/icons/icns_networks.png';
import icnDeleteFilter from '../../../_assets/media/images/icons/icn_delete_filter.png';
import icnClose from '../../../_assets/media/images/icons/icn_close.png';
import icnUserPlaceholder from '../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnStar from '../../../_assets/media/images/icons/icn_batch_star.svg';

const DirectoryEnterpricesModal = (props) => {
    const [currentUser, setCurrentUser] = useState(authenticationService.currentUserValue);
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(true);
    const [inputValues, setInputValues] = useState([]);
    const [inputValuesBase, setInputValuesBase] = useState([]);
    const [catalogs, setCatalogs] = useState(null);
    const [viewTitle, setViewTitle] = useState("Usuarios");
    const history = useHistory();

    const {width, height} = useWindowDimensions();
    const onBottomSheetDimiss = () => {
        if (props.onBottomSheetDimiss) {
            props.onBottomSheetDimiss();
        }
    }


    const getInputValue = (key) => {

        if (key && inputValues && inputValues.length) {
            let filtered = inputValues.filter(value => value.RedSocial === key);

            if (filtered.length && filtered[0].Activo) {
                return filtered[0].Perfil;
            }
        }

        return null;
    }

    const setupInputs = () => {
        setTimeout(() => {
            if (props.networks && props.networks.length) {
                props.networks.forEach((network) => {
                    if (network.RedSocial) {
                        let element = document.getElementById(network.RedSocial);

                        if (element) {
                            element.value = network.Perfil;
                        }
                    }
                });
            }
        },200);
    }

    const prepareCatalogs = () => {
        infoService.catalogs.subscribe((catalogs) =>{
            if (catalogs) {
                setCatalogs(catalogs);
            }
        });
    }

    useEffect(() => {
        let isSuscribed = true;
        setupInputs();
        prepareCatalogs();
        if (props.networks && props.networks.length) {
            if (isSuscribed) {
                setInputValues(props.networks);
                setInputValuesBase(JSON.parse(JSON.stringify(props.networks)));
            }
        }

        if (props.title) {
            setViewTitle(props.title);
        }

        return () => isSuscribed = false;
    }, []);

    const viewHasChanges = () => {
        let inputValuesCP = JSON.stringify(inputValues);
        let inputValuesBaseCP = JSON.stringify(inputValuesBase);

        if (inputValuesCP !== inputValuesBaseCP) {
            return true;
        }
        return false;
    }

    const footerBtn = () => {
        return <button className={`btn-cleared ${ viewHasChanges() ? 'active':''}`} onClick={onSubmitBtnTapped}>
            Guardar
        </button>
    }

    const onSubmitBtnTapped = () => {
        if (viewHasChanges()) {
            let obj = {RedesSociales: inputValues};
            let loader = swalAlertsService.createLoading("Espere un momento...");
            loader.fire();

            profileService.updateSocialNetworks(obj).then(
                (response) => {
                    loader.close();

                    if (response) {
                        if (response.data) {
                            swalAlertsService.showToast("success", "Redes sociales actualizadas correctamente");
                            if (props.dataUpdated) {
                                props.dataUpdated();
                            }
                        } else {
                            swalAlertsService.showToast("error", "Ocurrio un error inténtalo de nuevo más tarde");
                        }
                    }
                }
            )
        }

    }

    const onInputChanged = (e) => {
        if (catalogs) {
            const {RedesSociales} = catalogs;
            if (RedesSociales && RedesSociales.length) {
                let inputValuesCP = JSON.parse(JSON.stringify(inputValues));

                if (inputValuesCP && inputValuesCP.length) {
                    let filteredItem = inputValuesCP.filter(item => item.RedSocial === e.target.name);

                    if (filteredItem.length) {
                        let index = inputValuesCP.indexOf(filteredItem[0]);

                        if (index >= 0) {
                            inputValuesCP[index].Perfil = e.target.value;
                            inputValuesCP[index].Activo = true;

                            setInputValues(inputValuesCP);
                        }
                    } else {
                        let filteredNetwork = RedesSociales.filter(red => red.RedSocial === e.target.name);

                        if (filteredNetwork.length) {
                            let obj = {
                                clv_redsocial: filteredNetwork[0].clv_redsocial,
                                RedSocial: filteredNetwork[0].RedSocial,
                                Perfil: e.target.value,
                                Activo: true
                            }

                            inputValuesCP.push(obj);
                            setInputValues(inputValuesCP);
                        }
                    }
                } else {
                    let filteredNetwork = RedesSociales.filter(red => red.RedSocial === e.target.name);

                    if (filteredNetwork.length) {
                        let obj = {
                            clv_redsocial: filteredNetwork[0].clv_redsocial,
                            RedSocial: filteredNetwork[0].RedSocial,
                            Perfil: e.target.value,
                            Activo: true
                        }
                        setInputValues([obj]);
                    }

                }
            }
        }

    }

    const onNetworkDeleted = (key) => {
        let inputValuesCP = JSON.parse(JSON.stringify(inputValues));

        if (key && inputValuesCP && inputValuesCP.length) {
            let filtered = inputValuesCP.filter(item => item.RedSocial === key);

            if (filtered.length) {
                filtered[0].Activo = false;
                filtered[0].Perfil = '';
                let index = inputValuesCP.indexOf(filtered[0]);

                let element = document.getElementById(key);

                if (element) {
                    element.value = '';
                }
                if (index >= 0) {
                    inputValuesCP[index] = filtered[0];
                    setInputValues(inputValuesCP);
                }
            }
        }
    }

    const goToUser = (user) => {
        if (user && user.clv_usuario) {
            if(currentUser.Usuario.clv_usuario === user.clv_usuario) {
                history.replace('/profile')
            }else {
                history.push(`/profile-user/${user.clv_usuario}` , {from: '/'} )
            }
        }
    }

    const renderUsers = () => {
        if (props.users && props.users.length) {
            return props.users.map((user, index) => {
                return (
                    <div className="col-12 DirectoryEnterpricesModal-item-box px-0" key={index}>
                        <div className="row m-0 mouse-pointer" onClick={() => {goToUser(user)}}>
                            <div className={`col avatar-image-box-60 pos-relative ${user.clv_estatusbm === 2 ? 'isGold':''}`}>
                                <img src={user.ThumbnailUrl ? user.ThumbnailUrl:icnUserPlaceholder} 
                                    alt="User image" 
                                    className="avatar"
                                    onError={(e)=>{
                                        e.target.onerror = null;
                                        e.target.src=icnUserPlaceholder
                                    }}
                                />

                                {
                                    user.clv_estatusbm === 2 &&
                                    <img src={icnStar} alt="icnStar" className='bmIcon'/>
                                }
                            </div>
                            <div className="col pe-0">
                                <div className="row m-0 textCol">
                                    <div className="col-12 px-0 text-truncate nameCol">{user.NombreCompleto}</div>
                                    <div className="col-12 px-0">
                                        <div className="row m-0 line-height-20px">
                                            <div className="col-12 px-0 text-truncate detailsCol">
                                                {
                                                    user.Cargo || user.EmpresaNombreCorto ? (
                                                        `${user.Cargo ? user.Cargo:''}${user.Cargo && user.EmpresaNombreCorto ? ' | ':''}${user.EmpresaNombreCorto ? user.EmpresaNombreCorto:''}`
                                                    ):(<span className='placeholderTxt'>Sin cargo</span>)
                                                }
                                            </div>
                                            <div className="col-12 px-0 text-truncate detailsCol soft">
                                                {
                                                    user.EstadoResidencia || user.PaisResidencia ? (
                                                        `${user.EstadoResidencia ? user.EstadoResidencia:''}${user.EstadoResidencia && user.PaisResidencia ? ', ':''}${user.PaisResidencia ? user.PaisResidencia:''}`
                                                    ):(<span className='placeholderTxt'>Sin ubicación</span>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });

        }

        return null;
    }
    const renderView = (isDesktop) => {
        return (
            <div className={`row m-0 DirectoryEnterpricesModal-container ${isDesktop ? 'isDesktop':''}`}>

                {
                    isDesktop ? (
                        <div className="col-12 DirectoryEnterpricesModal-title-container">
                            <span>{viewTitle}</span>

                            <button className="btn-cleared" onClick={onBottomSheetDimiss}>
                                <img src={icnClose} alt="icnClose"/>
                            </button>
                        </div>
                    ):(null)
                }

                <div className="col-12 DirectoryEnterpricesModal-items-container">
                    <div className="row m-0">
                        {renderUsers()}
                    </div>
                </div>
            </div>
        );
    }

    const mobileRender = () => {
        return (
            <BottomSheetShared showHeader={true} showCancelBtn={true}
                blocking={true}
                isOpen={isBottomSheetOpen}
                onDismiss={onBottomSheetDimiss}
                title={viewTitle}
                footerBtn={false}
                shouldClose={true}
                isCloseSymbol={true}
            >
                {renderView(false)}
            </BottomSheetShared>
        )
    }

    const desktopRender = () => {
        return (
            <ModalDefault show={true} overAll={true} onHide={onBottomSheetDimiss}>
                {renderView(true)}
            </ModalDefault>
        )
    }

    return (
        <React.Fragment>
            {
                width && width >= 1450 ? (desktopRender()):(mobileRender())
            }
        </React.Fragment>

    )
}

export { DirectoryEnterpricesModal };
