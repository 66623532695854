import React, { useState, useEffect } from 'react';

import './MessagesList.scss';
import { Dropdown } from 'react-bootstrap';
import { toolsHelper } from '../../../../../_resources/helpers/';
import { NoResults } from '../../../../shared/';
import imgPlaceholder from '../../../../../_assets/media/images/icons/icn_user_placeholder.png';
import imgPlaceholderGroup from '../../../../../_assets/media/images/icons/icn_placeholder_group.png';
import icnMore from '../../../../../_assets/media/images/icons/icn_more_options.png';
import start from '../../../../../_assets/media/images/icons/icn_batch_star.svg';

const MessagesList = (props) => {
    const [selected, setSelected] = useState("")
    useEffect(() => {
        if(props.contacts) {
            console.log(props.contacts);
        }
    }, [props]);

    useEffect(() => {
        if(props.chats) {
            if(props.chats.length > 0 && selected === "") {
                setSelected(props.chats[0].clv_conversacion)
            }
        }
    }, [props.chats])

    const onChatSelected = (chat, index) => {
        setSelected(chat.clv_conversacion)
        if (props.onChatSelected) {
            props.onChatSelected(chat);
        }
    }

    const onContactSelected = (contact) => {
        if (props.onContactSelected) {
            props.onContactSelected(contact);
        }
    }

    const onChatDelete = (chat) => {
        if (props.onChatDelete) {
            props.onChatDelete(chat);
        }
    }

    const onGroupDelete = (chat) => {
        if (props.onGroupDelete) {
            props.onGroupDelete(chat);
        }
    }

    const getMessageTitle = (chat) => {

        if (chat) {
            if (chat.Mensajes && chat.Mensajes.length) {
                return chat.Mensaje;
            } else {
                return chat.Mensaje && chat.Mensaje.length ? chat.Mensaje:'Grupo creado';
            }
        }
    }
    const isMsgDeleted = (chat) => {
        if (chat) {
            if (chat.Mensajes && chat.Mensajes.length) {
                return chat.Mensajes[chat.Mensajes.length - 1].EliminadoXRemitente;
            }
        }
        return false;
    }

    const listRender = () => {

        let chatss = [];
        let contacts = [];

        if (props.chats && props.chats.length) {

            chatss = props.chats.map((chat, index) => {
                const placeholder = chat.clv_tipoconversacion === 1 ? imgPlaceholder:imgPlaceholderGroup
                return (
                    <div className={`MessagesList-result-item row m-0 ${chat.clv_conversacion === selected && 'active'}`} key={index}>
                        <div className="col avatar-image-box-50" onClick={e => onChatSelected(chat, index)}>
                            <img src={chat.ImageUrl ? chat.ImageUrl:placeholder} alt="user image" className='noselect avatar'
                                onError={(e)=>{
                                    e.target.onerror = null;
                                    e.target.src=placeholder
                                }}/>
                            {
                                chat.clv_estatusbm === 2 &&
                                <img src={start} width="5px" height="5px" alt="miembro" className="miembro"/>
                            }
                        </div>
                        <div className="col user-details-box">

                            <div className="row m-0">
                                <div className="col-12 p-0 ">

                                    <div className="row m-0">
                                        <div className="col user-details-title p-0 text-truncate noselect" onClick={e => onChatSelected(chat, index)}>
                                            {chat.Titulo}
                                        </div>

                                        {
                                            chat.Fecha && <div className="col p-0 MessagesList-result-timesince noselect" onClick={e => onChatSelected(chat, index)}>
                                                {toolsHelper.timeSince(chat.Fecha)}
                                            </div>
                                        }

                                        {
                                            chat.clv_tipoconversacion === 1 ? (
                                                <div className="col p-0 MessagesList-result-deleter noselect">
                                                    <Dropdown>
                                                        <Dropdown.Toggle>
                                                            <img src={icnMore} alt="options icon" width='30' height='30'/>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item key='0' className='text-danger' onClick={e => onChatDelete(chat, index)}>Eliminar</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            ):(null)
                                        }

                                        {
                                            chat.clv_tipoconversacion === 2 && chat.Inactivo ? (
                                                <div className="col p-0 MessagesList-result-deleter noselect">
                                                    <Dropdown>
                                                        <Dropdown.Toggle>
                                                            <img src={icnMore} alt="options icon" width='30' height='30'/>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item key='0' className='text-danger' onClick={e => onGroupDelete(chat, index)}>Eliminar</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            ):(null)
                                        }


                                    </div>
                                </div>
                                <div className="col-12 p-0" onClick={() => onChatSelected(chat)}>
                                    <div className="row m-0">
                                        <div className={`col p-0 line-height-19px ${chat.NoLeidos ? 'hasUnread':''}`}>

                                            <div className="row m-0">
                                                {
                                                    chat.clv_tipoconversacion === 1 ? (
                                                        <div className="col-12 p-0 user-details-subtitle noselect">
                                                            {`${chat.Cargo}${chat.Cargo && chat.EmpresaNombreCorto ? ' | ':''}${chat.EmpresaNombreCorto}`}
                                                        </div>
                                                    ):(null)
                                                }
                                                <div className={`col-12 p-0 user-details-subtitle soft text-truncate noselect text-17 ${isMsgDeleted(chat) ? 'deleted':''}`}>
                                                    {getMessageTitle(chat)}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            chat.NoLeidos ? (
                                                <div className="col p-0 MessagesList-result-unread-container noselect">
                                                    {chat.NoLeidos > 99 ? '99+':chat.NoLeidos}
                                                </div>
                                            ):(null)

                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
            
        }

        if (props.contacts && props.contacts.length) {

            contacts = props.contacts.map((contact, index) => {
                const placeholder = imgPlaceholder
                return (
                    <div className={`MessagesList-result-item row m-0`} key={index}>
                        <div className="col avatar-image-box-50" onClick={e => onContactSelected(contact, index)}>
                            <img src={contact.PhotoUrl ? contact.PhotoUrl:placeholder} alt="user image" className='noselect avatar'
                                onError={(e)=>{
                                    e.target.onerror = null;
                                    e.target.src=placeholder
                                }}/>
                            {
                                contact.clv_estatusbm === 2 &&
                                <img src={start} width="5px" height="5px" alt="miembro" className="miembro"/>
                            }
                        </div>
                        <div className="col user-details-box">

                            <div className="row m-0">
                                <div className="col-12 p-0 ">

                                    <div className="row m-0">
                                        <div className="col user-details-title p-0 text-truncate noselect" onClick={e => onContactSelected(contact, index)}>
                                            {contact.NombreCompleto}
                                        </div>


                                    </div>
                                </div>
                                <div className="col-12 p-0" onClick={() => onContactSelected(contact)}> 
                                    <div className="row m-0">
                                        <div className={`col p-0 line-height-19px`}>
                                            <div className="row m-0">
                                                <div className="col-12 p-0 user-details-subtitle noselect">
                                                    {`${contact.Cargo}${contact.Cargo && contact.EmpresaNombreCorto ? ' | ':''}${contact.EmpresaNombreCorto}`}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
            
        }


        if (chatss.length || contacts.length) {
            return <div className="MessagesList-results-container col-12 px-0">
                {chatss}
                {contacts.length ? (
                    <div className='MessageList-contacts-header'>
                        Contactos sin conversación activa
                    </div>
                ):(null)}
                {contacts}
            </div>
        }
        return <div className="MessagesList-noresults-container col-12">
            <NoResults/>
        </div>
    }
    return (
        <div className="MessagesList-container h-100 row m-0">
            {listRender()}
        </div>
    );
}

export { MessagesList };
