import React, { useState, useEffect } from 'react';

import { VideoPlayer, ModalDefault } from '../';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';

import './ContentVideo.scss';

import { infoService, profileService, swalAlertsService, authenticationService } from '../../../_resources/services/';
import { useWindowDimensions } from '../../../_resources/helpers';

import icnsNetworks from '../../../_assets/media/images/icons/icns_networks.png';
import icnDeleteFilter from '../../../_assets/media/images/icons/icn_delete_filter.png';
import icnClose from '../../../_assets/media/images/icons/icn_close.png';
import icnsReactions from '../../../_assets/media/images/icons/icns_reactions.png';
import icnStar from '../../../_assets/media/images/icons/icn_batch_star.svg';

const ContentVideo = (props) => {

    const history = useHistory();

    const {width, height} = useWindowDimensions();

    const onCloseTapped = () => {
        console.log('onCloseTapped');
        if (props.onCloseTapped) {
            props.onCloseTapped();
        }
    }

    useEffect(() => {
    }, []);
    
    const renderView = (isDesktop) => {
        if (!props.content || !props.content.URL) {
            return null;
        }

        return (
            <div className={`row m-0 ContentVideo-container isDesktop`}>

                
                <div className="col-12 ContentVideo-title-container">
                    <span>{props.content && props.content.Titulo ? props.content.Titulo:"Video"}</span>

                    <button className="btn-cleared" onClick={onCloseTapped}>
                        <img src={icnClose} alt="icnClose"/>
                    </button>
                </div>

                <div className="col-12 ContentVideo-items-container">
                    <div className="row m-0">
                        <ReactPlayer url={props.content.URL} controls={true}/>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <ModalDefault show={true} overAll={true} onHide={onCloseTapped}>
                {renderView(true)}
            </ModalDefault>
        </React.Fragment>
    )
}

export { ContentVideo };
