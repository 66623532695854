import React, { useState, useEffect, useRef } from 'react';
import {
    Redirect
} from "react-router-dom";
import './RecoverCode.scss';
import Countdown from "react-countdown";
import { authenticationService, infoService, swalAlertsService } from '../../../../../_resources/services';
import iconBack from '../../../../../_assets/media/images/icons/icn_back_arrow.png';
import icnPassword from '../../../../../_assets/media/images/icons/icn_lock.png';

const RecoverCode = (props) => {
    let input1 = useRef(null);
    let input2 = useRef(null);
    let input3 = useRef(null);
    let input4 = useRef(null);
    let input5 = useRef(null);
    const [goToMain, setGoToMain] = useState(false);
    const [preUser, setPreUser] = useState(authenticationService.preUserValue);

    const [codePostState, setCodePostState] = useState({
        code1: "",
        code2: "",
        code3: "",
        code4: "",
        code5: "",
        password: "",
        confirmPassword: ""
    });

    const goToWelcome = () => {

        swalAlertsService.answer("", "Si regresas perderás los cambios que hayas hecho en esta pantalla.", "Si, regresar").then((result) => {
            if (result.isConfirmed) {
                setGoToMain(true);
            }
        })

    }

    const onCodeChanged = (e) => {
        if (e.target.name) {
            let codePostStateCP = JSON.parse(JSON.stringify(codePostState))
            codePostStateCP[e.target.name] = e.target.value
            setCodePostState(codePostStateCP)

            if (input1 && input1.current && input1.current.name == e.target.name ) {
                input2.current.focus();
            }
            if (input2 && input2.current && input2.current.name == e.target.name) {
                input3.current.focus();
            }
            if (input3 && input3.current && input3.current.name == e.target.name) {
                input4.current.focus();
            }
            if (input4 && input4.current && input4.current.name == e.target.name) {
                input5.current.focus();
            }
        }
    }

    const onCodeBtnTapped = () => {
        if (props.onCodeBtnTapped) {
            props.onCodeBtnTapped(codePostState, preUser);
        }
    }

    const onResendCodeBtnTapped = () => {
        if (props.onResendCodeBtnTapped) {
            props.onResendCodeBtnTapped();
        }
    }

    const onPasswordChanged = (e) => {
        let codePostStateCP = JSON.parse(JSON.stringify(codePostState))
        codePostStateCP.password = e.target.value
        setCodePostState(codePostStateCP)
    }

    const onConfirmPasswordChanged = (e) => {
        let codePostStateCP = JSON.parse(JSON.stringify(codePostState))
        codePostStateCP.confirmPassword = e.target.value
        setCodePostState(codePostStateCP)
    }

    const onResetView = () => {
        props.setTimerRunning(false)
    }

    const changeTimerRunning = (value) => {
        props.setTimerRunning(!value)
    }

    useEffect(() => {

        authenticationService.preUser.subscribe((preUser) => {
            if (preUser && preUser.data) {
                setPreUser(preUser.data);
            }
        })
    }, [props]);


    const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return null;
        } else {
            minutes = minutes < 10 ? `0${minutes}`:minutes;
            seconds = seconds < 10 ? `0${seconds}`:seconds;
            return <span>{minutes}:{seconds}</span>;
        }
    }

    return (

        <React.Fragment>
            {

                <div className="RegisterCode-container">

                    <div className="row m-0">
                        <div className="col-12 p-0">
                            <button className='btn-cleared RegisterCode-close-btn' onClick={goToWelcome}>
                                <img src={iconBack} alt="close icon" />
                            </button>
                        </div>
                    </div>

                    <div className="row m-0 RegisterCode-titles-box">
                        <div className="col-12 px-0 title">
                            Recuperar contraseña
                        </div>
                        <div className="col-12 px-0 subtitle">
                            {
                                preUser && preUser.Celular ?
                                (
                                    <span>
                                        Ingresa el código que enviamos al número que termina en <strong>{`${preUser.Celular.slice(-4)}`}</strong>
                                    </span>
                                ):(null)
                            }
                        </div>
                    </div>

                    <div className="row m-0 RegisterData-codes-container d-flex justify-content-center">
                        <div className="col">
                            <input type="text" maxLength='1' name='code1' ref={input1} value={codePostState.code1} onChange={(e) => { onCodeChanged(e) }} />
                        </div>
                        <div className="col">
                            <input type="text" maxLength='1' name='code2' ref={input2} value={codePostState.code2} onChange={(e) => { onCodeChanged(e) }} />
                        </div>
                        <div className="col">
                            <input type="text" maxLength='1' name='code3' ref={input3} value={codePostState.code3} onChange={(e) => { onCodeChanged(e) }} />
                        </div>
                        <div className="col">
                            <input type="text" maxLength='1' name='code4' ref={input4} value={codePostState.code4} onChange={(e) => { onCodeChanged(e) }} />
                        </div>
                        <div className="col">
                            <input type="text" maxLength='1' name='code5' ref={input5} value={codePostState.code5} onChange={(e) => { onCodeChanged(e) }} />
                        </div>
                    </div>

                    <div className="row m-0">
                        <div className={'col-12 px-0 RegisterCode-input-box'}>
                            <label htmlFor="password" className='icon'>
                                <img src={icnPassword} alt="password icon" />
                            </label>
                            <input type="password" id='password' name='password' className='iconned' placeholder='Contraseña' onChange={(e) => { onPasswordChanged(e) }} />
                        </div>
                    </div>


                    <div className="row m-0">
                        <div className={'col-12 p-0 RegisterCode-input-box'}>
                            <label htmlFor="confirmPassword" className='icon'>
                                <img src={icnPassword} alt="password icon" />
                            </label>
                            <input type="password" id='confirmPassword' name='confirmPassword' className='iconned' placeholder='Contraseña' onChange={(e) => { onConfirmPasswordChanged(e) }} />
                        </div>
                    </div>

                    <div className="row m-0">
                        <div className="col-12 p-0 RegisterCode-input-box">
                            <p className='passwordDisclaimer'>
                                Al menos una letra mayúscula, Al menos una letra minúscula, Al menos un número, Al menos una carácter especial. Se consideran los siguientes: ! # $ % & _, Al menos 8 caracteres
                            </p>
                        </div>
                    </div>


                    <div className="row m-0">

                        <div className="col-12 px-0 RegisterCode-step-btn-box">
                            <button onClick={() => { onCodeBtnTapped() }}>
                                Confirmar
                            </button>
                        </div>

                    </div>

                    <div className="row m-0 RegisterCode-titles-box">
                        <div className="col-12 px-0 subtitle">
                            ¿No recibiste el código de seguridad? Podemos volver a enviarlo en:
                        </div>

                        <div className="col-12 p-0 text-center btns-container">
                            {
                                !props.timerRunning ? (
                                    <button className='btn-cleared sender-btn' onClick={() => { onResendCodeBtnTapped() }}>Reenviar código</button>
                                ) : (
                                    <div>
                                        <Countdown date={Date.now() + 180000}
                                            onComplete={() => changeTimerRunning(props.timerRunning)}
                                            renderer={countdownRenderer}
                                            zeroPadTime={2}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>

                </div>

            }


            {
                goToMain ? (
                    <Redirect to={{ pathname: '/' }} />
                ) : (null)
            }

        </React.Fragment>

    )
}

export { RecoverCode }
