import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './BrochureSheet.scss';

import icnBrochure from '../../../../_assets/media/images/icons/folleto.png';

const BrochureSheet = (props) => {
    const history = useHistory();

    const onBtnTapped = () => {
        if (props.isMine) {
            history.push("/profile/folleto")
        } else if (props.user && props.user.BrochureUrl) {
            configUrl(props.user.BrochureUrl);
        }
    }

    const configUrl = (value) => {
        if(value.includes("https://") || value.includes("http://")) {
            // window.location.replace(value)
            window.open(value);
        }else {
            // window.location.replace(`https://${value}`)
            window.open(`https://${value}`);
        }
    }

    const renderContent = () => {
        if (props.isMine) {
            return (<span className='font-weight-bold font-color-blue'>Subir folleto de mi empresa</span>)
        } else if (props.user) {
            if (props.user.BrochureUrl) {
                return (<span>Ver folleto de la empresa</span>);
            }
            return (<span>No disponible</span>);
        }
    }

    return (
        <div className="BrochureSheet-container row">
            <div className="col-12 px-0 mouse-pointer noselect" onClick={onBtnTapped}>
                <img src={icnBrochure} alt="icnBrochure"/>
                {renderContent()}
            </div>
        </div>
    );
}

export { BrochureSheet }
