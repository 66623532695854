import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from "react-router-dom";

import './PostDetails.scss';

import { publicationService, swalAlertsService, authenticationService, profileService } from '../../../_resources/services';
import { toolsHelper } from '../../../_resources/helpers';

import { Post, InputBox, ModalDefault } from '../../shared';
import { PostDetailsComments, PostDetailsInput } from './post-details-components';

import icnBack from '../../../_assets/media/images/icons/icn_back_arrow.png';
import { ImageCrop } from '../../shared/image-crop/ImageCrop';

const PostDetails = (props) => {
    const history = useHistory();
    const [postState, setPostState] = useState({
        id: null,
        item: null,
        fetched: false,
        isComment: false
    });
    const [fetched, setFetched] = useState(false);
    const [commentEdit, setCommentEdit] = useState(null);
    const [visibleCrop, setVisibleCrop] = useState(false)
    const [base64, setbase64] = useState("")
    const [imageCrop, setimageCrop] = useState({});
    const [currentUser, setCurrentUser] = useState(null);
    const [reactions, setReactions] = useState([]);
    const [size, setSize] = useState(18);
    const [lineSize, setLineSize] = useState(22);

    const showTab = () => {
        // if (props.onToggleTab) {
        //     props.onToggleTab(false);
        // }
    }

    const goToPreviousPath = () => {
        setTimeout(() => {
            showTab();
        },200);

        history.push("/", {shouldReload: true});
    }
    const getPassedID = () => {
        if (props) {
            if (props.match && props.match.params && props.match.params.post_id) {
                let id = props.match.params.post_id;

                if (isNaN(id)) {
                    const matches = id.match(/(\d+)/);
                    if (matches) {
                        id = matches[0];
                    } else {
                        return null;
                    }
                }
                return id;
            }
        }
        return null;
    }

    const scrollBottomComments = () => {
        let element = document.getElementById('PostDetails-container');

        if (element) {
            element.scrollTo({top: element.scrollHeight, behavior: 'smooth'});
        }
    }
    async function getById (byComment = null) {
        if (postState.id) {
            let loading = swalAlertsService.createLoading('Obteniendo publicación', 'En breve podrás verla');
            loading.fire();
            const response = await publicationService.getPublicationById(postState.id);
            loading.close();

            if (response.data) {
                let postStateCP = handleReceivedLocation();
                postStateCP.item = response.data;
                postStateCP.fetched = true;
                setPostState(postStateCP);
                if (byComment) {
                    setTimeout(() => {
                        scrollBottomComments();
                    });
                }
            } else {
                if (typeof response === 'string') {
                    swalAlertsService.showToast('error', response);

                } else {
                    swalAlertsService.showToast('error', 'Ocurrió un error al intentar obtener la publicación');
                }
                if (!byComment) {
                    goToPreviousPath();
                }

            }
        } else {
            if (!byComment) {
                goToPreviousPath();
            }
        }

    }

    const onInputHeightCheck = () => {
        const input = document.querySelector('.InputBox-container');

        if (input) {
            let mainContainer = document.querySelector('.PostDetails-container');

            if (mainContainer) {
                mainContainer.style = `padding-bottom: ${input.clientHeight}px`;
            }
        }
    }

    const attachmentBuilder = (data) => {
        let attachment = null;
        if (
            data.file &&
            data.file.type &&
            (
                data.file.type.indexOf('pdf') >= 0 ||
                data.file.type.indexOf('image') >= 0 ||
                data.file.type.indexOf('audio') >= 0 ||
                data.file.type.indexOf('video') >= 0 ||
                data.file.type.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') >= 0 ||
                data.file.type.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') >= 0 ||
                data.file.type.indexOf('application/vnd.openxmlformats-officedocument.presentationml.presentation') >= 0
            )
        ) {
            let clvfileType = data.file.type.indexOf('pdf') >= 0;

            let Extension = null;
            if (data.file.type.indexOf('pdf') >= 0) {
                clvfileType = 1;
                Extension = 'pdf';
            } else if (data.file.type.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') >= 0) {
                clvfileType = 8;
                Extension = 'xlsx';
            } else if (data.file.type.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') >= 0) {
                clvfileType = 2
                Extension = 'docx';
            } else if (data.file.type.indexOf('application/vnd.openxmlformats-officedocument.presentationml.presentation') >= 0) {
                clvfileType = 9
                Extension = 'pptx';
            } else if (data.file.type.indexOf('image') >= 0) {
                clvfileType = 3;
                Extension = 'jpg';
            } else if (data.file.type.indexOf('video') >= 0) {
                clvfileType = 6;
                Extension = 'mp4';
            } else if (data.file.type.indexOf('audio') >= 0) {
                clvfileType = 7;
                Extension = 'mp3';
            }

            attachment = {
                clv_tipoadjunto: clvfileType,
                Nombre: data.file.name || null,
                Extension,
                Base64: data.file.base64.split(",")[1]
            }
        }
        return attachment;
    }

    const newCommentBuilder = (data) => {
        let obj = {
            Mensaje: '',
            Menciones: data.Menciones && data.Menciones.length ? data.Menciones: []
        };
        let attachment = attachmentBuilder(data);
        if (attachment) {
            obj['Adjuntos'] = [attachment];
        }

        if (data.message) {
            obj.Mensaje = data.message || '';
        }

        return obj;
    }

    const editCommentBuilder = (data) => {
        if (commentEdit && data) {
            let obj = {
                clv_mensajexpublicacion: commentEdit.clv_mensajexpublicacion,
                Mensaje: data.message || '',
                Menciones: data.Menciones && data.Menciones.length ? data.Menciones: []
            }

            if (commentEdit.Adjuntos.length) {
                let attachments = [];
                if (data.file.deleted ) {
                    attachments.push({
                        clv_adjuntoxmensajepublicacion: commentEdit.Adjuntos[0].clv_adjuntoxmensajepublicacion,
                        Extension: data.file.type,
                        EliminarAdjunto: true
                    });
                } else if (!data.file.fromEdit){
                    attachments.push({
                        clv_adjuntoxmensajepublicacion: commentEdit.Adjuntos[0].clv_adjuntoxmensajepublicacion,
                        Extension: commentEdit.Adjuntos[0].Extension,
                        EliminarAdjunto: true
                    });

                    let attachment = attachmentBuilder(data);
                    attachments.push(attachment);
                }
                if (attachments.length) {
                    obj['Adjuntos'] = attachments;
                }
            } else {
                let attachment = attachmentBuilder(data);
                if (attachment) {
                    obj['Adjuntos'] = [attachment];
                }
            }

            return obj;
        }

        return null;
    }

    const sendEditedComment = (data) => {
        let objSend = editCommentBuilder(data);

        if (objSend && postState.item && postState.item.clv_publicacion) {

            let loading = swalAlertsService.createLoading('Enviando comentario', 'En breve estará listo');
            loading.fire();
            publicationService.editPublicationComment(postState.item.clv_publicacion,objSend).then((response) => {
                loading.close();
                setimageCrop({})
                setPostState({
                    id: null,
                    item: null,
                    fetched: false,
                    isComment: false
                })
                if (response && response.data && response.message) {
                    setCommentEdit(null);
                    setTimeout(() => {
                        swalAlertsService.showToast('success', response.message);
                        setCommentEdit(null);
                        getById(true);
                    }, 200);
                } else {
                    swalAlertsService.showToast('error', 'Ocurrió algo al intentar editar el comentario');
                }
            });
        }
    }

    const sendNewComment = (data) => {
        let objSend = newCommentBuilder(data);

        if (objSend && postState.item && postState.item.clv_publicacion) {
            let loading = swalAlertsService.createLoading('Enviando comentario', 'En breve estará listo');
            loading.fire();
            publicationService.commentPublication(postState.item.clv_publicacion,objSend).then((response) => {
                loading.close();
                setimageCrop({})
                setPostState({
                    id: null,
                    item: null,
                    fetched: false,
                    isComment: false
                })
                if (response && response.data && response.message) {
                    swalAlertsService.showToast('success', response.message);
                    getById(true);
                } else {
                    swalAlertsService.showToast('error', 'Ocurrió algo al intentar comentar la publicación');
                }
            });
        }
    }

    const onInputBoxSubmit = (data) => {
        if (data && (data.message || data.file)) {
            if (data.edited && commentEdit) {
                sendEditedComment(data);
            } else {
                sendNewComment(data);
            }
        }
    }

    useEffect(() => {
        let isSuscribed = true;

        if (props.onToggleTab) {
            props.onToggleTab(true);
        }
        const id = getPassedID();
        if (id) {
            let postStateCP = JSON.parse(JSON.stringify(postState));
            postStateCP.id = id;
            if (isSuscribed) {
                setPostState(postStateCP);
            }

            const {location} = props;
            if (location && location.state && location.state.shouldReload) {


                if (history.location.state && history.location.state.shouldReload) {
                    let state = { ...history.location.state };
                    delete state.shouldReload;
                    history.replace({ ...history.location, state });
                }
                window.location.reload();

            }
        } else {
            goToPreviousPath();
        }



        onInputHeightCheck();

        authenticationService.currentUser.subscribe((user) => {
            if (user && user.SUID && user.Usuario && isSuscribed) {
                setCurrentUser(user);
            }
        });

        profileService.getGeneric('user/reactions').then((response) => {
            if (response && response.data && isSuscribed) {
                setReactions(response.data);
            }
        });


        if(localStorage.getItem('letterSize')){
            setSize(parseInt(localStorage.getItem('letterSize')));
        }

        if(localStorage.getItem('lineSize')){
            setLineSize(parseInt(localStorage.getItem('lineSize')));
        }

        return () => isSuscribed = false
    }, []);

    useEffect(() => {
        if (postState.id && !postState.item && !postState.fetched) {
            getById();
        }
    }, [postState]);

    const onDeleteComment = (item) => {
        if (
            postState.item && postState.item.clv_publicacion &&
            item && item.clv_mensajexpublicacion
        ) {
            let loading = swalAlertsService.createLoading('Borrando comentario', 'En breve estará listo');
            loading.fire();
            publicationService.deletePublicationComment(postState.item.clv_publicacion,item.clv_mensajexpublicacion).then((response) => {
                loading.close();
                if (response && response.data && response.message) {
                    swalAlertsService.showToast('success', response.message);
                    getById(true);
                } else {
                    swalAlertsService.showToast('error', 'Ocurrió algo al intentar borrar comentario');
                }
            });
        }

    }

    const onEditComment = (item) => {
        setCommentEdit(null);
        setTimeout(() => {
            setCommentEdit(item);
        }, 200);
    }

    const onOptionTapped = (data) => {

        if (data && data.type && data.item) {
            if (data.type === 'delete') {
                onDeleteComment(data.item);
            } else if (data.type === 'edit') {
                onEditComment(data.item);
            }
        }
    }

    const onSuccessEdit = () => {
        getById();
    }

    const onCommentBtnTapped = () => {
        let postStateCP = JSON.parse(JSON.stringify(postState));
        postStateCP.isComment = true;
        setPostState(postStateCP);
    }

    const handleReceivedLocation = () => {
        const {location} = props;
        let postStateCP = JSON.parse(JSON.stringify(postState));
        if (location && location.state && 'isComment' in location.state) {

            postStateCP.isComment = location.state.isComment;
        }


        if (history.location.state && 'isComment' in location.state) {
            let state = { ...history.location.state };
            delete state.isComment;
            history.replace({ ...history.location, state });
        }

        return postStateCP;
    }

    const onInputBoxBlur = () => {
        let postStateCP = JSON.parse(JSON.stringify(postState));
        postStateCP.isComment = false;
        setPostState(postStateCP);

    }

    const cutImage = (image) => {
        setbase64(image.image)
        setimageCrop({...imageCrop, text: image.text})
        setVisibleCrop(true)
    }

    const cut = async(image) => {
        setimageCrop({...imageCrop, blob: image.blob})
        setVisibleCrop(false)
    }

    const cancel = () => {
        setVisibleCrop(false)
    }

    const onPostDelete = (item) => {
        if (item && item.clv_publicacion) {
            let loading = swalAlertsService.createLoading('Borrando publicación', 'En breve estará lista');
            loading.fire();
            publicationService.deletePublication(item.clv_publicacion).then((response) => {
                loading.close();
                if (response && response.message) {
                    swalAlertsService.showToast('success', response.message);

                    setTimeout(() => {
                        goToPreviousPath();
                    }, 1000);
                } else {
                    swalAlertsService.showToast('error', 'Ocurrió algo al intentar borrar la publicación');
                }
            });
        }
    }

    const onPostLiked = () => {
        if (postState.item && postState.item.clv_publicacion) {
            let loading = swalAlertsService.createLoading('Enviando reacción', '');
            loading.fire();
            profileService.postGeneric(`publication/${postState.item.clv_publicacion}/reaction`, 'POST', {clv_tiporeaccion: 1}).then((response) => {
                if (response && response.data) {
                    loading.close();
                    if (response.publication) {
                        let postStateCP = JSON.parse(JSON.stringify(postState));
                        postStateCP.item = response.publication;
                        setPostState(postStateCP);
                    }
                    profileService.getGeneric('user/reactions').then((responseReactions) => {
                        if (responseReactions && responseReactions.data) {
                            setReactions(responseReactions.data);
                        }
                    });
                }
            });
        }
    }

    const renderPostView = () => {
        if (postState.item) {
            return (
                <div className="PostDetails-post-box">
                    <Post item={postState.item}
                        isDetails={true}
                        onSuccessEdit={onSuccessEdit}
                        onCommentBtnTapped={onCommentBtnTapped}
                        onPostDelete={onPostDelete}
                        reactions={reactions}
                        postLiked={onPostLiked}
                        fontSize={size}
                        lineHeight={lineSize}
                    />

                    <PostDetailsComments currentUser={currentUser} 
                        item={postState.item} 
                        postState={postState} 
                        onOptionTapped={onOptionTapped}
                        fontSize={size}
                        lineHeight={lineSize}
                        />
                </div>
            );
        }
        return null;
    }
    return (
        <ModalDefault show={true} className='full-view'>
            {
                // !visibleCrop &&
                // (
                //     <div className="PostDetails-container" id='PostDetails-container'>
                //         <div className="row m-0 PostDetails-back-container">
                //             <div className="col p-0">
                //                 <button className="btn-cleared" onClick={goToPreviousPath}>
                //                     <img src={icnBack} alt="back icon" width='30' height='30'/>
                //                 </button>
                //             </div>
                //         </div>
                //         { renderPostView() }
                //         <InputBox onInputHeightCheck={onInputHeightCheck}
                //             onSubmit={onInputBoxSubmit}
                //             commentEdit={commentEdit}
                //             inputFocus={postState.isComment}
                //             onBlur={onInputBoxBlur}
                //             cutImage={cutImage}
                //             imageCrop={imageCrop}
                //             isPublication={true}
                //         />
                //     </div>
                // )
            }

            <div className="PostDetails-container" id='PostDetails-container'>
                <div className="row m-0 PostDetails-back-container">
                    <div className="col p-0">
                        <button className="btn-cleared" onClick={goToPreviousPath}>
                            <img src={icnBack} alt="back icon" width='30' height='30'/>
                        </button>
                    </div>
                </div>
                { renderPostView() }
                <InputBox onInputHeightCheck={onInputHeightCheck}
                    onSubmit={onInputBoxSubmit}
                    commentEdit={commentEdit}
                    inputFocus={postState.isComment}
                    onBlur={onInputBoxBlur}
                    cutImage={cutImage}
                    imageCrop={imageCrop}
                    isPublication={true}
                />
            </div>
            {
                visibleCrop &&
                <ImageCrop src={base64} cutCrop={cut} cancelCrop={cancel} text={imageCrop.text}/>
            }
        </ModalDefault>
    );
}

export { PostDetails }
