import { authHeader, handleResponse } from '../helpers';

const apiPath = process.env.REACT_APP_API_PATH;

export const messagesService = {
    getMessages,
    getExistingChat,
    deleteChat,
    postMessage,
    deleteMessage,
    getExistingGroup,
    createGroup,
    putGroup,
    postLeaveGroup,
    postAsignAdmin,
    deleteGroupMember,
    deleteGroup,
    postAddMembersToGroup
};

function getMessages(query = null) {
    let url = `${apiPath}conversation`;

    if (query) {
        url = `${url}?query=${query}`;
    }
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(url, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function getExistingChat(id, page=1, pageSize=10) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiPath}conversation/existing/${id}?Page=${page}&PageSize=${pageSize}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function getExistingGroup(id , page=1, pageSize=10) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiPath}conversation/${id}?Page=${page}&PageSize=${pageSize}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function deleteChat(id) {
    const requestOptions = { method: 'DELETE', headers: authHeader() };
    return fetch(`${apiPath}conversation/${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function postMessage(obj, isMassive) {
    const requestOptions = { method: 'POST', headers: authHeader(), body: JSON.stringify(obj)};
    return fetch(`${apiPath}message${isMassive ? '/broadcast':''}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    }).catch(error => {
        console.log(error);
    });
}

function deleteMessage(id) {
    const requestOptions = { method: 'DELETE', headers: authHeader() };
    return fetch(`${apiPath}message/${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function createGroup(obj) {
    const requestOptions = { method: 'POST', headers: authHeader(), body: JSON.stringify(obj)};
    return fetch(`${apiPath}conversation`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function putGroup(obj, id) {
    const requestOptions = { method: 'PUT', headers: authHeader(), body: JSON.stringify(obj)};
    return fetch(`${apiPath}conversation/${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function postLeaveGroup(id) {
    const requestOptions = { method: 'POST', headers: authHeader(), body: JSON.stringify({})};
    return fetch(`${apiPath}conversation/${id}/leave`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function postAsignAdmin(id) {
    const requestOptions = { method: 'POST', headers: authHeader(), body: JSON.stringify({})};
    return fetch(`${apiPath}conversation/member/${id}/admin`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function postAddMembersToGroup(id, obj) {
    const requestOptions = { method: 'POST', headers: authHeader(), body: JSON.stringify(obj)};
    return fetch(`${apiPath}conversation/${id}/members`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function deleteGroupMember(id) {
    const requestOptions = { method: 'DELETE', headers: authHeader() };
    return fetch(`${apiPath}conversation/member/${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}

function deleteGroup(id) {
    const requestOptions = { method: 'DELETE', headers: authHeader() };
    return fetch(`${apiPath}conversation/${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    }, (error) => {
        return error;
    });
}
