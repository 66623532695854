import React, { useState, useEffect, useRef } from 'react';

import './GroupDetails.scss';
import { Dropdown } from 'react-bootstrap';
import { toolsHelper } from '../../../_resources/helpers';
import { swalAlertsService, messagesService } from '../../../_resources/services';

import icnBackward from '../../../_assets/media/images/icons/icn_backward.png';
import icnCamera from '../../../_assets/media/images/icons/icn_camera.png';
import icnMore from '../../../_assets/media/images/icons/icn_more.png';
import icnMoreOptions from '../../../_assets/media/images/icons/icn_more_options.png';
import icnCancel from '../../../_assets/media/images/icons/icn_cancel.png';
import icnBackArrow from '../../../_assets/media/images/icons/icn_back_arrow.png';
import icnPlus from '../../../_assets/media/images/icons/icn_plus.png';
import icnBullet from '../../../_assets/media/images/icons/icn_bullet.png';
import icnBulletActive from '../../../_assets/media/images/icons/icn_bullet_actived.png';
import imgPlaceholder from '../../../_assets/media/images/icons/icn_user_placeholder.png';
import imgPlaceholderGroup from '../../../_assets/media/images/icons/icn_placeholder_group.png';
import start from '../../../_assets/media/images/icons/icn_batch_star.svg';


import { ModalDefault, BottomSheetShared, ContactList } from '../';

const GroupDetails = (props) => {
    const inputFile = useRef(null);
    const [groupState, setGroupState] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
    const [showLeaveGroupBottomSheet, setShowLeaveGroupBottomSheet] = useState(false);
    const [editTitleText, setEditTitleText] = useState('');
    const [editDescriptionText, setEditDescriptionText] = useState('');
    const [userAsAdmin, setUserAsAdmin] = useState([]);
    const [isGroupAdding, setIsGroupAdding] = useState(false);
    const [type, setType] = useState("")


    const onHideModal = () => {
        if (props.onHide) {
            props.onHide();
        }
    }

    const onImageChangeBtnTapped = () => {
        inputFile.current.click();
    }

    const resetFile = () => {
        setTimeout(() => {
            if (inputFile && inputFile.current) {
                inputFile.current.value = ''
            }
        }, 200);
    }

    async function processFile (event) {
        const result = await toolsHelper.getFile(event);

        if (result && result.splitted && groupState && groupState.clv_conversacion) {
            const obj = {
                Titulo: groupState.Titulo,
                Descripcion: groupState.Descripcion,
                Imagen: {
                    clv_tipoadjunto: 3,
                    Nombre: result.name || null,
                    Extension: 'jpg',
                    Base64: result.splitted
                }
            }
            let loading = swalAlertsService.createLoading('Actualizando grupo', '');
            loading.fire();
            messagesService.putGroup(obj, groupState.clv_conversacion).then((response) => {
                loading.close();
                if (response && response.data) {
                    setGroupState(response.data);
                    onChatUpdate();
                } else {
                    swalAlertsService.showToast('error', 'Ocurrió un problema al intentar actualizar el grupo');
                }
            });
        }
        resetFile();
    }

    useState(() => {
        if (props.group) {
            if (props.group.Usuarios && props.group.Usuarios.length) {
                const filtered = props.group.Usuarios.filter(el => el.Admin && el.clv_usuario === props.currentUser.Usuario.clv_usuario);
                setIsAdmin(filtered.length ? true:false);
            }
            setGroupState(props.group);
        }
    }, []);

    const onTitleTapped = () => {
        if (isAdmin) {
            console.log("open title modal");
            setType("title")
            setEditTitleText(groupState.Titulo);
            setBottomSheetOpen(true);

            setTimeout(() => {
                let element = document.getElementById('editTitleInput');

                if (element) {
                    element.value = groupState.Titulo;
                }
            },20);
        }
    }

    const onDescriptionTapped = () => {
        if (isAdmin) {
            console.log("open title modal");
            setType("description")
            setEditTitleText(groupState.Descripcion);
            setBottomSheetOpen(true);

            setTimeout(() => {
                let element = document.getElementById('editTitleInput');

                if (element) {
                    element.value = groupState.Descripcion;
                }
            },20);
        }
    }

    const onBottomSheetDimiss = () => {
        setBottomSheetOpen(false);
        setEditTitleText('');
    }

    const onHeaderSubmit = () => {
       if(type === "title") {
            if (editTitleText.length) {
                let obj = {
                    Titulo: editTitleText,
                    Descripcion: groupState.Descripcion
                }

                let loading = swalAlertsService.createLoading('Actualizando grupo', '');
                loading.fire();
                messagesService.putGroup(obj, groupState.clv_conversacion).then((response) => {
                    loading.close();
                    if (response && response.data) {
                        setGroupState(response.data);
                        setBottomSheetOpen(false);
                        onChatUpdate();
                    } else {
                        swalAlertsService.showToast('error', 'Ocurrió un problema al intentar actualizar el grupo');
                    }
                });
            } else {
                swalAlertsService.showToast('error', 'Debes escribir un nombre de grupo');
            }
       }
       if(type === "description") {
            if (editDescriptionText.length) {
                let obj = {
                    Titulo: groupState.Titulo,
                    Descripcion: editDescriptionText
                }

                let loading = swalAlertsService.createLoading('Actualizando grupo', '');
                loading.fire();
                messagesService.putGroup(obj, groupState.clv_conversacion).then((response) => {
                    loading.close();
                    if (response && response.data) {
                        setGroupState(response.data);
                        setBottomSheetOpen(false);
                        onChatUpdate();
                    } else {
                        swalAlertsService.showToast('error', 'Ocurrió un problema al intentar actualizar el grupo');
                    }
                });
            } else {
                swalAlertsService.showToast('error', 'Debes escribir una descripción para el grupo');
            }
       }
    }

    const clearEditText = () => {
        setEditTitleText('');
        let element = document.getElementById('editTitleInput');

        if (element) {
            element.value = '';
        }
    }

    const onEditTextChanged = (e) => {
        setEditTitleText(e.target.value);
    }

    const onEditDescriptionChanged = (e) => {
        setEditDescriptionText(e.target.value);
    }

    const editTitleContent = () => {

        return (
            <>
                <div className="GroupDetails-edit-title-container">

                    <div className="row m-0">
                        <div className="col-12 GroupDetails-edit-header-container">
                            <button className="btn-cleared back-btn" onClick={onBottomSheetDimiss}>
                                <img src={icnBackArrow} alt="Back arrow icon" width='30' height='30'/>
                            </button>

                            <p>
                                Editar
                            </p>
                            <button className="btn-cleared font-color-green submit-btn" onClick={onHeaderSubmit}>
                                Guardar
                            </button>
                        </div>
                    </div>
                    <div className="row mx-0 mt-4">
                        <div className="col-12 GroupDetails-edit-input-container">

                            <label htmlFor="editTitleInput">Nombre del grupo</label>
                            <input id='editTitleInput' name='editTitleInput' type="text" onKeyUp={onEditTextChanged}/>
                            {
                                editTitleText &&
                                <button className="btn-cleared" onClick={clearEditText}>
                                    <img src={icnCancel} alt="cancel icon"/>
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const editDescriptionContent = () => {

        return (
            <>
                <div className="GroupDetails-edit-title-container">

                    <div className="row m-0">
                        <div className="col-12 GroupDetails-edit-header-container">
                            <button className="btn-cleared back-btn" onClick={onBottomSheetDimiss}>
                                <img src={icnBackArrow} alt="Back arrow icon" width='30' height='30'/>
                            </button>

                            <p>
                                Editar
                            </p>
                            <button className="btn-cleared font-color-green submit-btn" onClick={onHeaderSubmit}>
                                Guardar
                            </button>
                        </div>
                    </div>
                    <div className="row mx-0 mt-4">
                        <div className="col-12 GroupDetails-edit-input-container">

                            <label htmlFor="editTitleInput">Descripción del grupo</label>
                            <input id='editTitleInput' name='editTitleInput' type="text" onKeyUp={onEditDescriptionChanged}/>
                            {
                                editDescriptionText &&
                                <button className="btn-cleared" onClick={clearEditText}>
                                    <img src={icnCancel} alt="cancel icon"/>
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const editTitleModal = () => {
        return <ModalDefault show={bottomSheetOpen} onHide={onBottomSheetDimiss} className='full-view'>
            {editTitleContent()}
        </ModalDefault>
    }

    const editDescription = () => {
        return <ModalDefault show={bottomSheetOpen} onHide={onBottomSheetDimiss} className='full-view'>
            {editDescriptionContent()}
        </ModalDefault>
    }

    const onAddNewMembers = () => {
        console.log("onAddNewMembers");
        setIsGroupAdding(true);
    }

    const onChatUpdate = () => {
        if (props.onChatUpdate) {
            props.onChatUpdate();
        }
    }
    const onDeleteUser = (user) => {
        if (user && user.clv_usuarioxconversacion) {
            swalAlertsService.answer("", "¿Estas seguro de eliminar usuario del grupo?", "Si, eliminar").then((result) => {
                if (result.isConfirmed) {
                    let loading = swalAlertsService.createLoading("Espere un momento...")
                    loading.fire();
                    messagesService.deleteGroupMember(user.clv_usuarioxconversacion).then((response) => {
                        loading.close();
                        if (response && response.data && response.data && response.message) {
                            swalAlertsService.showToast('success', response.message);
                            setGroupState(response.data);
                            onChatUpdate();
                        } else {
                            swalAlertsService.showToast('error', 'Ocurrió un problema al intentar salir del grupo');
                        }
                    });
                }
            })
        }

    }


    const onSetAdmin = (user) =>{
        if( user && user.clv_usuarioxconversacion) {
            swalAlertsService.answer("", `¿Estás seguro de hacer administrador del a ${user.NombreCompleto}?`, "Si, hacer admin").then( result => {
                if( result.isConfirmed){
                    console.log("Haciendo administrador");
                    let loading = swalAlertsService.createLoading("Espera un momento...");
                    messagesService.postAsignAdmin(user.clv_usuarioxconversacion).then( (response) =>{
                        loading.close();
                        if( response && response.data && response.message){
                            swalAlertsService.showToast('success', response.message);
                            setGroupState(response.data);
                            onChatUpdate();
                        }else{
                            swalAlertsService.showToast('error', 'Ocurrio un problema al asignar administrador');
                        }
                        console.log(response);
                    })
                }
            })
        }
    }

    const membersItem = () => {

        if (groupState && groupState.Usuarios && groupState.Usuarios.length) {
            const items = groupState.Usuarios.map((user, index) => {
                return <div className="col-12 GroupDetails-member-item" key={index}>
                    <div className="row m-0">
                        <div className="col avatar-image-box-60">
                            <img src={user.ThumbnailUrl ? user.ThumbnailUrl:imgPlaceholder} alt="user image" className="avatar"
                                onError={(e)=>{
                                    e.target.onerror = null;
                                    e.target.src=imgPlaceholder
                                }}
                            />
                            {
                                user.clv_estatusbm === 2 &&
                                <img src={start} width="10px" height="10px" alt="miembro" className="miembro"/>
                            }
                        </div>
                        <div className="col user-col-noavatar-60">
                            <div className="row m-0">
                                <div className="col-12 user-details-title p-0 text-truncate noselect">
                                    {user.NombreCompleto}
                                </div>
                                <div className="col-12 p-0">
                                    <div className="row m-0">
                                        <div className="col p-0 user-details-subtitle noselect">
                                            {`${user.Cargo ? user.Cargo:''}${user.Cargo && user.EmpresaNombreCorto ? ' | ':''}${user.EmpresaNombreCorto ? user.EmpresaNombreCorto:''}`}
                                        </div>



                                    </div>
                                </div>
                                <div className="col-12 p-0 user-details-subtitle soft text-truncate noselect">
                                    {`${user.EstadoResidencia ? user.EstadoResidencia:''}${user.EstadoResidencia && user.PaisResidencia ? ', ':''}${user.PaisResidencia ? user.PaisResidencia:''}`}
                                </div>
                            </div>
                        </div>
                        
                        <div className={`col p-0 admin-col noselect ${!user.Admin ? 'isDropdown':''}  ${props.currentUser.Usuario.clv_usuario == user.clv_usuario ? 'align-self-center' : ''}`}>
                            {/* {   
                                props.currentUser.Usuario.clv_usuario == user.clv_usuario && isAdmin ? (
                                    <>
                                        <div className='row m-0'>
                                            <span className=''>Admin.</span>
                                        </div>
                                    </>
                                ) :  (
                                    <>  
                                        <div className='row m-0'>
                                            <Dropdown className='col  p-0'>
                                                <Dropdown.Toggle>
                                                    <img src={icnMoreOptions} alt="options icon" width='30' height='30'/>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item key='0' className='text-danger' onClick={e => onDeleteUser(user)}>Eliminar del grupo</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                                {
                                                    user.Admin ? (
                                                        <div className='col-12 p-0'>
                                                            <span className='gray-color'>Admin.</span>
                                                        </div>
                                                    ) : (null)
                                                }
                                        </div>
                                    </>

                                    )
                            } */}

                            {
                                isAdmin ? (
                                    <>  
                                        <div className='row m-0'>
                                            <Dropdown className='col  p-0'>
                                                <Dropdown.Toggle>
                                                    <img src={icnMoreOptions} alt="options icon" width='30' height='30'/>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {
                                                        !user.Admin ? (
                                                            <Dropdown.Item key='0' className='text-primary' onClick={e => onSetAdmin(user)}>Hacer admin del grupo</Dropdown.Item>
                                                        ) :
                                                            (null)
                                                    }
                                                    <Dropdown.Item key='0' className='text-danger' onClick={e => onDeleteUser(user)}>
                                                        {
                                                            props.currentUser.Usuario.clv_usuario == user.clv_usuario ? 'Salir del grupo' : 'Eliminar del grupo'
                                                        }
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                                {
                                                    user.Admin ? (
                                                        <div className='col-12 p-0'>
                                                            <span className='gray-color'>Admin.</span>
                                                        </div>
                                                    ) : (null)
                                                }
                                        </div>
                                    </>
                                ) : !isAdmin && user.Admin ? (
                                    <>
                                    <div className='row m-0'>
                                        <span className=''>Admin.</span>
                                    </div>
                                </>
                                ) : (null)
                            }


                            {/* // {
                            //     isAdmin && !user.Admin ? (
                            //         <Dropdown>
                            //             <Dropdown.Toggle>
                            //                 <img src={icnMoreOptions} alt="options icon" width='30' height='30'/>
                            //             </Dropdown.Toggle>

                            //             <Dropdown.Menu>
                            //                 <Dropdown.Item key='0' className='text-primary' onClick={e => onSetAdmin(user)}>Hacer admin del grupo</Dropdown.Item>
                            //                 <Dropdown.Item key='1' className='text-danger' onClick={e => onDeleteUser(user)}>Eliminar del grupo</Dropdown.Item>
                            //             </Dropdown.Menu>
                            //         </Dropdown>
                            //     ):(null)

                            // } */}
                            </div>
                    </div>
                </div>
            });
            return <div className="row m-0">
                {items}
            </div>
        }
        return null;
    }

    const leavingGroup = () => {
        let loading = swalAlertsService.createLoading("Espere un momento...")
        loading.fire();
        messagesService.postLeaveGroup(groupState.clv_conversacion).then((response) => {
            loading.close();
            if (response && response.data && response.data && response.data.Activos) {
                onChatUpdate();
                swalAlertsService.showToast('success', 'Saliste del grupo correctamente');
                if (props.onGroupLeave) {
                    props.onGroupLeave();
                }
            } else {
                swalAlertsService.showToast('error', 'Ocurrió un problema al intentar salir del grupo');
            }
        });
    }

    const onGroupLeaveBtnTapped = () => {
        if (isAdmin) {

            const nAdmins = getNadmins();

            console.log(nAdmins);

            if( nAdmins == 1) {
                setShowLeaveGroupBottomSheet(true);
            }else{
                leavingGroup();
            }


        } else {
            swalAlertsService.answer("", "¿Estas seguro de querer salir del grupo?", "Si, salir").then((result) => {
                if (result.isConfirmed) {
                    leavingGroup();
                }
            })
        }
    }


    const getNadmins = () =>{
        let nAdmins = 0;
        groupState.Usuarios.map( user => {
            if( user.Admin ){
                nAdmins += 1;
            }
        })

        return nAdmins;
    }

    const onBottomSheetDimissLeaveGroup = () => {
        setUserAsAdmin([]);
        setShowLeaveGroupBottomSheet(false);
    }

    const getBulletIcon = (user) => {
        if (user && userAsAdmin.length && user.clv_usuario === userAsAdmin[0].clv_usuario) {
            return icnBulletActive;
        }
        return icnBullet;
    }

    const onSelectAsAdmin = (user) => {
        if (user) {
            setUserAsAdmin([user]);
        }
    }

    const leaveGroupBottomSheetItems = () => {
        return groupState.Usuarios.map((user, index) => {
            if (!user.Admin) {
                return (
                    <div className="GroupDetails-leave-item-container col-0" key={index} onClick={e => onSelectAsAdmin(user)}>

                        <div className="row m-0">
                            <div className="col avatar-image-box-60">
                                <img src={user.ThumbnailUrl ? user.ThumbnailUrl:imgPlaceholder} alt="User image" className="avatar"
                                    onError={(e)=>{
                                        e.target.onerror = null;
                                        e.target.src=imgPlaceholder
                                    }}
                                />
                                {
                                    user.clv_estatusbm === 2 &&
                                    <img src={start} width="10px" height="10px" alt="miembro" className="miembro"/>
                                }
                            </div>
                            <div className="col pe-0 user-col-noavatar-60">
                                <div className="row m-0">
                                    <div className="col-12 user-details-title p-0 text-truncate noselect">
                                        {user.NombreCompleto}
                                    </div>
                                    <div className="col-12 p-0">
                                        <div className="row m-0">
                                            <div className="col p-0 user-details-subtitle noselect">
                                                {`${user.Cargo ? user.Cargo:''}${user.Cargo && user.EmpresaNombreCorto ? ' | ':''}${user.EmpresaNombreCorto ? user.EmpresaNombreCorto:''}`}
                                            </div>
                                            <div className="col p-0 bullet-col noselect">
                                                <img src={getBulletIcon(user)} alt="Bullet icon"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 p-0 user-details-subtitle soft text-truncate noselect">
                                        {`${user.EstadoResidencia ? user.EstadoResidencia:''}${user.EstadoResidencia && user.PaisResidencia ? ', ':''}${user.PaisResidencia ? user.PaisResidencia:''}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            return null;
        });
    }
    const leaveGroupBottomSheetContent = () => {

        return <div className="GroupDetails-leave-container row m-0">
            <div className="col-12 px-0 GroupDetails-leave-disclaimer-container">
                Antes de salir por favor asigna un nuevo administrador para este grupo
            </div>
            {leaveGroupBottomSheetItems()}
        </div>
    }

    const onLeaveGroupSubmit = () => {
        if (userAsAdmin && userAsAdmin.length && userAsAdmin[0].clv_usuarioxconversacion) {
            messagesService.postAsignAdmin(userAsAdmin[0].clv_usuarioxconversacion).then((response) => {
                if (response && response.data) {
                    leavingGroup();
                } else {
                    swalAlertsService.showToast('error', 'Ocurrió un problema al intentar actualizar el grupo');
                }
            })
        } else {
            swalAlertsService.showToast('error', 'Debes seleccionar un admin para continuar');
        }
    }
    const footerBtn = () => {
        return (
            <button className={`btn-cleared ${userAsAdmin && userAsAdmin.length ? 'active red':''}`} onClick={onLeaveGroupSubmit}>
                Salir del grupo
            </button>
        );
    }

    const onLeaveGroupBottomSheet = () => {
        return (
            <BottomSheetShared showCancelBtn={true}
                blocking={true}
                isOpen={showLeaveGroupBottomSheet}
                onDismiss={onBottomSheetDimissLeaveGroup}
                showHeader={true}
                title='Salir del grupo'
                footerBtn={footerBtn}>
                { leaveGroupBottomSheetContent() }
            </BottomSheetShared>
        );
    }

    const onBottomSheetDimissContactList = () => {
        setIsGroupAdding(false);
    }

    const onGroupSendBtnTappedContactList = (data) => {
        if (data && data.length) {
            let obj = {
                Usuarios: data.map(el => { return {clv_usuario: el.clv_destinatario}})
            }

            let loading = swalAlertsService.createLoading("Espere un momento...")
            loading.fire();
            messagesService.postAddMembersToGroup(groupState.clv_conversacion, obj).then((response) => {
                loading.close();
                if (response && response.data && response.data && response.message) {
                    swalAlertsService.showToast('success', response.message);
                    setGroupState(response.data);
                    onBottomSheetDimissContactList();
                    onChatUpdate();
                } else {
                    swalAlertsService.showToast('error', 'Ocurrió un problema al intentar actualizar el grupo');
                }
            });
        }
    }

    return (
        <ModalDefault show={true} onHide={onHideModal} className='full-view'>

            {
                groupState ? (
                    <div className="GroupDetails-container h-100">

                        <div className="row m-0">
                            <div className="col-12 GroupDetails-header-container">
                                <button className="btn-cleared" onClick={onHideModal}>
                                    <img src={icnBackward} alt="Backward icon"/>
                                </button>

                                <p className='noselect'>
                                    Info del grupo
                                </p>
                            </div>
                        </div>

                        <div className="row m-0 main-box">
                            <div className="col-12 p-0">
                                <div className="row m-0">
                                    <div className="col-12 GroupDetails-image-container">
                                        <img src={groupState && groupState.ImageUrl ? groupState.ImageUrl:imgPlaceholderGroup}
                                            alt="Group image"
                                            className='group-img'
                                        />
                                        {
                                            isAdmin ? (
                                                <>
                                                    <button className="btn-cleared" onClick={onImageChangeBtnTapped}>
                                                        <img src={icnCamera} alt="camera icon"/>
                                                    </button>

                                                    <input id="image"
                                                        type="file"
                                                        ref={inputFile}
                                                        onChange={(event) => {
                                                            processFile(event);
                                                        }}
                                                        onError={error => console.log(error)}
                                                        accept="image/*"
                                                        style={{display: 'none'}}
                                                        multiple={false}
                                                    />
                                                </>
                                            ):(null)
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 p-0">
                                <div className="row m-0">
                                    <div className="col-12 GroupDetails-group-col-header">
                                        Nombre del grupo
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 p-0">
                                <div className="row m-0">
                                    <div className={`col-12 GroupDetails-group-col noselect ${isAdmin ? 'mouse-pointer':''}`} onClick={onTitleTapped}>
                                        {groupState.Titulo}

                                        {
                                            isAdmin ? (
                                                <img src={icnMore} alt="More icon" width='40' height='40'/>
                                            ):(null)
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 p-0">
                                <div className="row m-0">
                                    <div className="col-12 GroupDetails-group-col-header">
                                        Descripción del grupo
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 p-0">
                                <div className="row m-0">
                                    <div className={`col-12 GroupDetails-group-col noselect ${isAdmin ? 'mouse-pointer':''}`} onClick={onDescriptionTapped}>
                                        {groupState.Descripcion ? groupState.Descripcion : "Ingresa una descripión para el grupo"}

                                        {
                                            isAdmin ? (
                                                <img src={icnMore} alt="More icon" width='40' height='40'/>
                                            ):(null)
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 p-0">
                                <div className="row m-0">
                                    <div className="col-12 GroupDetails-group-col-header">
                                        Participantes ({groupState.Usuarios.length})
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 p-0">
                                <div className="row m-0">
                                    <div className="col-12 GroupDetails-group-members-container">

                                        {
                                            isAdmin &&
                                            <div className="row m-0">
                                                <div className="GroupDetails-group-add-container col-12 px-0" onClick={onAddNewMembers}>
                                                    <img src={icnPlus} alt="plus icon" width='60' height='60'/>
                                                    <p className='noselect'>
                                                        Añadir participantes
                                                    </p>
                                                </div>
                                            </div>
                                        }

                                        {membersItem()}
                                    </div>
                                </div>
                            </div>

                            <button className="btn-cleared footer-btn noselect" onClick={onGroupLeaveBtnTapped}>
                                Salir del grupo
                            </button>
                        </div>


                        {bottomSheetOpen && type === "title" && editTitleModal()}
                        {bottomSheetOpen && type === "description" && editDescription()}

                        {showLeaveGroupBottomSheet && onLeaveGroupBottomSheet()}

                        {
                            isGroupAdding && groupState && groupState.Usuarios &&
                            <ContactList currentUser={props.currentUser}
                                onBottomSheetDimiss={onBottomSheetDimissContactList}
                                onGroupSendBtnTappedContactList={onGroupSendBtnTappedContactList}
                                isGroupAdding={true}
                                groupPreviusUser={groupState.Usuarios}
                            />
                        }
                    </div>
                ):(null)
            }
        </ModalDefault>
    );
}

export { GroupDetails }
