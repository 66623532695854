import React, { useState, useEffect } from 'react';

import './HomeFilterToggler.scss';

import icnFilter from '../../../../_assets/media/images/icons/icn_filter.png';
import icnFilterActive from '../../../../_assets/media/images/icons/icn_filter_active.png';
import icnLetterSize from '../../../../_assets/media/images/icons/icn_size_typo.png'

const HomeFilterToggler = (props) => {

    const onFilterBtnTapped = () => {
        if (props.onOpenFilters) {
            props.onOpenFilters();
        }
    }

    return (
        <div className="HomeFilterToggler-container row mx-0">
            <div className="col-12 p-0 HomeFilterToggler-box">
                <span className='line-span'></span>
                <button className='btn-cleared btnToggler letterSize' onClick={() => {props.onLetterTapped()}}>
                    <img src={icnLetterSize} alt="filter icon" width='30' height='30'/>
                    <span className='text-letter-size'>Tamaño de letra</span>
                </button>
                <button className='btn-cleared btnToggler' onClick={() => {onFilterBtnTapped()}}>
                    <img src={props.isActiveFilters ? icnFilterActive:icnFilter} alt="filter icon" width='30' height='30'/>
                    <span className='ml-1'>{props.isActiveFilters ? 'Quitar filtro':'Filtros'}</span>
                </button>
            </div>
        </div>
    );
}

export { HomeFilterToggler };
