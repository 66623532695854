import React, { useState, useEffect } from 'react';
import './Rules.scss';

import { ModalDefault } from '../';

import { infoService } from '../../../_resources/services';

import iconClose from '../../../_assets/media/images/icons/icn_close.png';
import iconPlus from '../../../_assets/media/images/icons/icn_plus.png';
import iconMinus from '../../../_assets/media/images/icons/icn_minus.png';
import iconContact from '../../../_assets/media/images/icons/icn_contact_assist_small.png';
import { BehaviorSubject } from 'rxjs';

const Rules = (props) => {
    const [catalogs, setCatalogs] = useState(null);
    const [version, setvVersion] = useState({})

    const onHide = () => {
        if (props.onHide) {
            props.onHide();
        }
    }

    const goToSupport = () => {
        if (props.goToSupport) {
            props.goToSupport();
        }
    }

    const toggleView = (index) => {

        if (catalogs && 'TiposPublicacion' in catalogs && catalogs.TiposPublicacion[index]) {
            let catalogsCP = JSON.parse(JSON.stringify(catalogs));
            catalogsCP.TiposPublicacion[index].open = !catalogsCP.TiposPublicacion[index].open;
            setCatalogs(catalogsCP);
        }
    }

    useEffect(() => {
        let isSubscribed = true;
        infoService.catalogs.subscribe((response) => {
            if (typeof response === 'object' && response !== null && isSubscribed) {
                if ('TiposPublicacion' in response) {
                    response.TiposPublicacion = response.TiposPublicacion.map((item) => {
                        item.open = false;
                        return item;
                    });
                }
                setCatalogs(response);
            }
        });
        const currentVersionSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('infoVersion')));
        setvVersion(currentVersionSubject._value)
        return () => isSubscribed = false;
    }, []);

    const ruleItems = () => {
        const items = catalogs && 'TiposPublicacion' in catalogs && catalogs.TiposPublicacion.map((item, index) => {

            return (
                <div className='col-12 p-0 Rules-item-container' key={index}>
                    <div className="row m-0">
                        <div className="col-12 Rules-item-title" onClick={e => toggleView(index)}>
                            {item.TipoPublicacion} {item.open}
                            <img src={item.open ? iconMinus:iconPlus} alt="collase icon" height='30' width='30'/>
                        </div>
                        <div className={`col-12 Rules-item-content ${item.open ? 'showing':''}`}>
                            {item.Descripcion}
                        </div>
                    </div>
                </div>
            );
        });
        return (
            <div className="row m-0">
                <iframe src={version.RulesUrl} title="reglas de uso" height="100%" className="iframe"></iframe>
            </div>
        );
    }

    const headerView = () => {
        return (
            <div className="Rules-header-view row m-0">
                <div className="row m-0 Rules-header-container">
                    <div className="col-12 p-0 pos-relative">
                        <button className='btn-cleared Rules-header-close-btn' onClick={onHide}>
                            <img src={iconClose} alt="close icon"/>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    const renderView = () => {
        return (
            <div className="Rules-container">
                {headerView()}
                {ruleItems()}

                <div className="row m-0 Rules-contact-container" onClick={goToSupport}>
                    <div className="col-12 p-0">
                        <img src={iconContact} alt="contact icon"/>
                        ¿Necesitas ayuda?
                    </div>
                </div>
            </div>
        );
    }

    return (<ModalDefault show={props.show} className='super full-view'>{renderView()}</ModalDefault>)
}

export { Rules };
