import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { authenticationService } from '../services';

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/welcome', state: { from: props.location } }} />
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(currentUser.clv_tipousuario) === -1) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: '/'}} />
        }


        if (currentUser && currentUser.Usuario) {
            const {FillRate} = currentUser.Usuario;
            const {location} = props;

            if (props.match) {
                if (
                    !['/register-steps', '/register-wizard/:step', '/membership/:type'].includes(props.match.path) &&
                    (!FillRate.TieneDatos || !FillRate.TieneMembresia)
                ) {
                    return <Redirect to={{ pathname: '/register-steps', state: { id: 2 } }}/>
                } else if (
                    props.match === '/register-steps' ||
                    props.match === '/register-wizard/:step' ||
                    props.match === '/membership/:type'
                ) {
                    return <Redirect to={{ pathname: '/'}} />
                }
            }

        }

        // authorised so return component
        return <Component {...props} />
    }} />
)
