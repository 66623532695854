import React, { useState, useEffect } from 'react';
import { swalAlertsService, authenticationService, profileService } from '../../../_resources/services';
import { BottomSheetShared } from '../';

import icnGift from '../../../_assets/media/images/icons/icn_gift.png';

import './DiscountCode.scss';

const DiscountCode = (props) => {
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const [discountCode, setDiscountCode] = useState(null);
    const [isDiscountInvalid, setIsDiscountInvalid] = useState(false);

    const onBottomSheetDimiss = () => {
        setIsBottomSheetOpen(false);

        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const onCodeSuccess = () => {
        if (props.onCodeSuccess) {
            props.onCodeSuccess();
        }
    }

    const onDiscountCodeSend = () => {
        if (discountCode && discountCode.length && discountCode.length === 9) {
            setIsDiscountInvalid(false);
            let loader = swalAlertsService.createLoading("Espere un momento...");
            loader.fire();
            profileService.postGeneric('user/membership/code', 'POST', {Codigo: discountCode}).then(
                (response) => {
                    loader.close();
                    if (response) {
                        if (response.data) {
                            swalAlertsService.showToast('success', 'Código de descuento enviado correctamente');
                            authenticationService.getUser().then(() => {
                                onCodeSuccess();
                            });
                        } else {
                            swalAlertsService.showToast('error', 'No pudimos enviar código de descuento');
                            setIsDiscountInvalid(true);
                        }
                    }
                },
                (error) => {
                    console.log(error);
                    loader.close();
                    swalAlertsService.showToast('error', 'No pudimos enviar código de descuento');
                    setIsDiscountInvalid(true);
                }
            )
        }

    }

    const bottomSheetFooterBtn = () => {
        return (
            <button disabled={discountCode && discountCode.length && discountCode.length === 9 ? false:true}
                className={`btn-cleared ${discountCode && discountCode.length && discountCode.length === 9 ? 'active':''}`}
                onClick={onDiscountCodeSend}>
                Canjear
            </button>
        )
    }

    const renderDiscountInputContainer = () => {

        return (
            <>
                <div className="row DiscountCode-bottomsheet-container">
                    <div className="col-12 text-center">
                        <img src={icnGift} alt="icnGift" width='40' height='40'/>
                    </div>
                    <div className="col-12 DiscountCode-bottomsheet-txt-box">
                        Ingresa tu código
                    </div>
                    <div className={`col-12 DiscountCode-bottomsheet-input-box ${isDiscountInvalid ? 'hasError':''}`}>
                        <input type="text" placeholder='ABC123DFG' value={discountCode}
                            onChange={(e) => {
                                if (isDiscountInvalid) {
                                    setIsDiscountInvalid(false);
                                }
                                let pattern = /[0-9a-zA-Z]+/g;
                                let value = e.target.value.replace(/\s/g, '');

                                let ress = value.replace(pattern, '');

                                if (ress && ress.length) {
                                    let ressSplit = ress.split('');

                                    if (ressSplit.length) {
                                        ressSplit.forEach(element => value = value.replace(`${element}`, ''));
                                    }
                                }
                                value = `${value}`;
                                value = value.toUpperCase();
                                value = value.substr(0, 9);
                                setDiscountCode(value);
                            }}
                        />
                    </div>

                </div>

                {
                    isDiscountInvalid &&
                    <div className="col-12 DiscountCode-bottomsheet-alert-disclaimer">
                        Código NO válido
                    </div>
                }
            </>
        );
    }

    return (
        <BottomSheetShared showHeader={true} showCancelBtn={true}
            blocking={true}
            isOpen={true}
            onDismiss={onBottomSheetDimiss}
            title='Código de descuento'
            footerBtn={bottomSheetFooterBtn()}
            showFilterBtn={false}
            isActiveFilters={false}
            shouldClose={true}
        >
            {renderDiscountInputContainer()}
        </BottomSheetShared>
    );
}

export { DiscountCode };
