import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import {
    useHistory,
    Redirect
} from "react-router-dom";
import { GoogleReCaptcha, GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import './RegisterData.scss';
import { toolsHelper } from '../../../../../_resources/helpers';
import { authenticationService, swalAlertsService } from '../../../../../_resources/services';

import iconClose from '../../../../../_assets/media/images/icons/icn_close.png';

import icnArrowDown from '../../../../../_assets/media/images/icons/icn_arrow_down_green.png';
import icnsReg from '../../../../../_assets/media/images/icons/icns_reg.png';

import { UniversalPicker } from '../../../../shared';
import { infoService } from '../../../../../_resources/services';

const RegisterData = (props) => {
    const history = useHistory();
    const [openUniversalPicker, setOpenUniversalPicker] = useState(false)
    const [goToMain, setGoToMain] = useState(false);
    const [postState, setPostState] = useState({
        email: "",
        password: "",
        password2: '',
        phone: "",
        countryId: 0,
        lada: "",
        paisResidencia: "",
        googleToken: null
    });
    const [showPass1, setShowPass1] = useState(false);
    const [showPass2, setShowPass2] = useState(false);
    const [haveToken, setHaveToken] = useState(false);
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [token, setToken] = useState();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const email = useRef(null);
    const password = useRef(null);
    const password2 = useRef(null);
    const phone = useRef(null);

    let tokenXd = null;

    const goToWelcome = () => {
        swalAlertsService.answer("", "Si regresas perderás los cambios que hayas hecho en esta pantalla.", "Si, regresar").then((result) => {
            if (result.isConfirmed) {
                if (history.action !== 'POP') {
                    history.goBack();
                } else {
                    history.push('/welcome');
                }
            }
        });
    }


    const onPasswordChanged = (e, key) => {
        let postStateCP = JSON.parse(JSON.stringify(postState))

        if (key === 1) {
            postStateCP.password = e.target.value;
        } else {
            postStateCP.password2 = e.target.value;
        }
        setPostState(postStateCP)
    }

    const onPhoneChanged = (e) => {
        let postStateCP = JSON.parse(JSON.stringify(postState));
        let regex = new RegExp(/^\d+$/);
        if (e.target.value.length) {
            if (/^\d+$/.test(e.target.value)) {
                postStateCP.phone = e.target.value;
            }
        } else {
            postStateCP.phone = e.target.value;
        }
        setPostState(postStateCP)
    }

    const onRowTapped = (type) => {
        if (props.onRowTapped) {
            props.onRowTapped(type);
        }
    }

    const onNextBtnTapped = async() => {
        if (props.onNextBtnTapped) {
            let token  = await handleReCaptchaVerify();
            let cpPostState = JSON.parse(JSON.stringify(postState));
            cpPostState.email = email.current.value;
            cpPostState.password = password.current.value;
            cpPostState.password2 = password2.current.value;
            cpPostState.phone = phone.current.value;
            cpPostState.googleToken = token;
            props.onNextBtnTapped(cpPostState);
            setPostState(cpPostState);
        }
    }

    const onUniverlPickerHide = (data) => {
        setOpenUniversalPicker(data)
    }

    const onElementSelected = (element) => {
        let postStateCP = JSON.parse(JSON.stringify(postState))
        postStateCP.lada = element.Lada
        postStateCP.countryId = element.clv_pais
        postStateCP.paisResidencia = element.PaisResidencia
        setPostState(postStateCP)
    }

    const showPicker = () => {
        setOpenUniversalPicker(true)
    }

    useEffect(() => {
        let suscribed = true
        infoService.catalogs.subscribe((catalogs) => {
            if (suscribed && catalogs.Paises) {
                let defaultCountry = catalogs.Paises.filter(result => result.Lada === "52");

                if (defaultCountry[0]) {
                    let postStateCP = JSON.parse(JSON.stringify(postState))
                    postStateCP.lada = defaultCountry[0].Lada
                    postStateCP.countryId = defaultCountry[0].clv_pais
                    postStateCP.paisResidencia = defaultCountry[0].PaisResidencia
                    setPostState(postStateCP)
                }
            }
        });
        return () => suscribed = false
    }, []);

    const togglePass = (key) => {
        if (key === 1) {
            setShowPass1(!showPass1);
        } else {
            setShowPass2(!showPass2);
        }
    }

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        let error = false;
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            error = true;
        }
        const token = await executeRecaptcha('login');
        return token;
        // Do whatever you want with the token
    }, [executeRecaptcha]);


    return (

        <>
            {
                <div className="RegisterData-container">

                    <div className="row m-0">
                        <div className="col-12 p-0">
                            <button className='btn-cleared RegisterData-close-btn' onClick={goToWelcome}>
                                <img src={iconClose} alt="close icon" />
                            </button>
                        </div>
                    </div>

                    <div className="row m-0">
                        <div className="col-12 p-0">

                            <div className="row m-0 RegisterData-titles-box">
                                <div className="col-12 p-0 title">
                                    Crear cuenta
                                </div>
                                <div className="col-12 p-0 subtitle">
                                    Ingresa tus datos
                                </div>
                            </div>

                            <div className="row m-0">

                                <div className="col-12 p-0">

                                    <div className="row m-0">
                                        <div className={'col-12 px-0 RegisterData-input-box'}>
                                            <label htmlFor="email" className='icon'>
                                                <img src={icnsReg} alt="mail icon" className='xpos-0' />
                                            </label>
                                            <input
                                                type="text"
                                                name='email'
                                                className='iconned'
                                                // value={postState.email} 
                                                placeholder='Correo electrónico'
                                                // onChange={(e) => { onEmailChanged(e) }} 
                                                ref={email}
                                            />
                                        </div>
                                        { /*  Yo creo que con que el toast avise si faltan o no son válidos es mas que suficiente */}
                                        {
                                            postState.email && !toolsHelper.validateEmail(postState.email) ? (
                                                <div className="col-12 p-0">
                                                    <small className='text-danger'>
                                                        {
                                                            !postState.email ? ('Campo requerido') : (null)
                                                        }

                                                        {
                                                            postState.email && !toolsHelper.validateEmail(postState.email) ? ('Ingresa un correo electrónico válido') : (null)
                                                        }
                                                    </small>
                                                </div>
                                            ) : (null)
                                        }

                                    </div>

                                    <div className="row m-0">
                                        <div className={'col-12 px-0 RegisterData-input-box'}>
                                            <label htmlFor="password" className='icon'>
                                                <img src={icnsReg} alt="password icon" className='xpos-1' />
                                            </label>
                                            <input type={showPass1 ? 'text' : 'password'}
                                                id='password'
                                                name='password'
                                                className='iconned hasToggler'
                                                placeholder='Contraseña'
                                                ref={password}
                                            // onChange={(e) => { onPasswordChanged(e, 1) }} 
                                            />
                                            <button className="btn-cleared toggler" onClick={e => togglePass(1)}>
                                                <img src={icnsReg} alt="password icn" className={showPass1 ? 'xpos-3' : 'xpos-2'} />
                                            </button>
                                        </div>
                                    </div>


                                    <div className="row m-0">
                                        <div className={'col-12 px-0 RegisterData-input-box'}>
                                            <label htmlFor="password" className='icon'>
                                                <img src={icnsReg} alt="password icon" className='xpos-1' />
                                            </label>
                                            <input type={showPass2 ? 'text' : 'password'}
                                                id='password'
                                                name='password'
                                                className='iconned hasToggler'
                                                placeholder='Confirma tu contraseña'
                                                ref={password2}
                                            // onChange={(e) => { onPasswordChanged(e, 2) }} 
                                            />
                                            <button className="btn-cleared toggler" onClick={e => togglePass(2)}>
                                                <img src={icnsReg} alt="password icn" className={showPass2 ? 'xpos-3' : 'xpos-2'} />
                                            </button>
                                        </div>
                                    </div>

                                    <div className="row m-0">
                                        <div className={'col-12 px-0 RegisterData-input-box'}>
                                            <p className='passwordDisclaimer'>
                                                Al menos una letra mayúscula, Al menos una letra minúscula, Al menos un número, Al menos una carácter especial. Se consideran los siguientes: ! # $ % & _, Al menos 8 caracteres
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row m-0">
                                        <div className={`col-12 RegisterData-input-box-lada`}>
                                            <div className="row m-0 d-flex align-items-center">
                                                <div className="col p-0 lada-btn-box">
                                                    <button onClick={() => showPicker("phone")}>
                                                        {postState.lada != null ? "+" + postState.lada : "NA"} <img src={icnArrowDown} alt="Arrow down" width='25' height='25' />
                                                    </button>
                                                </div>
                                                <div className="col p-0 input-box">
                                                    <input type="text"
                                                        className='ladded'
                                                        id='phone'
                                                        name='phone'
                                                        maxLength='10'
                                                        ref={phone}
                                                    // onChange={(e) => { onPhoneChanged(e) }}
                                                    // value={postState.phone}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row m-0 RegisterData-titles-box">
                                <div className="col-12 p-0 subtitle line-height-20px">
                                    Enviaremos un código por SMS para validar tu cuenta al número que proporciones.
                                </div>
                            </div>



                            <div className="row m-0">
                                <div className="col-12 px-0 RegisterData-step-btn-box">
                                    <button onClick={() => { onNextBtnTapped() }}>
                                        Siguiente
                                    </button>
                                </div>
                            </div>


                        </div>
                    </div>



                </div>
            }

            {
                openUniversalPicker ? (
                    <UniversalPicker mode='phone' country={postState.paisResidencia} onDismiss={onUniverlPickerHide} onElementSelected={onElementSelected} />
                ) : (null)
            }

            {
                goToMain ? (
                    <Redirect to={{ pathname: '/' }} />
                ) : (null)
            }

        </>


    )
}

export { RegisterData }
