import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import './PostDetailsComments.scss';

import { Dropdown } from 'react-bootstrap';
// import ShowMore from 'react-show-more';
import ShowMoreText from "react-show-more-text";

import { toolsHelper } from '../../../../../_resources/helpers';
import { swalAlertsService } from '../../../../../_resources/services';

import { VideoPlayer, AudioPlayerSimple, AudioPlayer } from '../../../../shared';

import icnUserPlaceholder from '../../../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnMore from '../../../../../_assets/media/images/icons/icn_more_options.png';
import icnDoc from '../../../../../_assets/media/images/icons/icn_doc.png';
import icnDownload from '../../../../../_assets/media/images/icons/icn_download.png';
import icnstart from '../../../../../_assets/media/images/icons/icn_batch_star.svg';
import icnclose from '../../../../../_assets/media/images/icons/icn_close.png';



const PostDetailsComments = (props) => {
    const history = useHistory();

    const [imageFullScreen, setimageFullScreen] = useState(false)
    const [imageCommentSelected, setimageCommentSelected] = useState("")


    const onOptionTapped = (type, item) => {
        if (type === 'delete') {
            swalAlertsService.answer("", "¿Estás seguro de querer borrar tu comentario?", "Si, borrar").then((result) => {
                if (result.isConfirmed) {
                    if (props.onOptionTapped) {
                        props.onOptionTapped({type, item});
                    }
                }
            })
        } else if (props.onOptionTapped) {
            props.onOptionTapped({type, item});
        }
    }

    function setUser(comment) {
        if (comment && comment.clv_usuario) {
            const {currentUser} = props;
            if(currentUser.Usuario.clv_usuario === comment.clv_usuario ) {
                history.replace('/profile')
            }else {
                console.log(props);
                if (props.postState && props.postState.id) {
                    history.push('/profile-user/' + comment.clv_usuario, {from: `/post/${props.postState.id}`} )
                }

            }
        }

    }

    const commentOptions = (item) => {
        const {currentUser} = props;
        if (
            currentUser &&
            currentUser.Usuario &&
            currentUser.Usuario.clv_usuario === item.clv_usuario
        ) {

            return (
                <div className="col p-0 PostDetailsComments-options-container">
                    <Dropdown>
                        <Dropdown.Toggle>
                            <img src={icnMore} alt="options icon" width='30' height='17'/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="1" onClick={e => onOptionTapped('edit', item)}>Editar comentario</Dropdown.Item>
                            <Dropdown.Item eventKey="2" className='text-danger' onClick={e => onOptionTapped('delete', item)}>Eliminar comentario</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            );
        }
        return null;
    }

    const goImageFullScreen = (url) => {
        setimageFullScreen(!imageFullScreen)
        setimageCommentSelected(url)
    }

    const showContactData =  (comment) => {
        if (comment.Contactos) {
            const items = comment.Contactos.map((item, index) => {
                return (
                    <div className={`col-12 px-0 text-box text-18 isContact `}>
                        <div className="row m-0">
                            <div className="col avatar-image-box-50">
                                <img src={item.ThumbnailUrl ? item.ThumbnailUrl:icnUserPlaceholder} alt="User image" className="avatar"
                                    onError={(e)=>{
                                        e.target.onerror = null;
                                        e.target.src=icnUserPlaceholder
                                    }}/>
                            </div>
                            <div className="col pe-0 contactName">
                                {item.Nombre ? item.Nombre:item.Celular}
                            </div>

                            <a className='px-0' href={`tel://${item.Celular}`}>
                                <div className="col-12 callBtn px-0">
                                    Llamar
                                </div>
                            </a>
                        </div>
                    </div>
                );
            });

            return (
                <div className="PostDetailsComments-files-row row m-0">
                    {items}
                </div>
            );
        }
        return null;
    }

    const showIncludedFiles = (comment) => {
        if (comment.Adjuntos) {
            const items = comment.Adjuntos.map((item, index) => {
                let element = null;

                if (!item.EliminarAdjunto && item.URL) {
                    if (item.Extension === "pdf" || item.Extension === "xlsx"  || item.Extension === "docx" || item.Extension === "pptx") {
                        element = <div className={`PostDetailsComments-pdf-container col-12 ${!comment.Mensaje ? 'noComments':''}`} key={`${index}-files`}>
                            <a href={item.URL} target='_blank'>
                                <img src={icnDoc} alt="doc icon"/>
                                <div>
                                    { item.Nombre ? item.Nombre:'Ver documento'}
                                </div>
                            </a>
                        </div>
                    } else if (item.Extension === "png" || item.Extension === "jpeg" || item.Extension === "jpg") {
                        element = <div className={`PostDetailsComments-image-container col-12 ${!comment.Mensaje ? 'noComments':''}`} key={`${index}-images`}>
                            <img src={item.URL} alt="post image" className='image-container' onClick={() => goImageFullScreen(item.URL)}/>
                            <a href={item.URL} target='_blank'>
                                <img src={icnDownload} alt="donwload icon"/>
                            </a>
                        </div>
                    } else if (item.Extension === "mp3" || item.Extension === "audio" || item.Extension === "ogg") {
                        element = <div className={`PostDetailsComments-image-container col-12 ${!comment.Mensaje ? 'noComments':''}`} key={`${index}-images`}>
                            {
                                <AudioPlayerSimple url={item.URL} controls controlsList='nodownload nofullscreen noremoteplayback noplaybackrate' id={`AudioComment-${item.clv_adjuntoxmensajepublicacion}`}/>
                            }
                            {
                                // <AudioPlayer url={item.URL} id={`AudioComment-${item.clv_adjuntoxmensajepublicacion}`} duration={item.Duracion} counterToRight={true}/>
                            }
                        </div>
                    } else if (item.Extension === "mp4" || item.Extension === "video") {
                        element = <div className={`PostDetailsComments-image-container col-12 ${!comment.Mensaje ? 'noComments':''}`} key={`${index}-images`}>
                            <VideoPlayer url={item.URL} id={`VideoComment-${item.clv_adjuntoxmensajepublicacion}`} thumbnail={item.ThumbnailUrl}/>
                            <a href={item.URL} target='_blank'>
                                <img src={icnDownload} alt="donwload icon"/>
                            </a>
                        </div>
                    }
                }
                return(element);
            });

            return (
                <div className="PostDetailsComments-files-row row m-0">
                    {items}
                </div>
            );

        }
        return null;
    }

    const getMentionLink = (clv_usuario) => {
        if (clv_usuario) {
            if (
                props.currentUser &&
                props.currentUser.Usuario &&
                props.currentUser.Usuario.clv_usuario &&
                props.currentUser.Usuario.clv_usuario !== clv_usuario
            ) {
                return `/profile-user/${clv_usuario}`;
            } else {
                return `/profile`;
            }
        }
        return '';
    }

    const goToMentionLink = (clv_usuario) => {
        if (clv_usuario) {
            if (
                props.currentUser &&
                props.currentUser.Usuario &&
                props.currentUser.Usuario.clv_usuario &&
                props.currentUser.Usuario.clv_usuario !== clv_usuario
            ) {
                history.push(`/profile-user/${clv_usuario}`, {from: '/messages'});
            } else {
                history.push(`/profile`, {from: '/messages'});
            }
        }
        return '';
    }

    const createMentionTxt = (message) => {
        let value = message.Mensaje;
        if (message.Menciones && message.Menciones.length) {
            let finalValue = [];
            value = value.trim();
            let tokenString = value.split(' ');
            let keyValue = 0;
            if (tokenString.length) {
                tokenString.forEach((item, i) => {
                    if (item.length) {

                        if (item.indexOf('@@') >= 0) {
                            const brSpacing = item.indexOf('\n') >= 0;
                            const fixedTxt = item.replaceAll('\n', '');
                            const mentioned = message.Menciones.filter(el => el.Tag === fixedTxt);

                            if (mentioned.length) {
                                const obj = [0].map((el, i) => {
                                    keyValue += 1;
                                    if (brSpacing) {
                                        return (
                                            <span key={keyValue}>
                                                <br/>
                                                <a className='bubbleMention' title={mentioned[0].NombreCompleto} href={getMentionLink(mentioned[0].clv_usuario)} onClick={e => goToMentionLink(mentioned[0].clv_usuario)}>{`${mentioned[0].NombreCompleto}${tokenString.length - 1 > i ? ' ':''}`}</a>
                                            </span>
                                        );
                                    }
                                    return (<a key={keyValue} className='bubbleMention' title={mentioned[0].NombreCompleto} href={getMentionLink(mentioned[0].clv_usuario)} onClick={e => goToMentionLink(mentioned[0].clv_usuario)}>{`${mentioned[0].NombreCompleto}${tokenString.length - 1 > i ? ' ':''}`}</a>);
                                });
                                finalValue = finalValue.concat(obj);
                            }
                        } else {
                            keyValue += 1;
                            const obj = [0].map((el, i) => {
                                return (<span key={keyValue}>{`${item}${tokenString.length - 1 > i ? ' ':''}`}</span>);
                            });
                            finalValue = finalValue.concat(obj);
                        }
                    }
                });

            }
            return finalValue;
        }
        return message.Mensaje || null;
    }

    const renderMessageText = (message) => {
        if (message && message.Mensaje) {
            const isDeleted = message.Eliminado;
            if (!isDeleted && message.Menciones && message.Menciones.length) {
                return createMentionTxt(message)
            }
            return message.Mensaje;
        }
        return null;
    }

    const comments = () => {

        if (props && props.item && props.item.Comments) {
            return props.item.Comments.map((comment, index) => {
                console.log(comment.Mentor);
                if (!comment.Eliminado) {

                    return (
                        <React.Fragment key={index}>
                            {
                                (
                                    <div className="PostDetailsComments-comment-container row m-0" key={index}>
                                        <div className="col p-0 PostDetailsComments-user-image-container" onClick={e => setUser(comment)}>
                                            <img src={comment.ThumbnailUrl ? comment.ThumbnailUrl:icnUserPlaceholder} alt="user photo" className="avatar"
                                                onError={(e)=>{
                                                    e.target.onerror = null;
                                                    e.target.src=icnUserPlaceholder
                                                }}
                                            />
                                            {
                                                comment.clv_estatusbm === 2 &&
                                                <img src={icnstart} width="10px" height="10px" alt="miembro" className="miembro"/>
                                            }
                                        </div>
                                        <div className="col PostDetailsComments-comment-content">

                                            <div className="row m-0 PostDetailsComments-comment-row h-100">

                                                <div className="col-12 p-0">
                                                    <div className="row m-0 PostDetailsComments-user-details">
                                                        <div className="col-12 p-0 title">

                                                            <div className="row m-0">
                                                                <div className="col p-0 text-truncate name mouse-pointe" onClick={e => setUser(comment)}>
                                                                    <div className="text-truncate mouse-pointer">{comment.NombreCompleto}    {comment.Mentor ? 
                                                                        (
                                                                            <span className='label-Mentor'>Mentor</span>
                                                                        ):
                                                                        (null)}</div>
                                                                    <div className='timesince mouse-pointer'>{toolsHelper.timeSince(comment.Fecha)}</div>
                                                                </div>
                                                                {commentOptions(comment)}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0 subtitle mouse-pointer" onClick={e => setUser(comment)}>
                                                            {`${comment.Cargo}${comment.EmpresaNombreCorto && comment.Cargo ? ' | ':''}${comment.EmpresaNombreCorto}`}
                                                        </div>

                                                        <div className="col-12 p-0 subtitle mouse-pointer" onClick={e => setUser(comment)}>
                                                            {`${comment.EstadoResidencia}${comment.EstadoResidencia && comment.PaisResidencia ? ', ':''}${comment.PaisResidencia}`}
                                                        </div>
                                                    </div>

                                                    {
                                                        comment.Mensaje &&
                                                        <div className={`row m-0 PostDetailsComments-comment-box ${props.fontSize === 18 ? 'dinamycSmall': props.fontSize === 22 ? 'dinamycMedium' : props.fontSize == 28 ? 'dinamycBig' : 'dinamycSmall'} `}>
                                                            {
                                                                <ShowMoreText
                                                                    lines={3}
                                                                    more="Ver más"
                                                                    less="Ver menos"
                                                                    anchorClass="PostDetailsComments-comment-showmore"
                                                                    expanded={false}
                                                                    truncatedEndingComponent={"... "}
                                                                >
                                                                {renderMessageText(comment)}
                                                                </ShowMoreText>
                                                            }
                                                        </div>
                                                    }
                                                    {showIncludedFiles(comment)}
                                                    {showContactData(comment)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </React.Fragment>
                    );
                }
                return null;
            });
        }
        return null;
    }
    return (
        <React.Fragment>
            {
                !imageFullScreen &&
                <div className="PostDetailsComments-container">
                    {comments()}
                </div>
            }

            {
                imageFullScreen &&
                (
                    <div className="overlay-image">
                        <img src={icnclose} className="btn-close" onClick={() => goImageFullScreen()}/>
                        <div className="image-full-screen">
                            <img src={imageCommentSelected}/>
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    );
}

export { PostDetailsComments }
