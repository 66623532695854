import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './ProfilePersonalInfo.scss';

import { toolsHelper } from '../../../../../_resources/helpers';

import icnEditGreen from '../../../../../_assets/media/images/icons/icn_edit_green.png';
import icnStar from '../../../../../_assets/media/images/icons/icn_batch_star.svg';
import placeholderuser from '../../../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnsSheets from '../../../../../_assets/media/images/icons/icns_sheets.png';
import birth from '../../../../../_assets/media/images/icons/icn_birthday.png';

import icnCalendar from '../../../../../_assets/media/images/icons/icn_calendar.png';
import icnMentoring from '../../../../../_assets/media/images/icons/icn_mentoring.png';

const ProfilePersonalInfo = (props) => {
    const history = useHistory();


    const getUserDate = (dateString) => {
        if (dateString) {
            const month = toolsHelper.getMonthName(dateString);

            const date = new Date(dateString);

            return `${month}. ${date.getFullYear()}`;
        }
        return null;
    }

    const getDateBirth = () => {
        if (props.user.FechaNacimiento) {
            const month = toolsHelper.getFullMonthName(props.user.FechaNacimiento);
            let x = new Date(props.user.FechaNacimiento);
            let formatted_date = x.getDate() + " " + month + " " + x.getFullYear();
            return (
                <div className="col-12 p-0 detailsCol">
                    <img src={birth} className='outIcon p-1' />
                    <span>{formatted_date}</span>
                </div>
            )
        }
        return null;
    }

    const getPhoneDetails = () => {
        if (props.user && props.user.Celular) {
            return <a href={`tel://${props.user.Celular}`} className='anchorDetails ps-0'><span className='font-color-green text-truncate detailsDv'>{props.user.Celular}</span></a>;
        }

        return <span className='placeholderTxt mouse-default'>Sin definir celular</span>
    }

    const getEmailDetails = () => {

        if (props.user && props.user.Email) {

            return <a href={`mailto:${props.user.Email}`} className='anchorDetails'><div className='font-color-green text-truncate detailsDv' title={props.user.Email}>{props.user.Email}</div></a>;
        }

        return <span className='placeholderTxt mouse-default'>Sin definir email</span>
    }


    const getMentoringData = () => {
        // icnCalendar


        if (props.user.OfreceMentoria && (props.user.Mentoria || props.user.MentoriaPersonalizado)) {
            if (props.user.clv_tipomentoria === 4 && props.user.MentoriaPersonalizado) {
                return (
                    <div className="col-12 p-0 detailsCol">
                        <img src={icnMentoring} className='outIcon p-1' />
                        {
                            props.user.Mentoria ? (<span>{`Otorga mentoría - ${props.user.MentoriaPersonalizado || ''}`}</span>) : (<span>{`Otorga mentoría - ${props.user.Mentoria || ''}`}</span>)
                        }
                    </div>
                )
            }
            return (
                <div className="col-12 p-0 detailsCol">
                    <img src={icnMentoring} className='outIcon p-1' />
                    {
                        props.user.Mentoria ? (<span>{`Otorga mentoría - ${props.user.Mentoria || ''}`}</span>) : (<span>{`Otorga mentoría - ${props.user.MentoriaPersonalizado || ''}`}</span>)
                    }
                </div>
            )
        }
        return null;
    }

    const onInvitationLabelTapped = () => {
        if (props.user && props.user.clv_referidopor && props.user.clv_referidopor !== props.user.clv_usuario) {
            history.push('/profile-user/' + props.user.clv_referidopor, { from: '/profile' })
        }
    }


    return (
        <div className="ProfilePersonalInfo-container row h-100">
            <div className="col-12 p-0">
                <div className="row m-0 d-flex justify-content-between">
                    <div className="col nameCol px-0">
                        {
                            props.user && props.user.clv_estatusbm === 2 ? (
                                <img src={icnStar} alt="icnStar" />
                            ) : (null)
                        }
                        <div className={`text-truncate titleDiv ${props.user && props.user.clv_estatusbm === 2 ? 'ps-2' : ''}`}>{props.user && props.user.NombreCompleto ? props.user.NombreCompleto : ''}</div>
                    </div>
                    <div className="col btnCol pe-0">
                        <button className="btn-cleared" onClick={() => {
                            if (props.user && props.user.clv_usuario) {
                                history.push("/profile/edit/" + props.user.clv_usuario, {})
                            }
                        }}>
                            <span>Editar</span>
                            <img src={icnEditGreen} alt="icnEditGreen" width='40' height='40' />
                        </button>
                    </div>
                </div>

                <div className="data_account">
                    <div className="data_account_option">
                        {props.user.clv_estatusreferido && props.user.clv_estatusreferido != 3 ?
                            <p className='invitation-label'>Invitado por:
                                <span className='noselect' onClick={props.user.clv_estatusreferido == 2 ? onInvitationLabelTapped : () => { }}>
                                    {
                                        props.user.clv_estatusreferido == 1 ?
                                            (<strong className='ps-1 text-start noselect text-block-truncate-1'>Pendiente</strong>) :
                                            props.user.clv_estatusreferido == 2 && props.user.InvitadoPor && props.user.PhotoUrlInvitadoPor ?
                                                (
                                                    <>
                                                        <img src={props.user.PhotoUrlInvitadoPor} 
                                                            alt="invitation" 
                                                            className='noselect invitation-miniature'
                                                            onError={(e) => {
                                                                e.target.onerror = null;
                                                                e.target.src = placeholderuser
                                                            }}
                                                        />
                                                        <strong className='ps-1 text-start noselect text-block-truncate-1'>{props.user.InvitadoPor}</strong>
                                                    </>
                                                ) :
                                                props.user.clv_estatusreferido == 2 && props.user.InvitadoPor && !props.user.PhotoUrlInvitadoPor ?
                                                    (<strong className='ps-1 text-start noselect text-block-truncate-1'>{props.user.InvitadoPor}</strong>)
                                                    :
                                                    (null)
                                    }
                                </span>
                            </p>
                            : ''}
                    </div>
                </div>

                <div className="row m-0 ProfilePersonalInfo-detail-container">
                    <div className="col p-0 imgCol">
                        <img src={props.user && props.user.PhotoUrl ? props.user.PhotoUrl : placeholderuser} 
                            alt="placeholderuser"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = placeholderuser
                            }}
                        />
                    </div>
                    <div className="col">
                        <div className="row m-0 ps-2">
                            <div className="col-12 p-0 detailsCol">
                                <img src={icnsSheets} alt="icnsSheets" width='30' height='30' className='roleIcon' />
                                <span>{`${props.user && props.user.Cargo ? props.user.Cargo : ''}${props.user && props.user.Cargo && props.user.EmpresaNombreCorto ? ' | ' : ''}${props.user && props.user.EmpresaNombreCorto ? props.user.EmpresaNombreCorto : ''}`}</span>
                            </div>

                            <div className="col-12 p-0 detailsCol">
                                <img src={icnsSheets} alt="icnsSheets" width='30' height='30' className='phoneIcon' />
                                {getPhoneDetails()}
                            </div>

                            <div className="col-12 p-0 detailsCol d-flex align-items-center">
                                <img src={icnsSheets} alt="icnsSheets" width='30' height='30' className='emailIcon' />
                                {getEmailDetails()}
                            </div>

                            <div className="col-12 p-0 detailsCol">
                                <img src={icnsSheets} alt="icnsSheets" width='30' height='30' className='pinIcon' />
                                <span>{`${props.user && props.user.EstadoResidencia ? props.user.EstadoResidencia : ''}${props.user && props.user.EstadoResidencia && props.user.PaisResidencia ? ', ' : ''}${props.user && props.user.PaisResidencia ? props.user.PaisResidencia : ''}`}</span>
                            </div>

                            <div className="col-12 p-0 detailsCol">
                                <img src={icnsSheets} alt="icnsSheets" width='30' height='30' className='hobbyIcon' />
                                <span>{props.user && props.user.Aficion ? props.user.Aficion : (<span className='placeholderTxt p-0'>Sin afición</span>)}</span>
                            </div>

                            {getMentoringData()}

                            {getDateBirth()}

                            {
                                props.user && props.user.FechaAlta ? (
                                    <div className="col-12 p-0 detailsCol">
                                        <img src={icnCalendar} alt="icnsSheets" width='30' height='30' className='outIcon p-1' />
                                        <span>Miembro WeNet desde {getUserDate(props.user.FechaAlta)}</span>
                                    </div>
                                ) : (null)
                            }


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { ProfilePersonalInfo }
