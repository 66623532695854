import React, { useState, useEffect, useRef } from 'react';

import './AudioPlayer.scss';

import { Range } from 'react-range';

import { RangeSlider } from '../';
import { useDidMountEffect } from '../../../_resources/helpers';

import icnPlay from '../../../_assets/media/images/icons/icn_play.svg';
import icnPause from '../../../_assets/media/images/icons/icn_pause.svg';
import icnSound from '../../../_assets/media/images/icons/icn_sound.svg';
import icnMute from '../../../_assets/media/images/icons/icn_mute.svg';
import icnUserPlaceholder from '../../../_assets/media/images/icons/icn_user_placeholder.png';

const AudioPlayer = (props) => {
    let audioRef = useRef(null);
    let timelineRef = useRef(null);

    const [soundIcon, setSoundIcon] = useState(icnSound);
    const [playIcon, setPlayIcon] = useState(icnPlay);
    const [timeCounter, setTimeCounter] = useState('00:00');
    const [rangePercent, setRangePercent] = useState(0);

    const changeTimelinePosition = () => {
        if (audioRef.current && timelineRef.current) {
            const percentagePosition = (100 * audioRef.current.currentTime) / props.duration;
            timelineRef.current.style.backgroundSize = `${percentagePosition}% 100%`;
            timelineRef.current.value = percentagePosition;
            timeLeftCounter();
        }
    }

    // const changeTimelinePosition = () => {
    //     if (audioRef.current) {
    //         const percentagePosition = (100 * audioRef.current.currentTime) / props.duration;
    //         timeLeftCounter();
    //         setRangePercent(percentagePosition <= 100 ? percentagePosition:100);
    //     }
    // }

    useEffect(() => {

        if (props.duration > 0) {
            setTimeCounter(secondsToHumanTime(props.duration));
        }
    }, []);

    const audioEnded = () => {
        setPlayIcon(icnPlay);
        if (props.duration && timelineRef.current) {
            timelineRef.current.value = 100;
        }
    }

    const changeSeek = () => {
        if (audioRef.current && timelineRef.current) {
            const time = (timelineRef.current.value * props.duration) / 100;
            audioRef.current.currentTime = time;
        }
    }

    const onSliderDragged = (value) => {
        if (audioRef.current && value >= 0) {
            const time = (value * props.duration) / 100;
            audioRef.current.currentTime = time;
            setRangePercent(time);
        }
    }

    const toggleSound = () => {
        if (audioRef.current) {
            audioRef.current.muted = !audioRef.current.muted;
            setSoundIcon(audioRef.current.muted ? icnMute:icnSound);
        }
    }

    const toggleAudio = () => {
        if (audioRef.current) {
            if (audioRef.current.paused) {
                audioRef.current.play();
                setPlayIcon(icnPause);
            } else {
                audioRef.current.pause();
                setPlayIcon(icnPlay);
            }
        }
    }

    const handleOnlyOneAudio = () => {
        let audios = document.getElementsByTagName('audio');
        let videos = document.getElementsByTagName('video');
        if (audios && audios.length) {
            for (let i = 0; i < audios.length; i++) {
                if (audios[i].id !== `${props.id}`) {
                    audios[i].pause();
                }
            }
        }

        if (videos && videos.length) {
            for (let i = 0; i < videos.length; i++) {
                videos[i].pause();
            }
        }
    }

    const secondsToHumanTime = (secValue) => {
        if (secValue && !isNaN(secValue)) {
            const secNum = parseInt(secValue, 10); // don't forget the second param
            let hours = Math.floor(secNum / 3600);
            let minutes = Math.floor((secNum - (hours * 3600)) / 60);
            let seconds = secNum - (hours * 3600) - (minutes * 60);

            const hasHours = hours > 0 ? true:false;

            seconds = secNum < 1 ? 1:seconds;
            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            return `${hasHours ? `${hours}:`:''}${minutes}:${seconds}`;
        }
        return '00:00';
    }

    const timeLeftCounter = () => {
        if (props.duration > 0 && audioRef.current) {
            const duration = parseInt(props.duration);
            const currentTime = parseInt(audioRef.current.currentTime);
            const timeLeft = duration - currentTime;

            let s = timeLeft % 60;
            let m = Math.floor( timeLeft / 60 ) % 60;
            s = s < 10 ? "0"+s : s;
            m = m < 10 ? "0"+m : m;

            setTimeCounter(`${m}:${s}`);
        }
    }

    const mainRender = () => {
        if (props.url) {
            return (
                <div className={`AudioPlayer-container ${props.counterToRight ? 'counterToRight':''} ${props.avatar ? 'hasAvatar':''}`}>

                    <div className={`row m-0 ${props.avatar ? 'd-flex align-items-center':''}`}>
                        {
                            props.avatar ? (
                                <div className={`col-12 p-0 avatar-image-box-50 z-index-1 ${props.isMine ? 'order-first':'order-last'}`}>
                                    <img src={props.avatar} 
                                        alt="User image" 
                                        className="avatar"
                                        onError={(e)=>{
                                            e.target.onerror = null;
                                            e.target.src=icnUserPlaceholder
                                        }}
                                    />
                                </div>
                            ):(null)
                        }
                        <div className={`col-12 p-0 controlsBox ${props.avatar ? 'isAvatar':''}`}>
                            <audio src={props.url}
                                ref={audioRef}
                                onTimeUpdate={changeTimelinePosition}
                                onEnded={audioEnded}
                                onPlay={handleOnlyOneAudio}
                                id={props.id}
                                controls
                                controlsList='nodownload nofullscreen noremoteplayback noplaybackrate'
                                className={`${props.isChat ? 'isChat':''} ${props.isMine ? 'isMine':''}`}
                            />
                        </div>
                    </div>
                </div>
            )

            // return (
            //     <div className={`AudioPlayer-container ${props.counterToRight ? 'counterToRight':''} ${props.avatar ? 'hasAvatar':''}`}>
            //         <audio src={props.url} ref={audioRef} onTimeUpdate={changeTimelinePosition} onEnded={audioEnded} onPlay={handleOnlyOneAudio} id={props.id}></audio>
            //         <div className={`row m-0 ${props.avatar ? 'd-flex align-items-center':''}`}>
            //             {
            //                 props.avatar ? (
            //                     <div className={`col-12 p-0 avatar-image-box-50 ${props.isMine ? 'order-first':'order-last'}`}>
            //                         <img src={props.avatar} alt="User image" className="avatar"
            //                             onError={(e)=>{
            //                                 e.target.onerror = null;
            //                                 e.target.src=icnUserPlaceholder
            //                             }}/>
            //                     </div>
            //                 ):(null)
            //             }
            //             <div className={`col-12 p-0 controlsBox ${props.avatar ? 'isAvatar':''}`}>
            //                 <div className="controls">
            //                     <button className="player-button btn-cleared" onClick={toggleAudio}>
            //                         <img src={playIcon} alt="playIcon"/>
            //                     </button>
            //                     {
            //                         // <input type="range" className="timeline" max="100" value="0" ref={timelineRef} onChange={changeSeek}/>
            //                     }
            //                     <RangeSlider actualPercent={rangePercent} onSliderDragged={onSliderDragged}/>
            //                     <button className="sound-button btn-cleared" onClick={toggleSound}>
            //                         <img src={soundIcon} alt="soundIcon"/>
            //                     </button>
            //                 </div>
            //
            //                 <div className="counter">
            //                     {timeCounter}
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // )
        }
        return null;
    }

    return mainRender();
}

export { AudioPlayer };
