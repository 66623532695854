import React, { useState, useEffect } from "react";

import svgExplacation from "../../../_assets/media/images/svg/svg_exclamation_circle.svg";
import "./NoResults.scss";

const NoResults = (props) => {
  return (
    <div className="NoResults-container h-100 d-flex align-items-center justify-content-center">
      <div className="row m-0">
        <div className="col-12 p-0">
          <div className="row m-0">
            {
              // <div className="col-12 text-center">
              //     <img src={svgExplacation} alt="Exclamation icon" width='40' height='40'/>
              // </div>
            }
            {props.isMent ? (
              <div className="col-12 text-center main-placeholder-container">
                <p>Lo sentimos.</p>
                <span style={{color: '#ACACAC', fontSize: '18px' }}>Aún no hay preguntas para esta categoría.</span>
              </div>
            ) : (
              <div className="col-12 text-center main-placeholder-container">
                <p>Lo sentimos.</p>
                <span>No hemos encontrado resultados para esta búsqueda.</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { NoResults };
