import algoliasearch from 'algoliasearch';
// import algoliasearch from 'algoliasearch/lite';


export const algoliaService = {
    getResults,
    clearResults
};

async function getResults(searchTerm, parameters = {}, index = 'Publicaciones', completeObj = false, paginated = false) {
    try {
        const searchClient = algoliasearch(
            process.env.REACT_APP_ALGOLIA_APPID,
            process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
        );

        if (!parameters.hitsPerPage) {
            parameters['hitsPerPage'] = 100;
        }


        if (index === 'Publicaciones' || (index === 'Directorio' && paginated) || index === 'Empresas') {
            parameters['hitsPerPage'] = 20;
        }

        if (index === 'Directorio' && !paginated) {
            parameters['hitsPerPage'] = 999;
        }
        const algoliaSearcher = searchClient.initIndex(index);
        const data = await algoliaSearcher.search(searchTerm, parameters);

        if (completeObj) {
            return data;
        }

        const {hits} = data;
        return hits;
    } catch(e) {
        return e;
    }
    // {
    //     facets: ["TipoPublicacion"],
    //     facetFilters: ["TipoPublicacion:Social"],
    //     numericFilters:[]
    // }

}

async function clearResults(searchTerm, index = 'Publicaciones') {
    try {
        const searchClient = algoliasearch(
            process.env.REACT_APP_ALGOLIA_APPID,
            process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
        );
        let parameters = {hitsPerPage: 100};
        const algoliaSearcher = searchClient.initIndex(index);
        const {hits} = await algoliaSearcher.search(searchTerm, parameters);
        return hits;
    } catch(e) {
        return e;
    }
    // {
    //     facets: ["TipoPublicacion"],
    //     facetFilters: ["TipoPublicacion:Social"],
    //     numericFilters:[]
    // }

}
