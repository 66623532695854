import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import './ContentsModal.scss';

import { BottomSheetShared, ModalDefault, ContentDetailModal, ContentVideo } from '../';
import { infoService, profileService, swalAlertsService, authenticationService } from '../../../_resources/services/';
import { useWindowDimensions } from '../../../_resources/helpers';

import icnsNetworks from '../../../_assets/media/images/icons/icns_networks.png';
import icnDeleteFilter from '../../../_assets/media/images/icons/icn_delete_filter.png';
import icnClose from '../../../_assets/media/images/icons/icn_close.png';
import icnsReactions from '../../../_assets/media/images/icons/icns_reactions.png';
import icnStar from '../../../_assets/media/images/icons/icn_batch_star.svg';
import icnMore from '../../../_assets/media/images/icons/icn_more.png';
import icnContents from '../../../_assets/media/images/icons/icns_contents2.png';

const ContentsModal = (props) => {
    const [currentUser, setCurrentUser] = useState(authenticationService.currentUserValue);
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(true);
    const [inputValues, setInputValues] = useState([]);
    const [inputValuesBase, setInputValuesBase] = useState([]);
    const [catalogs, setCatalogs] = useState(null);

    const history = useHistory();

    const {width, height} = useWindowDimensions();
    const onBottomSheetDimiss = () => {
        if (props.onBottomSheetDimiss) {
            props.onBottomSheetDimiss();
        }
    }


    const getInputValue = (key) => {

        if (key && inputValues && inputValues.length) {
            let filtered = inputValues.filter(value => value.RedSocial === key);

            if (filtered.length && filtered[0].Activo) {
                return filtered[0].Perfil;
            }
        }

        return null;
    }

    const setupInputs = () => {
        setTimeout(() => {
            if (props.networks && props.networks.length) {
                props.networks.forEach((network) => {
                    if (network.RedSocial) {
                        let element = document.getElementById(network.RedSocial);

                        if (element) {
                            element.value = network.Perfil;
                        }
                    }
                });
            }
        },200);
    }

    const prepareCatalogs = () => {
        infoService.catalogs.subscribe((catalogs) =>{
            if (catalogs) {
                setCatalogs(catalogs);
            }
        });
    }

    useEffect(() => {
        let isSuscribed = true;
        setupInputs();
        prepareCatalogs();
        if (props.networks && props.networks.length) {
            if (isSuscribed) {
                setInputValues(props.networks);
                setInputValuesBase(JSON.parse(JSON.stringify(props.networks)));
            }
        }

        return () => isSuscribed = false;
    }, []);

    const viewHasChanges = () => {
        let inputValuesCP = JSON.stringify(inputValues);
        let inputValuesBaseCP = JSON.stringify(inputValuesBase);

        if (inputValuesCP !== inputValuesBaseCP) {
            return true;
        }
        return false;
    }

    const footerBtn = () => {
        return <button className={`btn-cleared ${ viewHasChanges() ? 'active':''}`} onClick={onSubmitBtnTapped}>
            Guardar
        </button>
    }

    const onSubmitBtnTapped = () => {
        if (viewHasChanges()) {
            let obj = {RedesSociales: inputValues};
            let loader = swalAlertsService.createLoading("Espere un momento...");
            loader.fire();

            profileService.updateSocialNetworks(obj).then(
                (response) => {
                    loader.close();

                    if (response) {
                        if (response.data) {
                            swalAlertsService.showToast("success", "Redes sociales actualizadas correctamente");
                            if (props.dataUpdated) {
                                props.dataUpdated();
                            }
                        } else {
                            swalAlertsService.showToast("error", "Ocurrio un error inténtalo de nuevo más tarde");
                        }
                    }
                }
            )
        }

    }

    const onInputChanged = (e) => {
        if (catalogs) {
            const {RedesSociales} = catalogs;
            if (RedesSociales && RedesSociales.length) {
                let inputValuesCP = JSON.parse(JSON.stringify(inputValues));

                if (inputValuesCP && inputValuesCP.length) {
                    let filteredItem = inputValuesCP.filter(item => item.RedSocial === e.target.name);

                    if (filteredItem.length) {
                        let index = inputValuesCP.indexOf(filteredItem[0]);

                        if (index >= 0) {
                            inputValuesCP[index].Perfil = e.target.value;
                            inputValuesCP[index].Activo = true;

                            setInputValues(inputValuesCP);
                        }
                    } else {
                        let filteredNetwork = RedesSociales.filter(red => red.RedSocial === e.target.name);

                        if (filteredNetwork.length) {
                            let obj = {
                                clv_redsocial: filteredNetwork[0].clv_redsocial,
                                RedSocial: filteredNetwork[0].RedSocial,
                                Perfil: e.target.value,
                                Activo: true
                            }

                            inputValuesCP.push(obj);
                            setInputValues(inputValuesCP);
                        }
                    }
                } else {
                    let filteredNetwork = RedesSociales.filter(red => red.RedSocial === e.target.name);

                    if (filteredNetwork.length) {
                        let obj = {
                            clv_redsocial: filteredNetwork[0].clv_redsocial,
                            RedSocial: filteredNetwork[0].RedSocial,
                            Perfil: e.target.value,
                            Activo: true
                        }
                        setInputValues([obj]);
                    }

                }
            }
        }

    }

    const onNetworkDeleted = (key) => {
        let inputValuesCP = JSON.parse(JSON.stringify(inputValues));

        if (key && inputValuesCP && inputValuesCP.length) {
            let filtered = inputValuesCP.filter(item => item.RedSocial === key);

            if (filtered.length) {
                filtered[0].Activo = false;
                filtered[0].Perfil = '';
                let index = inputValuesCP.indexOf(filtered[0]);

                let element = document.getElementById(key);

                if (element) {
                    element.value = '';
                }
                if (index >= 0) {
                    inputValuesCP[index] = filtered[0];
                    setInputValues(inputValuesCP);
                }
            }
        }
    }

    const showLikesCounter = () => {
        if (props.reactions && props.reactions.Reacciones) {
            return (
                <span className="likesCounter">
                    <img src={icnsReactions} alt="icnsReactions" width='25' height='25'/>
                    {props.reactions.Reacciones}
                </span>
            )
        }

        return null;
    }

    const goToUser = (user) => {
        if (user && user.clv_usuario) {
            if(currentUser.Usuario.clv_usuario === user.clv_usuario) {
                history.replace('/profile')
            }else {
                history.push(`/profile-user/${user.clv_usuario}` , {from: '/'} )
            }
        }
    }

    const showVid = (content) => {
        if (!content || !content.URL || content.clv_tipoadjunto != 6) {
            return;
        }

        if (props.onVideoShow) {
            props.onVideoShow(content);
        }
    }

    const renderContents = () => {
        if (props.contents && props.contents.length) {
            return props.contents.map((content, index) => {
                if (content.clv_tipoadjunto == 6) {
                    return (
                        <div className="col-12 ContentsModal-item-box mb-2 d-flex align-items-center mouse-pointer px-2" key={index} onClick={e => showVid(content)}>
                                <div className="row mx-0 d-flex align-items-center py-2 w-100">
                                    <div className={`col contentModal-icon px-0 noselect ${!content.Extension && content.clv_tipoadjunto == 12 ? 'mp3':''}${!content.Extension && content.clv_tipoadjunto == 10 ? 'links':''} ${content.Extension ? content.Extension:''}`}>
                                        <img src={icnContents} alt="content type icon"/>'
                                    </div>
                                    <div className="col contentModal-details px-0">
                                        <div className="row mx-0">
                                            <div className="col-12 contentModal-title noselect">{content.Titulo ? `${index + 1}. ${content.Titulo}`:`${index + 1} Contenido`}</div>
                                            <div className="col-12 contentModal-type noselect">{content.TipoAdjunto ? content.TipoAdjunto:''}</div>
                                            <div className="col-12 contentModal-description text-block-truncate-3ss noselect">
                                                {content.Descripcion ? content.Descripcion:''}
                                            </div>
                                        </div>
    
                                    </div>
                                    <div className="col contentModal-arrow px-0">
                                        <img src={icnMore} alt="arrow icon" />
                                    </div>
                                </div>
                            </div>
                    );
                } else {
                    return (
                        <a href={content.URL} target='_blank' key={index} className="px-0">
                            <div className="col-12 ContentsModal-item-box mb-2 d-flex align-items-center mouse-pointer px-2">
                                <div className="row mx-0 d-flex align-items-center py-2 w-100">
                                    <div className={`col contentModal-icon px-0 noselect ${!content.Extension && content.clv_tipoadjunto == 12 ? 'mp3':''}${!content.Extension && content.clv_tipoadjunto == 10 ? 'links':''}${content.Extension ? content.Extension:''}`}>
                                        <img src={icnContents} alt="content type icon"/>
                                    </div>
                                    <div className="col contentModal-details px-0">
                                        <div className="row mx-0">
                                            <div className="col-12 contentModal-title noselect">{content.Titulo ? `${index + 1}. ${content.Titulo}`:`${index + 1} Contenido`}</div>
                                            <div className="col-12 contentModal-type noselect">{content.TipoAdjunto ? content.TipoAdjunto:''}</div>
                                            <div className="col-12 contentModal-description text-block-truncate-3ss noselect">
                                                {content.Descripcion ? content.Descripcion:''}
                                            </div>
                                        </div>
    
                                    </div>
                                    <div className="col contentModal-arrow px-0">
                                        <img src={icnMore} alt="arrow icon" />
                                    </div>
                                </div>
                            </div>
                        </a>
                    );
                }
                
            });
        }

        return null;
    }

    const renderView = (isDesktop) => {
        return (
            <div className={`row m-0 ContentsModal-container ${isDesktop ? 'isDesktop':''}`}>

                {
                    isDesktop ? (
                        <div className="col-12 ContentsModal-title-container">
                            <span>{props.title || 'Contenidos'}</span>

                            <button className="btn-cleared" onClick={onBottomSheetDimiss}>
                                <img src={icnClose} alt="icnClose"/>
                            </button>
                        </div>
                    ):(null)
                }

                <div className="col-12 ContentsModal-items-container">
                    <div className="row m-0">
                        {renderContents()}
                    </div>
                </div>

                {
                    // <div className="col-12 ContentsModal-form-container">
                    //
                    //     <div className="row m-0">
                    //         <div className="col-12 px-0 pos-relative ContentsModal-form-group">
                    //             <label htmlFor="Twitter">
                    //                 <img src={icnsNetworks} alt="network sprite" className='Twitter'/>
                    //             </label>
                    //             <input type="text"
                    //                 id='Twitter'
                    //                 name='Twitter'
                    //                 placeholder='@usuario'
                    //                 onChange={onInputChanged}/>
                    //             {
                    //                 getInputValue('Twitter') &&
                    //                 <button className='btn-cleared' onClick={e => onNetworkDeleted('Twitter')}>
                    //                     <img src={icnDeleteFilter} alt="delete"/>
                    //                 </button>
                    //             }
                    //         </div>
                    //     </div>
                    //
                    //     <div className="row m-0">
                    //         <div className="col-12 px-0 pos-relative ContentsModal-form-group">
                    //             <label htmlFor="Facebook">
                    //                 <img src={icnsNetworks} alt="network sprite" className='Facebook'/>
                    //             </label>
                    //             <input type="text"
                    //                 id='Facebook'
                    //                 name='Facebook'
                    //                 placeholder='/usuario'
                    //                 onChange={onInputChanged}/>
                    //             {
                    //                 getInputValue('Facebook') &&
                    //                 <button className='btn-cleared' onClick={e => onNetworkDeleted('Facebook')}>
                    //                     <img src={icnDeleteFilter} alt="delete"/>
                    //                 </button>
                    //             }
                    //         </div>
                    //     </div>
                    //
                    //     <div className="row m-0">
                    //         <div className="col-12 px-0 pos-relative ContentsModal-form-group">
                    //             <label htmlFor="LinkedIn">
                    //                 <img src={icnsNetworks} alt="network sprite" className='LinkedIn'/>
                    //             </label>
                    //             <input type="text"
                    //                 id='LinkedIn'
                    //                 name='LinkedIn'
                    //                 placeholder='/usuario'
                    //                 onChange={onInputChanged}/>
                    //             {
                    //                 getInputValue('LinkedIn') &&
                    //                 <button className='btn-cleared' onClick={e => onNetworkDeleted('LinkedIn')}>
                    //                     <img src={icnDeleteFilter} alt="delete"/>
                    //                 </button>
                    //             }
                    //         </div>
                    //     </div>
                    //
                    //     <div className="row m-0">
                    //         <div className="col-12 px-0 pos-relative ContentsModal-form-group">
                    //             <label htmlFor="Instagram">
                    //                 <img src={icnsNetworks} alt="network sprite" className='Instagram'/>
                    //             </label>
                    //             <input type="text"
                    //                 id='Instagram'
                    //                 name='Instagram'
                    //                 placeholder='@usuario'
                    //                 onChange={onInputChanged}/>
                    //             {
                    //                 getInputValue('Instagram') &&
                    //                 <button className='btn-cleared' onClick={e => onNetworkDeleted('Instagram')}>
                    //                     <img src={icnDeleteFilter} alt="delete"/>
                    //                 </button>
                    //             }
                    //         </div>
                    //     </div>
                    //
                    //     <div className="row m-0">
                    //         <div className="col-12 px-0 pos-relative ContentsModal-form-group">
                    //             <label htmlFor="YouTube">
                    //                 <img src={icnsNetworks} alt="network sprite" className='YouTube'/>
                    //             </label>
                    //             <input type="text"
                    //                 id='YouTube'
                    //                 name='YouTube'
                    //                 placeholder='Nombre del canal'
                    //                 onChange={onInputChanged}/>
                    //             {
                    //                 getInputValue('YouTube') &&
                    //                 <button className='btn-cleared' onClick={e => onNetworkDeleted('YouTube')}>
                    //                     <img src={icnDeleteFilter} alt="delete"/>
                    //                 </button>
                    //             }
                    //         </div>
                    //     </div>
                    //
                    //     <div className="row m-0">
                    //         <div className="col-12 px-0 pos-relative ContentsModal-form-group">
                    //             <label htmlFor="Spotify">
                    //                 <img src={icnsNetworks} alt="network sprite" className='Spotify'/>
                    //             </label>
                    //             <input type="text"
                    //                 id='Spotify'
                    //                 name='Spotify'
                    //                 placeholder='Nombre del canal'
                    //                 onChange={onInputChanged}/>
                    //             {
                    //                 getInputValue('Spotify') &&
                    //                 <button className='btn-cleared' onClick={e => onNetworkDeleted('Spotify')}>
                    //                     <img src={icnDeleteFilter} alt="delete"/>
                    //                 </button>
                    //             }
                    //         </div>
                    //     </div>
                    // </div>
                    //
                    // {
                    //     isDesktop ? (
                    //         <div className="col-12 p-0 ContentsModal-footer-container">
                    //             {footerBtn()}
                    //         </div>
                    //     ):(null)
                    // }
                }
            </div>
        );
    }

    const mobileRender = () => {
        return (
            <BottomSheetShared showHeader={true} showCancelBtn={true}
                blocking={true}
                isOpen={isBottomSheetOpen}
                onDismiss={onBottomSheetDimiss}
                title={props.title || "Contenidos"}
                footerBtn={false}
                shouldClose={true}
                isCloseSymbol={true}
            >
                {renderView(false)}
            </BottomSheetShared>
        )
    }

    const desktopRender = () => {
        return (
            <ModalDefault show={true} overAll={true} onHide={onBottomSheetDimiss}>
                {renderView(true)}
            </ModalDefault>
        )
    }

    return (
        <React.Fragment>
            {
                width && width >= 1450 ? (desktopRender()):(mobileRender())
            }
        </React.Fragment>

    )
}

export { ContentsModal };
