import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { swalAlertsService, messagesService, authenticationService, algoliaService } from '../../../_resources/services';
import { toolsHelper, useDidMountEffect } from '../../../_resources/helpers';

import { Filters, ContactList } from '../';

import icnsMessages from '../../../_assets/media/images/icons/icns_messages.png';
import icnCancel from '../../../_assets/media/images/icons/icn_cancel.png';
import imgUserPlaceholder from '../../../_assets/media/images/icons/icn_user_placeholder.png';
import imgPlaceholderGroup from '../../../_assets/media/images/icons/icn_placeholder_group.png';

import './MessagesBubble.scss';

const MessagesBubble = (props) => {
    const history = useHistory();
    const [collapsed, setCollapsed] = useState(false);
    const [isNewChatOpen, setIsNewChatOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const [currentUser, setCurrentUser] = useState(null);

    const [chats, setChats] = useState([]);

    const [activeFilters, setActiveFilters] = useState({
        filtersSelected: null,
        postedBy: null,
        isUsingFilters: false
    });


    const getStoredMessages = () => {
        let storedMessages = localStorage.getItem('storedMessages');

        if (storedMessages) {
            try {
                storedMessages = JSON.parse(storedMessages);

                if (storedMessages.length) {
                    let chatsCP = JSON.parse(JSON.stringify(chats));
                    chatsCP = storedMessages;
                    setChats(chatsCP);
                }
            } catch (e) {
            }
        }
    }

    async function loadData() {
        return await messagesService.getMessages('');
    }

    async function loadDataAlgolia() {
        let parameters = {
            hitsPerPage: 500,
            numericFilters: [`clv_usuario=${currentUser.Usuario.clv_usuario}`]
        }

        return await algoliaService.getResults(searchTerm, parameters, 'Conversaciones', false);
    }

    const handleReceibedChat = () => {
        let chatsCP = JSON.parse(JSON.stringify(chats));
        return chatsCP;
    }

    const handleResponse = (response, chatsParam) => {
        if (response && response.data && response.data.Activos ) {
            let chatsCP = chatsParam ? chatsParam:JSON.parse(JSON.stringify(chats));
            chatsCP = response.data.Activos;
            chatsCP.Broadcast = response.Broadcast;
            storeMessages(chatsCP);
            setChats(chatsCP);
            console.log(chats.Broadcast);
        }
    }

    const handleAlgoliaResponse = (response, chatsParam) => {
        if (response) {
            let chatsCP = chatsParam ? chatsParam:JSON.parse(JSON.stringify(chats));
            chatsCP = response;
            storeMessages(chatsCP);
            setChats(chatsCP);
        }
    }

    useEffect(() => {
        let isSuscribed = true;
        getStoredMessages();

        authenticationService.currentUser.subscribe((user) => {
            if (user && user.SUID && user.Usuario && isSuscribed) {
                setCurrentUser(user);
            }
        });

        const messages = loadData();

        messages.then((response) => {
            if (isSuscribed) {
                handleResponse(response, handleReceibedChat());
            }
        });
        return () => isSuscribed =  false

    }, []);

    useDidMountEffect(() => {
        let isSuscribed = true;

        if (searchTerm) {
            const messages = loadDataAlgolia();
            messages.then((response) => {
                if (isSuscribed) {
                    handleAlgoliaResponse(response, handleReceibedChat());
                }
            });
        } else if (searchTerm === '') {
            const messages = loadData();

            messages.then((response) => {
                if (isSuscribed) {
                    handleResponse(response, handleReceibedChat());
                }
            });
        }
        return () => isSuscribed =  false

    }, [searchTerm]);

    useDidMountEffect(() => {
        let isSuscribed = true;

        if (searchTerm) {
            const messages = loadDataAlgolia();
            messages.then((response) => {
                if (isSuscribed) {
                    handleAlgoliaResponse(response, handleReceibedChat());
                }
            });
        } else if (searchTerm === '') {
            const messages = loadData();

            messages.then((response) => {
                if (isSuscribed) {
                    handleResponse(response, handleReceibedChat());
                }
            });
        }
        return () => isSuscribed =  false

    }, [collapsed]);

    const storeMessages = (items) => {
        if (items && items.length) {
            localStorage.setItem('storedMessages', JSON.stringify(items));
        }
    }

    const getChatTitle = (chat) => {
        if (chat) {
            if (chat.Mensaje) {
                return chat.Mensaje;
            } else {
                if (chat.clv_tipoconversacion === 1) {
                    return 'Conversación creada';
                } else {
                    return 'Grupo creado';
                }
            }
        }
        return null;
    }

    const onChatSelected = (chat) => {
        console.log(chat);
        if (chat && chat.clv_tipoconversacion) {
            if (chat.clv_tipoconversacion === 1) {
                let item = toolsHelper.chatObjBuilder(chat);
                if (chat) {
                    history.push(`/messages`, {chat});
                }
            } else if (chat.clv_tipoconversacion === 2) {
                history.push(`/messages`, {groupDetails: chat});
            }
        }
    }

    const onUserSelected = (item) => {
        let chat = toolsHelper.chatObjBuilder(item);
        if (chat) {
            setIsNewChatOpen(false);
            history.push(`/messages`, {chat});
        }
    }

    const onBottomSheetDimiss = () => {
        setIsNewChatOpen(false);
    }

    const onMassiveSendBtnTapped = (massiveChat) => {
        if (massiveChat && massiveChat.length) {
            setIsNewChatOpen(false);
            history.push(`/messages`, {massiveChat});
        }
    }

    const onGroupSendBtnTapped = (group) => {
        if (group && group.clv_conversacion) {
            setIsNewChatOpen(false);
            history.push(`/messages`, {groupDetails: group});
        }
    }

    const renderChats = () => {
        if (chats && chats.length) {
            return chats.map((chat, index) => {
                const placeholder = chat.clv_tipoconversacion === 1 ? imgUserPlaceholder:imgPlaceholderGroup
                return (
                    <div className="col-12 p-0 MessagesBubble-user-container" key={index}>
                        <div className="row m-0 mouse-pointer" onClick={e => onChatSelected(chat)}>
                            <div className="col avatar-image-box-60">
                                <img src={chat.ImageUrl ? chat.ImageUrl:placeholder} alt="placeholder"
                                    onError={(e)=>{
                                        e.target.onerror = null;
                                        e.target.src = placeholder
                                    }}/>
                            </div>
                            <div className="col pe-0 MessagesBubble-user-col">
                                <div className="row mx-0 MessagesBubble-user-row">
                                    <div className="col-12 px-0">
                                        <div className="row m-0">
                                            <div className="col p-0 nameCol text-truncate noselect">{chat.Titulo}</div>
                                            <div className="col p-0 timeSinceCol noselect">{toolsHelper.timeSince(chat.Fecha)}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 px-0 text-truncate subtitleCol">{`${chat.Cargo ? chat.Cargo:''}${chat.Cargo && chat.EmpresaNombreCorto ? ' | ':''}${chat.EmpresaNombreCorto ? chat.EmpresaNombreCorto:''}`}</div>
                                    <div className="col-12 px-0 text-block-truncate-2 contentCol">{getChatTitle(chat)}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                );
            });
        }
        return null;
    }
    return (
        <div className={`MessagesBubble-container row m-0 ${collapsed ? 'collapsed':'expanded'} ${props.isHome ? 'isHome':''}`}>
            <div className="col-12 MessagesBubble-header-container">
                <div className="row m-0">
                    <div className="col ps-0 MessagesBubble-header-title noselect mouse-pointer" onClick={e => setCollapsed(!collapsed)}>Mensajes</div>
                    <div className="col px-0 MessagesBubble-header-btn first">
                        <button className="btn-cleared" onClick={e => setIsNewChatOpen(true)}>
                            <img src={icnsMessages} alt="icnsMessages"/>
                        </button>
                    </div>
                    <div className="col pe-0 MessagesBubble-header-btn second">
                        <button className="btn-cleared" onClick={e => setCollapsed(!collapsed)}>
                            <img src={icnsMessages} alt="icnsMessages" className={collapsed ? 'collapsed':'expanded'}/>
                        </button>
                    </div>
                </div>
            </div>

            <div className={`col-12 MessagesBubble-main-container ${collapsed ? 'collapsed':'expanded'}`}>

                <div className="row m-0 MessagesBubble-searcher-container">
                    <div className="col-12 p-0 pos-relative">
                        <label htmlFor="MessagesBubble-searcher-input">
                            <img src={icnsMessages} alt="icnsMessages"/>
                        </label>
                        <input type="text" id='MessagesBubble-searcher-input' name='MessagesBubble-searcher-input' value={searchTerm} onChange={e => setSearchTerm(e.target.value)}/>
                        {
                            searchTerm &&
                            <button className="btn-cleared" onClick={e => setSearchTerm('')}>
                                <img src={icnCancel} alt="icnCancel"/>
                            </button>
                        }
                    </div>
                </div>

                <div className="row m-0 MessagesBubble-users-list">
                    {renderChats()}
                </div>
            </div>

            {
                isNewChatOpen &&
                <ContactList onUserSelected={onUserSelected}
                    currentUser={currentUser}
                    onBottomSheetDimiss={onBottomSheetDimiss}
                    onMassiveSendBtnTapped={onMassiveSendBtnTapped}
                    activeFilters={activeFilters}
                    onGroupSendBtnTapped={onGroupSendBtnTapped}
                    isDesktop={true}
                    currentMessagesCount = {chats.Broadcast}
                />
            }
        </div>
    );
}

export { MessagesBubble };
