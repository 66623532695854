import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { toolsHelper } from '../../../../../_resources/helpers';

import './UserProfilePersonalInfo.scss';

import icnEditGreen from '../../../../../_assets/media/images/icons/icn_edit_green.png';
import icnStar from '../../../../../_assets/media/images/icons/icn_batch_star.svg';
import placeholderuser from '../../../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnsSheets from '../../../../../_assets/media/images/icons/icns_sheets.png';
import icnsNetworks from '../../../../../_assets/media/images/icons/icns_networks.png';
import icnBirthday from '../../../../../_assets/media/images/icons/icn_birthday.png';
import icnDirectMsg from '../../../../../_assets/media/images/icons/icn_direct_msg.png';
import icnMore from '../../../../../_assets/media/images/icons/icn_more_options.png';
import birth from '../../../../../_assets/media/images/icons/icn_birthday.png';
import icnCalendar from '../../../../../_assets/media/images/icons/icn_calendar.png';
import icnMentoring from '../../../../../_assets/media/images/icons/icn_mentoring.png';


const UserProfilePersonalInfo = (props) => {
    const history = useHistory();

    const getFixedUrl = (item) => {
        if (item && item.RedSocial && item.Perfil) {

            if (!toolsHelper.validateURL(item.Perfil)) {
                switch (item.RedSocial) {
                    case 'Facebook':
                        return `https://facebook.com/${item.Perfil}`;
                        break;
                    case 'Twitter':
                        return `https://twitter.com/${item.Perfil}`;
                        break;
                    case 'LinkedIn':
                        return `https://linkedin.com/in/${item.Perfil}`;
                        break;
                    case 'YouTube':
                        return `https://www.youtube.com/results?search_query=${item.Perfil}`;
                        break;
                    case 'Instagram':
                        return `https://instagram.com/${item.Perfil}`;
                        break;
                    case 'Spotify':
                        return `https://spotify.com/${item.Perfil}`;
                        break;
                }
            }
            return item.Perfil;
        }
        return null;
    }

    const onChatTapped = () => {
        if (props.onChatTapped) {
            props.onChatTapped();
        }
    }

    const getUserDate = (dateString) => {
        if (dateString) {
            const month = toolsHelper.getMonthName(dateString);

            const date = new Date(dateString);

            return `${month}. ${date.getFullYear()}`;
        }
        return null;
    }

    const getPhoneDetails = () => {
        if (props.user && props.user.Celular) {
            if (props.user.CelularPrivado) {
                return <span className='font-color-green'>** *** ** **</span>
            }

            return <a href={`tel://${props.user.Celular}`}><span className='font-color-green text-truncate detailsDv'>{props.user.Celular}</span></a>;
        }

        return <span className='placeholderTxt mouse-default'>Sin definir celular</span>
    }

    const getEmailDetails = () => {

        if (props.user && props.user.Email) {
            if (props.user.EmailPrivado) {
                return <span className='font-color-green'>*****@*****.***</span>
            }

            return <a href={`mailto:${props.user.Email}`} className='anchorDetails'><div className='font-color-green text-truncate detailsDv' title={props.user.Email}>{props.user.Email}</div></a>;
        }

        return <span className='placeholderTxt mouse-default'>Sin definir email</span>
    }

    const getMentoringData = () => {
        // icnCalendar


        if (props.user && props.user.OfreceMentoria && (props.user.Mentoria || props.user.MentoriaPersonalizado)) {
            if (props.user.clv_tipomentoria === 4 && props.user.MentoriaPersonalizado) {
                return (
                    <div className="col-12 p-0 detailsCol d-flex">
                        <img src={icnMentoring} className='outIcon p-1' />
                        {
                            props.user.Mentoria ? (<span className="text-truncate mentoringSpan" title={`Otorga mentoría - ${props.user.MentoriaPersonalizado || ''}`}>{`Otorga mentoría - ${props.user.MentoriaPersonalizado || ''}`}</span>) : (<span className="text-truncate mentoringSpan" title={`Otorga mentoría - ${props.user.Mentoria || ''}`}>{`Otorga mentoría - ${props.user.Mentoria || ''}`}</span>)
                        }
                    </div>
                )
            }
            return (
                <div className="col-12 p-0 detailsCol d-flex">
                    <img src={icnMentoring} className='outIcon p-1' />
                    {
                        props.user.Mentoria ? (<span className="text-truncate mentoringSpan" title={`Otorga mentoría - ${props.user.Mentoria || ''}`}>{`Otorga mentoría - ${props.user.Mentoria || ''}`}</span>) : (<span className="text-truncate mentoringSpan" title={`Otorga mentoría - ${props.user.MentoriaPersonalizado || ''}`}>{`Otorga mentoría - ${props.user.MentoriaPersonalizado || ''}`}</span>)
                    }
                </div>
            )
        }
        return null;
    }

    const getDateBirth = () => {
        if (props.user.FechaNacimientoPrivado) {
            return '***********';
        }
        if (props.user.FechaNacimiento) {
            const month = toolsHelper.getFullMonthName(props.user.FechaNacimiento);
            let x = new Date(props.user.FechaNacimiento);
            let formatted_date = x.getDate() + " " + month + " " + x.getFullYear();
            return formatted_date
        }
        return 'Sin definir';
    }

    const onInvitationLabelTapped = () => {
        if (props.user && props.user.clv_referidopor) {
            if (props.currentUser && props.currentUser.Usuario && props.currentUser.Usuario.clv_usuario === props.user.clv_referidopor) {
                history.push('/profile', { from: `profile-user/${props.user.clv_usuario}` })
            } else if (props.user.clv_usuario !== props.user.clv_referidopor) {
                history.push('/profile-user/' + props.user.clv_referidopor, { from: `profile-user/${props.user.clv_usuario}` });
            }
        }
    }

    return (
        <div className="UserProfilePersonalInfo-container row h-100">
            <div className="col-12 p-0">
                <div className="row m-0 UserProfilePersonalInfo-detail-container">
                    <div className="col p-0 imgCol">
                        <img src={props.user && props.user.PhotoUrl ? props.user.PhotoUrl : placeholderuser} 
                            alt="placeholderuser"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = placeholderuser
                            }}
                        />

                        <button className="btn-cleared" onClick={onChatTapped}>
                            <img src={icnDirectMsg} alt="icnDirectMsg" />
                            <span>Enviar un mensaje</span>
                        </button>
                    </div>
                    <div className="col ps-4 colDtls">
                        <div className="row m-0">
                            <div className="col-12 px-0">
                                <div className="row m-0 d-flex justify-content-between pos-relative">
                                    <div className="col nameCol px-0">
                                        {
                                            props.user && props.user.clv_estatusbm === 2 ? (
                                                <img src={icnStar} alt="icnStar" />
                                            ) : (null)
                                        }
                                        <div className={`text-truncate titleDiv ${props.user && props.user.clv_estatusbm === 2 ? 'ps-2' : ''}`}>{props.user && props.user.NombreCompleto ? props.user.NombreCompleto : ''}</div>
                                    </div>
                                    <button className="btn-cleared btnMor" onClick={e => {
                                        if (props.onSettingsTapped) {
                                            props.onSettingsTapped();
                                        }
                                    }}>
                                        <img src={icnMore} alt="icnMore" width='40' height='40' />
                                    </button>
                                </div>
                                <div className="data_account">
                                    <div className="data_account_option">
                                        {props.user.clv_estatusreferido && props.user.clv_estatusreferido != 3 ?
                                            <p className='invitation-label'>Invitado por:
                                                <span className='noselect' onClick={props.user.clv_estatusreferido == 2 ? onInvitationLabelTapped : () => { }}>
                                                    {
                                                        props.user.clv_estatusreferido == 1 ?
                                                            (<strong className='ps-1 text-start noselect text-block-truncate-1'>Pendiente</strong>) :
                                                            props.user.clv_estatusreferido == 2 && props.user.InvitadoPor && props.user.PhotoUrlInvitadoPor ?
                                                                (
                                                                    <>
                                                                        <img src={props.user.PhotoUrlInvitadoPor} 
                                                                            alt="invitation" 
                                                                            className='noselect invitation-miniature'
                                                                            onError={(e) => {
                                                                                e.target.onerror = null;
                                                                                e.target.src = placeholderuser
                                                                            }}
                                                                        />
                                                                        <strong className='ps-1 text-start noselect text-block-truncate-1'>{props.user.InvitadoPor}</strong>
                                                                    </>
                                                                ) :
                                                                props.user.clv_estatusreferido == 2 && props.user.InvitadoPor && !props.user.PhotoUrlInvitadoPor ?
                                                                    (<strong className='ps-1 text-start noselect text-block-truncate-1'>{props.user.InvitadoPor}</strong>)
                                                                    :
                                                                    (null)
                                                    }
                                                </span>
                                            </p>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 px-0">
                                <div className="row m-0">
                                    <div className="col px-0">
                                        <div className="row m-0">
                                            <div className="col-12 p-0 detailsCol">
                                                <img src={icnsSheets} alt="icnsSheets" width='30' height='30' className='roleIcon' />
                                                {
                                                    props.user && (props.user.Cargo || props.user.EmpresaNombreCorto) ? (
                                                        <span className='mouse-default'>{`${props.user && props.user.Cargo ? props.user.Cargo : ''}${props.user && props.user.Cargo && props.user.EmpresaNombreCorto ? ' | ' : ''}${props.user && props.user.EmpresaNombreCorto ? props.user.EmpresaNombreCorto : ''}`}</span>
                                                    ) : (
                                                        <span className='placeholderTxt mouse-default'>Sin definir cargo/empresa</span>
                                                    )
                                                }

                                            </div>

                                            <div className="col-12 p-0 detailsCol">
                                                <img src={icnsSheets} alt="icnsSheets" width='30' height='30' className='phoneIcon' />
                                                {getPhoneDetails()}
                                            </div>

                                            <div className="col-12 p-0 detailsCol isDiv">
                                                <img src={icnsSheets} alt="icnsSheets" width='30' height='30' className='emailIcon' />
                                                {getEmailDetails()}

                                            </div>

                                            <div className="col-12 p-0 detailsCol">
                                                <img src={icnsSheets} alt="icnsSheets" width='30' height='30' className='pinIcon' />
                                                {
                                                    props.user && (props.user.EstadoResidencia || props.user.PaisResidencia) ? (
                                                        <span className='mouse-default'>{`${props.user && props.user.EstadoResidencia ? props.user.EstadoResidencia : ''}${props.user && props.user.EstadoResidencia && props.user.PaisResidencia ? ', ' : ''}${props.user && props.user.PaisResidencia ? props.user.PaisResidencia : ''}`}</span>
                                                    ) : (
                                                        <span className='placeholderTxt mouse-default'>Sin definir ubicación</span>
                                                    )
                                                }



                                            </div>

                                            <div className="col-12 p-0 detailsCol">
                                                <img src={icnsSheets} alt="icnsSheets" width='30' height='30' className='hobbyIcon' />
                                                {
                                                    props.user && props.user.Aficion ? (
                                                        <span className='mouse-default'>{props.user.Aficion}</span>) : (
                                                        <span className='placeholderTxt mouse-default'>Sin definir aficiones</span>
                                                    )
                                                }
                                            </div>

                                            {getMentoringData()}

                                            <div className="col-12 p-0 detailsCol isDiv">
                                                <img src={birth} alt="icnsSheets" width='30' height='30' className='outIcon p-1' />
                                                <span className="mouse-default">{getDateBirth()}</span>
                                            </div>

                                            {
                                                props.user && props.user.FechaAlta ? (<div className="col-12 p-0 detailsCol">
                                                    <img src={icnCalendar} alt="icnsSheets" width='30' height='30' className='outIcon p-1' />
                                                    <span>Miembro WeNet desde {getUserDate(props.user.FechaAlta)}</span>
                                                </div>) : (null)
                                            }
                                        </div>
                                    </div>
                                    <div className="col redesCol ps-5">
                                        <p>Sígueme en:</p>
                                        <div>
                                            {
                                                props.user &&
                                                    props.user.RedesSociales &&
                                                    props.user.RedesSociales.length ?
                                                    props.user.RedesSociales.map((item, index) => {

                                                        if (item.RedSocial !== 'Spotify') {
                                                            return (
                                                                <a href={getFixedUrl(item)} target="_blank" rel="noreferrer" key={index}>
                                                                    <img src={icnsNetworks} alt="" className={item.RedSocial} />
                                                                </a>
                                                            )
                                                        } else {
                                                            return (
                                                                <a href={''} key={index}>
                                                                    <img src={icnsNetworks} alt="" className={item.RedSocial} />
                                                                </a>
                                                            )
                                                        }
                                                    }) : (
                                                        <div className='font-color-gray font-size-18px'>
                                                            Sin redes sociales
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}

export { UserProfilePersonalInfo }
