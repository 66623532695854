import React, { useState, useEffect } from 'react';

import { authenticationService, infoService } from '../../../../_resources/services';
import './HomeHeader.scss';

const HomeHeader = (props) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [postTypes, setPostTypes] = useState([]);
    const [postTypeSelected, setPostTypeSelected] = useState(null);

    const onPostTypeSelected = (item) => {
        if (item) {

            if (item.clv_tipopublicacion === 0) {
                setPostTypeSelected(null);
            } else {
                setPostTypeSelected(item);
            }
        }
    }

    useEffect(() => {
        if (props.onPostTypeSelected) {
            props.onPostTypeSelected(postTypeSelected);
        }
    }, [postTypeSelected]);

    useEffect(() => {
        let isSuscribed = true;
        authenticationService.currentUser.subscribe((user) => {
            if (user && user.Usuario && isSuscribed) {
                setCurrentUser(user.Usuario);
            }
        })

        infoService.catalogs.subscribe((catalogs) =>{
            if (catalogs && catalogs.TiposPublicacion && catalogs.TiposPublicacion.length) {
                
                if (isSuscribed) {
                    let networking = { clv_tipopublicacion: 0, TipoPublicacion: "Networking" }
                    let mentoring = { clv_tipopublicacion: 4, TipoPublicacion: "Mentoría" }
                    let content = { clv_tipopublicacion: 5, TipoPublicacion: "Contenidos" }
                    setPostTypes([networking, ...[mentoring, content]]);
                }

            }
        });

        return () => isSuscribed = false
    }, []);

    const postTypesList = () => {

        if (postTypes.length) {
            const items = postTypes.map((item, index, arr) => {
                const selected = !postTypeSelected && item.clv_tipopublicacion === 0  || postTypeSelected && postTypeSelected.clv_tipopublicacion === item.clv_tipopublicacion? 'selected':'';
                return (<div className={`HomeHeader-postType-item mouse-pointer noselect ${selected} ${index === 0 ? 'border-line' : index + 1 === arr.length ?'last-border' : ''}`} key={index} onClick={e => onPostTypeSelected(item)}>{item.TipoPublicacion}</div>);
            });
            return items;
        }
        return null;
    }

    return (
        <div className="HomeHeader-container row m-0">

            {!props.hideName ? (
                <div className="col-12 HomeHeader-name-box">
                    Hola, {currentUser && currentUser.Nombre ? currentUser.Nombre:''}
                </div>
            ):(null)}

            <div className="col-12 px-0">
                <div className="d-flex m-0 flex-wrap HomeHeader-postTypes-container">
                    {postTypesList()}
                </div>
            </div>
        </div>
    );
}

export { HomeHeader }
