import React, { useState, useEffect } from 'react';

import iconMore from '../../../../../../../_assets/media/images/icons/icn_more.png';
import iconDeleteFilter from '../../../../../../../_assets/media/images/icons/icn_delete_filter.png';

import './FilterTagCell.scss';

const FilterTagCell = (props) => {
    const [cellTitle, setCellTitle] = useState('Celda');

    useEffect(() => {
        setCellTitle(props.title || 'Celda');
    }, []);

    const onFilterPickerBtnTapped = () => {
        if (props.onFilterPickerBtnTapped && props.keyValue) {
            props.onFilterPickerBtnTapped(props.keyValue)
        }
    }

    const onTagDelete = (tag) => {
        if (props.onTagDelete) {
            props.onTagDelete(tag);
        }
    }

    const tagsList = () => {

        if (props.items && props.items.length) {
            const tags = props.items.map((tag, index) => {
                return (
                    <div className="FilterTagCell-tag-item" key={`${index}-${tag.name}`} onClick={e => onTagDelete({tag, key: props.keyValue})}>
                        <div className='noselect'>
                            {props.keyValue === "Aficiones" ? tag.Aficion : tag.name}
                            <img src={iconDeleteFilter} alt="delete filter icon"/>
                        </div>
                    </div>
                );
            });

            return (
                <div className="row d-flex m-0 FilterTagCell-tags-box">
                    {tags}
                </div>
            );
        }
        return null;
    }

    return (
        <div className="FilterTagCell-container col-12 ">
            <div className="row m-0 FilterTagCell-title-box" onClick={onFilterPickerBtnTapped}>
                <div className="col p-0 title noselect">
                    {cellTitle}
                </div>
                <div className="col p-0 selector noselect">
                    <img src={iconMore} alt="more icon" width='40' height='40'/>
                </div>
            </div>

            {tagsList()}
        </div>
    );
}
export { FilterTagCell }
