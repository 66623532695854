import React, { useState, useEffect, useRef } from 'react';

// import { LazyVideo } from 'react-lazy-media';
import { LazyImage } from "react-lazy-images";

import './VideoPlayer.scss';

import icnPlay from '../../../_assets/media/images/icons/icn_play_video.png';

const VideoPlayer = (props) => {
    let videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const onPlayBtnTapped = () => {
        if (videoRef.current) {
            videoRef.current.play();
            handleOnlyOneAudio();
        }
        setIsPlaying(!isPlaying);
    }

    const handleOnlyOneAudio = () => {
        let audios = document.getElementsByTagName('audio');
        let videos = document.getElementsByTagName('video');
        if (videos && videos.length) {
            for (let i = 0; i < videos.length; i++) {
                console.log(videos[i].id, props.id);
                if (videos[i].id !== `${props.id}`) {
                    videos[i].pause();
                }
            }
        }

        if (audios && audios.length) {
            for (let i = 0; i < audios.length; i++) {
                audios[i].pause();
            }
        }
    }

    const mainRender = () => {
        if (props.url) {
            return (
                <div className="VideoPlayer-container">
                    {
                        !isPlaying ? (
                            <button className="btn-cleared" onClick={onPlayBtnTapped}>
                                <img src={icnPlay} alt="icnPlay" width='70' height='70' className='imgIcon'/>
                            </button>
                        ):(null)
                    }
                    {
                        !isPlaying ? (
                            <LazyImage src={props.thumbnail}
                                alt="Post img"
                                placeholder={ ({imageProps, ref}) =>
                                    <img ref={ref} src={props.thumbnail} alt={imageProps.alt} className='VideoPlayer-poster-img'/>
                                }
                                actual={({imageProps}) =>
                                    <img {...imageProps} className='VideoPlayer-poster-img'/>
                                }
                            />
                        ):(null)
                    }
                    {
                        isPlaying ? (
                            <video ref={videoRef}
                                id={props.id}
                                src={props.url}
                                controls
                                className={isPlaying ? 'isPlaying':''}
                                disablePictureInPicture controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
                                poster={props.thumbnail ? props.thumbnail:null}
                                autoPlay
                                onPlay={() => {handleOnlyOneAudio()}}
                            >
                                <source src={props.url} type='video/mp4'/>
                            </video>
                        ):(null)
                    }
                </div>
            )
        }
        return null;
    }
    return mainRender();
}

export { VideoPlayer };
