import React, { useState, useEffect } from 'react';

import './FiltersSheet.scss';

import { infoService } from '../../../../_resources/services';
import { useDidMountEffect } from '../../../../_resources/helpers';

import icnsMessages from '../../../../_assets/media/images/icons/icns_messages.png';
import icnCheckbox from '../../../../_assets/media/images/icons/icn_checkbox.png';
import icnCheckboxSelected from '../../../../_assets/media/images/icons/icn_checkbox_selected.png';

const FiltersSheet = (props) => {
    const [catalogsStructure, setCatalogsStructure] = useState([]);
    const [activeFilters, setActiveFilters] = useState({
        filtersSelected: null,
        postedBy: null,
        isUsingFilters: false
    });

    const [catalogs, setCatalogs] = useState(null);

    const prepareCatalogs = () => {
        infoService.catalogs.subscribe((catalogs) =>{
            if (catalogs) {
                console.log(catalogs);
                setCatalogs(catalogs);
                defileCatalogsStructure(catalogs);
            }
        });
    }

    const onClearFilters = () => {
        setActiveFilters({
            filtersSelected: null,
            postedBy: null,
            isUsingFilters: false
        });
    }

    const defileCatalogsStructure = (data) => {
        if (data) {
            let items = [];

            const {Cargos} = data;
            const {Giros} = data;
            const {Clasificaciones} = data;
            const {Paises} = data;
            const {Aficiones} = data;

            if (Cargos && Cargos.length) {
                items.push({filterName: 'Puesto', items: Cargos, toggled: false, baseKey: 'Cargos'});
            }

            if (Giros && Giros.length) {
                items.push({filterName: 'Giro', items: Giros, toggled: false, baseKey: 'Giros'});
            }

            if (Clasificaciones && Clasificaciones.length) {
                items.push({filterName: 'Clasificación', items: Clasificaciones, toggled: false, baseKey: 'Clasificaciones'});
            }

            if (Paises && Paises.length) {
                items.push({filterName: 'Ubicación de la empresa', items: Paises, toggled: false, baseKey: 'Paises'});
            }

            if (Aficiones && Aficiones.length) {
                items.push({filterName: 'Afición Deportiva', items: Aficiones, toggled: false, baseKey: 'Aficiones'});
            }

            const bmItem = {
                EstatusBM: "Soy miembro activo",
                clv_estatusbm: 2,
                id: 2,
                name: "BoardMedia"
            }
            items.push({filterName: 'Asociación', items: [bmItem], toggled: false, baseKey: 'Asociacion'});

            setCatalogsStructure(items);
        }
    }

    const onFilterToggled = (index) => {
        if (catalogsStructure && catalogsStructure.length && catalogsStructure[index]) {
            let catalogsStructureCP = JSON.parse(JSON.stringify(catalogsStructure));
            let value = !catalogsStructureCP[index].toggled;
            catalogsStructureCP = catalogsStructureCP.map((el) => {
                el.toggled = false;
                return el;
            });
            catalogsStructureCP[index].toggled = value;
            setCatalogsStructure(catalogsStructureCP);
        }
    }

    const setOptionSelected = (baseKey, item) => {
        if (baseKey && item && item.id) {
            let activeFiltersCP = JSON.parse(JSON.stringify(activeFilters));
            if (activeFiltersCP && activeFiltersCP.filtersSelected && activeFiltersCP.filtersSelected[baseKey]) {

                let itemFiltered = activeFiltersCP.filtersSelected[baseKey].filter(element => element.id === item.id);

                if (itemFiltered.length) {
                    let indx = activeFiltersCP.filtersSelected[baseKey].indexOf(itemFiltered[0]);

                    if (indx >= 0) {
                        activeFiltersCP.filtersSelected[baseKey].splice(indx, 1);
                        setActiveFilters(activeFiltersCP);
                    }
                } else {
                    activeFiltersCP.filtersSelected[baseKey].push(item);
                    setActiveFilters(activeFiltersCP);
                }
            } else {
                if (activeFiltersCP.filtersSelected) {
                    activeFiltersCP.filtersSelected[baseKey] = [item];
                } else {
                    let value = {};
                    value[baseKey] = [item];
                    activeFiltersCP.filtersSelected = value;
                }

                setActiveFilters(activeFiltersCP);
            }
        }

    }

    const checkOptionSelected = (baseKey, item) => {
        if (baseKey && item && item.id && activeFilters && activeFilters.filtersSelected && activeFilters.filtersSelected[baseKey]) {
            const activeFiltersCP = JSON.parse(JSON.stringify(activeFilters));
            const itemFiltered = activeFiltersCP.filtersSelected[baseKey].filter(element => element.id === item.id);
            return itemFiltered.length;
        }
        return false;
    }

    useEffect(() => {

        prepareCatalogs();

        if (props.activeFilters) {
            setActiveFilters(props.activeFilters);
        }
    }, []);

    useEffect(() => {

        if (props.activeFilters) {
            setActiveFilters(props.activeFilters);
        }
    }, [props.activeFilters]);

    useDidMountEffect(() => {
        if (props.filtersDidChanged) {
            props.filtersDidChanged(activeFilters);
        }
    }, [activeFilters]);

    const renderFilterItems = () => {
        if (catalogsStructure && catalogsStructure.length) {
            return catalogsStructure.map((item, index) => {
                const options = item.items.map((option, indxx) => {
                    return (
                        <div className={`col-12 FiltersSheet-filters-options-item mouse-pointer ${indxx === item.items.length - 1 ? 'isLast':''}`} key={indxx} onClick={e => setOptionSelected(item.baseKey, option)}>
                            <div className="row m-0 w-100 d-flex align-items-center">
                                <div className="col ps-0 FiltersSheet-filters-options-checkbox">
                                    <img src={checkOptionSelected(item.baseKey, option) ? icnCheckboxSelected:icnCheckbox} alt="icnCheckbox"/>
                                </div>
                                <div className="col px-0 FiltersSheet-filters-options-title noselect">{option.name}</div>
                            </div>
                        </div>
                    );
                });
                return (
                    <div className="col-12 p-0 FiltersSheet-filters-item" key={index}>
                        <div className="row m-0" onClick={e => onFilterToggled(index)}>
                            <div className={`col-12 FiltersSheet-filters-header noselect ${item.toggled ? '':'collapsed'}`}>
                                <span className='titleSpan'>{item.filterName}</span>
                                <img src={icnsMessages} alt="icnsMessages" className={`titleImg ${item.toggled ? 'active':''}`}/>
                            </div>
                        </div>

                        <div className={`row m-0 FiltersSheet-filters-options-container ${item.toggled ? '':'collapsed'}`}>
                            {options}
                        </div>
                    </div>
                );
            });
        }
        return null;
    }

    return (
        <div className="FiltersSheet-container row m-0">
            <div className="col-12 p-0">
                <div className="row m-0">
                    <div className="col-12 FiltersSheet-title-box">
                        Filtros
                    </div>
                </div>
                <div className="row m-0 FiltersSheet-filters-container">

                    {renderFilterItems()}

                </div>
            </div>
        </div>
    )
}

export { FiltersSheet }
