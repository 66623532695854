import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: "AIzaSyDIQG9_yI4AVBAZpA9Wxnda0bBBIaWyBRo",
//     authDomain: "fir-wenet-prueba.firebaseapp.com",
//     projectId: "fir-wenet-prueba",
//     storageBucket: "fir-wenet-prueba.appspot.com",
//     messagingSenderId: "874040054442",
//     appId: "1:874040054442:web:f015a263c4d9586d8676d3"
// };
const firebaseConfig = {
    apiKey: "AIzaSyB64ofFP5lnQw0mqylEQMywGi4LxFl8gds",
    authDomain: "wenet-91599.firebaseapp.com",
    projectId: "wenet-91599",
    storageBucket: "wenet-91599.appspot.com",
    messagingSenderId: "748620756275",
    appId: "1:748620756275:web:dcdb42419d8f4785133a46",
    measurementId: "G-P4FZ9EN7DQ"
};

firebase.initializeApp(firebaseConfig);

let messaging = null
if (firebase.messaging && firebase.messaging.isSupported()) {
   messaging = firebase.messaging();
}


// const publicKey = 'BFnGNTO_4k9ZbvU590ho4D78mAk9lRugXpWGMKjfiHGMZDVM6wxuEc0rHMAurIdnAp-y-nbA96AU1P4YT45EqSU';
const publicKey = 'BPE5xGN-HuQuRSupJCQMXsuB28hkLwMC1rnblNPo-5Cw3Ux5Br54APto4KvtzkbxkAD4ZMGSMGwskqE1XKIyyPM';

export const getToken = async (SUID =null) => {
    let currentToken = "";
    try {
        currentToken = await messaging.getToken({ vapidKey: publicKey });
        if (currentToken) {
            // setTokenFound(true);
        } else {
            // setTokenFound(false);
        }
        return {currentToken};
    } catch (error) {
        console.log(error);
        return {currentToken: SUID};
    }
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        if (!messaging) {
            resolve(null);
        }
        messaging.onMessage((payload) => {
            // console.log(payload);
            resolve(payload);
        });
    });

export const getMessagin = ()=>{
    return messaging;
}
