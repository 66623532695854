import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { authenticationService } from '../services';

export const PublicRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (currentUser) {
            if (!currentUser.notValid && currentUser.SUID) {
                return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            }
        }
        // authorised so return component
        return <Component {...props} />
    }} />
)
