import React, { useState, useEffect } from 'react';
import Linkify from 'react-linkify';

import './UserProfile.scss';
import { userService, authenticationService, swalAlertsService, infoService } from '../../../_resources/services';
import { Image } from 'react-bootstrap'
import icnsettings from '../../../_assets/media/images/icons/icn_more_options_w.svg'
import placeholderuser from '../../../_assets/media/images/icons/icn_user_placeholder.png'
import placeholdercompany from '../../../_assets/media/images/icons/placeholder_company.svg'
import icnbusiness from '../../../_assets/media/images/icons/icn_position.png'
import icnphone from '../../../_assets/media/images/icons/icn_phone.png'
import icnemail from '../../../_assets/media/images/icons/icn_mail_black.png'
import icnstate from '../../../_assets/media/images/icons/icn_pin.png'
import icnweb from '../../../_assets/media/images/icons/icn_web.png'
import icnfoundation from '../../../_assets/media/images/icons/icn_date_company.png'
import icnemployee from '../../../_assets/media/images/icons/icn_employee.png'
import icnfolleto from '../../../_assets/media/images/icons/folleto.png'
import icnback from '../../../_assets/media/images/icons/icn_back_arrow_w.png'
import birth from '../../../_assets/media/images/icons/icn_birthday.png';
import icnmore from '../../../_assets/media/images/icons/icn_more.png'
import icnmb from '../../../_assets/media/images/icons/icn_mb.svg'
import icnstart from '../../../_assets/media/images/icons/icn_batch_star.svg';
import icnCommentEdit from '../../../_assets/media/images/icons/icn_comment_edit.png';

import icnsNetworks from '../../../_assets/media/images/icons/icns_networks.png';
import icnLike from '../../../_assets/media/images/icons/icn_like.png';

import icnCalendar from '../../../_assets/media/images/icons/icn_calendar.png';
import icnMentoring from '../../../_assets/media/images/icons/icn_mentoring.png';

import { profileService } from '../../../_resources/services/profile.service';
import { useHistory } from 'react-router-dom';
import { toolsHelper, useDidMountEffect, useWindowDimensions } from '../../../_resources/helpers';

import {
    UniversalPicker,
    SocialNetworks,
    Talent,
    ContactList,
    BMAcademy,
    Header,
    NotificationsSheet,
    MessagesBubble,
    TalentSheet,
    CompanyDetailsSheet,
    BrochureSheet,
    AsociationsSheet
} from '../../shared/';

import { UserProfilePersonalInfo } from './user-profile-components';
import { getMessagin } from '../../../firebaseInit';

const UserProfile = (props) => {

    let scrollY = 0
    const history = useHistory()

    const [scroll, setScroll] = useState(scrollY)
    const [user, setUser] = useState({})
    const [openUniversalPicker, setOpenUniversalPicker] = useState(false)
    const [options, setOptions] = useState([
        { "option": "Bloquear" },
        { "option": "Silenciar" },
        { "option": "Bloquear y Silenciar" }
    ]);

    const [isTalentOpen, setIsTalentOpen] = useState(false);
    const [recomendationEdit, setRecomendationEdit] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [catalogs, setCatalogs] = useState(null);

    const { width, height } = useWindowDimensions();

    const [updateDot, setUpdateDot] = useState(false);

    let messaging = getMessagin();

    if(messaging){
        messaging.onMessage((payload) => {
            setUpdateDot(true);
        });
    }
    
    const endMessage = ()=>{
        setUpdateDot(false);
    }

    useEffect(() => {
        let isSuscribed = true;
        if (props.onToggleTab) {
            props.onToggleTab(true);
        }

        getUser();

        authenticationService.currentUser.subscribe((user) => {
            if (user && user.SUID && user.Usuario && isSuscribed) {
                setCurrentUser(user);
            }
        });

        infoService.catalogs.subscribe((response) => {
            if (typeof response === 'object' && response !== null && isSuscribed) {
                setCatalogs(response);
            }
        });

        return () => isSuscribed = false
    }, [])

    const getScroll = () => {
        setScroll(window.scrollY)
    }

    useDidMountEffect(() => {
        getUser();
    }, [props]);

    const getUser = () => {
        profileService.getUserProfile(props.match.params.user_id).then(
            (response) => {
                setUser(response.data);
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const setChat = () => {
        let chat = toolsHelper.chatObjBuilder(user);
        if (chat) {
            history.push(`/messages`, { chat });
        }
    }

    const setUrl = (key, value) => {
        switch (key) {
            case "icnweb":
                if (value.includes("https://") || value.includes("http://")) {
                    // window.location.replace(value)
                    window.open(value)
                } else {
                    // window.location.replace(`https://${value}`)
                    window.open(`https://${value}`);
                }
                break;
            case "icnphone":
                window.location.replace(`tel:${value}`)
                break;
            case "icnemail":
                window.location.replace(`mailto:${value}`)
                break;

            default:
                break;
        }
    }

    const dataUpdated = () => {
        getUser();
        onBottonTalentDismiss();
    }

    const goBackAndReload = () => {
        const { location } = props;
        if (location && location.state && location.state.from) {
            const froms = location.state.from;
            if (history.location.state && history.location.state.from) {
                let state = { ...history.location.state };
                delete state.from;
                history.replace({ ...history.location, state });
            }
            history.push(froms, { shouldReload: true });
        } else {
            history.goBack();

            setTimeout(() => {
                window.location.reload(true)
            }, 2000);
        }
    }
    const bloqued = () => {


        swalAlertsService.answer("", "¿Estás seguro de querer bloquear a este usuario?", "Si, bloquear").then((result) => {
            if (result.isConfirmed) {
                let loading = swalAlertsService.createLoading("Espere un momento...")

                profileService.bloqued(user.clv_usuario).then(
                    (response) => {
                        if (response.status === 200 || response.status === 202) {
                            loading.fire()
                            swalAlertsService.showToast("success", "El usuario fue bloqueado exitosamente")
                            goBackAndReload();
                        }
                    }
                )
            }
        })

    }

    const restring = () => {
        swalAlertsService.answer("", "¿Estás seguro de querer silenciar a este usuario?", "Si, silenciar").then((result) => {
            if (result.isConfirmed) {
                let loading = swalAlertsService.createLoading("Espere un momento...")
                loading.fire()
                profileService.restring(user.clv_usuario).then(
                    (response) => {
                        if (response.status === 200 || response.status === 202) {

                            swalAlertsService.showToast("success", "El usuario fue silenciado exitosamente")
                            goBackAndReload();
                        }
                    }
                )
            }
        })
    }

    const onInvitationLabelTapped = () => {
        if (user && user.clv_referidopor) {
            if (currentUser && currentUser.Usuario && currentUser.Usuario.clv_usuario === user.clv_referidopor) {
                history.push('/profile', { from: `profile-user/${user.clv_usuario}` })
            } else if (user.clv_usuario !== user.clv_referidopor) {
                history.push('/profile-user/' + user.clv_referidopor, { from: `profile-user/${user.clv_usuario}` });
            }
        }
    }

    const bloquedandrestring = () => {
        swalAlertsService.answer("", "¿Estás seguro de querer bloquear y silenciar a este usuario?", "Si, bloquear y silenciar").then((result) => {
            if (result.isConfirmed) {
                let loading = swalAlertsService.createLoading("Espere un momento...")
                loading.fire()
                profileService.bloqued(user.clv_usuario).then(
                    (response) => {
                        if (response.status === 200 || response.status === 202) {
                            profileService.restring(user.clv_usuario).then(
                                (response) => {
                                    if (response.status === 200 || response.status === 202) {
                                        loading.fire()
                                        swalAlertsService.showToast("success", "El usuario fue bloqueado y silenciado exitosamente")
                                        goBackAndReload();
                                    } else {
                                        swalAlertsService.showToast("error", "Ocurrio un error inténtalo de nuevo más tarde")
                                    }
                                }
                            )
                        } else {
                            swalAlertsService.showToast("error", "Ocurrio un error inténtalo de nuevo más tarde")
                        }
                    }
                )
            }
        })
    }

    const onUniverlPickerHide = (data) => {
        setOpenUniversalPicker(data)
    }

    const onElementSelected = (element) => {
        switch (element.option) {
            case "Bloquear":
                bloqued()
                break;
            case "Silenciar":
                restring()
                break;
            case "Bloquear y Silenciar":
                bloquedandrestring()
                break;

            default:
                break;
        }
    }

    const getTag = (tag) => {
        if (tag) {
            return (
                <div className="container-tag">
                    <p>{tag}</p>
                </div>
            )
        }

        return null;
    }

    const onBackBtnTapped = () => {
        // const {location} = props;
        //
        // if (location && location.state && location.state.from) {
        //     const froms = location.state.from;
        //     if (history.location.state && history.location.state.from) {
        //         let state = { ...history.location.state };
        //         delete state.from;
        //         history.replace({ ...history.location, state });
        //     }
        //     history.push(froms, {shouldReload: true});
        // } else {
        history.goBack();
        // }
    }

    const getIpadeDetails = () => {
        if (catalogs && catalogs.EstatusIpade && catalogs.EstatusIpade.length && user.clv_estatusIpade) {
            const filtered = catalogs.EstatusIpade.filter(el => el.clv_estatusIpade === user.clv_estatusIpade);

            if (filtered.length) {
                return `${filtered[0].EstatusIpade}${user.FechaIpade ? ` - ${user.FechaIpade}` : ''}`;
            }
        }

        return null;
    }

    const getFixedUrl = (item) => {
        if (item && item.RedSocial && item.Perfil) {

            if (!toolsHelper.validateURL(item.Perfil)) {
                switch (item.RedSocial) {
                    case 'Facebook':
                        return `https://facebook.com/${item.Perfil}`;
                        break;
                    case 'Twitter':
                        return `https://twitter.com/${item.Perfil}`;
                        break;
                    case 'LinkedIn':
                        return `https://linkedin.com/in/${item.Perfil}`;
                        break;
                    case 'YouTube':
                        return `https://www.youtube.com/results?search_query=${item.Perfil}`;
                        break;
                    case 'Instagram':
                        return `https://instagram.com/${item.Perfil}`;
                        break;
                    case 'Spotify':
                        return `https://spotify.com/${item.Perfil}`;
                        break;
                }
            }
            return item.Perfil;
        }
        return null;
    }

    const onBottonTalentDismiss = () => {
        setIsTalentOpen(false);
    }

    const onRecomendationUserTapped = (recomendation) => {
        if (recomendation && recomendation.clv_usuariorecomienda && currentUser && currentUser.Usuario.clv_usuario) {
            if (recomendation.clv_usuariorecomienda === currentUser.Usuario.clv_usuario) {
                history.push(`/profile`, { from: '/profile-user' });
            } else if (recomendation.clv_usuariorecomienda !== user.clv_usuario) {
                history.push(`/profile-user/${recomendation.clv_usuariorecomienda}`, { from: '/profile-user' });
            }
        }
    }

    const onRecomendationBtnTapped = () => {
        let text = '';
        if (user && user.Recomendaciones && user.Recomendaciones.length) {
            if (currentUser && currentUser.Usuario && currentUser.Usuario.clv_usuario) {
                let filtered = user.Recomendaciones.filter(ele => ele.clv_usuariorecomienda === currentUser.Usuario.clv_usuario);

                if (filtered.length && filtered[0].Recomendacion) {
                    text = filtered[0].Recomendacion;
                }
            }
        }


        setRecomendationEdit({ text });

        setTimeout(() => {
            setIsTalentOpen(true);
        }, 200);

    }

    const getMentoringData = () => {
        // icnCalendar


        if (user.OfreceMentoria && (user.Mentoria || user.MentoriaPersonalizado)) {

            if (user.clv_tipomentoria === 4 && user.MentoriaPersonalizado) {
                return (
                    <div className="data_user_info_fill">
                        <img src={icnMentoring} />
                        {
                            user.Mentoria ? (<p>{`Otorga mentoría - ${user.MentoriaPersonalizado || ''}`}</p>) : (<p>{`Otorga mentoría - ${user.Mentoria || ''}`}</p>)
                        }
                    </div>
                )
            }
            return (
                <div className="data_user_info_fill">
                    <img src={icnMentoring} />
                    {
                        user.Mentoria ? (<p>{`Otorga mentoría - ${user.Mentoria || ''}`}</p>) : (<p>{`Otorga mentoría - ${user.MentoriaPersonalizado || ''}`}</p>)
                    }
                </div>
            )
        }
        return null;
    }

    const getUserDate = (dateString) => {
        if (dateString) {
            const month = toolsHelper.getMonthName(dateString);

            const date = new Date(dateString);

            return `${month}. ${date.getFullYear()}`;
        }
        return null;
    }

    const renderRecomendations = () => {

        if (user.Recomendaciones && user.Recomendaciones.length) {
            const slicedArray = user.Recomendaciones.slice(0, 3);
            const items = slicedArray.map((recomendation, index) => {

                return (
                    <div className="col recomendation-item mouse-pointer" key={index}  onClick={() => history.push(`/talent/${user.clv_usuario}`, { from: '/profile-user' })}>
                        <div className="row m-0 recomendation-item-row ">
                            <div className="col-12 p-0">
                                <div className="row m-0">
                                    <div className="col-12 p-0">
                                        {/* <div className="row m-0 mouse-pointer" onClick={() => onRecomendationUserTapped(recomendation)}> */}
                                        <div className="row m-0 ">
                                            <div className="col avatar-image-box-60 pos-relative">
                                                <img src={recomendation.ThumbnailUrl ? recomendation.ThumbnailUrl : placeholderuser} alt="recomendation photo"
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = placeholderuser
                                                    }}
                                                />
                                                <img src={icnLike} alt="like icon" width='35' height='35' className='likeIcn' />
                                            </div>
                                            <div className="col pe-0">
                                                <div className="row m-0 recomendation-details-box">
                                                    <div className="col-12 px-0 user-details-title text-block-truncate-1">{recomendation.NombreCompleto}</div>
                                                    <div className="col-12 px-0 user-details-subtitle soft text-block-truncate-1 small">
                                                        {`${recomendation.Cargo ? recomendation.Cargo : ''}${recomendation.Cargo && recomendation.EmpresaNombreCorto ? ' | ' : ''}${recomendation.EmpresaNombreCorto ? recomendation.EmpresaNombreCorto : ''}`}
                                                    </div>
                                                    <div className="col-12 px-0 user-details-subtitle soft text-block-truncate-1 small">
                                                        {`${recomendation.EstadoResidencia ? recomendation.EstadoResidencia : ''}${recomendation.EstadoResidencia && recomendation.PaisResidencia ? ', ' : ''}${recomendation.PaisResidencia ? recomendation.PaisResidencia : ''}`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className="col-12 px-0 recomendation-text-box text-block-truncate-4">
                                        {recomendation.Recomendacion}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })
            return (
                <div className="col-12 data-recomendation-container">
                    <div className="row m-0 d-flex align-items-center recomendation-header">
                        <div className="col p-0 recomendation-cant-box">
                            <img src={icnLike} alt="like icon" width='35' height='35' /> {user.Recomendaciones.length} {user.Recomendaciones.length == 1 ? 'Recomendación' : 'Recomendaciones'}
                        </div>
                        <div className="col recomendation-showmore-box">
                            <button className="btn-cleared" onClick={() => history.push(`/talent/${user.clv_usuario}`, { from: '/profile-user' })}>Ver todo</button>
                        </div>
                    </div>

                    <div className="row m-0 recomendation-items-container flex-nowrap ">
                        {items}
                    </div>
                </div>
            )
        }
        return null;
    }

    const renderRecomendationBtn = () => {
        if (user && currentUser && currentUser.Usuario) {

            if (user.Recomendaciones && user.Recomendaciones.length) {
                let filtered = user.Recomendaciones.filter(ele => ele.clv_usuariorecomienda === currentUser.Usuario.clv_usuario);

                if (!filtered.length) {
                    return (
                        <div className="col-12 data-network-adder-container">
                            <button onClick={onRecomendationBtnTapped}>
                                <img src={icnCommentEdit} alt="recomendation icon" width='30' height='30' /> Recomendar
                            </button>
                        </div>
                    )
                }
            } else {
                return (
                    <div className="col-12 data-network-adder-container">
                        <button onClick={onRecomendationBtnTapped}>
                            <img src={icnCommentEdit} alt="recomendation icon" width='30' height='30' /> Recomendar
                        </button>
                    </div>
                )
            }

        }

        return null;
    }

    const getDiscountData = () => {
        if (user.OfreceDescuento && user.PorcentajeDescuento) {
            return (
                <div className="row m-0 UserProfile-discount-container">
                    <div className="col-12 UserProfile-discount-col">
                        <div className="row m-0 w-100">
                            <div className="col discount-number px-0">{user.PorcentajeDescuento}%</div>
                            <div className="col discount-text">
                                De descuento para miembros WeNet
                            </div>
                        </div>
                    </div>

                    {user.ComentariosDescuento ? (
                        <div className="col-12 UserProfile-discount-comments px-0 py-3">
                            <strong>Comentarios:</strong> {user.ComentariosDescuento}
                        </div>
                    ) : (null)}
                </div>
            );
        }
        return null;
    }

    const getPhoneDetails = () => {
        { console.log(user); }
        if (user && user.Celular) {
            if (user.CelularPrivado) {
                return <p className="data_user_info_fill_icnphone font-color-green">** **** ****</p>
            }
            return <p className="data_user_info_fill_icnphone  font-color-green" onClick={() => user.CelularPrivado === false ? setUrl("icnphone", user.Celular) : ""}>{user.CelularPrivado === false ? user.Celular : '** **** ****'}</p>;
        }

        return <p className="data_user_info_fill_icnphone placeholderTxt">Sin definir celular</p>;
    }

    const getEmailDetails = () => {

        if (user && user.Email) {
            if (user.EmailPrivado) {
                return <p className="data_user_info_fill_icnemail font-color-green">*****@*****.***</p>
            }

            return <p className="data_user_info_fill_icnemail font-color-green" onClick={() => user.EmailPrivado === false ? setUrl("icnemail", user.Email) : ""}>{user.EmailPrivado === false ? user.Email : "******@*****.com"}</p>;
        }

        return <p className="data_user_info_fill_icnemail placeholderTxt">Sin definir email</p>
    }

    const getDateBirth = () => {
        if (user.FechaNacimientoPrivado) {
            return '***********';
        }
        if (user.FechaNacimiento) {
            const month = toolsHelper.getMonthName(user.FechaNacimiento);
            let x = new Date(user.FechaNacimiento);
            let formatted_date = x.getDate() + " " + month + " " + x.getFullYear();
            return formatted_date;
        }
        return 'Sin definir';
    }

    const mobileRender = () => {

        return (
            <div className="container_profile">
                <div className="image_user">
                    <div className="btn_configuration">
                        <img src={icnback} onClick={onBackBtnTapped} />
                        <img src={icnsettings} onClick={() => setOpenUniversalPicker(true)} />
                    </div>
                    <img className="image_profile" 
                        src={user.PhotoUrl != null ? user.PhotoUrl : placeholderuser} 
                        width="375px" 
                        height="375px"
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = placeholderuser
                        }}
                    />
                </div>
                <div className={`data_user ${scroll >= 280 ? 'hidden' : ''}`}>

                    <div className="name-user">
                        <h1>
                            {
                                user.clv_estatusbm === 2 &&
                                <img src={icnstart} width="10px" height="10px" alt="mieicnmbro" />
                            }
                            {user.NombreCompleto}
                        </h1>
                        <div className="data_account">
                            <div className="data_account_option">
                                {user.clv_estatusreferido && user.clv_estatusreferido != 3 ?
                                    <p className='invitation-label'>Invitado por:
                                        <span className='noselect' onClick={user.clv_estatusreferido == 2 ? onInvitationLabelTapped : () => { }}>
                                            {
                                                user.clv_estatusreferido == 1 ?
                                                    (<strong className='ps-1 text-start noselect text-block-truncate-1'>Pendiente</strong>) :
                                                    user.clv_estatusreferido == 2 && user.InvitadoPor && user.PhotoUrlInvitadoPor ?
                                                        (
                                                            <>
                                                                <img src={user.PhotoUrlInvitadoPor} 
                                                                    alt="invitation" 
                                                                    className='noselect invitation-miniature'
                                                                    onError={(e) => {
                                                                        e.target.onerror = null;
                                                                        e.target.src = placeholderuser
                                                                    }}
                                                                />
                                                                <strong className='ps-1 text-start noselect text-block-truncate-1'>{user.InvitadoPor}</strong>
                                                            </>
                                                        ) :
                                                        user.clv_estatusreferido == 2 && user.InvitadoPor && !user.PhotoUrlInvitadoPor ?
                                                            (<strong className='ps-1 text-start noselect text-block-truncate-1'>{user.InvitadoPor}</strong>)
                                                            :
                                                            (null)
                                            }
                                        </span>
                                    </p>
                                    : ''}
                            </div>
                        </div>
                    </div>

                    <div className="data">

                        <div className="data_user_info">
                            <div className="data_user_info_fill">
                                <img src={icnbusiness} />
                                <p>
                                    {`${user.Cargo ? user.Cargo : ''}${user.Cargo && user.Empresa ? ' | ' : ''}${user.Empresa ? user.Empresa : ''}`}
                                </p>
                            </div>
                            <div className="data_user_info_fill">
                                <img src={icnphone} />
                                {getPhoneDetails()}
                            </div>
                            <div className="data_user_info_fill">
                                <img src={icnemail} />
                                {getEmailDetails()}
                            </div>

                            <div className="data_user_info_fill">
                                <img src={icnstate} />
                                {
                                    user.EstadoResidencia || user.PaisResidencia ? (
                                        <p className='mouse-default'>{`${user.EstadoResidencia ? user.EstadoResidencia : ''}${user.EstadoResidencia && user.PaisResidencia ? ', ' : ''}${user.PaisResidencia ? user.PaisResidencia : ''}`}</p>
                                    ) : (
                                        <p className='placeholderTxt mouse-default'>Sin definir ubicación</p>
                                    )
                                }
                            </div>

                            {getMentoringData()}

                            <div className="data_user_info_fill">
                                <img src={birth} />
                                <p className="data_user_info_fill_icnemail">{getDateBirth()}</p>
                            </div>

                            {
                                user.FechaAlta ? (
                                    <div className="data_user_info_fill">
                                        <img src={icnCalendar} />
                                        <p>Miembro WeNet desde {getUserDate(user.FechaAlta)}</p>
                                    </div>
                                ) : (null)
                            }

                            <button className="data_user_info_btn_edit" onClick={() => setChat()}>Enviar mensaje</button>
                        </div>

                        <div className="row data-network-container">
                            <div className="col-12 data-network-header">Sígueme en:</div>

                            <div className="col-12 data-networks-box">
                                {
                                    user.RedesSociales &&
                                        user.RedesSociales.length ?
                                        user.RedesSociales.map((item, index) => {

                                            if (item.RedSocial !== 'Spotify') {
                                                return (
                                                    <a href={getFixedUrl(item)} target="_blank" rel="noreferrer" key={index}>
                                                        <div className="row m-0">
                                                            <div className="col-12 mouse-pointer">
                                                                <img src={icnsNetworks} alt="" className={item.RedSocial} />
                                                                <div className='text-block-truncate-1'>
                                                                    {item.RedSocial === 'Facebook' || item.RedSocial === 'LinkedIn' ? '/' : '@'}{item.Perfil}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )
                                            } else {
                                                return (
                                                    <div className="row m-0" key={index}>
                                                        <div className="col-12 noselect">
                                                            <img src={icnsNetworks} alt="" className={item.RedSocial} />
                                                            <div className='text-block-truncate-1'>
                                                                {item.Perfil}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }) : (null)
                                }

                            </div>


                        </div>

                        <div className="row data-network-container">
                            <div className="col-12 data-network-header">Mi mayor talento:</div>

                            {
                                user.Talento ? (
                                    <div className="col-12 data-networks-box text-content">
                                        {user.Talento}
                                    </div>
                                ) : (null)
                            }

                            {renderRecomendationBtn()}

                            {renderRecomendations()}
                        </div>

                        <div className="data_company">
                            <div className="data_company_header">
                                <img src={user.LogoThumbnailUrl !== null ? user.LogoThumbnailUrl : placeholdercompany} width="100px" height="78px" />
                                <div className="data_company_header_title">
                                    <h6>{user.EmpresaNombreCorto}</h6>
                                    <p>{user.Giro}</p>
                                    <p>{user.Clasificacion}</p>
                                </div>
                            </div>

                            <h3>{user.Empresa}</h3>
                            <p>{user.Descripcion}</p>

                            <div className="data_company_info_fill">
                                <img src={icnweb} />
                                {
                                    user.URL ? (

                                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a className='pl-2' target="blank" href={decoratedHref} key={key} style={{ color: '#0fb855', textDecoration: 'none', marginLeft: '10px' }}>
                                                {decoratedText}
                                            </a>
                                        )}>
                                            {user.URL}
                                        </Linkify>

                                    ) : (<p>NA</p>)
                                }
                            </div>

                            <div className="data_company_info_fill">
                                <img src={icnphone} />
                                {
                                    user.Telefono ? (
                                        <p className="data_company_info_fill_icnphone" onClick={() => setUrl("icnphone", user.Telefono)}>{user.Telefono}</p>
                                    ) : (<p>NA</p>)
                                }
                            </div>

                            <div className="data_company_info_fill">
                                <img src={icnstate} />
                                <p>{user.EstadoResidencia + ", " + user.PaisResidencia}</p>

                            </div>

                            <div className="data_company_info_fill">
                                <img src={icnfoundation} />
                                {
                                    user.Fundacion ? (
                                        <p>{`Fundada en ${new Date(user.Fundacion).getFullYear().toString()}`}</p>
                                    ) : (<p>NA</p>)
                                }
                            </div>

                            {
                                user.RangoEmpleados &&
                                <div className="data_company_info_fill">
                                    <img src={icnemployee} />
                                    <p>De {user.RangoEmpleados} empleados</p>
                                </div>
                            }

                            {
                                user.Tag1 || user.Tag2 ?
                                    (
                                        <>
                                            <h4>Especialidades</h4>

                                            <div className="data_company_tags">
                                                {
                                                    getTag(user.Tag1)
                                                }
                                                {
                                                    getTag(user.Tag2)
                                                }
                                            </div>
                                        </>
                                    ) : (null)
                            }

                            {getDiscountData()}

                        </div>

                        <div className="data_folleto">

                            <img src={icnfolleto} width="100px" height="80px" />

                            {
                                user.BrochureUrl !== null
                                    ? <p className="text-green pointer" onClick={() => setUrl("icnweb", user.BrochureUrl)}>Folleto de la empresa</p>
                                    : <p>Folleto no disponible</p>
                            }

                            <img src={icnmore} width="40px" height="40px" />

                        </div>

                        {
                            user.clv_estatusbm == 2 || user.clv_estatusbm == 3 || user.clv_estatusIpade ?
                                <div className="row m-0 UserProfile-asociations-container">
                                    <div className="col-12 px-0 colTitle">
                                        Asociaciones a las que pertenece
                                    </div>

                                    {
                                        user.clv_estatusbm && user.clv_estatusbm != 4 && user.clv_estatusbm != 1 ? (
                                            <div className="col-12 asociationBox">
                                                <div className="row m-0">
                                                    <div className="col-12 px-0 asocTitle">BoardMedia Digital</div>
                                                    <div className="col-12 px-0 asocDetails">{user.clv_estatusbm === 2 ? 'Miembro activo' :
                                                        user.clv_estatusbm === 3 ? 'Ex Miembro' : ''}</div>
                                                </div>
                                            </div>
                                        ) : (null)
                                    }

                                    {
                                        user.clv_estatusIpade ? (
                                            <div className="col-12 asociationBox">
                                                <div className="row m-0">
                                                    <div className="col-12 px-0 asocTitle">IPADE</div>
                                                    <div className="col-12 px-0 asocDetails">{getIpadeDetails()}</div>
                                                </div>
                                            </div>
                                        ) : (null)
                                    }

                                </div> : (null)
                        }



                    </div>

                </div>

            </div>
        )
    }

    const desktopRender = () => {
        return (
            <div className="UserProfile-container">
                <Header title='' activeLink='profile' hideSearcher={true} updateShowDot={updateDot} 
                    endMessage={endMessage} />

                <div className="row m-0 d-flex justify-content-center UserProfile-box-container">
                    <div className="col ps-0 UserProfile-post-box">
                        <div className="row m-0 UserProfile-main-row">
                            <div className="col-12 p-0">
                                <div className="row m-0">
                                    <div className="col p-0">
                                        <UserProfilePersonalInfo user={user} onChatTapped={setChat} onSettingsTapped={e => setOpenUniversalPicker(true)} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 p-0">
                                <TalentSheet user={user} isMine={false} onRecomendationUserTapped={onRecomendationUserTapped} />
                            </div>

                            <div className="col-12 px-0">
                                <CompanyDetailsSheet isMine={false} user={user} />
                            </div>

                            {
                                user.clv_estatusbm == 2 || user.clv_estatusbm == 3 || user.clv_estatusIpade ?
                                    <div className="col-12 px-0">
                                        <AsociationsSheet user={user} />
                                    </div> : (null)
                            }


                            <div className="col-12 px-0">
                                <BrochureSheet isMine={false} user={user} />
                            </div>
                        </div>
                    </div>

                    <div className="col UserProfile-lateral-content right">
                        <div className="row m-0 pos-sticky isTop-10">
                            <div className="col-12 px-0 pb-3">
                                <NotificationsSheet />
                            </div>
                        </div>
                    </div>
                </div>

                <MessagesBubble />
            </div>
        );
    }
    return (
        <React.Fragment>
            {
                width && width >= 1450 ? (desktopRender()) : (mobileRender())
            }
            {
                openUniversalPicker ? (
                    <UniversalPicker mode="options" options={options} onDismiss={onUniverlPickerHide} onElementSelected={onElementSelected} />
                ) : (null)
            }

            {
                isTalentOpen && recomendationEdit && user &&
                <Talent
                    onBottomSheetDimiss={onBottonTalentDismiss}
                    title='Recomendación'
                    dataUpdated={dataUpdated}
                    talent={recomendationEdit ? recomendationEdit.text : ''}
                    isRecomendation={true}
                    currentUser={currentUser}
                    user={user}
                />
            }
        </React.Fragment>
    )
}

export { UserProfile };
