import React, { useState, useEffect } from 'react';
import "./NewPassword.scss"
import pass from '../../../../_assets/media/images/icons/icn_pass.png'
import back from '../../../../_assets/media/images/icons/icn_back_arrow.png'
import { authenticationService, swalAlertsService } from '../../../../_resources/services';
import { settingService } from '../../../../_resources/services/settings.service';
import { useHistory } from 'react-router-dom';

const NewPassword = (props) => {

    const history = useHistory()

    const [data, setData] = useState({
        "OldPassword": "",
        "NewPassword": "",
        "ConfirmPassword": ""
    })

    const setInputForm = (type, e) => {
        setData({...data, [type]: e.target.value})
    }

    useEffect(() => {
        if (props.onToggleTab) {
            props.onToggleTab(true);
        }
    }, []);

    const setDataPassword = () => {
        if(data.NewPassword != "" && data.ConfirmPassword != "" && data.OldPassword != "") {
            if(data.NewPassword === data.ConfirmPassword) {

                const r = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&_])[A-Za-z\\d@$!%*?&_]{8,}\$", "i")
                if(data.NewPassword.length < 8) {
                    let text = '';
                    text = "Contraseña no valida \n Tu Contraseña debe tener una longitud mínima de 8";
                    swalAlertsService.showToast('error', text);
                }  else {
                    let loading = swalAlertsService.createLoading("Actualizando")
                    loading.fire()
                    settingService.updatePassword(data).then(
                        (response) => {
                            if(response.status !== 500 && response.status !== 400) {
                                loading.close()
                                swalAlertsService.showToast('success', 'Contraseña actualizada correctamente')
                            }else {
                                loading.close()
                                swalAlertsService.showToast('error', 'Contraseña antigua incorrecta')
                            }
                        }
                    )
                }


            }else {
                swalAlertsService.showToast('error', 'Las contraseñas deben de coincidir')
            }
        }else {
            swalAlertsService.showToast('error', 'No pueden quedar campos vacios')
        }
    }

    const recoverPassword = () => {
        swalAlertsService.answer("", "¿Estás seguro de querer recuperar tu contraseña? Se cerrará la sesión que tienes abierta para comenzar el proceso de recuperación.", "Si, recuperar").then((result) => {
            if (result.isConfirmed) {
                authenticationService.logout().then((response) => {
                    history.replace('/recover');
                    window.location.reload();
                });
            }
        })


    }

    const password = () => {
        return (
            <div>
                <div className="conatainer_password_input">
                    <div className="container_edit_user_input_field jump_top" >
                        <img src={pass} />
                        <input type="password" onChange={e => setInputForm("ApellidoMaterno",e)} placeholder="Contraseña actual" onChange={e => setInputForm("OldPassword",e)}/>
                    </div>
                </div>

                <div className="conatainer_password_input">
                    <div className="container_edit_user_input_field " >
                        <img src={pass} />
                        <input type="password" onChange={e => setInputForm("ApellidoMaterno",e)} placeholder="Nueva contraseña" onChange={e => setInputForm("NewPassword",e)}/>
                    </div>
                </div>

                <div className="conatainer_password_input">
                    <div className="container_edit_user_input_field " >
                        <img src={pass} />
                        <input type="password" onChange={e => setInputForm("ApellidoMaterno",e)} placeholder="Confirmar nueva contraseña" onChange={e => setInputForm("ConfirmPassword",e)}/>
                    </div>
                </div>

                <div className="row m-0">
                    <div className="col-12 RegisterCode-input-box">
                        <p className='passwordDisclaimer'>
                            La contraseña debe ser mínimo de 8 caracteres que contengan al menos una letra mayúscula, una letra minúscula, un número y un carácter especial, como: ! # $ % & _
                        </p>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <div className={`conatainer_password ${props.isDesktop ? 'isDesktop':''}`}>
            <div className="container_header">
                {
                    !props.isDesktop ? (
                        <img src={back} width="30px" height="30px" onClick={() => history.goBack()}/>
                    ):(null)
                }
                <h3>Cambiar contraseña</h3>
            </div>

            {password()}

            <button onClick={() => setDataPassword()}>Cambiar contraseña</button>

            <button onClick={() => recoverPassword()} className="button-recover">Recuperar contraseña</button>
        </div>
    );
}

export {NewPassword};
