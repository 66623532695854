import React, { useState, useEffect, useRef } from 'react';
import { ImageGroup, Image } from 'react-fullscreen-image'

import { LazyImage } from "react-lazy-images";

import Linkify from 'react-linkify';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Redirect, useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

import { Document, Page, pdfjs } from 'react-pdf';
import { toolsHelper } from '../../../_resources/helpers';
import './Post.scss';
import { authenticationService, swalAlertsService, infoService } from '../../../_resources/services';

import { Dropdown } from 'react-bootstrap';
import { AddPost } from '../../pages';
import { ReportPost, VideoPlayer, LikesModal } from '../';

import icnUserPlaceholder from '../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnMore from '../../../_assets/media/images/icons/icn_more_options.png';
import icnBrochure from '../../../_assets/media/images/icons/icn_doc.png';
import icnComment from '../../../_assets/media/images/icons/icn_comment.png';
import icnDownload from '../../../_assets/media/images/icons/icn_download.png';
import start from '../../../_assets/media/images/icons/icn_batch_star.svg';
import close from '../../../_assets/media/images/icons/icn_close.png';
import icnPlay from '../../../_assets/media/images/icons/icn_play_video.png';
import icnsReactions from '../../../_assets/media/images/icons/icns_reactions.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Post = (props) => {
    let galleryRef = useRef(null);
    let galleryFullRef = useRef(null);
    const [currentUser, setCurrentUser] = useState(authenticationService.currentUserValue);
    const [isEllipsed, setIsEllipsed] = useState(false);
    const [showMore, setShowMore] = useState(false);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [openPostEditor, setOpenPostEditor] = useState(false);
    const [onReportPost, setOnReportPost] = useState(false);
    const [imageFullScreen, setImageFullScreen] = useState(false);

    const [galleryPosition, setGalleryPosition] = useState(0);
    const [catalogs, setCatalogs] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isOpenLikesModal, setIsOpenLikesModal] = useState(false);

    const history = useHistory()
    var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

    const isEllipsisActive = (e) => {
        return (e.offsetHeight < e.scrollHeight);
    }

    const checkIfElementEllipsed = () => {
        let element = document.getElementById(props.item.clv_publicacion);
            if (element) {
                setIsEllipsed(isEllipsisActive(element));
                if(props && props.isDetails){
                    setShowMore(true);
                }
            }
    }

    const detectLinksInContent = (content) => {

        if (content && content.Contenido && content.Contenido.length) {
            let urlRegex = /(https?:\/\/[^\s]+)/g;
            /*return content.replace(urlRegex, function(url) {
                return `<a href="' + url + '">' + url + '</a>`;
            })*/
            // or alternatively
            return content.Contenido.replace(urlRegex, `<a href="$1" target="_blank">$1</a>`)
        }

        return '';

    }

    const toggleOpenModal = (value) => {
        setOpenPostEditor(value);
    }

    function onDocumentLoadSuccess({ numPages }) {
        if (numPages > 0) {
            setNumPages(1);
        }

    }

    function setUser() {
        if(currentUser.Usuario.clv_usuario === props.item.clv_usuario ) {
            history.replace('/profile')
        }else {
            history.push('/profile-user/' + props.item.clv_usuario, {from: '/'} )
        }
    }

    const onCommentBtnTapped = () => {

        if (props.isDetails) {
            if (props.onCommentBtnTapped) {
                props.onCommentBtnTapped();
            }
        } else {
            goPostDetails(true);
        }
    }


    useEffect(() => {
        let isSuscribed = true;

        infoService.catalogs.subscribe((response) => {
            if (isSuscribed && response && response.TiposAdjunto && response.TiposAdjunto.length) {
                setCatalogs(response.TiposAdjunto);
            }
        });
        checkIfElementEllipsed();

        return () => isSuscribed = false
    }, []);

    const onOptionTapped = (type) => {

        if (type === 'edit') {
            setOpenPostEditor(true);
        } else if (type === 'delete') {
            swalAlertsService.answer("", "¿Estás seguro de querer borrar tu publicación?", "Si, borrar").then((result) => {
                if (result.isConfirmed) {
                    if (props.onPostDelete && props.item) {
                        props.onPostDelete(props.item);
                    }
                }
            })
        } else if (type === 'report') {
            setOnReportPost(true);
        }

    }

    const goPostDetails = (isComment = false) => {

        if (!props.isDetails && props.item && props.item.clv_publicacion) {
            history.push(`/post/${props.item.clv_publicacion}`, {isComment: isComment ? true:false});
        }
    }

    const onReportSuccess = () => {
        setOnReportPost(false);
        swalAlertsService.showToast('success', 'Publicación reportada con éxito');
    }

    const showDownDownOptions = () => {
        let options = [];
        if (currentUser.Usuario.clv_usuario === props.item.clv_usuario) {
            options.push(<Dropdown.Item key='0' onClick={e => onOptionTapped('edit')}>Editar publicación</Dropdown.Item>);
            options.push(<Dropdown.Item key='1' className='text-danger' onClick={e => onOptionTapped('delete')}>Eliminar publicación</Dropdown.Item>)
        } else {
            options.push(<Dropdown.Item key='0' className='text-danger' onClick={e => onOptionTapped('report')}>Reportar publicación</Dropdown.Item>);
        }
        return (
            <>{options}</>
        );
    }

    const onSuccessEdit = () => {
        setOpenPostEditor(false);
        if (props.onSuccessEdit) {
            props.onSuccessEdit();
        }
    }

    const goImageFullScreen = () => {
        setImageFullScreen(true);
    }

    const closeFullScreen = () => {
        setImageFullScreen(false);
    }

    const showGalleryImages = (goFull) => {
        if (props.item.Galeria && props.item.Galeria.length) {
            return props.item.Galeria.map((image, index) => {
                return showItemByType(goFull, image, index);
            });

        }
        return null;
    }

    const showItemByType = (goFull, image, index) => {
        if (catalogs && catalogs.length) {
            const typeFiltered = catalogs.filter(el => el.clv_tipoadjunto === image.clv_tipoimagen);

            if (typeFiltered.length && (typeFiltered[0].clv_tipoadjunto || typeFiltered[0].clv_tipoimagen)) {
                let type = typeFiltered[0].clv_tipoadjunto || typeFiltered[0].clv_tipoimagen;
                switch (type) {
                    case 6://video mp4
                        return (
                            <div className='item isVideo pos-relative' key={index}>
                                <VideoPlayer url={image.ImageUrl} id={`VideoPost-${image.clv_imagenxpublicacion}`} thumbnail={image.ImageThumbnailUrl}/>
                                {
                                    goFull ? (
                                        <a href={image.ImageUrl} target='_blank'>
                                            <img src={icnDownload} alt="donwload icon" className='image-icon'/>
                                        </a>
                                    ):(null)
                                }
                            </div>
                        );
                    case 3://jpg
                    case 4://png
                        return (
                            <div className='item' key={index}>
                                <LazyImage src={image.ImageUrl}
                                    alt="Post img"
                                    placeholder={ ({imageProps, ref}) =>

                                        <img ref={ref} src={image.ImageThumbnailUrl} alt={imageProps.alt} className={'LazyImage-Placeholder'}/>
                                    }
                                    actual={({imageProps}) =>
                                        <img {...imageProps}
                                            onClick={(e) => {
                                                if (goFull) {
                                                    goImageFullScreen();
                                                }
                                            }}
                                        />
                                    }
                                />
                                {
                                    goFull ? (
                                        <a href={image.ImageUrl} target='_blank'>
                                            <img src={icnDownload} alt="donwload icon" className='image-icon'/>
                                        </a>
                                    ):(null)
                                }
                            </div>
                        );
                        break;
                    default:

                }
            }

        }

        return null;
    }

    const didIReacted = () => {
        if (props.item && props.item.clv_publicacion && props.reactions && props.reactions.length) {
            const reacted = props.reactions.filter(element => element.clv_publicacion === props.item.clv_publicacion && element.clv_tiporeaccion === 1);

            if (reacted.length) {
                return true;
            }
        }
        return false;
    }

    const onLikeBtnTapped = () => {
        if (props.postLiked) {
            props.postLiked(props.item);
        }
    }

    const showLikesModal = () => {
        setIsOpenLikesModal(true);
    }

    const getReactions = () => {
        if (props.item && props.item.TiposReaccion && props.item.TiposReaccion.length) {
            const likes = props.item.TiposReaccion.filter(el => el.clv_tiporeaccion === 1);

            if (likes.length) {
                return likes[0];
            }
        }
        return null;
    }

    const goToMentionLink = (clv_usuario) => {

        if (clv_usuario) {
            console.log(clv_usuario);
            console.log(currentUser.Usuario);
            if (
                currentUser &&
                currentUser.Usuario &&
                currentUser.Usuario.clv_usuario &&
                currentUser.Usuario.clv_usuario === clv_usuario
            ) {
                history.replace('/profile');
            } else {
                history.push('/profile-user/' + clv_usuario, {from: '/'} );
            }
        }
    }

    const createMentionTxt = (message) => {
        let value = message.Contenido;
        if (message.Menciones && message.Menciones.length) {
            let finalValue = [];
            value = value.trim();
            let tokenString = value.split(' ');
            let keyValue = 0;
            if (tokenString.length) {
                tokenString.forEach((item, i) => {
                    if (item.length) {

                        if (item.indexOf('@@') >= 0) {
                            const brSpacing = item.indexOf('\n') >= 0;
                            const fixedTxt = item.replaceAll('\n', '');
                            const mentioned = message.Menciones.filter(el => el.Tag === fixedTxt);

                            if (mentioned.length) {
                                keyValue += 1;
                                const obj = [0].map((el, i) => {
                                    if (brSpacing) {
                                        return (
                                            <span key={keyValue}>
                                                <br/>
                                                <span className='bubbleMention' title={mentioned[0].NombreCompleto} onClick={e => goToMentionLink(mentioned[0].clv_usuario)}>{`${mentioned[0].Nombre}${tokenString.length - 1 > i ? ' ':''}`}</span>
                                            </span>
                                        );
                                    }
                                    return (<span key={keyValue} className='bubbleMention' title={mentioned[0].NombreCompleto} onClick={e => goToMentionLink(mentioned[0].clv_usuario)}>{`${mentioned[0].Nombre}${tokenString.length - 1 > i ? ' ':''}`}</span>);
                                });
                                finalValue = finalValue.concat(obj);
                            }
                        } else {
                            keyValue += 1;
                            const obj = [0].map((el, i) => {
                                return (<span key={keyValue}>{`${item}${tokenString.length - 1 > i ? ' ':''}`}</span>);
                            });
                            finalValue = finalValue.concat(obj);
                        }
                    }
                });

            }
            return finalValue;
        }
        return message.Mensaje || null;
    }

    const renderMessageText = (message) => {
        if (message && message.Contenido) {
            const isDeleted = message.Eliminado;
            if (!isDeleted && message.Menciones && message.Menciones.length) {
                return createMentionTxt(message);
            }
            return message.Contenido.split('\n').map((str, indexv) => {
                if(!str.length){
                    return <div key={`vval-${indexv}`}><br /></div>
                }
                return <div>{str}</div>
            });
        }
        return null;
    }

    if (props.item) {
        return (
           <React.Fragment>
                <div className={`Post-container mouse-pointer ${props.isDetails ? 'is-details':''}`}>
                    <div className="row m-0">
                        <div className="col-12 Post-header">

                            <div className="row m-0">
                                <div className="col p-0 Post-user-avatar-box" onClick={() => setUser()}>
                                    <img loading="lazy" src={props.item.ThumbnailUrl ? props.item.ThumbnailUrl:icnUserPlaceholder} 
                                        alt="User photo" 
                                        className="avatar"
                                        onError={(e)=>{
                                            e.target.onerror = null;
                                            e.target.src=icnUserPlaceholder
                                        }}
                                    />
                                    {
                                        props.item.clv_estatusbm === 2 &&
                                        <img src={start} width="10px" height="10px" alt="miembro" className="miembro"/>
                                    }
                                </div>
                                <div className="col Post-user-details">
                                    <div className="row m-0">
                                        <div className="col p-0" onClick={() => setUser()}>
                                            <div className="row m-0">
                                                <div className="col-12 p-0 Post-user-name text-block-truncate-1" title={props.item.NombreCompleto}>
                                                    {props.item.NombreCompleto}
                                                </div>

                                                {
                                                    props.item.Cargo || props.item.EmpresaNombreCorto ? (
                                                        <div className="col-12 p-0 Post-user-info">
                                                            {`${props.item.Cargo}${props.item.Cargo && props.item.EmpresaNombreCorto ? ' | ':''}${props.item.EmpresaNombreCorto ? props.item.EmpresaNombreCorto:''}`}
                                                        </div>
                                                    ):(null)
                                                }

                                                {
                                                    props.item.Estado || props.item.Pais ? (
                                                        <div className="col-12 p-0 Post-user-info">
                                                            {`${props.item.Estado}${props.item.Estado && props.item.Pais ? ', ':''}${props.item.Pais}`}
                                                        </div>
                                                    ):(null)
                                                }

                                                {
                                                    props.item.Fecha || props.item.TipoPublicacion ? (
                                                        <div className="col-12 p-0 Post-user-info">
                                                            {toolsHelper.timeSince(props.item.Fecha)}
                                                            {props.item.Fecha && props.item.TipoPublicacion ? ' | ':' '}
                                                            <span>{props.item.clv_tipopublicacion === 4 ?  props.item.CategoriaPublicacion : props.item.TipoPublicacion}</span>
                                                        </div>
                                                    ):(null)
                                                }
                                            </div>
                                        </div>
                                        <div className={`col p-0 Post-user-options ${props.isDetails ? 'isDetails':''}`}>
                                            <Dropdown>
                                                <Dropdown.Toggle>
                                                    <img src={icnMore} alt="options icon" width='30' height='30'/>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {showDownDownOptions()}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 Post-content">
                            <div className="row m-0" >
                                {
                                    props.item.Contenido ? ( 
                                        <React.Fragment>
                                            <div className={`col-12 px-0 Post-content-text ${showMore ? '':'line-clamp-w line-clamp-3-w'}`}
                                                id={props.item.clv_publicacion}
                                                style={ {fontSize: props.fontSize, lineHeight: `${props.lineHeight}px`}}
                                                onClick={goPostDetails}>
                                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                                    <a target="blank" href={decoratedHref} key={key} style={{color: '#0fb855', textDecoration: 'underline'}}>
                                                        {decoratedText}
                                                    </a>
                                                )}>
                                                    {renderMessageText(props.item)}
                                                </Linkify>
                                            </div>
                                            {
                                                isEllipsed ? (
                                                    <div className='col-12 px-0 show-more-btn'>
                                                        <button className='btn-cleared' onClick={() => {setShowMore(!showMore)}}>{showMore ? 'Ver menos':'Ver más'}</button>
                                                    </div>
                                                ):(null)
                                            }
                                        </React.Fragment>
                                    ):(null)

                                }
                            </div>
                        </div>

                        {
                            props.item.ImageUrl && (!props.item.Galeria || (props.item.Galeria && !props.item.Galeria.length)) ? (
                                <div className="col-12 p-0 Post-image-container" >
                                    <LazyImage src={props.item.ImageUrl}
                                        alt="Post img"
                                        placeholder={ ({imageProps, ref}) =>
                                            <img ref={ref} src={props.item.ImageThumbnailUrl} alt={imageProps.alt} className={`image-container`} onClick={goImageFullScreen}/>
                                        }
                                        actual={({imageProps}) =>
                                            <img {...imageProps}
                                                className={`image-container`}
                                                onClick={goImageFullScreen}
                                            />
                                        }
                                    />
                                    <a href={props.item.ImageUrl} target='_blank'>
                                        <img src={icnDownload} alt="donwload icon" className='image-icon'/>
                                    </a>
                                </div>
                            ):(null)
                        }

                        {
                            !imageFullScreen && props.item.Galeria && props.item.Galeria.length ? (
                                <div className="col-12 p-0 Post-image-container" >
                                    <OwlCarousel className='owl-theme'
                                        ref={galleryRef}
                                        margin={10}
                                        items={1}
                                        loop
                                        dots={props.item.Galeria.length > 1}
                                        autoplay={false}
                                        nav={false}
                                        startPosition={galleryPosition}
                                        onTranslated={(e) => {
                                                if (e && e.page) {
                                                    setGalleryPosition(e.page.index || 0);
                                                }
                                            }
                                        }>
                                        {showGalleryImages(true)}
                                    </OwlCarousel>
                                </div>
                            ):(null)
                        }

                        {
                            !props.item.ImageUrl && props.item.BrochureUrl ? (
                                <div className="col-12 p-0">
                                    <div className="Post-pdf-container">
                                        {
                                           // <Document
                                           //     file={props.item.BrochureUrl}
                                           //     onLoadSuccess={onDocumentLoadSuccess}
                                           //     renderMode={"canvas"}
                                           // >
                                           //     <Page pageNumber={pageNumber} />
                                           // </Document>
                                        }
                                        <img src={icnBrochure} alt="brochure icon"/>
                                        <a className='black-view' href={props.item.BrochureUrl} target='_blank'><div></div></a>
                                        <div className='document-span'>Ver documento</div>
                                    </div>
                                </div>
                            ):(null)
                        }

                        {
                            props.item.Reacciones || (props.item.Comments !== null && props.item.Comments.length >= 0) ? (
                                <div className="col-12 p-0">
                                    <div className="row m-0">
                                        <div className="col-6 Post-comments-count isLike" onClick={showLikesModal}>
                                            {
                                                props.item.Reacciones !== null && props.item.Reacciones > 0 ? (
                                                    <>
                                                        <img src={icnsReactions} alt="icnsReactions"/>
                                                        {`${props.item.Reacciones}`}
                                                    </>
                                                ):(null)
                                            }
                                        </div>

                                        <div className="col-6 Post-comments-count" onClick={goPostDetails}>
                                            {
                                                props.item.Comments !== null && props.item.Comments.length >= 0 ? (
                                                    <>
                                                        {`${props.item.Comments.length} ${props.item.Comments.length === 1 ? 'Comentario':'Comentarios'}`}
                                                    </>
                                                ):(null)
                                            }
                                        </div>

                                    </div>
                                </div>
                            ):(null)
                        }

                        <div className="col-12">

                            <div className="row m-0">
                                <div className="col-12 px-0 Post-footer">
                                    <button className={`btn-cleared likeBtn ${didIReacted() ? 'active':''}`} onClick={onLikeBtnTapped}>
                                        <img src={icnsReactions} alt="icnsReactions"/>
                                        <span>Me gusta</span>
                                    </button>
                                    <button className='btn-cleared' onClick={onCommentBtnTapped}>
                                        <img src={icnComment} alt="comment icon"/>
                                        <span>Comentar</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        openPostEditor && <AddPost edit={props.item} onSuccessEdit={onSuccessEdit} currentUser={currentUser} show={openPostEditor} onHide={e => toggleOpenModal(false)}/>
                    }

                    {
                        onReportPost && <ReportPost edit={props.item} show={onReportPost} onHide={e => setOnReportPost(false)} onSuccess={onReportSuccess}/>
                    }
                </div>


                {
                    imageFullScreen && props.item.ImageUrl && (!props.item.Galeria || (props.item.Galeria && !props.item.Galeria.length)) ?
                    (
                        <div className="overlay-image">
                            <img src={close} className="btn-close" onClick={() => closeFullScreen()}/>
                            <div className="image-full-screen">
                                <LazyImage src={ props.item.ImageUrl}
                                    alt="Post img"
                                    placeholder={ ({imageProps, ref}) =>
                                        <img ref={ref} src={ props.item.ImageThumbnailUrl} alt={imageProps.alt} onClick={goImageFullScreen}/>
                                    }
                                    actual={({imageProps}) =>
                                        <img {...imageProps}
                                            onClick={goImageFullScreen}
                                        />
                                    }
                                />
                            </div>
                        </div>

                    ):(null)
                }

                {
                    imageFullScreen && !props.item.ImageUrl && props.item.Galeria && props.item.Galeria.length ? (
                        <div className="overlay-image">
                            <img src={close} className="btn-close" onClick={() => closeFullScreen()}/>
                            <div className="image-full-screen">
                                <OwlCarousel className='owl-theme pos-relative' margin={10} items={1} loop dots={props.item.Galeria.length > 1} autoplay={false} nav={false}
                                    onTranslated={(e) => {
                                        if (e && e.page) {
                                            setGalleryPosition(e.page.index || 0);
                                        }
                                    }}
                                    startPosition={galleryPosition}>
                                    {showGalleryImages(false)}
                                </OwlCarousel>
                            </div>
                        </div>
                    ):(null)
                }

                {
                    isOpenLikesModal && getReactions() && <LikesModal reactions={getReactions()} onBottomSheetDimiss={el => setIsOpenLikesModal(false)}/>
                }

            </React.Fragment>
        );
    }
    return null;
}

export { Post };
