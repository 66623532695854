import React from 'react'
import { useHistory } from 'react-router-dom';
import { authenticationService, swalAlertsService } from '../../../../_resources/services';
import './LogoutSheet.scss';
export const LogoutSheet = () => {
    const history = useHistory();
    
    const logOut = () => {
        swalAlertsService.answer("", "¿Estas seguro de que quieres cerrar sesión?", "Si, cerrar sesión").then((result) => {
            if (result.isConfirmed) {
                authenticationService.logout().then((response) => {
                    history.replace('/welcome');
                    window.location.reload();
                });
            }
        })
    }

    return (
        <div className="LogoutSheet-container row m-0 d-flex justify-content-end">
        <h6 className='mb-0 logOut' onClick={logOut}>Cerrar sesión</h6>
        </div>
    )
}
