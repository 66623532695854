import { BehaviorSubject } from 'rxjs';
import React, { useState, useEffect } from 'react';
import {
    useHistory
} from "react-router-dom";
import './RegisterCreate.scss';
import { toolsHelper } from '../../../../../_resources/helpers';
import { swalAlertsService } from '../../../../../_resources/services';
import iconBack from '../../../../../_assets/media/images/icons/icn_back_arrow.png';
import icnMore from '../../../../../_assets/media/images/icons/icn_more.png';
import icnBullet from '../../../../../_assets/media/images/icons/icn_bullet.png';
import icnBulletActived from '../../../../../_assets/media/images/icons/icn_bullet_actived.png';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const infoVersion = new BehaviorSubject(JSON.parse(localStorage.getItem('infoVersion')));
const WSwal = withReactContent(Swal)

const RegisterCreate = (props) => {
    const history = useHistory();

    const [version, setVersion] = useState({})
    const [termPostState, setTermsPostState] = useState(false);

    const goToWelcome = () => {
        swalAlertsService.answer("", "Si regresas perderás los cambios que hayas hecho en esta pantalla.", "Si, regresar").then((result) => {
            if (result.isConfirmed) {
                history.push('/welcome');
            }
        })
    }

    const onTermsBtnTapped = () => {

        if (termPostState) {
            if (props.onTermsBtnTapped) {
                props.onTermsBtnTapped();
            }
        } else {
            swalAlertsService.showToast('error', "Debes aceptar los términos y condiciones");
        }
    }

    useEffect(() => {
        setVersion(infoVersion._value)

    }, []);

    return (

        <div className="RegisterCreate-container">

            <div className="row m-0">
                <div className="col-12 p-0">

                    <div className="row m-0 RegisterCreate-close-btn-container">
                        <div className="col-12 p-0">
                            <button className='btn-cleared RegisterCreate-close-btn' onClick={goToWelcome}>
                                <img src={iconBack} alt="close icon" />
                            </button>
                        </div>
                    </div>

                    <div className="row m-0 RegisterCreate-titles-box">
                        <div className="col-12 p-0 title">
                            ¿Estas de acuerdo?
                        </div>
                    </div>

                    <div className="row m-0 RegisterCreate-terms-container">
                        <div className="col-12 p-0">
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <a href={version && version.TermsUrl ? version.TermsUrl : null}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Términos y condiciones
                                        <img src={icnMore} alt="icon more"/>
                                    </a>
                                </li>
                                <li className="list-group-item">
                                    <a href={version && version.PrivacyUrl ? version.PrivacyUrl : null}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Política de privacidad
                                        <img src={icnMore} alt="icon more"/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>


            <div className="row m-0 RegisterCreate-interior-container">
                <div className="col-12 p-0">
                    <div className="row m-0 RegisterCreate-termscheck-container">
                        <div className="col p-0 check-col">
                            <button className='btn-cleared' onClick={e => setTermsPostState(!termPostState)}>
                                <img src={termPostState ? icnBulletActived:icnBullet} alt="bullet icon"/>
                            </button>
                        </div>
                        <div className="col pe-0">
                            <p className='mb-0'>
                                He leído, entendido y acepto los Términos y Condiciones de WeNet,
                                así como su Política de privacidad y por lo tanto, estoy de acuerdo en el uso y procesamiento de datos personales.
                            </p>
                        </div>
                    </div>

                    <div className="row m-0">
                        <div className="col-12 px-0 RegisterCreate-step-btn-box">
                            <button onClick={() => { onTermsBtnTapped() }}>
                                Crear cuenta
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export { RegisterCreate }
