import React, { useState, useEffect, useRef } from 'react';
import {EditCompanyHeader} from './edit-companu-header/EditCompanyHeader'
import placeholderuser from './../../../../_assets/media/images/icons/icn_user_placeholder.png';
import business from './../../../../_assets/media/images/icons/icn_company.png';
import web from './../../../../_assets/media/images/icons/icn_web.png';
import user from './../../../../_assets/media/images/icons/icn_user_name.png';
import birthday from './../../../../_assets/media/images/icons/icn_company_birthday.png';
import degree from './../../../../_assets/media/images/icons/icn_degree.png';
import study from './../../../../_assets/media/images/icons/icn_study.png'
import position from './../../../../_assets/media/images/icons/icn_position.png'
import more from './../../../../_assets/media/images/icons/icn_more.png';
import './EditCompany.scss'
import { profileService, swalAlertsService, authenticationService } from '../../../../_resources/services';
import { toolsHelper } from '../../../../_resources/helpers';
import { Filters, UniversalPicker } from '../../../shared';
import { useHistory, useLocation } from 'react-router-dom';
import DatePicker, { CalendarContainer, registerLocale, setDefaultLocale  } from "react-datepicker";
import { ImageCrop } from '../../../shared/image-crop/ImageCrop';
import es from 'date-fns/locale/es';



const EditCompany = (props) => {
    registerLocale('es', es);
    props.onToggleTab(true)
    const history = useHistory()
    const location = useLocation()
    const {id} = location.state
    const [openCamera, setOpenCamera] = useState(false);
    const [type, setType] = useState("")
    const [showFilters, setShowFilters] = useState(false);
    const [cargos, setCargos] = useState([])
    const [visibleCrop, setVisibleCrop] = useState(false)
    const [activeFilters, setActiveFilters] = useState({
        filtersSelected: null,
        postedBy: null,
        isUsingFilters: false
    });



    const [imageData, setImageData] = useState({
        base64: null,
        splitted: null
    });
    const [openUniversalPicker, setOpenUniversalPicker] = useState(false)
    const [fechaFormat, setFechaFormat] = useState(true)
    const [user, setUser] = useState({})
    const [userCopy, setUserCopy] = useState({})

    const inputImage = useRef(null);

    useEffect(() => {
        if(id !== 0 ) {
            getUser()
        }
        else {
            setUser({...user, Fundacion: new Date()})
        }
    }, [])

    const getUser = () => {
        profileService.getUser().then(
            (response) => {
                setUser(response.data)
                setUserCopy(response.data)
              },
            (error) => {
                console.log(error)
            }
        )
    }


    const saveUser = async(value) => {
        let loader = swalAlertsService.createLoading("Espere un momento...")
        loader.fire()
       if(id !== 0) {
        if(user.Empresa && user.EmpresaNombreCorto && /*user.URL &&*/ user.Descripcion
            && user.clv_giro && user.clv_clasificacion /*&& user.clv_estado*/) {

                if (user.OfreceDescuento && (user.PorcentajeDescuento === '' || user.PorcentajeDescuento === null || user.PorcentajeDescuento === undefined)) {
                    swalAlertsService.showToast("error", 'Debes ingregar un porcentaje de descuento');
                    return;
                }

                profileService.updateCompany(user).then(
                    (response) => {
                        if (response && response.data) {
                            authenticationService.getUser().then((user) => {
                                swalAlertsService.showToast("success", "Perfil de la empresa actualizado exitosamente");
                                history.goBack();
                            })
                        } else {
                            loader.close();
                        }
                    }
                )
                .catch(
                    (error) => {
                        loader.close()
                    }
                )
            }else {
                loader.close()
                let text = "Falta por llenar los siguientes datos: \n"
                if(!user.Empresa) {
                    text = text + "-Nombre completo de la empresa\n"
                }
                if(!user.EmpresaNombreCorto) {
                    text = text + "-Nombre corto de la empresa\n"
                }
                // if(!user.URL) {
                //     text = text + "-URL\n"
                // }
                if(!user.Descripcion) {
                    text = text + "-Descripción\n"
                }
                if(!user.clv_giro) {
                    text = text + "-Giro\n"
                }
                if(!user.clv_clasificacion) {
                    text = text + "-Clasificacion\n"
                }
                // if(!user.clv_estado) {
                //     text = text + "-Estado\n"
                // }

                swalAlertsService.showToast("error", text)
            }

       }else {
           if(user.Empresa && user.EmpresaNombreCorto && /*user.URL &&*/ user.Descripcion
                && user.clv_giro && user.clv_clasificacion /*&& user.clv_estado*/) {

                    if (user.Fundacion) {
                        const now = new Date();
                        const fundation = new Date(user.Fundacion);
                        const nowString = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
                        const fundationString = `${fundation.getFullYear()}-${fundation.getMonth() + 1}-${fundation.getDate()}`;
                        if (nowString === fundationString) {
                            user.Fundacion = null;
                        }
                    }
                    profileService.updateCompany(user).then(
                        (response) => {
                            profileService.updatePreferences(cargos).then(
                                (response) => {
                                    loader.close()
                                    history.replace("/register-steps", {id: 0})

                                },
                                (error) => {

                                }
                            )
                        }
                    )
                    .catch(
                        (error) => {
                            loader.close()
                        }
                    )
            } else {
                loader.close()
                let text = "Falta por llenar los siguientes datos: \n"
                if(!user.Empresa) {
                    text = text + "-Nombre completo de la empresa\n"
                }
                if(!user.EmpresaNombreCorto) {
                    text = text + "-Nombre corto de la empresa\n"
                }
                // if(!user.URL) {
                //     text = text + "-URL\n"
                // }
                if(!user.Descripcion) {
                    text = text + "-Descripcion\n"
                }
                if(!user.clv_giro) {
                    text = text + "-Giro\n"
                }
                if(!user.clv_clasificacion) {
                    text = text + "-Clasificacion\n"
                }
                // if(!user.clv_estado) {
                //     text = text + "-Estado\n"
                // }

                swalAlertsService.showToast("error", text)
            }
       }
    }

    const setImageUser = async(data) => {
        imageData.base64 = data
        setVisibleCrop(true)

    }

    const cut = async(image) => {
        var uri = URL.createObjectURL(image.blob);
        setUser({...user, "PhotoUrl": uri})
        setVisibleCrop(false)
        var reader = new FileReader();
        reader.readAsDataURL(image.blob);
        reader.onloadend = async function() {
            let loader = swalAlertsService.createLoading("Espere un momento...")
            var data = reader.result;
            loader.fire()
            const resp = await profileService.updateImageCompany(data.split(",")[1],3, "jpg")
            resp.text().then(text => {
                setUser({...user, LogoUrl: JSON.parse(text).message, FotoUser: true})
                loader.close()
            })
        }
    }

    const cancel = () => {
        setVisibleCrop(false)
    }


    const handleTakePhoto = (dataUri) => {

        if (openCamera) {
            setOpenCamera(false);
        }
        if (dataUri && dataUri.indexOf('base64,') >= 0) {
            const dataSplitted = dataUri.split(dataUri.substring(0, dataUri.indexOf('base64,') + 'base64,'.length));
            if (dataSplitted.length > 1) {
                setImageUser(dataUri)
            }
        }
    }

    const handleFile = (dataUri, extraData) => {

        if (dataUri && dataUri.indexOf('base64,') >= 0) {
            const dataSplitted = dataUri.split(dataUri.substring(0, dataUri.indexOf('base64,') + 'base64,'.length));

            if (dataSplitted.length > 1) {
                setImageData({
                    base64:dataUri,
                    splitted:dataSplitted[1]
                });
                console.log("entre")
                setImageUser()
            }
        }
    }

    async function getFile (event, type) {
        if (event.target && event.target.files && event.target.files.length) {
            const result = await toolsHelper.toBase64(event.target.files[0]).catch(e => console.log(e));

            if (result && type) {
                if (type === 'image') {
                    handleTakePhoto(result);
                } else {
                    handleFile(result, event.target.files[0]);
                }
            }
        }
    }

    const handleCameraError = (e) => {
        toolsHelper.showToast('error', 'Error al intentar usar la camara');
        console.log(e);
        swalAlertsService.showToast('error', 'Error al intentar usar la camara');

        setTimeout(() => {
            setOpenCamera(false);
        }, 500);
    }

    const setInputForm = (type, e) => {
        setFechaFormat(false)

        let value = e.target.value;
        if (type === 'PorcentajeDescuento') {
            value = e.target.value.replace(/\D/g,'');
            value = value > 100 ? 100:value;

            value =  value < 0 ? 0:value;
        }

        if(e && e.target && e.target.id && (e.target.id === 'tag1' || e.target.id === 'tag2') ){
            if( e.target.value.length < 20){
                setUser({...user, [type]: value})
            }
        }else{
            setUser({...user, [type]: value})
        }

    }

    const getDate = () => {
        let month = new Date(user.Fundacion).getMonth() + 1
        let day = new Date(user.Fundacion).getDay() + 1
        if(month < 9) {
            month = `0${month}`
        }
        if(day < 9) {
            day = `0${day}`
        }
        console.log(day+"-"+month+"-"+new Date(user.FechaNacimiento).getFullYear().toString())
        return (new Date(user.FechaNacimiento).getFullYear()+"-"+month+"-"+day).toString()
    }

    const setBottom = (type) => {
        if(type === "state") {
            if(user.Pais) {
                setType(type)
                setOpenUniversalPicker(true)
            }else {
                swalAlertsService.showToast("error", "Debes seleccionar un pais")

            }
        }else {
            setType(type)
            setOpenUniversalPicker(true)
        }
    }

    const onUniverlPickerHide = (data) => {
        setOpenUniversalPicker(data)
    }

    const onElementSelected = (element) => {
        console.log(element)
        switch(type) {
            case 'giro':
                console.log(element.clv_cargo)
                setUser({...user, "Giro": element.Giro, "clv_giro": element.clv_giro})
                break;
            case 'range':
                setUser({...user, "clv_rangoempleados": element.clv_rangoempleados, "RangoEmpleados": element.RangoEmpleados})
                break;
            case 'category':
                setUser({...user, "clv_clasificacion": element.clv_clasificacion, "Clasificacion": element.Clasificacion})
                break;
            case 'country':
                setUser({...user, "clv_pais": element.clv_pais, "Pais": element.Pais})
                break;
            case 'state':
                setUser({...user, "clv_estado": element.clv_estado, "Estado": element.Estado})
                break;
            default:
                break;
        }
    }

    const onFilterDismiss = () => {
        setShowFilters(false);
    }

    const selectFilters = (data) => {
        console.log(data)
        var cargos = []
        data.map( (cargo) =>
            cargos.push(JSON.parse(`{"clv_cargo": ${cargo.clv_cargo}, "Activo": true}`))

        )
        setCargos(cargos)
    }

    const back = () => {

        if(user === userCopy) {
            history.goBack()
        }else {
            swalAlertsService.answer("¿Seguro que quieres salir?", "Si sales se perderan tus cambios", "Si, regresar").then((result) => {
                if (result.isConfirmed) {
                    history.goBack()
                }
            })
        }
    }


    return (

        <React.Fragment>

            {   !visibleCrop &&
                (
                    <div className="container_edit_company">
                        <EditCompanyHeader save={saveUser} back={back}/>
                        <div className="container_edit_company_photo">
                            <img src={user.LogoUrl != null ? user.LogoUrl : placeholderuser} width="120px" height="120px" />
                                <input
                                    id="image"
                                    type="file"
                                    ref={inputImage}
                                    onChange={event => getFile(event, 'image')}
                                    onError={error => console.log(error)}
                                    accept="image/png, image/gif, image/jpeg"
                                    multiple={false}
                                    name="image"
                                />
                            <label htmlFor="image">Cambiar logo de la empresa</label>
                        </div>

                        <div className="container_edit_company_input">
                            <p>Nombre completo de la empresa</p>
                            <div className="container_edit_company_input_field" >
                                <img src={business}/>
                                <input type="text" value={user.Empresa} onChange={e => setInputForm("Empresa",e)}/>
                            </div>
                        </div>

                        <div className="container_edit_company_input">
                            <p>Nombre corto de la empresa</p>
                            <div className="container_edit_company_input_field" >
                                <img src={business} />
                                <input type="text" value={user.EmpresaNombreCorto} onChange={e => setInputForm("EmpresaNombreCorto",e)}/>
                            </div>
                        </div>

                        <div className="container_edit_company_input">
                            <p>Sitio web de la empresa</p>
                            <div className="container_edit_company_input_field" >
                                <img src={web} />
                                <input type="text" value={user.URL} onChange={e => setInputForm("URL",e)}/>
                            </div>
                        </div>

                        <div className="container_edit_company_input">
                            <p>¿En qué año se fundó la empresa?</p>
                            <div className="container_edit_company_input_field" >
                                <img src={birthday} className="left datepicker-icn"/>

                                <DatePicker
                                    selected={user.Fundacion ? new Date(user.Fundacion) : ''}
                                    onChange={(date) => setUser({...user, "Fundacion": date})}
                                    dateFormat="dd-MM-yyy"
                                    showYearDropdown={true}
                                    showMonthDropdown={true}
                                    disabledKeyboardNavigation={true}
                                    placeholderText="Selecciona una fecha" 
                                    locale="es"
                                    dropdownMode='select'/>
                            </div>
                        </div>

                        <div className="container_edit_company_input">
                            <h3 className="salto">Cúentanos más sobre tu empresa</h3>
                            <p>Descripción de la empresa</p>
                            <div className="container_edit_company_input_field" >
                                <textarea type="text" rows="5" cols="42" width="100%" placeholder="Escribe una breve descripción de la empresa" value={user.Descripcion} onChange={e => setInputForm("Descripcion",e)}/>
                            </div>
                        </div>


                        <div className="container_edit_company_input">
                            <h3 className="salto">Tipo de actividad de la empresa</h3>
                            <p>¿Cuál es el giro?</p>
                            <div className="container_edit_company_input_field" >
                                <button className={`field-btn ${user.Giro != "" ? 'black' : ''}`} onClick={() => setBottom("giro")}>{user.Giro != null ? user.Giro : "Selecciona una opción"}</button>
                                <img src={more} className="right"/>
                            </div>
                        </div>

                        <div className="container_edit_company_input">
                            <p>Elige una categoría</p>
                            <div className="container_edit_company_input_field" >
                                <button className={`field-btn ${user.Clasificacion != "" ? 'black' : ''}`} onClick={() => setBottom("category")}>{user.Clasificacion != null ? user.Clasificacion : "Selecciona una opción"}</button>
                                <img src={more} className="right"/>
                            </div>
                        </div>

                        <div className="container_edit_company_gender">
                            <h3 className="salto">Etiquetas de búsqueda</h3>
                            <p>Para que puedan encontrar a tu empresa otros usuarios escribe 2 palabras claves.</p>
                            <div className="container_edit_company_gender_container">
                                <div className="container_edit_company_gender_container_option">
                                    <div className="container_edit_company_gender_container_option_field h" >
                                        <input type="text" placeholder="Ej. Alimentos" value={user.Tag1} id='tag1' className={user.Tag1!= "" ? 'black' : ''} onChange={e => setInputForm("Tag1",e)}/>
                                    </div>
                                </div>
                                <div className="container_edit_company_gender_container_option">
                                    <div className="container_edit_company_gender_container_option_field m" >
                                        <input type="text" placeholder="Ej. Eccomerce" value={user.Tag2}  id='tag2' className={user.Tag2 != "" ? 'black' : ''} onChange={e => setInputForm("Tag2",e)}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container_edit_company_input">
                            <h3 className="salto">¿Cúal es la cantidad de empleados que trabajan en la empresa?</h3>
                            <div className="container_edit_company_input_field" >
                                <button className={`field-btn ${user.RangoEmpleados != "" ? 'black' : ''}`} onClick={() => setBottom("range")}>{user.RangoEmpleados != null ? user.RangoEmpleados +  " empleados" : "Selecciona una opción"}</button>
                                <img src={more} className="right"/>
                            </div>
                        </div>


                        <div className="container_edit_company_input">
                            <h3 className="salto">Ubicación de la empresa</h3>
                            <p>País</p>
                            <div className="container_edit_company_input_field" >
                                <button className={`field-btn ${user.Pais != "" ? 'black' : ''}`} onClick={() => setBottom("country")}>{user.Pais!= null ? user.Pais : "Selecciona una opción"}</button>
                                <img src={more} className="right"/>
                            </div>
                        </div>

                        <div className="container_edit_company_input">
                            <p>Estado</p>
                            <div className="container_edit_company_input_field" >
                                <button className={`field-btn ${user.Estado != "" ? 'black' : ''}`} onClick={() => setBottom("state")}>{user.Estado != null ? user.Estado : "Selecciona una opción"}</button>
                                <img src={more} className="right"/>
                            </div>
                        </div>

                        <div className="container_edit_company_input">
                            <h3 className="salto">Descuento para miembros WeNet</h3>
                            <p>¿Podrías ofrecer algún descuento a miembros WeNet?</p>
                            <div className="container_edit_user_gender_container blue px-0">
                                <div className="container_edit_user_gender_container_option">
                                    <div className={`container_edit_user_gender_container_option_field h ${!user.OfreceDescuento ? 'active' : ''}`}>
                                        <button className="field-btn blue" onClick={() => {setUser({...user, OfreceDescuento: false})}}>No</button>
                                    </div>
                                </div>
                                <div className="container_edit_user_gender_container_option">
                                    <div className={`container_edit_user_gender_container_option_field m  ${user.OfreceDescuento ? 'active' : ''}`}>
                                        <button className="field-btn blue" onClick={() => {setUser({...user, OfreceDescuento: true})}}>Si</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            user.OfreceDescuento ? (
                                <>
                                    <div className="container_edit_company_input">
                                        <p>¿De qué porcentaje?</p>
                                        <div className="container_edit_user_gender_container blue px-0">
                                            <div className="container_edit_user_gender_container_option">
                                                <div className="container_edit_company_gender_container_option_field m" >
                                                    <input type="text" className='percent' placeholder='0%' value={user.PorcentajeDescuento} onChange={e => setInputForm("PorcentajeDescuento",e)}/>
                                                    {
                                                        user.PorcentajeDescuento !== '' && user.PorcentajeDescuento !== null && user.PorcentajeDescuento !== undefined ? (
                                                            <span className="percentSymbol noselect">%</span>
                                                        ):(null)
                                                    }
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container_edit_company_input">
                                        <p>Comentarios</p>
                                        <div className="container_edit_company_input_field" >
                                            <textarea type="text" rows="5" cols="42" width="100%" value={user.ComentariosDescuento} onChange={e => setInputForm("ComentariosDescuento",e)}/>
                                        </div>
                                    </div>
                                </>
                            ):(null)
                        }


                        {
                            id === 0 ?
                                <div>
                                    <div className="container_edit_company_input pb-4">
                                        <h3 className="salto">Preferencias</h3>
                                        <p>Personaliza tus intereses y únete grupos especializados. Modifica tus preferencias en cualquier momento.</p>
                                        <p>Interactuar solo con personas de: </p>
                                        <div className="filters">
                                            <Filters className="puesto" window={"register"} showSubmit={false} onDismiss={onFilterDismiss} onSubmit={false} activeFilters={activeFilters} hidePostedBy={true} showHeader={true} selectFilters={selectFilters} hideModal={true}/>
                                        </div>
                                    </div>
                                </div>
                            : ""
                        }


                        <button className="btn-save" onClick={() => saveUser(true)}>Guardar</button>


                    </div>
                )
            }
            {
                    openUniversalPicker ? (
                    <UniversalPicker mode={type} country={user.Pais} onDismiss={onUniverlPickerHide} onElementSelected={onElementSelected}  />
                    ):(null)
            }
            {
                visibleCrop &&
                <ImageCrop src={imageData.base64} cutCrop={cut} cancelCrop={cancel} text={""}/>
            }
        </React.Fragment>

    )
}

export {EditCompany}
