import React, { useState, useEffect } from 'react';

import './FilterTable.scss';
import { infoService } from '../../../../../_resources/services';

import { FilterRadioCell, FilterTagCell } from './filter-cells';

const FilterTable = (props) => {
    const [catalogs, setCatalogs] = useState(null);
    const [hidePostedBy, setHidePostedBy] = useState(false);
    const [hideTags, setHideTags] = useState(false);

    const [activeFilters, setActiveFilters] = useState({
        filtersSelected: null,
        postedBy: null,
        isUsingFilters: false
    });


    const onPostedBySelected = (element) => {
        let activeFiltersCP = JSON.parse(JSON.stringify(activeFilters));
        activeFiltersCP.postedBy = element;
        setActiveFilters(activeFiltersCP);
    }

    useEffect(() => {
        if (props.catalogs) {
            setCatalogs(props.catalogs);
        }

        if (props.activeFilters) {
            setActiveFilters(props.activeFilters);
        }

        if ('hidePostedBy' in props) {
            setHidePostedBy(props.hidePostedBy);
        }

        if ('hideTags' in props) {
            setHideTags(props.hideTags);
        }
    }, []);

    useEffect(() => {
        if (props.activeFilters) {
            setActiveFilters(props.activeFilters);
        }
    }, [props.activeFilters]);

    useEffect(() => {
        if (activeFilters.postedBy &&
            (
                (
                    props.activeFilters.postedBy &&
                    activeFilters.postedBy.value !== props.activeFilters.postedBy.value
                ) ||
                !props.activeFilters.postedBy
            ) &&
            props.onPostedBySelected
        ) {
            props.onPostedBySelected(activeFilters.postedBy);
        }
    }, [activeFilters.postedBy]);

    useEffect(() => {
        if (activeFilters.postedBy &&
            (
                (
                    props.activeFilters.postedBy &&
                    activeFilters.postedBy.value !== props.activeFilters.postedBy.value
                ) ||
                !props.activeFilters.postedBy
            ) &&
            props.onPostedBySelected
        ) {
            props.onPostedBySelected(activeFilters.postedBy);
        }
    }, [activeFilters.filtersSelected]);

    const onTagDelete = (data) => {
        if (data && props.onTagDelete) {
            props.onTagDelete(data);
        }
    }

    const onFilterPickerBtnTapped = (data) => {
        if (props.onFilterPickerBtnTapped) {
            props.onFilterPickerBtnTapped(data);
        }
    }

    const postedByItems = () => {
        const obj = [
            { title: 'Todos', value: 1 },
            { title: 'Mis publicaciones', value: 2 }
        ];

        const items = obj.map((element, index) => {
            const selected = !activeFilters.postedBy && index === 0 || activeFilters.postedBy && activeFilters.postedBy.value === element.value ? true : false;
            return <FilterRadioCell key={index} item={element} onElementSelected={onPostedBySelected} selected={selected} />
        });

        return (
            <div className="col-12 p-0">
                <div className="row m-0">
                    <div className="col-12 m-0 FilterTable-cell-header">
                        Visualizar el contenido publicado por
                    </div>
                </div>
                <div className="row m-0">
                    {items}
                </div>
            </div>
        );
    }

    const getCatalogSelectedItem = (key) => {
        let value = [];
        if (activeFilters.filtersSelected && activeFilters.filtersSelected[key]) {
            value = activeFilters.filtersSelected[key];

        }
        return value;
    }

    const renderAllTags = () => {
        if (props.showAllTags) {
            const tags = [
                { keyValue: 'Giros', title: 'Giro' },
                { keyValue: 'Clasificaciones', title: 'Clasificación' },
                { keyValue: 'Paises', title: 'Ubicación' },
                { keyValue: 'Aficiones', title: 'Afición deportiva' },
                { keyValue: 'Asociacion', title: 'Asociación' },
            ]
            return tags.map((tag, index) => {
                return <FilterTagCell title={tag.title}
                    keyValue={tag.keyValue}
                    items={getCatalogSelectedItem(tag.keyValue)}
                    onTagDelete={onTagDelete}
                    onFilterPickerBtnTapped={onFilterPickerBtnTapped}
                    key={index}
                />
            });

        }
        return null;
    }
    return (
        <div className="row m-0 h-100s FilterTable-container">
            {!hidePostedBy && <>{postedByItems()}</>}
            {!hideTags &&
                <FilterTagCell title='Puesto'
                    keyValue='Cargos'
                    items={getCatalogSelectedItem('Cargos')}
                    onTagDelete={onTagDelete}
                    onFilterPickerBtnTapped={onFilterPickerBtnTapped}
                />
            }
            {renderAllTags()}
        </div>
    );
}
export { FilterTable }
