import React, { useState, useEffect } from 'react';

import './SettingsSide.scss';

import icnMore from '../../../../../_assets/media/images/icons/icn_more.png';

const SettingsSide = (props) => {

    const [options, setOptions] = useState([]);

    useEffect(() => {
        let isSuscribed = true;
        let items = [
            {name: 'Filtro permanente de publicaciones', key: 'preferences'},
            {name: 'Bloqueo permanente de usuarios', key: 'bloqued_users'},
            {name: 'Silencio permantente de usuarios', key: 'silence_users'},
            {name: 'Cambiar contraseña', key: 'new_password'},
            {name: 'Administrar notificaciones', key: 'notifications'},
            {name: 'Soporte', key: 'support'},
            {name: '', key: 'spacer'},
            {name: 'Preguntas frecuentes', key: 'faq', isLink: true, url: props.FaqUrl},
            {name: 'Términos y Condiciones', key: 'terms', isLink: true, url: props.TermsUrl},
            {name: 'Aviso de privacidad', key: 'privacy', isLink: true, url: props.PrivacyUrl},
            {name: 'Reglas de uso', key: 'privacy', isLink: true, url: props.RulesUrl},
            {name: '', key: 'spacer'},
            {name: 'Cerrar sesión', key: 'logout'},
            {name: 'Eliminar mi cuenta', key: 'deleteAccount'}
        ];

        let vrsion = localStorage.getItem('infoVersion');

        if (vrsion) {
            try {
                vrsion = JSON.parse(vrsion);
                if (vrsion.FaqUrl) {
                    items[6].url = vrsion.FaqUrl;
                }

                if (vrsion.TermsUrl) {
                    items[7].url = vrsion.TermsUrl;
                }

                if (vrsion.PrivacyUrl) {
                    items[8].url = vrsion.PrivacyUrl;
                }

                if (vrsion.RulesUrl) {
                    items[9].url = vrsion.RulesUrl;
                }
            } catch (e) {
            }
        }

        if (isSuscribed) {
            setOptions(items);
        }

        return () => isSuscribed = false
    }, []);

    useEffect(() => {

    }, [props]);

    const onOptionTapped = (key) => {
        if (props.onOptionTapped && key && props.optionActive && key !== props.optionActive) {
            props.onOptionTapped(key);
        }
    }

    const renderFilterItems = () => {
        if (options && options.length) {
            return options.map((item, index) => {
                if (item.isLink && item.url) {
                    return (
                        <a href={item.url} target='_blank' className='p-0'>
                            <div className={`col-12 p-0 SettingsSide-filters-item`} key={index}>
                                <div className="row m-0">
                                    <div className="col-12 SettingsSide-filters-header noselect">
                                        <span className='titleSpan line-spacing '>{item.name}</span>
                                        {
                                            item.key !== 'logout' ? (
                                                <img src={icnMore} alt="icnMore" className='titleImg'/>
                                            ):(null)
                                        }
                                    </div>
                                </div>
                            </div>
                        </a>
                    );
                } else if (item.key !== 'spacer') {
                    return (
                        <div className={`col-12 p-0 SettingsSide-filters-item ${props.optionActive && props.optionActive === item.key ? 'active':''}`} key={index} onClick={e => onOptionTapped(item.key)}>
                            <div className="row m-0">
                                <div className="col-12 SettingsSide-filters-header noselect">
                                    <span className='titleSpan'>{item.name}</span>
                                    {
                                        item.key !== 'logout' &&  item.key !== 'deleteAccount' ? (
                                            <img src={icnMore} alt="icnMore" className='titleImg'/>
                                        ):(null)
                                    }
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="col-12 p-0 SettingsSide-filters-item spacer" key={index}>
                        </div>
                    );
                }

            });
        }
        return null;
    }

    return (
        <div className="SettingsSide-container row m-0">
            <div className="col-12 p-0">
                <div className="row m-0">
                    <div className="col-12 SettingsSide-title-box">
                        Configuración
                    </div>
                </div>
                <div className="row m-0 SettingsSide-filters-container">

                    {renderFilterItems()}

                </div>
            </div>
        </div>
    );
}

export { SettingsSide }
