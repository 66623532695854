
import { BehaviorSubject } from 'rxjs';
import { authHeader, handleResponse } from '../helpers';
import md5 from 'md5';
import { swalAlertsService } from './swal-alerts.service';

const apiPath = process.env.REACT_APP_API_PATH;
export const membershipService = {
    newCard,
    getMembership,
    updateCard
};


function newCard(data) {
    let body = {
        "clv_metodopago": data.clv_metodopago,
        "clv_membresia": data.clv_membresia,
        "token_id": data.token_id
    }
   const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    };

    return fetch(`${apiPath}user/membership`, requestOptions)
}


function updateCard(token) {
    let body = {
        "token_id": token
    }
   const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(body)
    };

    return fetch(`${apiPath}user/card`, requestOptions)
}


function getMembership() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiPath}membership`, requestOptions)
            .then(response => handleResponse(response, false, false))
            .then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return user;
            }, (error) => {
                return error;
            });

}
