import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './Header.scss';

import { useDidMountEffect } from '../../../_resources/helpers';
import { profileService, authenticationService } from '../../../_resources/services';

import icnSearch from '../../../_assets/media/images/icons/icn_search.png';

import icnTabHome from '../../../_assets/media/images/icons/icn_tab_home.png';
import icnTabHomeActive from '../../../_assets/media/images/icons/icn_tab_home_actived.png';
import icnTabMessages from '../../../_assets/media/images/icons/icn_tab_messages.png';
import icnTabMessagesActive from '../../../_assets/media/images/icons/icn_tab_messages_actived.png';
import icnTabDirectory from '../../../_assets/media/images/icons/icn_tab_directory.png';
import icnTabDirectoryActive from '../../../_assets/media/images/icons/icn_tab_directory_actived.png';
import icnTabProfile from '../../../_assets/media/images/icons/icn_tab_profile.png';
import icnTabProfileActive from '../../../_assets/media/images/icons/icn_tab_profile_actived.png';
import icnNotificationDefault from '../../../_assets/media/images/icons/icn_notification_default.png';
import icnNotificationActive from '../../../_assets/media/images/icons/icn_notification_active.png';

import icnCancel from '../../../_assets/media/images/icons/icn_cancel.png';

import logoWenet from '../../../_assets/media/images/logos/logo_wenet_color2.png';

const Header = (props) => {
    const history = useHistory();
    const [placeholderTitle, setPlaceholderTitle] = useState('Buscar en');
    const [activeLink, setActiveLink] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [notifications, setNotifications] = useState([]);
    const [bellNotRead, setBellNotRead] = useState(0);

    const onClearSeach = () => {
        setSearchTerm('');
    }

    const onSearchTerm = () => {
        if (props.onSearchTerm) {
            props.onSearchTerm(searchTerm);
        }
    }

    const goTo = (link) => {
        const currentUser = localStorage.getItem('currentUser');
        if (currentUser) {
            authenticationService.getUser().then(() => {
                if (notifications && notifications.length) {
                    if (showDot(link)) {
                        markAsReaded(link);
                    } else {
                        history.replace(`/${link}`);
                    }
                } else {
                    history.replace(`/${link}`);
                }
            });
        } else {
            authenticationService.logout().then((response) => {
                history.replace('/welcome');
            });
        }
    }

    async function markAsReaded(link) {
        let obj = [];

        if (notifications && notifications.length) {
            if (link === 'home') {
                let notificationsFiltered = notifications.filter(el => el.clv_tiponotificacion === 2 && el.NoLeido > 0);
                let notificationsFiltered2 = notifications.filter(el => el.clv_tiponotificacion === 3 && el.NoLeido > 0);

                if (notificationsFiltered.length) {
                    obj.push({clv_tiponotificacion: notificationsFiltered[0].clv_tiponotificacion});
                }
                if (notificationsFiltered2.length) {
                    obj.push({clv_tiponotificacion: notificationsFiltered2[0].clv_tiponotificacion});
                }
            } else if (link === 'messages') {
                let notificationsFiltered = notifications.filter(el => el.clv_tiponotificacion === 1 && el.NoLeido > 0);

                if (notificationsFiltered.length) {
                    obj.push({clv_tiponotificacion: notificationsFiltered[0].clv_tiponotificacion});
                }
            } else if (link === 'profile') {
                let notificationsFiltered = notifications.filter(el => el.clv_tiponotificacion === 1 && el.NoLeido > 0);

                if (notificationsFiltered.length) {
                    obj.push({clv_tiponotificacion: notificationsFiltered[0].clv_tiponotificacion});
                }
            } else if (link === 'notifications') {
                let notificationsFiltered = notifications.filter(el => el.clv_tiponotificacion === 6 && el.NoLeido > 0);
                let notificationsFiltered2 = notifications.filter(el => el.clv_tiponotificacion === 7 && el.NoLeido > 0);
                // let notificationsFiltered3 = notifications.filter(el => el.clv_tiponotificacion === 9 && el.NoLeido > 0);

                if (notificationsFiltered.length) {
                    obj.push({clv_tiponotificacion: notificationsFiltered[0].clv_tiponotificacion});
                }
                if (notificationsFiltered2.length) {
                    obj.push({clv_tiponotificacion: notificationsFiltered2[0].clv_tiponotificacion});
                }
                // if (notificationsFiltered3.length) {
                //     obj.push({clv_tiponotificacion: notificationsFiltered3[0].clv_tiponotificacion});
                // }
            }

            if (obj.length) {
                await Promise.all(
                    obj.map((el) => {
                        return profileService.postGeneric('notification/read', 'POST', el);
                    })
                );

                history.replace(`/${link}`);
            } else {
                history.replace(`/${link}`);
            }
        }
    }

    const showDot = (option) => {
        if (option === activeLink) {
            return null;
        }

        if (notifications && notifications.length) {
            let value = false;

            if (option === 'home') {
                let notificationsFiltered = notifications.filter(el => el.clv_tiponotificacion === 2 && el.NoLeido > 0);
                let notificationsFiltered2 = notifications.filter(el => el.clv_tiponotificacion === 3 && el.NoLeido > 0);
                value = notificationsFiltered.length || notificationsFiltered2.length ? true:false;
            } else if (option === 'messages') {
                let notificationsFiltered = notifications.filter(el => el.clv_tiponotificacion === 1 && el.NoLeido > 0);
                value = notificationsFiltered.length ? true:false;
            } else if (option === 'profile') {
                let notificationsFiltered = notifications.filter(el => el.clv_tiponotificacion === 5 && el.NoLeido > 0);
                value = notificationsFiltered.length ? true:false;
            } else if (option === 'notifications') {
                    if(bellNotRead && bellNotRead > 0){
                        value = true;
                    }else{
                        value = false;
                    }
            }

            if (value) {
                return <span className='dotSpan'></span>;
            }
        }


        return null;
    }

    useEffect(() => {
        let isSuscribed = true;

        if (props.title && isSuscribed) {
            setPlaceholderTitle(`Buscar en ${props.title}`);
        }

        if (props.activeLink && isSuscribed) {
            setActiveLink(props.activeLink);
        }

        let cUser = localStorage.getItem('currentUser');

        if (cUser) {
            getUnread(isSuscribed);
        }


        setInterval(() => {
            if (isSuscribed) {
                let cUser2 = localStorage.getItem('currentUser');
                if (cUser2) {
                    profileService.getGeneric('notification/unread').then((response) => {
                        if (response && response.data && isSuscribed) {
                            setNotifications(response.data);
                        }
                    });
                }

            }
        }, 1000 * 30)
        return () => isSuscribed = false
    }, []);

    useEffect(() => {
        if(props && props.updateShowDot){
            getUnread(true);
            props.endMessage();
        }
    }, [props])
    

    useDidMountEffect(() => {
        onSearchTerm();
    }, [searchTerm]);


    const getUnread = (isSuscribed)=>{
        profileService.getGeneric('notification/unread').then((response) => {
            if (response && response.data && isSuscribed) {
                setNotifications(response.data);
            }
        });

        profileService.getGeneric('version').then((response) => {
            if (response && response.data && isSuscribed) {
                setBellNotRead(response.NotRead);
            }
        });
    }

    return (
        <div className="Header-container">
            <div className="row m-0 d-flex align-items-center h-100 Header-row-container">
                <div className="col Header-logo-container">
                    <img src={logoWenet} alt="logoWenet"/>
                </div>

                <div className="col Header-options-container">
                    <div className="row m-0 d-flex align-items-center justify-content-between">
                        <div className="col Header-option-item">
                            <button className={`btn-cleared ${activeLink === 'home' ? 'active':''}`} onClick={e => goTo('')}>
                                {showDot('home')}
                                <img src={activeLink === 'home' ? icnTabHomeActive:icnTabHome} alt="icnTabHome"/>
                                <span className='titleSpan'>Inicio</span>
                            </button>
                        </div>
                        <div className="col Header-option-item">
                            <button className={`btn-cleared ${activeLink === 'messages' ? 'active':''}`} onClick={e => goTo('messages')}>
                                {showDot('messages')}
                                <img src={activeLink === 'messages' ? icnTabMessagesActive:icnTabMessages} alt="icnTabMessages"/>
                                <span className='titleSpan'>Mensajes</span>
                            </button>
                        </div>
                        <div className="col Header-option-item">
                            <button className={`btn-cleared ${activeLink === 'directory' ? 'active':''}`} onClick={e => goTo('directory')}>
                                {showDot('directory')}
                                <img src={activeLink === 'directory' ? icnTabDirectoryActive:icnTabDirectory} alt="icnTabDirectory"/>
                                <span className='titleSpan'>Directorio</span>
                            </button>
                        </div>
                        <div className="col Header-option-item">
                            <button className={`btn-cleared ${activeLink === 'profile' ? 'active':''}`} onClick={e => goTo('profile')}>
                                {showDot('profile')}
                                <img src={activeLink === 'profile' ? icnTabProfileActive:icnTabProfile} alt="icnTabProfile"/>
                                <span className='titleSpan'>Perfil</span>
                            </button>
                        </div>
                        <div className="col Header-option-item">
                            <button className={`btn-cleared ${activeLink === 'notifications' ? 'active':''}`} onClick={e => goTo('notifications')}>
                                {showDot('notifications')}
                                <img src={activeLink === 'notifications' ? icnNotificationActive:icnNotificationDefault} alt="icnNotificationDefault"/>
                                <span className='titleSpan'>Notificaciones</span>
                            </button>
                        </div>
                    </div>
                </div>


                <div className="col Header-searcher-container">
                    {
                        !props.hideSearcher ? (
                            <>
                                <label htmlFor='header-input'>
                                    <img src={icnSearch} alt="icnSearch"/>
                                </label>
                                <input type="text" id='header-input' name='header-input' placeholder={placeholderTitle} value={searchTerm} onChange={e => setSearchTerm(e.target.value)}/>
                                {
                                    searchTerm && (
                                        <button className='btn-cleared' onClick={e => onClearSeach()}>
                                            <img src={icnCancel} alt="search icon"/>
                                        </button>
                                    )
                                }
                            </>
                        ):(null)
                    }

                </div>
            </div>
        </div>
    );
};

export { Header };
