import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { profileService, swalAlertsService } from '../../../../_resources/services';
import { toolsHelper } from '../../../../_resources/helpers/';

import './NotificationsSheet.scss';


import icnsNotification from '../../../../_assets/media/images/icons/icns_notification.png';
import icnPlaceHolder from "../../../../_assets/media/images/icons/icn_user_placeholder.png";
import icnClose from "../../../../_assets/media/images/icons/icn_close.png";
import { ModalDefault } from '../../modal-default/ModalDefault';

const NotificationsSheet = (props) => {
    const history = useHistory();
    const [notificationsList, setNotificationsList] = useState([]);
    const [modalActive, setModalActive] = useState(false);
    const [userRefeal, setUserRefeal] = useState(null);

    const onNotificationTapped = (item) => {
        console.log(item);
        if (item && item.Parametro) {
            if (item.clv_tiponotificacion === 6) {
                history.push(`/notification/${item.Parametro}`, { from: '/home' })
            } else if (item.clv_tiponotificacion === 7) {
                history.push(`/notification-birthday/${item.Parametro}`, { from: '/home' })
            } else if (item.clv_tipoaccion === 8) {
                prepareModal(item);
            } else if (item.clv_tipoaccion === 2 || item.clv_tipoaccion === 9) {
                console.log('action push');
                history.push(`/post/${item.Parametro}`);
            }else if (item.clv_tipoaccion === 4) {
                history.push(`/talent/${item.Parametro}`);
            }
        }
    }

    useEffect(() => {
        let isSuscribed = true;

        profileService.getGeneric('notification').then((response) => {
            if (isSuscribed && response && response.data && response.data.length) {
                let items = response.data;

                if (items.length > 1) {
                    items = items.sort((a, b) => {
                        const dateA = new Date(a.Fecha);
                        const dateB = new Date(b.Fecha);
                        return dateB.getTime() - dateA.getTime()
                    });
                }

                setNotificationsList(items);
            }
        });

        return () => isSuscribed = false
    }, []);

    const onViewAll = () => {
        history.push(`/notifications`, { from: '/home' });
    }

    const prepareModal = (item) => {
        profileService.getUserProfile(item.Parametro).then(
            (response) => {
                if (response && response.data) {
                    setUserRefeal(response.data);
                    setModalActive(true);
                }
            }
        )
    }

    const actionButton = (status) => {
        const objSend = {
            clv_usuario: userRefeal.clv_usuario,
            clv_estatusreferido: status
        }

        profileService.postGeneric('user/referal', 'put', objSend).then((response) => {
            if (response) {
                swalAlertsService.showToast('success', response.message);
                setModalActive(false);
            }
        })
    }

    const mainRender = () => {
        if (notificationsList.length) {
            return (
                <div className="NotificationsSheet-result-container col-12 px-0 mouse-pointer">
                    <div className="row m-0">
                        <div className="col-12 NotificationsSheet-top-badge" onClick={e => onNotificationTapped(notificationsList[0])}>{notificationsList[0].Fecha ? toolsHelper.timeSince(notificationsList[0].Fecha, true) : ''}</div>
                        <div className="col-12" onClick={e => onNotificationTapped(notificationsList[0])}>
                            <div className="row m-0">
                                <div className="col NotificationsSheet-icon-box">
                                    <img src={icnsNotification} alt="icnsNotification" />
                                </div>
                                <div className="col pe-0 NotificationsSheet-body-container">
                                    <div className="row m-0">
                                        <div className="col-12 px-0 NotificationsSheet-titleBox text-truncate" style={{ maxWidth: '315px' }}>{notificationsList[0].Titulo ? notificationsList[0].Titulo : ''}</div>
                                        <div className="col-12 px-0 NotificationsSheet-subtitleBox text-block-truncate-2">{notificationsList[0].Body ? notificationsList[0].Body : ''}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 NotificationsSheet-footer-content px-0">
                            <button className="btn-cleared" onClick={onViewAll}>
                                Ver todos
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="NotificationsSheet-no-results-container col-12">
                <img src={icnsNotification} alt="icnsNotification" />
                <span>No tienes notificaciones recientes.</span>
            </div>
        );
    }
    return (
        <>
            <div className="NotificationsSheet-container row m-0 d-flex justify-content-end">
                {mainRender()}
            </div>
            <ModalDefault show={modalActive} onHide={() => setModalActive(false)} className={'modalRefereal-mobile'}>
                {userRefeal ? (
                    <div className='container-refereal'>
                        <div className='text-end close-refereal'>
                            <img className='icn-close-refereal' src={icnClose} alt="" onClick={() => setModalActive(false)} />
                        </div>
                        <h3 className='title-refeal text-center'>
                            {
                                userRefeal.clv_estatusreferido === 2 ?
                                    `Has confirmado a ${userRefeal.Nombre} como tu invitado` :
                                    userRefeal.clv_estatusreferido === 3 ?
                                        `Has rechazado a ${userRefeal.Nombre} como tu invitado` :
                                        `¿Confirmas a ${userRefeal.Nombre} como tu invitado a WeNet?`
                            }
                        </h3>
                        <div className='detail-refeal'>
                            <div className='container-user-info-refereal'>
                                <div className='row'>
                                    <div className='col-2'>
                                        <img src={userRefeal.ThumbnailUrl ? userRefeal.ThumbnailUrl : icnPlaceHolder} alt="user image" className='icn_refeal' />
                                    </div>
                                    <div className='col-10'>
                                        <h3 className='name-refereal'>{userRefeal.NombreCompleto}</h3>
                                        <h3 className='position-business-refereal'> {userRefeal.Cargo} | {userRefeal.EmpresaNombreCorto}</h3>
                                        <h3 className='location-refereal'> {userRefeal.EstadoResidencia && userRefeal.PaisResidencia ? userRefeal.EstadoResidencia + '|' + userRefeal.PaisResidencia : ''}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='container-user-description-refereal'>
                                <h3> {userRefeal.Descripcion}</h3>
                            </div>
                            <div className='container-view-profile-refereal text-end'>
                                <Link to={`profile-user/${userRefeal.clv_usuario}`} className='view-profile-refereal'>Ver perfil</Link>
                            </div>
                        </div>
                        <div className='container-buttons-refeal row m-0'>
                            {
                                userRefeal.clv_estatusreferido == 1 ?
                                    (<>
                                        <div className='col-6 text-center'>
                                            <button className='btn btn-cancel-refereal' onClick={() => actionButton(3)}>No</button>
                                        </div>
                                        <div className='col-6 text-center'>
                                            <button className='btn btn-ok-refereal' onClick={() => actionButton(2)} >Si</button>
                                        </div>
                                    </>) :
                                    (null)

                            }

                        </div>
                    </div>
                ) : ''}
            </ModalDefault>
        </>

    );
};

export { NotificationsSheet };
