import React, { useState, useEffect, useRef } from 'react';
import Linkify from 'react-linkify';
import './Profile.scss';
import { userService, authenticationService, profileService, swalAlertsService, infoService } from '../../../_resources/services';
import { toolsHelper, useDidMountEffect, useWindowDimensions } from '../../../_resources/helpers';
import { Image } from 'react-bootstrap'
import icnsettings from '../../../_assets/media/images/icons/icn_settings.svg'
import placeholderuser from '../../../_assets/media/images/icons/icn_user_placeholder.png'
import placeholdercompany from '../../../_assets/media/images/icons/placeholder_company.svg'
import business from '../../../_assets/media/images/icons/icn_position.png'
import phone from '../../../_assets/media/images/icons/icn_phone.png'
import email from '../../../_assets/media/images/icons/icn_mail_black.png'
import birth from '../../../_assets/media/images/icons/icn_birthday.png';
import state from '../../../_assets/media/images/icons/icn_pin.png'
import web from '../../../_assets/media/images/icons/icn_web.png'
import foundation from '../../../_assets/media/images/icons/icn_date_company.png'
import employee from '../../../_assets/media/images/icons/icn_employee.png'
import folleto from '../../../_assets/media/images/icons/folleto.png'
import more from '../../../_assets/media/images/icons/icn_more.png'
import mb from '../../../_assets/media/images/icons/icn_mb.svg'
import shares from '../../../_assets/media/images/icons/icn_share.svg'
import start from '../../../_assets/media/images/icons/icn_batch_star.svg'

import icnCalendar from '../../../_assets/media/images/icons/icn_calendar.png';
import icnMentoring from '../../../_assets/media/images/icons/icn_mentoring.png';

import icnLike from '../../../_assets/media/images/icons/icn_like.png';
import icnClose from '../../../_assets/media/images/icons/icn_close.png';

import icnsNetworks from '../../../_assets/media/images/icons/icns_networks.png';
import { useHistory } from 'react-router-dom';

import {
    SocialNetworks,
    Talent,
    ContactList,
    BMAcademy,
    Header,
    ConfigSheet,
    MessagesBubble,
    TalentSheet,
    CompanyDetailsSheet,
    BrochureSheet,
    AsociationsSheet,
    AsociationsModal
} from '../../shared/';

import { ProfilePersonalInfo, ProfileNetworkInfo } from './profile-components';
import { getMessagin } from '../../../firebaseInit';

const Profile = (props) => {
    let scrollY = 0
    const reload = 0

    const [scroll, setScroll] = useState(scrollY)
    const [user, setUser] = useState({})
    const [card, setCard] = useState([])
    const [isNetworksOpen, setIsNetworksOpen] = useState(false);
    const [isTalentOpen, setIsTalentOpen] = useState(false);
    const [isContactAdding, setIsContactAdding] = useState(false);
    const [isBMAcademyOpen, setIsBMAcademyOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [catalogs, setCatalogs] = useState(null);
    const [isAsociationsModal, setIsAsociationsModal] = useState(false);
    const [copyBM, setCopyBm] = useState(null);
    const [updateDot, setUpdateDot] = useState(false);

    const history = useHistory()
    const url = useRef(null)

    const { width, height } = useWindowDimensions();

    let messaging = getMessagin();

    if(messaging){
        messaging.onMessage((payload) => {
            setUpdateDot(true);
            if (payload && payload.data && payload.data.clv_tipoaccion === "12") {
                getCurrentUser(true);
                getUser();
                getData();
            }
        });
    }
    
    const endMessage = ()=>{
        setUpdateDot(false);
    }

    useEffect(() => {
        let isSuscribed = true;

        if (props.onToggleTab) {
            props.onToggleTab(false);
        }

        getCurrentUser(isSuscribed);

        infoService.catalogs.subscribe((response) => {
            if (typeof response === 'object' && response !== null && isSuscribed) {
                setCatalogs(response);
            }
        });

        getUser();
        getData();
        return () => isSuscribed = false
    }, []);


    const getData = () => {
        profileService.getCard().then(
            (response) => {
                if (response.data.cards) {
                    setCard(response.data.cards[0])
                }

            }
        )
    }

    const getScroll = () => {
        setScroll(window.scrollY)

    }


    const getCurrentUser= (isSuscribed) =>{
        authenticationService.currentUser.subscribe((user) => {
            if (user && user.SUID && user.Usuario && isSuscribed) {
                setUser(user.Usuario);
                setCurrentUser(user);
            }
        });
    }

    const getUser = () => {
        profileService.getUser().then(
            (response) => {
                setUser(response.data)
            },
            (error) => {
            }
        )
    }

    const getTag = (tag) => {
        if (tag) {
            return (
                <div className="container-tag">
                    <p>{tag}</p>
                </div>
            )
        }
        return null;
    }

    const getreload = () => {

    }

    const share = () => {
        // console.log(navigator.share);
        // if (navigator.share) {
        //     navigator.share({
        //         title: 'Comparte este link',
        //         url: `https://www.wenet.mx/`,
        //         text: `Se que por tu perfil profesional, esto te interesa: somos una comunidad de dueños de empresas y directores generales apoyandonos para tomar mejores decisiones y generar nuevas oportunidades de negocio, me gustaría que fueras parte también. /n wenet.mx">https://www.wenet.mx/</a>`
        //     }).then(() => {
        //         console.log('Gracias por compartir!');
        //     })
        //     .catch(console.error);
        // } else {
        profileService.postGeneric('user/invitation', 'post', {}).then((response) => {
            setCopyBm(response);
            if (response && response.Mensaje) {
                document.getElementById("share-dialog").classList.add('is-open')
            }

        })

        // }
    }

    const removeshare = () => {
        document.getElementById("share-dialog").classList.remove('is-open')
    }

    const copy = () => {
        const el = document.createElement('textarea');
        // el.value = "https://wenet.mx";
        el.value = copyBM.Mensaje;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

    }

    const getFixedUrl = (item) => {
        if (item && item.RedSocial && item.Perfil) {

            if (!toolsHelper.validateURL(item.Perfil)) {
                switch (item.RedSocial) {
                    case 'Facebook':
                        return `https://facebook.com/${item.Perfil}`;
                        break;
                    case 'Twitter':
                        return `https://twitter.com/${item.Perfil}`;
                        break;
                    case 'LinkedIn':
                        return `https://linkedin.com/in/${item.Perfil}`;
                        break;
                    case 'YouTube':
                        return `https://www.youtube.com/results?search_query=${item.Perfil}`;
                        break;
                    case 'Instagram':
                        return `https://instagram.com/${item.Perfil}`;
                        break;
                    case 'Spotify':
                        return `https://spotify.com/${item.Perfil}`;
                        break;
                }
            }
            return item.Perfil;
        }
        return null;
    }

    const onInvitationLabelTapped = () => {
        if (user && user.clv_referidopor && user.clv_referidopor !== user.clv_usuario) {
            history.push('/profile-user/' + user.clv_referidopor, { from: '/profile' })
        }
    }

    const onRecomendationUserTapped = (recomendation) => {
        if (recomendation && recomendation.clv_usuariorecomienda && user && recomendation.clv_usuariorecomienda !== user.clv_usuario) {
            history.push(`/profile-user/${recomendation.clv_usuariorecomienda}`, { from: '/profile' })
        }
    }

    const onBottomSheetDimiss = () => {
        setIsNetworksOpen(false);
    }

    const onBottomSheetDimissAsociations = () => {
        setIsAsociationsModal(false);
    }

    const onOpenNetworkEditor = () => {
        setIsNetworksOpen(true);
    }

    const onOpenAsociationsEditor = () => {
        setIsAsociationsModal(true);
    }

    const onBottonTalentDismiss = () => {
        setIsTalentOpen(false);
    }

    const onOpenTalentEditor = () => {
        setIsTalentOpen(true);
    }

    const dataUpdated = () => {
        getUser();
        onBottomSheetDimiss();
        onBottonTalentDismiss();
    }

    const renderRecomendations = () => {

        if (user.Recomendaciones && user.Recomendaciones.length) {
            const slicedArray = user.Recomendaciones.slice(0, 3);
            const items = slicedArray.map((recomendation, index) => {

                return (
                    <div className="col recomendation-item" key={index}>

                        <div className="row m-0 recomendation-item-row mouse-pointer" onClick={() => history.push(`/talent/${user.clv_usuario}`, { from: '/profile' })}>
                            <div className="col-12 p-0">
                                <div className="row m-0">
                                    <div className="col-12 p-0">
                                        {/* <div className="row m-0 mouse-pointer" onClick={() => onRecomendationUserTapped(recomendation)}> */}
                                        <div className="row m-0 mouse-pointer">
                                            <div className="col avatar-image-box-60 pos-relative">
                                                <img src={recomendation.ThumbnailUrl ? recomendation.ThumbnailUrl : placeholderuser} alt="recomendation photo"
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = placeholderuser
                                                    }}
                                                />
                                                <img src={icnLike} alt="like icon" width='35' height='35' className='likeIcn' />
                                            </div>
                                            <div className="col pe-0">
                                                <div className="row m-0 recomendation-details-box">
                                                    <div className="col-12 px-0 user-details-title text-block-truncate-1">{recomendation.NombreCompleto}</div>
                                                    <div className="col-12 px-0 user-details-subtitle soft text-block-truncate-1 small">
                                                        {`${recomendation.Cargo ? recomendation.Cargo : ''}${recomendation.Cargo && recomendation.EmpresaNombreCorto ? ' | ' : ''}${recomendation.EmpresaNombreCorto ? recomendation.EmpresaNombreCorto : ''}`}
                                                    </div>
                                                    <div className="col-12 px-0 user-details-subtitle soft text-block-truncate-1 small">
                                                        {`${recomendation.EstadoResidencia ? recomendation.EstadoResidencia : ''}${recomendation.EstadoResidencia && recomendation.PaisResidencia ? ', ' : ''}${recomendation.PaisResidencia ? recomendation.PaisResidencia : ''}`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className="col-12 px-0 recomendation-text-box text-block-truncate-4">
                                        {recomendation.Recomendacion}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })
            return (
                <div className="col-12 data-recomendation-container">
                    <div className="row m-0 d-flex align-items-center recomendation-header">
                        <div className="col p-0 recomendation-cant-box">
                            <img src={icnLike} alt="like icon" width='35' height='35' /> {user.Recomendaciones.length} {user.Recomendaciones.length == 1 ? 'Recomendación' : 'Recomendaciones'}
                        </div>
                        <div className="col recomendation-showmore-box">
                            <button className="btn-cleared" onClick={() => history.push(`/talent/${user.clv_usuario}`, { from: '/profile' })}>Ver todo</button>
                        </div>
                    </div>

                    <div className="row m-0 recomendation-items-container flex-nowrap ">
                        {items}
                    </div>
                </div>
            )
        }
        return null;
    }

    const onCloseContactAdding = () => {
        setIsContactAdding(false);
    }

    const onCloseBMAcademy = () => {
        setIsBMAcademyOpen(false);
    }

    const onContantSendBtnTapped = (data) => {

        swalAlertsService.answer("", "Seguro que quieres enviar esa solicitud a tus amigos", "Si").then((result) => {
            if (result.isConfirmed) {
                if (data && data.length) {
                    let loader = swalAlertsService.createLoading("Espere un momento...")
                    loader.fire();
                    const objSend = {
                        users: data.map((el) => { return { clv_usuario: el.clv_usuario } })
                    }
                    profileService.postGeneric('user/recommendations', 'POST', objSend).then(
                        (response) => {
                            loader.close();
                            if (response) {
                                if (response.message && response.message.indexOf('correctamente') >= 0) {
                                    dataUpdated();
                                    swalAlertsService.showToast('success', 'Petición de recomendación enviada correctamente');
                                } else {
                                    swalAlertsService.showToast('error', 'No pudimos enviar la petición de recomendación');
                                }
                            }
                        },
                        (error) => {
                            loader.close();
                            swalAlertsService.showToast('error', 'No pudimos enviar la petición de recomendación');
                        }
                    )
                }

                onCloseContactAdding();
            }
        })
        // if (data && data.length) {
        //     let loader = swalAlertsService.createLoading("Espere un momento...")
        //     loader.fire();
        //     const objSend = {
        //         users: data.map((el) => { return {clv_usuario: el.clv_usuario}})
        //     }
        //     profileService.postGeneric('user/recommendations', 'POST', objSend).then(
        //         (response) => {
        //             loader.close();
        //             if (response) {
        //                 if (response.message && response.message.indexOf('correctamente') >= 0) {
        //                     dataUpdated();
        //                     swalAlertsService.showToast('success', 'Petición de recomendación enviada correctamente');
        //                 } else {
        //                     swalAlertsService.showToast('error', 'No pudimos enviar la petición de recomendación');
        //                 }
        //             }
        //         },
        //         (error) => {
        //             console.log(error);
        //             loader.close();
        //             swalAlertsService.showToast('error', 'No pudimos enviar la petición de recomendación');
        //         }
        //     )
        // }
        // // setUser({...user, InvitadoPor: data.NombreCompleto, clv_usuarioInvitadoPor: data.clv_usuario});
        // onCloseContactAdding();
        // if (data && data.length) {
        //     let obj = {
        //         Usuarios: data.map(el => { return {clv_usuario: el.clv_destinatario}})
        //     }
        //
        //     let loading = swalAlertsService.createLoading("Espere un momento...")
        //     loading.fire();
        //     messagesService.postAddMembersToGroup(groupState.clv_conversacion, obj).then((response) => {
        //         loading.close();
        //         if (response && response.data && response.data && response.message) {
        //             swalAlertsService.showToast('success', response.message);
        //             setGroupState(response.data);
        //             onBottomSheetDimissContactList();
        //             onChatUpdate();
        //         } else {
        //             swalAlertsService.showToast('error', 'Ocurrió un problema al intentar actualizar el grupo');
        //         }
        //     });
        // }
    }

    const generateBMCode = () => {
        if (currentUser && currentUser.Usuario && currentUser.Usuario.Codigo && currentUser.Usuario.Codigo.Codigo) {
            setIsBMAcademyOpen(true);
        } else {
            profileService.postGeneric('user/codebm', 'POST', {}).then((response) => {
                setIsBMAcademyOpen(true);
            });
        }

    }

    const onOptionTapped = (option) => {
        if (option) {
            switch (option) {
                case 'recomendation':
                    setIsContactAdding(true);
                    break;
                case 'invitation':
                    share();
                    break;
                case 'payment-method':
                    card ?
                        card.length === 0 ?
                            history.push("/membership/2", { id: 2 }) : history.push("/profile/method-pay") : history.push("/membership/2", { id: 2 })
                    break;
                case 'membership':
                    user.Membresia ?
                        user.Membresia.Object ?
                            ((user.Membresia.Object.status === "canceled" || user.Membresia.Object.status === "paused") ?
                                history.push("/membership/1", { id: 1 }) : history.push("/profile/membership")) : history.push("/profile/membership") : history.push("/membership/1", { id: 1 })
                    break;
                case 'config':
                    history.push("/setting");
                    break;
                default:
                    break;
            }
        }
    }

    const getMentoringData = () => {
        // icnCalendar


        if (user.OfreceMentoria && (user.Mentoria || user.MentoriaPersonalizado)) {

            if (user.clv_tipomentoria === 4 && user.MentoriaPersonalizado) {
                return (
                    <div className="data_user_info_fill">
                        <img src={icnMentoring} />
                        {
                            user.Mentoria ? (<p>{`Otorga mentoría - ${user.MentoriaPersonalizado || ''}`}</p>) : (<p>{`Otorga mentoría - ${user.Mentoria || ''}`}</p>)
                        }
                    </div>
                )
            }

            return (
                <div className="data_user_info_fill">
                    <img src={icnMentoring} />
                    {
                        user.Mentoria ? (<p>{`Otorga mentoría - ${user.Mentoria || ''}`}</p>) : (<p>{`Otorga mentoría - ${user.MentoriaPersonalizado || ''}`}</p>)
                    }
                </div>
            )
        }
        return null;
    }

    const getUserDate = (dateString) => {
        if (dateString) {
            const month = toolsHelper.getMonthName(dateString);

            const date = new Date(dateString);

            return `${month}. ${date.getFullYear()}`;
        }
        return null;
    }

    const desktopRender = () => {
        return (
            <div className="Profile-container">
                <Header title='' activeLink='profile' hideSearcher={true}  updateShowDot={updateDot} 
                    endMessage={endMessage} />

                <div className="row m-0 d-flex justify-content-center Profile-box-container">
                    <div className="col ps-0 Profile-post-box">
                        <div className="row m-0 Profile-main-row">
                            <div className="col-12 p-0">
                                <div className="row m-0">
                                    <div className="col p-0">
                                        <ProfilePersonalInfo user={user} />
                                    </div>

                                    <div className="col p-0 Profile-small-col">
                                        <ProfileNetworkInfo user={user} onEditBtnTapped={onOpenNetworkEditor} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 p-0">
                                <TalentSheet user={user} onEditBtnTapped={onOpenTalentEditor} isMine={true} onRecomendationUserTapped={onRecomendationUserTapped} />
                            </div>

                            <div className="col-12 px-0">
                                <CompanyDetailsSheet isMine={true} user={user} />
                            </div>

                            {user.clv_estatusbm == 2 || user.clv_estatusbm == 3 || user.clv_estatusIpade ?
                                <div className="col-12 px-0">
                                    <AsociationsSheet user={user} onEditBtnTapped={onOpenAsociationsEditor} />
                                </div> : (null)
                            }



                            <div className="col-12 px-0">
                                <BrochureSheet isMine={true} user={user} />
                            </div>
                        </div>
                    </div>

                    <div className="col Profile-lateral-content right">
                        <div className="row m-0 h-100">
                            <div className="col-12 px-0 pb-3">
                                <ConfigSheet onOptionTapped={onOptionTapped} />
                            </div>
                        </div>
                    </div>
                </div>

                <MessagesBubble />
                {
                    isContactAdding &&
                    <ContactList currentUser={currentUser}
                        onBottomSheetDimiss={onCloseContactAdding}
                        onInvitationAdded={onContantSendBtnTapped}
                        isGroupAdding={false}
                        groupPreviusUser={[]}
                        isInvitation={true}
                        isMultiple={true}
                        isDesktop={true}
                        destopHeaderTitle={'Pedir recomendación'}
                    />
                }
            </div>
        );
    }
    const setUrl = (key, value) => {
        switch (key) {
            case "icnweb":
                if (value.includes("https://") || value.includes("http://")) {
                    window.location.replace(value)
                } else {
                    window.location.replace(`https://${value}`)
                }
                break;
            case "icnphone":
                window.location.replace(`tel:${value}`)
                break;
            case "icnemail":
                window.location.replace(`mailto:${value}`)
                break;

            default:
                break;
        }
    }

    const getDiscountData = () => {
        if (user.OfreceDescuento && user.PorcentajeDescuento) {
            return (
                <div className="row m-0 Profile-discount-container">
                    <div className="col-12 Profile-discount-col">
                        <div className="row m-0 w-100">
                            <div className="col discount-number px-0">{user.PorcentajeDescuento}%</div>
                            <div className="col discount-text">
                                De descuento para miembros WeNet
                            </div>
                        </div>
                    </div>

                    {user.ComentariosDescuento ? (
                        <div className="col-12 Profile-discount-comments px-0 py-3">
                            <strong>Comentarios:</strong> {user.ComentariosDescuento}
                        </div>
                    ) : (null)}
                </div>
            );
        }
        return null;
    }

    const getIpadeDetails = () => {
        if (catalogs && catalogs.EstatusIpade && catalogs.EstatusIpade.length && user.clv_estatusIpade) {
            const filtered = catalogs.EstatusIpade.filter(el => el.clv_estatusIpade === user.clv_estatusIpade);

            if (filtered.length) {
                return `${filtered[0].EstatusIpade}${user.FechaIpade ? ` - ${user.FechaIpade}` : ''}`;
            }
        }

        return null;
    }
    const getPhoneDetails = () => {
        if (user && user.Celular) {
            return <p className="data_user_info_fill_icnphone  font-color-green ms-0" onClick={() => user.CelularPrivado === false ? setUrl("icnphone", user.Celular) : ""}>{user.Celular}</p>;
        }

        return <p className="data_user_info_fill_icnphone placeholderTxt">Sin definir celular</p>;
    }

    const getEmailDetails = () => {

        if (user && user.Email) {
            return <p className="data_user_info_fill_icnemail ms-0" onClick={() => user.EmailPrivado === false ? setUrl("icnemail", user.Email) : ""}>{user.Email}</p>;
        }

    }

    const getDateBirth = () => {
        if (user.FechaNacimiento) {
            const month = toolsHelper.getMonthName(user.FechaNacimiento);
            let x = new Date(user.FechaNacimiento);
            let formatted_date = x.getDate() + " " + month + " " + x.getFullYear();
            return (
                <div className="data_user_info_fill">
                    <img src={birth} />
                    <p>{formatted_date}</p>
                </div>
            )
        }
        return null;
    }




    const mobileRender = () => {
        return (
            <>
                {
                    user &&
                    (
                        <div className="container_profile">
                            <div className="image_user">
                                <div className="btn_configuration_profile" onClick={() => history.push("/setting")}>
                                    <img src={icnsettings} />
                                </div>
                                <img className="image_profile" 
                                    src={user.PhotoUrl ? user.PhotoUrl : placeholderuser} 
                                    width="375px" 
                                    height="375px"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = placeholderuser
                                    }}
                                />
                            </div>
                            <div className={`data_user ${scroll >= 280 ? 'hidden' : ''}`}>

                                <div className="name-user">
                                    <h1>
                                        {
                                            user.clv_estatusbm === 2 &&
                                            <img src={start} width="10px" height="10px" alt="miembro" />

                                        }
                                        {user.NombreCompleto}
                                    </h1>
                                    <div className="data_account">
                                        <div className="data_account_option mouse-pointer">
                                            {user.clv_estatusreferido && user.clv_estatusreferido != 3  ?
                                                <p className='invitation-label'>Invitado por:
                                                    <span className='noselect'  onClick={ user.clv_estatusreferido == 2?  onInvitationLabelTapped : ()=>{}}>
                                                        {
                                                            user.clv_estatusreferido == 1 ?
                                                                (<strong className='ps-1 text-start noselect text-block-truncate-1'>Pendiente</strong>) :
                                                            user.clv_estatusreferido == 2 && user.InvitadoPor && user.PhotoUrlInvitadoPor ?
                                                                (
                                                                    <>
                                                                        <img src={user.PhotoUrlInvitadoPor} 
                                                                            alt="invitation" 
                                                                            className='noselect invitation-miniature'
                                                                            onError={(e) => {
                                                                                e.target.onerror = null;
                                                                                e.target.src = placeholderuser
                                                                            }}
                                                                        />
                                                                        <strong className='ps-1 text-start noselect text-block-truncate-1'>{user.InvitadoPor}</strong>
                                                                    </>
                                                                ):
                                                            user.clv_estatusreferido == 2 && user.InvitadoPor && !user.PhotoUrlInvitadoPor?
                                                                (  <strong className='ps-1 text-start noselect text-block-truncate-1'>{user.InvitadoPor}</strong>)
                                                                :
                                                                (null)
                                                        }



                                                    </span>
                                                </p>
                                                : ''}
                                        </div>
                                    </div>
                                </div>


                                <div className="data pb-4">

                                    <div className="data_user_info">
                                        <div className="data_user_info_fill">
                                            <img src={business} />
                                            <p>
                                                {`${user.Cargo ? user.Cargo : ''}${user.Cargo && user.Empresa ? ' | ' : ''}${user.Empresa ? user.Empresa : ''}`}
                                            </p>
                                        </div>
                                        <div className="data_user_info_fill">
                                            <img src={email} />
                                            <p className='font-color-green'>{getEmailDetails()}</p>
                                        </div>
                                        <div className="data_user_info_fill">
                                            <img src={phone} />
                                            <p className='font-color-green'>{getPhoneDetails()}</p>
                                        </div>
                                        {
                                            user.EstadoResidencia || user.PaisResidencia ? (
                                                <div className="data_user_info_fill">
                                                    <img src={state} />
                                                    <p>{user.EstadoResidencia}{user.EstadoResidencia && user.PaisResidencia ? ', ' : ''}{user.PaisResidencia}</p>
                                                </div>
                                            ) : (null)
                                        }

                                        {getMentoringData()}

                                        {getDateBirth()}

                                        {
                                            user.FechaAlta ? (
                                                <div className="data_user_info_fill">
                                                    <img src={icnCalendar} />
                                                    <p>Miembro WeNet desde {getUserDate(user.FechaAlta)}</p>
                                                </div>
                                            ) : (null)
                                        }

                                        <button className="data_user_info_btn_edit" onClick={() => history.push("/profile/edit/" + user.clv_usuario, {})} >Editar perfil</button>

                                    </div>

                                    <div className="row data-network-container">
                                        <div className="col-12 data-network-header">Sígueme en:</div>

                                        <div className="col-12 data-networks-box">
                                            {
                                                user.RedesSociales &&
                                                    user.RedesSociales.length ?
                                                    user.RedesSociales.map((item, index) => {

                                                        if (item.RedSocial !== 'Spotify') {
                                                            return (
                                                                <a href={getFixedUrl(item)} target="_blank" rel="noreferrer" key={index}>
                                                                    <div className="row m-0">
                                                                        <div className="col-12 mouse-pointer">
                                                                            <img src={icnsNetworks} alt="" className={item.RedSocial} />
                                                                            <div className='text-block-truncate-1'>
                                                                                {item.RedSocial === 'Facebook' || item.RedSocial === 'LinkedIn' ? '/' : '@'}{item.Perfil}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            )
                                                        } else {
                                                            return (
                                                                <div className="row m-0" key={index}>
                                                                    <div className="col-12 noselect">
                                                                        <img src={icnsNetworks} alt="" className={item.RedSocial} />
                                                                        <div className='text-block-truncate-1'>
                                                                            {item.Perfil}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    }) : (null)
                                            }

                                        </div>

                                        <div className="col-12 data-network-adder-container">
                                            <button onClick={onOpenNetworkEditor}>
                                                {
                                                    user.RedesSociales &&
                                                        user.RedesSociales.length ?
                                                        ('Editar redes sociales') : ('Agrega tus redes sociales')
                                                }
                                            </button>
                                        </div>


                                    </div>

                                    <div className="row data-network-container">
                                        <div className="col-12 data-network-header">Mi mayor talento:</div>

                                        {
                                            user.Talento ? (
                                                <div className="col-12 data-networks-box text-content">
                                                    {user.Talento}
                                                </div>
                                            ) : (null)
                                        }

                                        <div className="col-12 data-network-adder-container">
                                            <button onClick={onOpenTalentEditor}>
                                                {
                                                    user.Talento ?
                                                        ('Editar mi mayor talento') : ('Agregar mi mayor talento')
                                                }
                                            </button>
                                        </div>

                                        {renderRecomendations()}



                                        <div className="row m-0">
                                            <div className="col-12 px-0 data-network-adder-container">
                                                <button className='btn-cleared' onClick={e => setIsContactAdding(true)}>
                                                    Solicitar recomendaciones
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="data_company">
                                        <div className="data_company_header">
                                            <img src={user.LogoThumbnailUrl != null ? user.LogoThumbnailUrl : placeholdercompany} width="100px" height="78px" />
                                            <div className="data_company_header_title">
                                                <h6>{user.EmpresaNombreCorto}</h6>
                                                <p>{user.Giro}</p>
                                                <p>{user.Clasificacion}</p>
                                            </div>
                                        </div>

                                        <h3>{user.Empresa}</h3>
                                        <p>{user.Descripcion}</p>

                                        <div className="data_company_info_fill">
                                            <img src={web} />
                                            {
                                                user.URL ? (

                                                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                                        <a className='pl-2' target="blank" href={decoratedHref} key={key} style={{ color: '#0fb855', textDecoration: 'none', marginLeft: '10px' }}>
                                                            {decoratedText}
                                                        </a>
                                                    )}>
                                                        {user.URL}
                                                    </Linkify>
                                                    // <p>{user.URL}</p>
                                                ) : (<p>NA</p>)
                                            }
                                        </div>

                                        <div className="data_company_info_fill">
                                            <img src={phone} />
                                            {
                                                user.Telefono ? (
                                                    <p>{user.Telefono}</p>
                                                ) : (<p>NA</p>)
                                            }
                                        </div>

                                        <div className="data_company_info_fill">
                                            <img src={state} />
                                            <p>{user.EstadoResidencia + ", " + user.PaisResidencia}</p>
                                        </div>

                                        <div className="data_company_info_fill">
                                            <img src={foundation} />
                                            {
                                                user.Fundacion ? (
                                                    <p>{`Fundada en ${new Date(user.Fundacion).getFullYear().toString()}`}</p>
                                                ) : (<p>NA</p>)
                                            }
                                        </div>

                                        {
                                            user.RangoEmpleados &&
                                            <div className="data_company_info_fill">
                                                <img src={employee} />
                                                <p>De {user.RangoEmpleados} empleados</p>
                                            </div>
                                        }


                                        {
                                            user.Tag1 || user.Tag2 ? (
                                                <>
                                                    <h4>Especialidades</h4>

                                                    <div className="data_company_tags">
                                                        {
                                                            getTag(user.Tag1)
                                                        }
                                                        {
                                                            getTag(user.Tag2)
                                                        }
                                                    </div>
                                                </>
                                            ) : (null)
                                        }

                                        {getDiscountData()}

                                        <button className="data_company_btn_edit" onClick={() => history.push("/profile/edit-company/" + user.clv_usuario, {})}>Editar</button>

                                    </div>

                                    <div className="data_folleto mouse-pointer" onClick={() => history.push("/profile/folleto")}>

                                        <img src={folleto} width="100px" height="80px" />

                                        <p>Folleto de la empresa</p>

                                        <img src={more} width="40px" height="40px" />

                                    </div>


                                    {

                                        user.clv_estatusbm == 2 || user.clv_estatusbm == 3 || user.clv_estatusIpade ?
                                            <div className="row m-0 Profile-asociations-container">
                                                <div className="col-12 px-0 colTitle">
                                                    Asociaciones a las que pertenece
                                                </div>

                                                {
                                                    user.clv_estatusbm && user.clv_estatusbm != 4 && user.clv_estatusbm != 1 ? (
                                                        <div className="col-12 asociationBox">
                                                            <div className="row m-0">
                                                                <div className="col-12 px-0 asocTitle">BoardMedia Digital</div>
                                                                <div className="col-12 px-0 asocDetails">{user.clv_estatusbm === 2 ? 'Miembro activo' :
                                                                    user.clv_estatusbm === 3 ? 'Ex Miembro' : ''}</div>
                                                            </div>
                                                        </div>
                                                    ) : (null)
                                                }

                                                {
                                                    user.clv_estatusIpade ? (
                                                        <div className="col-12 asociationBox">
                                                            <div className="row m-0">
                                                                <div className="col-12 px-0 asocTitle">IPADE</div>
                                                                <div className="col-12 px-0 asocDetails">{getIpadeDetails()}</div>
                                                            </div>
                                                        </div>
                                                    ) : (null)
                                                }

                                            </div> :
                                            (null)
                                    }



                                    <h3>Mi cuenta</h3>

                                    <div className="data_account" onClick={() => user.Membresia ? user.Membresia.Object ? ((user.Membresia.Object.status === "canceled" || user.Membresia.Object.status === "paused") ? history.push("/membership/1", { id: 1 }) : history.push("/profile/membership")) : history.push("/profile/membership") : history.push("/membership/1", { id: 1 })}>
                                        <div className="data_account_option mouse-pointer">
                                            <p>Membresía</p>
                                            <img src={more} width="40px" height="40px" />
                                        </div>
                                    </div>



                                    <div className="data_account" onClick={() => card ? card.length === 0 ? history.push("/membership/2", { id: 2 }) : history.push("/profile/method-pay") : history.push("/membership/2", { id: 2 })}>
                                        <div className="data_account_option mouse-pointer">
                                            <p>Método de pago</p>
                                            <img src={more} width="40px" height="40px" />
                                        </div>
                                    </div>

                                    <div className="data_account" onClick={() => share()}>
                                        <div className="data_account_option mouse-pointer">
                                            <p className="green">Invitar amigos a Wenet</p>
                                            <img src={shares} width="30px" height="30px" />
                                        </div>
                                    </div>

                                    <div className="data_account" onClick={() => generateBMCode()}>
                                        <div className="data_account_option noselect mouse-pointer">
                                            <p className="green">Conecta con WeAcademy</p>
                                            <img src={more} width="40px" height="40px" />
                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>
                    )
                }

                {
                    isContactAdding &&
                    <ContactList currentUser={currentUser}
                        onBottomSheetDimiss={onCloseContactAdding}
                        onInvitationAdded={onContantSendBtnTapped}
                        isGroupAdding={false}
                        groupPreviusUser={[]}
                        isInvitation={true}
                        isMultiple={true}
                    />
                }
            </>
        )
    }

    return (
        <React.Fragment>


            {
                width && width >= 1450 ? (desktopRender()) : (mobileRender())
            }


            {
                <div>
                    <div className="share-dialog" id="share-dialog">
                        <header>
                            <h3 className="dialog-title m-0">Invitación</h3>

                            <button className="close-button" onClick={() => removeshare()}>
                                <img src={icnClose} alt="icnClose" width='30' height='30' />
                            </button>
                        </header>

                        {
                            // <div className="targets">
                            //     <a className="button" href="https://facebook.com" target="_blank" rel="noreferrer">
                            //     <svg>
                            //         <use href="#facebook"></use>
                            //     </svg>
                            //     <span>Facebook</span>
                            //     </a>
                            //
                            //     <a className="button" href="https://twitter.com" target="_blank" rel="noreferrer">
                            //     <svg>
                            //         <use href="#twitter"></use>
                            //     </svg>
                            //     <span>Twitter</span>
                            //     </a>
                            //
                            //     <a className="button" href="https://linkedin.com" target="_blank" rel="noreferrer">
                            //     <svg>
                            //         <use href="#linkedin"></use>
                            //     </svg>
                            //     <span>LinkedIn</span>
                            //     </a>
                            //
                            //     <a className="button">
                            //     <svg>
                            //         <use href="#email" href={`mailto: https://wenet.mx`} target="_blank" rel="noreferrer"></use>
                            //     </svg>
                            //     <span>Email</span>
                            //     </a>
                            // </div>
                        }
                        <span className="dialog-body m-0 pb-4" >
                            {copyBM && copyBM.Mensaje ? `${copyBM.Mensaje}` : ''}
                        </span>
                        <div className='col-12 center-div'>
                            <button className="col-6 copy-link" onClick={() => copy()}>Copiar invitación</button>
                        </div>
                    </div>

                    <svg className="hidden">
                        <defs>
                            <symbol id="share-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-share"><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><polyline points="16 6 12 2 8 6"></polyline><line x1="12" y1="2" x2="12" y2="15"></line></symbol>

                            <symbol id="facebook" viewBox="0 0 24 24" fill="#3b5998" stroke="#3b5998" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></symbol>

                            <symbol id="twitter" viewBox="0 0 24 24" fill="#1da1f2" stroke="#1da1f2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></symbol>

                            <symbol id="email" viewBox="0 0 24 24" fill="#777" stroke="#fafafa" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></symbol>

                            <symbol id="linkedin" viewBox="0 0 24 24" fill="#0077B5" stroke="#0077B5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></symbol>

                            <symbol id="close" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="9" y1="9" x2="15" y2="15"></line><line x1="15" y1="9" x2="9" y2="15"></line></symbol>
                        </defs>
                    </svg>
                </div>
            }

            {
                isNetworksOpen &&
                <SocialNetworks
                    onBottomSheetDimiss={onBottomSheetDimiss}
                    title={user.RedesSociales && user.RedesSociales.length ? 'Editar redes sociales' : 'Agregar redes sociales'}
                    networks={user.RedesSociales || []}
                    dataUpdated={dataUpdated}
                />
            }

            {
                isAsociationsModal &&
                <AsociationsModal onBottomSheetDimiss={onBottomSheetDimissAsociations}
                    title={user.RedesSociales && user.RedesSociales.length ? 'Editar redes sociales' : 'Agregar redes sociales'}
                    networks={user.RedesSociales || []}
                    dataUpdated={dataUpdated}
                />
            }

            {
                isTalentOpen &&
                <Talent
                    onBottomSheetDimiss={onBottonTalentDismiss}
                    title={user.Talento ? 'Editar talento' : 'Agregar talento'}
                    dataUpdated={dataUpdated}
                    talent={user.Talento || ''}
                    isRecomendation={false}
                />
            }

            {
                isBMAcademyOpen &&
                <BMAcademy currentUser={currentUser} onDismiss={onCloseBMAcademy} />
            }
        </React.Fragment>
    )
}

export { Profile };
