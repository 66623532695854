import React, { useEffect, useState } from "react";
import { infoService } from "../../../../_resources/services";
import "./HomeFilterMent.scss";

export const HomeFilterMent = (props) => {
    
    const initialCategory = {
        CategoriaPublicacion: "Todo",
        Eliminar: false,
        Mentores: null,
        clv_categoriapublicacion: 0,
        id: 0,
        name: "Todo"
    };
    
    const [elementList, setElementList] = useState(null);
    const [elementSelected, setElementSelected] = useState(null);

    useEffect(() => {
        let isSuscribed = true;
        let postType = 0;
        
        setElementList([]);
        
        infoService.catalogs.subscribe((catalogs) => {
            if (!props.postTypeClv || props.postTypeClv == 0) {
                if (catalogs && catalogs.TiposPublicacion && catalogs.TiposPublicacion.length) {
                    let filtered = catalogs.TiposPublicacion.filter(el => ![4,5].includes(el.clv_tipopublicacion));
                    if (filtered.length) {
                        
                        filtered = filtered.map((el) => {
                            return {...el, ...{id: el.clv_tipopublicacion, name: el.TipoPublicacion}}
                        });
                    }
                    filtered.unshift({clv_tipopublicacion: 0, id: 0, name: 'Todo'});
                    setElementList(filtered);
                }
            } else {
                if (catalogs) {
                    if (props.postTypeClv == 4 && catalogs.CategoriasPublicacion && catalogs.CategoriasPublicacion.length) {
                        let elements = catalogs.CategoriasPublicacion.map((el) => {
                            return {...el, ...{id: el.clv_categoriapublicacion, name: el.CategoriaPublicacion}}
                        });
    
                        elements.unshift({clv_categoriapublicacion: 0, id: 0, name: 'Todo'});
                        setElementList(elements);
                    } else if (props.postTypeClv == 5 && catalogs.TiposContenido && catalogs.TiposContenido.length) {
                        let elements = catalogs.TiposContenido.map((el) => {
                            return {...el, ...{id: el.clv_tipocontenido, name: el.TipoContenido}}
                        });
    
                        setElementList(elements);
                    }
                }
            }
            
        });


        return() =>{
            setElementList(null);
            setElementSelected(null);
        }


    }, []);

    const onClickElementList = (element) => {
        setElementSelected(element);

        if (!props.postTypeClv || props.postTypeClv == 0) {
            props.onSubPostTypeSelected(element);
        } else if (props.postTypeClv == 4) {
            props.onMentorSelected(element);
        } else if (props.postTypeClv == 5) {
            props.onContentSelected(element);
        }
    }


    const elementListList = () => {
        if (elementList) {
            let items = elementList.map((element, index) => {
                let isSelected = false;
                if (index == 0 && !elementSelected) {
                    isSelected = true;
                }

                if (elementSelected && element.id === elementSelected.id) {
                    isSelected = true;
                }
                return (<div className={`Home-filter-ment-item ${isSelected ? 'selected' : ''}`} key={element.id} onClick={() => onClickElementList(element)}>{element.name}</div>)
            });

            return items;
        }
    }

    return (
        <div className="Home-filter-ment">
            { elementListList() }
        </div>
    );
};
