import React, { useEffect, useState } from 'react';
import back from '../../../../../_assets/media/images/icons/icn_back_arrow.png'
import Cards from 'react-credit-cards'
import 'react-credit-cards/lib/styles.scss'

import './Card.scss'
import Swal from 'sweetalert2';
import { swalAlertsService } from '../../../../../_resources/services/swal-alerts.service';
import { membershipService } from '../../../../../_resources/services/membership.service';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { load } from 'dotenv';

const Card = (props) => {
    const params = useParams()
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
    }, [])


    var validado = false

    const [card, setCard] = useState({
        number: "",
        name: "",
        expiry: "",
        focused: "",
        cvc: ""
    })

    let data = {
        "card": {
          "number": card.number,
          "name": card.name,
          "exp_year": "20"+card.expiry.split("/")[1] ,
          "exp_month": card.expiry.split("/")[0],
          "cvc": card.cvc
        }
      };

    const setForm = (type, e) => {
        if(type === "number" ) {
            if(e.target.value.toString().length <= 16) {
                setCard({...card, [type]: e.target.value, "focused": type})
            }
        }else if(type === "expiry") {
            console.log(e.nativeEvent)
            if(e.target.value.toString().length <= 5) {
                setCard({...card, [type]: e.target.value, "focused": type})
            }
            if(e.target.value.toString().length === 3 && e.nativeEvent.data !== null) {
                setCard({...card, [type]: e.target.value, "focused": type})
                setCard({...card, [type]: card.expiry + "/", "focused": type})
            }
            if(validado === false) {
                validado = true
            }
        }else {
            setCard({...card, [type]: e.target.value, "focused": type})
        }

    }

    const setApiConekta = (token) => {
        let loading = swalAlertsService.createLoading("Espere un momento...")
        loading.fire()
        props.setCard(card)
        setTimeout(() => {
            loading.close()
            props.setView({"type": false,"method": false, "card": false, "detail": true})
        }, 2000);

    }

    const updateCard = (token) => {
        let loader = swalAlertsService.createLoading("Espere un momento...")
        loader.fire()
        membershipService.updateCard(token).then(
            (response) => {
                loader.close()
                if(response.status === 200 || response.status === 202) {
                    swalAlertsService.showToast("success", "Tarjeta actualizada exitosamente")
                }else {
                    swalAlertsService.showToast("error", "Ocurrio un error intentelo de nuevo mas tarde")
                }
                history.goBack()
            }
        )
    }

    const successHandler = (token) => {
        if(location && location.state && location.state.id && location.state.id === 2) {
            updateCard(token.id)
        }else {
            props.setToken(token.id)
            setApiConekta(token)
        }

      };

      const errorHandler = (err) => {
        swalAlertsService.showToast('error', err.message_to_purchaser)
      };

    const setConekta = () => {
        // eslint-disable-next-line no-undef
        new Conekta.Token.create(data, successHandler, errorHandler)
    }

    return (
        <React.Fragment>
             <div className="container-card">
                <div className="container_header">
                    <img src={back} width="30px" height="30px" onClick={() => {params.type === "2" ? history.goBack() : props.setView({"type": false,"method": true, "card": false, "detail": false})}}/>
                    <h3>Agregar tarjeta</h3>
                </div>

                <div id="PaymentForm">
                    <Cards
                    cvc={card.cvc}
                    expiry={card.expiry}
                    focused={card.focused}
                    name={card.name}
                    number={card.number}
                    />
                </div>

                <div className="container_form">
                    <div className="container_form_input">
                        <p>Número de tarjeta</p>
                        <input type="number" placeholder="0000 0000 0000 0000 0000" value={card.number} onChange={ e => setForm("number", e)} num/>
                    </div>
                    <div className="container_form_input">
                        <p>Nombre del titular</p>
                        <input type="text" placeholder="Nombre como aparece en la tarjeta" value={card.name} onChange={ e => setForm("name", e)}/>
                    </div>
                    <div className="container_form_column">
                        <div className="container_form_input">
                            <p>Vigencia</p>
                            <input type="text" placeholder="MM/AA" value={card.expiry.includes("/") ? card.expiry.split("/")[0] + "/" + card.expiry.split("/")[1] : card.expiry} onChange={ e => setForm("expiry", e)} maxLength="5"/>
                        </div>
                        <div className="container_form_input">
                            <p>Código de seguridad</p>
                            <input type="password" placeholder="CVV" value={card.cvc} className="input_right" onChange={ e => setForm("cvc", e)} maxLength="3"/>
                        </div>
                    </div>
                </div>


                <button onClick={() => setConekta()}>Siguiente</button>

            </div>
        </React.Fragment>
    );
}

export default Card;
