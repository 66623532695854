import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import './MessagesHeader.scss';

import icnNewChat from '../../../../../_assets/media/images/icons/icn_new_chat.png';
import icnSearch from '../../../../../_assets/media/images/icons/icn_search.png';
import icnCancel from '../../../../../_assets/media/images/icons/icn_cancel.png';
import icnClose from '../../../../../_assets/media/images/icons/icn_close.png';

const MessagesHeader = (props) => {
    const history = useHistory();
    const inputSearcher = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');

    const setupTxtInputVal = (value) => {
        let input = document.getElementById('MessagesHeader-searcher-input');

        if (input) {
            input.value = value;
        }
    }

    const sendInputVal = () => {
        if (props.onSearcherChanged) {
            props.onSearcherChanged(searchTerm)
        }
    }

    const onTextFieldChanged = (e) => {
        setSearchTerm(e.target.value);
    }

    const onClearSearch = () => {
        setSearchTerm('');
        setupTxtInputVal('');
        if (inputSearcher && inputSearcher.current) {
            inputSearcher.current.focus();
        }
    }

    const onNewChatBtnTapped = () => {
        if (props.onNewChatBtnTapped) {
            props.onNewChatBtnTapped()
        }
    }

    const onCloseBtnTapped = () => {
        history.push(`/`);
    }

    useEffect(() => {
        sendInputVal();
    }, [searchTerm]);

    return (
        <div className="MessagesHeader-container row m-0">
            <div className="col-12 p-0 text-right">
                <div className="row m-0 MessagesHeader-newchat-btn-container d-flex justify-content-between">
                    <div className="col p-0 closer">
                        <button className="btn-cleared" onClick={onCloseBtnTapped}>
                            <img src={icnClose} alt="close icon" width='30' height='30'/>
                        </button>
                    </div>
                    <div className="col p-0 new-chat">
                        <button className="btn-cleared" onClick={onNewChatBtnTapped}>
                            <img src={icnNewChat} alt="new chat icon" width='30' height='30'/>
                        </button>
                    </div>
                </div>

            </div>

            <div className="col-12 MessagesHeader-title-container">
                Mensajes
            </div>

            <div className="col-12 p-0 MessagesHeader-searcher">
                <label htmlFor="MessagesHeader-searcher-input">
                    <img src={icnSearch} alt="search icon" className='noselect'/>
                </label>
                <input type="text"
                    id='MessagesHeader-searcher-input'
                    name='MessagesHeader-searcher-input'
                    onKeyUp={onTextFieldChanged}
                    ref={inputSearcher}
                />

                {
                    searchTerm &&
                    <button className='btn-cleared' onClick={onClearSearch}>
                        <img src={icnCancel} alt="cancel icon" className='noselect'/>
                    </button>
                }

            </div>
        </div>
    );
}

export { MessagesHeader };
