import React, { useCallback, useRef, useState } from 'react';
import './ImageCrop.scss'
import Cropper from 'react-easy-crop'

const ImageCrop = (props) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [zoom2, setZoom2] = useState(1)
    const [cropArea, setCropArea] = useState({})


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCropArea(croppedAreaPixels)
    }, [])

    const addZoom = () => {
        if(zoom2 < 12) {
            setZoom2( zoom2 + 1)
        }
    }

    const minusZoom = () => {
        if(zoom2 > 1) {
            setZoom2( zoom2 - 1)
        }
    }
    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        canvas.width =  cropArea.width;
        canvas.height = cropArea.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
          image,
          cropArea.x,
          cropArea.y,
          cropArea.width,
          cropArea.height,
          0,
          0,
          cropArea.width,
          cropArea.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                resolve({blob, 'text': props.text});
              }, 'image/jpeg', 1);
        });
      }

      const getImage = async() => {
        var imagen = document.createElement('img');
        imagen.src= props.src
        var result = await getCroppedImg(imagen, crop, "imagen")
        props.cutCrop(result)
    }
    //aspect={props.aspectRatio ? props.aspectRatio:4 / 3}
    return (
        <React.Fragment >
            <div className="container-crop">
                <div className="mainBox">
                    <div className="buttons-top">
                        <button className="button-cancel" onClick={() => props.cancelCrop()}>Cancelar</button>
                        <button className="button-cut" onClick={() => getImage()}>Cortar</button>
                    </div>
                    <Cropper
                        image={props.src}
                        crop={crop}
                        zoom={zoom2}
                        aspect={props.aspectRatio ? props.aspectRatio:1/1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom2}
                    />
                    <div className="zoom">
                        <h4>Zoom</h4>
                        <div className="buttons-bottom">
                            <button className="zoom-plus" onClick={() => minusZoom()}>&#45;</button>
                            <button className="zoom-minus" onClick={() => addZoom()}>&#43;</button>
                        </div>
                    </div>


                </div>

            </div>
        </React.Fragment>
    )
}

export { ImageCrop };
