import { BehaviorSubject } from 'rxjs';
import { authHeader, handleResponse } from '../helpers';

import md5 from 'md5';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
const tempCurrentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('tempCurrentUser')));
const preUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('preUser')));

const apiPath = process.env.REACT_APP_API_PATH;
export const authenticationService = {
    getUser,
    login,
    register,
    createAccount,
    recoverPassword,
    confirmRecoverPassword,
    acceptTerms,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    preUser: preUserSubject.asObservable(),
    get preUserValue() { return preUserSubject.value },
    tempCurrentUser: tempCurrentUserSubject.asObservable(),
    get tempCurrentUserValue() { return tempCurrentUserSubject.value },
    getApiUser,
    evalToken,
    getClientIp
};

function confirmRecoverPassword(parameters) {

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(parameters)
    };

    return fetch(`${apiPath}/user/recoverpassword`, requestOptions)
        .then(response => handleResponse(response, false, false))
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (user && user.data) {
                localStorage.removeItem('preUser');
                localStorage.setItem('currentUser', JSON.stringify(user.data));
                currentUserSubject.next(user);
            }

            return user;
        }, (error) => {
            console.log(error);
            return error;
        });
}

function recoverPassword(parameters) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters)
    };

    return fetch(`${apiPath}/user/recoverpassword`, requestOptions)
        .then(response => handleResponse(response, false, false))
        .then(preuser => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (preuser && preuser.data) {

                localStorage.setItem('preUser', JSON.stringify(preuser.data));
                preUserSubject.next(preuser);
            }

            return preuser;
        }, (error) => {
            console.log(error);
            return error;
        });
}

function acceptTerms(parameters) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters)
    };

    return fetch(`${apiPath}/user/terms`, requestOptions)
        .then(response => handleResponse(response, false, false))
        .then((response) => {
            if (response.message) {
                localStorage.removeItem('preUser');
                preUserSubject.next(null);

                let item = localStorage.getItem('tempCurrentUser');
                if (item) {
                    item = JSON.parse(item);
                    localStorage.removeItem('tempCurrentUser');
                    localStorage.setItem('currentUser', JSON.stringify(item));
                    currentUserSubject.next(item);
                }

                return response;

            }
            return response;
        }, (error) => {

            console.log(error);
            return error;
        });
}

function createAccount(parameters) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters)
    };

    return fetch(`${apiPath}/user/createaccount`, requestOptions)
        .then(response => handleResponse(response, false, false))
        .then((response) => {

            if (response.data && response.data.SUID && response.data.Usuario) {
                let user = response.data;
                localStorage.setItem('tempCurrentUser', JSON.stringify(user));
                tempCurrentUserSubject.next(user);
                return response;

            }
            return response;
        }, (error) => {
            return error
        });
}

function register(parameters) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters)
    };

    return fetch(`${apiPath}/user/register`, requestOptions)
        .then(response => handleResponse(response, false, false))
        .then(preuser => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (preuser && preuser.data) {
                localStorage.setItem('preUser', JSON.stringify(preuser.data));
                preUserSubject.next(preuser);
            }

            return preuser;
        }, (error) => {
            return error;
        });
}

function login(value, password, key) {
    let body = {
        Password: md5(password)
    }


    const IUID = localStorage.getItem('iuid');
    if (IUID) {
        body['IUID'] = IUID;
    }

    body[key] = value;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    };

    return fetch(`${apiPath}user/login`, requestOptions)
        .then(response => handleResponse(response, true, false))
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (user && user.data) {
                localStorage.removeItem('iuid');
                localStorage.removeItem('preUser');
                localStorage.removeItem('tempCurrentUser');
                localStorage.setItem('currentUser', JSON.stringify(user.data));
                currentUserSubject.next(user);
            }

            return user;
        }, (error) => {
            if(error.message){
                return error.message;
            }else{
                return 'Se produjo un error al ingresar';
            }
        });
}

function getUser() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };


    return new Promise((resolve, reject) => {
        const user = localStorage.getItem('currentUser');
        if (user) {
            return fetch(`${apiPath}/user`, requestOptions)
                .then(response => handleResponse(response, false, false))
                .then(user => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    if (user && user.data) {
                        let currentItem = localStorage.getItem('currentUser');

                        if (currentItem) {
                            try {
                                currentItem = JSON.parse(currentItem);
                                currentItem.Usuario = user.data;
                                localStorage.setItem('currentUser', JSON.stringify(currentItem));
                                currentUserSubject.next(currentItem);
                            } catch (e) {

                            }
                        }
                        // localStorage.removeItem('preUser');
                        // localStorage.removeItem('tempCurrentUser');
                        // localStorage.setItem('currentUser', JSON.stringify(user.data));
                        // currentUserSubject.next(user);
                    }

                    resolve(user);
                }, (error) => {
                    reject(error);
                });
        }
        reject(null);
    });
}


function getApiUser() {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return new Promise((resolve, reject) => {
        fetch(`${apiPath}/user`, requestOptions)
            .then(response => handleResponse(response, false, false))
            .then(user => {
                if (user && user.data) {
                    let currentItem = localStorage.getItem('currentUser');

                    if (currentItem) {
                        try {
                            currentItem = JSON.parse(currentItem);
                            currentItem.Usuario = user.data;
                            localStorage.setItem('currentUser', JSON.stringify(currentItem));
                            currentUserSubject.next(currentItem);
                        } catch (e) {
                            console.log('failure on catch in getApiUser');
                        }
                    }
                    resolve(user);
                }
                reject('No hay usuario');
            }, (error) => {
                reject(error);
            });
    });
}

function getClientIp() {
    const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    return new Promise((resolve, reject) => {
        fetch("https://api.ipify.org?format=json")
            .then(response => {
                return response.json();
            }, "jsonp")
            .then(res => {
                if(res && res.ip){
                    resolve(res);
                }else{
                    reject('Error al sacar ip');
                }
            })
            .catch(err => reject('Error al sacar ip'));
    });
}

function logout() {
    // remove user from local storage to log user out

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({})
    };
    return fetch(`${apiPath}/user/logout`, requestOptions)
        .then(response => handleResponse(response, true, true))
        .then(user => {
            localStorage.removeItem('preUser');
            localStorage.removeItem('tempCurrentUser');
            localStorage.removeItem('currentUser');
            currentUserSubject.next(null);
            console.log("logout");
            return user;
        }, (error) => {
            localStorage.removeItem('preUser');
            localStorage.removeItem('tempCurrentUser');
            localStorage.removeItem('currentUser');
            currentUserSubject.next(null);
            return error;
        });

}


function evalToken(parameters) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(parameters)
    };

    return fetch(`https://www.google.com/recaptcha/api/siteverify`, requestOptions)
        .then(response => handleResponse(response, false, false))
        .then(results => {

            return results;
        }, (error) => {
            return error;
        });
}
