import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { profileService } from '../../../../../_resources/services';
import back from '../../.././../../_assets/media/images/icons/icn_back_arrow.png'
import visa from '../../../../../_assets/media/images/icons/img_visa.png'
import amex from '../../../../../_assets/media/images/icons/img_amex.png'
import master from '../../../../../_assets/media/images/icons/img_mastercard.png'
import './MethodPay.scss'

const MethodPay = (props) => {

    const history = useHistory()
    props.onToggleTab(true)


    const [card, setCard] = useState([])
    const [membership, setMembership] = useState({})

    useEffect(() => {
       getData()
    }, [])


    const getData = () => {
        profileService.getCard().then(
            (response) => {
                setCard(response.data.cards[0])
            }
        )
    }

    return (
        <React.Fragment>
            <div className="container_membership">
                <div className="container_header_membership">
                    <img src={back} width="30px" height="30px" onClick={() => history.goBack()}/>
                    <h3>Membresía</h3>
                </div>


                <p className="title_method">Edita tu información de pago o cambia a tu forma de pago preferida.</p>

                <div className="data_card">
                    <div className={`container_data_option_detail_member`}>
                        <div className="container_data_option_detail_member_description no_margin">
                            <p>Nombre de titular</p>
                            <h5>{`${card.name}`}</h5>
                        </div>
                    </div>
                    <p className="subtitulo">Tarjeta de credito</p>
                    <div className={`container_data_option_detail_member flex`}>
                        <img src={card.brand === "visa" ? visa : card.brand === "mastercard" ? master : card.brand === "amex" ? amex : ""} width="50px" height="40px"/>
                        <div className="container_data_option_detail_member_description">
                            <p>{card.name}</p>
                            <h5>{`**** ${card.last4}`}</h5>
                        </div>
                    </div>
                    <div className={`container_data_option_detail_member mt-2`}>
                        <div className="container_data_option_detail_member_description no_margin">
                            <p>Fecha de vto.</p>
                            <h5>{`${card.exp_month}/${card.exp_year}`}</h5>
                        </div>
                    </div>
                </div>

                <button className="buttom_method_profile" onClick={() => history.push("/membership/2", {id: 2})}>Editar tarjeta</button>

            </div>
        </React.Fragment>
    );
}

export {MethodPay};
