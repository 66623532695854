import React, { useState, useEffect } from 'react';

import { toolsHelper, useDidMountEffect } from '../../../_resources/helpers';
import {swalAlertsService} from '../../../_resources/services/swal-alerts.service';

import './AudioRecorder.scss';

import icnsRecord from '../../../_assets/media/images/icons/icns_record.png';
import icnsSenders from '../../../_assets/media/images/icons/icns_senders.png';

// import { Recorder } from 'react-voice-recorder';
// import 'react-voice-recorder/dist/index.css';

import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import { useStopwatch } from 'react-timer-hook';

import { Timer } from '../';
import Countdown from 'react-countdown';

const AudioRecorder = (props) => {
    const [totalDuration, setTotalDuration] = useState(0);
    const [isRecording, setIsRecording] = useState(false);
    const [recordState, setRecordState] = useState(null);
    const [recordedData, setRecordedData] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const { seconds, minutes, hours, days, isRunning, start, pause, reset } = useStopwatch({ autoStart: false });

    const startRecord = () => {
        setRecordState(RecordState.START);
        start();
        setIsRecording(true);
        // countDown();
    }

    const pauseRecord = () => {
        setRecordState(RecordState.PAUSE);
        pause();
    }

    const stopRecord = () => {
        setRecordState(RecordState.STOP);
        pause();
        setIsRecording(false);
        // setTimeout(() => {
        //     if (props.stopped) {
        //         props.stopped();
        //     }
        // }, 1000);
    }

    const closeView = () => {
        if (props.stopped) {
            props.stopped();
        }
    }

    const convertToMB = (bytesValue) => {
        if (bytesValue === 0) return 0;
        const gbValue = (bytesValue / (1000 * 1000)).toFixed(2);
        return gbValue;
    }

    useDidMountEffect(() => {
        let value = false;
        if (isPlaying) {
            const audioElement = document.getElementById('recordedDataID');

            if (audioElement) {
                audioElement.play();
            }
        } else {
            const audioElement = document.getElementById('recordedDataID');

            if (audioElement) {
                audioElement.pause();
            }
        }
    }, [isPlaying]);

    async function toBase64(audioData) {
        const result = await toBase64(audioData).catch(e => console.log(e));
    }

    //audioData contains blob and blobUrl
    async function onStop(audioData) {
        if (audioData && audioData.blob) {
            const base64 = await toolsHelper.toBase64(audioData.blob);
            
            setRecordedData({ base64, type: audioData.blob.type, size: audioData.blob.size});
            console.log({ base64, type: audioData.blob.type, size: audioData.blob.size});
            pause();
            if ( convertToMB(audioData.blob.size) > 20  ){
                swalAlertsService.showToast('error', 'El tamaño de los audios no pueden exceder los 10mb');
                clearRecord();
            }
        }
    }

    const clearRecord = () => {
        closeView();
    }

    // const timeLeftCounter = () => {
    //     if (props.duration > 0 && audioRef.current) {
    //         const duration = parseInt(props.duration);
    //         const currentTime = parseInt(audioRef.current.currentTime);
    //         const timeLeft = duration - currentTime;
    //
    //         let s = timeLeft % 60;
    //         let m = Math.floor( timeLeft / 60 ) % 60;
    //         s = s < 10 ? "0"+s : s;
    //         m = m < 10 ? "0"+m : m;
    //
    //         setTimeCounter(`${m}:${s}`);
    //     }
    // }

    const getCounterTime = () => {
        const time = new Date();
        const daysToSeconds = days * 24 * 60 * 60;
        const minutesToSeconds = minutes * 60;
        const hoursToSeconds = (hours * 60) * 60;
        const duration = seconds + minutesToSeconds + hoursToSeconds + daysToSeconds;
        console.log(duration);
        time.setSeconds(time.getSeconds() + duration);
        return time;
    }

    const counterRender = (status) => {
        return (
            <div className={`AudioRecorder-timer-container col-12 ${status}`}>
                {
                    days ? (
                        <>
                            <span>{`${days < 10 ? `0${days}`:days}`}</span>:
                        </>):(null)
                }
                {
                    hours ? (
                        <>
                            <span>{`${hours < 10 ? `0${hours}`:hours}`}</span>:
                        </>):(null)
                }
                <span>{`${minutes < 10 ? `0${minutes}`:minutes}`}</span>:<span>{`${seconds < 10 ? `0${seconds}`:seconds}`}</span>
            </div>
        );
    }

    const getRecordedDataElement = () => {
        if (recordedData) {
            return (<audio src={recordedData.base64} id='recordedDataID' onPause={e => onPlayRecord} onEnded={e => setIsPlaying(false)}></audio>);
        }
        return null;
    }

    const onPlayRecord = (value) => {
        setIsPlaying(!isPlaying);
    }

    const recordButtonsRender = () => {
        if (isRecording) {
            return (
                <div className="col-12 p-0 AudioRecorder-buttons-container mouse-pointer" onClick={stopRecord}>
                    <button className="btn-cleared">
                        <img src={icnsRecord} alt="icnsRecord" className='stop'/>
                    </button>

                    <span className='font-color-green statusLabel'>
                        Grabando
                    </span>
                    {counterRender('stop')}
                </div>
            );
        } else if (recordedData && !isPlaying) {
            return (
                <div className="col-12 p-0 AudioRecorder-buttons-container mouse-pointer" onClick={onPlayRecord}>
                    {getRecordedDataElement()}
                    <button className="btn-cleared">
                        <img src={icnsRecord} alt="icnsRecord" className='play'/>
                    </button>

                    <span className='font-color-blue statusLabel'>
                        Reproducir audio
                    </span>
                    {counterRender('play')}
                </div>
            );
        } else if (recordedData && isPlaying) {

            return (
                <div className="col-12 p-0 AudioRecorder-buttons-container mouse-pointer" onClick={onPlayRecord}>
                    {getRecordedDataElement()}
                    <button className="btn-cleared">
                        <img src={icnsRecord} alt="icnsRecord" className='playing'/>
                    </button>

                    <span className='font-color-blue statusLabel'>
                        Reproduciendo audio
                    </span>
                    {
                        // <Timer expiryTimestamp={getCounterTime()} />
                    }
                    <div className='emptyCounter'></div>
                </div>
            );

        } else {
            return (
                <div className="col-12 p-0 AudioRecorder-buttons-container mouse-pointer" onClick={startRecord}>
                    <button className="btn-cleared">
                        <img src={icnsRecord} alt="icnsRecord" className='record'/>
                    </button>

                    <span className='font-color-blue statusLabel'>
                        Grabar audio
                    </span>
                    {counterRender('record')}
                </div>
            );
        }
    }

    const recordActionsRender = () => {
        if (isRecording || !recordedData) {
            return (
                <div className="col-12 px-0 AudioRecorder-actions-container cancelBox">
                    <button className="btn-cleared" onClick={clearRecord}>
                        Cancelar
                    </button>
                </div>
            );
        } else if (recordedData && !isRecording) {
            return (
                <div className="col-12 px-0 AudioRecorder-actions-container submitBox">
                    <button className="btn-cleared cancel" onClick={clearRecord}>
                        <img src={icnsSenders} alt="icnsSenders" className='cancel'/>
                        <span className='font-color-red'>Borrar</span>
                    </button>

                    <button className="btn-cleared submit" onClick={onSubmitBtnTapped}>
                        <img src={icnsSenders} alt="icnsSenders" className='submit'/>
                        <span className='font-color-green'>Enviar</span>
                    </button>
                </div>
            );
        }
    }

    const onSubmitBtnTapped = () => {
        if (props.submit && recordedData) {
            props.submit({file:recordedData});
        }
    }

    const requestMicrophone = () => {
        if(navigator.mediaDevices != undefined) {
            navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
                setHasPermission(true);
            }).catch((err) => {
                setHasPermission(false);
            })
        } else {
            setHasPermission(false);
        }
    }

    const noPermissionView = () => {
        return (
            <div className="AudioRecorder-nopermission-container col-12">
                <p>Permiso de micrófono bloqueado</p>
                <span className='subTxt'>Asegurate de permitir el uso del micrófono para poder enviar un mensaje de voz.</span>
                <span>
                    <button className="btn-cleared submitBtn" onClick={requestMicrophone}>
                        Permitir
                    </button>
                    <button className="btn-cleared cancelBtn" onClick={closeView}>
                        Cancelar
                    </button>
                </span>
            </div>
        );
    }

    useEffect(() => {
        requestMicrophone();
    }, []);

    return (
        <div className="AudioRecorder-container row m-0 justify-content-center">
            {
                hasPermission ? (
                    <>
                        <AudioReactRecorder state={recordState} onStop={onStop} type='audio/webm'/>

                        {recordButtonsRender()}
                        {recordActionsRender()}
                    </>
            ):(noPermissionView())
            }
        </div>
    );
}

export { AudioRecorder };
