import React, { useState, useEffect } from 'react';

import './ModalDefault.scss';

import { Modal } from 'react-bootstrap';
// import PropTypes from 'prop-types';

// const propTypes = {
//     children: PropTypes.node,
// };
//
// const defaultProps = {};

const ModalDefault = (props) => {
    //size={`${this.props.modalSize || 'md'}`}
    // className={`${this.state.className}`}
    return (
        <Modal show={props.show} onHide={props.onHide}
            aria-labelledby="ModalDefault-main-box"
            centered
            className={`ModalDefault-main-box ${props.className ? props.className:''} ${props.overAll ? 'overAll':''}`}>

            {
                // this.props.showCloseBtn ? (
                //     this.props.showCloseBtn
                // ):(<Modal.Header closeButton/>)
            }

            <Modal.Body>
                {
                    props.children ? props.children:("Contenido")
                }
            </Modal.Body>

            {
                // <Modal.Footer className='d-none'>
                //     <div className="row m-0 d-flex justify-content-center w-100 py-2 ">
                //         {
                //             this.props.buttonShow && !this.props.isUploading ? (
                //                 <div className="row m-0 d-flex justify-content-center w-100">
                //                     <div className="col-4">
                //                         <button type="button" className="btn-standard w-100 primary-bg toggle-content" onClick={this.sendToParent}>
                //                             {this.props.buttonTitle}
                //                         </button>
                //                     </div>
                //                 </div>):(null)
                //         }
                //     </div>
                // </Modal.Footer>
            }
      </Modal>
    );
}

// ModalDefault.propTypes = propTypes;
// ModalDefault.defaultProps = defaultProps;
export { ModalDefault };
