import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const WSwal = withReactContent(Swal);

function showToast(type, message) {

    const Toast = WSwal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,

    })

    Toast.fire({
        icon: type,
        title: message
    })
}

function showToastCenter(type, message) {

    const Toast = WSwal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,

    })

    Toast.fire({
        icon: type,
        title: message
    })
}


function createLoading(title = 'Procesando los datos', message = 'En breve estará listo') {
    let obj = Swal.mixin({
        title: title,
        html: message,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
           Swal.showLoading()
        }
    });

    return obj;
}

function answer(title = "", descripcion, accept, cancel = 'Cancelar') {
    let obj =  Swal.fire({
        title: title,
        text: descripcion,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0FB855',
        cancelButtonColor: '#d33',
        confirmButtonText: accept,
        cancelButtonText: cancel
      })

    return obj
}

function confirm(icon, title, text, callBack) {

    Swal.fire({
        icon: icon,
        title: title,
        text: text,
        showConfirmButton: true,
        confirmButtonColor: '#0FB855',
        willClose: () => {
            if (callBack) {
                callBack();
            }
        }
    })
}



export const swalAlertsService = {
    showToast,
    createLoading,
    answer,
    confirm,
    showToastCenter
};
