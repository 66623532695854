/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BehaviorSubject } from 'rxjs'
import back from '../../../../_assets/media/images/icons/icn_back_arrow.png'
import more from '../../../../_assets/media/images/icons/icn_more.png'
import logo from '../../../../_assets/media/images/icons/logo_icecode.svg'
import { authenticationService, swalAlertsService } from '../../../../_resources/services';

import {
    NotificationsSheet,
    MessagesBubble,
    Header
} from '../../../shared';

import { SettingsSide } from './settings-side/SettingsSide';

import { Preferences } from '../preferences/Preferences';
import { BloquedUsers } from '../bloqued-users/bloquedusers';
import { RestrictedUsers } from '../restricted-users/RestrictedUsers';
import { NewPassword } from '../new-password/NewPassword';
import { Support } from '../support/Support';

import { useWindowDimensions } from '../../../../_resources/helpers';
import "./settings.scss"
import { settingService } from '../../../../_resources/services/settings.service';
import { Notifications } from '../notifications/Notifications';

const Settings = (props) => {
    props.onToggleTab(true)
    const history = useHistory()

    const [version, setvVersion] = useState({});

    const {width, height} = useWindowDimensions();

    const [optionPicked, setOptionPicked] = useState('preferences');

    useEffect(() => {
        const currentVersionSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('infoVersion')));
        setvVersion(currentVersionSubject._value)
    }, [])

    const logOut = () => {
        swalAlertsService.answer("", "¿Estas seguro de que quieres cerrar sesión?", "Si, cerrar sesión").then((result) => {
            if (result.isConfirmed) {
                authenticationService.logout().then((response) => {
                    history.replace('/welcome');
                    window.location.reload();
                });
            }
        })
    }

    const deleteAccount = ()=>{
        swalAlertsService.answer("Advertencia", "¿Esta seguro de que quieres eliminar su cuenta?", "Si").then((result) => {
            if (result.isConfirmed) {
                swalAlertsService.answer("Advertencia", "¿Esta seguro de que quieres eliminar su cuenta? Esta acción es definitiva y los datos no podran ser recuperados. Si desea seguir utilizando  Wenet deberá de crear una cuenta nueva", "Si").then((result) => {
                    if (result.isConfirmed) {
                        settingService.deleteAccount().then( (response) => {
                            authenticationService.logout().then((response) => {
                                history.replace('/welcome');
                                window.location.reload();
                            });
                        })
                        
                    }
                })
            }
        })
    }

    const mobileRender = () => {

        return (
            <div className="container-settings bg-white">
                <div className="container-settings-header">
                    <img src={back} width="30px" height="30px" onClick={() => history.goBack()}/>
                    <h3>Configuración</h3>
                </div>

                <div className="container-settings_settings">

                    <div className="container-settings_option" onClick={() => history.push("/setting/preferences")}>
                        <p>Filtro permanente de publicaciones</p>
                        <img src={more} width="40px" height="40px"></img>
                    </div>

                    <div className="container-settings_option" onClick={() => history.push("/setting/bloqued_users")}>
                        <p>Bloqueo permanente de usuarios</p>
                        <img src={more} width="40px" height="40px"></img>
                    </div>

                    <div className="container-settings_option" onClick={() => history.push("/setting/silence_users")}>
                        <p>Silencio permantente de usuarios</p>
                        <img src={more} width="40px" height="40px"></img>
                    </div>

                    <div className="container-settings_option" onClick={() => history.push("/setting/new_password")}>
                        <p>Cambiar contraseña</p>
                        <img src={more} width="40px" height="40px"></img>
                    </div>

                    <div className="container-settings_option" onClick={() => history.push("/setting/notifications")}>
                        <p>Administrar notificaciones</p>
                        <img src={more} width="40px" height="40px"></img>
                    </div>

                    <div className="container-settings_option jump" onClick={() => history.push("/setting/support")}>
                        <p>Soporte</p>
                        <img src={more} width="40px" height="40px"></img>
                    </div>

                    <a href={version.FaqUrl} target="_blank" rel="noreferrer">
                        <div className="container-settings_option" >
                            Preguntas frecuentes
                            <img src={more} width="40px" height="40px"></img>
                        </div>
                    </a>

                    <a href={version.TermsUrl} target="_blank" rel="noreferrer">
                        <div className="container-settings_option" >
                            Términos y condiciones
                            <img src={more} width="40px" height="40px"></img>
                        </div>
                    </a>

                    <a href={version.PrivacyUrl} target="_blank" rel="noreferrer">
                        <div className="container-settings_option" >
                            Aviso de privacidad
                            <img src={more} width="40px" height="40px"></img>
                        </div>
                    </a>

                    <a href={version.RulesUrl} target="_blank" rel="noreferrer">
                        <div className="container-settings_option jump" >
                            Reglas de uso
                            <img src={more} width="40px" height="40px"></img>
                        </div>
                    </a>



                    <div className="container-settings_option jump" onClick={() => logOut()}>
                        <p className="logout">Cerrar sesión</p>
                    </div>
                    <div className="container-settings_option jump" onClick={() => deleteAccount()}>
                        <p className="logout">Eliminar mi cuenta</p>
                    </div>


                    <div className="container-settings_logo jump">
                        <a href={"https://icecode.mx"} target="_blank" rel="noreferrer">Desarrollado en México por:</a>
                        <a href={"https://icecode.mx"} target="_blank" rel="noreferrer"><img src={logo} width="150px" height="100px"/></a>
                    </div>


                </div>
            </div>
        );
    }

    const onOptionTapped = (option) => {
        if (option) {
            if (option === 'logout') {
                logOut();
            }else if(option === 'deleteAccount'){
                deleteAccount();
            }else {
                setOptionPicked(option);
            }
        }
    }

    const desktopRender = () => {
        return (
            <div className="Settings-container">
                <Header hideSearcher={true}/>

                <div className="row m-0 d-flex justify-content-center Settings-box-container">
                    <div className="col Settings-lateral-content left">
                        <div className="row m-0 pos-sticky isTop-24">
                            <div className="col-12 px-0 py-3">
                                <SettingsSide optionActive={optionPicked}
                                    onOptionTapped={onOptionTapped}
                                    FaqUrl={version.FaqUrl}
                                    TermsUrl={version.TermsUrl}
                                    PrivacyUrl={version.PrivacyUrl}
                                    RulesUrl={version.RulesUrl}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col Settings-post-box">
                        {desktopOptionPicked()}
                    </div>
                    <div className="col Settings-lateral-content left">
                        <div className="row m-0 pos-sticky isTop-10">
                            <div className="col-12 px-0 pb-3">
                                <NotificationsSheet/>
                            </div>
                        </div>
                    </div>

                </div>
                <MessagesBubble isHome={true}/>
            </div>

        );
    }

    const desktopOptionPicked = () => {
        switch (optionPicked) {

            case 'preferences':
                return <Preferences isDesktop={true}/>
                break;
            case 'bloqued_users':
                return <BloquedUsers isDesktop={true}/>
                break;
            case 'silence_users':
                return <RestrictedUsers isDesktop={true}/>
                break;
            case 'new_password':
                return <NewPassword isDesktop={true}/>
                break;
            case 'support':
                return <Support isDesktop={true}/>
                break;
            case 'notifications':
                return <Notifications isDesktop={true}/>
                break;
            default:
                break;
        }
        return null;
    }

    return (
        <React.Fragment>
            {
                width && width >= 1450 ? (desktopRender()):(mobileRender())
            }
        </React.Fragment>
    )
}


export {Settings}
