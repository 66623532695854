import React, { useState, useEffect } from "react";

import "./AddPostCarousel.scss";
import icn_bullet_select from "../../../../../_assets/media/images/icons/icn_bullet_actived.png";
import icn_bullet from "../../../../../_assets/media/images/icons/icn_bullet.png";

const AddPostCarousel = (props) => {
  let postSelected = props.postTypeSelected
    ? props.postTypeSelected
    : { clv_tipopublicacion: 0 };
  const [categoryPostSelected, setCategoryPostSelected] = useState({
    clv_categoriapublicacion: 1,
  });

  const onCarouselItemPicked = (item) => {
    if (props.onCarouselItemPicked) {
      props.onCarouselItemPicked(item);
    }
  };

  const onClickCategoryPost = (category) => {
    setCategoryPostSelected(category);
    props.onSubcategoryItemPicked(category);
  };

  const prepareCarouselItems = () => {
    if (
      props.catalogs &&
      props.catalogs.TiposPublicacion &&
      props.catalogs.TiposPublicacion.length
    ) {
      return props.catalogs.TiposPublicacion.map((item, index) => {
        if (!item.Publicar) {
          return null;
        }
        let selected =
          props.postTypeSelected &&
          props.postTypeSelected.clv_tipopublicacion ===
            item.clv_tipopublicacion
            ? "selected"
            : "";

        return (
          <div
            className={`col AddPostCarousel-postType-item mouse-pointer noselect ${selected}`}
            key={index}
            onClick={(e) => onCarouselItemPicked(item)}
          >
            <img
              className="icn-select"
              src={
                props.postTypeSelected &&
                props.postTypeSelected.clv_tipopublicacion ===
                  item.clv_tipopublicacion
                  ? icn_bullet_select
                  : icn_bullet
              }
              width={30}
              height={30}
              alt=""
            />
            {item.TipoPublicacion}
          </div>
        );
      });
    }
    return null;
  };

  const prepareSubCategories = () => {
    if (
      props.catalogs &&
      props.catalogs.CategoriasPublicacion &&
      props.catalogs.CategoriasPublicacion.length
    ) {
      return props.catalogs.CategoriasPublicacion.map((category, index) => {
        return (
          <div
            className={`Home-filter-ment-item ${
              categoryPostSelected &&
              category.clv_categoriapublicacion ===
                categoryPostSelected.clv_categoriapublicacion
                ? "selected"
                : ""
            }`}
            key={category.id}
            onClick={() => onClickCategoryPost(category)}
          >
            {category.CategoriaPublicacion}
          </div>
        );
      });
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="AddPostCarousel-container row m-0 flex-nowrap">
        {prepareCarouselItems()}
      </div>
      {postSelected.clv_tipopublicacion === 4 ? (
        <div className="AddPostCarousel-subcategory-container row m-0 flex-nowrap">
          {prepareSubCategories()}
        </div>
      ) : null}
    </>
  );
};

export { AddPostCarousel };
