/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import './Messages.scss';

import { useHistory } from 'react-router-dom';


import { messagesService, swalAlertsService, algoliaService, authenticationService } from '../../../_resources/services';
import { toolsHelper, useDidMountEffect, useWindowDimensions } from '../../../_resources/helpers';

import {
    MessagesHeader,
    MessagesList,
    MessagesChat,
    MessagesDesktopList,
    MessagesDesktopChat
} from './messages-components';
import {
    ContactList,
    BottomSheetShared,
    NoResults,
    GroupDetails,
    Header
} from '../../shared';

import icnSearch from '../../../_assets/media/images/icons/icn_search.png';
import icnCancel from '../../../_assets/media/images/icons/icn_cancel.png';
import icnBroadcast from '../../../_assets/media/images/icons/icn_broadcast.png';
import icnCheckBox from '../../../_assets/media/images/icons/icn_checkbox.png';
import icnCheckBoxSelected from '../../../_assets/media/images/icons/icn_checkbox_selected.png';
import { getMessagin } from '../../../firebaseInit';

const Messages = (props) => {
    const history = useHistory();
    const [isHidingTab, setIsHidingTab] = useState(false);
    const [groupDetailsItem, setGroupDetailsItem] = useState({
        group: null,
        showDetails: false
    });
    const [isNewChatOpen, setIsNewChatOpen] = useState(false);
    const [messagesState, setMessagesState] = useState({
        searchTerm: null,
        chats: [],
        chat: null,
        view: 'list',
        isMassive: null,
        massiveChat: [],
        isGroup: false,
        groupDetails: null,
        isNew: false,
        receivedObj:{
            chat: null,
            massiveChat: [],
            groupDetails: null
        },
        Broadcast : {
            Limite: null,
            Mensajes: null
        },
        reply: null
    });

    // const [isActiveInterval, setIsActiveInterval] = useState(null);
    // const [activeInterval, setActiveInterval] = useState(null);
    const [seconds, setSeconds] = useState(null);

    const [currentUser, setCurrentUser] = useState(null);

    const {width, height} = useWindowDimensions();

    const [updateDot, setUpdateDot] = useState(false);

    const [sendFailed, setSendFailed] = useState(false);
    const [failedIds, setFailedIds] = useState([]);

    const [sendSuccess, setSendSuccess] = useState(false);
    const [successIds, setSuccessIds] = useState([]);
    const [contacts, setContacts] = useState([]);

    let messaging = getMessagin();

    if(messaging){
        messaging.onMessage((payload) => {
            setUpdateDot(true);
            if (payload && payload.data && payload.data.clv_tipoaccion === "1" && payload.data.Parametro) {
                inCommingMessage();
            }else if (payload && payload.data && payload.data.clv_tipoaccion === "12") {
                // getUser(true);
            }
        });
    }


    const onSearcherChanged = (searchTerm) => {
        if (messagesState.searchTerm !== searchTerm) {
            let messagesStateCP = JSON.parse(JSON.stringify(messagesState));
            if (searchTerm === '' && messagesStateCP.searchTerm === null) {
                messagesStateCP.searchTerm = null;
            } else {
                messagesStateCP.searchTerm = searchTerm;
            }
            setMessagesState(messagesStateCP);
        }
    }

    const onNewChatBtnTapped = () => {
        setIsNewChatOpen(true);
    }

    const onBottomSheetDimiss = () => {
        setIsNewChatOpen(false);
    }

    async function loadData() {
        return await messagesService.getMessages('');
    }

    async function loadDataAlgolia() {
        let parameters = {
            hitsPerPage: 500,
            numericFilters: [`clv_usuario=${currentUser.Usuario.clv_usuario}`]
        }

        return await algoliaService.getResults(messagesState.searchTerm, parameters, 'Conversaciones', false);
    }

    const contactsNumericFilters = () => {
        let numericFilters = [];
        
        if (currentUser && currentUser.Usuario && currentUser.Usuario.clv_usuario) {
            numericFilters.push(`BloqueadoPor.clv_usuario!=${currentUser.Usuario.clv_usuario}`)
            numericFilters.push(`clv_usuario!=${currentUser.Usuario.clv_usuario}`)
        }
        
        if (messagesState.chats && messagesState.chats.length) {
            messagesState.chats.forEach((item) => {
                if (item.clv_tipoconversacion == 1 && item.clv_destinatario) {
                    numericFilters.push(`clv_usuario!=${item.clv_destinatario}`)
                }
            });
        }
        
        return numericFilters;
    }

    async function loadContactsDataAlgolia() {
        let parameters = {
            hitsPerPage: 500,
            numericFilters: contactsNumericFilters()
        }

        return await algoliaService.getResults(messagesState.searchTerm, parameters, 'Directorio', false);
    }

    const toggleTab = (value) => {
        if (props.onToggleTab) {
            props.onToggleTab(value);
        }
    }

    const handleReceibedChat = () => {
        const {location} = props;
        let messagesStateCP = JSON.parse(JSON.stringify(messagesState));
        if (location && location.state &&
            (
                (location.state.massiveChat && location.state.massiveChat.length) ||
                location.state.groupDetails ||
                location.state.chat
            )
        ) {
            if (location.state.chat && !messagesStateCP.receivedObj.chat) {
                messagesStateCP.receivedObj.chat = location.state.chat;
                messagesStateCP.receivedObj.massiveChat = [];
                messagesStateCP.receivedObj.groupDetails = null;
            } else if (location.state.massiveChat && location.state.massiveChat.length && !messagesStateCP.receivedObj.massiveChat.length) {
                messagesStateCP.receivedObj.chat = null;
                messagesStateCP.receivedObj.massiveChat = location.state.massiveChat;
                messagesStateCP.receivedObj.groupDetails = null;
            } else if (location.state.groupDetails) {
                messagesStateCP.receivedObj.chat = null;
                messagesStateCP.receivedObj.massiveChat = [];
                messagesStateCP.receivedObj.groupDetails = location.state.groupDetails;
            }
        }

        if (history.location.state && (history.location.state.chat || history.location.state.massiveChat || history.location.state.groupDetails)) {
            let state = { ...history.location.state };
            delete state.chat;
            delete state.massiveChat;
            delete state.groupDetails;
            history.replace({ ...history.location, state });
        }

        return messagesStateCP;
    }

    const getStoredMessages = () => {
        let storedMessages = localStorage.getItem('storedMessages');

        if (storedMessages) {
            try {
                storedMessages = JSON.parse(storedMessages);

                if (storedMessages.length) {
                    let messagesStateCP = JSON.parse(JSON.stringify(messagesState));


                    if (storedMessages[0].clv_tipoconversacion === 2) {
                        messagesStateCP.isGroup = true;
                        messagesStateCP.groupDetails = storedMessages[0];
                    } else {
                        messagesStateCP.chat = storedMessages[0];
                    }
                    messagesStateCP.chats = storedMessages;
                    // messagesStateCP.view = 'chat';
                    setMessagesState(messagesStateCP);
                }
            } catch (e) {
            }
        }
    }

    const inCommingMessage = ()=> {
        messagesService.getMessages().then((response) => {
            if(response && response.data && response.data.Activos && response.data.Activos.length){
                // props.chats = response.data.Activos
                let chats = response.data.Activos;
                try {
                    if (chats.length) {
                        let messagesStateCP = JSON.parse(JSON.stringify(messagesState));
                        messagesStateCP.chats = chats;
                        // messagesStateCP.view = 'chat';
                        setMessagesState(messagesStateCP);
                    }
                } catch (e) {
                }
            }
        });
            
    }

    const endMessage = ()=>{
        setUpdateDot(false);
    }

    const storeMessages = (items) => {
        if (items && items.length) {
            localStorage.setItem('storedMessages', JSON.stringify(items));
        }
    }

    const handleResponse = (response, messagesStateParam) => {
        if (
            response &&
            response.data &&
            response.data.Activos
        ) {
            let messagesStateCP = messagesStateParam ? messagesStateParam:JSON.parse(JSON.stringify(messagesState));
            messagesStateCP.chats = response.data.Activos;
            messagesStateCP.Broadcast = response.Broadcast;

            if (!messagesStateCP.isMassive && !messagesStateCP.massiveChat.length) {
                if (messagesStateCP.receivedObj.chat || messagesStateCP.receivedObj.massiveChat.length || messagesStateCP.receivedObj.groupDetails) {
                    messagesStateCP.view = 'chat';
                    if (messagesStateCP.receivedObj.chat) {
                        messagesStateCP.chat = messagesStateCP.receivedObj.chat;
                        let filtered = messagesStateCP.chats.filter(el => el.clv_destinatario === messagesStateCP.receivedObj.chat.clv_destinatario);
                        if (!filtered.length) {
                            messagesStateCP.chats.unshift(messagesStateCP.receivedObj.chat);
                        }
                    } else if (messagesStateCP.receivedObj.massiveChat.length) {
                        messagesStateCP.chat = null;
                        messagesStateCP.massiveChat = messagesStateCP.receivedObj.massiveChat;
                        messagesStateCP.isMassive = true;
                    } else {
                        messagesStateCP.chat = null;
                        messagesStateCP.groupDetails = messagesStateCP.receivedObj.groupDetails;
                        messagesStateCP.isGroup = true;
                    }
                } else {

                    if (!messagesStateCP.chat && !messagesStateCP.groupDetails && response.data.Activos.length) {
                        let chat = response.data.Activos[0];

                        messagesStateCP.chat = null;
                        messagesStateCP.groupDetails = null;
                        messagesStateCP.isGroup = false;
                        if (chat.clv_tipoconversacion === 1) {
                            messagesStateCP.chat = chat;
                        } else if (chat.clv_tipoconversacion === 2) {
                            messagesStateCP.groupDetails = chat;
                            messagesStateCP.isGroup = true;
                        }
                    }

                }
            }
            storeMessages(messagesStateCP.chats);
            messagesStateCP.reply = null;
            setMessagesState(messagesStateCP);
        }
    }

    const getMesage = (item) => {
        const message = messagesState.chats.findIndex( chat => chat.clv_conversacion === item.clv_conversacion)
        messagesState.chats[message].NoLeidos = 0

    }

    const getUser = (isSuscribed)=>{
        authenticationService.currentUser.subscribe((user) => {
            if (user && user.SUID && user.Usuario && isSuscribed) {
                setCurrentUser(user);
            }
        });
    }

    useEffect(() => {
        let isSuscribed = true;
        getStoredMessages();
        onChatUpdate();
        toggleTab(true);
        getUser(isSuscribed);
        const {location} = props;
        if (location && location.state && location.state.shouldReload) {


            if (history.location.state && history.location.state.shouldReload) {
                let state = { ...history.location.state };
                delete state.shouldReload;
                history.replace({ ...history.location, state });
            }
            window.location.reload();

        }

        if (!messagesState.chats.length) {
            setTimeout(() => {
                const messages = loadData();

                messages.then((response) => {
                    if (isSuscribed) {
                        handleResponse(response, handleReceibedChat());
                    }
                });
            }, 200);
        }
        return () => isSuscribed =  false

    }, []);

    useEffect(() => {
        let isSuscribed = true;
        if (seconds !== null) {
            if (messagesState.searchTerm) {
                const messages = loadDataAlgolia();
                messages.then((response) => {

                    if (isSuscribed) {
                        handleAlgoliaResponse(response, handleReceibedChat());
                    }
                });
            } else if (messagesState.searchTerm === '') {
                const messages = loadData();

                messages.then((response) => {
                    if (isSuscribed) {
                        handleResponse(response, handleReceibedChat());
                    }

                });
            }
        }
        return () => isSuscribed = false
    },[seconds]);

    useEffect(() => {
        let isSuscribed = true;

        if (messagesState.isMassive !== null && !messagesState.isMassive && !messagesState.chat) {
            const messages = loadData();

            messages.then((response) => {
                if (
                    isSuscribed &&
                    response &&
                    response.data &&
                    response.data.Activos
                ) {

                    let messagesStateCP = handleReceibedChat();
                    messagesStateCP.chats = response.data.Activos;

                    if (!messagesStateCP.isMassive && !messagesStateCP.massiveChat.length && !messagesStateCP.chat && !messagesStateCP.groupDetails && response.data.Activos.length) {
                        let chat = response.data.Activos[0];

                        messagesStateCP.chat = null;
                        messagesStateCP.groupDetails = null;
                        messagesStateCP.isGroup = false;
                        if (chat.clv_tipoconversacion === 1) {
                            messagesStateCP.chat = chat;
                        } else if (chat.clv_tipoconversacion === 2) {
                            messagesStateCP.groupDetails = chat;
                            messagesStateCP.isGroup = true;
                        }
                    }
                    setMessagesState(messagesStateCP);
                }
            });
        }
        return () => isSuscribed =  false

    }, [messagesState.isMassive]);

    useEffect(() => {
        let isSuscribed = true;

        if (messagesState.searchTerm) {
            const messages = loadDataAlgolia();
            messages.then((response) => {
                if (isSuscribed) {
                    handleAlgoliaResponse(response, handleReceibedChat(), () => {
                        const contactsResponse = loadContactsDataAlgolia();

                        contactsResponse.then((response) => {
                            setContacts(response);
                        });
                    });
                }
            });
        } else if (messagesState.searchTerm === '') {
            const messages = loadData();

            messages.then((response) => {
                if (isSuscribed) {
                    handleResponse(response, handleReceibedChat());
                }
            });

            setContacts([]);
        }
        return () => isSuscribed =  false

    }, [messagesState.searchTerm]);

    const updateChats = () => {
        if (messagesState.searchTerm) {
            const messages = loadDataAlgolia();
            messages.then((response) => {
                handleAlgoliaResponse(response, handleReceibedChat());
            });
        } else if (!messagesState.searchTerm) {
            const messages = loadData();

            messages.then((response) => {
                handleResponse(response, handleReceibedChat());
            });
        }
    }

    const handleAlgoliaResponse = (response, messagesStateParam, callBack = null) => {
        if (
            response
        ) {
            let messagesStateCP = messagesStateParam ? messagesStateParam:JSON.parse(JSON.stringify(messagesState));
            messagesStateCP.chats = response;

            if (
                !messagesStateCP.isMassive &&
                !messagesStateCP.massiveChat.length &&
                !messagesStateCP.chat &&
                !messagesStateCP.groupDetails
            ) {
                let chat = response.length ? response[0]:null;

                messagesStateCP.chat = null;
                messagesStateCP.groupDetails = null;
                messagesStateCP.isGroup = false;
                if (chat.clv_tipoconversacion === 1) {
                    messagesStateCP.chat = chat;
                } else if (chat.clv_tipoconversacion === 2) {
                    messagesStateCP.groupDetails = chat;
                    messagesStateCP.isGroup = true;
                }
            }
            storeMessages(messagesStateCP.chats);
            setMessagesState(messagesStateCP);
        }

        if (callBack) {
            callBack();
        }
    }

    const onUserSelected = (item) => {
        let formattedItem = toolsHelper.chatObjBuilder(item);
        if (formattedItem) {

            let messagesStateCP = JSON.parse(JSON.stringify(messagesState));
            let chatFiltered = messagesStateCP.chats.filter(chat => chat.clv_destinatario === formattedItem.clv_destinatario);
            if (chatFiltered.length) {
                messagesStateCP.chat = chatFiltered[0];
            } else {
                messagesStateCP.chat = formattedItem;
                messagesStateCP.chats.unshift(formattedItem);
                messagesStateCP.chat.isNew = true;
            }

            messagesStateCP.view = 'chat';
            messagesStateCP.isMassive = false;
            messagesStateCP.massiveChat = [];
            messagesStateCP.isGroup = false;
            messagesStateCP.groupDetails = null;
            setMessagesState(messagesStateCP);
            setIsNewChatOpen(false);
        }
    }

    const onContactSelected = (item) => {
        console.log(item);
    }

    const onChatSelected = (item) => {
        if (item) {
            setTimeout(() => {
                onChatUpdate()
            }, 1000);
            let messagesStateCP = JSON.parse(JSON.stringify(messagesState));
            messagesStateCP.chat = null;
            messagesStateCP.isGroup = false;
            messagesStateCP.groupDetails = null;
            let filteredChat = messagesState.chats.findIndex( chatFilter => chatFilter.CUID === item.CUID);

            setTimeout(() => {
                if(filteredChat > -1 && messagesStateCP.chats && messagesStateCP.chats[filteredChat]){
                    messagesStateCP.chats[filteredChat].NoLeidos = 0;
                 } 
            }, 1000);

           

            if (item.clv_tipoconversacion === 1) {
                messagesStateCP.chat = item;
            } else if (item.clv_tipoconversacion === 2) {
                messagesStateCP.isGroup = true;
                messagesStateCP.groupDetails = item;
            }

            messagesStateCP.view = 'chat';
            messagesStateCP.isMassive = false;
            messagesStateCP.massiveChat = [];
            setMessagesState(messagesStateCP);
            
            setSendFailed(false);
            setSendSuccess(false);

            setSuccessIds([]);
            setFailedIds([]);

        }
    }

    const onBackBtnTapped = () => {
        let messagesStateCP = JSON.parse(JSON.stringify(messagesState));
        messagesStateCP.view = 'list';
        messagesStateCP.chat = null;
        messagesStateCP.isMassive = false;
        messagesStateCP.massiveChat = [];
        messagesStateCP.isGroup = false;
        messagesStateCP.groupDetails = null;
        setMessagesState(messagesStateCP);
    }

    const attachmentBuilder = (data) => {
        let attachment = null;
        if (
            data.file &&
            data.file.type &&
            (
                data.file.type.indexOf('pdf') >= 0 ||
                data.file.type.indexOf('image') >= 0 ||
                data.file.type.indexOf('audio') >= 0 ||
                data.file.type.indexOf('video') >= 0 ||
                data.file.type.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') >= 0 ||
                data.file.type.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') >= 0 ||
                data.file.type.indexOf('application/vnd.openxmlformats-officedocument.presentationml.presentation') >= 0
            )
        ) {
            let clvfileType = data.file.type.indexOf('pdf') >= 0;
            let Extension = null;

            if (data.file.type.indexOf('pdf') >= 0) {
                clvfileType = 1;
                Extension = 'pdf';
            } else if (data.file.type.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') >= 0) {
                clvfileType = 8;
                Extension = 'xlsx';
            } else if (data.file.type.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') >= 0) {
                clvfileType = 2
                Extension = 'docx';
            } else if (data.file.type.indexOf('application/vnd.openxmlformats-officedocument.presentationml.presentation') >= 0) {
                clvfileType = 9
                Extension = 'pptx';
            } else if (data.file.type.indexOf('image') >= 0) {
                clvfileType = 3;
                Extension = 'jpg';
            } else if (data.file.type.indexOf('video') >= 0) {
                clvfileType = 6;
                Extension = 'mp4';
            } else if (data.file.type.indexOf('audio') >= 0) {
                clvfileType = 7;
                Extension = 'mp3';
            }

            attachment = {
                clv_tipoadjunto: clvfileType,
                Nombre: data.file.name || null,
                Extension,
                Base64: data.file.base64.split(",")[1]
            }
        }
        return attachment;
    }

    const newCommentBuilder = (data) => {
        if (data) {

            if (messagesState.isMassive) {
                if (data.Destinatarios && data.Destinatarios.length) {
                    let obj = {
                        Destinatarios: data.Destinatarios,
                        Mensaje: ''
                    };

                    let attachment = attachmentBuilder(data);
                    if (attachment) {
                        obj['Adjuntos'] = [attachment];
                    }

                    if (data.message) {
                        obj.Mensaje = data.message || '';
                    }

                    if (data.Reply) {
                        obj['Reply'] = data.Reply;
                    }

                    return obj;
                }
            } else if (messagesState.isGroup) {
                if (data.clv_conversacion) {
                    let obj = {
                        clv_conversacion: data.clv_conversacion,
                        Mensaje: ''
                    };

                    let attachment = attachmentBuilder(data);
                    if (attachment) {
                        obj['Adjuntos'] = [attachment];
                    }

                    if (data.message) {
                        obj.Mensaje = data.message || '';
                    }

                    if (data.Menciones && data.Menciones.length) {
                        obj.Menciones = data.Menciones;
                    }
                    if (data.Reply) {
                        obj['Reply'] = data.Reply;
                    }

                    return obj;
                }
            } else {
                if (data.clv_destinatario) {
                    let obj = {
                        clv_destinatario: data.clv_destinatario,
                        Mensaje: ''
                    };

                    let attachment = attachmentBuilder(data);
                    if (attachment) {
                        obj['Adjuntos'] = [attachment];
                    }

                    if (data.message) {
                        obj.Mensaje = data.message || '';
                    }

                    if (data.Menciones && data.Menciones.length) {
                        obj.Menciones = data.Menciones;
                    }
                    if (data.Reply) {
                        obj['Reply'] = data.Reply;
                    }

                    return obj;
                }
            }
        }
        return null;
    }

    const sendNewComment = (data) => {

        let messagesStateCP = JSON.parse(JSON.stringify(messagesState));
        
        if (messagesStateCP && messagesStateCP.chat) {
            messagesStateCP.chat.isNew = false;
        }
        
        setMessagesState(messagesStateCP);

        let objSend = newCommentBuilder(data);

        if (objSend && (objSend.clv_destinatario || objSend.Destinatarios || objSend.clv_conversacion)) {
            // let loading = swalAlertsService.createLoading('Enviando mensaje', 'En breve estará listo');
            // loading.fire();
            let {isMassive} = messagesState;
            onCancelReply();
            messagesService.postMessage(objSend, messagesState.isMassive).then((response) => {
                setTimeout(() => {
                    // loading.close();
                    if (response && response.data) {
                        let messagesStateCP = JSON.parse(JSON.stringify(messagesState));
                        messagesStateCP.reply = null;
                        if (isMassive) {
                            messagesStateCP.isMassive = false;
                            messagesStateCP.massiveChat = [];
                            messagesStateCP.view = 'list';
                            messagesStateCP.chat = null;
                            
                            storeMessages(messagesStateCP.chats);
                            setMessagesState(messagesStateCP);
                            let successAlert = swalAlertsService.createLoading('Mensaje enviado', 'El mensaje fue enviado con éxito a todos los contactos seleccionados');
                            successAlert.fire();
                            setTimeout(() => {
                                successAlert.close();
                            }, 1500);
                            return;
                        } else if (messagesStateCP.isGroup && messagesStateCP.groupDetails){
                            if (messagesStateCP.groupDetails && messagesStateCP.groupDetails.clv_conversacion === response.data.clv_conversacion) {
                                messagesStateCP.groupDetails = response.data;
                                messagesStateCP.reply = null;
                                storeMessages(messagesStateCP.chats);
                                setMessagesState(messagesStateCP);
                            }
                        } else {
                            if (messagesStateCP.chat && messagesStateCP.chat.clv_destinatario === response.data.clv_destinatario) {
                                messagesStateCP.chat = response.data;
                                messagesStateCP.reply = null;
                                storeMessages(messagesStateCP.chats);
                                setMessagesState(messagesStateCP);
                            }
                        }

                        let cpFailed = JSON.parse(JSON.stringify(failedIds));
                        if(cpFailed.includes(data.idTemp)){
                            let index = cpFailed.findIndex((id) => id === data.idTemp);
                            if(index > -1){
                                cpFailed.splice(index, 1);
                                setFailedIds(cpFailed);
                            }
                        }
                        onSuccessMessage(data.idTemp);
                        updateChats();
                    } else {
                        onFailedMessage(data.idTemp);
                    }
                }, 200);
            })
        }
    }

    const onFailedMessage = (id) => {
        setSendFailed(true);
        let cpFailed = JSON.parse(JSON.stringify(failedIds));
        cpFailed.push(id);
        setFailedIds(cpFailed);
    }

    const onSuccessMessage = (id) =>{
        setSendSuccess(true);
        let cpSuccess = JSON.parse(JSON.stringify(successIds));
        cpSuccess.push(id);
        setSuccessIds(cpSuccess);
    }

    const onSubmit = (data) => {
        sendNewComment(data);
    }

    const onGroupDelete = (item) => {
        if (item && item.clv_conversacion) {
            swalAlertsService.answer("", "¿Estás seguro de querer borrar este grupo?", "Si, borrar").then((result) => {
                if (result.isConfirmed) {
                    let messagesStateCP = JSON.parse(JSON.stringify(messagesState));
                    let filtered = messagesStateCP.chats.filter(chat => chat.clv_conversacion === item.clv_conversacion);
                    const index =  messagesStateCP.chats.indexOf(filtered[0]);

                    if (index >= 0) {
                        if (item.clv_conversacion) {

                            messagesService.deleteChat(item.clv_conversacion)
                            .then((response) => {
                                if (response && response.data) {
                                    messagesStateCP.chats.splice(index, 1);

                                    if (messagesStateCP.groupDetails && messagesStateCP.groupDetails.clv_conversacion === item.clv_conversacion) {
                                        messagesStateCP.groupDetails = null;
                                        messagesStateCP.isGroup = false;
                                    }
                                    storeMessages(messagesStateCP.chats);
                                    setMessagesState(messagesStateCP);
                                    onChatUpdate();
                                } else {
                                    swalAlertsService.showToast('error', 'Error al intentar borrar la conversación');
                                }
                            });
                        } else {
                            messagesStateCP.chats.splice(index, 1);
                            storeMessages(messagesStateCP.chats);
                            setMessagesState(messagesStateCP);
                        }
                    }
                }
            })
        }
    }

    const onChatDelete = (item) => {

        swalAlertsService.answer("", "¿Estás seguro de querer borrar esta conversación?", "Si, borrar").then((result) => {
            if (result.isConfirmed) {
                if (item) {

                    let messagesStateCP = JSON.parse(JSON.stringify(messagesState));

                    if (item.clv_destinatario) {
                        let filtered = messagesStateCP.chats.filter(chat => chat.clv_destinatario === item.clv_destinatario);


                        if (filtered.length) {

                            const index =  messagesStateCP.chats.indexOf(filtered[0]);
                            if (index >= 0) {
                                if (item.clv_conversacion) {

                                    messagesService.deleteChat(item.clv_conversacion)
                                    .then((response) => {
                                        if (response && response.data) {
                                            messagesStateCP.chats.splice(index, 1);


                                            if (messagesStateCP.chat && 'clv_destinatario' in messagesStateCP.chat && messagesStateCP.chat.clv_destinatario === item.clv_destinatario) {
                                                messagesStateCP.chat = null;

                                                messagesStateCP.chat = messagesStateCP.chats.length ? messagesStateCP.chats[0]:null;
                                            }
                                            storeMessages(messagesStateCP.chats);
                                            setMessagesState(messagesStateCP);
                                        } else {
                                            swalAlertsService.showToast('error', 'Error al intentar borrar la conversación');
                                        }
                                    });
                                } else {
                                    messagesStateCP.chats.splice(index, 1);
                                    storeMessages(messagesStateCP.chats);
                                    setMessagesState(messagesStateCP);
                                }
                            }
                        }
                    } else {

                    }

                }
            }
        })


    }

    const onCancelReply = () => {
        let messagesStateCP = JSON.parse(JSON.stringify(messagesState));
        messagesStateCP.reply = null;
        setMessagesState(messagesStateCP);
    }

    const onReplyMessage = (message) => {
        if (message && message.clv_mensajexconversacion) {
            console.log(message);
            let messagesStateCP = JSON.parse(JSON.stringify(messagesState));
            messagesStateCP.reply = message;
            setMessagesState(messagesStateCP);
        }
    }

    const onDeleteMessage = (message) => {
        if (message && message.clv_mensajexconversacion) {
            swalAlertsService.answer("", "¿Estás seguro de querer borrar este mensaje?", "Si, borrar").then((result) => {
                if (result.isConfirmed) {

                    let loading = swalAlertsService.createLoading('Eliminando mensaje', 'En breve estará listo');
                    loading.fire();
                    messagesService.deleteMessage(message.clv_mensajexconversacion).then((response) => {

                        setTimeout(() => {
                            loading.close();
                            if (response && response.data && response.message) {
                                let messagesStateCP = JSON.parse(JSON.stringify(messagesState));
                                if (messagesStateCP.chat && messagesStateCP.chat.clv_destinatario === response.data.clv_destinatario) {
                                    messagesStateCP.chat = response.data;
                                    storeMessages(messagesStateCP.chats);
                                    setMessagesState(messagesStateCP);
                                }
                            } else {
                                swalAlertsService.showToast('error', 'Ocurrió algo al intentar eliminar mensaje');
                            }
                        }, 200);
                    });
                }
            })

        }
    }

    const onMassiveSendBtnTapped = (selected) => {
        if (selected && selected.length) {
            let messagesStateCP = JSON.parse(JSON.stringify(messagesState));
            messagesStateCP.view = 'chat';
            messagesStateCP.chat = null;
            messagesStateCP.isMassive = true;
            messagesStateCP.massiveChat = selected;
            messagesStateCP.isGroup = false;
            messagesStateCP.groupDetails = null;
            storeMessages(messagesStateCP.chats);
            setMessagesState(messagesStateCP);
            setIsNewChatOpen(false);
        }
    }

    const prepareGroupObj = (group) => {
        let obj = {
            Titulo: group.Titulo,
            Mensajes: [],
            Usuarios: group.Usuarios,
            ImageUrl: group.ImageUrl || null,
            Imagen: group.Imagen
        };

        return obj;
    }
    const onGroupSendBtnTapped = (group) => {
        if (group) {
            let messagesStateCP = JSON.parse(JSON.stringify(messagesState));
            messagesStateCP.view = 'chat';
            messagesStateCP.chat = null;
            messagesStateCP.isMassive = false;
            messagesStateCP.massiveChat = [];
            messagesStateCP.isGroup = true;
            messagesStateCP.groupDetails = group;
            storeMessages(messagesStateCP.chats);
            setMessagesState(messagesStateCP);
            setIsNewChatOpen(false);
            onChatUpdate();
        }
    }

    const onChatUpdate = () => {
        setSeconds((seconds) => {
            const total = (seconds === null ? 0:seconds) + 1;
            return total <= 10 ? total:1;
        });
    }

    const showGroupDetails = (group) => {
        if (group) {
            setGroupDetailsItem({group, showDetails: true});
        }
    }

    const onHideGroupDetails = () => {
        setGroupDetailsItem({group: null, showDetails: false});
    }

    const onGroupLeave = () => {
        setGroupDetailsItem({showDetails: false, group: null});

        let messagesStateCP = JSON.parse(JSON.stringify(messagesState));
        messagesStateCP.view = 'list';
        messagesStateCP.chat = null;
        messagesStateCP.isMassive = false;
        messagesStateCP.massiveChat = [];
        messagesStateCP.isGroup = false;
        messagesStateCP.groupDetails = null;
        storeMessages(messagesStateCP.chats);
        setMessagesState(messagesStateCP);
        setIsNewChatOpen(false);

        onChatUpdate();
    }

    const desktopRender = () => {
        return (
            <div className="Messages-container">
                <Header title='mensajes' activeLink='messages' onSearchTerm={onSearcherChanged} updateShowDot={updateDot} endMessage={endMessage}/>

                <div className="row m-0 d-flex justify-content-center Messages-box-container">
                    <div className="col Messages-lateral-content left">
                        <div className="row m-0 h-100">
                            <div className="col-12 px-0 pb-3">
                                <MessagesDesktopList chats={messagesState.chats}
                                    currentUser={currentUser}
                                    onChatSelected={onChatSelected}
                                    onChatDelete={onChatDelete}
                                    onGroupDelete={onGroupDelete}
                                    onNewChatBtnTapped={onNewChatBtnTapped}
                                    contacts={contacts}
                                    onContactSelected={onUserSelected}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="col ps-0 Messages-post-box">
                        {
                            messagesState.chat && !messagesState.isMassive && !messagesState.isGroup &&
                            <MessagesDesktopChat 
                                chat={messagesState.chat}
                                onBackBtnTapped={onBackBtnTapped}
                                currentUser={currentUser}
                                onSubmit={onSubmit}
                                onReplyMessage={onReplyMessage}
                                reply={messagesState.reply}
                                onCancelReply={onCancelReply}
                                onDeleteMessage={onDeleteMessage}
                                isMassive={messagesState.isMassive}
                                massiveChat={messagesState.massiveChat}
                                onChatUpdate={onChatUpdate}
                                isGroup={messagesState.isGroup}
                                groupDetails={messagesState.groupDetails}
                                onFailedMessage={sendFailed}
                                failedIds={failedIds}
                                onSuccessMessage={sendSuccess}
                                sucessIds={successIds}
                                
                            />
                        }

                        {
                            !messagesState.chat && messagesState.isMassive && messagesState.massiveChat.length && !messagesState.isGroup &&
                            <MessagesDesktopChat chat={messagesState.chat}
                                onBackBtnTapped={onBackBtnTapped}
                                currentUser={currentUser}
                                onSubmit={onSubmit}
                                onReplyMessage={onReplyMessage}
                                reply={messagesState.reply}
                                onCancelReply={onCancelReply}
                                onDeleteMessage={onDeleteMessage}
                                isMassive={messagesState.isMassive}
                                massiveChat={messagesState.massiveChat}
                                onChatUpdate={onChatUpdate}
                                isGroup={messagesState.isGroup}
                                groupDetails={messagesState.groupDetails}
                                onFailedMessage={sendFailed}
                                failedIds={failedIds}
                                onSuccessMessage={sendSuccess}
                                sucessIds={successIds}
                            />
                        }

                        {
                            !messagesState.chat && !messagesState.isMassive && messagesState.isGroup && messagesState.groupDetails &&
                            <MessagesDesktopChat chat={messagesState.chat}
                                onBackBtnTapped={onBackBtnTapped}
                                currentUser={currentUser}
                                onSubmit={onSubmit}
                                onReplyMessage={onReplyMessage}
                                reply={messagesState.reply}
                                onCancelReply={onCancelReply}
                                onDeleteMessage={onDeleteMessage}
                                isMassive={messagesState.isMassive}
                                massiveChat={messagesState.massiveChat}
                                onChatUpdate={onChatUpdate}
                                isGroup={messagesState.isGroup}
                                groupDetails={messagesState.groupDetails}
                                showGroupDetails={showGroupDetails}
                                onFailedMessage={sendFailed}
                                failedIds={failedIds}
                                onSuccessMessage={sendSuccess}
                                sucessIds={successIds}
                            />
                        }
                        {
                            !messagesState.chat && !messagesState.massiveChat.length && !messagesState.groupDetails &&
                            <div className="Messages-chat-box-noresults row m-0 h-100"><NoResults/></div>
                        }
                    </div>

                </div>
            </div>
        )
    }

    const mobileRender = () => {
        return (
            <div className="Messages-container h-100s">
                <div className="row m-0 h-100 pos-relative">
                    <div className={`col p-0 Messages-list-box ${messagesState.view === 'list' ? 'active':''}`}>

                        <div className="row m-0">
                            <div className="col-12 p-0 Messages-header-box">
                                <MessagesHeader onSearcherChanged={onSearcherChanged}
                                    onNewChatBtnTapped={onNewChatBtnTapped}
                                />
                            </div>
                            <div className="col-12 p-0">
                                <MessagesList chats={messagesState.chats}
                                    contacts={contacts}
                                    currentUser={currentUser}
                                    onChatSelected={onChatSelected}
                                    onContactSelected={onUserSelected}

                                    onChatDelete={onChatDelete}
                                    onGroupDelete={onGroupDelete}
                                />
                            </div>
                        </div>

                    </div>
                    <div className={`col p-0 Messages-chat-box ${messagesState.view === 'chat' ? 'active':''}`}>
                        {
                            messagesState.chat && !messagesState.isMassive && !messagesState.isGroup &&
                            <MessagesChat chat={messagesState.chat}
                                onBackBtnTapped={onBackBtnTapped}
                                currentUser={currentUser}
                                onSubmit={onSubmit}
                                onReplyMessage={onReplyMessage}
                                reply={messagesState.reply}
                                onCancelReply={onCancelReply}
                                onDeleteMessage={onDeleteMessage}
                                isMassive={messagesState.isMassive}
                                massiveChat={messagesState.massiveChat}
                                onChatUpdate={onChatUpdate}
                                isGroup={messagesState.isGroup}
                                groupDetails={messagesState.groupDetails}
                                onFailedMessage={sendFailed}
                                failedIds={failedIds}
                                onSuccessMessage={sendSuccess}
                                sucessIds={successIds}
                            />
                        }

                        {
                            !messagesState.chat && messagesState.isMassive && messagesState.massiveChat.length && !messagesState.isGroup &&
                            <MessagesChat chat={messagesState.chat}
                                onBackBtnTapped={onBackBtnTapped}
                                currentUser={currentUser}
                                onSubmit={onSubmit}
                                onReplyMessage={onReplyMessage}
                                reply={messagesState.reply}
                                onCancelReply={onCancelReply}
                                onDeleteMessage={onDeleteMessage}
                                isMassive={messagesState.isMassive}
                                massiveChat={messagesState.massiveChat}
                                onChatUpdate={onChatUpdate}
                                isGroup={messagesState.isGroup}
                                groupDetails={messagesState.groupDetails}
                                onFailedMessage={sendFailed}
                                failedIds={failedIds}
                                onSuccessMessage={sendSuccess}
                                sucessIds={successIds}
                            />
                        }

                        {
                            !messagesState.chat && !messagesState.isMassive && messagesState.isGroup && messagesState.groupDetails &&
                            <MessagesChat chat={messagesState.chat}
                                onBackBtnTapped={onBackBtnTapped}
                                currentUser={currentUser}
                                onSubmit={onSubmit}
                                onReplyMessage={onReplyMessage}
                                reply={messagesState.reply}
                                onCancelReply={onCancelReply}
                                onDeleteMessage={onDeleteMessage}
                                isMassive={messagesState.isMassive}
                                massiveChat={messagesState.massiveChat}
                                onChatUpdate={onChatUpdate}
                                isGroup={messagesState.isGroup}
                                groupDetails={messagesState.groupDetails}
                                showGroupDetails={showGroupDetails}
                                onFailedMessage={sendFailed}
                                failedIds={failedIds}
                                onSuccessMessage={sendSuccess}
                                sucessIds={successIds}
                            />
                        }
                        {
                            !messagesState.chat && !messagesState.massiveChat.length && !messagesState.groupDetails &&
                            <div className="Messages-chat-box-noresults row m-0 h-100"><NoResults/></div>
                        }
                    </div>

                </div>



            </div>
        );
    }

    return (
        <React.Fragment>
            {
                width && width >= 1450 ? (desktopRender()):(mobileRender())
            }

            {
                isNewChatOpen &&
                <ContactList onUserSelected={onUserSelected}
                    currentUser={currentUser}
                    onBottomSheetDimiss={onBottomSheetDimiss}
                    onMassiveSendBtnTapped={onMassiveSendBtnTapped}
                    onGroupSendBtnTapped={onGroupSendBtnTapped}
                    currentMessagesCount = { messagesState.Broadcast }
                />
            }

            {
                groupDetailsItem.showDetails && groupDetailsItem.group &&
                <GroupDetails
                    onHide={onHideGroupDetails}
                    group={groupDetailsItem.group}
                    currentUser={currentUser}
                    onGroupLeave={onGroupLeave}
                    onChatUpdate={onChatUpdate}
                />
            }
        </React.Fragment>
    );
}

export { Messages };
