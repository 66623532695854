import { BehaviorSubject } from 'rxjs';
import { authHeader, handleResponse } from '../helpers';

//const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

const apiPath = process.env.REACT_APP_API_PATH;
export const profileService = {
    getUser,
    getUserProfile,
    updateImage,
    updateImageCompany,
    updateUser,
    updateCompany,
    getCard,
    deleteMembership,
    deleteDocument,
    setDocument,
    bloqued,
    restring,
    updatePreferences,
    updateSocialNetworks,
    postTalent,
    postRecomendation,
    postGeneric,
    getGeneric
};

function getUser() {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiPath}user`, requestOptions)
            .then(response => handleResponse(response, false, false))
            .then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return user;
            }, (error) => {
                return error;
            });
}

function getUserProfile(idUser) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiPath}user/${idUser}`, requestOptions)
            .then(response => handleResponse(response, false, false))
            .then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return user;
            }, (error) => {
                return error;
            });
}

function updateImage(image, clv, extension) {
    let body = {
        "clv_tipoadjunto": clv,
        "Extension": extension,
        "Base64": image
    }
   const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    };

    return fetch(`${apiPath}user/photo`, requestOptions)

}

function updateImageCompany(image, clv, extension) {
    let body = {
        "clv_tipoadjunto": clv,
        "Extension": extension,
        "Base64": image
    }
   const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    };

    return fetch(`${apiPath}user/logo`, requestOptions)

}

function updateUser(data) {
    let body = {
        "EmailPrivado": data.EmailPrivado,
        "CelularPrivado": data.CelularPrivado,
        "Nombre": data.Nombre,
        "ApellidoPaterno": data.ApellidoPaterno,
        "ApellidoMaterno": data.ApellidoMaterno,
        "clv_genero": data.clv_genero,
        "FechaNacimiento": data.FechaNacimiento,
        "clv_nivelestudio": data.clv_nivelestudio,
        "clv_aficion": data.clv_aficion,
        "clv_nacionalidad": data.clv_nacionalidad,
        "clv_estadoresidencia": data.clv_estadoresidencia,
        "clv_paisresidencia": data.clv_paisresidencia,
        "Telefono": data.Telefono,
        "clv_cargo": data.clv_cargo,
        "clv_estatusbm": data.clv_estatusbm,
        "InvitadoPor": data.InvitadoPor,
        "clv_usuarioInvitadoPor": data.clv_usuarioInvitadoPor,
        "RedesSociales": data.RedesSociales,
        "clv_estatusIpade": data.clv_estatusIpade || null,
        "FechaIpade": data.FechaIpade || null,
        "OfreceMentoria": data.OfreceMentoria || null,
        "clv_tipomentoria": data.clv_tipomentoria || null,
        "Mentoria": data.Mentoria || null,
        "FechaNacimientoPrivado": data.FechaNacimientoPrivado || null
    }
   const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    };

    return fetch(`${apiPath}user/personalinfo`, requestOptions)
            .then(response => handleResponse(response, false,false))
            .then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return user;
            }, (error) => {
                return error;
            });

}

function postGeneric(path, method, data) {
    const requestOptions = {
        method: method,
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${apiPath}${path}`, requestOptions)
        .then(response => handleResponse(response, false,false))
        .then(user => {
            return user;
        }, (error) => {
            return error;
        });

}

function getGeneric(path) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiPath}${path}`, requestOptions).then(response => handleResponse(response, false, false)).then(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        return user;
    }, (error) => {
        return error;
    }).catch( (reason) =>{
        console.log(reason);
    });
}

function postTalent(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${apiPath}user/talent`, requestOptions)
        .then(response => handleResponse(response, false,false))
        .then(user => {
            return user;
        }, (error) => {
            return error;
        });

}

function postRecomendation(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${apiPath}user/recommendation`, requestOptions)
        .then(response => handleResponse(response, false,false))
        .then(user => {
            return user;
        }, (error) => {
            return error;
        });

}

function updateCompany(data) {
    let body = {
        "Empresa": data.Empresa,
        "EmpresaNombreCorto": data.EmpresaNombreCorto,
        "URL": data.URL,
        "Fundacion": data.Fundacion,
        "Descripcion": data.Descripcion,
        "clv_giro": data.clv_giro,
        "clv_clasificacion": data.clv_clasificacion,
        "Tag1": data.Tag1,
        "Tag2": data.Tag2,
        "clv_estado": data.clv_estado,
        "clv_rangoempleados": data.clv_rangoempleados,
        "OfreceDescuento": data.OfreceDescuento || null,
        "PorcentajeDescuento": data.PorcentajeDescuento || null,
        "ComentariosDescuento": data.ComentariosDescuento || null
    }
   const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    };

    return fetch(`${apiPath}user/companyinfo`, requestOptions)
            .then(response => handleResponse(response, false, false))
            .then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return user;
            }, (error) => {
                return error;
            });

}

function updatePreferences(data) {
    let body = {
        Cargos: data
    }
   const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    };

    return fetch(`${apiPath}user/preferences`, requestOptions)
        .then(response => handleResponse(response, false, false))
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return user;
        }, (error) => {
            return error;
        });
}

function setDocument(data) {
    let body = data
   const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    };

    return fetch(`${apiPath}user/brochure`, requestOptions)


}


function getCard() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiPath}user/card`, requestOptions)
            .then(response => handleResponse(response, false, false))
            .then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return user;
            }, (error) => {
                return error;
            });

}


function deleteMembership() {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${apiPath}user/membership`, requestOptions)
}

function deleteDocument() {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${apiPath}user/brochure`, requestOptions)
}


function bloqued(id) {
    let body = {
        "clv_usuario": id
    }
    const requestOptions = {
         method: 'POST',
         headers: authHeader(),
         body: JSON.stringify(body)
     };

     return fetch(`${apiPath}user/block`, requestOptions)


}

function restring(id) {
    let body = {
        "clv_usuario": id
    }
    const requestOptions = {
         method: 'POST',
         headers: authHeader(),
         body: JSON.stringify(body)
     };

     return fetch(`${apiPath}user/restrict`, requestOptions)


}

function updateSocialNetworks(body) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    };

    return fetch(`${apiPath}user/socialnetworks`, requestOptions)
        .then(response => handleResponse(response, false, false))
        .then(user => {
            return user;
        }, (error) => {
                return error;
        });

}
