import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faQuoteLeft, faQuoteRight, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import './css/magnific-popup.css';

import './LandingIndex.scss';

import logoWenet from '../../../_assets/media/images/new-landing/logo-WeNet.svg';

import bgProduct from '../../../_assets/media/images/new-landing/revolution-slider/product/bg_product.jpg';
import macbookPro from '../../../_assets/media/images/new-landing/revolution-slider/product/macbookpro.png';
import macbookContent from '../../../_assets/media/images/new-landing/revolution-slider/product/macbook_content.jpg';
import ipadDark from '../../../_assets/media/images/new-landing/revolution-slider/product/ipad_dark.png';
import ipadContent from '../../../_assets/media/images/new-landing/revolution-slider/product/ipad_content.jpg';
import ihponeDark from '../../../_assets/media/images/new-landing/revolution-slider/product/ihpone_dark.png';
import iphoneContent from '../../../_assets/media/images/new-landing/revolution-slider/product/iphone_content.jpg';
import ceoBiomedica from '../../../_assets/media/images/new-landing/ceo-biomedica.jpg';
import ceoGarabatos from '../../../_assets/media/images/new-landing/ceo-garabatos.jpg';
import ceoArion from '../../../_assets/media/images/new-landing/ceo-arion.jpg';
import presidenteColectivoModa from '../../../_assets/media/images/new-landing/presidente-colectivo-moda.jpg';
import ceoRappi from '../../../_assets/media/images/new-landing/ceo-rappi.jpg';
import bgWenet from '../../../_assets/media/images/new-landing/bg-WeNet.jpg';

import project1 from '../../../_assets/media/images/new-landing/projects/project-1.jpg';
import project2 from '../../../_assets/media/images/new-landing/projects/project-2.jpg';
import project3 from '../../../_assets/media/images/new-landing/projects/project-3.jpg';
import project4 from '../../../_assets/media/images/new-landing/projects/project-4.jpg';
import project5 from '../../../_assets/media/images/new-landing/projects/project-5.jpg';


import svgArrowUp from '../../../_assets/media/images/svg/svg_arrow_up_solid.svg';

const LandingOffer = (props) => {
    let mainRef = useRef(null);
    const [toggleBtn, setToggleBtn] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (props.onToggleTab) {
            props.onToggleTab(true);
        }
        removePreloader();
        goTop();

        window.addEventListener("scroll", (e) => handleNavigation(e));

        return () => { // return a cleanup function to unregister our function since its gonna run multiple times
            window.removeEventListener("scroll", (e) => handleNavigation(e));
        };
    }, []);

    const handleNavigation = (e) => {
        toggleHeaderFixed(window.scrollY > 0 ? true:false);
    }

    const toggleHeaderFixed = (value) => {
        let element = document.getElementById('header');

        if (element && element.className) {
            if (value && element.className.indexOf('header-fixed') < 0) {
                element.className = element.className.trim();
                element.className += ' header-fixed';
            }

            if (!value) {
                element.className = element.className.replace(/header-fixed/g, '');
                element.className = element.className.trim();
            }
        }
    }

    const removePreloader = () => {
        setTimeout(() => {
            let preloader = document.getElementById('preloader');
            if (preloader && preloader.className) {
                if (preloader.className.indexOf('loading') >= 0) {
                    preloader.className = preloader.className.replace(/loading/g, 'ended');
                }

                if (preloader.className.indexOf('ended') < 0) {
                    preloader.className = preloader.className.trim();
                    preloader.className += ' ended';
                }

            }
        }, 500);
    }

    const goToLogin = () => {
        history.push("/login");
    }

    const goToRegister = () => {
        history.push("/register");
    }

    const goTop = () => {
        window.scrollTo(0, 0);
    }

    const toggleHeader = () => {
        setToggleBtn(!toggleBtn);
    }

    return (

        <div className="LandingIndex-container dark" ref={mainRef} id='LandingIndex-container'>
            <div className="preloader loading" id="preloader">
                <div className="logopreloader">
                    <img src={logoWenet} alt="logo-black"/>
                </div>
                <div className="loader" id="loader"></div>
            </div>

            <div className="wrapper">
                <header className="header" id='header'>
                    <div className="header-inner">
                        <nav className="navbar">


                            <div className="logo">
                                <Link to="/welcome" className="navbar-brand" data-target=".navbar-collapse.show" className="navbar-brand">
                                    <img id="logo-light" className="logo-light" src={logoWenet} alt="logo-light" />
                                    <img id="logo-dark" className="logo-dark" src={logoWenet} alt="logo-dark" />
                                </Link>
                            </div>

                            <button className="navbar-toggle navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleHeader}>
        						<span id="icon-toggler" className={toggleBtn ? 'open':''}>
        						  <span></span>
        						  <span></span>
        						  <span></span>
        						  <span></span>
        						</span>
        					</button>

                            <div id="navbarSupportedContent" className={`collapse navbar-collapse navbar-responsive-collapse show ${toggleBtn ? 'in':''}`} style={{height: toggleBtn ? 'auto':'0px'}}>

                                <ul className="nav navbar-nav" id="main-navigation">
                                    <li><a href='/welcome'>Inicio</a></li>
        							<li className="active"><Link to='/welcome/oferta'>Oferta</Link></li>
        							<li><Link to='/welcome/tutorial'>Tutorial</Link></li>
        							<li><Link to='/login'>Iniciar sesión</Link></li>
        							<li><Link to='/register' className='lastLink'>Registro</Link></li>
        						</ul>

        					</div>

                        </nav>
                    </div>
                </header>

                <section className="banner">
                    <div className="content text-center">
                        <div className="text-center top-text">
                            <div className="text-center top-text">
                                <h1>JUNTOS PODEMOS TRASCENDER MEJOR</h1>
                            </div>
                            <hr className='hrVerde'/>
                            <ul className="breadcrumb d-flex justify-content-center">
                                <li>Todos los empresarios tenemos la responsabilidad de generar mejores negocios, para trascender positivamente con nuestra sociedad</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section className="portfolio">
                    <div className="container">
                        <div>
                            <div className="filtr-container row m-0">
                                <div className="col-12 col-sm-4 col-md-4 filtr-item" data-category="1">
                                    <div className="magnific-popup-gallery">
                                        <figure className="thumbnail thumbnail__portfolio">
                                            <a className="image-wrap" ><img className="img-responsive" src={project1} alt="Image Project" /></a>
                                        </figure>
                                        <div className="caption">
                                            <a className="title-link" href="image-project.html">
                                                <h3><span className='green-text'>We</span>net</h3>
                                            </a>
                                            <p>Colabora desde la palma de tu mano, solicitando y brindando apoyo:<br/>
                                                <ul>
                                                    <li>Para obtener opiniones sobre cosas que tengas que tomar decisiones en el negocio</li>
                                                    <li>Para entablar comunicación con terceras personas de nuestro interes (relacionamiento)</li>
                                                    <li>Para vender o comprar productos o servicios </li>
                                                    <li>Para compartir información de interés general</li>
                                                </ul>
                                            </p>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-4 col-md-4 filtr-item" data-category="2">
                                    <div className="magnific-popup-gallery">
                                        <figure className="thumbnail thumbnail__portfolio">
                                            <a className="image-wrap" ><img className="img-responsive" src={project2} alt="Gallery project" /></a>
                                        </figure>
                                        <div className="caption">
                                            <a className="title-link" href="youtube-project.html">
                                                <h3><span className='green-text'>We</span>Live</h3>
                                            </a>
                                            <p>Accede a información premium de negocios:
                                                <ul>
                                                    <li>Conferencias digitales </li>
                                                <li>Entrevistas en vivo</li>
                                                <li>Masterclass en vivo</li>
                                                <li>Sesiones de Consultoría en vivo</li>
                                                </ul>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4 col-md-4 filtr-item" data-category="1">
                                    <div className="magnific-popup-gallery">
                                        <figure className="thumbnail thumbnail__portfolio">
                                            <a className="image-wrap" title="Gallery project"><img className="img-responsive" src={project3} alt="Gallery project" /></a>
                                        </figure>
                                        <div className="caption">
                                            <a className="title-link" href="gallery-project.html">
                                                <h3><span className='green-text'>We</span>Entrepenur</h3>
                                            </a>
                                            <p>Forma parte del desarrollo de emprendedores:
                                            <ul><li>Brindando Mentoría</li>
                                            <li>Dictando Conferencias</li>
                                            <li>Aportando inversión a proyectos</li>
                                            </ul>
                                            Nota. Parte de nuestrtos ingresos se destinan a programas de emprendedores</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-4 col-md-4 filtr-item" data-category="3">
                                    <div className="magnific-popup-gallery">
                                        <figure className="thumbnail thumbnail__portfolio">
                                            <a className="image-wrap" title="portfolio"><img className="img-responsive" src={project4} alt="portfolio" /></a>
                                        </figure>
                                        <div className="caption">
                                            <a className="title-link" href="image-project.html">
                                                <h3><span className='green-text'>We</span>Board Link</h3>
                                            </a>
                                            <p>Accede a un soporte virtual:
                                                <ul>
                                                    <li>Para solicitar MENTORÍA en temas de negocios</li>
                                                <li>Para solicitar INVERSIÓN para algún proyecto</li>
                                                <li>Para solicitar una SESIÓN DE CONSEJO para tener opiniones de especialistas en tu negocio </li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4 col-md-4 filtr-item" data-category="1">
                                    <div className="magnific-popup-gallery">
                                        <figure className="thumbnail thumbnail__portfolio">
                                            <a className="image-wrap" title="Image project"><img className="img-responsive" src={project5} alt="Image Project" /></a>
                                        </figure>
                                        <div className="caption">
                                            <a className="title-link" href="image-project.html">
                                                <h3><span className='green-text'>We</span>Academy</h3>
                                            </a>
                                            <p>Aprende a ser “dueño de tu negocio”:
                                                <ul><li>Masterclass en diversos temas de negocio</li>
                                                <li>Mentorías para resolver retos específicos</li>
                                                <li>Podcast sobre negocios</li>
                                                </ul>
                                            </p>

                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>

                    </div>
                </section>

                <footer className="footer top-logos">
                    <div className="container top-footer">
                        <div className="row">

                            <div className="col-6 col-lg-2">
                                <h4>Wenet</h4>
                                <div className="menu">
                                    <ul>
                                        <li><a href="/welcome">Inicio</a></li>
                                        <li><Link to='/welcome/oferta'>Oferta</Link></li>
                                        <li><Link to='/welcome/tutorial'>Tutorial</Link></li>

                                    </ul>
                                </div>
                            </div>

                            <div className="col-6 col-lg-2">
                                <h4>Soporte</h4>
                                <div className="menu">
                                    <ul>
        								<li><a href="https://api.wenet.mx/html/terms.html" target='_blank'>Términos y condiciones </a></li>
                                        <li><a href="https://api.wenet.mx/html/privacy.html">Aviso de privacidad</a></li>
                                        <li><Link to="/register">Registro</Link></li>
                                        <li><Link to="/login">Iniciar sesión</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-6 col-lg-2">
                                <h4>Contactanos</h4>
                                <div className="menu">
                                    <ul>
                                        <li><span><i className="fa fa-envelope-open"></i> <a href="mailto:contacto@wenet.mx">contacto@wenet.mx</a> </span></li>
                                        <li><span><i className="fa fa-phone"></i> <a href="https://api.whatsapp.com/send?phone=525535008661&text=Escribo%20para%20solicitar%20información%20de%20la%20app%20WeNet" target='_blank'>Whats app</a></span></li>
                                        <li><span><i className="fa fa-map-marker"></i> Woodlands, USA, 77389</span></li>

                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>

                </footer>
                <div id="back-top-wrapper">
                    <p id="back-top">
                        <a href="#top"><FontAwesomeIcon icon={faArrowUp}/></a>
                    </p>
                </div>
            </div>

        </div>

    );
}

export { LandingOffer };
