import React, { useState, useEffect, useRef } from 'react';

import './BMAcademy.scss';

import { algoliaService, swalAlertsService, messagesService, profileService } from '../../../_resources/services';
import { toolsHelper } from '../../../_resources/helpers';

import { NoResults, BottomSheetShared, Filters, ModalDefault } from '../';

import icnBullet from '../../../_assets/media/images/icons/icn_bullet.png';
import icnBulletSelected from '../../../_assets/media/images/icons/icn_bullet_actived.png';
import imgUserPlaceholder from '../../../_assets/media/images/icons/icn_user_placeholder.png';

import icnSearch from '../../../_assets/media/images/icons/icn_search.png';
import icnCancel from '../../../_assets/media/images/icons/icn_close.png';
import icnBroadcast from '../../../_assets/media/images/icons/icn_broadcast.png';
import icnGroup from '../../../_assets/media/images/icons/icn_group.png';
import icnCamera from '../../../_assets/media/images/icons/icn_camera.png';
import icnCheckBox from '../../../_assets/media/images/icons/icn_checkbox.png';
import icnCheckBoxSelected from '../../../_assets/media/images/icons/icn_checkbox_selected.png';
import icnDeletContact from '../../../_assets/media/images/icons/icn_delet_contact.png';
import icnCopy from '../../../_assets/media/images/icons/icn_copy.png';
import start from '../../../_assets/media/images/icons/icn_batch_star.svg';


const BMAcademy = (props) => {
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(true);
    const [user, setUser] = useState(null);
    const divRef = useRef(null);

    useEffect(() => {
        let isSuscribed = true;

        if (!user) {
            profileService.getUser().then((response) => {
                if (isSuscribed) {
                    if (response && response.data && response.data && response.data.Codigo && response.data.Codigo.Codigo) {
                        setUser(response.data);
                    } else {
                        onDismiss();
                    }
                }
            })
        }

        return () => isSuscribed =  false
    }, []);

    const contactSearcherFooterBtn = () => {
        return <a className={`btn-cleared active`} href='https://www.weacademy.mx/offers/d3NsXdtB'>
            Ir a WeAcademy
        </a>
    }

    const onBottomSheetDimiss = () => {
        if (props.onBottomSheetDimiss) {
            props.onBottomSheetDimiss();
        }
    }

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const onCodeCopied = () => {
        if (divRef && divRef.current && divRef.current.innerText) {

            var textField = document.createElement('textarea');
            textField.innerText = divRef.current.innerText;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();

            swalAlertsService.showToast('success','Código copiado correctamente');
        }
    }

    const mainRender = () => {
        return (
            <React.Fragment>
                <div className={`BMAcademy-container`}>
                    <div className={`row m-0`}>
                        <div className={`col-12 BMAcademy-header-box`} style={{visibility: "visible"}}>
                            <button className='btn-cleared BMAcademy-btn-close mouse-pointer' onClick={onDismiss}>
                                <img src={icnCancel} alt="close icon"/>
                            </button>

                            <p className={`large-title`}>WeAcademy</p>
                        </div>

                        {
                            user && user.Codigo && user.Codigo.Codigo ? (
                                <div className="row m-0 BMAcademy-content">
                                    <div className="col-12 p-0 BMAcademy-content-label">
                                        Código promocional
                                    </div>
                                    <div className="col-12 BMAcademy-content-code">
                                        <div ref={divRef}>{user.Codigo.Codigo}</div>
                                    </div>

                                    <div className="col-12 BMAcademy-content-btn-copy px-0">
                                        <button className="btn-cleared" onClick={onCodeCopied}>
                                            <img src={icnCopy} alt="copy icn" width='30' height='30'/> <span>Copiar código</span>
                                        </button>
                                    </div>
                                    <div className="col-12 BMAcademy-disclaimer-container px-0">
                                        Con este Código Promocional (de uso personal) regístrate en <a className='refWeAcademy'  href="http://www.WeAcademy.mx" target="_blank" rel="noopener noreferrer">www.WeAcademy.mx </a>
                                                        de forma gratuita y mejora tu forma de liderear tu proyecto empresarial.
                                    </div>
                                </div>
                            ):(null)
                        }
                    </div>

                    <div className="row m-0 BMAcademy-footer-container">
                        <a href='https://www.weacademy.mx/offers/d3NsXdtB' target="_blank" rel="noreferrer">Ir a WeAcademy</a>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <ModalDefault show={true} className='full-view'>
            {mainRender()}
        </ModalDefault>

    );
}

export { BMAcademy };
