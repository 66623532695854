import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from './membership-components/card/Card';
import { Detail } from './membership-components/detail/Detail';
import { Method } from './membership-components/method/Method';
import { TypeMembership } from './membership-components/type-membership/TypeMembership';
import './Membership.scss'

const Membership = (props) => {

    const params = useParams()

    useEffect(() => {
        let isSuscribed = true;

        if (isSuscribed) {
            if (props.onToggleTab) {
                props.onToggleTab(true);
            }
            switch (params.type) {
                case "0":
                    setView({"type": true,"method": false, "card": false, "detail": false})
                    break;
                case "1":
                    setView({"type": true,"method": false, "card": false, "detail": false})
                    break;
                case "2":
                    setView({"type": false,"method": false, "card": true, "detail": false})
                    break;
                default:
                   break;
            }
        }
        return () => isSuscribed = false;
    }, [])

    const [view, setView] = useState({
        type: true,
        method: false,
        card: false,
        detail: false
    })
    const [token, setToken] = useState("")
    const [data, setData] = useState({
        "clv_metodopago": 1,
        "clv_membresia": 1,
        "token_id": ""
    })
    const [membership, setMembership] = useState([])
    const [card, setCard] = useState({})

    return (
        <React.Fragment>
            {
                view.type? <TypeMembership  setView={setView} setData={setData} data={data} setMembership={setMembership} membership={membership}/> : ""
            }
            {
                view.method ? <Method  setView={setView} setData={setData} data={data}/> : ""
            }
            {
                view.card ? <Card setView={setView} setData={setData} data={data} card={card} setCard={setCard} setToken={setToken} toke={token}/> : ""
            }
            {
                view.detail ? <Detail setView={setView} setData={setData} data={data} data={data} setMembership={setMembership} membership={membership} card={card} setCard={setCard} token={token}/> : ""
            }
        </React.Fragment>
    );
}

export {Membership};
