/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import back from '../../../../_assets/media/images/icons/icn_back_arrow.png'
import support from '../../../../_assets/media/images/icons/compu.png'
import after from '../../../../_assets/media/images/icons/after.png'
import logo from '../../../../_assets/media/images/icons/logo_wenet_green_white.png'
import whatssap from '../../../../_assets/media/images/icons/whatsapp.png'
import correo from '../../../../_assets/media/images/icons/correo.png'

import './Support.scss';
import { useHistory } from 'react-router';
import { BehaviorSubject } from 'rxjs';
import { toolsHelper } from '../../../../_resources/helpers';
import { ModalDefault } from '../../../shared';


const Support = (props) => {


    const history = useHistory()

    const [version, setvVersion] = useState({})

    useEffect(() => {
        const currentVersionSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('infoVersion')));
        setvVersion(currentVersionSubject._value)
    }, [])

    const setChat = () => {
        let user = {
            "clv_usuario": version.UsuarioSoporte.clv_usuario,
            "NombreCompleto": version.UsuarioSoporte.NombreCompleto,
            "EmpresaNombreCorto": version.UsuarioSoporte.EmpresaNombreCorto,
            "ThumbnailUrl":  version.UsuarioSoporte.ThumbnailUrl
        }
        let chat = toolsHelper.chatObjBuilder(user);
        if (chat) {
            history.push(`/messages`, {chat});
        }

        onHide()
    }

    const goBack = () => {
        if(props.goToSupport) {
            props.goToSupport()
        }else {
            history.goBack()
        }
    }

    const onHide = () => {
        if (props.goToPreviousPath) {
            props.goToPreviousPath();
        }
    }

    const renderView = () => {
        return (
            <React.Fragment>
                <div className={`container-Support container-support ${props.isDesktop ? 'isDesktop':''}`}>
                        <div className="container_header">
                            {
                                !props.isDesktop ? (
                                    <img src={back} width="30px" height="30px" onClick={() => goBack()}/>
                                ):(null)
                            }
                            <h3>Soporte</h3>
                        </div>

                        <div className="container_body">
                            <img src={support} width="90px" height="90px" />


                            <p className="container_body_text_blue">¿Necesitas ayuda?</p>
                            <p className="container_body_text_black px-3">Escríbenos y a la brevedad nuestro equipo de Soporte se pondrá en contacto contigo.</p>


                            <div className="container_body_card" onClick={() => setChat()}>
                                <div className="container_body_card_content_left">
                                    <p>Envia mensaje por</p>
                                    <img src={logo} width="102px" height="24px"/>
                                </div>
                                <img src={after} width="30px" height="30px"/>
                            </div>

                            <a name="whatsapp" href={`https://api.whatsapp.com/send?phone=${version.WhatsApp}`} target="_blank" rel="noreferrer">
                                <div className="container_body_card">
                                    <div className="container_body_card_content_left">
                                        Envia mensaje por
                                        <img src={whatssap} width="138px" height="32px" name="whatsapp"/>
                                    </div>
                                    <img src={after} width="30px" height="30px"/>
                                </div>
                            </a>


                            <a href={`mailto:${version.Email}`} target="_blank" rel="noreferrer">
                                <div className="container_body_card">
                                    <div className="container_body_card_content_left">
                                        Envia mensaje por
                                        <div className="container_body_card_content_left_correo" >
                                            <img src={correo} width="28px" height="20px"/>
                                            <p>Correo electrónico</p>
                                        </div>
                                    </div>
                                    <img src={after} width="30px" height="30px"/>
                                </div>
                            </a>


                        </div>
                </div>
            </React.Fragment>
        );
    }

    if (props.hideModal) {
        return (
            <ModalDefault show={true} className='super full-view'>
                {renderView()}
            </ModalDefault>
        );
    } else {

        return renderView();
    }
}

export {Support};
