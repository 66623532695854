import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import './CreatorsContentsModal.scss';

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { BottomSheetShared, ModalDefault, ContentPost, ContentDetailModal } from '../';
import { 
    infoService, 
    profileService, 
    swalAlertsService, 
    authenticationService,
    algoliaService
} from '../../../_resources/services/';
import { 
    useDidMountEffect,
    useElementOnScreen,
    useWindowDimensions,
 } from '../../../_resources/helpers';

import icnsNetworks from '../../../_assets/media/images/icons/icns_networks.png';
import icnDeleteFilter from '../../../_assets/media/images/icons/icn_delete_filter.png';
import icnClose from '../../../_assets/media/images/icons/icn_close.png';
import icnsReactions from '../../../_assets/media/images/icons/icns_reactions.png';
import icnStar from '../../../_assets/media/images/icons/icn_batch_star.svg';
import icnMore from '../../../_assets/media/images/icons/icn_more.png';

const CreatorsContentsModal = (props) => {
    const [currentUser, setCurrentUser] = useState(authenticationService.currentUserValue);
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(true);
    const [inputValues, setInputValues] = useState([]);
    const [inputValuesBase, setInputValuesBase] = useState([]);
    const [catalogs, setCatalogs] = useState(null);
    const [reactions, setReactions] = useState([]);
    const [posts, setPosts] = useState([]);

    const [isFetchingData, setIsFetchingData] = useState(false);
    const [algoliaData, setAlgoliaData] = useState({
        nbHits: 0,
        nbPages: 0,
        page: 0,
    });

    const [lastItemRef, isVisible] = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
    });

    const history = useHistory();

    const {width, height} = useWindowDimensions();
    const onBottomSheetDimiss = () => {
        if (props.onBottomSheetDimiss) {
            props.onBottomSheetDimiss();
        }
    }

    const prepareFilters = (page = 0) => {
        let parameters = {
            numericFilters: [],
            facets: ["TipoPublicacion"],
            facetFilters: [['TipoPublicacion: Contenidos']],
            page: page || 0
        };
        
        if (props.creator) {
            parameters.numericFilters = [`clv_usuario=${props.creator.clv_usuario}`];
        }

        return parameters;
    };

    async function loadPosts(page = 0, shouldScroll = true) {
        let parameters = prepareFilters(page);
        
        const response = await algoliaService.getResults(
            '',
            parameters,
            'Publicaciones',
            true
        );
        return response;
    }

    const getInputValue = (key) => {

        if (key && inputValues && inputValues.length) {
            let filtered = inputValues.filter(value => value.RedSocial === key);

            if (filtered.length && filtered[0].Activo) {
                return filtered[0].Perfil;
            }
        }

        return null;
    }

    const setupInputs = () => {
        setTimeout(() => {
            if (props.networks && props.networks.length) {
                props.networks.forEach((network) => {
                    if (network.RedSocial) {
                        let element = document.getElementById(network.RedSocial);

                        if (element) {
                            element.value = network.Perfil;
                        }
                    }
                });
            }
        },200);
    }

    const prepareCatalogs = () => {
        infoService.catalogs.subscribe((catalogs) =>{
            if (catalogs) {
                setCatalogs(catalogs);
            }
        });
    }

    useEffect(() => {
        let isSuscribed = true;
        if (props.creator) {
           setIsFetchingData(true);
            const postsCP = loadPosts(0, true);

            postsCP.then((response) => {
                if (isSuscribed) {
                    if (response) {
                        let algoliaDataCP = JSON.parse(JSON.stringify(algoliaData));

                        const { nbHits, nbPages, page } = response;
                        algoliaDataCP = {
                            nbHits: nbHits || 0,
                            nbPages: nbPages || 0,
                            page: page || 0,
                        };
                        setAlgoliaData(algoliaDataCP);

                        if (response.hits && response.hits.length) {
                            setPosts(response.hits);
                        } else {
                            setPosts([]);
                        }
                    } else {
                        setPosts([]);
                    }
                    setIsFetchingData(false);
                }
            });
        }
        return () => (isSuscribed = false);
    }, []);

    const viewHasChanges = () => {
        let inputValuesCP = JSON.stringify(inputValues);
        let inputValuesBaseCP = JSON.stringify(inputValuesBase);

        if (inputValuesCP !== inputValuesBaseCP) {
            return true;
        }
        return false;
    }

    const footerBtn = () => {
        return <button className={`btn-cleared ${ viewHasChanges() ? 'active':''}`} onClick={onSubmitBtnTapped}>
            Guardar
        </button>
    }

    const onSubmitBtnTapped = () => {
        if (viewHasChanges()) {
            let obj = {RedesSociales: inputValues};
            let loader = swalAlertsService.createLoading("Espere un momento...");
            loader.fire();

            profileService.updateSocialNetworks(obj).then(
                (response) => {
                    loader.close();

                    if (response) {
                        if (response.data) {
                            swalAlertsService.showToast("success", "Redes sociales actualizadas correctamente");
                            if (props.dataUpdated) {
                                props.dataUpdated();
                            }
                        } else {
                            swalAlertsService.showToast("error", "Ocurrio un error inténtalo de nuevo más tarde");
                        }
                    }
                }
            )
        }

    }

    const onInputChanged = (e) => {
        if (catalogs) {
            const {RedesSociales} = catalogs;
            if (RedesSociales && RedesSociales.length) {
                let inputValuesCP = JSON.parse(JSON.stringify(inputValues));

                if (inputValuesCP && inputValuesCP.length) {
                    let filteredItem = inputValuesCP.filter(item => item.RedSocial === e.target.name);

                    if (filteredItem.length) {
                        let index = inputValuesCP.indexOf(filteredItem[0]);

                        if (index >= 0) {
                            inputValuesCP[index].Perfil = e.target.value;
                            inputValuesCP[index].Activo = true;

                            setInputValues(inputValuesCP);
                        }
                    } else {
                        let filteredNetwork = RedesSociales.filter(red => red.RedSocial === e.target.name);

                        if (filteredNetwork.length) {
                            let obj = {
                                clv_redsocial: filteredNetwork[0].clv_redsocial,
                                RedSocial: filteredNetwork[0].RedSocial,
                                Perfil: e.target.value,
                                Activo: true
                            }

                            inputValuesCP.push(obj);
                            setInputValues(inputValuesCP);
                        }
                    }
                } else {
                    let filteredNetwork = RedesSociales.filter(red => red.RedSocial === e.target.name);

                    if (filteredNetwork.length) {
                        let obj = {
                            clv_redsocial: filteredNetwork[0].clv_redsocial,
                            RedSocial: filteredNetwork[0].RedSocial,
                            Perfil: e.target.value,
                            Activo: true
                        }
                        setInputValues([obj]);
                    }

                }
            }
        }

    }

    const onNetworkDeleted = (key) => {
        let inputValuesCP = JSON.parse(JSON.stringify(inputValues));

        if (key && inputValuesCP && inputValuesCP.length) {
            let filtered = inputValuesCP.filter(item => item.RedSocial === key);

            if (filtered.length) {
                filtered[0].Activo = false;
                filtered[0].Perfil = '';
                let index = inputValuesCP.indexOf(filtered[0]);

                let element = document.getElementById(key);

                if (element) {
                    element.value = '';
                }
                if (index >= 0) {
                    inputValuesCP[index] = filtered[0];
                    setInputValues(inputValuesCP);
                }
            }
        }
    }

    const showLikesCounter = () => {
        if (props.reactions && props.reactions.Reacciones) {
            return (
                <span className="likesCounter">
                    <img src={icnsReactions} alt="icnsReactions" width='25' height='25'/>
                    {props.reactions.Reacciones}
                </span>
            )
        }

        return null;
    }

    const goToUser = (user) => {
        if (user && user.clv_usuario) {
            if(currentUser.Usuario.clv_usuario === user.clv_usuario) {
                history.replace('/profile')
            }else {
                history.push(`/profile-user/${user.clv_usuario}` , {from: '/'} )
            }
        }
    }

    const onPostLiked = (item) => {
        if (posts && posts.length && item && item.clv_publicacion) {
            let loading = swalAlertsService.createLoading("Enviando reacción", "");
            loading.fire();
            profileService
                .postGeneric(`publication/${item.clv_publicacion}/reaction`, "POST", {
                    clv_tiporeaccion: 1,
                })
                .then((response) => {
                    if (response && response.data) {
                        loading.close();
                        if (response.publication) {
                            let postsCP = JSON.parse(JSON.stringify(posts));
                            const filtered = postsCP.filter(
                                (ele) =>
                                    ele.clv_publicacion === response.publication.clv_publicacion
                            );

                            if (filtered.length) {
                                let index = postsCP.indexOf(filtered[0]);

                                if (index >= 0) {
                                    postsCP[index] = response.publication;
                                    setPosts(postsCP);
                                }
                            }
                        }
                        profileService
                            .getGeneric("user/reactions")
                            .then((responseReactions) => {
                                if (responseReactions && responseReactions.data) {
                                    setReactions(responseReactions.data);
                                }
                            });
                    }
                });
        }
    };

    const onShowContentDetails = (data) => {
        if (props.onShowContentDetails) {
            props.onShowContentDetails(data);
        }
    }

    const contentPosts = () => {
        return posts.length && posts.map((item, index) => {
            return (
                <ContentPost key={`contentPost-${index}`}
                    item={item}
                    reactions={reactions}
                    postLiked={onPostLiked}
                    fontSize={props.letterSize || 18}
                    lineHeight={props.lineSize || 22}
                    showDetails={onShowContentDetails}
                />
            );
        });
    }

    const postsList = () => {
        const items = contentPosts();
        
        return (
            <div id="Home-post-container"
                className={`Home-post-container`}>
                {posts.length ? <React.Fragment>{items}</React.Fragment> : null}

                <div ref={lastItemRef} className="pt-0"></div>

                {isFetchingData ? (
                    <div className="row mx-0 Home-loaderBox">
                        <div className="col-12 px-0 pb-3">
                            <h1>
                                <Skeleton />
                            </h1>
                        </div>

                        <div className="col-12 px-0">
                            <Skeleton count={3} />
                        </div>
                    </div>
                ) : null}
            </div>
        );
    };
    
    const renderView = (isDesktop) => {
        return (
            <div className={`row m-0 CreatorsContentsModal-container ${isDesktop ? 'isDesktop':''}`}>

                {
                    isDesktop ? (
                        <div className="col-12 CreatorsContentsModal-title-container">
                            <span>{props.creator && props.creator.NombreCompleto ? props.creator.NombreCompleto:"Contenidos"}</span>

                            <button className="btn-cleared" onClick={onBottomSheetDimiss}>
                                <img src={icnClose} alt="icnClose"/>
                            </button>
                        </div>
                    ):(null)
                }

                <div className="col-12 CreatorsContentsModal-items-container px-0">
                    <div className="row m-0">
                        {postsList()}
                    </div>
                </div>
            </div>
        );
    }

    const mobileRender = () => {
        return (
            <BottomSheetShared showHeader={true} showCancelBtn={true}
                blocking={true}
                isOpen={isBottomSheetOpen}
                onDismiss={onBottomSheetDimiss}
                title={props.creator && props.creator.NombreCompleto ? props.creator.NombreCompleto:"Contenidos"}
                footerBtn={false}
                shouldClose={true}
                isCloseSymbol={true}
            >
                {renderView(false)}
                
            </BottomSheetShared>
        )
    }

    const desktopRender = () => {
        return (
            <ModalDefault show={true} overAll={true} onHide={onBottomSheetDimiss}>
                {renderView(true)}
            </ModalDefault>
        )
    }

    return (
        <React.Fragment>
            {
                width && width >= 1450 ? (desktopRender()):(mobileRender())
            }
        </React.Fragment>

    )
}

export { CreatorsContentsModal };
