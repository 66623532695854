import { BehaviorSubject } from 'rxjs';
import { authHeader, handleResponse } from '../helpers';
import md5 from 'md5';
import { swalAlertsService } from './swal-alerts.service';
//const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

const apiPath = process.env.REACT_APP_API_PATH;
export const settingService = {
    deleteBloquedUser,
    getBloquedUser,
    getRestrictedUser,
    deleteRestrictedUser,
    updatePassword,
    deleteAccount
};




function deleteBloquedUser(clv) {
    let body = {
        "clv_bloqueadoxusuario": clv
    }
   const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(body)
    };

    return fetch(`${apiPath}user/block`, requestOptions)

}

function updatePassword(data) {
    let body = {
        "OldPassword": md5(data.OldPassword),
        "NewPassword": md5(data.NewPassword)
    }
   const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    };

    return fetch(`${apiPath}user/updatepassword`, requestOptions)
}

function deleteRestrictedUser(clv) {
    let body = {
        "clv_restringidoxusuario": clv
    }
   const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(body)
    };

    return fetch(`${apiPath}user/restrict`, requestOptions)

}

function getBloquedUser() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiPath}user/blockedusers`, requestOptions)
            .then(response => handleResponse(response, false, false))
            .then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return user;
            }, (error) => {
                return error;
            });

}


function getRestrictedUser() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiPath}user/restrictedusers`, requestOptions)
            .then(response => handleResponse(response, false, false))
            .then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return user;
            }, (error) => {
                return error;
            });
}

function deleteAccount(){
   const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: null
    };

    return fetch(`${apiPath}user`, requestOptions)
}