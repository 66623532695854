import React, {
    useState,
    useEffect,
    useRef,
    useMemo,
    useCallback,
    ReactElement ,
    MouseEvent
} from 'react';

import * as DataTransfer from 'fbjs/lib/DataTransfer';
import './InputBox.scss';

import { toolsHelper, useDidMountEffect } from '../../../_resources/helpers';
import {
    swalAlertsService,
    authenticationService,
    algoliaService
} from '../../../_resources/services';

import { AudioRecorder, MentionsSuggestions } from '../';

import icnAttachment from '../../../_assets/media/images/icons/icn_attachment.png';
import icnSend from '../../../_assets/media/images/icons/icn_send.png';
import icnCancel from '../../../_assets/media/images/icons/icn_cancel.png';
import icnMic from '../../../_assets/media/images/icons/icn_mic.png';
import icnPlay from '../../../_assets/media/images/icons/icn_play_video.png';
import icnPlaceholder from '../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnStar from '../../../_assets/media/images/icons/icn_batch_star.svg';
import icnsReply from '../../../_assets/media/images/icons/icns_reply.png';

import { EditorState, convertToRaw, Modifier, ContentState, convertFromRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
    defaultSuggestionsFilter,
    MentionData,
    MentionPluginTheme,
} from '@draft-js-plugins/mention';
import createMentionEntities from '../../../_resources/helpers/mentions';


function Entry(props) {
    const {
        mention,
        theme,
        searchValue,
        isFocused,
        ...parentProps
    } = props;

    let cleanProps = { ...parentProps };
    ['selectMention'].forEach(prop => {
        delete cleanProps[prop];
    });

    return (
        <div {...cleanProps} className='InputBox-mention-box col-12'>
            <div className='InputBox-mention-main row m-0'>
                <div className='InputBox-mention-left avatar-image-box-45 col'>
                    <img src={mention.avatar ? mention.avatar:icnPlaceholder}
                        alt="User image"
                        className="avatar"
                        onError={(e)=>{
                            e.target.onerror = null;
                            e.target.src=icnPlaceholder
                        }}
                        role="presentation"
                    />
                    {
                        mention.clv_estatusbm === 2 ? (
                            <img src={icnStar} alt="icnStar" className='bmIcon'/>
                        ):(null)
                    }
                </div>

                <div className='InputBox-mention-right col'>
                    <div className="row m-0">
                        <div className="col-12 p-0 InputBox-mention-name text-truncate">
                            {mention.name}
                        </div>
                        <div className="col-12 p-0 InputBox-mention-title text-truncate">
                            {mention.title}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const InputBox = (props) => {
    const [currentUser, setCurrentUser] = useState(authenticationService.currentUserValue);
    const [renderInputFile, setRenderInputFile] = useState(true);
    const inputFile = useRef(null);
    const [message, setMessage] = useState('');

    const [isRecording, setIsRecording] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [mentionsPicked, setMentionsPicked] = useState([]);

    const [inputState, setInputState] = useState({
        file: null,
        edited: false
    });

    const [chatProps, setChatProps] = useState(null);

    const refEditor = useRef(null);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const [suggestions, setSuggestions] = useState([]);
    const [mentions, setMentions] = useState([]);

    const [activeFilters, setActiveFilters] = useState({
        filtersSelected: null,
        postedBy: null,
        isUsingFilters: false
    });

    const [openMentions, setOpenMentions] = useState(false);

    const [{ MentionSuggestions, plugins}] = useState(() => {
        const mentionPlugin = createMentionPlugin({
            entityMutability: 'IMMUTABLE',
            mentionPrefix: '@',
            supportWhitespace: true,
        });
        const { MentionSuggestions } = mentionPlugin;

        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    });

    const storeChat = (text, clv_conversacion) => {
        if (chatProps && ((chatProps.chat && chatProps.chat.clv_conversacion) || (chatProps.isGroup && chatProps.groupDetails && chatProps.groupDetails.clv_conversacion))) {
            let storedChats = localStorage.getItem('storedChats');
            let clv_conversacion = null;

            if (chatProps.chat && chatProps.chat.clv_conversacion) {
                clv_conversacion = chatProps.chat.clv_conversacion;
            } else if (chatProps.isGroup && chatProps.groupDetails && chatProps.groupDetails.clv_conversacion) {
                clv_conversacion = chatProps.groupDetails.clv_conversacion;
            }

            if (clv_conversacion) {
                if (storedChats) {
                    try {
                        storedChats = JSON.parse(storedChats);

                        if (storedChats && storedChats.length) {
                            let filtered = storedChats.filter(el => el.clv_conversacion === clv_conversacion);

                            if (filtered.length) {
                                const index = storedChats.indexOf(filtered[0]);

                                filtered[0].text = text;

                                if (index >= 0 ) {
                                    storedChats[index] = filtered[0];
                                    localStorage.setItem('storedChats', JSON.stringify(storedChats));
                                }

                            } else {
                                storedChats.push({clv_conversacion, text});
                                localStorage.setItem('storedChats', JSON.stringify(storedChats));
                            }
                        } else {
                            localStorage.setItem('storedChats', JSON.stringify([{clv_conversacion, text}]));
                        }
                    } catch (e) {}
                } else {
                    localStorage.setItem('storedChats', JSON.stringify([{clv_conversacion, text}]));
                }
            }
        }
    }

    const onDeleteStoredChat = () => {
        if (chatProps && ((chatProps.chat && chatProps.chat.clv_conversacion) || (chatProps.isGroup && chatProps.groupDetails && chatProps.groupDetails.clv_conversacion))) {
            let storedChats = localStorage.getItem('storedChats');
                let clv_conversacion = null;

            if (storedChats) {
                try {
                    storedChats = JSON.parse(storedChats);
                } catch (e) {}

                if (storedChats.length) {
                    if (chatProps.chat && chatProps.chat.clv_conversacion) {
                        clv_conversacion = chatProps.chat.clv_conversacion;
                    } else if (chatProps.isGroup && chatProps.groupDetails && chatProps.groupDetails.clv_conversacion) {
                        clv_conversacion = chatProps.groupDetails.clv_conversacion;
                    }

                    if (clv_conversacion) {
                        let filtered = storedChats.filter(el => el.clv_conversacion === clv_conversacion);

                        if (filtered.length) {
                            const index = storedChats.indexOf(filtered[0]);

                            if (index >= 0) {
                                let chatss = [];
                                storedChats.forEach((el) => {
                                    if (el.clv_conversacion !== clv_conversacion) {
                                        chatss.push(el);
                                    }
                                });
                                localStorage.setItem('storedChats', JSON.stringify(chatss));
                            }
                        }
                    }
                }

            }
        }
    }

    const onSubmitAudio = (data) => {
        if (props.onSubmit) {
            props.onSubmit(data);
            setIsRecording(false);
        }
    }

    const onSubmitBtnTapped = () => {
        if (message || inputState.file) {
            if (props.onSubmit) {
                onDeleteStoredChat();
                let obj = JSON.parse(JSON.stringify(inputState));
                let messageTxt = message;
                let mentionsPickedCP = JSON.parse(JSON.stringify(mentionsPicked));

                let mentionsStored = localStorage.getItem('mentionsStored');

                if (mentionsStored) {
                    try {
                        mentionsStored = JSON.parse(mentionsStored);

                        // if (chatProps.isGroup && chatProps.groupDetails && chatProps.groupDetails.clv_conversacion) {
                            let clvConversation;

                            if (chatProps.isGroup && chatProps.groupDetails && chatProps.groupDetails.clv_conversacion) {
                                let clvConversation = chatProps.groupDetails.clv_conversacion;
                            } else if (!chatProps.isGroup && chatProps.chat && chatProps.chat.clv_conversacion) {
                                let clvConversation = chatProps.chat.clv_conversacion;
                            }

                            if (mentionsStored.length && clvConversation) {
                                let filtered = mentionsStored.filter(ele => ele.clv_conversacion === clvConversation);

                                if (filtered.length && filtered[0].mentions && filtered[0].mentions.length) {


                                    if (mentionsPickedCP.length) {
                                        let notListed = [];
                                        filtered[0].mentions.forEach((mention) => {
                                            let pickedFiltered = mentionsPickedCP.filter(el => el.clv_usuario === mention.clv_usuario);

                                            if (!pickedFiltered.length) {
                                                notListed.push(pickedFiltered[0]);
                                            }
                                        });

                                        if (notListed.length) {
                                            mentionsPickedCP = mentionsPickedCP.concat(notListed);
                                        }
                                    } else {
                                        mentionsPickedCP = filtered[0].mentions;
                                    }

                                    if (mentionsStored.length > 1) {
                                        let index = mentionsStored.indexOf(filtered[0]);
                                        if (index >= 0) {
                                            mentionsStored.splice(index, 1);
                                            localStorage.setItem('mentionsStored', JSON.stringify(mentionsStored));
                                        }
                                    } else {
                                        localStorage.removeItem('mentionsStored');
                                    }
                                }

                            }


                            if (mentionsPickedCP.length) {
                                mentionsPickedCP.forEach((item, i) => {
                                        console.log(item);
                                        if(item.name.indexOf('@') > -1){
                                            messageTxt = messageTxt.replaceAll(`${item.name}`, `@@${item.clv_usuario}`);
                                        }else{
                                            messageTxt = messageTxt.replaceAll(`@${item.name}`, `@@${item.clv_usuario}`);
                                        }
                                });

                                obj.Menciones = mentionsPickedCP;
                            }
                        // }
                    } catch (e) {}
                } else {
                    if (mentionsPickedCP.length) {
                        mentionsPickedCP.forEach((item, i) => {
                            console.log(item);
                                        if(item.name.indexOf('@') > -1){
                                            messageTxt = messageTxt.replaceAll(`${item.name}`, `@@${item.clv_usuario}`);
                                        }else{
                                            messageTxt = messageTxt.replaceAll(`@${item.name}`, `@@${item.clv_usuario}`);
                                        }
                        });
                        obj.Menciones = mentionsPickedCP;
                    }
                }

                obj.message = messageTxt;
                if (inputState.file) {
                    obj.file = inputState.file;
                }

                props.onSubmit(obj);

                // setTimeout(() => {
                    insertTxtToEditor('');
                    focusEditor();
                    setInputState({
                        file: null,
                        edited: false
                    });
                    setMentionsPicked([]);
                    setMessage('');
                // },200);
            }
        } else {
            swalAlertsService.showToast('error', 'Debes escribir un mensaje para continuar');
        }
    }

    const resetFile = () => {
        setTimeout(() => {
            if (inputFile && inputFile.current) {
                inputFile.current.value = ''
            }
        }, 200);
    }

    const focusEditor = () => {
        if (showEditor) {
            setTimeout(() => {
                if (refEditor && refEditor.current) {
                    refEditor.current.focus();
                }
            }, 200);
        }
    }

    const convertToMB = (bytesValue) => {
        if (bytesValue === 0) return 0;
        const gbValue = (bytesValue / (1000 * 1000)).toFixed(2);
        return gbValue;
    }

    async function processFile (event) {

        let loading = swalAlertsService.createLoading('Procesando archivo', '');
        loading.fire();
        console.log(event.target.files[0].type);
        if (
            event &&
            event.target &&
            event.target.files &&
            event.target.files.length &&
            event.target.files[0].type.indexOf('video') >= 0

        ) {
            if (event.target.files[0].type.indexOf('quicktime') >= 0 || event.target.files[0].type.indexOf('mov') >= 0) {
                loading.close();
                swalAlertsService.showToast('error', 'Formato de video no permitido');
                resetFile();
                return null;
            } else if (convertToMB(event.target.files[0].size) > 20) {
                loading.close();
                swalAlertsService.showToast('error', 'El tamaño del video no debe exceder los 20mb');
                resetFile();
                return null;
            }
        }
        if( event &&
            event &&
            event.target &&
            event.target.files &&
            event.target.files.length &&
            event.target.files[0].type.indexOf('image') >= 0)
        {
            if(convertToMB(event.target.files[0].size) > 10){
                loading.close();
                swalAlertsService.showToast('error', 'El tamaño de las imagenes no debe exceder los 10mb');
                resetFile();
                return null;
            }
        }
        if( event &&
            event &&
            event.target &&
            event.target.files &&
            event.target.files.length &&
            event.target.files[0].type.indexOf('pdf') >= 0)
        {
            if(convertToMB(event.target.files[0].size) > 20){
                loading.close();
                swalAlertsService.showToast('error', 'El tamaño de los pdf no debe exceder los 20mb');
                resetFile();
                return null;
            }
        }

        if( event &&
            event &&
            event.target &&
            event.target.files &&
            event.target.files.length &&
            event.target.files[0].type.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') >= 0)
        {
            if(convertToMB(event.target.files[0].size) > 20){
                loading.close();
                swalAlertsService.showToast('error', 'El tamaño de los excel no debe exceder los 20mb');
                resetFile();
                return null;
            }
        }

        if( event &&
            event &&
            event.target &&
            event.target.files &&
            event.target.files.length &&
            event.target.files[0].type.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') >= 0)
        {
            if(convertToMB(event.target.files[0].size) > 20){
                loading.close();
                swalAlertsService.showToast('error', 'El tamaño de los word no debe exceder los 20mb');
                resetFile();
                return null;
            }
        }

        if( event &&
            event &&
            event.target &&
            event.target.files &&
            event.target.files.length &&
            event.target.files[0].type.indexOf('application/vnd.openxmlformats-officedocument.presentationml.presentation') >= 0)
        {
            if(convertToMB(event.target.files[0].size) > 20){
                loading.close();
                swalAlertsService.showToast('error', 'El tamaño de los power point no debe exceder los 20mb');
                resetFile();
                return null;
            }
        }

        let result = await toolsHelper.getFile(event);
        console.log('result ', result);
        if (result && result.type) {
            loading.close();
            if (result.type && result.type.indexOf('image') >= 0) {
                props.cutImage({image: result.base64, text: message});
            } else if (result.type.indexOf('pdf') >= 0) {
                let inputStateCP = JSON.parse(JSON.stringify(inputState));
                inputStateCP.file = result;
                setInputState(inputStateCP);
            } else if (result.type.indexOf('video') >= 0) {
                let inputStateCP = JSON.parse(JSON.stringify(inputState));
                const duration = await toolsHelper.getVideoDuration(result.base64);
                result.duration = duration;
                inputStateCP.file = result;
                inputStateCP.type = 'video';
                setInputState(inputStateCP);
            }else if(result.type.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') >= 0){
                let inputStateCP = JSON.parse(JSON.stringify(inputState));
                inputStateCP.file = result;
                setInputState(inputStateCP);
            }else if(result.type.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') >= 0){
                let inputStateCP = JSON.parse(JSON.stringify(inputState));
                inputStateCP.file = result;
                setInputState(inputStateCP);
            }else if(result.type.indexOf('application/vnd.openxmlformats-officedocument.presentationml.presentation') >= 0){
                let inputStateCP = JSON.parse(JSON.stringify(inputState));
                inputStateCP.file = result;
                setInputState(inputStateCP);
            }
        }
    }

    const onAddFileBtnTapped = () => {
        inputFile.current.click();
    }

    const onFileDelete = () => {
        let inputStateCP = JSON.parse(JSON.stringify(inputState));
        if (inputStateCP.file.fromEdit) {
            inputStateCP.file.deleted = true;
            setInputState(inputStateCP);
        } else {
            inputStateCP.file = null;
            setInputState(inputStateCP);
            setRenderInputFile(false);
        }
        resetFile();
        if (props.onFileDelete) {
            props.onFileDelete();
        }
    }

    const prepareEditComment = () => {
        if (props.commentEdit) {
            let commentEdit = JSON.parse(JSON.stringify(props.commentEdit));

            let obj = {
                file: null,
                edited: true
            };

            if (commentEdit.Adjuntos.length) {
                let file = {
                    base64: commentEdit.Adjuntos[0].URL,
                    type: commentEdit.Adjuntos[0].Extension,
                    fromEdit: true,
                    deleted: false,
                    name: commentEdit.Adjuntos[0].Nombre || ''
                }
                obj.file = file;
            }

            let message = commentEdit.Mensaje;
            let value = commentEdit.Mensaje;
            if (commentEdit.Menciones && commentEdit.Menciones.length) {
                let finalValue = [];
                value = value.trim();
                let tokenString = value.split(' ');
                let keyValue = 0;
                if (tokenString.length) {
                    tokenString.forEach((item, i) => {
                        if (item.length) {

                            if (item.indexOf('@@') >= 0) {
                                const brSpacing = item.indexOf('\n') >= 0;
                                const fixedTxt = item.replaceAll('\n', '');
                                const mentioned = commentEdit.Menciones.filter(el => el.Tag === fixedTxt);

                                if (mentioned.length) {
                                    keyValue += 1;
                                    const obj = [0].map((el, i) => {
                                        if (brSpacing) {
                                            return (mentioned);
                                        }
                                        return (mentioned);
                                    });
                                    finalValue = finalValue.concat(obj);
                                }
                            } else {
                                keyValue += 1;
                                const obj = [0].map((el, i) => {
                                    return (item);
                                });
                                finalValue = finalValue.concat(obj);
                            }
                        }
                    });

                }
                let finalMessage = '';
                finalValue.map( value =>{
                    if(Array.isArray(value)){
                        finalMessage += ` @${value[0].NombreCompleto }`;
                    }else{
                        finalMessage += ` ${value }`;
                    }
                });
                message = finalMessage;
                let cpMentions = JSON.parse(JSON.stringify(commentEdit.Menciones));

                cpMentions.map( mention =>{
                    mention.id = mention.clv_usuario;
                    mention.name = '@'+mention.NombreCompleto;
                    mention.link = `/profile-user/${mention.clv_usuario}`
                });

                setMentionsPicked(cpMentions);
                let messageWithMentionsRaw = createMentionEntities(message, cpMentions);
                let newStateEditor = EditorState.push(editorState, messageWithMentionsRaw);
                newStateEditor = EditorState.moveSelectionToEnd(newStateEditor);
                setEditorState(newStateEditor);
            }else{
                insertTxtToEditor(message);
            }
            setTimeout(() => {
                setShowEditor(true);
            },200);
            setMessage(commentEdit.Mensaje);
            setInputState(obj);
            focusEditor();
        }
    }

    const getStoredChat = (chatProps) => {
        let storedChats = localStorage.getItem('storedChats');

        if (storedChats) {
            try {
                storedChats = JSON.parse(storedChats);

                if (storedChats && storedChats.length) {
                    if (chatProps.chat) {
                        let filtered = storedChats.filter(stored => stored.clv_conversacion === chatProps.chat.clv_conversacion);
                        if (filtered.length) {
                            return filtered[0];
                        }
                    } else if (chatProps.isGroup && chatProps.groupDetails && chatProps.groupDetails.clv_conversacion) {
                        let filtered = storedChats.filter(stored => stored.clv_conversacion === chatProps.groupDetails.clv_conversacion);
                        if (filtered.length) {
                            return filtered[0];
                        }
                    }
                }
            } catch (e) {}
        }
        return null;
    }

    const checkIfStoredChat = () => {

        if (chatProps) {
            let storedChat = getStoredChat(chatProps);

            if (storedChat) {
                let inputStateCP = JSON.parse(JSON.stringify(inputState));
                insertTxtToEditor(storedChat.text);
                setShowEditor(true);
                setTimeout(() => {
                    if (refEditor && refEditor.current) {
                        refEditor.current.focus();
                    }
                }, 200);
            } else {
                setShowEditor(true);
                focusEditor();
            }
        } else {
            setShowEditor(true);
        }
    }

    useEffect(() => {
        let isSuscribed = true;
        if (!renderInputFile && isSuscribed) {
            setTimeout(() => {
                setRenderInputFile(true);
            }, 500);
        }

        return () => isSuscribed = false;
    }, [renderInputFile]);

    useEffect(() => {
        let isSuscribed = true;
        if (props && isSuscribed) {

            if (props.chatProps) {
                setChatProps(props.chatProps);
                // setTimeout(() =>{
                //     setShowEditor(true);
                // },200);
            } else if (props.isPublication) {
                setTimeout(() =>{
                    setShowEditor(true);
                },200);
            }
        }

        resetFile();
        setInputState({
            file: null,
            edited: false
        });

        insertTxtToEditor('');
        setTimeout(() => {
            if (props.commentEdit && refEditor) {
                prepareEditComment();
            }

            checkIfStoredChat();
        }, 200);
        if(props.imageCrop && props.imageCrop.blob) {
            var reader = new FileReader();
            reader.readAsDataURL(props.imageCrop.blob);
            reader.onloadend = async function() {
                let loader = swalAlertsService.createLoading("Espere un momento...")
                var data = reader.result;
                loader.fire()
                props.imageCrop.blob.base64 = data;
                setInputState({...inputState, file: props.imageCrop.blob});
                loader.close()
            }
        }
        return () => isSuscribed = false;
    }, [props]);

    useEffect(() => {
        if (props && props.chatProps) {
            setChatProps(props.chatProps);
        }
    }, []);

    useDidMountEffect(() => {
        let isSuscribed = true;
        if (isSuscribed) {
            storeChat(message);

            setTimeout(() => {
                setShowEditor(true);
            }, 200);
        }
        return () => isSuscribed = false;
    },[message]);

    const secondsToHumanTime = (secValue) => {
        if (secValue && !isNaN(secValue)) {
            const secNum = parseInt(secValue, 10); // don't forget the second param
            let hours = Math.floor(secNum / 3600);
            let minutes = Math.floor((secNum - (hours * 3600)) / 60);
            let seconds = secNum - (hours * 3600) - (minutes * 60);

            const hasHours = hours > 0 ? true:false;

            seconds = secNum < 1 ? 1:seconds;
            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            return `${hasHours ? `${hours}:`:''}${minutes}:${seconds}`;
        }
        return '00:00';
    }

    const onOpenChangeMentions = useCallback((_open) => {
        setOpenMentions(_open);
    }, []);

    const prepareFilters = () => {
        let parameters = {
            facets: ["Aficion", "Cargo", "Clasificacion", "Estado", "Giro", "Pais"],
            hitsPerPage: 999,
            facetFilters: []
        };

        if (currentUser && currentUser.Usuario && currentUser.Usuario.clv_usuario) {
            let userID = currentUser.Usuario.clv_usuario;

            parameters['numericFilters'] = [`BloqueadoPor.clv_usuario!=${currentUser.Usuario.clv_usuario}`, `clv_usuario!=${currentUser.Usuario.clv_usuario}`];

            const {filtersSelected} = activeFilters;

            if (filtersSelected) {
                let facetsRows = ["Aficiones", "Cargos", "Clasificaciones", "Estados", "Giros", "Paises"]
                facetsRows.forEach((facet, index) => {
                    if (filtersSelected[facet] && filtersSelected[facet].length) {
                        const elements = filtersSelected[facet].map(element => `${parameters.facets[index]}: ${element.name}`);
                        parameters.facetFilters.push(elements);
                    }
                });
            }
        }

        return parameters;
    }

    const onSearchChange = useCallback((e,chatProps) => {
        if (props) {
            // if (props.isPublication) {


                let parameters = prepareFilters();
                if (!parameters.numericFilters && currentUser && currentUser.Usuario && 'clv_usuario' in currentUser.Usuario) {
                    parameters.numericFilters = [`clv_usuario!=${currentUser.Usuario.clv_usuario}`];
                }

                const fetchData = async () => {
                    const response = await algoliaService.getResults(e.value, parameters, 'Directorio', false, false);
                    return response;
                }

                fetchData().then((response) => {
                    if (response && response.length) {
                        let mapped = response.map((user) => {
                            const id = user.clv_usuario;
                            const key = `${user.NombreCompleto}-${user.clv_usuario}`;
                            const entityKey = `${user.NombreCompleto}-${user.clv_usuario}`;
                            const name = user.NombreCompleto;
                            const link = `/profile-user/${user.clv_usuario}`;
                            const avatar = user.ThumbnailUrl || null;
                            const userId = user.clv_usuario;
                            const title = `${user.Cargo ? user.Cargo:''}${user.Cargo && user.EmpresaNombreCorto ? ' | ':''}${user.EmpresaNombreCorto ? user.EmpresaNombreCorto:''}`;
                            const clv_estatusbm = user.clv_estatusbm || null;
                            return {name, link, avatar, userId, title, clv_estatusbm, id, key, entityKey}
                        });
                        setSuggestions(mapped);
                    } else {
                        setSuggestions([]);
                    }
                }, (e) => {console.log(e);});
            // } else {
            //     setSuggestions(defaultSuggestionsFilter(e.value, getSuggestionsFromGroup(chatProps)));
            // }
        }
    }, []);

    const renderFiles = () => {

        let item = null;

        if (inputState && inputState.file && inputState.file.type && inputState.file.base64 && !inputState.file.deleted) {
            if (
                inputState.file.type.indexOf('pdf') >= 0 || 
                inputState.file.type.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') >= 0  || 
                inputState.file.type.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') >= 0 || 
                inputState.file.type.indexOf('application/vnd.openxmlformats-officedocument.presentationml.presentation') >= 0 
                ) {
                item = <div className="row m-0 InputBox-file-pdf">
                    <div className="col-12 p-0" onClick={onFileDelete}>
                        <div className='Input-inner-div text-truncate'>
                            <div className="text-truncate text-div">
                                {
                                    inputState.file.name ? inputState.file.name:'doc.pdf'
                                }
                            </div>
                            <img src={icnCancel} alt="cancel icon"/>
                        </div>
                    </div>
                </div>
            } else if (['jpg','png','jpeg','image', 'image/jpg', 'image/jpeg', 'image/png'].includes(inputState.file.type)) {
                item = <div className="row m-0 InputBox-file-image">
                    <div className="col-12 p-0 d-flex align-items-center justify-content-between">
                        <img src={inputState.file.base64} alt="image attachment" className='image-container'/>
                        <button className="btn-cleared" onClick={onFileDelete}>
                            <img src={icnCancel} alt="cancel icon"/>
                        </button>
                    </div>
                </div>
            } else if (['mp4','video', 'video/mp4'].includes(inputState.file.type)) {
                item = <div className="row m-0 InputBox-file-image">
                    <div className="col-12 p-0 d-flex align-items-center justify-content-between">
                        <div className="videoContainer-box">
                            <video src={inputState.file.base64} alt="image attachment" className='image-container'/>

                                <div className='videoOverlay'></div>
                                <img src={icnPlay} alt="icnPlay" className='videoPlayIcon'/>
                                {
                                    inputState.file.duration ? (
                                        <span className='durationSpan'>{secondsToHumanTime(inputState.file.duration)}</span>
                                    ):(null)
                                }
                        </div>
                        <button className="btn-cleared" onClick={onFileDelete}>
                            <img src={icnCancel} alt="cancel icon"/>
                        </button>
                    </div>
                </div>
            }
        }

        if (item) {
            return (
                <div className="row m-0 Input-file-container">
                    <div className="col-12 p-0">
                        {item}
                    </div>
                </div>
            );
        }

        return null;

    }

    const onTxtAreaBlur = () => {
        if (props.inputFocus && props.onBlur) {
            props.onBlur();
        }
    }

    const recordStop = () => {
        setIsRecording(false);
    }

    const showSenderToggle = () => {
        if (message || (inputState.file && renderFiles())) {
            return (
                <div className="col p-0 InputBox-send-container">
                    <button className="btn-cleared" onClick={onSubmitBtnTapped}>
                        <img src={icnSend} alt="send icon"/>
                    </button>
                </div>
            );
        } else {
            if (isRecording) {

                return (<AudioRecorder stopped={recordStop} submit={onSubmitAudio}/>);
            } else {
                return (
                    <div className="col p-0 InputBox-send-container">
                        <button className="btn-cleared" onClick={e => setIsRecording(true)}>
                            <img src={icnMic} alt="icnMic"/>
                        </button>
                    </div>
                );
            }
        }
    }

    const insertTxtToEditor = (text) => {
        let newStateEditor = EditorState.push(editorState, ContentState.createFromText(text));
        newStateEditor = EditorState.moveSelectionToEnd(newStateEditor);
        setEditorState(newStateEditor);
    }

    const getSuggestionsFromGroup = (chatData) => {
        if (
            props &&
            currentUser &&
            currentUser.Usuario &&
            currentUser.Usuario.clv_usuario
        ) {
            if (
                chatData &&
                chatData.isGroup &&
                chatData.groupDetails &&
                chatData.groupDetails.Usuarios &&
                chatData.groupDetails.Usuarios.length > 1
            ) {
                let data = chatData.groupDetails.Usuarios.filter(user => user.clv_usuario !== currentUser.Usuario.clv_usuario && !user.Eliminado);
                if (data.length) {
                    let mapped = data.map((user) => {
                        const id = user.clv_usuario;
                        const key = `${user.NombreCompleto}-${user.clv_usuario}`;
                        const entityKey = `${user.NombreCompleto}-${user.clv_usuario}`;
                        const name = user.NombreCompleto;
                        const link = `/profile-user/${user.clv_usuario}`;
                        const avatar = user.ThumbnailUrl || null;
                        const userId = user.clv_usuario;
                        const title = `${user.Cargo ? user.Cargo:''}${user.Cargo && user.EmpresaNombreCorto ? ' | ':''}${user.EmpresaNombreCorto ? user.EmpresaNombreCorto:''}`;
                        const clv_estatusbm = user.clv_estatusbm || null;
                        return {name, link, avatar, userId, title, clv_estatusbm, id, key, entityKey}
                    });
                    return mapped;
                }
            }
        }
        return [];
    }

    const onCancelReply = () => {
        if (props.onCancelReply) {
            props.onCancelReply();
        }
    }

    const replyContent = (reply) => {
        let icon = null;
        let message = '';
        if (reply.Mensaje) {
            message = reply.Mensaje;
        }

        if (reply.Adjuntos && reply.Adjuntos.length) {
            if ([1,2,8,9].includes(reply.Adjuntos[0].clv_tipoadjunto)) {
                icon = 'doc';
                if(!message.length) {
                    message = "Documento";
                }
            } else if ([3,4].includes(reply.Adjuntos[0].clv_tipoadjunto)) {
                icon = 'img';
                if(!message.length) {
                    message = "Foto";
                }
            } else if (reply.Adjuntos[0].clv_tipoadjunto == 6) {
                icon = 'video';
                if(!message.length) {
                    message = "Video";
                }
            } else if (reply.Adjuntos[0].clv_tipoadjunto == 7) {
                icon = 'audio';
                if(!message.length) {
                    message = "Audio";
                }
            } else if ([10,11,12].includes(reply.Adjuntos[0].clv_tipoadjunto)) {
                icon = 'link';
                if(!message.length) {
                    message = "Enlace";
                }
            } 
        }

        if (reply.Contactos && reply.Contactos.length) {
            icon = 'contact';
            message = reply.Contactos[0].Nombre ? reply.Contactos[0].Nombre:'Contacto';
        }

        return <>
            {
                icon ? <img src={icnsReply} className={icon}/>:null
            }
            {message}
        </>;
    }

    const renderReply = () => {
        if (!props.chatProps || !props.chatProps.reply || !props.chatProps.reply.clv_mensajexconversacion) {
            return null;
        }

        const isMine = currentUser && currentUser.Usuario && currentUser.Usuario.clv_usuario && props.chatProps.reply.clv_usuario == currentUser.Usuario.clv_usuario;

        return <div className="row m-0 input-reply-container">
            <div className="col">
                <div className="row m-0">
                    <div className="col-12 px-0 name-container">
                        {
                            isMine ? 'Yo':props.chatProps.reply.Nombre
                        }
                    </div>
                    <div className="col-12 px-0 details-container">{replyContent(props.chatProps.reply)}</div>
                </div>
            </div>
            <div className="col btn-closer-container">
                <button onClick={onCancelReply}>
                    <img src={icnCancel} alt="close icon" />
                </button>
            </div>
        </div>;
    }

    return (
        <div className={`InputBox-container row m-0 ${isRecording ? 'isRecording':''}`}>
            <div className="col-12 p-0">
                {
                    renderReply()
                }
                {
                    !isRecording && inputState.file && renderFiles()
                }

                <div className="row m-0 InputBox-editor-container">
                    {
                        !isRecording ? (
                            <>
                                <div className="col p-0 Input-attachment-btn-container">
                                    <button className="btn-cleared" onClick={onAddFileBtnTapped}>
                                        <img src={icnAttachment} alt="attachment icon"/>
                                    </button>
                                </div>
                                <div className="col Input-input-container">
                                    {
                                        showEditor ? (
                                            <>
                                                <Editor className='DraftEditor-testt'
                                                    editorKey={'editor'}
                                                    editorState={editorState}
                                                    onChange={(e) => {
                                                        const result = convertToRaw(e.getCurrentContent());
                                                        let value = '';
                                                        if (result && result.blocks) {
                                                            const blocks = result.blocks;
                                                            value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

                                                            if (value.length === 1 && (value.indexOf('\n') >= 0 || value.indexOf('\t') >= 0)) {
                                                                value = '';
                                                            }
                                                        }

                                                        setEditorState(e);
                                                        setMessage(value);
                                                    }}
                                                    plugins={plugins}
                                                    ref={refEditor}
                                                    onBlur={onTxtAreaBlur}
                                                    handlePastedFiles={(e) => {

                                                        if (e && e.length && e[0].type.indexOf('image') >= 0) {
                                                            processFile({target:{files:e}});
                                                        }
                                                    }}
                                                />

                                                <MentionSuggestions
                                                    open={openMentions}
                                                    onOpenChange={e => onOpenChangeMentions(e)}
                                                    suggestions={suggestions}
                                                    onSearchChange={e => {onSearchChange(e, chatProps)}}
                                                    onAddMention={(e) => {
                                                        console.log(e);
                                                        if (e && e.userId) {
                                                            let mentionsData = [];
                                                            if (mentionsPicked.length) {
                                                                let filterd = mentionsPicked.filter(el => el.clv_usuario === e.userId);

                                                                if (!filterd.length) {
                                                                    let mentionsPickedCP = JSON.parse(JSON.stringify(mentionsPicked));
                                                                    mentionsPickedCP.push({clv_usuario: e.userId, name: e.name});
                                                                    mentionsData = mentionsPickedCP;
                                                                    setMentionsPicked(mentionsPickedCP);
                                                                }
                                                            } else {
                                                                mentionsData = [{clv_usuario: e.userId, name: e.name}];
                                                                setMentionsPicked([{clv_usuario: e.userId, name: e.name}]);
                                                            }

                                                            if (chatProps) {
                                                                let clvConversation;

                                                                if (chatProps.isGroup && chatProps.groupDetails && chatProps.groupDetails.clv_conversacion) {
                                                                    let clvConversation = chatProps.groupDetails.clv_conversacion;
                                                                } else if (!chatProps.isGroup && chatProps.chat && chatProps.chat.clv_conversacion) {
                                                                    let clvConversation = chatProps.chat.clv_conversacion;
                                                                }

                                                                if (clvConversation) {
                                                                    let mentionsStored = localStorage.getItem('mentionsStored');

                                                                    if (mentionsStored) {
                                                                        try {
                                                                            mentionsStored = JSON.parse(mentionsStored);

                                                                            if (mentionsStored.length) {
                                                                                let filtered = mentionsStored.filter(el => el.clv_conversacion === clvConversation);

                                                                                if (filtered.length && filtered[0].mentions) {
                                                                                    if (filtered[0].mentions.length) {
                                                                                        let userFilterd = filtered[0].mentions.filter(ele => ele.clv_usuario === e.userId);

                                                                                        if (!userFilterd.length) {
                                                                                            filtered[0].mentions.push({clv_usuario: e.userId, name: e.name});
                                                                                        }
                                                                                    } else {
                                                                                        filtered[0].mentions.push({clv_usuario: e.userId, name: e.name});
                                                                                    }
                                                                                } else {
                                                                                    mentionsStored.push({clv_conversacion: clvConversation, mentions: mentionsData});
                                                                                }
                                                                            } else {
                                                                                mentionsStored.push({clv_conversacion: clvConversation, mentions: mentionsData});
                                                                            }

                                                                            localStorage.setItem('mentionsStored', JSON.stringify(mentionsStored));
                                                                        } catch (e) {

                                                                        }
                                                                    } else {
                                                                        localStorage.setItem('mentionsStored', JSON.stringify([{clv_conversacion: clvConversation, mentions: mentionsData}]));
                                                                    }
                                                                }


                                                            }

                                                        }
                                                    }}
                                                    entryComponent={Entry}
                                                />

                                            </>

                                        ):(null)
                                    }

                                </div>
                            </>
                        ):(null)
                    }

                    {showSenderToggle()}


                </div>
            </div>
            {
                renderInputFile &&
                <>
                    <input id="image"
                        type="file"
                        ref={inputFile}
                        onChange={(event) => {
                            processFile(event);
                        }}
                        onError={error => console.log(error)}
                        accept="image/*, application/pdf, video/mp4, video/x-m4v, video/quicktime, video/*, .docx, .xlsx, .pptx"
                        style={{display: 'none'}}
                        multiple={false}
                    />
                </>
            }
        </div>
    );
}

export { InputBox };
