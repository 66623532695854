import React, { useEffect, useRef, useCallback } from 'react';

import moment from "moment";

function validateEmail(email) {
    // const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    return re.test(email);
}

function validateURL(url) {
    const re = /(^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$)/;
    return re.test(url)
}

function timeSince(date, isNotification =  false) {


    if(isNotification){
        let dateNotification = moment(date);
        let yesterdayInital = moment().subtract(1, 'day').startOf('day');
        let yesterdayFinal = moment().subtract(1, 'day').endOf('day');

        if(dateNotification >= yesterdayInital && dateNotification <= yesterdayFinal){
            return `Ayer`;
        }
    }

    const dateVal = new Date(date);
    var seconds = Math.floor((new Date() - dateVal) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return getCompleteDateString(date);
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return getCompleteDateString(date);
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return getCompleteDateString(date);
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return `${Math.floor(interval)} h`;
        // return `${Math.floor(interval)} ${ Math.floor(interval) === 1 ? 'hora':'horas'}`;
    }
    interval = seconds / 60;
    if (interval > 1) {
        return `${Math.floor(interval)} m`;
    }


    return Math.sign(Math.floor(seconds))  == -1? `Hace unos segundos`: `${Math.floor(seconds)} s`;
}


function getDayName(dateString) {
    const days = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];
    const d = new Date(dateString);
    const dayName = days[d.getDay()];
    return dayName;
}

function getMonthName(dateString) {
    const monthNames = [
        "Ene", "Feb", "Mar", "Abr", "May", "Jun",
        "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
    ];

    const d = new Date(dateString);
    return monthNames[d.getMonth()];
}

function getFullMonthName(dateString) {
    const monthNames = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    const d = new Date(dateString);
    return monthNames[d.getMonth()];
}

function getCompleteDateString(dateString) {
    let date = new Date(dateString);
    return `${getDayName(dateString)}, ${date.getDate()} ${getMonthName(dateString)}`
}

function toBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {

            return resolve(reader.result);
        }
        // reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
}

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

async function getFile(event) {
    if (event.target && event.target.files && event.target.files.length) {

        if (
            event.target.files[0].type !== 'application/pdf' &&
            event.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
            event.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
            event.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.presentationml.presentation' &&
            event.target.files[0].type !== 'image/png' &&
            event.target.files[0].type !== 'image/jpg' &&
            event.target.files[0].type !== 'image/jpeg' &&
            event.target.files[0].type !== 'image/webp' &&
            event.target.files[0].type.indexOf('video/') < 0
        ) {
            return null;
        }
        console.log("aqui2");
        const result = await toBase64(event.target.files[0]).catch(e => console.log(e));


        if (result) {
            const dataSplitted = result.split(result.substring(0, result.indexOf('base64,') + 'base64,'.length));
            if (dataSplitted.length > 1) {
                let data = {
                    type: event.target.files[0].type,
                    base64: result,
                    splitted: dataSplitted[1]
                }
                if ('name' in event.target.files[0]) {
                    data.name = event.target.files[0].name;
                }
                if ('size' in event.target.files[0]) {
                    data.size = event.target.files[0].size;
                }

                return data;
            }

        }
        return null;
    }
}

function chatObjBuilder(item) {
    let obj = null;

    if (item) {
        obj = {
            Titulo: item.NombreCompleto,
            ImageUrl: item.ThumbnailUrl || null,
            Cargo: item.Cargo || null,
            EmpresaNombreCorto: item.EmpresaNombreCorto || null,
            clv_destinatario: item.clv_usuario || null,
            clv_tipoconversacion: 1
        }
    }
    return obj;
}

function useIsMounted() {
    const isMountedRef = useRef(true);
    const isMounted = useCallback(() => isMountedRef.current, []);

    useEffect(() => {
        return () => void (isMountedRef.current = false);
    }, []);

    return isMounted;
}

async function getVideoDuration(videoBase64) {
    return new Promise((resolve, reject) => {

        let video = document.createElement('video');
        video.preload = 'metadata';
        video.src = videoBase64;

        video.onloadedmetadata = () => resolve(video.duration);

        video.onerror = error => reject(error);
    })
}


export const toolsHelper = {
    validateEmail,
    timeSince,
    toBase64,
    formatBytes,
    getFile,
    chatObjBuilder,
    useIsMounted,
    validateURL,
    getDayName,
    getMonthName,
    getFullMonthName,
    getVideoDuration
};
