import { authenticationService } from '../services';

export function handleResponse(response, isLogin = false, isLogout = false) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                if (!isLogout) {
                    authenticationService.logout();
                }

                if (!isLogin) {
                    // localStorage.removeItem('currentUser');
                    if (localStorage.getItem('currentUser')) {
                        window.location.reload(true);
                    }
                }
            }

            const error = (data && data.message) || response.statusText;
            throw new Error(error);
            return Promise.reject(error);
        }

        return data;
    });
}
