import React, { useEffect, useState } from 'react';
import back from '../../../../../_assets/media/images/icons/icn_close.png'
import visa from '../../../../../_assets/media/images/icons/img_visa.png'
import amex from '../../../../../_assets/media/images/icons/img_amex.png'
import master from '../../../../../_assets/media/images/icons/img_mastercard.png'

import './Detail.scss';
import { membershipService } from '../../../../../_resources/services/membership.service';
import { swalAlertsService } from '../../../../../_resources/services';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const Detail = (props) => {

    const [membership, setMembership] = useState([])
    const params = useParams()
    const location = useLocation()
    const history = useHistory()


    useEffect(() => {
        getData()
    }, [])


    const getData = () => {
        setMembership(props.membership.filter( member => member.clv_membresia === props.data.clv_membresia)[0])
    }

    const validateCard = (card) => {
        if(new RegExp("^4[0-9]{12}(?:[0-9]{3})?\$").test(card)) {
            return visa
        }
        if(new RegExp("^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}\$").test(card)) {
            return master
        }
        if(new RegExp("^3[47][0-9]{13}\$").test(card)) {
            return amex
        }
        if(new RegExp("^6(?:011|5[0-9]{2})[0-9]{12}\$").test(card)) {
            return amex
        }
    }

    const setCard = () => {
        let loading = swalAlertsService.createLoading("Espere un momento...")
        loading.fire()
        let data = {
            "clv_metodopago": 1,
            "clv_membresia": props.data.clv_membresia,
            "token_id": props.token
        }

        membershipService.newCard(data).then(
            (response) => {
                loading.close()
                if(response.status === 200 && location.state.id === 0 ) {
                        swalAlertsService.showToast("success", "Subscripción creada exitosamente")
                        history.replace("/register-steps", {id: 1})
                        window.location.reload(true)
                }
                if(response.status === 200 && location.state.id === 1 ) {
                        swalAlertsService.showToast("success", "Subscripción creada exitosamente")
                        history.goBack()
                }
                if(response.status === 500){
                        response.text().then(text => {
                            swalAlertsService.showToast("error","No es posible crear una suscripción a un usuario que tiene una suscripción activa")
                        })
                }
            }
        )
    }

    const getMethod = (dataCard) => {
        return (
            <div className={`container_data_option_detail`}>
                 <img src={validateCard(dataCard.number)} width="50px" height="40px"/>
                <div className="container_data_option_detail_description">
                    <p>{dataCard.name}</p>
                    <h5>{`**** ${dataCard.number[dataCard.number.length - 4]}${dataCard.number[dataCard.number.length - 3]}${dataCard.number[dataCard.number.length - 2]}${dataCard.number[dataCard.number.length - 1]}`}</h5>
                </div>

            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="container-details">
                <div className="container_header">
                    <img src={back} width="30px" height="30px" onClick={() => {props.setView({"type": true,"method": false, "card": false, "detail": false})}}/>
                    <h3>Pagar</h3>
                </div>

                <h5>Membresía</h5>

                <div className={`container_data_option_detail`}>
                    <div className="container_data_option_detail_description">
                        <h5>{`${membership.Moneda} $${membership.Precio} ${membership.Intervalo}`}</h5>
                        <p>{`Primeros ${membership.DiasPrueba} días gratis`}</p>
                    </div>
                </div>

                <p className="container_subtitulo">Puedes cancelar cuando quieras</p>

                <h5>Método de pago</h5>

                {
                    getMethod(props.card)
                }


                <button className="button_detail" onClick={() => setCard()}>Pagar</button>

            </div>
        </React.Fragment>
    );
}

export {Detail};
