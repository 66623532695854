import React from 'react';

import logoSplash from '../../../_assets/media/images/logos/logo_wenet_splash.png';

import './Loader.scss';

const Loader = (props) => {
    return (
        <div className={`container-fluid Loader-container ${props.showAnimation ? `${props.animationShow ? 'Loader-animated-in':'Loader-animated-out'}`:''}`}>
            <div className="row h-100 m-0 d-flex align-items-center justify-content-center pos-relative">

                <div className="col-12 p-0">
                    <div className="row m-0">
                        <div className="col-12 Loader-svg-container d-flex align-items-center justify-content-center">
                            <svg className="pl" viewBox="0 0 200 200" width="200" height="200" xmlns="http://www.w3.org/2000/svg">
                            	<defs>
                            		<linearGradient id="pl-grad1" x1="1" y1="0.5" x2="0" y2="0.5">
                            			<stop offset="0%" stop-color="#028F3C" />
                            			<stop offset="100%" stop-color="#0FB855" />
                            		</linearGradient>
                            		<linearGradient id="pl-grad2" x1="0" y1="0" x2="0" y2="1">
                            			<stop offset="0%" stop-color="#028F3C" />
                            			<stop offset="100%" stop-color="#0FB855" />
                            		</linearGradient>
                            	</defs>
                            	<circle className="pl__ring" cx="100" cy="100" r="82" fill="none" stroke="url(#pl-grad1)" stroke-width="36" stroke-dasharray="0 257 1 257" stroke-dashoffset="0.01" stroke-linecap="round" transform="rotate(-90,100,100)" />
                            	<line className="pl__ball" stroke="url(#pl-grad2)" x1="100" y1="18" x2="100.01" y2="182" stroke-width="36" stroke-dasharray="1 165" stroke-linecap="round" />
                            </svg>
                        </div>

                        <div className="col-12 text-center Loader-text-container">
                            Bienvenido(a) a WeNet
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { Loader };
