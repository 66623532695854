import React, {useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import icnUserPlaceholder from '../../../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnCompanyPlaceholder from '../../../../../_assets/media/images/icons/icn_company_placeholder.png';
import { useDidMountEffect, useElementOnScreen } from '../../../../../_resources/helpers';

import start from '../../../../../_assets/media/images/icons/icn_batch_star.svg';


import './DirectoryDesktopList.scss';
import { DirectoryEnterpricesModal } from '../../../../shared';

const DirectoryDesktopList = (props) => {
    const history = useHistory();
    const [isOpenDirectoryEnterpricesModal, setIsOpenDirectoryEnterpricesModal] = useState(false);
    const [enterpriceToShow, setEnterpriceToShow] = useState(null);

    const [ lastItemRef, isVisible ] = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 1.0
    });

    useDidMountEffect(() => {
        // react please run me if 'key' changes, but not on initial render
        let isSuscribed = true;
        if (isVisible && props.loadMore) {
            props.loadMore();
        }
        return () => isSuscribed =  false
    }, [isVisible]);

    const getImage = (item) => {
        if (item) {
            let value = item[props.mode === 'users' || props.mode === 'mentors' ? 'ThumbnailUrl':'LogoThumbnailUrl'];
            let icon = props.mode === 'users' || props.mode === 'mentors' ? icnUserPlaceholder:icnCompanyPlaceholder;
            return value || icon;
        }
        return null;
    }

    const getName = (item) => {
        if (item) {
            if (props.mode === 'users' || props.mode === 'mentors') {
                return item.NombreCompleto ? item.NombreCompleto:'';
            } else {
                return item.EmpresaNombreCorto ? item.EmpresaNombreCorto:'';
            }
        }

        return null;
    }

    const getRoleData = (item) => {
        if (item) {
            if (props.mode === 'users'  || props.mode === 'mentors') {
                return `${item.Cargo ? `${item.Cargo}`:''}${item.Cargo && item.EmpresaNombreCorto ? ` | `:''}${item.EmpresaNombreCorto ? `${item.EmpresaNombreCorto}`:''}`;
            } else {
                return `${item.Empresa ? `${item.Empresa}`:''}`;
            }
        }
        return null;
    }

    const getGiroData = (item) => {
        if (item) {
            if (props.mode === 'users' || props.mode === 'mentors') {
                return `${item.Giro ? `${item.Giro}`:''}${item.Giro && item.Clasificacion ? `, `:''}${item.Clasificacion ? `${item.Clasificacion}`:''}`;
            } else {
                return `${item.Miembros} Miembro${item.Miembros > 1 ? 's': ''}`;
            }
        }

        return null;
    }

    const goToUser = (item) => {
        
        const {currentUser} = props;
        if (item.clv_usuario && currentUser) {
            
            if (item.clv_usuario === currentUser.Usuario.clv_usuario) {
                history.replace('/profile');
            } else {
                history.push(`/profile-user/${item.clv_usuario}`, {from:'/directory'} )
            }

        } else if (item.Usuarios && item.Usuarios.length) {
            setEnterpriceToShow(item);
            setIsOpenDirectoryEnterpricesModal(true);
        }
    }

    const getUsers = () => {
        return null;
    }

    const renderItems = () => {
        if (props.directoryState && props.directoryState.results && props.directoryState.results.length) {
            return props.directoryState.results.map((item, index) => {
                return (
                    <div className="col-4 DirectoryDesktopList-item" key={index} onClick={e => goToUser(item)}>
                        <div className="row m-0 mainRow">
                            <div className="col-12 p-0">
                                <div className="row m-0">
                                    <div className="col-12 px-0 text-center DirectoryDesktopList-img-box">
                                        <img src={getImage(item)}
                                            alt=""
                                            className="avatar"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                const icon = props.mode === 'users' || props.mode === 'mentors' ? icnUserPlaceholder : icnCompanyPlaceholder;
                                                e.target.src = icon
                                            }}
                                        />
                                        {
                                            (props.mode === 'users' || props.mode === 'mentors') && item.clv_estatusbm === 2 &&
                                            <img src={start} width="10px" height="10px" alt="miembro" className="miembro" />
                                        }
                                    </div>
                                    <div className="col-12 text-truncate text-center DirectoryDesktopList-name-box noselect">
                                        {getName(item)}
                                    </div>
                                    <div className="col-12 px-0">
                                        <div className="row m-0 pb-3 DirectoryDesktopList-details-box">
                                            <div className="col-12 text-truncate text-center DirectoryDesktopList-details-title noselect">
                                                {getRoleData(item)}
                                            </div>
                                            <div className={`col-12 text-truncate text-center DirectoryDesktopList-details-subtitle noselect ${props.mode === 'users' || props.mode === 'mentors' ? '':'order-last'}`}>
                                                {getGiroData(item)}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 p-0 DirectoryDesktopList-footer-btn-box">
                                <button className="btn-cleared noselect" onClick={e => goToUser(item)}>{item.Usuarios ? "Ver perfiles":"Ver perfil"}</button>
                            </div>
                        </div>
                    </div>
                )
            });
        } else {
            return (
                <div className="col-12 p-0 DirectoryDesktopList-placeholder-container">
                    <p>Lo sentimos.</p>
                    <span>No hemos encontrado resultados para esta búsqueda.</span>
                </div>
            )
        }
        return null;
    }
    return (
        <div className={`DirectoryDesktopList-container row m-0 ${props.directoryState && ((props.directoryState.results && !props.directoryState.results.length) || (!props.directoryState.results)) ? 'noresults h-100':''}`}>

            {renderItems()}
            <div ref={lastItemRef} className='pt-0'></div>

            {
                isOpenDirectoryEnterpricesModal && 
                enterpriceToShow && 
                <DirectoryEnterpricesModal 
                    users={enterpriceToShow && enterpriceToShow.Usuarios ? enterpriceToShow.Usuarios:null} 
                    title={enterpriceToShow && enterpriceToShow.EmpresaNombreCorto ? enterpriceToShow.EmpresaNombreCorto:null} 
                    onBottomSheetDimiss={() => {setIsOpenDirectoryEnterpricesModal(false); setEnterpriceToShow(null)}}
                />
            }
        </div>
    );
}

export { DirectoryDesktopList }
