import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import back from '../../../../../_assets/media/images/icons/icn_close.png'
import logo from '../../../../../_assets/media/images/icons/logo_wenet_color.png'
import check from '../../../../../_assets/media/images/icons/icn_step_check.png'
import './TypeMembership.scss'
import { membershipService } from '../../../../../_resources/services/membership.service';
import { ConnectableObservable } from 'rxjs';

const TypeMembership = (props) => {

    const {data, setData, setView} = props


    const history = useHistory()

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        membershipService.getMembership().then(
            (response) => {
                props.setMembership(response.data)
            }
        )
    }


    const setType = (id) => {
        setData({...data, "clv_membresia": id})
    }

    const optionsMembership = (membership, key) => {
        return (
            <div key={key} className={`container_data_option ${data.clv_membresia === membership.clv_membresia ? 'active' : ''}`} onClick={() => setType(membership.clv_membresia)}>
                <div className="container_data_option_description">
                    <h4>{`${membership.Moneda} $${membership.Precio} ${membership.Intervalo}`}</h4>
                    <p>{`Primeros ${membership.DiasPrueba} días gratis`}</p>
                </div>

                <img src={check} width="30px" height="30px" />
            </div>
        )
    }
    return (
        <React.Fragment>
            <div className="container-membership">
                <div className="container_header">
                    <img  src={back} width="30px" height="30px" onClick={() => history.goBack()}/>
                </div>

                <div className="container_data">
                    <img className="logo" src={logo} width="140px" height="50px"/>
                    <p className="text1">El lugar para generar <strong>nuevas oportunidades</strong> de negocio</p>

                    <p className="text2">Selecciona el plan que más te convenga y disfruta de todos los beneficios.</p>

                    {
                        props.membership.length > 0 ?
                            props.membership.map( (membership, index) =>
                                optionsMembership(membership, index)
                            )
                        :
                            ""
                    }


                    <button onClick={() => {props.setView({"type": false,"method": true, "card": false, "detail": false})}}>Siguiente</button>
                </div>
            </div>
        </React.Fragment>
    );
}

export {TypeMembership};
