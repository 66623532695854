import { useEffect, useState } from 'react';
import { BottomSheetShared} from '../';

import { infoService } from '../../../_resources/services';
import './UniversalPicker.scss';

const UniversalPicker = (props) => {
    const [open, setOpen] = useState(true);
    const [itemList, setItemList] = useState([]);
    
    const onDismiss = () => {
        setOpen(false);
        if (props.onDismiss) {
            props.onDismiss(false);
        }
    }

    const prepareCatalogItems = () => {
        infoService.catalogs.subscribe((catalogs) => {
            if (catalogs) {
                switch (props.mode) {
                    case 'phone':
                        if (catalogs.Paises) {
                            setItemList(catalogs.Paises);
                        }
                        break;
                    case 'hobby':
                        if(catalogs.Aficiones) {
                            setItemList(catalogs.Aficiones)
                        }
                        break;
                    case 'study':
                        if(catalogs.NivelesEstudio) {
                            setItemList(catalogs.NivelesEstudio)
                        }
                        break;
                    case 'post':
                        if(catalogs.Cargos) {
                            setItemList(catalogs.Cargos)
                        }
                        break;
                    case 'nationality':
                        if (catalogs.Paises) {
                            setItemList(catalogs.Paises);
                        }
                        break;
                    case 'country':
                        if (catalogs.Paises) {
                            setItemList(catalogs.Paises);
                        }
                        break;
                    case 'state':
                        if (catalogs.Paises) {
                            setItemList(catalogs.Paises.filter(pais => pais.Pais === props.country)[0].Estados);
                        }
                        break;
                    case 'category':
                        if (catalogs.Clasificaciones) {
                            setItemList(catalogs.Clasificaciones);
                        }
                        break;
                    case 'giro':
                        if (catalogs.Giros) {
                            setItemList(catalogs.Giros);
                        }
                        break;
                    case 'range':
                        if (catalogs.RangoEmpleados) {
                            setItemList(catalogs.RangoEmpleados);
                        }
                        break;
                    case 'options':
                        setItemList(props.options)
                        break;
                    default:
                        onDismiss();
                        break;
                }
            }

        });
    }

    useEffect(() => {
        prepareCatalogItems();
    })

    const ladaStringFormatter = (value) => {
        let lada = value;
        const valueSplitted = value.split(' ');
        if (valueSplitted.length && valueSplitted.length > 1) {
            lada = `${valueSplitted[0]} (${valueSplitted[1]})`
        }
        return lada;
    }

    const onElementSelected = (item) => {
        console.log(item)
        if (props.onElementSelected) {
            props.onElementSelected(item);
            onDismiss();
        }
    }

    const elementsList = () => {
        const items = itemList.length && itemList.map((element, index) => {
            return (
                <div className="col-12 p-0 UniversalPicker-list-item" key={index} onClick={() => {onElementSelected(element)}}>
                    <div className={`row m-0 h-100 d-flex align-items-center ${props.mode === 'options' ? 'text-red' : ""}`}>
                        {
                            props.mode === 'phone' || props.mode === "country"? (
                                <div className="col element element-left">{element.Pais}</div>
                            ):(null)
                        }
                        {
                            props.mode === 'hobby' ? (
                                <div className="col element element-left">{element.Aficion}</div>
                            ):(null)
                        }
                        {
                            props.mode === 'study' ? (
                                <div className="col element element-left">{element.NivelEstudio}</div>
                            ):(null)
                        }
                        {
                            props.mode === 'post' ? (
                                <div className="col element element-left">{element.Cargo}</div>
                            ):(null)
                        }
                        {
                            props.mode === 'nationality' ? (
                                <div className="col element element-left">{element.Nacionalidad}</div>
                            ):(null)
                        }
                        {
                            props.mode === 'state' ? (
                                <div className="col element element-left">{element.Estado}</div>
                            ):(null)
                        }
                        {
                            props.mode === 'category' ? (
                                <div className="col element element-left">{element.Clasificacion}</div>
                            ):(null)
                        }
                        {
                            props.mode === 'giro' ? (
                                <div className="col element element-left">{element.Giro}</div>
                            ):(null)
                        }
                        {
                            props.mode === 'range' ? (
                                <div className="col element element-left">{element.RangoEmpleados + " empleados"}</div>
                            ):(null)
                        }
                        {
                            props.mode === 'phone' ? (
                                <div className="col element element-right">+{ladaStringFormatter(element.Lada)}</div>
                            ):(null)
                        }
                        {
                            props.mode === 'options' ? (
                                <div className="col element element-right text-red">{element.option}</div>
                            ):(null)
                        }
                    </div>
                </div>
            );
        });

        return (
            <div className="UniversalPicker-list-container row m-0">
                {items}
            </div>
        );
    }

    return (
        <BottomSheetShared showHeader={true} isOpen={open} onDismiss={onDismiss} viewMinHeight={400} viewMaxHeight={1000}>
            {elementsList()}
        </BottomSheetShared>
    )
}

export { UniversalPicker };
