import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import './Recover.scss';
import { toolsHelper } from '../../../_resources/helpers';
import { authenticationService, infoService, swalAlertsService } from '../../../_resources/services';
import { RecoverPhone, RecoverCode } from './recover-components';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import md5 from 'md5';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const WSwal = withReactContent(Swal)

const Recover = (props) => {
    props.onToggleTab(true)
    const history = useHistory();
    const [timerRunning, setTimerRunning] = useState(true);
    const [currentState, setCurrentState] = useState({
        status: 'initial'
    });

    const [postState, setPostState] = useState({
        phone: "",
        countryId: 0,
        lada: "",
        paisResidencia: ""
    });

    const [showFilters, setShowFilters] = useState(false);

    const goToCode = () => {
        let actualStateCP = JSON.parse(JSON.stringify(currentState))
        actualStateCP.status = 'code'
        setCurrentState(actualStateCP)
    }

    const onNextBtnTapped = (postState) => {

        if (postState.countryId && postState.phone && postState.googleToken) {


            authenticationService.getClientIp().then(objIp => {
                let parameters = {
                    clv_pais: postState.countryId,
                    Celular: postState.phone,
                    token: postState.googleToken,
                    IP: objIp.ip
                }

                setPostState(postState)

                let loading = swalAlertsService.createLoading('Cargando');

                loading.fire();
                authenticationService.recoverPassword(parameters).then((response) => {
                    loading.close();
                    if (response && response.data) {
                        goToCode()
                    } else if (response) {
                        swalAlertsService.showToast('error', response);
                    }
                });
            })


        } else {
            let text = '';

            if (!postState.lada) {
                text = 'Introduce una lada';
            }

            if (!postState.phone || postState.phone.length != 10) {
                text = 'Introduce un teléfono (10 dígitos)';
            }

            if (!postState.googleToken) {
                text = 'Catpcha no valido';
            }

            swalAlertsService.showToast('error', text);
        }
    }

    const onCodeBtnTapped = (codePostState, preUser) => {

        if (codePostState.code1 && codePostState.code2 && codePostState.code3 && codePostState.code4 && codePostState.code4) {

            if (codePostState.password && codePostState.confirmPassword) {
                if (codePostState.password === codePostState.confirmPassword) {

                    const r = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&_])[A-Za-z\\d@$!%*?&_]{8,}\$", "i")
                    if (codePostState.password.length < 8) {
                        let text = '';
                        text = "Contraseña no valida \n Tu Contraseña debe tener una longitud mínima de 8";
                        swalAlertsService.showToast('error', text);
                    } else {
                        let code = codePostState.code1 + codePostState.code2 + codePostState.code3 + codePostState.code4 + codePostState.code5
                        let parameters = {
                            UUID: preUser.UUID,
                            Codigo: code,
                            NewPassword: md5(codePostState.password)
                        }
                        let loading = swalAlertsService.createLoading('Cargando');
                        loading.fire();
                        authenticationService.confirmRecoverPassword(parameters).then((response) => {
                            loading.close();
                            if (response.data) {
                                window.location.reload()
                            } else if (response) {
                                swalAlertsService.showToast('error', response);
                            } else {
                                swalAlertsService.showToast('error', "Hubo un error");
                            }
                        });
                    }

                } else {

                    swalAlertsService.showToast('error', "La contraseña no coincide");
                }
            } else {
                swalAlertsService.showToast('error', "Introduce la contraseña");
            }

        } else {

            let text = 'Introduce el código que te enviamos por SMS';

            swalAlertsService.showToast('error', text);
        }
    }



    const onResendCodeBtnTapped = () => {

        let parameters = {
            clv_pais: postState.countryId,
            Celular: postState.phone
        }
        let loading = swalAlertsService.createLoading('Cargando');
        loading.fire();
        authenticationService.recoverPassword(parameters).then((response) => {
            loading.close();
            if (response && response.data) {
                setTimerRunning(true)
            } else if (response) {
                swalAlertsService.showToast('error', response);
            }
        });

    }

    return (
        <GoogleReCaptchaProvider useRecaptchaNet={true} reCaptchaKey="6LfXwokhAAAAANaTzdPQBm9VabRfC0KhzHdIFtRs"  scriptProps={{
            async: true, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: 'head', // optional, default to "head", can be "head" or "body",
            nonce: undefined // optional, default undefined
          }}>
            <div className="container-fluid Recover-container">
                {(currentState.status === 'initial' &&
                    <RecoverPhone onNextBtnTapped={onNextBtnTapped}
                        {...props} />)
                }
                {(currentState.status === 'code' &&
                    <RecoverCode
                        onCodeBtnTapped={onCodeBtnTapped}
                        onResendCodeBtnTapped={onResendCodeBtnTapped}
                        timerRunning={timerRunning}
                        setTimerRunning={setTimerRunning}
                        {...props} />)
                }
            </div>
        </GoogleReCaptchaProvider>

    );

}

export { Recover };
