import React from 'react';
import './NotFound.scss';
import { userService, authenticationService } from '../../../_resources/services';

class NotFound extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: authenticationService.currentUserValue,
        };
    }

    componentDidMount() {}

    render() {
        return (
            <div>
                <h1>NotFound</h1>
            </div>
        );
    }
}

export { NotFound };
