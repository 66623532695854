import React, { useState, useEffect, useRef } from 'react';

import { Link, useHistory } from 'react-router-dom';

import { Dropdown } from 'react-bootstrap';
import ShowMore from 'react-show-more';

import './MessagesChat.scss';

import { messagesService, swalAlertsService } from '../../../../../_resources/services';
import { toolsHelper } from '../../../../../_resources/helpers';

import { InputBox, AudioPlayer, VideoPlayer } from '../../../../shared';
import { uuid } from 'uuidv4';

import PouchDB from "pouchdb-browser";



import imgPlaceholder from '../../../../../_assets/media/images/icons/icn_user_placeholder.png';
import imgPlaceholderGroup from '../../../../../_assets/media/images/icons/icn_placeholder_group.png';
import icnBackward from '../../../../../_assets/media/images/icons/icn_backward.png';
import icnMore from '../../../../../_assets/media/images/icons/icn_more_options.png';
import icnDoc from '../../../../../_assets/media/images/icons/icn_doc.png';
import icnDownload from '../../../../../_assets/media/images/icons/icn_download.png';
import icnstart from '../../../../../_assets/media/images/icons/icn_batch_star.svg';
import { ImageCrop } from '../../../../shared/image-crop/ImageCrop';
import icnclose from '../../../../../_assets/media/images/icons/icn_close.png';
import icn_sending_message from '../../../../../_assets/media/images/icons/icn_sending_message.png';
import icn_send_failed from '../../../../../_assets/media/images/icons/icn_send_failed.png';
import img_bg_white from '../../../../../_assets/media/images/whites-bg-img.png';
import icnsReply from '../../../../../_assets/media/images/icons/icns_reply.png';

import moment from 'moment';


const MessagesChat = (props) => {
    const history = useHistory();
    const [existingChat, setExistingChat] = useState(null);
    const [existingGroup, setExistingGroup] = useState(null);
    const [existBroadcast, setExistBroadcast] = useState(null);

    const [visibleCrop, setVisibleCrop] = useState(false);
    const [imageCrop, setImageCrop] = useState({})
    const [base64, setBase64] = useState("")
    const lastDiv = useRef(null);
    const [text, setText] = useState("")
    const [imageFullScreen, setimageFullScreen] = useState(false)
    const [imageCommentSelected, setimageCommentSelected] = useState("");
    const [size, setSize] = useState(18);
    const [page, setPage] = useState(1);
    const [pageSize, setpageSize] = useState(10);
    const [pagination, setPagination] = useState(null);
    const [db, setDb] = useState(null);


    const topMessages = useRef(null);


    async function loadDataGroup(id, page, pageSize) {
        return await messagesService.getExistingGroup(id, page, pageSize);
    }

    const goScrollTop = (callBack) => {

        if (lastDiv && lastDiv.current) {
            lastDiv.current.scrollIntoView({ behavior: "smooth" });
        }
    }
    useEffect(() => {
        let isSuscribed = true;
        setDb(new PouchDB('pendingMessages'), {
            auto_compaction: false,
        });
        return () => isSuscribed = false
    }, []);

    useEffect(() => {
        let isSuscribed = true;

        if (!props.isGroup && !props.isMassive && props.chat && props.chat.clv_conversacion) {
            const chat = loadDataGroup(props.chat.clv_conversacion);
            chat.then((response) => {
                if (isSuscribed) {
                    if (
                        response &&
                        response.data
                    ) {
                        setExistingChat(response.data);
                        setPagination(response.pagination);
                        let cpExistingChat = JSON.parse(JSON.stringify(response.data));
                        new PouchDB('pendingMessages').allDocs({
                            include_docs: true,
                            attachments: true
                        }).then(function (result) {
                            // handle result
                            if (result && result.rows) {
                                let tempArr = [];
                                result.rows.map(res => {
                                    if (res.doc.idTemp) {
                                        let message = JSON.parse(res.doc.message);
                                        if(message.isSending || message.isFailed){
                                            message.isSending = false;
                                            message.isFailed = true;
                                            if (message.clv_destinatario === cpExistingChat.clv_destinatario && cpExistingChat.Mensajes.findIndex(messageExist => messageExist.idTemp === message.idTemp) < 0) {
                                                tempArr.push(message)
                                            } 
                                        }
                                    }
                                })
                                if (tempArr && tempArr.length) {
                                    cpExistingChat.Mensajes.unshift(...tempArr);
                                    setExistingChat(cpExistingChat);
                                }
                            }
                        }).catch(function (err) {
                            console.log(err);
                        });

                        setTimeout(() => {
                            goScrollTop();
                        }, 1000);
                    } else {
                        // setExistingChat(props.chat);
                    }
                }
            });
        }else if (!props.isGroup && !props.isMassive && props.chat && !props.chat.clv_conversacion && !props.failedIds.length  &&props.chat.isNew) {
            setExistingChat(props.chat);
        }else if (props.isGroup && props.groupDetails && !props.isMassive && !props.chat ) {
            if (props.groupDetails.clv_conversacion) {
                const group = loadDataGroup(props.groupDetails.clv_conversacion);
                group.then((response) => {
                    if (isSuscribed) {
                        if (
                            response &&
                            response.data
                        ) {
                            setExistingGroup(response.data);
                            setPagination(response.pagination);
                            let cpExistingChat = JSON.parse(JSON.stringify(response.data));

                                new PouchDB('pendingMessages').allDocs({
                                    include_docs: true,
                                    attachments: true
                                }).then(function (result) {
                                    // handle result
                                    if (result && result.rows) {
                                        let tempArr = [];
                                        result.rows.map(res => {
                                            if (res.doc.idTemp) {
                                                let message = JSON.parse(res.doc.message);
                                                message.isSending = false;
                                                message.isFailed = true;
                                                if (message.clv_conversacion === cpExistingChat.clv_conversacion && cpExistingChat.Mensajes.findIndex(messageExist => messageExist.idTemp === message.idTemp) === -1) {
                                                    tempArr.push(message)
                                                }
                                            }
                                        })
                                        if (tempArr && tempArr.length) {
                                            cpExistingChat.Mensajes.unshift(...tempArr);
                                            setExistingGroup(cpExistingChat);
                                        }
                                    }
                                }).catch(function (err) {
                                    console.log(err);
                                });

                            setTimeout(() => {
                                goScrollTop();
                            }, 1000);
                        } else {
                            // onBackBtnTapped();
                        }
                    }
                });
            } else {
                // onBackBtnTapped();
            }
        }
      

        if(props && props.onFailedMessage && props.failedIds && props.failedIds.length){
            let cpChat = null; 
            if(!props.isGroup && !props.isMassive && props.chat && existingChat && existingChat.Mensajes){
                cpChat = JSON.parse(JSON.stringify(existingChat));
            }else if(props.isGroup && props.groupDetails && !props.isMassive && !props.chat && existingGroup && existingGroup.Mensajes){
                cpChat =JSON.parse(JSON.stringify(existingGroup));
            }else if(!props.isGroup && !props.groupDetails && props.isMassive && !props.chat && existBroadcast && existBroadcast.Mensajes){
                cpChat = JSON.parse(JSON.stringify(existBroadcast));
            }else if(!props.isGroup && !props.groupDetails && props.isMassive && !props.chat && existBroadcast && !existBroadcast.Mensajes){
                cpChat = {Mensajes: []};
            }

            if(cpChat){
                let failedMessages = cpChat.Mensajes.filter(message =>  props.failedIds.includes(message.idTemp));
                if(failedMessages && failedMessages.length){
                    failedMessages.map( message =>{
                        let index = cpChat.Mensajes.findIndex((messageIndex) => messageIndex.idTemp === message.idTemp);
                        if(index > -1){
                            message.isSending = false;
                            message.isFailed = true;
                            cpChat.Mensajes[index] = message;
                        }
    
                        if(!props.isMassive){
                            db.allDocs({
                                include_docs: true,
                                attachments: true
                            }).then(function (result) {
                                if(result  && result.rows){
                                    result.rows.map( res =>{
                                        if(props.failedIds.includes(res.doc.idTemp)){
                                            let doc = res.doc;
                                            let message = JSON.parse(doc.message);
                                            message.isSending = false;
                                            message.isFailed = true;
                                            doc.message = JSON.stringify(message);
                                            db.put(doc, function (err, response) {
                                                // if (err) { return console.log(err); }
                                                console.log(err);
                                                console.log(response);
                                                    // handle response
                                            });
                                        }
                                    })
                                }
                            }).catch(function (err) {
                                console.log(err);
                            });
                        }
                    });
                    if(!props.isGroup && !props.isMassive && props.chat){
                        setExistingChat(cpChat);
                    }else if(props.isGroup && props.groupDetails && !props.isMassive && !props.chat){
                        setExistingGroup(cpChat);
                    }else if(!props.isGroup && !props.groupDetails && props.isMassive && !props.chat){
                        setExistBroadcast(cpChat);
                    }
                }
            }
        }

        if (props && props.onSuccessMessage && props.sucessIds && props.sucessIds.length) {
            let successMessages = [];
            let cpChat = null;
            if(!props.isGroup && !props.isMassive && props.chat){
                if(existingChat && existingChat.Mensajes){
                    successMessages =  existingChat.Mensajes.filter(message => props.sucessIds.includes(message.idTemp));
                }
                cpChat = JSON.parse(JSON.stringify(existingChat));
                setExistingChat(cpChat);
            }else if(props.isGroup && props.groupDetails && !props.isMassive && !props.chat){
                if(existingGroup && existingGroup.Mensajes){
                    successMessages =  existingGroup.Mensajes.filter(message => props.sucessIds.includes(message.idTemp));
                }
                cpChat = JSON.parse(JSON.stringify(existingGroup));
                setExistingGroup(cpChat);

            }

            if (successMessages && successMessages.length) {

                db.allDocs({
                    include_docs: true,
                    attachments: true
                }).then(function (result) {
                    // handle result
                    if(result  && result.rows){
                        result.rows.map( res =>{
                            if(props.sucessIds.includes(res.doc.idTemp)){
                                db.remove(res.doc).then(response=>{
                                    // console.log(response);  
                                }).catch(err=>{
                                    // console.log(err);
                                });
                            }
                        })
                    }
                }).catch(function (err) {
                    console.log(err);
                });
            }
        }

        return () => isSuscribed = false
    }, [props]);

    const onBackBtnTapped = () => {
        setExistingChat(null);
        if (props.onBackBtnTapped) {
            props.onBackBtnTapped();
        }
    }

    const getSubTitle = () => {
        if (props.isMassive && props.massiveChat) {
            return props.massiveChat.map(e => e.name).reduce((a, b) => `${a}, ${b}`);
        } else if (props.isGroup && existingGroup && existingGroup.Usuarios && existingGroup.Usuarios.length) {
            return existingGroup.Usuarios.map(e => e.NombreCompleto).reduce((a, b) => `${a}, ${b}`);
        } else if (props.chat) {
            return `${props.chat.Cargo}${props.chat.Cargo && props.chat.EmpresaNombreCorto ? ' | ' : ''}${props.chat.EmpresaNombreCorto}`;
        }
        return null;
    }

    const goToUser = () => {
        if (props.chat && props.chat.clv_destinatario && !props.isMassive && !props.isGroup) {
            history.push(`/profile-user/${props.chat.clv_destinatario}`, { from: '/messages' })
        } else if (props.isGroup && existingGroup && !existingGroup.Inactivo) {
            if (props.showGroupDetails) {
                props.showGroupDetails(existingGroup);
            }
        }
    }

    const goToGroupUser = (message) => {
        if (message && message.clv_usuario) {
            history.push(`/profile-user/${message.clv_usuario}`, { from: '/messages' })
        }
    }

    const cutImage = (image) => {
        setImageCrop({ ...imageCrop, 'text': image.text })
        setBase64(image.image)
        setVisibleCrop(true)
    }

    const cut = async (image) => {
        setImageCrop({ ...imageCrop, 'blob': image.blob })
        setVisibleCrop(false)
    }

    const cancel = () => {
        setVisibleCrop(false)
    }


    const getHeaderTitle = () => {
        let title = ''
        if (props.isMassive) {
            title = 'Mensaje masivo';
        } else if (props.isGroup && existingGroup) {
            title = existingGroup.Titulo;
        } else if (props.chat && props.chat.Titulo) {
            title = props.chat.Titulo;
        }

        return <div className="col-12 p-0 user-details-title text-truncate" title={title}>{title}</div>
    }

    const headerRender = () => {

        if (props.chat || props.massiveChat || existingGroup) {
            return <div className="MessagesChat-header-container row m-0 d-flex align-items-center mouse-pointer">
                <div className="col p-0 MessagesChat-header-btn-container left">
                    <button className="btn-cleared" onClick={onBackBtnTapped}>
                        <img src={icnBackward} alt="backward icon" width='30' height='30' className='noselect' />
                    </button>
                </div>
                <div className="col MessagesChat-header-details-box">
                    <div className="row m-0 d-flex align-items-center" onClick={goToUser}>
                        {
                            !props.isMassive && !props.isGroup &&
                            <div className="col p-0 avatar-image-box-30 mouse-pointer">
                                <img src={props.chat.ImageUrl ? props.chat.ImageUrl : imgPlaceholder} alt="User image" className="avatar"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = imgPlaceholder
                                    }} />
                                {
                                    props.chat.clv_estatusbm === 2 &&
                                    <img src={icnstart} width="5px" height="5px" alt="miembro" className="miembro" />
                                }
                            </div>
                        }

                        {
                            !props.isMassive && props.isGroup && !props.chat && existingGroup &&
                            <div className="col p-0 avatar-image-box-30 mouse-pointer">
                                <img src={existingGroup.ImageUrl ? existingGroup.ImageUrl : imgPlaceholderGroup} alt="User image"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = imgPlaceholderGroup
                                    }}
                                />
                            </div>
                        }
                        <div className='col line-height-19px user-details-container'>
                            <div className="row m-0 mouse-pointer">
                                {getHeaderTitle()}
                                <div className="col-12 p-0 user-details-subtitle text-truncate mouse-pointer" title={getSubTitle()}>
                                    {getSubTitle()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        return null;
    }

    const renderPDFName = (file) => {

        if (file && file.Nombre) {
            let name = file.Nombre;
            name = name.indexOf('/document/') >= 0 ? name.replace('/document/', '') : name;
            return <div className='pdf-title text-truncate'>{name}</div>
        }
        return <div className='pdf-title text-truncate'>Ver documento</div>
    }

    const getAvatarData = (message, users) => {
        let avatar = imgPlaceholder;

        if (message && message.clv_usuario && users && users.length) {
            const userFiltered = users.filter(user => user.clv_usuario === message.clv_usuario);

            if (userFiltered.length) {
                avatar = userFiltered[0].ThumbnailUrl || imgPlaceholder;
            }
        }
        return avatar;
    }

    const renderAttachmentByType = (file, isMine, message, users) => {

        if (file && file.Extension && file.URL) {
            switch (file.Extension) {
                case 'mp4':
                    return (
                        <div className="audio-file-container row m-0">
                            <div className="col-12 p-0 pos-relative">
                                <VideoPlayer url={file.URL} duration={file.Duracion} id={file.clv_adjuntoxmensaje} thumbnail={file.ThumbnailUrl} />
                            </div>
                        </div>
                    );
                    break;
                case 'mp3':
                    return (
                        <div className="audio-file-container row m-0">
                            <div className="col-12 p-0 pos-relative">
                                <AudioPlayer url={file.URL} duration={file.Duracion} id={file.clv_adjuntoxmensaje} avatar={getAvatarData(message, users)} isMine={isMine} isChat={true} />
                            </div>
                        </div>
                    );
                    break;
                case 'pdf':
                    return (
                        <div className="pdf-file-container row m-0">
                            <div className="col-12 p-0">
                                <div className="row m-0 name-box">
                                    <div className="col-12 p-0">
                                        <a href={file.URL} target='_blank'>
                                            <img src={icnDoc} alt="" className='pdf-icon' />

                                            {renderPDFName(file)}
                                        </a>
                                    </div>
                                </div>
                                <div className="row m-0 donwloader-box">
                                    <div className="col-12 p-0">
                                        <a href={file.URL} target='_blank' className={`${isMine ? 'mine' : ''}`}>
                                            Descargar archivo
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                    break;
                case 'xlsx':
                    return (
                        <div className="pdf-file-container row m-0">
                            <div className="col-12 p-0">
                                <div className="row m-0 name-box">
                                    <div className="col-12 p-0">
                                        <a href={file.URL} target='_blank'>
                                            <img src={icnDoc} alt="" className='pdf-icon' />

                                            {renderPDFName(file)}
                                        </a>
                                    </div>
                                </div>
                                <div className="row m-0 donwloader-box">
                                    <div className="col-12 p-0">
                                        <a href={file.URL} target='_blank' className={`${isMine ? 'mine' : ''}`}>
                                            Descargar archivo
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                    break;
                case 'docx':
                    return (
                        <div className="pdf-file-container row m-0">
                            <div className="col-12 p-0">
                                <div className="row m-0 name-box">
                                    <div className="col-12 p-0">
                                        <a href={file.URL} target='_blank'>
                                            <img src={icnDoc} alt="" className='pdf-icon' />

                                            {renderPDFName(file)}
                                        </a>
                                    </div>
                                </div>
                                <div className="row m-0 donwloader-box">
                                    <div className="col-12 p-0">
                                        <a href={file.URL} target='_blank' className={`${isMine ? 'mine' : ''}`}>
                                            Descargar archivo
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                    break;
                case 'pptx':
                    return (
                        <div className="pdf-file-container row m-0">
                            <div className="col-12 p-0">
                                <div className="row m-0 name-box">
                                    <div className="col-12 p-0">
                                        <a href={file.URL} target='_blank'>
                                            <img src={icnDoc} alt="" className='pdf-icon' />

                                            {renderPDFName(file)}
                                        </a>
                                    </div>
                                </div>
                                <div className="row m-0 donwloader-box">
                                    <div className="col-12 p-0">
                                        <a href={file.URL} target='_blank' className={`${isMine ? 'mine' : ''}`}>
                                            Descargar archivo
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                    break;
                default:
                    return (
                        <div className="image-file-container row m-0">
                            <div className="col-12 p-0 pos-relative">
                                <img src={file.URL} alt="message image" className='message-img' onClick={() => goImageFullScreen(file.URL)} />
                                <a href={file.URL} target='_blank'>
                                    <img src={icnDownload} alt="download icon" className='icon-img' />
                                </a>
                            </div>
                        </div>
                    );
                    break;
            }
        }
        return null;
    }

    const renderBubbleAttachment = (message, isMine, users) => {

        if (!message.EliminadoXRemitente && message.Adjuntos && message.Adjuntos.length) {
            return message.Adjuntos.map((file, index) => {
                return (
                    <div className={`col-12 attachment-box`} key={index}>
                        {renderAttachmentByType(file, isMine, message, users)}
                    </div>
                )
            });

        }
        return null;

        // if (!message.EliminadoXRemitente && message.Adjuntos && message.Adjuntos.length) {
        //     return message.Adjuntos.map((file, index) => {
        //         return <div className={`col-12 attachment-box`} key={index}>
        //
        //             {
        //                 file.Extension === 'pdf' ? (
        //                     <div className="pdf-file-container row m-0">
        //                         <div className="col-12 p-0">
        //                             <div className="row m-0 name-box">
        //                                 <div className="col-12 p-0">
        //                                     <a href={file.URL} target='_blank'>
        //                                         <img src={icnDoc} alt="" className='pdf-icon'/>
        //
        //                                         {renderPDFName(file)}
        //                                     </a>
        //                                 </div>
        //                             </div>
        //                             <div className="row m-0 donwloader-box">
        //                                 <div className="col-12 p-0">
        //                                     <a href={file.URL} target='_blank' className={`${isMine ? 'mine':''}`}>
        //                                         Descargar archivo
        //                                     </a>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 ):(
        //
        //                     <div className="image-file-container row m-0">
        //                         <div className="col-12 p-0 pos-relative">
        //                             <img src={file.URL} alt="message image" className='message-img' onClick={() => goImageFullScreen(file.URL)}/>
        //                             <a href={file.URL} target='_blank'>
        //                                 <img src={icnDownload} alt="download icon" className='icon-img'/>
        //                             </a>
        //                         </div>
        //                     </div>
        //                 )
        //             }
        //         </div>
        //     });
        //
        // }
        // return null;
    }

    const onInputHeightCheck = () => {
        // console.log("hola");
    }

    const onSubmit = (data) => {
        
        if(data && !data.isResend){
            let tempMessage = {}
            let idTemp = uuid();
            if (!data.file) {
                tempMessage = {
                    Mensaje: data.message,
                    clv_usuario: props.currentUser.Usuario.clv_usuario,
                    clv_destinatario : existingChat && existingChat.clv_destinatario ? existingChat.clv_destinatario  : null,
                    clv_conversacion:
                    existingGroup && existingGroup.clv_conversacion ? existingGroup.clv_conversacion :
                    existingChat && existingChat.clv_conversacion ? existingChat.clv_conversacion  : null,
                    isSending: true,
                    Fecha: moment(),
                    idTemp,
                    data
                }
            }else{
                let Adjuntos = [];
    
                if(data.file.type === 'image/jpeg' || data.file.type === 'image/png' ){
                    Adjuntos.push({
                        clv_tipoadjunto: 3,
                        base64: data.file.base64,
                        Extension: data.file.type.split('/')[1],
                        URL: data.file.base64
                    })
                }else if(data.type === 'video' ){
                    Adjuntos.push({
                        clv_tipoadjunto: 6,
                        base64: data.file.base64,
                        Extension: data.file.type.split('/')[1],
                        URL: data.file.base64,
                        Duracion: data.file.duration,
                        ThumbnailUrl : img_bg_white
                    })
                }else if(data.file.type === "application/pdf" ){
                    Adjuntos.push({
                        clv_tipoadjunto: 6,
                        base64: data.file.base64,
                        Extension: data.file.type.split('/')[1],
                        URL: data.file.base64,
                        Duracion: data.file.duration,
                    })
                }else if(data.file.type === "audio/webm" ){
                    Adjuntos.push({
                        clv_tipoadjunto: 7,
                        base64: data.file.base64,
                        Extension: 'mp3',
                        URL: data.file.base64,
                        Duracion: 0
                    })
                }
                data.meta = {
                    fileType:data.file.type
                }
    
                tempMessage = {
                    Mensaje: data.message,
                    clv_usuario: props.currentUser.Usuario.clv_usuario,
                    clv_destinatario : existingChat && existingChat.clv_destinatario ? existingChat.clv_destinatario  : null,
                    clv_conversacion:
                    existingGroup && existingGroup.clv_conversacion ? existingGroup.clv_conversacion :
                    existingChat && existingChat.clv_conversacion ? existingChat.clv_conversacion  : null,
                    Adjuntos,
                    isSending: true,
                    Fecha: moment(),
                    idTemp,
                    data
                }
            }

            if(!props.isGroup && !props.isMassive && props.chat){
                let cpChat = JSON.parse(JSON.stringify(existingChat));
                if( cpChat.Mensajes){
                    cpChat.Mensajes.unshift(tempMessage);
                }else{
                    cpChat.Mensajes = [tempMessage];
                }
                setExistingChat(cpChat);
            }else if(props.isGroup && props.groupDetails && !props.isMassive && !props.chat){
                let cpChat = JSON.parse(JSON.stringify(existingGroup));
                if( cpChat.Mensajes){
                    cpChat.Mensajes.unshift(tempMessage);
                }else{
                    cpChat.Mensajes = [tempMessage];
                }
                setExistingGroup(cpChat);
            }else if(!props.isGroup && !props.groupDetails && props.isMassive && !props.chat){
                let cpChat = {Mensajes: []};
                cpChat.Mensajes.unshift(tempMessage);
                setExistBroadcast(cpChat);
            }
           
            data['idTemp'] = idTemp;

            if(!props.isMassive){
                db.post( {idTemp: idTemp, message: JSON.stringify(tempMessage)} , (err, result)=>{
                    if(err){
                        // console.log(err);
                    }
                    if(result){
                        // console.log(result);
                    }
                });
            }
        }

        if (props.onSubmit) {
            if (props.isMassive && props.massiveChat) {
                data['Destinatarios'] = props.massiveChat.map(el => { return { clv_destinatario: el.clv_destinatario } });
            } else if (props.isGroup && existingGroup && existingGroup.clv_conversacion) {
                data['clv_conversacion'] = existingGroup.clv_conversacion;
            } else {
                data.clv_destinatario = props.chat.clv_destinatario;
            }

            if (props.reply && props.reply.clv_mensajexconversacion) {
                data['Reply'] = props.reply.clv_mensajexconversacion;
            }
            props.onSubmit(data);
            setImageCrop({})
            setBase64("")
        }
    }

    const goToMentionLink = (clv_usuario) => {
        if (clv_usuario) {
            if (
                props.currentUser &&
                props.currentUser.Usuario &&
                props.currentUser.Usuario.clv_usuario &&
                props.currentUser.Usuario.clv_usuario !== clv_usuario
            ) {
                history.push(`/profile-user/${clv_usuario}`, { from: '/messages' });
            } else {
                history.push(`/profile`, { from: '/messages' });
            }
        }
        return '';
    }

    const createMentionTxt = (message) => {
        let value = message.Mensaje;
        if (message.Menciones && message.Menciones.length) {
            let finalValue = [];
            value = value.trim();
            let tokenString = value.split(' ');
            let keyValue = 0;
            if (tokenString.length) {
                tokenString.forEach((item, i) => {
                    if (item.length) {

                        if (item.indexOf('@@') >= 0) {
                            const brSpacing = item.indexOf('\n') >= 0;
                            const fixedTxt = item.replaceAll('\n', '');
                            const mentioned = message.Menciones.filter(el => el.Tag === fixedTxt);

                            if (mentioned.length) {
                                const obj = [0].map((el, i) => {
                                    keyValue += 1;
                                    if (brSpacing) {
                                        return (
                                            <span key={keyValue}>
                                                <br />
                                                <span className='bubbleMention' title={mentioned[0].NombreCompleto} onClick={e => goToMentionLink(mentioned[0].clv_usuario)}>{`${mentioned[0].NombreCompleto}${tokenString.length - 1 > i ? ' ' : ''}`}</span>
                                            </span>
                                        );
                                    }
                                    return (<span key={keyValue} className='bubbleMention' title={mentioned[0].NombreCompleto} onClick={e => goToMentionLink(mentioned[0].clv_usuario)}>{`${mentioned[0].NombreCompleto}${tokenString.length - 1 > i ? ' ' : ''}`}</span>);
                                });
                                finalValue = finalValue.concat(obj);
                            }
                        } else {
                            keyValue += 1;
                            const obj = [0].map((el, i) => {
                                return (<span key={keyValue}>{`${item}${tokenString.length - 1 > i ? ' ' : ''}`}</span>);
                            });
                            finalValue = finalValue.concat(obj);
                        }
                    }
                });

            }
            return finalValue;
        }
        return message.Mensaje || null;
    }

    const resendMessage = (idTemp) =>{
        swalAlertsService.answer("", "El mensaje no se envío.", "Reenviar", "Borrar").then((result) => {
            let cpChat = null;

            if(!props.isGroup && !props.isMassive && props.chat){
                cpChat =JSON.parse(JSON.stringify(existingChat));
            }else if(props.isGroup && props.groupDetails && !props.isMassive && !props.chat){
                cpChat =JSON.parse(JSON.stringify(existingGroup));
            }else if(!props.isGroup && !props.groupDetails && props.isMassive && !props.chat){
                cpChat =JSON.parse(JSON.stringify(existBroadcast));
            }

            let index = cpChat.Mensajes.findIndex((messageIndex) => messageIndex.idTemp === idTemp);
            let filteredResendMessage = cpChat.Mensajes.filter(mensaje => mensaje.idTemp === idTemp);


            if (result.isConfirmed) {
                if(filteredResendMessage && filteredResendMessage.length && filteredResendMessage[0] && filteredResendMessage[0].data){
                    filteredResendMessage[0].data['isResend'] = true;
                    cpChat.Mensajes[index].isSending = true;
                    cpChat.Mensajes[index].isFailed = false;
                    if(!props.isGroup && !props.isMassive && props.chat){
                        setExistingChat(cpChat);
                    }else if(props.isGroup && props.groupDetails && !props.isMassive && !props.chat){
                        setExistingGroup(cpChat);
                    }
                    if(filteredResendMessage[0].data.file){
                        filteredResendMessage[0].data.file.type = filteredResendMessage[0].data.meta.fileType;
                    }
                    onSubmit(filteredResendMessage[0].data);
                }
            }else if(result.isDismissed && result.dismiss === "cancel"){
                swalAlertsService.answer("Confirmación", "El mensaje será eliminado.", "Si", "No").then((result) => {
                    if (result.isConfirmed) {
                        if(index > -1){
                            cpChat.Mensajes.splice(index, 1);
                            if(!props.isGroup && !props.isMassive && props.chat){
                                setExistingChat(cpChat);
                            }else if(props.isGroup && props.groupDetails && !props.isMassive && !props.chat){
                                setExistingGroup(cpChat);
                            }
                            db.allDocs({
                                include_docs: true,
                                attachments: true
                            }).then(function (result) {
                                // handle result
                                if (result && result.rows) {
                                    let filteredDb = result.rows.filter(row => row.doc.idTemp === filteredResendMessage[0].idTemp)

                                    if (filteredDb && filteredDb.length) {
                                        db.remove(filteredDb[0].doc).then(response => {
                                            // console.log(response);
                                        }).catch(err => {
                                            // console.log(err);
                                        });
                                    }
                                }
                            }).catch(function (err) {
                                console.log(err);
                            });
                        }
                    }
                })
            }
        });
    }

    const renderMessageText = (message) => {
        if (message && message.Mensaje) {
            const isDeleted = message.EliminadoXRemitente;
            if (!isDeleted && message.Menciones && message.Menciones.length) {
                return <div className={`col-12 px-0 text-box ${isDeleted ? 'deleted' : ''}`}>{createMentionTxt(message)}</div>
            }
            return <div className={`col-12 px-0 text-box ${isDeleted ? 'deleted' : ''}`} style={{ fontSize: size }} >{isDeleted ? '[' : ''}{message.Mensaje}{isDeleted ? ']' : ''} </div>
        }
        return null;
    }

    const renderContact = (message) => {
        if (message && message.Contactos && message.Contactos.length) {
            const isDeleted = message.EliminadoXRemitente;
            if (isDeleted) {
                return <div className={`col-12 px-0 text-box text-18 isContact ${isDeleted ? 'deleted' : ''}`}>{isDeleted ? '[' : ''}{message.Mensaje}{isDeleted ? ']' : ''}</div>
            } else {
                return <div className={`col-12 px-0 text-box text-18 isContact `}>
                    <div className="row m-0">
                        <div className="col avatar-image-box-50">
                            <img src={message.Contactos[0].ThumbnailUrl ? message.Contactos[0].ThumbnailUrl : imgPlaceholder} alt="User image" className="avatar"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = imgPlaceholder
                                }} />
                        </div>
                        <div className="col pe-0 contactName">
                            {message.Contactos[0].Nombre ? message.Contactos[0].Nombre : message.Contactos[0].Celular}
                        </div>

                        <div className={`col-12 p-0 bubble-timesince`}>
                            {toolsHelper.timeSince(message.Fecha)}
                        </div>

                        <a className='px-0' href={`tel://${message.Contactos[0].Celular}`}>
                            <div className="col-12 callBtn px-0">
                                Llamar
                            </div>
                        </a>
                    </div>
                </div>
            }

        }
        return null;
    }

    const renderRecommendation = (message) => {

        return <div className={`col-12 px-0 text-box text-18 isContact `}>
            <a className='px-0' href={`/talent/${message.clv_usuario}`}>
                <div className="col-12 callBtn px-0">
                    Recomendar
                </div>
            </a>
        </div>
    }

    const onReplyMessage = (message) => {
        if (props.onReplyMessage && message && message.clv_mensajexconversacion) {
            props.onReplyMessage(message);
        }
    }

    const onDeleteMessage = (message) => {
        if (props.onDeleteMessage && message && message.clv_mensajexconversacion) {
            props.onDeleteMessage(message);
        }
    }

    const onChatUpdate = () => {
        if (props.onChatUpdate) {
            props.onChatUpdate();
        }
    }

    const updateChatBtn = () => {
        return <div className="col-12 p-0 MessagesChat-update-container mouse-pointer noselect" onClick={() => onChatUpdate()}>
            Actualizar conversación
        </div>
    }

    const handleScroll = (e) => {
        if (e.currentTarget.scrollTop === 0) {
            loadMore();
        }
    }

    const loadMore = () => {
        let {TotalItems} = pagination;
        if(!props.isGroup && !props.isMassive && props.chat){
            if( existingChat.Mensajes.length < TotalItems){
                const chat = loadDataGroup(props.chat.clv_conversacion, page + 1, pageSize);
                chat.then((response) => {
                    if (
                        response &&
                        response.data &&
                        response.data.Mensajes
                    ) {
                        let arrMessages = response.data.Mensajes.reverse();
                        let cpChat = existingChat;
                        let newArr = [...arrMessages, ...cpChat.Mensajes]
                        cpChat.Mensajes = [...cpChat.Mensajes, ...arrMessages];
                        setExistingChat(cpChat);
                        setPage(page + 1);
                    } 
                });
            }
        }else if(props.isGroup && props.groupDetails && !props.isMassive && !props.chat){
            if( existingGroup.Mensajes.length < TotalItems){
                const chat = loadDataGroup(props.groupDetails.clv_conversacion, page + 1, pageSize);
                chat.then((response) => {
                    if (
                        response &&
                        response.data &&
                        response.data.Mensajes
                    ) {
                        let arrMessages = response.data.Mensajes.reverse();
                        let cpChat = existingGroup;
                        let newArr = [...arrMessages, ...cpChat.Mensajes]
                        cpChat.Mensajes = [...cpChat.Mensajes, ...arrMessages];
                        setExistingGroup(cpChat);
                        setPage(page + 1);
                    } 
                });
            }
        }
        
    }

    const renderTutorials = (message) => {
        return <div className={`col-12 px-0 text-box text-18 isContact `}>
            <a className='px-0' target={'_blank'} href={`https://imagora.mx/WeNet30/tutorial-wenet.html`}>
                <div className="col-12 callBtn px-0">
                    Ver tutoriales
                </div>
            </a>
        </div>
    }

    const renderReplyMessage = (message) => {

        if (!message || !message.ReplyMessage) {
            return null;
        }
        
        let messageTxt = '';
        if (message.ReplyMessage.Mensaje) {
            messageTxt = message.ReplyMessage.Mensaje;
        }

        let icon = null;
        const { currentUser } = props;
        

        if (message.ReplyMessage.Adjuntos && message.ReplyMessage.Adjuntos.length) {
            if ([1,2,8,9].includes(message.ReplyMessage.Adjuntos[0].clv_tipoadjunto)) {
                icon = 'doc';
                if(!messageTxt.length) {
                    messageTxt = "Documento";
                }
            } else if ([3,4].includes(message.ReplyMessage.Adjuntos[0].clv_tipoadjunto)) {
                icon = 'img';
                if(!messageTxt.length) {
                    messageTxt = "Foto";
                }
            } else if (message.ReplyMessage.Adjuntos[0].clv_tipoadjunto == 6) {
                icon = 'video';
                if(!messageTxt.length) {
                    messageTxt = "Video";
                }
            } else if (message.ReplyMessage.Adjuntos[0].clv_tipoadjunto == 7) {
                icon = 'audio';
                if(!messageTxt.length) {
                    messageTxt = "Audio";
                }
            } else if ([10,11,12].includes(message.ReplyMessage.Adjuntos[0].clv_tipoadjunto)) {
                icon = 'link';
                if(!messageTxt.length) {
                    messageTxt = "Enlace";
                }
            } 
        }

        if (message.ReplyMessage.Contactos && message.ReplyMessage.Contactos.length) {
            icon = 'contact';
            messageTxt = message.ReplyMessage.Contactos[0].Nombre ? message.ReplyMessage.Contactos[0].Nombre:'Contacto';
        }

        const isMine = currentUser && currentUser.Usuario && currentUser.Usuario.clv_usuario && message.ReplyMessage.clv_usuario == currentUser.Usuario.clv_usuario;

        return <div className="col-12 Message-reply-container">
            <div className="row m-0">
                <div className="col-12 px-0 text-truncate reply-name">
                    {isMine ? 'Yo':message.ReplyMessage.Nombre}
                </div>
                <div className="col-12 p-0">
                    <div className="row m-0">
                        <div className="col px-0 text-truncate reply-details">
                            {
                                icon ? <img src={icnsReply} alt="icon reply" className={icon}/>:null
                            }
                            {messageTxt}
                        </div>
                        {
                            message.ReplyMessage.Adjuntos && message.ReplyMessage.Adjuntos.length && [3,4].includes(message.ReplyMessage.Adjuntos[0].clv_tipoadjunto) ? 
                            <div className="col reply-attachment-container">
                                <img src={message.ReplyMessage.Adjuntos[0].URL} alt="reply img" />
                            </div>:null
                        }
                    </div>
                </div>
            </div>
        </div>;
    }

    const chatContainer = () => {
        const { currentUser } = props;
        let obj = null;

        if (props.isGroup && existingGroup) {
            obj = JSON.parse(JSON.stringify(existingGroup));
        } else if (props.chat && existingChat) {
            obj = JSON.parse(JSON.stringify(existingChat));
        }else if(!props.chat && props.isMassive && existBroadcast){
            obj = JSON.parse(JSON.stringify(existBroadcast));
        }

        if (obj && obj.Mensajes && obj.Mensajes.length && currentUser && 'Usuario' in currentUser) {

            obj.Mensajes.reverse();

            let bubbles = obj.Mensajes.map((message, index) => {

                if (message.Mensaje || (message.Adjuntos && message.Adjuntos.length)) {
                    const mine = currentUser.Usuario.clv_usuario === message.clv_usuario;
                    return (
                        <div className="row m-0" key={index}>
                            <div className={`col-12 p-0  col-bubble ${mine ? 'mine' : ''}`}>
                                
                                <div className={`MessagesChat-bubble row ${message.ReplyMessage ? 'is-reply':null}`}>
                                    { renderReplyMessage(message) }
                                    {
                                        !message.isFailed && !message.isSending &&
                                        <div className="MessagesChat-bubble-btn-container">
                                            <Dropdown>
                                                <Dropdown.Toggle>
                                                    <img src={icnMore} alt="options icon" width='30' height='30' />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item className='text-primary' onClick={e => onReplyMessage(message)}>Responder</Dropdown.Item>
                                                    {
                                                        mine &&
                                                        <Dropdown.Item className='text-danger' onClick={e => onDeleteMessage(message)}>Eliminar</Dropdown.Item>
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    }

                                    {
                                        !mine && props.isGroup &&
                                        <div className="MessagesChat-bubble-name-container col-12 px-0 m-0" onClick={e => goToGroupUser(message)}>
                                            <div className="col-12 name noselect">{message.NombreCompleto}</div>
                                            <div className="col-12 details noselect">{`${message.Cargo ? message.Cargo : ''}${message.Cargo && message.EmpresaNombreCorto ? ' | ' : ''}${message.EmpresaNombreCorto}`}</div>
                                        </div>
                                    }
                                   
                                    {renderBubbleAttachment(message, mine, obj.Usuarios || [])}
                                    {renderMessageText(message)}
                                    <div className={`col-12 p-0 bubble-timesince`}>
                                        {toolsHelper.timeSince(message.Fecha)}
                                        {
                                            mine && message.isSending && !message.isFailed &&
                                            <span><img className='sendingMessage' src={icn_sending_message} alt="" /></span>
                                        }
                                    </div>
                                    {message.clv_tipoaccion == 4 ? renderRecommendation(message) : ''}
                                    {message.clv_tipoaccion === 10 ? renderTutorials(message) : ''}
                                </div>

                                {
                                    mine && !message.isSending && message.isFailed &&
                                    <span><img className='icn_send_failed' src={icn_send_failed} alt="" onClick={()=> {resendMessage(message.idTemp)}} /></span>
                                }
                            </div>
                        </div>
                    );
                } else if (message.Contactos && message.Contactos.length) {
                    const mine = currentUser.Usuario.clv_usuario === message.clv_usuario;
                    return (
                        <div className="row m-0" key={index}>
                            <div className={`col-12 p-0  col-bubble ${mine ? 'mine' : ''}`}>

                                <div className={`MessagesChat-bubble row isContact ${message.ReplyMessage ? 'is-reply':null}`}>
                                    {
                                        !message.isFailed && !message.isSending &&
                                        <div className="MessagesChat-bubble-btn-container">
                                            <Dropdown>
                                                <Dropdown.Toggle>
                                                    <img src={icnMore} alt="options icon" width='30' height='30' />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item className='text-primary' onClick={e => onReplyMessage(message)}>Responder</Dropdown.Item>
                                                    {
                                                        mine &&
                                                        <Dropdown.Item className='text-danger' onClick={e => onDeleteMessage(message)}>Eliminar</Dropdown.Item>
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    }
                                    {
                                        !mine && props.isGroup &&
                                        <div className="MessagesChat-bubble-name-container col-12 px-0 m-0" onClick={e => goToGroupUser(message)}>
                                            <div className="col-12 name noselect">{message.NombreCompleto}</div>
                                            <div className="col-12 details noselect">{`${message.Cargo ? message.Cargo : ''}${message.Cargo && message.EmpresaNombreCorto ? ' | ' : ''}${message.EmpresaNombreCorto}`}</div>
                                        </div>
                                    }
                                    {renderContact(message)}

                                </div>
                            </div>
                        </div>
                    );
                }
                return null;
            });
            if (bubbles) {
                bubbles.push(<div className="MessagesChat-div-scrolled" key={bubbles.length} ref={lastDiv}></div>);
            }
            return (
                <>
                    <div ref={topMessages}></div>
                    <div id='MessagesChat-bubble-container' className="MessagesChat-bubble-container col-12" onScroll={handleScroll}>
                        {bubbles}
                    </div>
                </>

            )
        }

        return null;
    }

    const goImageFullScreen = (url) => {
        setimageFullScreen(!imageFullScreen)
        setimageCommentSelected(url)
    }

    const onCancelReply = () => {
        if (props.onCancelReply) {
            props.onCancelReply();
        }
    }

    const renderInputBox = () => {
        if (existingChat || (existingGroup && props.isGroup) || (props.isMassive && props.massiveChat)) {
            
            if (existingChat || (props.isMassive && props.massiveChat)) {
                return <InputBox onInputHeightCheck={onInputHeightCheck} onSubmit={onSubmit} cutImage={cutImage} imageCrop={imageCrop} chatProps={props} onCancelReply={onCancelReply}/>
            } else if (existingGroup && props.isGroup) {
                if (!existingGroup.Inactivo) {
                    return <InputBox onInputHeightCheck={onInputHeightCheck} onSubmit={onSubmit} cutImage={cutImage} imageCrop={imageCrop} chatProps={props} onCancelReply={onCancelReply}/>
                } else {
                    return <div className="ChatBanned-container">
                        No puedes enviar mensajes. Ya no formas parte de este grupo.
                    </div>
                }
            }
        }

        return null;
    }

    return (
        <React.Fragment>
            {
                // !visibleCrop &&
                // (
                //     <div className="MessagesChat-container row m-0 h-100">
                //         <div className="col-12 p-0">
                //
                //             {headerRender()}
                //             {
                //                 !props.isMassive &&
                //                 <div className="row m-0">
                //                     {updateChatBtn()}
                //                 </div>
                //             }
                //             <div className="row m-0">
                //                 {chatContainer()}
                //             </div>
                //             {renderInputBox()}
                //         </div>
                //     </div>
                // )
            }
            <div className="MessagesChat-container row m-0 h-100">
                <div className="col-12 p-0">

                    {headerRender()}
                    {
                        !props.isMassive &&
                        <div className="row m-0">
                            {updateChatBtn()}
                        </div>
                    }
                    <div className="row m-0">
                        {chatContainer()}
                    </div>
                    {renderInputBox()}
                </div>
            </div>
            {
                visibleCrop &&
                <ImageCrop src={base64} cutCrop={cut} cancelCrop={cancel} text={text} />
            }
            {
                imageFullScreen &&
                (
                    <div className="overlay-image">
                        <img src={icnclose} className="btn-close" onClick={() => goImageFullScreen()} />
                        <div className="image-full-screen">
                            <img src={imageCommentSelected} />
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    );
}

export { MessagesChat };
