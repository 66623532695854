import React, {
    useState,
    useEffect,
    useRef,
    useMemo,
    useCallback,
    ReactElement,
    MouseEvent
} from 'react';
import {
    useHistory,
    Redirect
} from "react-router-dom";

import * as DataTransfer from 'fbjs/lib/DataTransfer';

import './AddPost.scss';

import 'draft-js/dist/Draft.css';

import { BottomSheetShared, Filters, ModalDefault, Rules } from '../../shared';
import { FilterOptionPicker } from '../../shared/filters/filter-components';
import { AddPostHeader, AddPostCarousel, AddPostBottom } from './add-post-components';
import {
    infoService,
    profileService,
    publicationService,
    swalAlertsService,
    authenticationService,
    algoliaService
} from '../../../_resources/services/';
import { toolsHelper, useWindowDimensions } from '../../../_resources/helpers';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import { EditorState, convertToRaw, Modifier, ContentState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
    defaultSuggestionsFilter,
    MentionData,
    MentionPluginTheme,
} from '@draft-js-plugins/mention';

import icnCancel from '../../../_assets/media/images/icons/icn_cancel_red.png';
import icnBrochure from '../../../_assets/media/images/icons/icn_doc.png';
import icnClose from '../../../_assets/media/images/icons/icn_close.png';

import icnMore from '../../../_assets/media/images/icons/icn_more.png';
import icnPhoto from '../../../_assets/media/images/icons/icn_options_photos.png';
import icnImages from '../../../_assets/media/images/icons/icn_options_images.png';
import icnVideo from '../../../_assets/media/images/icons/icn_options_video.png';
import icnDoc from '../../../_assets/media/images/icons/icn_options_doc.png';
import icnRules from '../../../_assets/media/images/icons/icn_options_rules.png';
import icnVideoPlaceholder from '../../../_assets/media/images/icons/icn_video_placeholder.png';
import icnPlay from '../../../_assets/media/images/icons/icn_play_video.png';


import icnPlaceholder from '../../../_assets/media/images/icons/icn_user_placeholder.png';
import icnStar from '../../../_assets/media/images/icons/icn_batch_star.svg';


import imgUserPlaceholder from '../../../_assets/media/images/icons/icn_user_placeholder.png';
import start from '../../../_assets/media/images/icons/icn_batch_star.svg';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Support } from '../configuration/support/Support';
import { ImageCrop } from '../../shared/image-crop/ImageCrop';
import id from 'date-fns/esm/locale/id/index.js';
const WSwal = withReactContent(Swal);


function Entry(props) {
    const {
        mention,
        theme,
        searchValue,
        isFocused,
        ...parentProps
    } = props;

    let cleanProps = { ...parentProps };
    ['selectMention'].forEach(prop => {
        delete cleanProps[prop];
    });


    return (
        <div {...cleanProps} className='AddPost-mention-box col-12'>
            <div className='AddPost-mention-main row m-0'>
                <div className='AddPost-mention-left avatar-image-box-45 col'>
                    <img src={mention.avatar ? mention.avatar : icnPlaceholder}
                        alt="User image"
                        className="avatar"
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = icnPlaceholder
                        }}
                        role="presentation"
                    />
                    {
                        mention.clv_estatusbm === 2 ? (
                            <img src={icnStar} alt="icnStar" className='bmIcon' />
                        ) : (null)
                    }
                </div>

                <div className='AddPost-mention-right col'>
                    <div className="row m-0">
                        <div className="col-12 p-0 AddPost-mention-name text-truncate">
                            {mention.name}
                        </div>
                        <div className="col-12 p-0 AddPost-mention-title text-truncate">
                            {mention.title}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const AddPost = (props) => {
    const history = useHistory();
    const [goToMain, setGoToMain] = useState(false);
    const [showRules, setShowRules] = useState(false);
    const inputFile = useRef(null);
    const inputImage = useRef(null);
    const inputVideo = useRef(null);
    const [catalogs, setCatalogs] = useState(null);
    const [roleString, setRoleString] = useState(null);
    const [renderInputs, setRenderInputs] = useState(true);
    const [commentLength, setCommentLength] = useState(3500);
    const [showFilters, setShowFilters] = useState(false);
    const [showSupport, setShowSupport] = useState(true);
    const [visibleCrop, setVisibleCrop] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [showSupportVisible, setShowSupportVisible] = useState(false)
    const [bottomSheetConfig, setBottomSheetConfig] = useState({
        isOpen: true,
        isFistOpen: true
    });
    const [openCamera, setOpenCamera] = useState(false);

    const [bottomSheetMaxHeightDefiner, setBottomSheetMaxHeightDefiner] = useState(500);

    const [postState, setPostState] = useState({
        canPost: false,
        postTypeSelected: null,
        postComment: '',
        postRole: null,
        postCategory: null
    });
    const [imageData, setImageData] = useState({
        base64: null,
        splitted: null,
        fromEdit: false,
        deleted: false
    });

    const [galleryData, setGalleryData] = useState([]);
    const [galleryDataDeleted, setGalleryDataDeleted] = useState([]);

    let selected = postState && postState.postTypeSelected && postState.postTypeSelected.clv_tipopublicacion ? postState.postTypeSelected.clv_tipopublicacion : { clv_tipopublicacion: 0 };

    const [fileData, setFileData] = useState({
        base64: null,
        splitted: null,
        name: null,
        size: null,
        fromEdit: false,
        deleted: false
    });

    const [activeFilters, setActiveFilters] = useState({
        filtersSelected: null,
        postedBy: null,
        isUsingFilters: false
    });

    const refEditor = useRef(null);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const [suggestions, setSuggestions] = useState([]);
    const [mentions, setMentions] = useState([]);
    const [mentionsPicked, setMentionsPicked] = useState([]);

    const [openMentions, setOpenMentions] = useState(false);

    const [{ MentionSuggestions, plugins }] = useState(() => {
        const mentionPlugin = createMentionPlugin({
            entityMutability: 'IMMUTABLE',
            mentionPrefix: '@',
            supportWhitespace: true,
        });
        const { MentionSuggestions } = mentionPlugin;

        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    });

    const { width, height } = useWindowDimensions();

    const onHideModal = () => {
        if (props.onHide) {
            props.onHide();
        }
    }

    const onOpenChangeMentions = useCallback((_open) => {
        setOpenMentions(_open);
    }, []);

    const prepareFilters = () => {
        let parameters = {
            facets: ["Aficion", "Cargo", "Clasificacion", "Estado", "Giro", "Pais"],
            hitsPerPage: 999,
            facetFilters: []
        };

        if (currentUser && currentUser.Usuario && currentUser.Usuario.clv_usuario) {
            let userID = currentUser.Usuario.clv_usuario;

            parameters['numericFilters'] = [`BloqueadoPor.clv_usuario!=${currentUser.Usuario.clv_usuario}`, `clv_usuario!=${currentUser.Usuario.clv_usuario}`];

            const { filtersSelected } = activeFilters;

            if (filtersSelected) {
                let facetsRows = ["Aficiones", "Cargos", "Clasificaciones", "Estados", "Giros", "Paises"]
                facetsRows.forEach((facet, index) => {
                    if (filtersSelected[facet] && filtersSelected[facet].length) {
                        const elements = filtersSelected[facet].map(element => `${parameters.facets[index]}: ${element.name}`);
                        parameters.facetFilters.push(elements);
                    }
                });
            }
        }

        return parameters;
    }

    const onSearchChange = useCallback((e) => {
        if (props) {
            let parameters = prepareFilters();
            if (!parameters.numericFilters && currentUser && currentUser.Usuario && 'clv_usuario' in currentUser.Usuario) {
                parameters.numericFilters = [`clv_usuario!=${currentUser.Usuario.clv_usuario}`];
            }

            const fetchData = async () => {
                const response = await algoliaService.getResults(e.value, parameters, 'Directorio', false, false);
                return response;
            }

            fetchData().then((response) => {
                if (response && response.length) {
                    let mapped = response.map((user) => {
                        const id = user.clv_usuario;
                        const key = `${user.NombreCompleto}-${user.clv_usuario}`;
                        const entityKey = `${user.NombreCompleto}-${user.clv_usuario}`;
                        const name = user.NombreCompleto;
                        const link = `/profile-user/${user.clv_usuario}`;
                        const avatar = user.ThumbnailUrl || null;
                        const userId = user.clv_usuario;
                        const title = `${user.Cargo ? user.Cargo : ''}${user.Cargo && user.EmpresaNombreCorto ? ' | ' : ''}${user.EmpresaNombreCorto ? user.EmpresaNombreCorto : ''}`;
                        const clv_estatusbm = user.clv_estatusbm || null;
                        return { name, link, avatar, userId, title, clv_estatusbm, id, key, entityKey }
                    });
                    setSuggestions(mapped);
                } else {
                    setSuggestions([]);
                }
            }, (e) => { console.log(e); });
        }
    }, []);

    const insertTxtToEditor = (text) => {
        let newStateEditor = EditorState.push(editorState, ContentState.createFromText(text));
        newStateEditor = EditorState.moveSelectionToEnd(newStateEditor);
        setEditorState(newStateEditor);
    }

    const goToPreviousPath = () => {
        // if (history.action !== 'POP') {
        //     history.goBack();
        // } else {
        //     setGoToMain(true);
        // }
        onHideModal();
    }

    const onCarouselItemPicked = (item) => {
        let postStateCP = JSON.parse(JSON.stringify(postState));
        if (item.Longitud) {
            setCommentLength(item.Longitud);
            if (postStateCP.postComment && postStateCP.postComment && postStateCP.postComment.length > item.Longitud) {
                postStateCP.postComment = postStateCP.postComment.substring(0, item.Longitud);
                let element = document.getElementById('comment-text-field');
                if (element) {
                    element.value = postStateCP.postComment;
                }
                calcTextAreaHeight();
            }
        }

        postStateCP.postTypeSelected = item;
        setPostState(postStateCP);
        defineHeightBottomSheet();
    }

    const onSubcategoryItemPicked = (item) => {

        let postStateCP = JSON.parse(JSON.stringify(postState));
        postStateCP.postCategory = item;
        setPostState(postStateCP);
    }

    const defineHeightBottomSheet = () => {
        let textFieldEl = document.getElementById('AddPost-textfield-container');
        if (textFieldEl && textFieldEl.offsetTop + textFieldEl.offsetHeight + 20 !== bottomSheetMaxHeightDefiner) {
            setBottomSheetMaxHeightDefiner(textFieldEl.offsetTop + textFieldEl.offsetHeight + 20);
        }
    }

    const onTextFieldChanged = (e) => {
        calcTextAreaHeight();
        let postStateCP = JSON.parse(JSON.stringify(postState));
        postStateCP.postComment = e.target.value;
        setPostState(postStateCP);
        defineHeightBottomSheet();
    }

    const calcTextAreaHeight = () => {
        let textarea = document.querySelector(".comment-text-field");
        if (textarea) {
            textarea.style.height = `30px`;
            const lh = 30;
            const scrlH = textarea.scrollHeight - lh;
            let lines = Math.ceil(scrlH / 14);

            let height = lines * 20 + 20 + 2 + 12;
            height = height > 250 ? 250 : height;
            textarea.style.height = `${height}px`;
        }
    }

    const onFileDelete = (type, index) => {
        if (type === 'imageOld' && imageData.fromEdit) {
            let imageDataCP = JSON.parse(JSON.stringify(imageData));
            imageDataCP.deleted = true;
            setImageData(imageDataCP);
        } else if (type === 'image' || type === 'video') {

            if (galleryData.length && galleryData[index]) {
                let galleryDataCP = JSON.parse(JSON.stringify(galleryData));
                if (type === 'video') {
                    swalAlertsService.answer("", "¿Seguro que deseas borrar este video?", "Si, borrar").then((result) => {
                        if (result.isConfirmed) {
                            if (galleryDataCP[index].fromEdit) {
                                let galleryDataDeletedCP = JSON.parse(JSON.stringify(galleryDataDeleted));
                                galleryDataDeletedCP.push(galleryDataCP[index]);
                                setGalleryDataDeleted(galleryDataDeletedCP);
                            }
                            galleryDataCP.splice(index, 1);
                            setGalleryData(galleryDataCP);

                            setRenderInputs(false);
                        }
                    })
                } else {
                    if (galleryDataCP[index].fromEdit) {
                        let galleryDataDeletedCP = JSON.parse(JSON.stringify(galleryDataDeleted));
                        galleryDataDeletedCP.push(galleryDataCP[index]);
                        setGalleryDataDeleted(galleryDataDeletedCP);
                    }
                    galleryDataCP.splice(index, 1);
                    setGalleryData(galleryDataCP);

                    setRenderInputs(false);
                }
            }

        } else if (type === 'file') {
            if (fileData.fromEdit) {
                let fileDataCP = JSON.parse(JSON.stringify(fileData));
                fileDataCP.deleted = true;
                setFileData(fileDataCP);
            } else {
                setFileData({
                    base64: null,
                    splitted: null,
                    name: null,
                    size: null,
                    fromEdit: false,
                    deleted: false
                });
                setRenderInputs(false);
            }
        }
    }

    const secondsToHumanTime = (secValue) => {
        if (secValue && !isNaN(secValue)) {
            const secNum = parseInt(secValue, 10); // don't forget the second param
            let hours = Math.floor(secNum / 3600);
            let minutes = Math.floor((secNum - (hours * 3600)) / 60);
            let seconds = secNum - (hours * 3600) - (minutes * 60);

            const hasHours = hours > 0 ? true : false;

            seconds = secNum < 1 ? 1 : seconds;
            if (hours < 10) { hours = "0" + hours; }
            if (minutes < 10) { minutes = "0" + minutes; }
            if (seconds < 10) { seconds = "0" + seconds; }
            return `${hasHours ? `${hours}:` : ''}${minutes}:${seconds}`;
        }
        return '00:00';
    }

    const showMediaByType = (item, index) => {
        if (item) {
            if (item.type) {
                if (item.type === 'video') {
                    return (
                        <div className="AddPost-image-container pos-relative" key={index}>
                            <video className='image-container isVideo' src={item.base64} alt="photo preview" />
                            <button className='btn-cleared' onClick={() => onFileDelete('video', index)}>
                                <img src={icnCancel} alt="cancel icon" />
                            </button>
                            <div className='videoOverlay'></div>
                            <img src={icnPlay} alt="icnPlay" className='videoPlayIcon' />
                            {
                                item.duration ? (
                                    <span className='durationSpan'>{secondsToHumanTime(item.duration)}</span>
                                ) : (null)
                            }
                        </div>
                    );
                }
            }
            return (
                <div className="AddPost-image-container" key={index}>
                    <img className='image-container' src={item.base64} alt="photo preview" />
                    <button className='btn-cleared' onClick={() => onFileDelete('image', index)}>
                        <img src={icnCancel} alt="cancel icon" />
                    </button>
                </div>
            );
        }
        return null;
    }

    const showGallery = () => {
        if (galleryData.length) {
            const itemsFiltered = galleryData.filter(item => item.base64 && !item.deleted);
            if (itemsFiltered.length) {
                return itemsFiltered.map((item, index) => {
                    return showMediaByType(item, index);
                });
            }
        }

        return null;
    }

    const getLengthOfSelectedText = () => {
        const currentSelection = editorState.getSelection();
        const isCollapsed = currentSelection.isCollapsed();

        let length = 0;

        if (!isCollapsed) {
            const currentContent = editorState.getCurrentContent();
            const startKey = currentSelection.getStartKey();
            const endKey = currentSelection.getEndKey();
            const startBlock = currentContent.getBlockForKey(startKey);
            const isStartAndEndBlockAreTheSame = startKey === endKey;
            const startBlockTextLength = startBlock.getLength();
            const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
            const endSelectedTextLength = currentSelection.getEndOffset();
            const keyAfterEnd = currentContent.getKeyAfter(endKey);

            if (isStartAndEndBlockAreTheSame) {
                length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
            } else {
                let currentKey = startKey;

                while (currentKey && currentKey !== keyAfterEnd) {
                    if (currentKey === startKey) {
                        length += startSelectedTextLength + 1;
                    } else if (currentKey === endKey) {
                        length += endSelectedTextLength;
                    } else {
                        length += currentContent.getBlockForKey(currentKey).getLength() + 1;
                    }

                    currentKey = currentContent.getKeyAfter(currentKey);
                };
            }
        }

        return length;
    }

    const handleBeforeInput = () => {
        if (bottomSheetConfig.isOpen) {
            onBottomSheetDimiss();
        }
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;
        const selectedTextLength = getLengthOfSelectedText();

        if (currentContentLength - selectedTextLength > commentLength - 1) {
            console.log(`you can type max ${commentLength} characters`);

            return 'handled';
        }
    }

    const handlePastedText = (pastedText) => {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;
        const selectedTextLength = getLengthOfSelectedText();

        if (currentContentLength + pastedText.length - selectedTextLength > commentLength) {
            const selection = editorState.getSelection()
            const isCollapsed = selection.isCollapsed()
            const tempEditorState = !isCollapsed ? removeSelection() : editorState
            addPastedContent(pastedText, tempEditorState)
            return 'handled';
        }
        return 'not-handled'
    }

    const removeSelection = () => {
        const selection = editorState.getSelection()
        const startKey = selection.getStartKey()
        const startOffset = selection.getStartOffset()
        const endKey = selection.getEndKey()
        const endOffset = selection.getEndOffset()
        if (startKey !== endKey || startOffset !== endOffset) {
            const newContent = Modifier.removeRange(editorState.getCurrentContent(), selection, 'forward')
            const tempEditorState = EditorState.push(
                editorState,
                newContent,
                "remove-range"
            )
            setEditorState(
                tempEditorState
            )
            return tempEditorState
        }
        return editorState
    }

    const addPastedContent = (input, editorState) => {
        const inputLength = editorState
            .getCurrentContent()
            .getPlainText().length;
        let remainingLength = commentLength - inputLength;

        const newContent = Modifier.insertText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            input.slice(0, remainingLength)
        );
        setEditorState(
            EditorState.push(
                editorState,
                newContent,
                "insert-characters"
            )
        )
    }

    const textFieldElement = () => {

        return (
            <React.Fragment>
                <div id='AddPost-textfield-container' className="AddPost-textfield-container">
                    {
                        <>
                            <Editor className='DraftEditor-testt'
                                editorKey={'editor'}
                                editorState={editorState}
                                onChange={(e) => {
                                    const result = convertToRaw(e.getCurrentContent());
                                    let value = '';
                                    if (result && result.blocks) {
                                        const blocks = result.blocks;
                                        // value = blocks.map(block => (!block.text.trim() && !'\n') || block.text).join('\n');
                                        value = blocks.map(block => block.text).join('\n');
                                        if (value.length === 1 && (value.indexOf('\n') >= 0 || value.indexOf('\t') >= 0)) {
                                            value = '';
                                        }
                                    }

                                    setEditorState(e);
                                    let postStateCP = JSON.parse(JSON.stringify(postState));
                                    postStateCP.postComment = value;
                                    setPostState(postStateCP);
                                }}
                                plugins={plugins}
                                ref={refEditor}
                                handlePastedFiles={(e) => {
                                    if (e && e.length && e[0].type.indexOf('image') >= 0) {
                                        getFile({ target: { files: e } }, 'image')
                                    }
                                }}
                                handleBeforeInput={handleBeforeInput}
                                handlePastedText={handlePastedText}
                                placeholder={
                                    selected === 4 ?
                                        'Escribe una pregunta especializada. Ejemplo: ¿Qué libro de finanzas me recomiendan? ¿Dónde puedo conseguir un distribuidor de café?'
                                        :
                                        'Escribir...'}
                            />

                            <MentionSuggestions
                                open={openMentions}
                                onOpenChange={e => onOpenChangeMentions(e)}
                                suggestions={suggestions}
                                onSearchChange={e => { onSearchChange(e) }}
                                onAddMention={(e) => {
                                    if (e && e.userId) {
                                        if (mentionsPicked.length) {
                                            let filterd = mentionsPicked.filter(el => el.clv_usuario === e.userId);

                                            if (!filterd.length) {
                                                let mentionsPickedCP = JSON.parse(JSON.stringify(mentionsPicked));
                                                mentionsPickedCP.push({ clv_usuario: e.userId, name: e.name });
                                                setMentionsPicked(mentionsPickedCP);
                                            }
                                        } else {
                                            setMentionsPicked([{ clv_usuario: e.userId, name: e.name }]);
                                        }
                                    }
                                }}
                                entryComponent={Entry}
                            />

                        </>
                    }

                    <span className='counterSpan'>{postState.postComment.length} / {commentLength}</span>

                </div>
                {
                    imageData.base64 && !imageData.deleted &&
                    <div className="AddPost-image-container">
                        <img className='image-container' src={imageData.base64} alt="photo preview" />
                        <button className='btn-cleared' onClick={() => onFileDelete('imageOld')}>
                            <img src={icnCancel} alt="cancel icon" />
                        </button>
                    </div>
                }
                {showGallery()}
                {
                    fileData.base64 && !fileData.deleted &&
                    <div className="row m-0">
                        <div className="col-12">
                            <div className="row m-0 AddPost-file-container">
                                <div className="col p-0 icon-box">
                                    <img className='image-container' src={icnBrochure} alt="file icon" />
                                </div>
                                <div className="col p-0">
                                    <div className="row m-0 d-flex align-items-center h-100">
                                        <div className="col-12 p-0">
                                            <div className="row m-0 extradata-container">
                                                <div className="col-12 p-0 title text-block-truncate-1">
                                                    {fileData.name || 'Documento'}
                                                </div>

                                                {
                                                    fileData.size &&
                                                    <div className="col-12 p-0 subtitle text-block-truncate-1">
                                                        {fileData.size}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <button className='btn-cleared' onClick={() => onFileDelete('file')}>
                                    <img src={icnCancel} alt="cancel icon" />
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }

    const onBottomSheetDimiss = () => {
        setBottomSheetConfig({
            isOpen: false,
            isFistOpen: false
        });
    }

    const onRowTapped = (type) => {
        if (type === 'rol') {
            setShowFilters(true);
        } else if (type === 'photo') {
            if (fileData.splitted) {
                swalAlertsService.answer("", "Si cargas una imagen borrarás el documento cargado anteriormente", "Si, cargar").then((result) => {
                    if (result.isConfirmed) {
                        setOpenCamera(true);
                    }
                })
            } else {
                setOpenCamera(true);
            }
            // setOpenCamera(true);
        } else if (type === 'image') {
            if (fileData.splitted) {
                swalAlertsService.answer("", "Si cargas una imagen borrarás el documento cargado anteriormente", "Si, cargar").then((result) => {
                    if (result.isConfirmed) {
                        inputImage.current.click();
                    }
                })
            } else {
                inputImage.current.click();
            }
        } else if (type === 'video') {
            if (fileData.splitted) {
                swalAlertsService.answer("", "Si cargas un video borrarás el documento cargado anteriormente", "Si, cargar").then((result) => {
                    if (result.isConfirmed) {
                        inputVideo.current.click();
                    }
                })
            } else {
                inputVideo.current.click();
            }
        } else if (type === 'doc') {

            if (imageData.splitted || galleryData.length) {
                swalAlertsService.answer("", "Si cargas un documento borrarás las imágenes cargadas anteriormente", "Si, cargar").then((result) => {
                    if (result.isConfirmed) {
                        inputFile.current.click();
                    }
                })
            } else {
                inputFile.current.click();
            }
        } else {
            setShowRules(true);
        }
    }

    const defineRoleString = () => {

        if (postState.postRole && postState.postRole.length) {
            let value = '';

            if (postState.postRole && postState.postRole.length == 7) {
                value = 'Todos'
            } else {

                const maxStringCount = 18;
                let itemsExtraCount = 0;

                postState.postRole.forEach((item, index) => {
                    if (item.Cargo) {
                        if (value.length < maxStringCount) {
                            const subtrr = maxStringCount - value.length;
                            if (subtrr) {
                                value += item.Cargo.substring(0, subtrr);

                                if (postState.postRole.length - 1 > index) {
                                    if (value.length === maxStringCount) {
                                        value += '...';
                                    } else if (value.length + 3 < maxStringCount) {
                                        value += ', ';
                                    }
                                } else {
                                    if (value.length === maxStringCount) {
                                        value += '...';
                                    }
                                }

                            }

                        } else if (postState.postRole.length - 1 > index) {
                            itemsExtraCount += 1;
                        }
                    }
                });
            }
            // if (itemsExtraCount) {
            //     value = `${value} +${itemsExtraCount}`;
            // }
            return value;
        }
        return null;
    }

    const onSubmit = (data) => {
        if (data.activeFilters && data.activeFilters.filtersSelected && data.activeFilters.filtersSelected.Cargos) {
            setActiveFilters({
                filtersSelected: data.activeFilters.filtersSelected,
                postedBy: null,
                isUsingFilters: true
            });

            let postStateCP = postState;
            postStateCP.postRole = data.activeFilters.filtersSelected.Cargos;
            setPostState(postStateCP);

            setTimeout(() => {
                setRoleString(defineRoleString());
            }, 200);
        } else {
            setActiveFilters({
                filtersSelected: null,
                postedBy: null,
                isUsingFilters: false
            });

            let postStateCP = postState;
            postStateCP.postRole = null;
            setPostState(postStateCP);

            setTimeout(() => {
                setRoleString(defineRoleString());
            }, 200);
        }
        setShowFilters(false);
    }

    const onFilterDismiss = () => {
        setShowFilters(false);
    }

    const onOpenFilters = () => {
        setShowFilters(true);
    }

    const handleTakePhoto = (dataUri, fileType, duration = null) => {

        if (openCamera) {
            setOpenCamera(false);
        }
        if (dataUri && dataUri.indexOf('base64,') >= 0) {

            const galleryFiltered = galleryData.filter(item => !item.deleted);
            const dataSplitted = dataUri.split(dataUri.substring(0, dataUri.indexOf('base64,') + 'base64,'.length));
            if (dataSplitted.length > 1) {
                let galleryDataCP = JSON.parse(JSON.stringify(galleryData));
                console.log(fileType, duration);
                galleryDataCP.push({
                    base64: dataUri,
                    splitted: dataSplitted[1],
                    fromEdit: false,
                    deleted: false,
                    type: fileType,
                    duration: duration
                });

                setGalleryData(galleryDataCP);

                if (props.edit && props.edit.BrochureUrl) {
                    setFileData({
                        base64: null,
                        splitted: null,
                        name: null,
                        size: null,
                        fromEdit: true,
                        deleted: true
                    })
                } else {
                    setFileData({
                        base64: null,
                        splitted: null,
                        name: null,
                        size: null,
                        fromEdit: false,
                        deleted: false
                    })
                }

                if (fileType === 'image') {
                    setVisibleCrop(true);
                } else {
                    setRenderInputs(false);
                }
            }
        }
    }

    const cut = async (image) => {
        var uri = URL.createObjectURL(image.blob);
        setVisibleCrop(false)
        var reader = new FileReader();
        reader.readAsDataURL(image.blob);
        reader.onloadend = async function () {
            const galleryFiltered = galleryData.filter(item => !item.deleted);
            let galleryDataCP = JSON.parse(JSON.stringify(galleryData));

            let loader = swalAlertsService.createLoading("Espere un momento...")
            var data = reader.result;
            loader.fire()
            const dataSplitted = data.split(",")[1];

            galleryDataCP[galleryDataCP.length - 1] = {
                base64: data,
                splitted: dataSplitted,
                fromEdit: false,
                deleted: false
            };
            // galleryDataCP.push({
            //     base64:data,
            //     splitted:dataSplitted,
            //     fromEdit: false,
            //     deleted: false
            // });
            setGalleryData(galleryDataCP);
            // setImageData({
            //     base64:data,
            //     splitted:dataSplitted,
            //     fromEdit: false,
            //     deleted: false
            // });
            setPostState({ ...postState, postComment: image.text })
            loader.close()
        }
    }

    const cancel = () => {
        if (galleryData && galleryData.length) {
            let galleryDataCP = JSON.parse(JSON.stringify(galleryData));
            galleryDataCP.splice(galleryDataCP.length - 1, 1);
            setGalleryData(galleryDataCP);

            const postComment = postState.postComment;
            setPostState({ ...postState, postComment });
        }
        setVisibleCrop(false)
    }

    const handleFile = (dataUri, extraData) => {

        if (dataUri && dataUri.indexOf('base64,') >= 0) {
            const dataSplitted = dataUri.split(dataUri.substring(0, dataUri.indexOf('base64,') + 'base64,'.length));
            const [,ext] = dataUri.split(';')[0].split('/');
            if (dataSplitted.length > 1) {
                setFileData({
                    base64: dataUri,
                    splitted: dataSplitted[1],
                    ext: ext,
                    name: extraData && 'name' in extraData ? extraData.name : null,
                    size: extraData && 'size' in extraData ? toolsHelper.formatBytes(extraData.size) : null,
                    fromEdit: false,
                    deleted: false
                })

                if (props.edit) {
                    if (props.edit.ImageUrl) {
                        setImageData({
                            base64: null,
                            splitted: null,
                            fromEdit: true,
                            deleted: true
                        });
                    }

                    if (props.edit && props.edit.Galeria && props.edit.Galeria.length) {
                        setGalleryDataDeleted(props.edit.Galeria);
                        setGalleryData([]);
                    }

                } else {
                    setImageData({
                        base64: null,
                        splitted: null,
                        fromEdit: false,
                        deleted: false
                    });

                    setGalleryData([]);
                }
            }
        }
    }

    const convertToMB = (bytesValue) => {
        if (bytesValue === 0) return 0;
        const gbValue = (bytesValue / (1000 * 1000)).toFixed(2);
        return gbValue;
    }

    async function getFile(event, type) {
        let loading = swalAlertsService.createLoading('Procesando archivo', '');
        loading.fire();
        if (type === 'image' && event && event.target && event.target.files && event.target.files.length && event.target.files[0].type && event.target.files[0].type.indexOf('webp') >= 0) {
            loading.close();
            swalAlertsService.showToast('error', 'El formato de la imagen que intentas subir no está soportado, (formatos soportados: image/png, jpg/jpeg)');
            return null;
        } else if (type === 'image' && convertToMB(event.target.files[0].size) > 10) {
            loading.close();
            swalAlertsService.showToast('error', 'Solo puedes subir fotos de máximo 10mb');
            return null;
        } else if (type === 'pdf' && convertToMB(event.target.files[0].size) > 20) {
            loading.close();
            swalAlertsService.showToast('error', 'Solo puedes subir documentos de máximo 20mb');
            return null;
        } else if( type === 'xls' || type === 'xlsx' && convertToMB(event.target.files[0].size) > 20){
            loading.close();
            swalAlertsService.showToast('error', 'Solo puedes subir documentos de máximo 20mb');
        } else if( type === 'docx' && convertToMB(event.target.files[0].size) > 20){
            loading.close();
            swalAlertsService.showToast('error', 'Solo puedes subir documentos de máximo 20mb');
        }else if( type === 'doc' && convertToMB(event.target.files[0].size) > 20){
            loading.close();
            swalAlertsService.showToast('error', 'Solo puedes subir documentos de máximo 20mb');
        }else if( type === 'pptx' && convertToMB(event.target.files[0].size) > 20){
            loading.close();
            swalAlertsService.showToast('error', 'Solo puedes subir documentos de máximo 20mb');
        }

        if (event.target && event.target.files && event.target.files.length) {
            if (type === 'video') {
                if (event.target.files[0].type.indexOf('quicktime') >= 0 || event.target.files[0].type.indexOf('mov') >= 0) {
                    loading.close();
                    swalAlertsService.showToast('error', 'Formato de video no permitido');
                    return null;
                } else if (convertToMB(event.target.files[0].size) > 20) {
                    loading.close();
                    swalAlertsService.showToast('error', 'El tamaño del video no debe exceder los 20mb');
                    return null;
                }
            }

            const result = await toolsHelper.toBase64(event.target.files[0]).catch(e => console.log(e));

            if (result && type) {
                if (type === 'image' || type === 'video') {
                    if (type === 'video') {
                        const duration = await toolsHelper.getVideoDuration(result);
                        handleTakePhoto(result, type, duration);
                    } else {
                        handleTakePhoto(result, type);
                    }
                } else {
                    handleFile(result, event.target.files[0]);
                }
            }

            loading.close();
        } else {
            loading.close();
        }
    }

    const handleCameraError = (e) => {
        swalAlertsService.showToast('error', 'Error al intentar usar la camara');

        setTimeout(() => {
            setOpenCamera(false);
        }, 500);
    }

    const onSuccessEdit = () => {
        window.location.reload();
        // if (props.onSuccessEdit) {
        //     props.onSuccessEdit();
        // }
    }

    const onSuccessNew = () => {
        window.location.reload();
    }

    const onSendNewPost = () => {
        if (postState.canPost && postState.postTypeSelected) {
            console.log(postState);
            let obj = {
                clv_tipopublicacion: postState.postTypeSelected.clv_tipopublicacion,
                Contenido: postState.postComment || ''
            }

            if (postState.postRole && postState.postRole.length) {
                obj.Cargos = postState.postRole.map((rol) => { return { clv_cargo: rol.clv_cargo } });
            }

            if (fileData.splitted) {
                let extSend = 'pdf';
                let clv_tipoadjunto = 1;
                if(fileData.ext === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                    extSend = 'xlsx';
                    clv_tipoadjunto = 8;
                }else if(fileData.ext === 'vnd.openxmlformats-officedocument.wordprocessingml.document'){
                    extSend = 'docx';
                    clv_tipoadjunto = 2;
                }else if(fileData.ext === 'vnd.openxmlformats-officedocument.presentationml.presentation'){
                    extSend = 'pptx';
                    clv_tipoadjunto = 9;
                }

                obj.Brochure = {
                    clv_tipoadjunto,
                    Extension:extSend,
                    Base64: fileData.splitted
                }
            } else if (galleryData.length) {
                let items = galleryData.filter(gallery => gallery.splitted);
                if (items.length) {
                    items = items.map((item) => {
                        if (item.type === 'video') {
                            return {
                                clv_tipoadjunto: 6,
                                Extension: 'mp4',
                                Base64: item.splitted
                            }
                        }
                        return {
                            clv_tipoadjunto: 3,
                            Extension: 'jpg',
                            Base64: item.splitted
                        }
                    });
                    obj.Galeria = items;
                }
            }

            let mentionsPickedCP = JSON.parse(JSON.stringify(mentionsPicked));
            if (mentionsPickedCP.length) {
                let messageTxt = obj.Contenido || '';
                mentionsPickedCP.forEach((item, i) => {
                    messageTxt = messageTxt.replaceAll(`@${item.name}`, `@@${item.clv_usuario}`);
                });

                obj.Contenido = messageTxt;

                obj.Menciones = mentionsPickedCP;
                setMentionsPicked([]);
            }

            if (postState && postState.postTypeSelected.clv_tipopublicacion === 4 && postState.postCategory) {
                obj.clv_categoriapublicacion = postState.postCategory.clv_categoriapublicacion
            } else if (postState && postState.postTypeSelected.clv_tipopublicacion === 4 && !postState.postCategory) {
                obj.clv_categoriapublicacion = catalogs.CategoriasPublicacion[0].clv_categoriapublicacion
            }



            let loading = swalAlertsService.createLoading('Creando publicación', 'En breve estará lista');
            loading.fire();
            publicationService.postPublication(obj).then((response) => {
                setTimeout(() => {
                    loading.close();
                    if (response && response.data && response.message) {
                        swalAlertsService.showToast('success', response.message);
                        onSuccessNew();
                    } else {
                        swalAlertsService.showToast('error', response);
                    }
                }, 2000);
            });
        } else {
            let text = '';

            if (!postState.postTypeSelected) {
                text = 'Selecciona un tipo de publicación';
            }

            if (!postState.postComment) {
                text = `${text}${text.length ? ' y ' : ''}escribe un comentario o sube un archivo`;
            }
            swalAlertsService.showToast('error', text);
        }
    }

    const onSendEditedPost = () => {
        if (postState.canPost && postState.postTypeSelected && props.edit.clv_publicacion) {
            let obj = {
                clv_tipopublicacion: postState.postTypeSelected.clv_tipopublicacion,
                Contenido: postState.postComment || '',
            }

            if (props.edit.ImageUrl) {
                if (imageData.fromEdit) {
                    if (imageData.deleted) {
                        obj['Imagen'] = {
                            'EliminarAdjunto': true
                        }
                    }
                }
            }

            let gallery = [];
            if (props.edit.Galeria && props.edit.Galeria.length) {

                if (galleryDataDeleted.length) {
                    gallery = gallery.concat(
                        galleryDataDeleted.map((item) => {
                            return {
                                EliminarAdjunto: true,
                                clv_imagenxpublicacion: item.clv_imagenxpublicacion
                            }
                        })
                    )
                }

                const galleryNew = galleryData.filter(item => !item.deleted && item.base64 && item.splitted && !item.fromEdit);

                if (galleryNew.length) {
                    gallery = gallery.concat(
                        galleryNew.map((item) => {
                            if (item.type === 'video') {
                                return {
                                    clv_tipoadjunto: 6,
                                    Extension: 'mp4',
                                    Base64: item.splitted
                                }
                            }

                            return {
                                clv_tipoadjunto: 3,
                                Extension: 'jpg',
                                Base64: item.splitted
                            }
                        })
                    )
                }
            } else if (galleryData && galleryData.length) {
                const galleryNew = galleryData.filter(item => !item.deleted && item.base64 && item.splitted && !item.fromEdit);

                if (galleryNew.length) {
                    gallery = gallery.concat(
                        galleryNew.map((item) => {
                            return {
                                clv_tipoadjunto: 3,
                                Extension: 'jpg',
                                Base64: item.splitted
                            }
                        })
                    )
                }
            }

            if (gallery.length) {
                obj['Galeria'] = gallery;
            }

            if (props.edit.BrochureUrl) {

                if (fileData.fromEdit) {
                    if (imageData.deleted) {
                        obj['Brochure'] = {
                            'EliminarAdjunto': true
                        }
                    }
                } else if (fileData.base64 && fileData.splitted && !fileData.fromEdit) {
                    let attachment = {
                        clv_tipoadjunto: 1,
                        Extension: 'pdf',
                        Base64: fileData.splitted
                    }
                    obj['Brochure'] = attachment;
                } else if (!fileData.base64) {
                    obj['Brochure'] = {
                        'EliminarAdjunto': true
                    }
                }

            } else if (fileData.base64 && fileData.splitted && !fileData.fromEdit) {
                let attachment = {
                    clv_tipoadjunto: 1,
                    Extension: 'pdf',
                    Base64: fileData.splitted
                }
                obj['Brochure'] = attachment;
            }


            const roles = props.edit.Cargos && props.edit.Cargos.length ? props.edit.Cargos.filter(el => el.Activo) : [];
            if (roles.length) {
                if (postState.postRole && postState.postRole.length) {
                    let rolesFixed = postState.postRole.map((rol) => {
                        rol.Activo = true;
                        return rol;
                    });

                    postState.postRole.forEach((rol) => {
                        let filtered = props.edit.Cargos.filter(pRol => pRol.clv_cargo !== rol.clv_cargo);

                        if (filtered.length) {
                            filtered[0].Activo = false;
                            const duplicated = rolesFixed.filter(el => el.clv_cargo === filtered[0].clv_cargo);
                            rolesFixed.push(filtered[0]);
                        }
                    });
                    obj['Cargos'] = rolesFixed;
                } else {
                    const rolesFixed = props.edit.Cargos.map((rol) => {
                        rol.Activo = false;
                        return rol;
                    });
                    obj['Cargos'] = rolesFixed;
                }
            } else if (postState.postRole && postState.postRole.length) {
                const rolesFixed = postState.postRole.map((rol) => {
                    rol.Activo = true;
                    return rol;
                });
                obj['Cargos'] = rolesFixed;
            }

            if (postState && postState.postTypeSelected.clv_tipopublicacion === 4 && postState.postCategory) {
                obj.clv_categoriapublicacion = postState.postCategory.clv_categoriapublicacion
            } else if (postState && postState.postTypeSelected.clv_tipopublicacion === 4 && !postState.postCategory) {
                obj.clv_categoriapublicacion = catalogs.CategoriasPublicacion[0].clv_categoriapublicacion
            }

            console.log(obj);

            let loading = swalAlertsService.createLoading('Guardando publicación', 'En breve estará lista');
            loading.fire();
            publicationService.putPublication(props.edit.clv_publicacion, obj).then((response) => {
                setTimeout(() => {
                    loading.close();
                    if (response && response.data && response.message) {
                        swalAlertsService.showToast('success', response.message);
                        onSuccessEdit();
                    } else {
                        swalAlertsService.showToast('error', 'Ocurrió algo al intentar guardar la publicación');
                    }
                }, 2000);
            });
        } else {
            let text = '';

            if (!postState.postTypeSelected) {
                text = 'Selecciona un tipo de publicación';
            }

            if (!postState.postComment) {
                text = `${text}${text.length ? ' y ' : ''}escribe un comentario o sube un archivo`;
            }
            swalAlertsService.showToast('error', text);
        }
    }


    const onPostBtnTapped = () => {
        if (postState.postTypeSelected.clv_tipopublicacion === 4) {
            swalAlertsService.confirm(null, 'Aviso', 'Tu pregunta se ha enviado y se publicará cuando un mentor autorizado responda', () => {
                if (props.edit) {
                    onSendEditedPost();
                } else {
                    onSendNewPost();
                }
            });
        } else {
            if (props.edit) {
                onSendEditedPost();
            } else {
                onSendNewPost();
            }
        }


    }

    const setupInputVal = (value) => {
        let textarea = document.querySelector('.comment-text-field');

        if (textarea) {
            textarea.value = value || '';
            defineHeightBottomSheet();
        }

    }

    const prepareEditValues = () => {
        if (catalogs && props.edit) {
            let postStateCP = JSON.parse(JSON.stringify(postState));
            const filteredPub = catalogs.TiposPublicacion.filter(pub => pub.clv_tipopublicacion === props.edit.clv_tipopublicacion);

            if (props.edit.Cargos.length) {
                const activeRol = props.edit.Cargos.filter(rol => rol.Activo);

                if (activeRol.length) {
                    let filteredRole = [];

                    activeRol.forEach((rol) => {
                        const element = catalogs.Cargos.filter(el => el.clv_cargo === rol.clv_cargo);
                        if (element.length) {
                            filteredRole.push(element[0]);
                        }
                    });

                    if (filteredRole) {
                        postStateCP.postRole = filteredRole;
                        setActiveFilters({
                            filtersSelected: { Cargos: filteredRole },
                            postedBy: null,
                            isUsingFilters: false
                        });
                    }
                }
            }


            postStateCP.postComment = props.edit.Contenido || '';


            if (filteredPub.length) {
                postStateCP.postTypeSelected = filteredPub[0];
            }

            if (props.edit.ImageUrl) {
                setImageData({
                    base64: props.edit.ImageUrl,
                    splitted: props.edit.ImageUrl,
                    fromEdit: true,
                    deleted: false
                })
            } else if (props.edit.BrochureUrl) {
                setFileData({
                    base64: props.edit.BrochureUrl,
                    splitted: null,
                    name: null,
                    size: null,
                    fromEdit: true,
                    deleted: false
                });
            } else if (props.edit.Galeria && props.edit.Galeria.length) {
                let items = props.edit.Galeria.map((item) => {
                    return {
                        base64: item.ImageUrl,
                        splitted: item.ImageUrl,
                        fromEdit: true,
                        deleted: false,
                        clv_imagenxpublicacion: item.clv_imagenxpublicacion,
                        duration: item.Duracion,
                        type: item.clv_tipoimagen === 6 ? 'video' : 'image'
                    }
                });
                setGalleryData(items);
            }
            setPostState(postStateCP);
            setupInputVal(props.edit.Contenido || '');
            setTimeout(() => {
                if (postState.postTypeSelected && postState.postComment) {
                    onCarouselItemPicked(postState.postTypeSelected);
                }
            }, 1000);
        }


    }


    useEffect(() => {
        if (postState.postTypeSelected) {
            let value = false;
            if (postState.postComment || imageData.base64 || fileData.base64) {
                value = true;
            }

            let postStateCP = JSON.parse(JSON.stringify(postState));
            postStateCP.canPost = value;
            if (postState.canPost !== value) {
                setPostState(postStateCP);
            }
        }

        if (props.edit) {
            if (postState.postRole && !roleString) {
                setRoleString(defineRoleString());
            }
        }
    }, [postState]);

    useEffect(() => {
        if (postState.postTypeSelected) {
            let value = false;
            if (postState.postComment || imageData.base64 || fileData.base64) {
                value = true;
            }

            let postStateCP = JSON.parse(JSON.stringify(postState));
            postStateCP.canPost = value;
            if (postState.canPost !== value) {
                setPostState(postStateCP);
            }
        }
    }, [imageData, fileData]);

    useEffect(() => {

        let bottomSheetConfigCP = JSON.parse(JSON.stringify(bottomSheetConfig));

        if (!showFilters) {
            bottomSheetConfigCP.isOpen = true;
        } else {
            bottomSheetConfigCP.isOpen = false;

        }
        if (bottomSheetConfigCP.isOpen !== bottomSheetConfig.isOpen) {
            setBottomSheetConfig(bottomSheetConfigCP);
        }
    }, [showFilters]);

    useEffect(() => {

        let bottomSheetConfigCP = JSON.parse(JSON.stringify(bottomSheetConfig));

        if (!openCamera) {
            bottomSheetConfigCP.isOpen = true;
        } else {
            bottomSheetConfigCP.isOpen = false;

        }
        if (bottomSheetConfigCP.isOpen !== bottomSheetConfig.isOpen) {
            setBottomSheetConfig(bottomSheetConfigCP);
        }
    }, [openCamera]);

    useEffect(() => {
        if (!bottomSheetConfig.isOpen && !showFilters && !openCamera) {
            setBottomSheetConfig({
                isOpen: true,
                isFistOpen: false
            });
        }
    }, [bottomSheetConfig]);

    useEffect(() => {
        if (!renderInputs) {
            setRenderInputs(true);
        }
    }, [renderInputs]);

    useEffect(() => {
        let isSubscribed = true;
        infoService.catalogs.subscribe((response) => {
            if (typeof response === 'object' && response !== null && isSubscribed) {
                console.log(response);
                setCatalogs(response);
            }
        });

        authenticationService.currentUser.subscribe((user) => {
            if (user && user.SUID && user.Usuario && isSubscribed) {
                setCurrentUser(user);
            }
        });

        
        if (props && props.edit && props.edit.Contenido) {
            setEditorState(EditorState.createWithContent(ContentState.createFromText(props.edit.Contenido)));
            console.log(props.edit.clv_tipopublicacion);
            let cpPostState = JSON.parse(JSON.stringify(postState));
            cpPostState.postTypeSelected = {clv_tipopublicacion: props.edit.clv_tipopublicacion};
            setPostState(cpPostState);
        }

        setTimeout(() => {
            if (isSubscribed) {
                defineHeightBottomSheet();
            }
        }, 1000);
        return () => isSubscribed = false;
    }, []);

    useEffect(() => {
        if (props.edit && catalogs) {
            if (props.edit.clv_tipopublicacion === 4) {
                let input = props.edit.Contenido
                prepareEditValues();
                const inputLength = editorState
                    .getCurrentContent()
                    .getPlainText().length;
                let remainingLength = commentLength - inputLength;

                const newContent = Modifier.insertText(
                    editorState.getCurrentContent(),
                    editorState.getSelection(),
                    input.slice(0, remainingLength)
                );
                setEditorState(
                    EditorState.push(
                        editorState,
                        newContent,
                        "insert-characters"
                    )
                )
            }

        }
    }, [props.edit, catalogs]);

    const onAddBtnTapped = (data) => {
        if ('optionsSelected' in data && data.mode === 'Cargos') {
            let obj = { activeFilters: { filtersSelected: {} } };
            obj.activeFilters.filtersSelected = { 'Cargos': data.optionsSelected };
            onSubmit(obj);
        }
    }

    const onDismissPicker = () => {
        setShowFilters(false);
    }

    const goToSupport = () => {
        setShowSupport(!showSupport)
        setShowSupportVisible(!showSupportVisible)
    }

    const getPostHeader = () => {
        if (currentUser) {
            return <AddPostHeader currentUser={currentUser}
                canPost={postState.canPost}
                postState={postState}
                onPostBtnTapped={onPostBtnTapped}
                goToPreviousPath={goToPreviousPath}
                edit={props.edit}
            />
        }

        return null;
    }

    const renderView = () => {
        return (
            <React.Fragment>
                {
                    !visibleCrop &&
                    (
                        <div className='AddPost-container'>
                            {getPostHeader()}
                            {catalogs &&
                                <AddPostCarousel catalogs={catalogs}
                                    postTypeSelected={postState.postTypeSelected}
                                    onCarouselItemPicked={onCarouselItemPicked}
                                    onSubcategoryItemPicked={onSubcategoryItemPicked}
                                />
                            }

                            {textFieldElement()}
                            {
                                bottomSheetMaxHeightDefiner && bottomSheetConfig.isOpen &&
                                <BottomSheetShared showCancelBtn={false}
                                    blocking={false}
                                    isOpen={bottomSheetConfig.isOpen}
                                    firstOpen={bottomSheetConfig.isFistOpen}
                                    onDismiss={onBottomSheetDimiss}
                                    viewMinHeight={30}
                                    viewMaxHeight={30}
                                    bottomSheetMaxHeightDefiner={300}>

                                    <AddPostBottom onRowTapped={onRowTapped} roleString={roleString} postTypeSelected={postState.postTypeSelected} />
                                </BottomSheetShared>
                            }

                            {
                                // showFilters && <Filters onDismiss={onFilterDismiss} onSubmit={onSubmit} hidePostedBy={true} showSubTitle={true} activeFilters={activeFilters}/>
                            }

                            {openCamera &&
                                <div className="AddPost-camera-container">
                                    <Camera onTakePhoto={(e) => { handleTakePhoto(e, 'image') }}
                                        onCameraError={handleCameraError}
                                        isDisplayStartCameraError={false}
                                        isFullscreen={false}
                                        sizeFactor={1}
                                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                                        isImageMirror={false}
                                        imageType={IMAGE_TYPES.JPG}
                                        imageCompression={0.97}>

                                    </Camera>
                                    <button className='btn-cleared closer-btn' onClick={() => setOpenCamera(false)}>
                                        <img src={icnCancel} alt="cancel icon" width='65' height='65' />
                                    </button>
                                </div>
                            }
                        </div>
                    )
                }

                {
                    visibleCrop && galleryData.length &&
                    <ImageCrop src={galleryData[galleryData.length - 1].base64} cutCrop={cut} cancelCrop={cancel} text={postState.postComment} />
                }
            </React.Fragment>
        );
    }

    const mobileRender = () => {
        return (
            <ModalDefault show={props.show} onHide={onHideModal} className='full-view'>
                {renderView()}
            </ModalDefault>
        );
    }

    const desktopRender = () => {
        return (
            <ModalDefault show={props.show} onHide={onHideModal}>
                <div className="AddPost-container-desktop">
                    <div className="row m-0">
                        <div className="col-12 AddPost-desktop-header">
                            <span>{props.edit ? ('Editar') : ('Crear')} publicación</span>
                            <button className="btn-cleared" onClick={onHideModal}>
                                <img src={icnClose} alt="icnClose" />
                            </button>
                        </div>
                    </div>

                    {
                        currentUser && currentUser.Usuario ? (
                            <div className="row m-0 AddPost-desktop-user-container">
                                <div className="col p-0 AddPost-desktop-img-container">
                                    <img src={currentUser.Usuario.ThumbnailUrl || imgUserPlaceholder} alt="user image" 
                                        onError={(e)=>{
                                            e.target.onerror = null;
                                            e.target.src=imgUserPlaceholder
                                        }}
                                    />
                                    {
                                        currentUser.Usuario.clv_estatusbm === 2 &&
                                        <img src={start} width="10px" height="10px" alt="miembro" className="miembro" />
                                    }
                                </div>
                                <div className="col AddPost-desktop-info-container">
                                    <div className="row m-0">
                                        <div className="col-12 p-0 name text-block-truncate-1">{currentUser.Usuario.NombreCompleto}</div>
                                        <div className="col-12 p-0 subtext text-block-truncate-1">{currentUser.Usuario.Cargo} | {currentUser.Usuario.EmpresaNombreCorto}</div>
                                        <div className="col-12 p-0 subtext text-block-truncate-1">{currentUser.Usuario.EstadoResidencia}, {currentUser.Usuario.PaisResidencia}</div>
                                    </div>
                                </div>
                            </div>
                        ) : (null)
                    }

                    {catalogs &&
                        <AddPostCarousel catalogs={catalogs}
                            postTypeSelected={postState.postTypeSelected}
                            onCarouselItemPicked={onCarouselItemPicked}
                            onSubcategoryItemPicked={onSubcategoryItemPicked}
                        />
                    }

                    {textFieldElement()}
                    {
                        selected != 4 ?
                            (<div className="AddPost-desktop-bottom-container">
                                <div className="row m-0 AddPost-desktop-bottom-row AddPost-desktop-bottom-rol-filter-container" onClick={() => { onRowTapped('rol') }}>
                                    <div className="col-12 p-0 pos-relative line-height-60px itmCol">


                                        <span className='firstLabel noselect'>Seleccionar puesto</span>

                                        <span className='secondLabel noselect'>
                                            <span>{roleString ? roleString : 'Todos'}</span>
                                            <button className='btn-cleared'>
                                                <img src={icnMore} alt="" width='40' height='40' />
                                            </button>
                                        </span>

                                    </div>
                                </div>
                            </div>)
                            : (null)
                    }


                    <div className="AddPost-desktop-file-container">
                        <div className="row m-0 h-100">
                            {
                                // <div className="col p-0 AddPost-desktop-file-item noselect" onClick={() => {onRowTapped('photo')}}>
                                //     <img src={icnPhoto} alt="icnPhoto" width='30' height='30'/>
                                //     <span>Foto</span>
                                // </div>
                            }
                            <div className="col p-0 AddPost-desktop-file-item noselect" onClick={() => { onRowTapped('image') }}>
                                <img src={icnImages} alt="icnImages" width='30' height='30' />
                                <span>Imágenes</span>
                            </div>
                            <div className="col p-0 AddPost-desktop-file-item noselect" onClick={() => { onRowTapped('video') }}>
                                <img src={icnVideo} alt="icnVideo" width='30' height='30' />
                                <span>Video</span>
                            </div>
                            <div className="col p-0 AddPost-desktop-file-item noselect" onClick={() => { onRowTapped('doc') }}>
                                <img src={icnDoc} alt="icnDoc" width='30' height='30' />
                                <span>Archivo</span>
                            </div>
                            <div className="col p-0 AddPost-desktop-file-item noselect" onClick={() => { onRowTapped('rules') }}>
                                <img src={icnRules} alt="icnRules" width='30' height='30' />
                                <span>Reglamento</span>
                            </div>
                        </div>
                    </div>

                    <div className="AddPost-desktop-submit-container">
                        <div className="row m-0">
                            <div className="col-12 p-0">
                                <button className={`btn-cleared ${postState.canPost ? 'active' : ''}`} onClick={onPostBtnTapped}>Publicar</button>
                            </div>
                        </div>
                    </div>

                    {openCamera &&
                        <div className="AddPost-camera-container">
                            <Camera onTakePhoto={(e) => { handleTakePhoto(e, 'image') }}
                                onCameraError={handleCameraError}
                                isDisplayStartCameraError={false}
                                isFullscreen={false}
                                sizeFactor={1}
                                idealFacingMode={FACING_MODES.ENVIRONMENT}
                                isImageMirror={false}
                                imageType={IMAGE_TYPES.JPG}
                                imageCompression={0.97}>

                            </Camera>
                            <button className='btn-cleared closer-btn' onClick={() => setOpenCamera(false)}>
                                <img src={icnCancel} alt="cancel icon" width='65' height='65' />
                            </button>
                        </div>
                    }

                    {
                        visibleCrop && galleryData.length &&
                        <ImageCrop src={galleryData[galleryData.length - 1].base64} cutCrop={cut} cancelCrop={cancel} text={postState.postComment} />
                    }
                </div>
            </ModalDefault>
        )
    }

    return (
        <React.Fragment>
            {
                width && width >= 1450 ? (desktopRender()) : (mobileRender())
            }

            {
                showFilters ? (
                    <FilterOptionPicker mode='Cargos'
                        title='Cargos'
                        open={showFilters}
                        catalogs={catalogs}
                        onAddBtnTapped={onAddBtnTapped}
                        onDismiss={onDismissPicker}
                        filtersSelected={activeFilters.filtersSelected}
                    />
                ) : (null)
            }

            {
                // showFilters ? (
                //     <FilterOptionPicker mode={optionPickerMode}
                //         title={optionPickerMode}
                //         open={showFilters}
                //         catalogs={catalogs}
                //         onAddBtnTapped={onAddBtnTapped}
                //         onDismiss={onDismissPicker}
                //         filtersSelected={activeFilters.filtersSelected}
                //     />
                // ):(null)
            }



            {
                // <input type="file" name="myImage" accept="image/png, image/gif, image/jpeg" />
                // <input type="file" name="myImage" accept="image/*" />
                // inputProps={{ accept: 'image/png, image/gif, image/jpeg' }}
            }
            {
                renderInputs &&
                <>

                    <input id="video"
                        type="file"
                        ref={inputVideo}
                        onChange={(event) => { getFile(event, 'video') }}
                        onError={error => console.log(error)}
                        accept="video/mp4,video/x-m4v,video/quicktime,video/*"
                        style={{ display: 'none' }}
                        multiple={false}
                    />

                    <input id="image"
                        type="file"
                        ref={inputImage}
                        onChange={(event) => { getFile(event, 'image') }}
                        onError={error => console.log(error)}
                        accept="image/png, image/gif, image/jpeg"
                        style={{ display: 'none' }}
                        multiple={false}
                    />

                    <input id="image"
                        type="file"
                        ref={inputFile}
                        onChange={(event) => {
                            if (event.target && event.target.files && event.target.files.length) {
                                    if(event.target.files[0].type === "application/pdf"){
                                    getFile(event, 'pdf');
                                }else if(event.target.files[0].type === "application/vnd.ms-excel"){
                                    getFile(event, 'xls');
                                }else if(event.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
                                    getFile(event, 'xlsx');
                                }else if(event.target.files[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"){
                                    getFile(event, 'docx');
                                }else if(event.target.files[0].type === "application/msword"){
                                    getFile(event, 'doc');
                                }else if(event.target.files[0].type === "application/vnd.openxmlformats-officedocument.presentationml.presentation"){
                                    getFile(event, 'pptx');
                                }
                            }
                        }}
                        onError={error => console.log(error)}
                        accept="
                        application/pdf, 
                        application/vnd.ms-excel, 
                        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
                        .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                        .pptx, application/vnd.openxmlformats-officedocument.presentationml.presentation
                        "
                        style={{ display: 'none' }}
                        multiple={false}
                    />
                </>
            }

            {
                goToMain ? (
                    <Redirect to={{ pathname: '/' }} />
                ) : (null)
            }
            <Rules show={showRules} onHide={e => setShowRules(false)} goToSupport={goToSupport} />
            {
                showSupportVisible &&
                <Support hideModal={!showSupport} show={showSupportVisible} goToSupport={goToSupport} goToPreviousPath={goToPreviousPath} onHide={e => setShowRules(false)} />
            }
        </React.Fragment>


    );
}

export { AddPost };
