import React, { useState, useEffect} from 'react';

import './ReportPost.scss';

import { infoService, swalAlertsService, publicationService } from '../../../_resources/services';

import { ModalDefault } from '../';

const ReportPost = (props) => {
    const [reportTypes, setReportTypes] = useState([]);

    const [viewState, setViewState] = useState({
        submitted: false,
        reportType: '',
        comment: null
    });

    const onHide = () => {
        if (props.onHide) {
            props.onHide();
        }
    }

    useEffect(() => {
        let isSuscribed = true;
        infoService.catalogs.subscribe((catalogs) =>{
            if (catalogs && 'TiposReporte' in catalogs && isSuscribed) {
                setReportTypes(catalogs.TiposReporte);
            }
        });

        if (!props || !props.edit || !props.edit.clv_publicacion) {
            onHide();
        }
        return () => isSuscribed = false;
    }, []);



    const onReportTypeSelected = (e) => {
        let viewStateCP = JSON.parse(JSON.stringify(viewState));
        viewStateCP.reportType = e.target.value;

        setViewState(viewStateCP);

    }

    const onHandleTextArea = (e) => {
        let viewStateCP = JSON.parse(JSON.stringify(viewState));
        viewStateCP.comment = e.target.value;

        setViewState(viewStateCP);
    }

    const onSuccess = () => {
        if (props.onSuccess) {
            props.onSuccess();
        }
    }

    const onSubmit = () => {
        let viewStateCP = JSON.parse(JSON.stringify(viewState));
        viewStateCP.submitted = true;

        setViewState(viewStateCP);

        if (viewState.reportType) {
            let loading = swalAlertsService.createLoading('Enviando reporte', 'En breve estará listo');
            const obj = {
                clv_tiporeporte: viewState.reportType,
                Comentarios: viewState.comment
            }

            loading.fire();
            publicationService.reportPublication(props.edit.clv_publicacion,obj).then((response) => {
                loading.close();
                if (response && response.data && response.message) {

                    onSuccess();
                } else {
                    swalAlertsService.showToast('error', 'Ocurrió algo al intentar reportar la publicación');
                }
            });
        } else {
            swalAlertsService.showToast('error', 'Debes ingresar los datos requeridos');
        }

    }

    const headerView = () => {
        return (
            <div className="col-12 ReportPost-header-container">
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <button className="btn-cleared" onClick={onHide}>
                            Cancelar
                        </button>
                        <p>Reportar publicación</p>
                    </div>
                </div>
            </div>
        );
    }

    const reportTypesSelector = () => {
        if (reportTypes && reportTypes.length) {
            let options = reportTypes.map((type, index) => {
                return <option value={type.clv_tiporeporte} key={index}>{type.TipoReporte}</option>
            });
            return (
                <div className={`ReportPost-select-box col-12 p-0 ${viewState.submitted && !viewState.reportType ? 'isError':''}`}>
                    <label htmlFor="report-type-box">
                        Motivo de reporte
                    </label>

                    <select name="report-type-box" id="report-type-box" value={viewState.reportType} onChange={onReportTypeSelected}>
                        <option value='' disabled={true}>Selecciona una  opción</option>
                        {options}
                    </select>
                    {
                        viewState.submitted && !viewState.reportType && <small className='text-danger'>Campo requerido</small>
                    }
                </div>
            );

        }
        return null
    }

    const reportComment = () => {
        return (
            <div className={`ReportPost-input-box col-12 p-0 ${viewState.submitted && !viewState.comment ? 'isError':''}`}>
                <label htmlFor="report-comment">
                    Agrega un comentario
                </label>

                <textarea name="report-comment" id="report-comment" placeholder='Escribir...' maxLength='150' onKeyUp={onHandleTextArea}></textarea>
                {
                    viewState.submitted && !viewState.comment && <small className='text-danger'>Campo requerido</small>
                }
            </div>
        );
    }

    const submitBtn = () => {
        return (
            <div className="ReportPost-submit-box col-12 px-0">
                <button className="btn-cleared" onClick={onSubmit}>
                    Enviar
                </button>
            </div>
        );
    }

    return (
        <ModalDefault show={props.show} onHide={onHide}>
            <div className="ReportPost-container row m-0">
                {headerView()}

                <div className="ReportPost-body col-12">
                    <div className="row m-0">
                        {reportTypesSelector()}
                    </div>
                    <div className="row m-0">
                        {reportComment()}
                    </div>

                    <div className="row m-0">
                        {submitBtn()}
                    </div>
                </div>
            </div>
        </ModalDefault>

    );
}

export { ReportPost }
